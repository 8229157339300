import styled from 'styled-components'

export const FlexDiv = styled.div`
 display:flex;
width:100%;
justify-content:space-between;
padding:.6em 1.8em;
background: #CECECE;
border-radius:8px;
margin-top:50px;
align-items:center;
`


export const InputFlexBetween = styled.div`
 display:flex;
width:92%;
justify-content:space-between;
margin-top:-25px;
align-items:center;
background:#3F3D3C;
border-radius:15px;
padding:0 1em;

`
export const Div = styled.div`
padding:0em 2em;
margin-top:50px;
`
export const H6 = styled.h6`
color:#000;
font-size:12.5px;
margin:0;
font-weight:bold;
&:focus{
   padding:.2em 1.5em;
   background: #FFFFFF;
   border:none;
   font-weight:bold;
   border-radius:5px;
   box-shadow: 0px 4px 8px 0px #00000014;
   color:red;
}
`
export const H4 = styled.h4`
color:#fff;
margin:0;
font-weight:bold;

`
export const Button = styled.button`
padding:.2em 1.5em;
font-size:12px;
background: ${(props:any) => props.active ? '#fff' : 'transparent' };
border:none;
font-weight:bold;
border-radius:5px;
box-shadow: ${(props:any) => props.active ? '0px 4px 8px 0px #00000014' : '0px' };
color:#000;
`


export const DriverDiv = styled.div`
padding:0 2em;
margin-top:0px;
display:flex;
gap:10px;

`

export const Input = styled.input`
background:transparent;
border:none;
padding:15px;
opacity:1;
outline:none;
color:#fff;
`







