import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { Action, combineReducers } from 'redux';
import type { ThunkAction } from 'redux-thunk';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from "redux-persist"
import thunkMiddleware from 'redux-thunk';
import { setupListeners } from '@reduxjs/toolkit/dist/query/react';
import { blogApi } from './services/blogApi';
import { onboardingApi } from './services/onboardingApi';
import { providerApi } from './services/providerApi';
import authReducer from './slices/auth'
import travelReducer from './slices/travelPassengers'
import otpReducer from './slices/otp'
import providerReducer from './slices/provider'
import adminReducer from './slices/admin'
import linkReducer from './slices/link'
import themeReducer from './slices/theme'
import showFilterReducer from './slices/showFilter'
import setShowLocationReducer from './slices/showLocation';
import setCurrentLocationReducer from './slices/currentLocation';
import setShowCarOptionReducer from './slices/showCarOption'
import setShowDriverOptionReducer from './slices/showDriverOption'
import setShowTimeDateReducer from './slices/showTimeDate';
import setShowExtraNoteReducer from './slices/showExtraNote'
import setShowDeliveryLocationReducer from './slices/showDeliveryLocation';
import setShowItemSizeReducer from './slices/showItemSize';
import cartReducer from './slices/cart'
import favouriteReducer from './slices/favourites'
import { customerApi } from './services/customerApi';
import { walletApi } from './services/walletApi';
import { adminApi } from './services/adminApi';
import setPaginationNumReducer from './slices/pagination';


const reducers = combineReducers({
    [onboardingApi.reducerPath]: onboardingApi.reducer,
    [providerApi.reducerPath]: providerApi.reducer,
    [blogApi.reducerPath]: blogApi.reducer,
    [customerApi.reducerPath]: customerApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [walletApi.reducerPath]: walletApi.reducer,

    provider: providerReducer,
    admin: adminReducer,
    link: linkReducer,
    auth: authReducer,
    travel:travelReducer,
    otp: otpReducer,
    theme: themeReducer,
    cart: cartReducer,
    favourites: favouriteReducer,
    showFilter: showFilterReducer,
    showLocation: setShowLocationReducer,
    showCarOption: setShowCarOptionReducer,
    showTimeDate: setShowTimeDateReducer,
    showExtraNote: setShowExtraNoteReducer,
    showDriverOption: setShowDriverOptionReducer,
    showDeliveryLocation: setShowDeliveryLocationReducer,
    showItemSize: setShowItemSizeReducer,
    currentLocation: setCurrentLocationReducer,
    setPaginationNum: setPaginationNumReducer,
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [
        customerApi.reducerPath, 
        walletApi.reducerPath,
        providerApi.reducerPath,
        adminApi.reducerPath,
        blogApi.reducerPath,
    ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
              },
        }).concat(
            thunkMiddleware,
            customerApi.middleware,
            onboardingApi.middleware,
            providerApi.middleware,
            adminApi.middleware,
            blogApi.middleware,
            walletApi.middleware,
        ),
    // getDefaultMiddleware().concat(authApi.middleware) 
});

export type RootState = ReturnType<typeof store.getState>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
setupListeners(store.dispatch);

export default store;