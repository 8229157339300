import styled from 'styled-components'

export const BackgroundBox = styled.div`
 display:flex;
width:100%;
align-items:center;
justify-content:center;
background:rgba(255, 255, 255, 0.9);
transition: all 0.5s ease 0s;
height:100vh;
position:fixed;
top:0;
left:0;
bottom:0;
z-index:11;

`
export const ModalBox = styled.div`
background:black;
display:flex;
flex-direction:column;
padding:30px 20px;
border-radius:10px;
position:absolute;
width:95%;
`
export const H3 = styled.h3`
color:#fff;
`
export const Button = styled.button`
background:#ffa100;
color:#000;
width:50%;
border:none;
outline:none;
padding:10px 12px;
border-radius:7px;
font-weight:700;
font-size:18px;
`
