import React, { FC, useEffect, useRef, useState } from "react";
import { BackgroundBox, Button, H3, ModalBox } from "./index.styles";
import { Link } from "react-router-dom";
import { useAdminCustomerDataMutation } from "../../../services/adminApi";
import userImg from "../../../assets/images/png/phebe.png";
import locationBlack from "../../../assets/images/png/locationBlack.png";
import location from "../../../assets/images/png/location.png";
import seat from "../../../assets/images/png/seat.png";
import cam from "../../../assets/images/png/cam.png";
import filledMail from "../../../assets/images/svg/filled-mailbox.svg";

import {
  useGetVehiclesQuery,
  useProviderPictureUpdateMutation,
  useTravelGarageMutation,
} from "../../../services/providerApi";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import CurrencyInput from "react-currency-input-field";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import Compressor from "compressorjs";
import uuid from "react-uuid";
import { setPassengers } from "../../../slices/travelPassengers";
import {
  MailIconBox,
  MailWrap,
  NameInputWrap,
} from "../../../pages/user/TicketPage/ticketpage.styles";
import { InputIcons } from "../../locationModal/locationmodal.styles";
import PhoneInput from "react-phone-number-input";

const AddPassengerModal: FC<{ addPassengerModal; setAddPassengerModal }> = ({
  setAddPassengerModal,
  addPassengerModal,
}) => {
  let [isLoadingPublish, setIsLoadingPublish] = useState(false);
  const [imagePrev, setImagePrev] = useState<any>();

  const user = useAppSelector((state: any) => state?.auth.auth);

  const { passengers } = useAppSelector<any>(
    (store: RootState) => store?.travel
  );

  const {
    data: providerVehicles,
    isFetching: isFetchingVehicles,
    refetch: refetchVehicles,
  } = useGetVehiclesQuery(user?.id);

  const [travelGarageData, { data, isLoading, isSuccess, isError, error }] =
    useTravelGarageMutation();

  useEffect(() => {
    refetchVehicles();
  }, [providerVehicles, refetchVehicles]);

  const dispatch: any = useAppDispatch();
  let [passengerDetails, setPassengerDetails] = useState<any>({
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    next_of_kin_contact: "",
  });

  const handleAddPassenger = () => {
    setIsLoadingPublish(true);
    const newPassenger: any = {
      id: uuid(),
      firstname: passengerDetails.firstname,
      lastname: passengerDetails.lastname,
      phone: passengerDetails.phone,
      email: passengerDetails.email,
      next_of_kin_contact: passengerDetails.next_of_kin_contact,
    };

    if (passengerDetails.firstname.length < 3) {
      notifyError("Passenger Firstname must be filled");
      setIsLoadingPublish(false);
    } else if (passengerDetails.lastname.length < 3) {
      notifyError("Passenger lastname must be filled");
      setIsLoadingPublish(false);
    } else if (
      (passengerDetails?.phone?.length < 10 &&
        passengerDetails?.email?.length === 0) ||
      (passengerDetails?.email?.length < 8 && passengerDetails?.phone?.length === 0)
    ) {
      notifyError("Passenger Phone or Email must be filled");
      setIsLoadingPublish(false);
    } else if (passengerDetails.next_of_kin_contact.length < 10) {
      notifyError("Passenger's Next of kin contact is required");
      setIsLoadingPublish(false);
    } else {
      setIsLoadingPublish(false);
      setAddPassengerModal(false);
      dispatch(setPassengers(newPassenger));
    }
  };

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const [
    uploadImage,
    {
      data: providerImage,
      isLoading: providerImageLoading,
      isSuccess: providerImageSuccess,
      isError: providerImageIsError,
      error: providerImageError,
    },
  ] = useProviderPictureUpdateMutation();

  return (
    <>
      <ToastContainer />

      <BackgroundBox onClick={() => setAddPassengerModal((prev: any) => !prev)}>
        <ModalBox onClick={(e: any) => e.stopPropagation()}>
          <div className="modal-box">
            <h1>Passenger Details</h1>

            <div className="flex-div">
              <div className="from">
                <label>Firstname</label>

                <input
                  type="text"
                  placeholder="Type Here..."
                  // value={travelVehicleDetails.from}
                  onChange={(e: any) =>
                    setPassengerDetails({
                      ...passengerDetails,
                      firstname: e.target.value,
                    })
                  }
                />
              </div>
              <div className="to">
                <label>Lastname</label>

                <input
                  type="text"
                  placeholder="Type Here..."
                  onChange={(e: any) =>
                    setPassengerDetails({
                      ...passengerDetails,
                      lastname: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="phone">
              <label>Passenger Phone</label>

              <PhoneInput
                defaultCountry="NG"
                value={passengerDetails.phone}
                onChange={(e: any) =>
                  setPassengerDetails({
                    ...passengerDetails,
                    phone: e,
                  })
                }
                international
                withCountryCallingCode
              />
            </div>

            <div className="phone">
              <label>Passenger's Next of Kin Phone</label>

              <PhoneInput
                defaultCountry="NG"
                value={passengerDetails.next_of_kin_contact}
                onChange={(e: any) =>
                  setPassengerDetails({
                    ...passengerDetails,
                    next_of_kin_contact: e,
                  })
                }
                international
                withCountryCallingCode
              />
            </div>

            <div className="email">
              <label>Passenger's Email Address</label>

              <MailWrap>
                <MailIconBox>
                  <InputIcons src={filledMail} iconWidth={"25px"} />
                </MailIconBox>
                <input
                style={{width:"100%"}}
                  type="email"
                  placeholder="Passenger gets Ticket on this Email"
                  onChange={(e: any) =>
                    setPassengerDetails({
                      ...passengerDetails,
                      email: e.target.value,
                    })
                  }
                />
              </MailWrap>
            </div>
            <br />
            <div className="submit">
              {isLoadingPublish ? (
                <button>Loading...</button>
              ) : (
                <button onClick={() => handleAddPassenger()}>
                  Save Seat {passengers.length + 1}
                </button>
              )}
            </div>
          </div>
        </ModalBox>
      </BackgroundBox>
    </>
  );
};

export default AddPassengerModal;
