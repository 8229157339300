import styled from 'styled-components'

export const FlexBetween = styled.div`
 display:flex;
width:100%;
gap:5px;
align-items:center;


`


export const Div = styled.div`
padding:0em 2em;
margin-top:50px;
`

export const Label = styled.label`
color:#fff;
font-size:14px;
font-weight:bold;
flex:1;
`


export const Buttons = styled.button`
padding:.4em .7em;
flex:1;
font-size:11.3px;
background: ${(props:any) => props.$clicked ? "#ffa100" : "#EAEAEA"};
position:relative;
border:none;
font-weight:700;
border-radius:5px;
box-shadow: 0px 4px 8px 0px #00000014;
color:#000;
 &:focus{
   background:#ffa100;
 }
 &:active{
  background:#ffa100;
}
`
export const P = styled.p`
position: absolute;
right:-5px;
color:#fff;
bottom:5px;
background:#ff3a3a;
width:18px;
height:18px;
border-radius:50%;
font-weight:bold;
`


export const RequestDiv = styled.div`
padding:0;
margin-top:0px;
width:100%;
@media only screen and (min-width: 900px) {
  display:grid;
  grid-template-columns: 1fr 1fr;
  gap:1em;

  }
`







