import React, { FC, useEffect, useState, useRef } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { P, Input, Button, Label } from "./insurance.styles";
import FirstScreen from "./firstScreen/firstScreen";
import { useGetInsuranceQuery } from "../../../services/providerApi";
import SecondScreen from "./secondScreen/secondScreen";
// import FirstScreen from '../../providerCarReg/firstScreen/firstScreen';

type Props = {
  vehicleProfile: any;
  userId: any;
};
const Insurance: FC<Props> = ({ userId, vehicleProfile }) => {
  const id = vehicleProfile?.data[0]?.id;

  const {
    data: vehicleInsurance,
    isFetching: isFetchingVehicleInsurance,
    refetch: refetchVehicleInsurance,
  } = useGetInsuranceQuery(id);

  useEffect(() => {
    refetchVehicleInsurance();
  }, [vehicleInsurance]);
  return (
    <>
      <br />
      <br />
      <br />
      <Label>Insurance </Label>
      {vehicleInsurance?.data[0]?.document ? (
        <>
          
          <FirstScreen
            vehicleInsurance={vehicleInsurance}
            vehicleProfile={vehicleProfile}
          />
        </>
      ) : (
        <>
          <FirstScreen
            vehicleInsurance={vehicleInsurance}
            vehicleProfile={vehicleProfile}
          />
         
        </>
      )}
    </>
  );
};
export default Insurance;
