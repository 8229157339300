import styled from 'styled-components'



export const DateBox = styled.div`
border:1px solid #fff;
color:#fff;
padding:8px 5px;
font-size:13px;
font-weight:bold;
background:#000;
border-radius:5px;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
`
export const CounterFilterBox = styled.div`
width:57%;

`
export const Box = styled.div`
display:flex;
align-items:center;
gap:10px;
`
export const Boxes = styled.div`
display:flex;
align-items:center;
gap:5px;
`
export const H1 = styled.h1`
color:#fff;
margin:0;
font-size:20px;

`
export const Text = styled.label`
color:white;
font-size:12px;
`

export const DarkBox = styled.div`
display:grid;
grid-template-columns:1fr 1fr;
flex-wrap:wrap;
align-items:center;
margin-top:15px;
gap:10px;
background:black;
border-radius:10px;
justify-content:center;
padding:15px 10px;
`
export const H4 = styled.h1`
font-weight:bold;
color:white;
font-size:16px;
`
export const P = styled.p`
font-weight:bold;
font-size:12px;
margin:0;
color: #FFD58C;

@media only screen and (max-width: 380px)  {
    font-size: 11px;
}
`
export const Label = styled.label`
padding:3px 5px;
background:gray;
color: #fff;
border-radius:4px;
font-size:8px;
font-weight:700;
`
export const LabelBoxes = styled.div`
display:flex;
align-items:center;
gap:3px;

`

export const Button = styled.button`
background: ${(props:any) => ((props.isDailyActive? '#ffa100':'transparent'))};
border:2px solid white;
padding:5px;
border-radius:50%;
`
export const FullTimeButton = styled.button`
background: ${(props:any) =>(props.isFullTimeActive?  '#ffa100' :'transparent')};
border:2px solid white;
padding:5px;
border-radius:50%;
`



