import React, { useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import {
     FlexDiv, Button, H4
} from './index.styles';
import { RxHamburgerMenu } from 'react-icons/rx';
import Stats from '../Stats/statsOne';
import StatsTwo from '../Stats/statsTwo';
import StatsThree from '../Stats/statsThree';
import StatsFour from '../Stats/statsFour';
import Ratings from '../DashboardRatings/Ratings';
import Reports from '../DashboardRatings/Reports';
import Members from '../Members';
import { baseUrl } from '../../../services/adminApi';
import axios from 'axios';
import { useAppSelector } from '../../../store';

interface VehicleInterface {
    countAccepted?: number;
    countApproved?: number;
    countBlocked?: number;
    countPending?: number;
    countPinned?: number;
    countRejected?: number;
    countTrashed?: number;
}
const DashboardInfo = () => {

    const [vehicleCount, setVehicleCount] = useState<VehicleInterface>();
    const [driverCount, setDriverCount] = useState<any>();
    const [requestCount, setRequestCount] = useState<any>();
    const [totalCount, setTotalCount] = useState<any>();
    
    const user: any = useAppSelector((state: any) => state?.auth?.auth)

    const getFilteredCount = async () => {
        await axios.get(`${baseUrl}count-vehicle-status`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            if (response?.data?.message === 'success') {
                // dispatch(setAllDriver(response?.data))
                setVehicleCount(response?.data?.data)
            }

        }).catch((err) => {
        })
    }
    const getFilteredRequest = async () => {
        await axios.get(`${baseUrl}count-request-status`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            if (response?.data?.message === 'success') {
                // dispatch(setAllDriver(response?.data))
                setRequestCount(response?.data?.data)
            }

        }).catch((err) => {
        })
    }

    const getDriverCount = async () => {
        await axios.get(`${baseUrl}count-driver-status`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            if (response?.data?.message === 'success') {
                // dispatch(setAllDriver(response?.data))
                setDriverCount(response?.data?.data)
            }

        }).catch((err) => {
        })
    }

    const getTotalCount = async () => {
        await axios.get(`${baseUrl}fetch-total-counts`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
                // dispatch(setAllDriver(response?.data))
                setTotalCount(response?.data?.data)

        }).catch((err) => {
        })
    }

    useEffect(() => {
        getFilteredCount()
        getFilteredRequest()
        getDriverCount()
        getTotalCount()
    }, [])

    return (
        <>
            <FlexDiv>
                <Stats requestCount={requestCount}/>
                <StatsTwo vehicleCount={vehicleCount}/>
            </FlexDiv>
          
            {/* <Button>Cancelled</Button> */}

            <FlexDiv style={{marginTop:'40px'}}>
                <StatsThree totalCount={totalCount}/>
                <StatsFour driverCount={driverCount}/>
            </FlexDiv>

            <FlexDiv style={{marginTop:'80px'}}>
                <Ratings />
                <Reports />
            </FlexDiv>

            <H4>Members of Admin</H4>
            <Members/>
            <Members/>
            <Members/>

        </>
    )
}

export default DashboardInfo