import React, { FC, useEffect, useMemo, useRef, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { RequestDiv } from "./adminRequests.styles";

import Requests from "./requests";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setAllBooking,
  setFilterPagination,
  setFilterPaginationData,
  setPageNumber,
} from "../../../slices/admin";

import NoData from "./NoData";
import { baseUrl, useGetAdminRequestsQuery } from "../../../services/adminApi";
import {
  PaginationButton,
  PaginationDiv,
  PaginationLabel,
} from "../Drivers/adminDrivers.styles";
import axios from "axios";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import PaginationComponent from "../../paginationComponent";
import { setPaginationNum } from "../../../slices/pagination";
import AdminPagination from "../Pagination/pagination";

interface Request {
  book_status: string;
}

const AdminRequests: FC = () => {
  const user: any = useAppSelector((state: any) => state?.auth?.auth);

  const dispatch: any = useAppDispatch();
  const pageNumberStore: number = useAppSelector(
    (state: any) => state?.admin.currentPage
  );
  const [currentPage, setCurrentPage] = useState<number>(pageNumberStore);
  const [page, setPage] = useState<number>(10);

  useEffect(() => {
    dispatch(setFilterPaginationData(""));
    dispatch(setPageNumber(1));
  }, []);

  const getFiltered = async (data: string, pageNo: any) => {
    const promise = toast.loading("Loading...");

    await axios
      .get(`${baseUrl}filter-request-status/${data}?page=${pageNo}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          dispatch(setAllBooking(response?.data));
          dispatch(setFilterPagination(true));
          dispatch(setFilterPaginationData(data));
          setTimeout(() => {
            toast.dismiss(promise);
          }, 0);
          toast.success("Successfully Loaded!");
        }
      })
      .catch((err) => {});
  };

  // let promise: any;

  const getRequests = async () => {
    await axios
      .get(`${baseUrl}admin-list-request`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          dispatch(setAllBooking(response?.data?.data));
        }
      })
      .catch((err) => {});
  };

  //@ts-ignore
  const adminRequest = useAppSelector((state: any) => state?.admin?.allBooking);

  useEffect(() => {
    getRequests();
  }, []);

  const searchListRef: any = useRef();
  const goToListTop: any = () => {
    window.scrollTo({
      top: searchListRef?.current.offsetTop,
      behavior: "smooth",
    });
  };
  const ITEMS_PER_PAGE = 10; // Number of items to display per page

  const totalPages = Math.ceil(adminRequest?.length / ITEMS_PER_PAGE);

  // Calculate the range of pages to display in the pagination bar
  const pageRange = 3; // Adjust this value to control the number of page links displayed

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= pageRange * 2 + 1) {
      for (let i = 1; i <= totalPages; i++) {
        //@ts-ignore
        pageNumbers.push(i);
      }
    } else {
      const startPage = Math.max(currentPage - pageRange, 1);
      const endPage = Math.min(currentPage + pageRange, totalPages);

      if (startPage > 1) {
        //@ts-ignore
        pageNumbers.push(1, "..."); // Add ellipsis if not on the first page
      }

      for (let i = startPage; i <= endPage; i++) {
        //@ts-ignore
        pageNumbers.push(i);
      }

      if (endPage < totalPages) {
        //@ts-ignore
        pageNumbers.push("...", totalPages); // Add ellipsis if not on the last page
      }
    }
    return pageNumbers;
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
    setPageNumber(page);
  };

  const renderPagination = () => {
    const pageNumbers = getPageNumbers();
    return (
      <PaginationDiv>
        {pageNumbers.map((page, index) => (
          <PaginationButton
            key={index}
            className={`page-item ${
              page === "..." || page === currentPage ? "disabled" : ""
            }`}
            disabled={page === currentPage}
          >
            {page === "..." ? (
              <span className="page-link">...</span>
            ) : (
              <PaginationLabel onClick={() => handlePageClick(page)}>
                {page}
              </PaginationLabel>
            )}
          </PaginationButton>
        ))}
      </PaginationDiv>
    );
  };

  // Calculate the start and end indices for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  // Slice the data to display only the items for the current page
  const currentData = adminRequest?.slice(startIndex, endIndex);

  return (
    <>
      <Toaster />
      <RequestDiv style={{ marginTop: "45px" }} ref={searchListRef}>
        {currentData?.map((request: any, i) => {
          return <Requests key={request?.id} request={request} />;
        })}
      </RequestDiv>
      <AdminPagination data={adminRequest} />
      {/* {renderPagination()} */}
    </>
  );
};

export default AdminRequests;
