import styled from 'styled-components'

export const FundWalletComponentWrap = styled.div `
    display: ${(props:any) => props.display};
    position: relative;
`

export const BackBtnWrap = styled.div `
    /* border: solid 1px red; */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    top: ${(props:any) => props.top};
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 600;

    & img {
        width: 25px;
    }
`

export const H1 = styled.div `
    font-size: 16px;
    font-weight: 700;
    color: #09051C;

    @media only screen and (max-width: 454px) {
        font-size: 14px;
    }
`

export const AmountInputWrap = styled.div `
    margin: 15px 0;
    border-radius: 7px;
    padding: 13px 20px;
    background-color: #F6F6F6;
    box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.1);

    & label {
        color: #868686;
        padding: 5px 3px;
        font-size: 13px;
    }

    & input {
        background-color: transparent;
        outline: none;
        border: none;
        width: 100%;
        font-size: 16px;
        font-weight: 700;

        &::placeholder {
            color: #000;
            font-size: 16px;
            font-weight: 700;
        }
    }

    @media only screen and (max-width: 454px) {
        padding: 6px 15px;

        label {
            font-size: 9px;
            padding: 3px 1px;
        }

        input {
            font-size: 12px;

            &::placeholder {
                font-size: 12px;
            }
        }
    }
`

export const FundOptionsWrap = styled.div `
    margin: 50px 0;

    @media only screen and (max-width: 454px) {
        margin: 30px 0;
    }
`

export const FundWalletOption = styled.div `
    background-color: #fff;
    border-radius: 7px;
    box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`

export const PillRadioWrap = styled.div `
    margin-left: 10px;
    display: flex;
    align-items: center;
    gap: 15px;
`

export const CardTxt = styled.div `
    color: #868686;
    font-weight: 700;
    font-size: 13px;

    @media only screen and (max-width: 454px) {
        font-size: 10px;
    }
`

export const OptionsImgBox = styled.div `
    display: flex;
    align-items: center;
    padding: 13px 0;
    width: 120px;
    justify-content: center;
    gap: 10px;
    background-color: ${(props:any) => props.bg};
    border-radius: inherit;

    & img {
        width: 40px;
    }

    @media only screen and (max-width: 454px) {
        width: 100px;

        img {
            width: 20px;
        }
    }
`