import React, { useState } from 'react'
import FailedWalletComponent from '../failedWalletComponent'
import { ModalOverlay, CancelModalBox } from '../optionModal/optionmodal.styles'
import OtpWalletComponent from '../otpWalletComponent'
import SuccessWalletComponent from '../successWalletComponent'
import { WalletModalBox, WalletModalHeader, WalletContentWrap, RoadlersBlackCircle, RoadlersHomeCone } from '../walletModal/walletmodal.styles'
import WithdrawWalletComponent from '../withdrawWalletComponent'


import roadlersCone from '../../assets/images/svg/homebutton.svg'
import logo from '../../assets/images/svg/logo-small.svg'
import LittleLoader from '../littleLoader'

const WithdrawCurrencyModal = ({showWithdrawModal, removeWalletModal, provider, onWithdraw, withdrawAmount, setWithdrawAmount, withdrawBank, setWithdrawBank, withdrawAccountNo, setWithdrawAccountNo, withdrawAccountName, setWithdrawAccountName, withdrawErrorMessage, bankCode, setBankCode, showComponent, setShowComponent, otp, setOtp, onOtpVerify, isWithdrawLoading, otpIsLoading, otpError, withdrawData}:any) => {


    const handleShowComponent = (type: any) => {
        setShowComponent(type)
    }
  return (
    <ModalOverlay activeModal={showWithdrawModal}>
        <CancelModalBox onClick={() => {removeWalletModal(false)
            handleShowComponent('a')
        }}/>
        <WalletModalBox>
            <RoadlersHomeCone src={roadlersCone} />
            <RoadlersBlackCircle>
                <img src={logo} />
            </RoadlersBlackCircle>
            <div className="inner-modal-box">
                <WalletModalHeader>
                    <div className=""></div>
                </WalletModalHeader>

                {
                    isWithdrawLoading || otpIsLoading ? 
                    <LittleLoader /> :
                    <WalletContentWrap>
                        <OtpWalletComponent displaybox={showComponent === 'otp' ? 'block' : 'none'} handleShowComponent={handleShowComponent} otp={otp} setOtp={setOtp} onOtpVerify={onOtpVerify} otpError={otpError} />
                        <SuccessWalletComponent displaybox={showComponent === 'success' ? 'block' : 'none'} handleShowComponent={handleShowComponent}
                            amount={withdrawData?.data?.withdrawal_amount?.toLocaleString('en-US', {maximumFractionDigits: 2})}
                            walletText={"was successfully withdrawn"}
                        />
                        <FailedWalletComponent displaybox={showComponent === 'failed' ? 'block' : 'none'} handleShowComponent={handleShowComponent} 
                            amount={withdrawData?.data?.withdrawal_amount?.toLocaleString('en-US', {maximumFractionDigits: 2})}
                            walletText={"was not successfully withdrawn"}
                        />
                        <WithdrawWalletComponent displaybox={showComponent === 'a' ? 'block' : 'none'} handleShowComponent={handleShowComponent} onWithdraw={onWithdraw} withdrawAmount={withdrawAmount} setWithdrawAmount={setWithdrawAmount} withdrawBank={withdrawBank} setWithdrawBank={setWithdrawBank} withdrawAccountNo={withdrawAccountNo} setWithdrawAccountNo={setWithdrawAccountNo} withdrawAccountName={withdrawAccountName} setWithdrawAccountName={setWithdrawAccountName} withdrawErrorMessage={withdrawErrorMessage} bankCode={bankCode} setBankCode={setBankCode}/>
                    </WalletContentWrap>
                }

            </div>
        </WalletModalBox>

    </ModalOverlay>
  )
}

export default WithdrawCurrencyModal