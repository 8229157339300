import React, { FC, useEffect, useState } from "react";
import { Box, Button, H1, P, Div } from "./index.styles";
import image from "../../../../src/assets/images/png/create-wallet.png";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/header";

const CheckMail = () => {
  const navigate = useNavigate();

  const handleResend = () => {
    navigate("/forgot-password");
  };
  return (
    <>
      <Header />

      <Div>
        <img src={image} alt="" />
        <Box>
          <H1>Check Your Mail</H1>
          <P>A password reset link has been sent yo your mail</P>
          <Button onClick={() => handleResend()}>Resend Mail</Button>
        </Box>
      </Div>
    </>
  );
};

export default CheckMail;
