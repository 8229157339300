import React from 'react'
import { setShowDriverOption } from '../../slices/showDriverOption'
import { AppDispatch, RootState, useAppDispatch, useAppSelector } from '../../store'
import { CancelModalBox, ModalOverlay, OptionPill, PillRadioBtn } from '../optionModal/optionmodal.styles'
import { DriverModalBox } from '../driverOptionModal/driveroptionmodal.styles'

const DriverVehicleTypeModal = ({handleService, driverService, handleServiceName, serviceName, setChoiceDriver, isModalOpen, showModal}: any) => {
    const dispatch: AppDispatch = useAppDispatch()
    const {showDriverOption} = useAppSelector<any>((store:RootState) => store?.showDriverOption)

    const driverOptionModalControl = () => {
        dispatch(setShowDriverOption(false))
    }
    
    const driverOptions:any = [
        {type: 'Car Driving' },
        {type: 'Truck Driving'},
        {type: 'Bike Driving'},
    ]

    const handleServiceChoice = (value:any) => {
        handleService(value?.type)
        showModal(false)
    }


  return (
    <ModalOverlay activeModal={isModalOpen} >
        <CancelModalBox onClick={() => showModal(false)}/>
        <div className="modal-inner-wrapper">
            <DriverModalBox className={"driver-vehicle-type-box"}>
                <div className="inner-modal-box">
                    <h4>Type of Driver</h4>

                    {
                        driverOptions?.map((item:any, id:any) => {
                            return (
                                <OptionPill key={id} onClick={() => handleServiceChoice(item)}>
                                    <PillRadioBtn activePill={serviceName === item?.type ? 'fill' : ''} />
                                    <p>
                                    {item?.type}
                                    </p>
                                </OptionPill>
                            )
                        })
                    }

                </div>
            </DriverModalBox>
        </div>
    </ModalOverlay>
  )
}

export default DriverVehicleTypeModal