import React, { useEffect, useState } from 'react'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import { AssetOverall, BoxExtras, H11, PillWrapper, SearchBtn } from '../Homepage/homepage.styles'
import { BookingQuestionsWrap, CarouselImg, CarouselSection, ModalCallBtn, PP, QuestionBox, QuestionHeader, QuestionImg, SearchInput, SearchOverall, VehicleNameBox, VehiclePrice, ModalCallBtnWrap, BookingFooterWrap, BookingFooterBtn, FeatureWrapper, SubFeatureWrap, TickBox, SetHoursBox, HoursControlBox, WhereImg, QuestImg, BookingOverall, BookingFlexWrap, BookingExtrasFlexWrap } from '../CarBooking/CarBooking.styles'
import AssetCard from '../../../components/assetCard'
import ProgressBar from '../../../components/progressBar'
import OwnersNoteCard from '../../../components/ownersNoteCard'
import RoadlersCard from '../../../components/roadlersCard'
import OffersCard from '../../../components/offersCard'
import Carousel from '../../../components/carousel'
import Modal from '../../../components/modal'
import OptionsModal from '../../../components/optionModal'
import LocationModal from '../../../components/locationModal'
import { setShowLocation } from '../../../slices/showLocation'
import { AppDispatch, RootState, useAppDispatch, useAppSelector } from '../../../store'
import { setShowCarOption } from '../../../slices/showCarOption'
import TimeDateModal from '../../../components/timeDateModal'
import { setShowTimeDate } from '../../../slices/showTimeDate'
import { setShowExtraNote } from '../../../slices/showExtraNote'
import ExtrasModal from '../../../components/extrasModal'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import SearchComponent from '../../../components/searchComponent'
import { useGetVehicleByIdQuery, useGetVehicleListQuery } from '../../../services/customerApi'



import car1 from '../../../assets/images/svg/carousel1.svg'
import car2 from '../../../assets/images/svg/carousel2.svg'
import car3 from '../../../assets/images/svg/carousel3.svg'
import arrowDown from '../../../assets/images/svg/arrowDown.svg'
import whereTag from '../../../assets/images/svg/where-tag.svg'
import whenTag from '../../../assets/images/svg/when-tag.svg'
import noteTag from '../../../assets/images/svg/note-tag.svg'
import tickX from '../../../assets/images/svg/tickx-icon.svg'
import tickR from '../../../assets/images/svg/tickr-icon.svg'
import addArrow from '../../../assets/images/svg/addArrow.svg'
import subtractArrow from '../../../assets/images/svg/subtractArrow.svg'
import { addToCart, replaceCartItem } from '../../../slices/cart'
import uuid from 'react-uuid'
import { PaddingWrap } from '../CartPage/cartPage.styles'
import SearchUpdateComponent from '../../../components/searchUpdateComponent'






const EditCarBookingPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams()
  const vehicleId:any = searchParams.get('bookingId')
  const dispatch: AppDispatch = useAppDispatch()
  const {cart} = useAppSelector<any>((store:RootState) => store?.cart)
  const [vehicleInfoArray, setVehicleInfoArray] = useState<any>([])
  const [hours, setHours] = useState(1)
  const [month, setMonth] = useState(1)
  const [days, setDays] = useState(1)
  const [weeks, setWeeks] = useState(1)
  const [whenModalBtn, setWhenModalBtn] = useState(false)
  const [whereModalBtn, setWhereModalBtn] = useState(false)
  const [bookedDate, setBookedDate] = useState([])
  const [pickupTime, setPickupTime] = useState('')
  const [carOwnerInstruction, setCarOwnerInstruction] = useState('')
  const [escortDays, setEscortDays] = useState('')
  const [escortNumber, setEscortNumber] = useState('')
  const [progressbarNum, setProgressBarNum] = useState(1)
  const [progressInfo, setProgressInfo] = useState('Start Booking - Let’s Move')
  const [transportType, setTransportType] = useState('Within state')
  const [origin, setOrigin] = useState('')
  const [destination, setDestination] = useState('')
  const [isStateWithin, setIsStateWithin] = useState(true) 
  const [tripOptionsChosen, setTripOptionsChosen] = useState('Select option')
  const [tripChosenPrice, setTripChosenPrice] = useState<number>(0)
  const [bookingAmount, setBookingAmount] = useState(0)
  const [insuranceAmount, setInsuranceAmount] = useState<number>(0)
  const [insuranceType, setInsuranceType] = useState('')
  const [dateLength, setDateLength] = useState(6)
  const [vehicleList, setVehicleList] = useState<any>([])
  const [filterVehicleList, setFilterVehiclelist] = useState<any>([])
  const [editItem, setEditItem] = useState<any []>([])



  const {data, isFetching, refetch, error} = useGetVehicleByIdQuery(editItem?.[0]?.vehicle_id, {refetchOnMountOrArgChange:true})

  const {data: vehicleListData, isFetching: isVehicleListFetching, refetch: refetchVehiclelist, isSuccess: isVehicleListSuccess} = useGetVehicleListQuery({refetchOnFocus:true, refetchOnMountOrArgChange:true})

  //  Filtering out the item to be edited using it's booking id
  useEffect(() => {
    const cartResult = cart?.filter((item:any) => item?.booking_id === vehicleId)

    setEditItem(cartResult)
  }, [vehicleId])


  useEffect(() => {
    if(vehicleId > 0) {
      refetch()
    }
  }, [vehicleId])

  useEffect(() => {
    refetch()
    setVehicleInfoArray(data?.data?.[0])
  }, [vehicleId, vehicleInfoArray, data])

  useEffect(() => {
    refetchVehiclelist()
    setVehicleList(vehicleListData?.data)
  }, [vehicleListData, vehicleId, vehicleList])


  // This is setting the states to the previous booked options
  useEffect(() => {
    if(editItem) {
      setTripOptionsChosen(editItem?.[0]?.transport_options)
    }
    // setTransportType(editItem?.[0]?.state_location)
  }, [vehicleId, vehicleInfoArray, data, editItem])


  const slides:any = [
    {img: `${vehicleInfoArray?.vehicleData?.[0]?.image1}`, name: 'Main Image', id: 0},
    {img: `${vehicleInfoArray?.vehicleData?.[0]?.image2}`, name: 'Inner Image', id: 1},
    {img: `${vehicleInfoArray?.vehicleData?.[0]?.image3}`, name: 'Back Image', id: 2}
  ]

    // USEFFECT FOR FILTERING ALL RELATED VEHICLES
    useEffect(() => {
      let filtered = vehicleList;
  
      if(vehicleInfoArray?.vehicleData?.[0]?.vehicle_location) {
        filtered = filtered?.filter((item:any) => item?.vehicle_location?.toLowerCase()?.includes(vehicleInfoArray?.vehicleData?.[0]?.vehicle_location?.toLowerCase()))
      }
  
      if(vehicleInfoArray?.vehicleData?.[0]?.vehicle_type) {
        filtered = filtered?.filter((item:any) => item?.vehicle_type?.toLowerCase()?.includes(vehicleInfoArray?.vehicleData?.[0]?.vehicle_type?.toLowerCase()))
      }
  
      setFilterVehiclelist(filtered)
  
    }, [vehicleList, vehicleInfoArray])

  const locationModalControl = () => {
    dispatch(setShowLocation(true))
  } 

  const carOptionModalControl = () => {
    dispatch(setShowCarOption(true))
  }

  const timeDateModalControl = () => {
    dispatch(setShowTimeDate(true))
  }

  const extraModalControl = () => {
    dispatch(setShowExtraNote(true))
  }

  const handleIncreaseHours = () => {
    setHours(hours + 1)
  }

  const handleDecreaseHours = () => {
    if(hours > 6 ) {
      return setHours(hours - 1)
    }
  }

  const handleIncreaseDays = () => {
    setDays(days + 1)
  }

  const handleDecreaseDays = () => {
    if(days > 1 ) {
      return setDays(days - 1)
    }
  }

  const handleIncreaseMonth = () => {
    setMonth(month + 1)
  }

  const handleDecreaseMonth = () => {
    if(month > 1 ) {
      return setMonth(month - 1)
    }
  }

//   Changing the states of the modal buttons when a function has been completed so as to affect the UI to signify the users
  const handleLocationComplete= (itemComplete:any) => {
    setWhereModalBtn(itemComplete)
  }

  const handleTimeComplete = () => {
    setWhenModalBtn(true)
  }

  // USEFFECT FUNCTION TO DETERMINE THE PRICE BASED ON THE VEHICLE SERVICE PICKED
  useEffect(() => {
    if(tripOptionsChosen?.includes('Hourly Rate')) {
      setBookingAmount(tripChosenPrice * hours )
    }else if(tripOptionsChosen?.includes('Full Day Service')) {
      setBookingAmount(tripChosenPrice * days )
    }else if(tripOptionsChosen?.includes('Full Month Service')) {
      setBookingAmount(tripChosenPrice * month )
    }else if(tripOptionsChosen?.includes('Half Day Service')) {
      setBookingAmount(tripChosenPrice * bookedDate?.length )
    }else {
      setBookingAmount(tripChosenPrice )
    }

  }, [days, hours, month, days, weeks, tripOptionsChosen, tripChosenPrice, bookedDate])

//   This is checking the conditions before changing the states of the modalbuttons
  useEffect(() => {
    if(bookedDate?.length !== 0 && pickupTime?.length !== 0) {
        handleTimeComplete()
    }
  }, [bookedDate, pickupTime])

  useEffect(() => {
    if(whereModalBtn === true) {
      setProgressBarNum(progressbarNum + 1)
    }else {
      setProgressBarNum(progressbarNum)
    }
  }, [whereModalBtn])

//   This is checking whether the states of the modal btns are true before increasing the progress bar
  useEffect(() => {
    if(whenModalBtn === true) {
      setProgressBarNum(progressbarNum + 1)
    }else {
      setProgressBarNum(progressbarNum)
    }
  }, [whenModalBtn])

// This is checking the progress bar number to decide what progress text to display
  useEffect(() => {
    if(progressbarNum === 2) {
      setProgressInfo('Almost there - Keep Moving')
    }else if(progressbarNum === 3) {
      setProgressInfo('Completed - Proceed to Move with Ease')
    }
  }, [progressbarNum])

// This is checking the type of trip option chosen to determine the number of hours to set
useEffect(() => {
    if(tripOptionsChosen?.includes('Hourly Rates')) {
      setHours(6)
    }else {
      setHours(1)
    }
  }, [tripOptionsChosen])

  useEffect(() => {
    if(bookedDate?.length > 0) {
      if(tripOptionsChosen?.includes('Full Day Service')) {
        setDays(bookedDate?.length)
      }
    }
  }, [bookedDate, tripOptionsChosen, days])


  const handleSubmit = (e:any) => {
    e.preventDefault()

    if(progressbarNum === 3) {
      if(tripOptionsChosen?.includes('Hourly Rates')) {
        const bookingDetails:any = {
          'pickup_date': bookedDate,
          'pickup_time': pickupTime,
          'pickup_location': origin,
          'destination': destination,
          'escort_personel': escortNumber,
          'escort_days': escortDays,
          'passenger_instruction': carOwnerInstruction,
          'hours_needed': hours,
          'image': vehicleInfoArray?.vehicleData?.[0]?.image1,
          'transport_options': tripOptionsChosen,
          'category': 'Car Booking',
          'amount': (bookingAmount * bookedDate?.length) + insuranceAmount,
          'insurance': insuranceType,
          'insurance_amount': insuranceAmount,
          'state_location': transportType,
          'plate_number': vehicleInfoArray?.vehicleData?.[0]?.plate_number,
          'vehicle_id': vehicleInfoArray?.vehicleData?.[0]?.id,
          'provider_id': vehicleInfoArray?.vehicleData?.[0]?.user_id,
          'booking_title': vehicleInfoArray?.vehicleData?.[0]?.vehicle_name,
          'vehicle_year': vehicleInfoArray?.vehicleData?.[0]?.year,
          'booking_id': vehicleId,
          'total': tripChosenPrice + insuranceAmount,
          'booking_price': bookingAmount,
          'tripPrice': tripChosenPrice,
          'num_of_day': bookedDate?.length,
          'book_picture': vehicleInfoArray?.vehicleData?.[0]?.image1,
        }

        dispatch(replaceCartItem(bookingDetails))
        navigate('/cart')
      }else {
        const bookingDetails:any = {
          'pickup_date': bookedDate,
          'pickup_time': pickupTime,
          'pickup_location': origin,
          'destination': destination,
          'escort_personel': escortNumber,
          'escort_days': escortDays,
          'passenger_instruction': carOwnerInstruction,
          'image': vehicleInfoArray?.vehicleData?.[0]?.image1,
          'transport_options': tripOptionsChosen,
          'category': 'Car Booking',
          'amount': bookingAmount + insuranceAmount,
          'insurance': insuranceType,
          'insurance_amount': insuranceAmount,
          'state_location': transportType,
          'plate_number': vehicleInfoArray?.vehicleData?.[0]?.plate_number,
          'vehicle_id': vehicleInfoArray?.vehicleData?.[0]?.id,
          'provider_id': vehicleInfoArray?.vehicleData?.[0]?.user_id,
          'booking_title': vehicleInfoArray?.vehicleData?.[0]?.vehicle_name,
          'vehicle_year': vehicleInfoArray?.vehicleData?.[0]?.year,
          'booking_id': vehicleId,
          'total': tripChosenPrice + insuranceAmount,
          'booking_price': bookingAmount,
          'tripPrice': tripChosenPrice,
          'num_of_day': bookedDate?.length,
          'book_picture': vehicleInfoArray?.vehicleData?.[0]?.image1,
        }

        dispatch(replaceCartItem(bookingDetails))
        navigate('/cart')
      }
  
    }else {

    }
  }


  // EDIT INSURANCE ITEMS INFORMATION
  useEffect(() => {
    if(editItem) {
      setCarOwnerInstruction(editItem?.[0]?.passenger_instruction)
      setInsuranceAmount(editItem?.[0]?.insurance_amount)
      setInsuranceType(editItem?.[0]?.insurance)
      setBookingAmount(editItem?.[0]?.total)
    }
  }, [editItem])




  return (
    <div>
        <OptionsModal setTripOptions={setTripOptionsChosen} tripOptionChoice={tripOptionsChosen} setPrice={setTripChosenPrice} setHours={setHours} previousPrice={editItem?.[0]?.total} tripOptionsList={vehicleInfoArray?.serviceData}  />

        <LocationModal setLocationBtn={handleLocationComplete} setOrigin={setOrigin} handleDestination={setDestination} setTransportType={setTransportType} previousOrigin={editItem?.[0]?.pickup_location} previousDestination={editItem?.[0]?.destination} withinOrWithout={editItem?.[0]?.state_location}/>

        <TimeDateModal handleBookedDate={setBookedDate} handlePickupTime={setPickupTime} previousPickupDate={editItem?.[0]?.pickup_date} previousPickupTime={editItem?.[0]?.pickup_time}/>

        <ExtrasModal handleInstruction={setCarOwnerInstruction} instruction={carOwnerInstruction} setEscortNum={setEscortNumber} setEscortDay={setEscortDays} insuranceAmount={insuranceAmount} setInsuranceAmount={setInsuranceAmount} insuranceType={insuranceType} setInsuranceType={setInsuranceType} insuranceLabel={'Would you like to be covered by insurance on this trip?'} editItem={editItem}/>
        <Header />

        {/* <SearchComponent /> */}

        <PaddingWrap>
          <SearchUpdateComponent />
        </PaddingWrap>

        <BookingFlexWrap>
          <CarouselSection bg={'#fff'}>
            <Carousel 
              linkPresent={true}
              slides={slides} 
              display={'block'}
              priceDisplay={'block'}
              title={`${editItem?.[0]?.booking_title} ${editItem?.[0]?.vehicle_year}`}  
              displayAmount={`${Number(editItem?.[0]?.total).toLocaleString('en-US', {maximumFractionDigits: 2})}`}
            />
          </CarouselSection>

          <BookingOverall>
          <div className="booking-suboverall" style={{padding: '30px', paddingRight: '80px'}}>
            <ProgressBar 
              progressText={progressInfo}
              barDisplay={'flex'}
              progressNum={progressbarNum}
            />

            <BookingQuestionsWrap>
              <QuestionHeader>
                How long do you need this vehicle?
              </QuestionHeader>
              <QuestionBox>

                <PP>{tripOptionsChosen}</PP>

                {
                  tripOptionsChosen?.includes('Hourly Rate') && 
                  <SetHoursBox>
                    <h2>{hours}</h2>
                    <p>Hours</p>

                    <HoursControlBox>
                      <img src={addArrow} onClick={handleIncreaseHours} />
                      <img src={subtractArrow} onClick={handleDecreaseHours} />
                    </HoursControlBox>
                  </SetHoursBox>
                }

                {
                  tripOptionsChosen?.includes('Full Day Service') && 
                  <SetHoursBox>
                    <h2>{days}</h2>
                    <p>{days === 1 && 'Day'} {days > 1 && 'Days'}</p>

                    <HoursControlBox>
                      <img src={addArrow} onClick={handleIncreaseDays} />
                      <img src={subtractArrow} onClick={handleDecreaseDays} />
                    </HoursControlBox>
                  </SetHoursBox>
                }

                {
                  tripOptionsChosen?.includes('Full Month Service') && 
                  <SetHoursBox>
                    <h2>{month}</h2>
                    <p>{month === 1 && 'Month'} {month > 1 && 'Months'}</p>

                    <HoursControlBox>
                      <img src={addArrow} onClick={handleIncreaseMonth} />
                      <img src={subtractArrow} onClick={handleDecreaseMonth} />
                    </HoursControlBox>
                  </SetHoursBox>
                }


                <WhereImg src={arrowDown} onClick={() => carOptionModalControl()}  width={'50px'}/>

              </QuestionBox>

              <ModalCallBtnWrap>
                <ModalCallBtn complete={whereModalBtn} onClick={() => locationModalControl()}>
                  <WhereImg src={whereTag} width={'30px'} />

                  <p>Where?</p>
                </ModalCallBtn>

                <ModalCallBtn complete={whenModalBtn} onClick={() => timeDateModalControl()}>
                  <QuestImg complete={whenModalBtn} src={whenTag} width={'30px'} />

                  <p>When?</p>
                </ModalCallBtn>

                <ModalCallBtn complete={carOwnerInstruction || insuranceAmount ? true : false} onClick={() => extraModalControl()}>
                  <QuestImg complete={carOwnerInstruction || insuranceAmount ? true : false} src={noteTag} width={'25px'} />

                  <p>Extra/Note</p>
                </ModalCallBtn>
              </ModalCallBtnWrap>
            </BookingQuestionsWrap>

            <BookingFooterWrap>
              {
                <BookingFooterBtn text={'#fff'} bordercolor={'#fff'} bg={'#000'}>
                  ₦{`${(bookingAmount + insuranceAmount)?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}
                </BookingFooterBtn>
              }

              <BookingFooterBtn text={'#000'} bordercolor={'#ffa100'} bg={'#ffa100'} onClick={handleSubmit}>
                Proceed Booking
              </BookingFooterBtn>
            </BookingFooterWrap>

            {/* <BookingFooterBtn text={'#fff'} bordercolor={'#fff'} bg={'#000'} >
                Cancel
            </BookingFooterBtn> */}
          </div>
          </BookingOverall>
        </BookingFlexWrap>

        <BookingExtrasFlexWrap>
          <FeatureWrapper>
            <h4>Feature</h4>

            <SubFeatureWrap>
              <TickBox>
                <QuestionImg width={'15px'} src={tickX} />
              </TickBox>

              <p>Air Conditioner Working</p>
            </SubFeatureWrap>

            <SubFeatureWrap>
              <TickBox>
                <QuestionImg width={'15px'} src={tickR} />
              </TickBox>

              <p>Available for travel </p>
            </SubFeatureWrap>

            <SubFeatureWrap>
              <TickBox>
                <QuestionImg width={'15px'} src={tickR} />
              </TickBox>

              <p>Available Per Hour</p>
            </SubFeatureWrap>

            <SubFeatureWrap>
              <TickBox>
                <QuestionImg width={'15px'} src={tickX} />
              </TickBox>

              <p>Comes with a driver </p>
            </SubFeatureWrap>

            <SubFeatureWrap>
              <TickBox>
                <QuestionImg width={'15px'} src={tickX} />
              </TickBox>

              <p>Fully fuelled.</p>
            </SubFeatureWrap>

            <SubFeatureWrap>
              <TickBox>
                <QuestionImg width={'15px'} src={tickX} />
              </TickBox>

              <p>Available for self drive</p>
            </SubFeatureWrap>

            <SubFeatureWrap>
              <TickBox>
                <QuestionImg width={'15px'} src={tickX} />
              </TickBox>

              <p>Available for overnight trips</p>
            </SubFeatureWrap>

            <SubFeatureWrap>
              <TickBox>
                <QuestionImg width={'15px'} src={tickX} />
              </TickBox>

              <p>Comprehensive insurance</p>
            </SubFeatureWrap>

            <SubFeatureWrap>
              <TickBox>
                <QuestionImg width={'15px'} src={tickX} />
              </TickBox>

              <p>Verified </p>
            </SubFeatureWrap>
          </FeatureWrapper>

          <BoxExtras className="booking-section">
            <OwnersNoteCard />

            <RoadlersCard />
          </BoxExtras>
        </BookingExtrasFlexWrap>

        
        <BoxExtras>
            <div className="subsection-header">
                <H11>
                  Related Offers
                </H11>
            </div>

            <AssetOverall>
                {
                  filterVehicleList?.slice(0, 4)?.map((item:any, id:any) => {
                    return (
                      <AssetCard 
                      item={item}
                      firstPrice={item?.final_normal_price}
                      discountPrice={item?.final_discounted_price} 
                      discount={item?.discount}
                      name={item?.vehicle_name}
                      locate={item?.vehicle_location || item?.state}
                      vehicleImg={item?.image1}
                      vehicleId={item?.id}
                      key={id}
                      year={item?.year}
                      passengerNum={item?.num_passenger}
                      rating={item?.rating}
                      likes={item?.likes}
                      />
                    )
                  })
                }
                <OffersCard />
                <OffersCard />
            </AssetOverall>

        </BoxExtras>

        <Footer />
    </div>
  )
}

export default EditCarBookingPage