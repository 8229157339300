import React, { FC, useEffect, useState, useRef } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import { IoCarSport } from 'react-icons/io5';
import { BiUser } from 'react-icons/bi';
import { RiDashboard3Line } from 'react-icons/ri';
import { BsCalendar2Minus } from 'react-icons/bs';
import { GiReceiveMoney } from 'react-icons/gi';


import {
    DriverCounterBox,
    Page,
} from './customers.styles';



import { Navigate, useNavigate } from 'react-router-dom';
import ProviderBalance from '../../../components/providerDashboard/providerBalance/providerBalance';
import ProviderActions from '../../../components/providerDashboard/providerActions/providerActions';
import AdminHeader from '../../../components/adminHeader/';
import DashboardInfo from '../../../components/Admin/DahboardInfo';
import Counter from '../../../components/Admin/Counter';
import Filter from '../../../components/Admin/Filter';
import ButtonGroups from '../../../components/Admin/ButtonGroups';
import Vehicles from '../../../components/Admin/Vehicles';
import CounterFilter from '../../../components/Admin/CounterFilter';
import RequestButtonGroups from '../../../components/Admin/RequestButtonGroup';

import AdminCustomers from '../../../components/Admin/Customers/adminCustomers';
import LinkHeader from '../../../components/Admin/Header/header';
import { PagePadding } from '../Dashboard/dashboard.styles';

const ListCustomers: FC = () => {
    return (
        <>
            <AdminHeader />
            <Page>
            <PagePadding className='padding'>


                    <LinkHeader />

                    <DriverCounterBox>
                        <Counter driver={false} request={false} vehicle={false} provider={false} customer={true} />
                        <CounterFilter driver={false} request={false} vehicle={false} provider={false} customer={true} />
                    </DriverCounterBox>
                    <Filter vehicle={false} request={false} driver={false} provider={false} customer={true} />
                    <ButtonGroups />
                    <AdminCustomers />
                </PagePadding>
            </Page>
        </>
    )
}
export default ListCustomers