import styled from 'styled-components'

export const Page = styled.div`
    position: relative;
    min-height: 100vh;
    width: 100vw;
    place-items: center;
    transition: .5s;
    background-color: #242321;
padding:1.3em;

`

export const AbsoluteDiv = styled.div`
display:flex;
align-items:center;
height:100px;
border-radius:0px 0px 15px 15px;
gap:20px;
background:#fff;
width:100%;
position: relative;
padding:0 1.2em;
margin-top:-50px;

`
export const IconDiv = styled.div`
display:flex;
align-items:center;
justify-content:center;
font-size:25px;
font-weight:400;
height:45px;
width:58px;
border-radius:7px;
gap:5px;
background:#000;
flex:1;
position: relative;
top:50px;

`
export const P = styled.p`
color:#fff;
position:absolute;
font-size:11px;
top:37px;
`
export const H5 = styled.h5`
color:#fff;
font-weight:600;
font-size:11px;
`
export const FlexBox = styled.div`
padding:0.3em 1em;
background: #868686;
height:40px;
align-items:center;
justify-content:space-between;
display:flex;
gap:10px;
border-radius:5px;

`


export const FlexDiv = styled.div`
 display:flex;
width:100%;
justify-content:space-between;
padding:.6em 2em;
background: #CECECE;
border-radius:8px;
margin-top:20px;
align-items:center;
`
export const Div = styled.div`
padding:0em .5em;
margin-top:30px;
`
export const PaymentHistoryWrapper = styled.div`
margin-bottom:30px;
`

export const H6 = styled.h6`
color:#000;
font-size:14px;
margin:0;
font-weight:bold;

`
export const Button = styled.button`
padding:.4em 1.5em;
font-size:14px;
background: #FFFFFF;
border:none;
font-weight:bold;
border-radius:8px;
box-shadow: 0px 4px 8px 0px #00000014;
color:#000;
 &:focus{
    color:red;
 }
`


