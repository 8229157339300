import React from 'react'
import { BigBox, ExtrasActionWrap, SuccessImg, TextBox } from '../successWalletComponent/successwallet.styles'
import { FailedWalletWrap } from './faliedwallet.styles'

import failed from '../../assets/images/png/failed-wallet.png'
import clock from '../../assets/images/svg/clock-history.svg'


const FailedWalletComponent = ({displaybox, amount, walletText}:any) => {
  return (
    <FailedWalletWrap display={displaybox}>
        <BigBox>
            <TextBox>
                <h2>NGN {amount}</h2>
                <p>{walletText} Check your balance or try again </p>
            </TextBox>

            <SuccessImg src={failed} />
        </BigBox>

        <ExtrasActionWrap bg={'rgba(241, 3, 3, 0.22)'}>
            <p>Try Again</p>
        </ExtrasActionWrap>

        <ExtrasActionWrap bg={'#FFD58C'}>
            <p>Go to history</p>

            <img src={clock}/>
        </ExtrasActionWrap>
    </FailedWalletWrap>

  )
}

export default FailedWalletComponent