// export const baseImageUrl = 'https://testapi.roadlers.com/storage/'
export const baseImageUrl = 'https://testapi.roadlers.com/storage/'


export const formatCurrencyPrefix = (location:string) => {
    if (location === "NG") {
      return "₦";
    } else if (location === "CA") {
      return "$";
    } else {
      return "$";
    }
  };