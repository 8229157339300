import React, { FC, useEffect, useState } from 'react'
import Customer from '../Customers/customer'
import { BackgroundBox, Button, H3, ModalBox } from './callcustomer.styles'
import { Link } from 'react-router-dom'
import { useAdminCustomerDataMutation } from '../../../services/adminApi'


type Props = {
    setShowCustomer: any;
    customerId: string;
}
const CallCustomer: FC<Props> = ({ customerId, setShowCustomer }) => {

    let [customerInfo, setCustomerInfo] = useState([])

    // const providerRequest = useAppSelector((state: any) => state?.provider.allRequest)


    const [customerData, { data, isLoading, isSuccess, isError, error }] = useAdminCustomerDataMutation()
    useEffect(() => {
        if (isLoading) {
            // setBtnLoad(true)
        }
        if (isSuccess) {
            // setBtnLoad(false)
            setCustomerInfo(data?.data)
        }


        if (isError && 'status' in error!) {
            if (isError && error.status.toString() === 'FETCH_ERROR') {
                // notifyError('Network Connection Failed')
            }
            // setBtnLoad(false)
        }
    }, [customerId])


    const handleGetCustomer = () => {
        let formData: any = new FormData()
        formData.append('customer_id', customerId)



        customerData(formData)

    }
    useEffect(() => {
        handleGetCustomer()
    }, [customerId])


    // const dispatch = useAppDispatch()

    // useEffect(() => {
    //     refetchCustomer()
    //     // dispatch(setAllRequest(providerRequests?.data))
    //     // dispatch(setRequestCount(totalMessageCount))
    // }, [refetchCustomer, customerData])


    // const notifyError = (text: any) => toast.error(text, {
    //     position: "bottom-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    // })

    return (
        <>
            <BackgroundBox onClick={() => setShowCustomer(false)}>

            <ModalBox>
                <H3>Customer Data</H3>
                <Customer driverDetails={customerInfo} isModal={true} isCustomer={true}/>


                {/* <Button> */}
                    {/* <a href={`tel: ${customerInfo?.phone_number}`}>
                        Call Client

                    </a> */}
                   
                {/* </Button> */}
                
            </ModalBox>
            </BackgroundBox>

        </>
    )
}

export default CallCustomer