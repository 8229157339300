import React, { useState } from 'react'
import { FilterSearchBtn, InputOverall, SearchWrapper, UserImageWrap, UserStateWrap, VehicleTypeInput, VehicleYearInput } from './searchUpdate.styles'


import locationicon from '../../assets/images/svg/location-black.svg'
import searchIcon from '../../assets/images/svg/header-search-icon.svg'
import userImg from '../../assets/images/svg/header-search-user.svg'
import filterIcon from '../../assets/images/svg/grey-filter.svg'
import { createSearchParams, useNavigate } from 'react-router-dom'
import LocationInputModal from '../locationSearchModal'
import { RootState, useAppDispatch, useAppSelector } from '../../store'
import SearchLocationInputModal from '../searchModal'
import { setSearchData } from '../../slices/showCarOption'

const SearchUpdateComponent = () => {
    const navigate = useNavigate() 
    const {currentLocation} = useAppSelector<any>((store: RootState) => store?.currentLocation)
    const [location, setLocation] = useState(currentLocation?.city)
    const [value, setValue] = useState('')
    const [showModal, setShowModal] = useState(false)
    // const params = {vehicleLocation:`${location}`, inputValue: `${value}` }
     const dispatch = useAppDispatch()

    const goToSearch = (e:any) => {
      e.preventDefault()

        if (location?.length !== 0 && value?.length !== 0 ) {
          dispatch(setSearchData(value))
          // navigate({
          //   pathname: '/search-page',
          //   search: `?${createSearchParams(params).toString()}`
          // })
          navigate("/search-page")
        }
    }

  return (
    <>
    <SearchWrapper>
        <UserImageWrap>
            <img src={userImg} />
        </UserImageWrap>

        <form className="inner-search-wrap" onSubmit={goToSearch}>
            <InputOverall>
                <VehicleTypeInput placeholder='What Vehicle? | Model' value={value} onChange={(e:any) => setValue(e.target.value)}/>
            </InputOverall>

            <UserStateWrap onClick={() => setShowModal(true)}>
                <img src={locationicon} />

                <p>{location}</p>

                <div className="absolute-txt">Where?</div>
            </UserStateWrap>

            <FilterSearchBtn type='submit'>
                {/* <img src={filterIcon} /> */}
                <img src={searchIcon} />
                <div className="absolute-txt">Search</div>
            </FilterSearchBtn>
        </form>
    </SearchWrapper>

    <SearchLocationInputModal 
        location={location}
        setLocation={setLocation}
        showModal={showModal}
        setShowModal={setShowModal}
    />
    </>
  )
}

export default SearchUpdateComponent