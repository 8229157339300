import React, { useEffect, useRef, useState } from "react";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import {
  GarageHead,
  GarageHeaderImgWrap,
  GarageHeadImage,
  GarageHeadTextWrap,
  H1,
  InputDateWrap,
  InputFlexWrap,
  LatestListingWrap,
  SearchTicketBtn,
  TicketSearchWrap,
  TicketTypePill,
  VehicleBox,
  VehicleImgBox,
} from "./travelgarage.styles";
import {
  InputIcons,
  WhereOptionPill,
  WhereRadioPill,
} from "../../../components/locationModal/locationmodal.styles";
import { HorizontalLine } from "../../../components/travelGarageBox/garagebox-styles";

import map from "../../../assets/images/png/garage-map.png";
import saloon from "../../../assets/images/svg/saloon-cars.svg";
import suv from "../../../assets/images/svg/suv-cars.svg";
import location from "../../../assets/images/svg/location-black.svg";
import calendar from "../../../assets/images/svg/grey-calendar.svg";
import GarageListingCard from "../../../components/garageListingCard";
import { NavLink } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import AddTravelModal from "../../../components/travelGarage/addNewTravel";
import {
  baseUrl,
  useGetTravelGarageQuery,
} from "../../../services/providerApi";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import NoticeModal from "../../../components/travelGarage/noticePopup";
import { ToastContainer, toast } from "react-toastify";
import { setGarage, setTravels } from "../../../slices/travelPassengers";
import DeleteTravelModal from "../../../components/travelGarage/deletePopup";
import { BiCalendar } from "react-icons/bi";

import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";

const TravelGaragePage = () => {
  const user = useAppSelector((state: any) => state?.auth.auth);

  const { travels } = useAppSelector((state: any) => state?.travel);
  const garage = useAppSelector((state: any) => state?.travel?.garage);

  let [isAddModal, setIsAddModal] = useState(false);
  let [isEditModal, setIsEditModal] = useState(false);
  let [isDeleteAction, setIsDeleteAction] = useState(false);
  const [toWhere, setToWhere] = useState<any>("");
  const [fromWhere, setFromWhere] = useState<any>("");
  const [travelDate, setTravelDate] = useState<any>("");
  const [scroll, setScroll] = useState(false);
  const [loneTravel, setLoneTravel] = useState<any>();
  const travelRef: any = useRef();
  const dispatch: any = useAppDispatch();

  const {
    data: travelGarage,
    isFetching: isFetchingTravelGarage,
    refetch: refetchTravelGarage,
  } = useGetTravelGarageQuery();

  useEffect(() => {
    refetchTravelGarage();
  }, [travelGarage]);

  const [filterTravelList, setFilterTravellist] = useState<any>(garage);
  const [editTravel, setEditTravel] = useState<any>();
  useEffect(() => {
    if (travelGarage && travelGarage?.data) {
      // setFilterTravellist(garage);
      dispatch(setGarage(travelGarage?.data));
    }
  }, [garage, filterTravelList, travelGarage]);

  useEffect(() => {
    let filtered = garage;

    if (fromWhere) {
      filtered = filtered?.filter((item: any) =>
        item?.from?.toLowerCase()?.includes(fromWhere?.toLowerCase())
      );
    }
    if (toWhere) {
      filtered = filtered?.filter((item: any) =>
        item?.to?.toLowerCase()?.includes(toWhere?.toLowerCase())
      );
    }

    if (travelDate) {
      filtered = filtered?.filter((item: any) =>
        item?.departure_date?.includes(travelDate)
      );
    }

    // travelDate
    setFilterTravellist(filtered);
  }, [fromWhere,toWhere, travelDate]);

  if (scroll) {
    setTimeout(() => {
      travelRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start", // or 'end'
      });
    }, 0);
  }

  console.log("filterTravelList",filterTravelList)
  console.log("garage",garage)

 console.log(travelDate)

  const handleCancel = (travelDetails) => {
    setIsDeleteAction((prev) => !prev);
    setLoneTravel(travelDetails);
  };
  const handleClearFilter = () => {
    setFilterTravellist(garage);
    setToWhere("");
    setFromWhere("");
    setTravelDate("");
  };

  const handleEdit = (travelDetails) => {
    setIsEditModal((prev) => !prev);
    setEditTravel(travelDetails);
  };
  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

 


  return (
    <>
      <Header />
      <ToastContainer />

      <GarageHead>
        <GarageHeadImage src={map} />
        <GarageHeadTextWrap>
          <h3>Roadlers</h3>

          <h1>Travel Garage</h1>
        </GarageHeadTextWrap>

        <GarageHeaderImgWrap>
          <VehicleBox>
            <VehicleImgBox boxBg={"#000"}>
              <img src={saloon} />
            </VehicleImgBox>

            <p>Saloon Cars</p>
          </VehicleBox>

          <VehicleBox>
            <VehicleImgBox boxBg={"#0E0D0D"}>
              <img src={suv} />
            </VehicleImgBox>

            <p>SUV Cars</p>
          </VehicleBox>
        </GarageHeaderImgWrap>
      </GarageHead>

      <TicketSearchWrap>
        <div className="subwrapper">
          <div>
            <label>From</label>
            <InputFlexWrap>
              <InputIcons src={location} iconWidth={"20px"} />
              <input
                type="text"
                placeholder="City | Region | State"
                onChange={(e: any) => {
                  setFromWhere(e.target.value);
                }}
                value={fromWhere}
              />
            </InputFlexWrap>
          </div>

          <div>
            <label>When</label>
            <InputFlexWrap>
            <BiCalendar style={{ color: "#000", position: "absolute" }} />

              <DatePicker
                value={travelDate}
                onChange={setTravelDate}
                inputPlaceholder="Select a day"
                shouldHighlightWeekends
              />

            </InputFlexWrap>
          </div>
        </div>

       

        <div className="subwrapper">
          <div>
            <label>To</label>
            <InputFlexWrap>
              <InputIcons src={location} iconWidth={"20px"} />
              <input
                type="text"
                placeholder="City | Region | State"
                onChange={(e: any) => {
                  setToWhere(e.target.value);
                }}
                value={toWhere}
              />
            </InputFlexWrap>
          </div>

          <SearchTicketBtn onClick={() => handleClearFilter()}>
            Clear Filter
          </SearchTicketBtn>
        </div>

        <div className="ticket-type-wrap">
          <TicketTypePill>
            <WhereRadioPill pillActive={"ddd"} />

            <p>Share Ride</p>
          </TicketTypePill>

          <TicketTypePill>
            <WhereRadioPill pillActive={""} />

            <p>Rent a Car</p>
          </TicketTypePill>
        </div>
      </TicketSearchWrap>

      <LatestListingWrap>
        <div className="subsection-header">
          <H1>Latest Listing</H1>
          <HorizontalLine />
        </div>

        {filterTravelList?.map((travel: any) => {
          return (
            <div key={travel?.id}>
              <NavLink
                ref={travelRef}
                to={`/ticket-page/${travel?.id}/${travel?.vehicle_id}`}
              >
                <GarageListingCard travel={travel} />
              </NavLink>

              {travel?.provider_id == user?.id && (
                <div className="action-div">
                  <button className="edit" onClick={() => handleEdit(travel)}>
                    Edit
                  </button>

                  <button
                    className="cancel"
                    onClick={() => handleCancel(travel)}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          );
        })}

        <div
          className="float-div"
          onClick={() => setIsAddModal((prev) => !prev)}
        >
          <div className="float-btn">
            <FaPlus className="icon" />
          </div>
        </div>
      </LatestListingWrap>

      {isDeleteAction && (
        <DeleteTravelModal
          loneTravel={loneTravel}
          setIsDeleteAction={setIsDeleteAction}
        />
      )}

      {isAddModal && (
        <>
          {user?.user_role === "provider" ? (
            <AddTravelModal setIsAddModal={setIsAddModal} />
          ) : (
            <NoticeModal setIsAddModal={setIsAddModal} />
          )}
        </>
      )}

      {isEditModal && (
        <>
          <AddTravelModal
            setIsAddModal={setIsEditModal}
            editTravel={editTravel}
            edit={true}
          />
        </>
      )}

      <Footer />
    </>
  );
};

export default TravelGaragePage;
