import React, { useState } from 'react'
import { ModalOverlay, ModalBox, OptionPill, PillRadioBtn, CancelModalBox } from '../optionModal/optionmodal.styles'
import ValueChangeBox from '../valueChangeBox'
import { ExtraAmtWrap, ExtraInput, ExtraInputWrap, ExtrasAccordionWrap, ExtrasModalBox, InputBoxOverall, InstructionBox, SaveBtn } from './extrasmodal.styles'


import topArrow from '../../assets/images/svg/chevron-up.svg'
import cancelbtn from '../../assets/images/svg/cancel-icon-yellow.svg'
import bottomArrow from '../../assets/images/svg/chevron-down.svg'
import { AppDispatch, RootState, useAppDispatch, useAppSelector } from '../../store'
import { setShowExtraNote } from '../../slices/showExtraNote'
import InsuranceChoiceBox from '../insuranceChoiceBox'

const ExtrasModal = ({handleInstruction, instruction, setEscortNum, setEscortDay, setInsuranceAmount, insuranceAmount, insuranceType, setInsuranceType, insuranceLabel}:any) => {
    const dispatch: AppDispatch = useAppDispatch()
    const {showExtraNote} = useAppSelector<any>((store:RootState) => store?.showExtraNote)
    const [escortNumber, setEscortNumber] = useState(0)
    const [escortDays, setEscortDays] = useState(0)
    const [choice, setChoice] = useState('0')
    const [clicked, setClicked] = useState("0")

    const handleToggle = (index:any) => {
        if(clicked === index) {
            return setClicked("0")

        }

        setClicked(index)
    } 

    const clearInsurance = () => {
        setChoice("0")
        setInsuranceAmount(0)
        setInsuranceType('')
    }

    const handleInsuranceChosen = (item:any) => {
        if(choice === item?.id) {
            return clearInsurance()
        }

        setChoice(item?.id)
        setInsuranceAmount(item?.price)
        setInsuranceType(item?.type)
    }

    const extraModalControl = () => {
        dispatch(setShowExtraNote(false))
    }

    const increaseEscortPerson = () => {
        setEscortNumber(escortNumber + 1)
    }
    const decreaseEscortPerson = () => {
        if(escortNumber > 0) {
            setEscortNumber(escortNumber - 1)
        }
    }

    const increaseEscortDays = () => {
        setEscortDays(escortDays + 1)
    }
    const decreaseEscortDays= () => {
        if(escortDays > 0) {
            setEscortDays(escortDays - 1)
        }
    }

    const handleSubmitExtra = () => {
        setEscortNum(escortNumber)
        setEscortDay(escortDays)
        extraModalControl()
    }

    const insuranceList:any = [
        {id: '1', type: 'Basic cover for this trip', info: 'Sum Insured 300,000 naira - Death, CI, TTD, TPD and medical expenses of 50,000 naira ', price: 500},
        {id: '2' ,type: 'Standard cover for this trip', info: 'Sum Insured 800,000 naira - Death, CI, TTD, TPD and medical expenses of 100,000 naira.', price: 1250},
        {id: '3', type: 'Premium cover for this trip', info: 'Sum Insured 2,000,000 naira - Death, CI, TTD, TPD and medical expenses of 150,000 naira', price: 3000},
    ]

  return (
    <ModalOverlay activeModal={showExtraNote} >
        <CancelModalBox onClick={() => extraModalControl()}/>
        <div className="modal-inner-wrapper">
            <ExtrasModalBox>
                <div className="inner-modal-box">
                    <div className="header-box-wrap">
                        <div className="left-section">
                            <h3>Opt for more services</h3>
                            <hr />
                        </div>

                        <button onClick={() => dispatch(setShowExtraNote(false))}>
                            <p>
                                {
                                    insuranceAmount || instruction ? 
                                    "Save":
                                    "Skip"
                                }
                            </p>
                        </button>
                    </div>

                    {/* <InputBoxOverall>
                        <label>Do you need a security escort on this trip?</label>
                        <ExtraInputWrap>
                            <ExtraInput placeholder="Security escort " />
                            <ValueChangeBox imgTrue={true} escortNum={escortNumber} handleAddPerson={increaseEscortPerson} handleSubtractPerson={decreaseEscortPerson} />
                            <ValueChangeBox escortDays={escortDays} handleAddDay={increaseEscortDays} handleSubtractDay={decreaseEscortDays} />
                        </ExtraInputWrap>

                        <div className="amount-wrap-overall">
                            <ExtraAmtWrap>
                                ₦0.00
                            </ExtraAmtWrap>
                        </div>
                    </InputBoxOverall> */}

                    <InputBoxOverall>
                        <label>{insuranceLabel}</label>
                        <div className="extra-inner-wrapper">
                            {
                                insuranceList?.map((item:any, id:string) => {
                                    return (
                                        <InsuranceChoiceBox 
                                            type={item?.type} 
                                            info={item?.info}
                                            price={item?.price}
                                            key={id}
                                            setInsuranceType={() => handleInsuranceChosen(item)}
                                            choice={choice === item?.id}
                                            onToggle={() => handleToggle(id)}
                                            contentShow={clicked === id}
                                        />
                                    )
                                })
                            }

                        </div>

                        <div className="amount-wrap-overall">
                            <ExtraAmtWrap>
                            ₦{`${(insuranceAmount)?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}
                            </ExtraAmtWrap>
                        </div>
                    </InputBoxOverall>

                    <div className="textarea-wrap">
                        <InstructionBox  placeholder={'Give car owner an instruction ...'} rows={5} value={instruction} onChange={(e:any) => handleInstruction(e.target.value)}  />
                        {/* <div className="btn-wrap">
                            <SaveBtn onClick={handleSubmitExtra}>
                                Save
                            </SaveBtn>
                        </div> */}
                    </div>
                </div>
            </ExtrasModalBox>
        </div>
    </ModalOverlay>
  )
}

export default ExtrasModal