import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    showDriverOption: false
  };
  const showDriverOptionSlice = createSlice({
    name: 'showDriverOption',
    initialState,
    reducers: {
      setShowDriverOption: (state, action) => {
        return { ...state, showDriverOption: action.payload };
      },
      clearShowDriverOption: () => {
        return { showDriverOption: false };
      }
    }
  });
  
  const { reducer, actions } = showDriverOptionSlice;
  export const { setShowDriverOption, clearShowDriverOption} = actions;
  export default reducer;