import React, { useState } from 'react'
import { ModalFilterBox } from '../filterBoxModal/filterModal.styles'
import { CancelModalBox, ModalOverlay } from '../optionModal/optionmodal.styles'
import { SubmitBtn } from '../toggleBar/togglebar.styles'
import { LocationActionWrap, LocationHeader, LocationInputWrapper, LocationModalBox, LocationModalOverlay } from '../locationSearchModal/locationInput.styles'
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api'

import cancel from "../../assets/images/svg/cancel-yellow.svg"

const SearchLocationInputModal = ({location, setLocation, showModal, setShowModal, setCity, setPickupLatLong}:any) => {
    const [origin, setOrigin] = useState()
    const [autocompleteOrigin, setAutocompleteOrigin] = useState<any>(null)


  return (
    <LocationModalOverlay activeModal={showModal}>
        <CancelModalBox  onClick={() => setShowModal(false)}/>

        <LocationModalBox>
            <div className="inner-modal-box">
                <LocationHeader>
                    <p>Location of Vehicle</p>

                    <div className="cancel-wrap">
                        <img src={cancel} onClick={() => setShowModal(false)} />
                    </div>
                </LocationHeader>

                <LocationInputWrapper>
                    <input type="text" placeholder='Type here' value={origin} onChange={(e:any) => {setLocation(e.target.value);
                        setOrigin(e.target.value)
                    }} />
                </LocationInputWrapper>

                <LocationActionWrap>
                    <SubmitBtn onClick={() => {setShowModal(false); 
                    }}>
                        Continue
                    </SubmitBtn>
                </LocationActionWrap>
            </div>
        </LocationModalBox>
    </LocationModalOverlay>
  )
}

export default SearchLocationInputModal