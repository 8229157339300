import styled from 'styled-components'

export const Page = styled.div`
    position: relative;
    min-height: 100vh;
    place-items: center;
    transition: .5s;


`

export const DIV = styled.div`
display:flex;
align-items:center;
height:120px;
gap:15px;
`
export const SPAN = styled.span`
color:#ffa100;
`
export const H44 = styled.h4`
font-weight:500;
color:#fff;
padding-top:20px;

`

export const RANGEDIV = styled.div`
margin-top:30px;
position:relative;
`
export const Container = styled.div`
display:flex;
padding:0px 12px;
justify-content:space-between;
align-items:center;
margin-top:0px;
`
export const Button = styled.div`
background: #000;
color: #fff;
padding:0px 18px;
border-radius:5px;
border:1px solid #ddd;
display:flex;
align-items:center;
height:50px;
`

export const UploadButton = styled.div`
background: #ffa100;
color: #000;
padding:25px 18px;
border-radius:25px;
display:flex;
align-items:center;
height:50px;
`
export const DisabledButton = styled.div`
background: #000;
color: #aaa;
padding:25px 18px;
border-radius:25px;
display:flex;
align-items:center;
height:50px;
`
