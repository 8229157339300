import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  showItemSize: false
};
const showItemSizeSlice = createSlice({
  name: 'showItemSize',
  initialState,
  reducers: {
    setShowItemSize: (state, action) => {
      return { ...state, showItemSize: action.payload };
    },
    clearShowItemSize: () => {
      return { showItemSize: false };
    }
  }
});

const { reducer, actions } = showItemSizeSlice;
export const { setShowItemSize, clearShowItemSize} = actions;
export default reducer;