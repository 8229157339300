import React from 'react'
import { FailedBtn, BlogCardBtn } from './button.styles'


const StyledButton = ({buttonText}: any) => {
  return (
    <BlogCardBtn>
      {buttonText}
    </BlogCardBtn>
  )
}

export default StyledButton