import React, { useState } from 'react'
import { ActionsBottomWrap, ActionsLeftSection, ActionsRightSection, ActionsTopWrap, CancelTripWrap, CompletedRatingBox, EnvelopeImgWrap, MessageBtn, ReceiptBtn, TripsCardWrap, TripStatus } from '../tripsCardComponent/tripscardcomponent.styles'
import { DeliverPersonImgWrap, DeliveryActionSection, DeliveryBottomSide, DeliveryTopSide, ItemImgWrap, ItemInfoWrap, PickupWrap, TimeWrapper } from './deliverytrips.styles'
import { Link } from 'react-router-dom'
import ReactStars from "react-rating-stars-component";


import clock from '../../assets/images/svg/clock-icon.svg'
import driver from '../../assets/images/png/delivery-man.png'
import car from '../../assets/images/png/delivery-car.png'
import envelope from '../../assets/images/svg/envelope.svg'
import darkReceipt from '../../assets/images/svg/dark-receipt.svg'
import call from '../../assets/images/svg/yellow-call.svg'
import { DriverName } from '../pickDriverCard/pickdriver.styles';
import CancelBookingModal from '../cancelBookingModal';
import { useUpdateBookingMutation } from '../../services/customerApi';
import ReactWhatsapp from 'react-whatsapp';


const DeliveryTripsCard = ({bookingImage,providerNo, tripPending, progress, completed, canceled, time, origin, riderName, amount, noOfItems, idForBooking, handleCancelBooking}: any) => {
    const [showCancelModal, setShowCancelModal] = useState(false)


    const ratingChanged = (newRating: any) => {
        console.log(newRating);
    };

  return (
    <>
        <CancelBookingModal 
            setShowCancelModal={setShowCancelModal}
            showCancelModal={showCancelModal}
            handleCancelBooking={ () => handleCancelBooking(idForBooking)}
        />
        <TripsCardWrap>
            {
                (tripPending) &&
                <TripStatus bg={'#005FEE'} text={'#fff'}>
                    Pending
                </TripStatus>
            }

            {
                progress && 
                <TripStatus bg={'#FFA100'} text={'#000'}>
                    In-progress
                </TripStatus>
            }

            {
                completed && 
                <TripStatus bg={'#07B418'} text={'#000'}>
                    Completed
                </TripStatus>
            }

            {
                canceled && 
                <TripStatus bg={'#D52B1E'} text={'#fff'}>
                    Canceled
                </TripStatus>
            }

            <DeliveryTopSide>
                <DeliverPersonImgWrap>
                    <img src={driver} />
                </DeliverPersonImgWrap>

                <TimeWrapper>
                    <img src={clock} />
                    <p>{time}</p>
                </TimeWrapper>

                <PickupWrap>
                    <p>{origin}</p>
                </PickupWrap>
            </DeliveryTopSide>

            <DeliveryBottomSide>
                <ItemInfoWrap>
                    <div className="name-wrap">
                        {riderName}
                    </div>

                    <div className="price-wrapper">
                        ₦{`${(amount)?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}
                    </div>

                    <div className="no-of-deliveries">
                        {noOfItems}
                        {noOfItems === "1" ? " Delivery" : " Deliveries"}
                    </div>
                </ItemInfoWrap>

                {/* <ItemImgWrap>
                    <img src={car}/>
                </ItemImgWrap> */}

                <DeliveryActionSection>
                    <ActionsLeftSection>
                        <EnvelopeImgWrap>
                        {/* <img src={envelope} /> */}
                        </EnvelopeImgWrap>
                    </ActionsLeftSection>

                    <ActionsRightSection>
                        <ActionsTopWrap>
                            {
                                tripPending &&
                                <CancelTripWrap onClick={() => setShowCancelModal(true)}>
                                    <p>Cancel</p>
                                </CancelTripWrap>
                            }
                            
                            {
                                completed && 
                                <ReceiptBtn>
                                    <p>Receipt</p>
                                    <img src={darkReceipt} />
                                </ReceiptBtn>
                            }

                            {               
                                canceled && 
                                <ReceiptBtn>
                                    <p>Re-book</p>
                                </ReceiptBtn>
                            }

                        </ActionsTopWrap>


                        {/* ACTIONS BOTTOM WRAP */}
                        {
                            !completed && !canceled &&
                            <ActionsBottomWrap>
                                <Link to={"tel:" + providerNo}>
                                    <CancelTripWrap>
                                        <img src={call} />
                                    </CancelTripWrap>
                                </Link>


                                <MessageBtn>
                                    <ReactWhatsapp number="+2349044442200">
                                        Message
                                    </ReactWhatsapp>
                                </MessageBtn>                        
                            </ActionsBottomWrap>
                        }

                        {
                            (completed || canceled) && 
                            <ActionsBottomWrap>
                                <CompletedRatingBox>
                                    <h4>Give a Review</h4>
                                    <div className="">
                                    <ReactStars
                                        count={5}
                                        onChange={ratingChanged}
                                        size={20}
                                        activeColor="#ffd700"
                                    />
                                    </div>
                                </CompletedRatingBox>
                            </ActionsBottomWrap>
                        }

                    </ActionsRightSection>
                </DeliveryActionSection>

            </DeliveryBottomSide>
        </TripsCardWrap>
    </>
  )
}

export default DeliveryTripsCard