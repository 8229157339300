import styled from 'styled-components'

export const PageWrapper = styled.section `
    padding: 20px 40px;
    margin-bottom: 50px;

    @media only screen and (min-width: 1100px) {
        padding: 20px 150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    } 

    @media only screen and (min-width: 1300px) {
        padding: 20px 300px;
    }
`

export const ProfileFlexWrap = styled.div `
    display: flex;
    align-items: flex-end;
    margin-bottom: 50px;
    gap: 20px;

    @media only screen and (max-width: 454px) {
        margin-bottom: 20px;
    }
`

export const ProfileImgWrap = styled.div `
    border-radius: 50%;
    position: relative;

    
    & .profile-img {
        width: 80px;
        height: 80px;
        border-radius: inherit;
        display: block;
        object-fit: cover;
        object-position: center;
    }

    @media only screen and (max-width: 454px) {
        img {
            width: 18px;
        }

        .profile-img {
            width: 50px;
            height: 50px;
        }
    }
`

export const EditCameraWrap = styled.div `
    position: absolute;
    bottom: -7px;
    right: -8px;
    border-radius: 50%; 
    box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.13);

    & img {

    }
`

export const InputComponentWrap = styled.div `
    margin-bottom: 30px;

    @media only screen and (max-width: 454px) {
        margin-bottom: 20px;
    }
`

export const DeleteAccountBtn = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    padding: 15px 0;
    font-size: 16px;
    font-weight: 700;
    color: #FF3A3A;
    cursor: pointer;
    background-color: #fff;
`