import React, { FC, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'

import driverRequest from "../../../assets/images/png/driverRequest.png";
import vehicleRequest from "../../../assets/images/png/trips-card-car.png";
import envelope from "../../../assets/images/png/envelop.png";
import userWhite from "../../../assets/images/png/user-white.png";
import { AiOutlineClockCircle } from "react-icons/ai";
import { ImLocation2 } from "react-icons/im";
import { BsCalendar3 } from "react-icons/bs";

import {
  RequestDiv,
  Div,
  ImageDiv,
  StatusDiv,
  Span,
  AcceptButton,
  FlexDiv,
  H6,
  H5,
  Label,
  RejectButton,
  IDDiv,
  StartButton,
  CancelButton,
} from "./requests.styles";
import {
  useAcceptRequestMutation,
  useRejectRequestMutation,
  useUpdateRequestMutation,
} from "../../../services/providerApi";
import { setAllRequest } from "../../../slices/provider";
import { useAppDispatch } from "../../../store";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer, toast } from "react-toastify";
import BookingTimeModal from "../../bookingTimeModal";
import BookingCalendarModal from "../../bookingCalendarModal";
import BookingLocationModal from "../../bookingLocationModal";
import CallCustomer from "../../Admin/CallCustomer";
import TicketModal from "./ticketPopup";
const Request: FC<{ request: any }> = ({ request }) => {
  const dispatch = useAppDispatch();

  const formatDate = (): string => {
    const dateString = request.pickup_date;
    const dateArray = JSON.parse(dateString.replace(/\\/g, ""));
    const firstDate = dateArray.slice(0, 10); // Extract the first date from the range
    const commaSeparatedDate = firstDate.slice(0, 6) + " " + firstDate.slice(6); // Insert a comma after the year
    const correctedDateRange = commaSeparatedDate + dateArray.slice(10);
    return correctedDateRange;
  };

  const address = request?.destination;
  const words = address.split(" ");
  const firstThreeWords = words.slice(0, 2).join(" ");

  const [acceptRequest, { data, isLoading, isSuccess, isError, error }] =
    useAcceptRequestMutation();
  const [
    rejectRequest,
    {
      data: rejectData,
      isLoading: loading,
      isSuccess: success,
      isError: err,
      error: errr,
    },
  ] = useRejectRequestMutation();
  const [
    updateRequest,
    {
      data: updateData,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: Updateerror,
    },
  ] = useUpdateRequestMutation();

  useEffect(() => {
    if (isLoading) {
    }
    if (isSuccess) {
      dispatch(setAllRequest(data?.data));
    }
    if (isError && "status" in error!) {
      if (isError && error.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
        // setIsDelete(false)
      }
      // setBtnLoad(false)
    }
  }, [isLoading, isSuccess, isError, data]);

  useEffect(() => {
    if (isUpdateLoading) {
    }
    if (isUpdateSuccess) {
      dispatch(setAllRequest(updateData?.data));
    }
    if (isUpdateError && "status" in Updateerror!) {
      if (isUpdateError && Updateerror.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
        // setIsDelete(false)
      }
      // setBtnLoad(false)
    }
  }, [isUpdateLoading, isUpdateSuccess, isUpdateError, updateData]);

  useEffect(() => {
    if (loading) {
    }
    if (success) {
      // dispatch(setAllRequest(rejectData?.data))
      console.log("success");
    }
    if (err && "status" in errr!) {
      if (err && errr.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
        // setIsDelete(false)
      }
      // setBtnLoad(false)
    }
  }, [loading, success, err, rejectData]);

  const handleAcceptRequest = async () => {
    let formData: any = new FormData();
    formData.append("id", request?.id);
    formData.append("book_status", "Pending");
    acceptRequest(formData);
  };

  const handleFinishRequest = async () => {
    let formData: any = new FormData();
    formData.append("id", request?.id);
    formData.append("book_status", "Completed");
    rejectRequest(formData);
  };

  const handleStartRequest = async () => {
    let formData: any = new FormData();
    formData.append("booking_id", request?.id);
    formData.append("book_status", "In Progress");
    formData.append("provider_status", "In Progress");
    updateRequest(formData);
  };
  const handleCancelRequest = async () => {
    let formData: any = new FormData();
    formData.append("booking_id", request?.id);
    formData.append("provider_status", "Cancel");
    formData.append("book_status", "Open");
    updateRequest(formData);
  };

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showCustomer, setShowCustomer] = useState(false);
  const [passengerInfo, setPassengerInfo] = useState({});

  const passengersDetails = request?.passenger;

  const handlePassenger = (passengerData) => {
    setPassengerInfo(passengerData);
    setIsTicket((prev) => !prev);
  };
  const [isTicket, setIsTicket] = useState(false);
  return (
    <>
      <ToastContainer />

      <BookingTimeModal
        time={request?.pickup_time}
        showTimeModal={showTimeModal}
        setShowTimeModal={setShowTimeModal}
      />

      <BookingCalendarModal
        showDateModal={showDateModal}
        setShowDateModal={setShowDateModal}
        dateArray={formatDate()}
      />

      <BookingLocationModal
        previousOrigin={request?.pickup_location}
        previousDestination={request?.destination}
        showLocationModal={showLocationModal}
        setShowLocationModal={setShowLocationModal}
        withinOrWithout={"within"}
        setDistance={undefined}
      />
      {showCustomer && (
        <CallCustomer
          customerId={request?.customer_id}
          setShowCustomer={setShowCustomer}
        />
      )}

      {request?.book_status !== "Cancel" && (
        <div style={{ position: "relative", margin: "40px 0px" }}>
          {request?.book_status === "Open" &&
          request?.category === "Travel Garage Booking" ? (
            <StatusDiv style={{ background: "#005FEE" }}>Listed</StatusDiv>
          ) : (
            <StatusDiv>{request?.book_status}</StatusDiv>
          )}
          {request?.book_status === "Pending" && (
            <StatusDiv style={{ background: "#005FEE" }}>
              {request?.book_status}
            </StatusDiv>
          )}
          {request?.book_status === "In Progress" && (
            <StatusDiv style={{ background: "#ffa100", color: "#000" }}>
              In_Progress
            </StatusDiv>
          )}
          {request?.book_status === "Completed" && (
            <StatusDiv style={{ background: "##07B418" }}>
              {request?.book_status}
            </StatusDiv>
          )}

          <RequestDiv>
            <FlexDiv>
              <ImageDiv>
                <img src={vehicleRequest} alt="" width={90} height="auto" />
              </ImageDiv>
              <IDDiv>
                <Label>ID: {request?.booking_id}</Label>

                <H5>
                  {request?.category === "Travel Garage Booking"
                    ? request?.from + " to " + request?.to
                    : request?.booking_title}
                </H5>
              </IDDiv>

              <Div style={{ alignItems: "start" }}>
                <Span onClick={() => setShowTimeModal(true)}>
                  <AiOutlineClockCircle />
                  <Label>{request?.pickup_time}</Label>
                </Span>

                <Span onClick={() => setShowLocationModal(true)}>
                  <ImLocation2 />
                  <Label>{firstThreeWords}</Label>
                </Span>

                <Span onClick={() => setShowDateModal(true)}>
                  <BsCalendar3 />
                  <Label>{formatDate().split(",").slice(0, 1).join(" ")}</Label>
                </Span>
              </Div>
              <Div style={{ width: "30%", alignItems: "flex-end" }}>
                {request?.book_status !== "In Progress" &&
                request?.category !== "Travel Garage Booking" ? (
                  <H6>
                    ₦
                    {request?.amount.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                  </H6>
                ) : (
                  <H6></H6>
                )}

                {request?.category === "Travel Garage Booking" && (
                  <div className="travel-action">
                    <h6>.</h6>
                  </div>
                )}

                {request?.category === "Travel Garage Booking" && (
                  <div className="travel-action">
                    <div className="view">
                      <button>View</button>
                    </div>

                    <div className="seat">
                      <button>seat</button>
                      <label htmlFor="">1</label>
                    </div>
                  </div>
                )}

                {request?.book_status === "Open" &&
                  request?.category !== "Travel Garage Booking" && (
                    <>
                      <RejectButton onClick={handleCancelRequest}>
                        Reject
                      </RejectButton>
                      {!isLoading ? (
                        <AcceptButton onClick={handleAcceptRequest}>
                          Accept
                        </AcceptButton>
                      ) : (
                        <AcceptButton onClick={handleAcceptRequest}>
                          Loading...
                        </AcceptButton>
                      )}
                    </>
                  )}

                {request?.book_status === "Pending" &&
                  request?.category !== "Travel Garage Booking" && (
                    <>
                      {!isUpdateLoading ? (
                        <StartButton onClick={handleStartRequest}>
                          Start Now
                        </StartButton>
                      ) : (
                        <StartButton onClick={handleStartRequest}>
                          Starting...
                        </StartButton>
                      )}

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 7,
                        }}
                      >
                        <img
                          src={envelope}
                          alt=""
                          width={28}
                          onClick={handleAcceptRequest}
                        />
                        <CancelButton onClick={handleCancelRequest}>
                          Cancel
                        </CancelButton>
                      </div>
                    </>
                  )}

                {request?.book_status === "In Progress" &&
                  request?.category !== "Travel Garage Booking" && (
                    <>
                      {/* <StartButton onClick={handleStartRequest}>
                                        Start Now
                                    </StartButton> */}
                      <div className="">&nbsp;</div>
                      <H6>
                        ₦
                        {request?.amount.toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                        })}
                      </H6>
                      <RejectButton onClick={handleFinishRequest}>
                        Finish
                      </RejectButton>
                    </>
                  )}
              </Div>
            </FlexDiv>
          </RequestDiv>
          {request?.category === "Travel Garage Booking" ? (
            <div
              style={{
                marginTop: -45,
                marginBottom: 75,
                display: "flex",
                gap: 5,
                alignItems: "center",
              }}
            >
              {passengersDetails?.map((passenger, i) => {
                return (
                  <div
                    key={passenger?.id}
                    onClick={() => handlePassenger(passenger)}
                    style={{
                      //   border: "1px solid white",
                      padding: "5px",
                      borderRadius: 5,
                      background: "#AA6C05",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={userWhite} width={30} height={25} />
                    
                  </div>
                );
              })}
             
            </div>
          ) : (
            ""
          )}
        </div>
      )}

      {isTicket && (
        <TicketModal
          setIsTicket={setIsTicket}
          request={request}
          passengerInfo={passengerInfo}
        />
      )}
    </>
  );
};

export default Request;
