import React, { useEffect } from 'react'
import { AmtPayBox, CollectMoneyThreeWrap, DarkGreyBox, DescriptionBox, DescriptionTxt, H14, NameFootWrap, OverflowBox, PayBtn, TextHeadWrap } from './collectmoneythree.styles'
import { useForm, SubmitHandler } from "react-hook-form"
import ReactCountryFlag from "react-country-flag"

import person from  '../../assets/images/svg/person-icon.svg'
import canada from '../../assets/images/png/canada.png'
import { GreyWrap } from '../collectMoneyOne/collectmoneyone.styles'
import { FlexInputWrap } from '../fundWalletThree/fundwalletthree.styles'

const CollectMoneyThree = ({displaybox, handleShowComponent, description, receiverName, currency, amount, payerName, handlePayme, paymeSuccess, link}:any) => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm()
    const onSubmit = (data:any) => {
        handlePayme({...data, amount: amount, currency: currency, link: link })
    }

    useEffect(() => {
        if(paymeSuccess) {
            reset()
        }
    }, [paymeSuccess])

  return (
    <CollectMoneyThreeWrap display={displaybox}>
        <TextHeadWrap>
            <h2>Payment link from <span> {receiverName} </span></h2>
        </TextHeadWrap>

        <DarkGreyBox>
            <DescriptionBox>
                <DescriptionTxt>Description</DescriptionTxt>
                <H14>{description}</H14>

                <AmtPayBox>
                    {/* <img src={canada} /> */}
                    <ReactCountryFlag countryCode={"NG"} />

                    <div className="">
                        <h5>Amount to pay</h5>
                        <p>{currency} {`${(Number(amount)?.toLocaleString("en-US", {maximumFractionDigits: 2}))}`}</p>
                    </div>
                </AmtPayBox>

                <NameFootWrap>
                    <div className="profile-wrap">
                        <img src={person} />
                    </div>

                    <p>{payerName}</p>
                </NameFootWrap>
            </DescriptionBox>
        </DarkGreyBox>

        {/* <OverflowBox> */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <GreyWrap>
                    <FlexInputWrap>
                        <input type="text" placeholder='First Name' {...register("firstname", { required: "First Name is required" })}/>
                        <img src={person} />
                        {errors.firstname?.type === "required" && (
                            <p role="alert" className='error-msg'>First name is required</p>
                        )}
                    </FlexInputWrap>

                    <FlexInputWrap>
                        <input type="text" placeholder='Last Name' {...register("lastname", { required: "Last Name is required" })} />
                        {errors.lastname?.type === "required" && (
                            <p role="alert" className='error-msg'>Last name is required</p>
                        )}
                    </FlexInputWrap>

                    <FlexInputWrap>
                        <input type="email" placeholder='Email Address' {...register("payer_email", { required: "First Name is required", pattern: {value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, message: "Invalid email"} })}/>
                        {/* @ts-ignore */}
                        {/* {errors.payer_mail && <p role="alert" className='error-msg'>{errors.payer_mail.message}</p>} */}
                        {errors.payer_email?.type === "required" && (
                            <p role="alert" className='error-msg'>Email is required</p>
                        )}
                    </FlexInputWrap>
                </GreyWrap>

                <PayBtn type={"submit"} >
                    <p>Pay</p>
                </PayBtn>
            </form>
        {/* </OverflowBox> */}
    </CollectMoneyThreeWrap>
  )
}

export default CollectMoneyThree