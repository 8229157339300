import styled from 'styled-components'

export const Page = styled.div`
    position: relative;
    min-height: 100vh;
    width: 100vw;
    place-items: center;
    transition: .5s;
    background-color: #242321;
padding:2em;

`

export const KinLabel = styled.label`
background: #059BBF;
color:#fff;
font-weight:bold;
font-size:13px;
margin-left:15px;
padding:5px 10px;
border-radius:5px;
margin-top:55px;
margin-bottom:55px;
`
export const OwnerLabel = styled.label`
background: #001AFF;
color:#fff;
font-weight:bold;
font-size:13px;
margin-left:15px;
padding:5px 10px;
border-radius:5px;
margin-top:200px;
`
export const StatusDiv = styled.div`
display:flex;
background: #005FEE;
position:absolute;
right:10px;
top:0px;
align-items:center;
justify-content:center;

padding:.7em .9em;
border-radius:0px 0px 7px 7px;
font-size:13px;
font-weight:700;
`
export const RejectedDiv = styled.div`
display:flex;
background: #E70909;
position:absolute;
right:10px;
top:0px;
align-items:center;
justify-content:center;

padding:.7em .9em;
border-radius:0px 0px 7px 7px;
font-size:13px;
font-weight:700;
`
export const ApprovedDiv = styled.div`
display:flex;
background: #06AE2B;
position:absolute;
right:10px;
top:0px;
align-items:center;
justify-content:center;

padding:.7em .9em;
border-radius:0px 0px 7px 7px;
font-size:13px;
font-weight:700;
`

export const VerifyButton = styled.div`
display:flex;
background: #A65204;
width:100%;
color:#fff;
font-weight:bold;
padding:13px;
border-radius:5px;
margin:50px 0px 20px 0px;
text-align:center;
justify-content:center;
`
export const VerifiedButton = styled.div`
display:flex;
background: #ffa100;
width:100%;
color:#000;
font-weight:bold;
padding:13px;
border-radius:5px;
margin:50px 0px 20px 0px;
text-align:center;
justify-content:center;
`

export const PublishButton = styled.div`
display:flex;
background: #FFD58C;
width:70%;
color:#000;
font-weight:bold;
justify-content:center;
align-item:center;
padding:13px;
border-radius:5px;
margin: 30px auto;
text-align:center;
`
export const PublishedButton = styled.div`
display:flex;
background: #ffa100;
width:70%;
color:#000;
font-weight:bold;
justify-content:center;
align-item:center;
padding:13px;
border-radius:5px;
margin: 30px auto;
text-align:center;
`
export const VerifyProfileDiv = styled.div`
display:flex;
justify-content:space-between;
gap:20px;
`
export const AcceptButton = styled.button`
background: #06AE2B;
color: #fff;
padding:14px 10px;
border-radius:7px;
outline:none;
border:none;
width:50%;
font-size:20px;
`
export const RejectButton = styled.button`
background: #E70909;
color: #fff;
padding:14px 10px;
border-radius:7px;
outline:none;
border:none;
width:50%;
font-size:20px;
`