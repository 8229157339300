import React, { FC, useEffect, useState } from 'react'
import {
    Modal, Label, Flex, ImageContainer, TextContainer, BackButton, ModalContainer, PinButton
} from './index.styles';
import deleteImage from '../../../assets/images/png/modalOption.png'
import spinner from '../../../assets/images/gif/spinner.gif'

import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify'

import axios from 'axios';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setAllVehicle } from '../../../slices/admin';
import { usePinDriverMutation, usePinProviderMutation, usePinVehicleMutation } from '../../../services/adminApi';

type Props = {
    id: any;
    setIsPin: any;
    isUnPin?: any;
    setIsUnPin?: any;
    isDriver?: boolean;
    isVehicle?: boolean;
    isProvider?: boolean;
}
export const PinModal: FC<Props> = ({ isProvider, isVehicle, isDriver, setIsUnPin, isUnPin, id, setIsPin }) => {
    const token = useAppSelector((state: any) => state?.auth?.auth?.token)
    const allVehicle = useAppSelector((state: any) => state?.provider.allVehicle)

    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    //@ts-ignore
    const dispatch = useAppDispatch()


    const [pinVehicle, { data, isLoading, isSuccess, isError, error }] = usePinVehicleMutation()
    const [pinDriver, { data:driverData, isLoading:driverLoading, isSuccess:driverSuccess, isError:driverError, error:driverErr }] = usePinDriverMutation()
    const [pinProvider, { data:providerData, isLoading:providerLoading, isSuccess:providerSuccess, isError:providerError, error:providerErr }] = usePinProviderMutation()


    useEffect(() => {
        if (isLoading) {}
        if (isSuccess) {
            // dispatch(setAllVehicle([]))
            const updatedVehicle = data;
            // dispatch(setAllVehicle(data?.data))
            setIsPin(false)
        }
        if (isError && 'status' in error!) {
            if (isError && error.status.toString() === 'FETCH_ERROR') {
                notifyError('Network Connection Failed')
                setIsPin(false)
            }
            // setBtnLoad(false)
        }
    }, [isLoading, isSuccess, isError, data])

    useEffect(() => {
        if (driverLoading) {}
        if (driverSuccess) {
            // const updatedVehicle = data;
            setIsPin(false)
        }
        if (isError && 'status' in error!) {
            if (isError && error.status.toString() === 'FETCH_ERROR') {
                notifyError('Network Connection Failed')
                setIsPin(false)
            }
            // setBtnLoad(false)
        }
    }, [driverLoading, driverSuccess, driverError, driverData])



    const handlePin = async () => {
        setIsPin(true)

        let formData: any = new FormData()
        formData.append('id', id)
        formData.append('pin_status', 1)
        pinVehicle(formData).then(function (response: any) {
            //@ts-ignore
            const message = response?.data?.message
            if (message === 'success') {
                // dispatch(setAllVehicle(response?.data?.data))
                setIsPin(false)
            }
        }).catch(function (error) {
            //handle error
            setIsPin(false)

        });
    }

    const handleUnPin = async () => {
        let formData: any = new FormData()
        formData.append('id', id)
        formData.append('pin_status', 0)
        pinVehicle(formData).then(function (response: any) {
            //@ts-ignore
            const message = response?.data?.message
            if (message === 'success') {
                // dispatch(setAllVehicle(response?.data?.data))
                setIsUnPin(false)
            }
        }).catch(function (error) {
            //handle error
            setIsUnPin(false)

        });
    }


    const handlePinDriver = async () => {
        setIsPin(true)

        let formData: any = new FormData()
        formData.append('id', id)
        formData.append('pin_status', 1)
        pinDriver(formData).then(function (response: any) {
            //@ts-ignore
            const message = response?.data?.message
            if (message === 'success') {
                setIsPin(false)
            }
        }).catch(function (error) {
            //handle error
            setIsPin(false)

        });
    }

    const handleUnPinDriver = async () => {
        let formData: any = new FormData()
        formData.append('id', id)
        formData.append('pin_status', 0)
        pinDriver(formData).then(function (response: any) {
            //@ts-ignore
            const message = response?.data?.message
            if (message === 'success') {
                // dispatch(setAllVehicle(response?.data?.data))
                setIsUnPin(false)
            }
        }).catch(function (error) {
            //handle error
            setIsUnPin(false)

        });
    }


    const handlePinProvider = async () => {
        setIsPin(true)

        let formData: any = new FormData()
        formData.append('id', id)
        formData.append('pin_status', 1)
        pinProvider(formData).then(function (response: any) {
            //@ts-ignore
            const message = response?.data?.message
            if (message === 'success') {
                // dispatch(setAllVehicle(response?.data?.data))
                setIsPin(false)
            }
        }).catch(function (error) {
            //handle error
            setIsPin(false)

        });
    }

    const handleUnPinProvider = async () => {
        let formData: any = new FormData()
        formData.append('id', id)
        formData.append('pin_status', 0)
        pinProvider(formData).then(function (response: any) {
            //@ts-ignore
            const message = response?.data?.message
            if (message === 'success') {
                // dispatch(setAllVehicle(response?.data?.data))
                setIsUnPin(false)
            }
        }).catch(function (error) {
            //handle error
            setIsUnPin(false)

        });
    }




    const handlePinModal = async () => {
        setIsPin(false)
        setIsUnPin(false)
    }


    const notifyError = (text: any) => toast.error(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",

    })

    return (
        <>
            <ModalContainer onClick={handlePinModal}>

                {/* <ToastContainer /> */}
                <Modal onClick={(e: any) => e.stopPropagation()}>
                    <ImageContainer>
                        <img src={deleteImage} style={{ width: '100%' }} />
                    </ImageContainer>
                    <TextContainer>
                        <Label>
                            Are you sure you want to {isUnPin ? 'unpin' : 'pin'} this
                            {
                                isDriver && ' driver? '
                            }
                            {
                                isVehicle && ' vehicle? '
                            }

                            It will be moved to the {isUnPin ? 'original position' : 'top of the search page'} in its location.
                        </Label>

                        <Flex>
                            <BackButton onClick={handlePinModal}>
                                Go Back
                            </BackButton>
                            {
                                isDriver && <>
                                    {
                                        isLoading ?
                                            <PinButton style={{ padding: '0px 8px' }}>
                                                <p>loading</p>
                                                <img src={spinner} width={20} />
                                            </PinButton> :
                                            <>


                                                {
                                                    isUnPin ? <PinButton onClick={handleUnPinDriver}>
                                                        Un pin
                                                    </PinButton> :
                                                        <PinButton onClick={handlePinDriver}>
                                                            Pin
                                                        </PinButton>
                                                }
                                            </>
                                    }
                                </>
                            }

                            {
                                isVehicle && <>
                                    {
                                        isLoading ?
                                            <PinButton style={{ padding: '0px 8px' }}>
                                                <p>loading</p>
                                                <img src={spinner} width={20} />
                                            </PinButton> :
                                            <>


                                                {
                                                    isUnPin ? <PinButton onClick={handleUnPin}>
                                                        Un pin
                                                    </PinButton> :
                                                        <PinButton onClick={handlePin}>
                                                            Pin
                                                        </PinButton>
                                                }
                                            </>
                                    }
                                </>
                            }

                            {
                                isProvider && <>
                                    {
                                        isLoading ?
                                            <PinButton style={{ padding: '0px 8px' }}>
                                                <p>loading</p>
                                                <img src={spinner} width={20} />
                                            </PinButton> :
                                            <>


                                                {
                                                    isUnPin ? <PinButton onClick={handleUnPinProvider}>
                                                        Un pin
                                                    </PinButton> :
                                                        <PinButton onClick={handlePinProvider}>
                                                            Pin
                                                        </PinButton>
                                                }
                                            </>
                                    }
                                </>
                            }




                        </Flex>
                    </TextContainer>


                </Modal>
            </ModalContainer >

        </>

    )
}


