import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import providerPlaceholder from '../../../assets/images/png/providerPlaceholder.png'
import customerPlaceholder from '../../../assets/images/png/phebe.png'

import { GiCheckMark } from 'react-icons/gi';
import { IoCallSharp, IoEllipsisHorizontalOutline } from 'react-icons/io5';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { BsEnvelope, BsPinFill } from 'react-icons/bs';

import {
    DriverDiv, Div, ImageDiv, CheckMark, DriverDetails, FlexDiv, H6, Id, StatusLabel,
    CallDiv, CallButton, IdDiv, AddressDiv, FirstNameDiv, NameLabel, PinnedInfoDiv,
    Ellipses, BookingLabel, CartBox
} from './customer.styles';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { baseUrl } from '../../../services/adminApi';
import { useAppSelector } from '../../../store';
import { JoinDiv, TopLabel } from '../Drivers/driver.styles';
import moment from 'moment';
const Customer: FC<{ isCustomer: boolean, driverDetails: any, isModal: boolean }> = ({ isCustomer, isModal, driverDetails }) => {

    //please note, the drivrDetails here represents customer details; please bear with me
    const user: any = useAppSelector((state: any) => state?.auth?.auth)

    let [userCart, setUserCart] = useState(0);
    let [userRequests, setUserRequests] = useState(0);
    const handleCheckCustomerCart = () => {
        let formData: any = new FormData()
        formData.append('id', driverDetails?.id)

        axios({
            method: "post",
            url: `${baseUrl}go-to-cart}`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const message = response.data.data;
            // setUserCart(response.data.data?.length)

        })
            .catch((error) => {
                // Handle the error if needed
                console.error('Error verifying vehicle:', error);
                // setVehicleStatus('Error');
            });

    }
    const handleCheckCustomerRequest = () => {

        axios
            .get(`${baseUrl}fetch-requestbyuserid/${driverDetails?.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${user?.token}`,
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then(function (response) {
                //handle success
                const customerRequest = response.data.data;
                // const errors = response.data.errors;
                // setUserRequests(customerRequest)


            })
            .catch(function (error) {
                //handle error
                console.log("error ", error.response.data);
            });
    };

    useEffect(() => {
        handleCheckCustomerCart()
        handleCheckCustomerRequest()
    }, [])

    return (
        <>

            <DriverDiv>
                {/* {!isModal && !isCustomer && */}
                    <PinnedInfoDiv style={{top:'-20px'}}>
                        <JoinDiv>
                            <TopLabel>Joined: {moment(driverDetails?.created_at).format('DD/MM/YYYY')}</TopLabel>
                        </JoinDiv>
                       
                    </PinnedInfoDiv>
                {/* } */}

                <IdDiv>
                    <ImageDiv>
                        <img src={customerPlaceholder} />
                    </ImageDiv>

                    <CheckMark>
                        <GiCheckMark style={{ padding: '.2em', borderRadius: '5px', background: '#FFD58C', }} />
                        <p style={{ fontSize: '9px', fontWeight: '800' }}>Customer</p>
                    </CheckMark>

                    <CheckMark>
                        <GiCheckMark style={{ padding: '.2em', borderRadius: '5px', background: '#FFD58C', }} />
                        <p style={{ fontSize: '9px', fontWeight: '800' }}>{driverDetails?.username}</p>
                    </CheckMark>

                </IdDiv>

                <AddressDiv>
                    <DriverDetails>
                        <FirstNameDiv>
                            <NameLabel>First</NameLabel>
                            <H6>{driverDetails?.firstname || driverDetails?.name}</H6>
                        </FirstNameDiv>

                        <FirstNameDiv>
                            <NameLabel>Lastname</NameLabel>
                            <H6>{driverDetails?.lastname || '.'}</H6>
                        </FirstNameDiv>
                    </DriverDetails>

                    <DriverDetails>
                        <FirstNameDiv>
                            <NameLabel>Phone Number</NameLabel>
                            <H6>{driverDetails?.phone_number || '.'}</H6>
                        </FirstNameDiv>

                    </DriverDetails>

                    <DriverDetails>
                        <FirstNameDiv>
                            <NameLabel>Location</NameLabel>
                            <H6>{driverDetails?.city || '.'}</H6>
                        </FirstNameDiv>

                    </DriverDetails>
                </AddressDiv>

                <Div style={{ marginLeft: 20, display: 'flex', alignItems: 'flex-end', gap: 6 }}>
                    <StatusLabel>Account</StatusLabel>
                    <BookingLabel>2 Bookings</BookingLabel>
                    <CartBox>
                        <FaRegCalendarAlt style={{ fontSize: 9 }} />
                        {userCart} Cart
                    </CartBox>



                    <CallDiv>
                        {
                            isModal &&
                            <BsEnvelope style={{ fontSize: 16 }} />
                        }
                        {
                            !isModal &&
                            <FaRegCalendarAlt style={{ fontSize: 16 }} />

                        }
                        <Link to={"tel:" + driverDetails?.phone_number}>
                            <CallButton>
                                <IoCallSharp />
                            </CallButton>
                        </Link>

                    </CallDiv>
                </Div>

            </DriverDiv>

        </>

    )
}

export default Customer