import React from 'react'
import { AmountTransfer, HistoryCardWrap, LeftHistoryBox, RightHistoryBox } from './paymenthistory.styles'

import receipt from '../../assets/images/svg/receipt-icon.svg'

const PaymentHistoryCard = ({date, time, type, amount, currency}:any) => {
  return (
    <>
        <HistoryCardWrap>
            <div className="inner-history-card-wrap">
                <LeftHistoryBox>
                    {/* <p>From: Peace</p> */}

                    <div className="date-time-wrap">
                        <h4>{date}</h4>

                        <h4>{time}</h4>
                    </div>
                </LeftHistoryBox>

                <RightHistoryBox>
                    <img src={receipt} alt="" />

                    <AmountTransfer type={type}>
                        {type === 'credit' ?
                         <p>{`+ ${currency} ${(Number(amount))?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}</p> :
                         <p>{`- ${currency} ${(Number(amount))?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}</p>
                        }

                    </AmountTransfer>
                </RightHistoryBox>
            </div>
        </HistoryCardWrap>
    </>
  )
}

export default PaymentHistoryCard