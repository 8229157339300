import React, { useEffect, useState } from 'react'
import Carousel from '../../../components/carousel'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import OffersCard from '../../../components/offersCard'
import OwnersNoteCard from '../../../components/ownersNoteCard'
import ProgressBar from '../../../components/progressBar'
import RoadlersCard from '../../../components/roadlersCard'
import { SearchOverall, SearchInput, CarouselSection, BookingQuestionsWrap, QuestionHeader, QuestionBox, PP, QuestionImg, ModalCallBtnWrap, ModalCallBtn, BookingFooterWrap, BookingFooterBtn, FeatureWrapper, SubFeatureWrap, TickBox, SetHoursBox, QuestImg, WhereImg, SelectBlackBox, BookingFlexWrap, BookingExtrasFlexWrap, BookingOverall } from '../CarBooking/CarBooking.styles'
import { SearchBtn, BoxExtras, H11, AssetOverall } from '../Homepage/homepage.styles'


import arrowDown from '../../../assets/images/svg/arrowDown.svg'
import whereTag from '../../../assets/images/svg/where-tag.svg'
import whenTag from '../../../assets/images/svg/when-tag.svg'
import noteTag from '../../../assets/images/svg/note-tag.svg'
import tickX from '../../../assets/images/svg/tickx-icon.svg'
import tickR from '../../../assets/images/svg/tickr-icon.svg'
import delivery from '../../../assets/images/png/delivery.png'
import AssetCard from '../../../components/assetCard'
import DeliveryLocationModal from '../../../components/deliveryLocationModal'
import ItemSizeModal from '../../../components/itemSizeModal'
import ExtrasModal from '../../../components/extrasModal'
import TimeDateModal from '../../../components/timeDateModal'
import { AppDispatch, useAppDispatch } from '../../../store'
import { setShowDeliveryLocation } from '../../../slices/showDeliveryLocation'
import { setShowExtraNote } from '../../../slices/showExtraNote'
import { setShowTimeDate } from '../../../slices/showTimeDate'
import { NavLink, useNavigate } from 'react-router-dom'
import { setShowItemSize } from '../../../slices/showItemSize'
import SearchComponent from '../../../components/searchComponent'
import uuid from 'react-uuid'
import { addToCart } from '../../../slices/cart'
import DeliveryLocationModal2 from '../../../components/deliveryLocationModal/deliveryLocationModal'
import { PaddingWrap } from '../CartPage/cartPage.styles'
import SearchUpdateComponent from '../../../components/searchUpdateComponent'




const DeliverBooking = () => {
    const dispatch: AppDispatch = useAppDispatch()
    const navigate = useNavigate();
    const [progressInfo, setProgressInfo] = useState('Start Booking - Let’s Move')
    const [progressbarNum, setProgressBarNum] = useState(1)
    const [whenModalBtn, setWhenModalBtn] = useState(false)
    const [whereModalBtn, setWhereModalBtn] = useState(false)
    const [bookedDate, setBookedDate] = useState([])
    const [pickupTime, setPickupTime] = useState('')
    const [carOwnerInstruction, setCarOwnerInstruction] = useState('')
    const [escortDays, setEscortDays] = useState('')
    const [escortNumber, setEscortNumber] = useState('')
    const [origin, setOrigin] = useState('')
    const [destination, setDestination] = useState('')
    const [singleMail, setSingleMail] = useState('')
    const [singleName, setSingleName] = useState('')
    const [singleNumber, setSingleNumber] = useState<any>()
    const [deliveryType, setDeliveryType] = useState('single')
    const [multipleDeliveryArray, setMultiplyDeliveryArray] = useState<any []>([])
    const [optionsSize, setOptionsSize] = useState('Select option')
    const [calculatedAmt, setCalculatedAmt] = useState(0)
    const [insuranceAmount, setInsuranceAmount] = useState<number>(0)
    const [insuranceType, setInsuranceType] = useState('')

    const basePrice = 700;


    const slides:any = [
        {img: delivery, name: 'Main Image', id: 0},
      ]

      const locationModalControl = () => {
        dispatch(setShowDeliveryLocation(true))
      } 

      const timeDateModalControl = () => {
        dispatch(setShowTimeDate(true))
      }
    
      const extraModalControl = () => {
        dispatch(setShowExtraNote(true))
      }

      const itemSizeModalControl = () => {
        dispatch(setShowItemSize(true))
      }

      const handleLocationComplete= (itemComplete:any) => {
        setWhereModalBtn(itemComplete)
      }
    
      const handleTimeComplete = () => {
        setWhenModalBtn(true)
      }

      useEffect(() => {
        if(bookedDate?.length !== 0 && pickupTime?.length !== 0) {
            handleTimeComplete()
        }
      }, [bookedDate, pickupTime])
    
      useEffect(() => {
        if(whereModalBtn === true) {
          setProgressBarNum(progressbarNum + 1)
        }else {
          setProgressBarNum(progressbarNum)
        }
      }, [whereModalBtn])
    
      useEffect(() => {
        if(whenModalBtn === true) {
          setProgressBarNum(progressbarNum + 1)
        }else {
          setProgressBarNum(progressbarNum)
        }
      }, [whenModalBtn])
    
      useEffect(() => {
        if(progressbarNum === 2) {
          setProgressInfo('Almost there - Keep Moving')
        }else if(progressbarNum === 3) {
          setProgressInfo('Completed - Proceed to Move with Ease')
        }
      }, [progressbarNum])


      const handleProceedBooking = (e:any) => {
        e.preventDefault()

        if(progressbarNum === 3) {
          if(deliveryType?.includes('single')) {
            const bookingDetails:any = {
              'item_size': optionsSize,
              'pickup_time': pickupTime,
              'pickup_date': bookedDate,
              'escort_personel': escortNumber,
              'escort_days': escortDays,
              'passenger_instruction': carOwnerInstruction,
              'pickup_location': origin,
              'delivery': [{
                receiver_name: singleName,
                receiver_mail: singleMail,
                receiver_phone: singleNumber,
                destination: destination,
                pickup_location: origin,
              }],
              'booking_title': 'Single Delivery',
              'booking_id': uuid(),
              'user_id': '',
              'insurance': insuranceType,
              'insurance_amount': insuranceAmount,
              'price': basePrice + calculatedAmt + insuranceAmount,
              'amount': basePrice + calculatedAmt + insuranceAmount,
              'no_of_items': 1,
              'total': basePrice + calculatedAmt + insuranceAmount,
              'num_of_day': bookedDate?.length,
            }

            dispatch(addToCart(bookingDetails))
            navigate('/cart')
          }else {
            const bookingDetails:any = {
              'item_size': optionsSize,
              'pickup_time': pickupTime,
              'pickup_date': bookedDate,
              'escort_personel': escortNumber,
              'escort_days': escortDays,
              'passenger_instruction': carOwnerInstruction,
              'pickup_location': origin,
              'delivery': multipleDeliveryArray,
              'booking_title': 'Multiple Delivery',
              'booking_id': uuid(),
              'user_id': '',
              'insurance': insuranceType,
              'insurance_amount': insuranceAmount,
              'price': basePrice + calculatedAmt + insuranceAmount,
              'amount': basePrice + calculatedAmt + insuranceAmount,
              'image': '',
              'no_of_items': multipleDeliveryArray?.length,
              'total': basePrice + calculatedAmt + insuranceAmount,
              'num_of_day': bookedDate?.length,
            }
            
              dispatch(addToCart(bookingDetails))
              navigate('/cart')
          }
        }
      }


  return (
    <>
        <DeliveryLocationModal setOrigin={setOrigin} origin={origin} setDestination={setDestination} destination={destination} setSingleMail={setSingleMail} singleMail={singleMail} setSingleName={setSingleName} singleName={singleName} setSingleNumber={setSingleNumber} singleNumber={singleNumber} setLocationBtn={handleLocationComplete} setMultiplyDeliveryArray={setMultiplyDeliveryArray} setDeliveryOption={setDeliveryType}/>
        <DeliveryLocationModal2 
          setOrigin={setOrigin} origin={origin} 
          setDestination={setDestination} destination={destination} setSingleMail={setSingleMail} singleMail={singleMail} setSingleName={setSingleName} singleName={singleName} setSingleNumber={setSingleNumber} singleNumber={singleNumber} setLocationBtn={handleLocationComplete} setMultiplyDeliveryArray={setMultiplyDeliveryArray} setDeliveryOption={setDeliveryType} setCalculatedAmt={setCalculatedAmt}
        />
        <ItemSizeModal itemModalActive={false} setOptionsSize={setOptionsSize} optionsSize={optionsSize} />

        <TimeDateModal handleBookedDate={setBookedDate} handlePickupTime={setPickupTime}/>

        <ExtrasModal handleInstruction={setCarOwnerInstruction} instruction={carOwnerInstruction} setEscortNum={setEscortNumber} setEscortDay={setEscortDays} insuranceAmount={insuranceAmount} setInsuranceAmount={setInsuranceAmount} insuranceType={insuranceType} setInsuranceType={setInsuranceType} insuranceLabel={'Would you like your goods to be covered by insurance on this trip?'}/>

        <Header />
        
        {/* <SearchComponent /> */}
        <PaddingWrap>
          <SearchUpdateComponent />
        </PaddingWrap>

        <BookingFlexWrap>
          <CarouselSection bg={'#fff'}>
            <Carousel 
              slides={slides} 
              display={'none'} 
              priceDisplay={'block'}
              title={'Make A Delivery'}
              displayAmount={`${Number(Math.ceil(basePrice + calculatedAmt + insuranceAmount))?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}
            />
          </CarouselSection>

          <BookingOverall>
            <div className="" style={{padding: '30px', paddingRight: '80px'}}>
              <ProgressBar 
                progressText={progressInfo}
                barDisplay={'flex'}
                progressNum={progressbarNum}
              />

              <BookingQuestionsWrap>
                <QuestionHeader>
                    What is the item size
                </QuestionHeader>
                <QuestionBox onClick={() => itemSizeModalControl()}>

                  <PP>{optionsSize}</PP>

                  <div className="flex-wrap">
                    {/* <SelectBlackBox>
                      <p>Select</p>
                    </SelectBlackBox> */}

                    <QuestionImg src={arrowDown}  width={'50px'}/>
                  </div>

                </QuestionBox>
                <ModalCallBtnWrap>
                  <ModalCallBtn complete={whereModalBtn} onClick={() => locationModalControl()}>
                    <WhereImg src={whereTag} width={'30px'} />

                    <p>Where?</p>
                  </ModalCallBtn>

                  <ModalCallBtn complete={whenModalBtn} onClick={() => timeDateModalControl()}>
                    <QuestImg complete={whenModalBtn} src={whenTag} width={'30px'} />

                    <p>When?</p>
                  </ModalCallBtn>

                  <ModalCallBtn complete={carOwnerInstruction || insuranceAmount ? true : false} onClick={() => extraModalControl()}>
                    <QuestImg complete={carOwnerInstruction || insuranceAmount ? true : false} src={noteTag} width={'25px'} />

                    <p>Extra/Note</p>
                  </ModalCallBtn>
                </ModalCallBtnWrap>
              </BookingQuestionsWrap>

              <BookingFooterWrap>
                <BookingFooterBtn text={'#fff'} bordercolor={'#fff'} bg={'#000'}>
                  ₦{`${Number(Math.ceil(basePrice + calculatedAmt + insuranceAmount))?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}
                </BookingFooterBtn>

                <BookingFooterBtn text={'#000'} bordercolor={'#ffa100'} bg={'#ffa100'} onClick={handleProceedBooking}>
                  Proceed Booking
                </BookingFooterBtn>

              </BookingFooterWrap>
            </div>
          </BookingOverall>

        </BookingFlexWrap>


        <BookingExtrasFlexWrap>
          <FeatureWrapper>
            <h4>Feature</h4>

            <SubFeatureWrap>
              <TickBox>
                <QuestionImg width={'15px'} src={tickR} />
              </TickBox>

              <p>24/7 delivery request</p>
            </SubFeatureWrap>

            <SubFeatureWrap>
              <TickBox>
                <QuestionImg width={'15px'} src={tickR} />
              </TickBox>

              <p>Local deliveries</p>
            </SubFeatureWrap>

            <SubFeatureWrap>
              <TickBox>
                <QuestionImg width={'15px'} src={tickR} />
              </TickBox>

              <p>Small & medium parcels</p>
            </SubFeatureWrap>
          </FeatureWrapper>

          <BoxExtras className="booking-section">
            <OwnersNoteCard />

            <RoadlersCard />
          </BoxExtras>
        </BookingExtrasFlexWrap>



        {/* <BoxExtras>
            <div className="subsection-header">
                <H11>
                  Related Offers
                </H11>
            </div>

            <AssetOverall>
                <OffersCard />
                <OffersCard />
            </AssetOverall>

        </BoxExtras> */}

        <Footer />
    </>
  )
}

export default DeliverBooking