import React, { useEffect, useRef, useState } from 'react'
import { LocationBox, UserLocationWrap, WhereOptionPill, WhereRadioPill, LocationInputWrap, InputIcons, InputBtn } from '../locationModal/locationmodal.styles'
import { CancelModalBox, ModalBox, ModalOverlay } from '../optionModal/optionmodal.styles'
import { useJsApiLoader, GoogleMap, Marker, Autocomplete } from '@react-google-maps/api'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import uuid from 'react-uuid';

import whereTag from '../../assets/images/svg/where-tag.svg'
import greyBox from '../../assets/images/svg/greybox.svg'
import profile from '../../assets/images/svg/user-icon-black.svg'
import mail from '../../assets/images/svg/mail.svg'
import { DeliveryInputOverall, DeliveryLocationWrap, DoneBtn, InputWrapOverall, ItemBtn, ItemsBtnList, SaveDeliveryBtn, DeleteFormBtn, DeleteFormWrap, } from './deliverylocation.styles'
import { SaveBtn } from '../extrasModal/extrasmodal.styles'
import { AppDispatch, RootState, useAppDispatch, useAppSelector } from '../../store'
import { setShowDeliveryLocation } from '../../slices/showDeliveryLocation'
import MultipleDeliveryInput from '../multipleDeliveryInput'

const center = {lat: 6.605874, lng: 3.349149}

const DeliveryLocationModal = ({setOrigin, setDestination, setSingleMail, setSingleName, setSingleNumber, singleNumber, setLocationBtn, origin, singleName, singleMail, destination, setMultiplyDeliveryArray, setDeliveryOption, previousDeliveryType, previousDeliveryInfo}: any) => {
    const dispatch: AppDispatch = useAppDispatch()
    const {showDeliveryLocation} = useAppSelector<any>((store:RootState) => store?.showDeliveryLocation)
    const [children, setChildren] = useState<any[]>([])
    const [deleteChildren, setDeleteChildren] = useState<any[]>([])
    const [where, setWhere] = useState('')
    const [deliveryType, setDeliveryType] = useState('single')
    const [map, setMap] = useState(null)
    const [value, setValue] = useState<any>()
    const [singleValue, setSingleValue] = useState<any>()
    const [receiverMailMulitple, setReceiverMailMultiple] = useState('')
    const [receiverNumMutliple, setReceiverNumMultiple] = useState('')
    const [receiverNameMultiple, setReceiverNameMultiple] = useState('')
    const [receiverAddressMultiple, setReceiverAddressMultiple] = useState('')
    const [receiverInfoArray, setReceiverInfoArray] = useState<any []>([])
    const [currentSlide, setCurrentSlide] = useState(0)
    const [autocomplete, setAutocomplete] = useState<any>(null)
    const [autocompleteOrigin, setAutocompleteOrigin] = useState<any>(null)
    const [formId, setFormId] = useState<any>('')

    const onPlaceChanged = () => {
        if(autocomplete !== null) {
            const places = autocomplete.getPlace().formatted_address
            if(places) {
                setDestination(places)
            }else {
                setDestination(autocomplete.getPlace().name)
            }
        }
    }

    const onOriginPlaceChanged = () => {
        if(autocompleteOrigin !== null) {
            const places = autocompleteOrigin.getPlace().formatted_address
            if(places) {
                setOrigin(places)
            }else {
                setOrigin(autocompleteOrigin.getPlace().name)
            }
        }
    }

    const originRef:any = useRef()
   

    const destinationRef:any = useRef()
    const numberRef:any = useRef()
    const mailRef:any = useRef()
    const nameRef:any = useRef()

    const libraries: (
        | 'places'
        | 'drawing'
        | 'geometry'
        | 'localContext'
        | 'visualization'
      )[] = ['places', 'geometry', 'drawing'];

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs',
        libraries,
    }) 

    async function calculateRoute() {
        if(origin === '' || destination.current.value === '') {
            return
        }
        const directionService = new google.maps.DirectionsService()
        const results = await directionService.route({
            origin: origin,
            destination: destination,
            travelMode: google.maps.TravelMode.DRIVING
        })

        // @ts-ignore
        setDirectionResponse(results)
        // @ts-ignore
        setDistance(results.routes[0].legs[0].distance.text)
        // @ts-ignore
        setDuration(results.routes[0].legs[0].duration.text)
    }

    const locationModalControl = () => {
        dispatch(setShowDeliveryLocation(false))
    }

    // EDITING THE DELIVERY INFORMATION
    useEffect(() => {
        if(previousDeliveryType) {
            if(previousDeliveryType?.toLowerCase()?.includes('single')) {
                setDeliveryType('single');
                setDeliveryOption('single');
                setOrigin(previousDeliveryInfo?.[0]?.pickup_location)
                setDestination(previousDeliveryInfo?.[0]?.destination)
                setSingleNumber(previousDeliveryInfo?.[0]?.receiver_phone)
                setSingleMail(previousDeliveryInfo?.[0]?.receiver_mail)
                setSingleName(previousDeliveryInfo?.[0]?.receiver_name)
            }else if(previousDeliveryType?.toLowerCase()?.includes('multiple')){
                setDeliveryType('multiple');
                setDeliveryOption('multiple');
                setOrigin(previousDeliveryInfo?.[0]?.pickup_location)
                setReceiverInfoArray(previousDeliveryInfo)
    
                if(previousDeliveryInfo?.length) {
                    previousDeliveryInfo?.map((item:any, i:any) => {
    
                        setChildren(children => [...children, <MultipleDeliveryInput 
                            mail={ item?.receiver_mail}
                            name={ item?.receiver_name}
                            value={item?.receiver_phone}
                            newAddress={item?.destination}
                            setMail={setReceiverMailMultiple}
                            setName={setReceiverNameMultiple}
                            setValue={setReceiverNumMultiple}
                            setNewAddress={setReceiverAddressMultiple}
                            setFormId={setFormId}
                            package_id={item?.package_id}
                            handleDelete={handleDeleteForm}
                        />])
                    })
    
                }
            }
        }

    }, [previousDeliveryType])
    // EDITING THE DELIVERY INFORMATION -CLOSE

    const addFirstInput = () => {
        // this is basically calling/recognizing the contents in the state and adding the component to it
        setChildren(children => [...children, <MultipleDeliveryInput 
            setMail={setReceiverMailMultiple}
            setName={setReceiverNameMultiple}
            setValue={setReceiverNumMultiple}
            setNewAddress={setReceiverAddressMultiple}
            setFormId={setFormId}
            key = {uuid()}
            handleDelete={handleDeleteForm}
        />])
    }

    const addMultipleInput = () => {
        // if (receiverNumMutliple?.trim()?.length > 3 && receiverAddressMultiple?.trim()?.length > 3 &&  receiverNameMultiple?.trim()?.length >= 2) {
            if (previousDeliveryInfo?.length) {
                if(receiverInfoArray?.length === children?.length) {
                    addFirstInput()
                }else {
                    const newDeliveryInput:any = {
                        receiver_name: receiverNameMultiple,
                        receiver_mail: receiverMailMulitple,
                        receiver_phone: receiverNumMutliple,
                        destination: receiverAddressMultiple,
                        pickup_location: origin,
                        package_id: formId,
                    } 
        
                    setReceiverInfoArray([  ...receiverInfoArray, newDeliveryInput,])
        
                    // this is basically calling/recognizing the contents in the state and adding the component to it
                    setChildren(children => [...children, <MultipleDeliveryInput 
                        setMail={setReceiverMailMultiple}
                        setName={setReceiverNameMultiple}
                        setValue={setReceiverNumMultiple}
                        setNewAddress={setReceiverAddressMultiple}
                        setFormId={setFormId}
                        package_id = {uuid()}
                        handleDelete={handleDeleteForm}
                    /> ])
                }
            } else {
                const newDeliveryInput:any = {
                    receiver_name: receiverNameMultiple,
                    receiver_mail: receiverMailMulitple,
                    receiver_phone: receiverNumMutliple,
                    destination: receiverAddressMultiple,
                    pickup_location: origin,
                    package_id: formId,
                } 
    
                setReceiverInfoArray([  ...receiverInfoArray, newDeliveryInput,])
    
                // this is basically calling/recognizing the contents in the state and adding the component to it
                setChildren(children => [...children, <MultipleDeliveryInput 
                    setMail={setReceiverMailMultiple}
                    setName={setReceiverNameMultiple}
                    setValue={setReceiverNumMultiple}
                    setNewAddress={setReceiverAddressMultiple}
                    setFormId={setFormId}
                    key = {uuid()}
                    package_id = {uuid()}
                    handleDelete={handleDeleteForm}
                /> ])
            }
            // }
    }

    const goToSlide = (id: any) => {
        setCurrentSlide(id)
    }

    const handleDeleteForm = (id:any) => {
        console.log('strangest',id)



        let newChildren = [...children];
        const filteredArray = newChildren.filter(item => item?.key !== id);

        // newChildren.splice(id, 1)
        // setChildren(filteredArray)

        setChildren(filteredArray)
    
        console.log(id, filteredArray, 'filt')
        setReceiverInfoArray(receiverInfoArray => receiverInfoArray?.filter((item:any) => {
            return item?.key !== id
        }))
    }

    console.log(children, 'child')
    console.log(receiverInfoArray, 'recei')

    const handleSingleSubmit = () => {
        if(origin?.trim()?.length > 0 && destination?.trim()?.length > 2 && singleName?.trim()?.length > 2 && singleNumber?.length !== 0) {
            locationModalControl()
            setLocationBtn(true)
        }
    }

    const handleMultipleSubmit = () => {
        setMultiplyDeliveryArray(receiverInfoArray)
        locationModalControl()
        setLocationBtn(true)
    }


  return (
    <ModalOverlay activeModal={false} >
        <CancelModalBox onClick={() => locationModalControl()}/>
        <div className="modal-inner-wrapper">
            <ModalBox>
                <div className="inner-modal-box">
                    {/* GOOGLE MAP BOX */}
                    <LocationBox>
                        {/* Google map box */}
                        {/*@ts-ignore */}
                        <GoogleMap 
                            center={center} 
                            zoom={10} 
                            mapContainerStyle={{width: '100%', height: '100%', borderRadius: '14px'}} 
                            options={{
                                zoomControl: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false
                            }}
                        >
                            <Marker position={center} />
                        </GoogleMap>

                    </LocationBox>

                    <DeliveryLocationWrap>
                        <h4>Where</h4>

                        <div className='where-pill-wrap'>
                            <WhereOptionPill onClick={() => {
                                setDeliveryType('single');
                                setDeliveryOption('single');
                            }}>
                                <WhereRadioPill pillActive={deliveryType === 'single' ? 'fill' : ''} />

                                <p>Single delivery </p>
                            </WhereOptionPill>

                            <WhereOptionPill onClick={() => {
                                setDeliveryType('multiple');
                                setDeliveryOption('multiple');
                            }}>
                                <WhereRadioPill pillActive={deliveryType === 'multiple' ? 'fill' : ''} />

                                <p>Multiple deliveries </p>
                            </WhereOptionPill>
                        </div>

                        <div className="location-input-overall">
                            <label>
                                Pickup location
                            </label>
                            <LocationInputWrap>
                                <InputIcons src={whereTag} iconWidth={'30px'} />
                                <Autocomplete className='autocomplete-wrap'
                                onPlaceChanged={onOriginPlaceChanged}
                                onLoad={(value) => setAutocompleteOrigin(value)}
                                >
                                    <input type="text" placeholder='Type here'
                                    // ref={originRef}
                                    value={origin}
                                    onChange={(e:any) => setOrigin(e.target.value) }
                                    />
                                </Autocomplete>   
                            </LocationInputWrap>

                            {
                                // SINGLE DELIVERY FORM
                                deliveryType === 'single' ?
                                <>
                                    <label>
                                        Delivery location
                                    </label>
                                    <LocationInputWrap>
                                        <InputIcons src={greyBox} iconWidth={'30px'} />
                                        <Autocomplete className='autocomplete-wrap'
                                        onPlaceChanged={onPlaceChanged}
                                        onLoad={(value) => setAutocomplete(value)}>
                                            <input type="text" placeholder='Type here'
                                            // ref={destinationRef}
                                            value={destination}
                                            onChange={(e:any) => setDestination(e.target.value)}
                                            />
                                        </Autocomplete>
                                        
                                        {/* <InputBtn>
                                            <p>Done</p>
                                        </InputBtn> */}
                                    </LocationInputWrap>

                                    <InputWrapOverall>
                                        <LocationInputWrap>
                                            <PhoneInput
                                                defaultCountry='US'
                                                international={true}
                                                placeholder="Enter phone number"
                                                value={singleNumber}
                                                onChange={setSingleNumber}
                                            />
                                        </LocationInputWrap>
                                    </InputWrapOverall>

                                    <InputWrapOverall>
                                        <LocationInputWrap>
                                            <InputIcons src={mail} iconWidth={'30px'} />
                                            <input type="email" placeholder='Reciever’s Email'
                                            // ref={mailRef}
                                            value={singleMail}
                                            onChange={(e:any) => setSingleMail(e.target.value)}
                                            />
                                        </LocationInputWrap>
                                    </InputWrapOverall>

                                    <InputWrapOverall>
                                        <LocationInputWrap>
                                            <InputIcons src={profile} iconWidth={'20px'} />
                                            <input type="text" placeholder='Reciever’s Name'
                                            // ref={nameRef}
                                            value={singleName}
                                            onChange={(e:any) => setSingleName(e.target.value)}
                                            />
                                        </LocationInputWrap>
                                    </InputWrapOverall>

                                    <div className="save-wrapper">
                                        <SaveDeliveryBtn onClick={handleSingleSubmit}>
                                            Save
                                        </SaveDeliveryBtn>
                                    </div>                            
                                </> 
                                    :
                                // MULTIPLE DELIVERY FORM
                                <>

                                    <DeliveryInputOverall>
                                    {
                                        children?.map((child:any, id:any) => (
                                            <div 
                                            className='input-box-wrapper'
                                            key={id}
                                            style={{transform: `translate(-${currentSlide * 100}%)`}}
                                            >
                                                {child}
                                            </div>
                                        ) )
                                    }
                                    </DeliveryInputOverall>
                                    {
                                        // children?.length > 1 &&
                                        <DeleteFormWrap>
                                            {children?.map((item:any, id:any) => (
                                            <DeleteFormBtn display={id === currentSlide ? 'flex' : 'none'}
                                            onClick={() => handleDeleteForm(item?.key)}
                                            >
                                                Delete
                                            </DeleteFormBtn>
                                            ))}
                                        </DeleteFormWrap>
                                    }

                                     <div className="save-add-wrapper">
                                        {
                                            children?.length === 0 ? 
                                            <SaveDeliveryBtn onClick={() => addFirstInput()}>
                                                Add Item
                                            </SaveDeliveryBtn> : 
                                            <SaveDeliveryBtn onClick={() => addMultipleInput()}>
                                                Save & Add Item
                                            </SaveDeliveryBtn>
                                        }

                                        {
                                            children?.length > 1 &&
                                            <DoneBtn onClick={() => handleMultipleSubmit()}>
                                                Done
                                            </DoneBtn>
                                        }

                                    </div>     

                                    <ItemsBtnList>
                                        {children?.map((child:any, id) => (
                                            <ItemBtn key={id} onClick={() => goToSlide(id)}
                                                active={id === currentSlide ? true : false}
                                            >
                                                Item {id + 1}
                                            </ItemBtn>
                                        ))}
                                    </ItemsBtnList>                       
                                </>
                            }

                        </div>
                    </DeliveryLocationWrap>
                </div>
            </ModalBox>
        </div>
    </ModalOverlay>
  )
}

export default DeliveryLocationModal