import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';


interface CustomError {
    data: {
        error: string;
        message: any;
        statusCode: number;
    };
    status: number;
}

export const baseUrl = process.env.REACT_APP_API_BASE_URL;


export const providerApi = createApi({
    reducerPath: 'providerApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState)?.auth?.auth?.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
    tagTypes: ['providers'],
    endpoints: builder => ({
        createOTP: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'otp-authentication',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
            invalidatesTags: ["providers"]
        }),
        confirmOTP: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'otp-confirm',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),
        createAccount: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'sign-up',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),

        providerUpdate: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'complete-registration',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),
        carRegistration: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'new-vehicle',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),
        carUpdate: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-vehicle-registration',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),
        carPictureUpdate: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-vehicle-picture',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),
        driverPictureUpdate: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-driver-picture',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),
        
        driverUpdate: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-driver-registration',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),

         driverProfileUpdate: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-driver-profile',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),
        carPriceUpdate: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-service-price',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),
        driverPriceUpdate: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-service-price',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),

        addNewCarPrice: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'new-service-price',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),
                carTravelUpdate: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-travel',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),
        carTravelAdd: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'travel-trip',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),
        
        
        bikeRegistration: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'bike-registration',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),
        driverRegistration: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'add-driver',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),


        Login: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'login',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        updateProfile: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-profile',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),

        carOwnerProfile: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'car-owner-registration',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        updateProvider: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-provider',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        uploadInsurance: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'insurance-document',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        updateInsurance: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-insurance-doc',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        updateBusinessDoc: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'business-certificate',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        
        updateKin: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-next-of-kin',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        updateGuarantor: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-guarantor',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        nextofKin: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'next-of-kin',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        guarantor:builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'guarantor',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        deleteVehicle: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'delete-vehicle',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        deleteDriver: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'delete-driver',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        forgotPassword: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'password-reset-link',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        acceptRequest: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'confirm-request',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        rejectRequest: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'confirm-request',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        updateRequest: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-request',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        getVehicles: builder.query({
            query: (id) => {
                return {
                    url: `vehicle-assets/${id}`,
                };
            },
        }),

        getBikes: builder.query({
            query: (id) => {
                return {
                    url: `bike-asset/${id}`,
                };
            },
        }),

        getDrivers: builder.query({
            query: (id) => {
                return {
                    url: `driver-assets/${id}`,
                };
            },
        }),

        getRequests: builder.query<any, void>({
            query: () => {
                return {
                    url: 'list-request-service-provider',
                };
            }
        }),

        getVehicleById: builder.query<any, void>({
            query: (id) => {
                return {
                    url: `fetch-vehiclebyidsingle/${id}`,
                };
            },
        }),

        getBusinessById: builder.query<any, void>({
            query: (id) => {
                return {
                    url: `pending-approval-business/${id}`,
                };
            },
        }),
        getBikeById: builder.query({
            query: (id) => {
                return {
                    url: `bike-details/${id}`,
                };
            },
        }),
        getDriverById: builder.query({
            query: (id) => {
                return {
                    url: `fetch-driver-provider/${id}`,
                };
            },
        }),
        getProviderById: builder.query({
            query: (id) => {
                return {
                    url: `view-provider-profile/${id}`,
                };
            },
        }),
        getNextOfKinByProviderId: builder.query({
            query: (id) => {
                return {
                    url: `provider-next-of-kin/${id}`,
                };
            },
        }),
    
        getInsurance: builder.query({
            query: (id) => {
                return {
                    url: `view-insurance-doc/${id}`,
                };
            }
        }),

        providerPictureUpdate: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-provider-picture',
                    method: 'post',
                    body: value
                };
            },
        }),

        getTravelGarage: builder.query<any, void>({
            query: () => {
                return {
                    url: 'list-all-travels',
                };
            }
        }),
        getTravelById: builder.query({
            query: (id) => {
                return {
                    url: `list-travel-by-id/${id}`,
                };
            }
        }),
        travelGarage: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'travel-garage',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
        }),
        deleteTravelGarage: builder.mutation<any, void>({
            query: (id) => {
                return {
                    url: `delete-travel-garage/${id}`,
                    method: 'post',
                    // body, 
                    // body: value
                };
            },
        }),

        
      

    }),

    
});

export const {
    useForgotPasswordMutation,
    useCreateOTPMutation,
    useConfirmOTPMutation,
    useCreateAccountMutation,
    useLoginMutation,
    useProviderUpdateMutation,
    useCarRegistrationMutation,
    useCarUpdateMutation,
    useCarPictureUpdateMutation,
    useDriverPictureUpdateMutation,
    useDriverUpdateMutation,
    useCarPriceUpdateMutation,
    useDriverPriceUpdateMutation,
    useAddNewCarPriceMutation,
    useCarTravelUpdateMutation,
    useCarTravelAddMutation,
    useBikeRegistrationMutation,
    useDriverRegistrationMutation,
    useUpdateProfileMutation,
    useDriverProfileUpdateMutation,
    useCarOwnerProfileMutation,
    useUpdateProviderMutation,
    useUpdateBusinessDocMutation,
    useNextofKinMutation,
    useGuarantorMutation,
    useUploadInsuranceMutation,
    useUpdateKinMutation,
    useUpdateGuarantorMutation,
    useUpdateInsuranceMutation,
    useAcceptRequestMutation,
    useRejectRequestMutation,
    useUpdateRequestMutation,
    useDeleteVehicleMutation,
    useDeleteDriverMutation,
    useGetVehiclesQuery,
    useGetBikesQuery,
    useGetDriversQuery,
    useGetVehicleByIdQuery,
    useGetProviderByIdQuery,
    useGetBusinessByIdQuery,
    useGetBikeByIdQuery,
    useGetDriverByIdQuery,
    useGetRequestsQuery,
    useGetNextOfKinByProviderIdQuery,
    useGetInsuranceQuery,
    useProviderPictureUpdateMutation,
    useGetTravelGarageQuery,
    useTravelGarageMutation,
    useGetTravelByIdQuery,
    useDeleteTravelGarageMutation,

} = providerApi;