import styled from 'styled-components'

export const BlogCardBtn = styled.button`
  outline: none;
  border: ${(props: any) => props.variant === 'outline' ? 'solid 1px #000' : 'none'};
  background-color: ${(props: any) => props.variant === 'outline' ? '#fff' : '#000' } ;
  font-size: 16px;
  font-weight: 600;
  color: ${(props: any) => props.variant === 'outline' ? '#000' : '#fff'};
  cursor: pointer;
  padding: 15px 40px;
  border-radius: 7px;
`

export const FailedBtn = styled(BlogCardBtn) `
    background-color: greenyellow;
    transition: 0.3s all ease;
    &:hover {
        background-color: red;
    }
`