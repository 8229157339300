import React, { FC, useEffect, useRef, useState } from "react";
import {
  Modal,
  Div,
  Labels,
  INPUTDIV,
  Inputs,
  Texts,
  BLACKDIV,
  DISCOUNT,
  ButtonArea,
  DoneButton,
  SaveButton,
  LocationInput,
  PriceInput,
} from "../../providerCarReg/fifthScreen/fifthScreen.styles";
import map from '../../../assets/images/png/map.png'
import rect from "../../../assets/images/png/rect.png";
import location from "../../../assets/images/png/location.png";
import line from "../../../assets/images/png/line.png";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import modalOption from "../../../assets/images/png/modalOption.png";
import add from "../../../assets/images/svg/triangle-up.svg";
import subtract from "../../../assets/images/svg/triangle-down.svg";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import { setVehicleDetailss } from "../../../slices/provider";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  //   Polyline,
  useJsApiLoader,
  Autocomplete,
} from "@react-google-maps/api";

import {
  Box,
  Button,
  DiscountBox,
  FlexBox,
  IconBox,
  ImageBox,
  Input,
  InputBox,
  Label,
  ModalContainer,
} from "../../discountModal/index.styles";
import { LocationBox } from "../../locationModal/locationmodal.styles";
import CurrencyInput from "react-currency-input-field";
import { formatCurrencyPrefix } from "../../../services/utils";

type Props = {
  driverDetails: any;
  setDriverDetails: any;
  setIsDiscountVisible: any;
  travelDetails: any;
  setIsVisibleTravelTwo: any;
  setIsVisibleModalOne: any;
};

const center = { lat: 6.605874, lng: 3.349149 };

export const ModalOne: FC<Props> = ({
  travelDetails,
  setIsVisibleTravelTwo,
  driverDetails,
  setDriverDetails,
  setIsVisibleModalOne,
}) => {
  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );
  const [addresses, setAddresses] = useState<any[]>([]);

  const [directionResponse, setDirectionResponse] = useState(null);

  const [destination, setDestination] = useState(
    travelDetails?.destination ? travelDetails?.destination : ""
  );
  const [pickupState, setPickupState] = useState(
    travelDetails?.pickup_location ? travelDetails?.pickup_location : ""
  );
  const [price, setPrice] = useState(
    travelDetails?.price ? travelDetails?.price : ""
  );

  const [autocomplete, setAutocomplete] = useState<any>(null);
  const [autocompleteDestination, setAutocompleteDestination] =
    useState<any>(null);

  const handleClose = () => {
    setIsVisibleModalOne(false);
  };
  const handleNewDestination = () => {
    let newServicePrice = [
      ...driverDetails.travel_trip,
      {
        destination,
        pickup_location: pickupState,
        price,
        discount: 0,
      },
    ];
    let newServiceId = newServicePrice.map((item, i) => {
      return {
        ...item,
        title: i + 1,
      };
    });
    setDriverDetails({
      ...driverDetails,
      travel_trip: newServiceId,
    });
    setIsVisibleModalOne(false);
  };

  const initiateNewDestination = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (driverDetails.travel_trip?.length >= 7) {
      notifyError("You Can only Set up to 7 Travel Destinations");

      handleClose();
    } else {
      handleNewDestination();
    }
  };

  const handleTravelPrice = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setPrice((event.target as HTMLTextAreaElement).value);
  };

  const handleTravelOne = () => {
    const modifiedTravels = driverDetails?.travel_trip?.map((travel: any) => {
      if (travel.title === travelDetails?.title) {
        return {
          ...travel, // Spread the original object
          pickup_location: pickupState,
          destination: destination,
          price: price,
        };
      }
      return travel; // Return unchanged object for other titles
    });

    setDriverDetails({
      ...driverDetails,
      travel_trip: modifiedTravels,
    });
    if (modifiedTravels?.length < 1) {
      handleNewDestination();
    }

    handleClose();
  };
  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const places = autocomplete.getPlace().formatted_address;
      if (places) {
        setPickupState(places);
      } else {
        setPickupState(autocomplete.getPlace().name);
      }
    }
  };
  const onPlaceChangedDestination = () => {
    if (autocompleteDestination !== null) {
      const places = autocompleteDestination.getPlace().formatted_address;
      if (places) {
        setDestination(places);
      } else {
        setDestination(autocompleteDestination.getPlace().name);
      }
    }
  };
  const handleAddAddress = (newAddress: any) => {
    console.log(newAddress, "new");
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: newAddress }, (results: any, status) => {
      if (status === "OK") {
        const location = results[0].geometry.location;
        setAddresses([...addresses, location]);
        // console.log(`Geocoding error: ${location}`);
      } else {
        console.log(`Geocoding error: ${status}`);
      }
    });
  };
  useEffect(() => {
    if (pickupState) {
      handleAddAddress(pickupState);
    }
  }, [pickupState]);

  useEffect(() => {
    if (destination) {
      handleAddAddress(destination);
    }
  }, [destination]);

  return (
    <ModalContainer onClick={() => handleClose()}>
      <ToastContainer />

      <Modal onClick={(e: any) => e.stopPropagation()}>
        <LocationBox>
        <img src={map} width="100%" height="100%"/>

        </LocationBox>

        <Labels style={{ padding: "10px 10px" }}>Where</Labels>
        <Labels
          style={{
            marginBottom: "10px",
            padding: "1px 10px",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          Travel out of state Maximum of 7 destinations
        </Labels>

        <INPUTDIV style={{ margin: "25px 0px" }}>
          <Autocomplete
            className="autocomplete-wrap"
            onPlaceChanged={onPlaceChanged}
            onLoad={(value) => setAutocomplete(value)}
          >
            <LocationInput
              type="text"
              placeholder="Type State"
              value={pickupState}
              onChange={(e: any) => {
                // setOrigin(e.target.value);
                setPickupState(e.target.value);
              }}
              style={{
                width: "100%",
                color: "#000",
                padding: "10px 10px 10px 40px",
                fontSize: "15px",
              }}
            />
          </Autocomplete>

          <Texts>Pickup location</Texts>
          <img
            src={location}
            alt=""
            style={{ position: "absolute", left: "-3px" }}
          />
          <img
            src={line}
            alt=""
            style={{
              position: "absolute",
              left: "20px",
              top: "40px",
              zIndex: "1",
            }}
          />
        </INPUTDIV>
        <INPUTDIV>
          <Autocomplete
            className="autocomplete-wrap"
            onPlaceChanged={onPlaceChangedDestination}
            onLoad={(value) => setAutocompleteDestination(value)}
          >
            <LocationInput
              type="text"
              placeholder="Type States"
              value={destination}
              onChange={(e: any) => {
                setDestination(e.target.value);
              }}
              style={{
                width: "100%",
                color: "#000",
                padding: "10px 10px 10px 40px",
                fontSize: "15px",
              }}
            />
          </Autocomplete>

          <Texts>Travel location(s)</Texts>
          <img
            src={rect}
            alt=""
            style={{ position: "absolute", left: "12px" }}
          />
        </INPUTDIV>
        <INPUTDIV style={{ margin: "15px 0px" }}>
          <PriceInput
            type="text"
            value="Set Price"
            readOnly
            style={{
              width: "60%",
              color: "#000",
              padding: "10px",
              fontSize: "15px",
            }}
          />
          <BLACKDIV>
            {/* <P style={{ left: '0px', top: '-37px' }}>Set Price</P> */}
            

            <Inputs>
              <CurrencyInput
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  color: "#fff",
                }}
                decimalsLimit={2}
                prefix={formatCurrencyPrefix(currentLocation)}

                placeholder="0.00"
                value={price}
                defaultValue={0.0}
                onValueChange={(e: any) => setPrice(e)}
                // setPrice((event.target as HTMLTextAreaElement).value)
              />
            </Inputs>
          </BLACKDIV>
        </INPUTDIV>
        <ButtonArea>
          <SaveButton onClick={initiateNewDestination}>
            Save & Add New
          </SaveButton>
          <DoneButton onClick={handleTravelOne}>Done</DoneButton>
        </ButtonArea>
      </Modal>
    </ModalContainer>
  );
};

export const TravelDiscountModal: FC<Props> = ({
  travelDetails,
  setIsDiscountVisible,
  setDriverDetails,
  driverDetails,
}) => {
  const dispatch: any = useAppDispatch();

  const [discount, setDiscount] = useState(
    travelDetails?.discount ? travelDetails?.discount : 0
  );

  const handleIncrease = () => {
    setDiscount((prev: number) => prev + 1);
  };
  const handleDecrease = () => {
    if (discount === 0) return;
    setDiscount((prev: number) => prev - 1);
  };

  const handleDiscount = () => {
    const modifiedTravelDiscount = driverDetails?.travel_trip?.map(
      (travel: any) => {
        if (travel.title === travelDetails?.title) {
          return {
            ...travel, // Spread the original object
            discount: discount,
          };
        }
        return travel; // Return unchanged object for other titles
      }
    );

    setDriverDetails({
      ...driverDetails,
      travel_trip: modifiedTravelDiscount,
    });
  };

  const handleSetDiscount = () => {
    // operation(discount, fullDayArray, discountFor)
    handleDiscount();
    setIsDiscountVisible(false);
  };

  return (
    <ModalContainer onClick={() => setIsDiscountVisible(false)}>
      <Box onClick={(e: any) => e.stopPropagation()} style={{ bottom: 150 }}>
        <ImageBox>
          <img src={modalOption} height="100%" width="100%" />
        </ImageBox>
        <DiscountBox>
          <Label>Attract more clients by offering a discount. </Label>

          <FlexBox>
            <InputBox>
              {/* <Input value={discount} /> */}

              <div
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  display: "flex",
                  flex: 1,
                }}
              >
                <p style={{ margin: 0, fontSize: 20, fontWeight: "bold" }}>
                  {discount < 10 ? `0${discount} %` : `${discount} %`}
                </p>

                <IconBox>
                  <FiChevronUp
                    style={{ fontWeight: 800 }}
                    onClick={handleIncrease}
                  />
                  <FiChevronDown onClick={handleDecrease} />
                </IconBox>
              </div>
            </InputBox>
            <Button onClick={handleSetDiscount}>Set Discount</Button>
          </FlexBox>
        </DiscountBox>
      </Box>
    </ModalContainer>
  );
};
