import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import car from '../../../assets/images/png/Car.png'

import {
    DateBox, H1,
    P,
    H4,
    Box,
    Boxes,
    DarkBox,
    CounterFilterBox,
    Text,
    Button,
    Label,
    LabelBoxes,
    FullTimeButton
} from './index.styles';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setAllBooking, setAllDriver } from '../../../slices/admin';
import { baseUrl, useGetAdminDriversQuery, useGetAdminRequestsQuery, useGetFetchTotalRequestsQuery } from '../../../services/adminApi';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast'
import { Toaster } from "react-hot-toast";
type Props = {
    driver?: boolean;
    request?: boolean;
    vehicle?: boolean;
    provider?: boolean;
    customer?: boolean;
    business?: boolean;
    requests?:any;
}
const CounterFilter: FC<Props> = ({requests, business, driver, request, vehicle, provider, customer }) => {
    const dispatch: any = useAppDispatch()
    const user: any = useAppSelector((state: any) => state?.auth?.auth)

    let [isDriverClicked, setIsDriverClicked] = useState(false)
    let [isVehicleClicked, setIsVehicleClicked] = useState(false)
    let [isTravelClicked, setIsTravelClicked] = useState(false)
    let [isDeliveryClicked, setIsDeliveryClicked] = useState(false)

    let [isDailyActive, setIsDailyActive] = useState(false)
    let [isFullTimeActive, setIsFullTimeActive] = useState(false)



    const { data: adminRequests, isFetching: isFetchingRequests, refetch: refetchRequests } = useGetAdminRequestsQuery(1)
    const { data: totalRequests, isFetching: isFetchingTotalRequests, refetch: refetchTotalRequests } = useGetFetchTotalRequestsQuery()

    const drivers: any = useAppSelector((state: any) => state?.admin?.allDriver)

    const { data: adminDrivers, isFetching: isFetchingDrivers, refetch: refetchDrivers } = useGetAdminDriversQuery(1)


    useEffect(() => {
        refetchTotalRequests()
    }, [refetchTotalRequests, totalRequests])

    // useEffect(() => {
    //     refetchRequests()
    // }, [refetchRequests, adminRequests])

    // const driverFilter = () => {
    //     setIsVehicleClicked(false)
    //     setIsDriverClicked(prev => !prev)
    //     dispatch(setAllBooking(adminRequests?.data?.filter((item: any) => item?.category === 'Driver Booking')))
    // }

    // const vehicleFilter = () => {
    //     setIsVehicleClicked(prev => !prev)
    //     setIsDriverClicked(false)
    //     dispatch(setAllBooking(adminRequests?.data?.filter((item: any) => item?.category === 'Car Booking')))
    // }

    const month = new Date().toLocaleString(
        'default', { month: 'long' }
    );


    const handleDayDriver = () => {
        // dispatch(setAllDriver(
        //     adminDrivers?.data?.filter((dailyDriver: any) => dailyDriver?.availability?.includes('Da'))))
        // setIsDailyActive(prev => !prev)
        // setIsFullTimeActive(false)
    }
    const handleFullTimeDriver = async () => {
        let promise: any;
        promise = toast.loading('Loading...');

        let formData = new FormData();
        formData.append("search_value", 'Full-Time Driver');
        axios({
            method: "post",
            url: `${baseUrl}search-fulltime-driver`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then(function (response) {
            const message = response.data.data;
        }).catch(function (error) {
            //handle error
            console.log("error ", error.response.data);
        });

    }



    const getFilteredRequest = async (data: string) => { 

        dispatch(setAllBooking(requests?.filter((request: any) => {
            return request?.category?.includes(data)||request?.booking_title?.includes(data)
        })))


    }

    return (


        <CounterFilterBox >
            <Toaster />

            {request && <>

                <Box>
                    <DateBox>
                        <H1>{totalRequests?.data?.totalMonthRequest}</H1>
                        <P>{totalRequests?.data?.totalMonthRequest.length > 1 ? 'Requests' : 'Request'}</P>
                    </DateBox>
                    <H4>In {month}</H4>

                </Box>

                <DarkBox>
                    <Boxes>
                        <Button onClick={() => {
                            getFilteredRequest('Driver Booking');
                            setIsDriverClicked(true);
                            setIsVehicleClicked(false);
                            setIsTravelClicked(false);
                            setIsDeliveryClicked(false);


                        }}
                            style={isDriverClicked ? { background: '#ffa100' } : { background: 'transparent' }} ></Button>
                        <Text>Drivers</Text>
                    </Boxes>
                    <Boxes>
                        <Button onClick={() => {
                            getFilteredRequest('Car Booking');
                            setIsVehicleClicked(true);
                            setIsDriverClicked(false);
                            setIsTravelClicked(false);
                            setIsDeliveryClicked(false);


                        }} style={isVehicleClicked ? { background: '#ffa100' } : { background: 'transparent' }}  ></Button>
                        <Text>Vehicles</Text>
                    </Boxes>
                    <Boxes>
                        <Button
                            onClick={() => {
                                getFilteredRequest('Delivery');
                                setIsDeliveryClicked(true);
                                setIsDriverClicked(false);
                                setIsVehicleClicked(false);
                                setIsTravelClicked(false);



                            }}
                            style={isDeliveryClicked ? { background: '#ffa100' } : { background: 'transparent' }} ></Button>


                        <Text>Deliveries</Text>
                    </Boxes>
                    <Boxes>
                        <Button onClick={() => {
                            getFilteredRequest('Travel');
                            setIsTravelClicked(true);
                            setIsDriverClicked(false);
                            setIsVehicleClicked(false);
                            setIsDeliveryClicked(false);

                        }}
                            style={isTravelClicked ? { background: '#ffa100' } : { background: 'transparent' }} ></Button>

                        <Text>Travel</Text>
                    </Boxes>
                </DarkBox>
            </>}



            {
                driver && <div style={{ height: 150, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                    <LabelBoxes>
                        <Label style={{ background: '#ffa100' }}>
                            <Link to='/list-drivers' style={{ background: '#ffa100', color: '#000', textDecoration: 'none' }}>Drivers</Link>

                        </Label>
                        <Label><Link to='/list-customers' style={{ color: '#fff', textDecoration: 'none' }}>Customers</Link></Label>
                        <Label><Link to='/list-providers' style={{ color: '#fff', textDecoration: 'none' }}>Providers</Link></Label>
                        <Label><Link to='/list-business' style={{ color: '#fff', textDecoration: 'none' }}>Business</Link></Label>

                    </LabelBoxes>

                    <DarkBox style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Boxes onClick={() => handleDayDriver()}>
                            <Button isDailyActive={isDailyActive}></Button>
                            <Text >Daily Drivers</Text>
                        </Boxes>
                        <Boxes onClick={() => handleFullTimeDriver()}>
                            <FullTimeButton isFullTimeActive={isFullTimeActive}></FullTimeButton>
                            <Text>Full Time Drivers</Text>
                        </Boxes>

                    </DarkBox>
                </div>
            }

            {
                provider &&

                <div style={{ height: 150, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                    <LabelBoxes>
                        <Label><Link to='/list-drivers' style={{ color: '#fff', textDecoration: 'none' }}>Drivers</Link></Label>
                        <Label><Link to='/list-customers' style={{ color: '#fff', textDecoration: 'none' }}>Customers</Link></Label>
                        <Label
                            style={{ background: '#ffa100', color: '#000', textDecoration: 'none' }} >
                            <Link style={{ color: '#000', textDecoration: 'none' }} to='/list-providers'>Providers</Link>
                        </Label>


                        <Label><Link to='/list-business' style={{ color: '#fff', textDecoration: 'none' }}>Business</Link></Label>

                    </LabelBoxes>

                    <DarkBox style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Boxes>
                            <Button style={{ background: "#ffa100" }}></Button>
                            <Text >Vehicle Owner</Text>
                        </Boxes>
                        <Boxes >
                            <Link to='/list-business' style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                <Button></Button>
                                <Text>Business Owner</Text></Link>
                        </Boxes>

                    </DarkBox>
                </div>
            }


            {
                business &&

                <div style={{ height: 150, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                    <LabelBoxes>
                        <Label><Link to='/list-drivers' style={{ color: '#fff', textDecoration: 'none' }}>Drivers</Link></Label>
                        <Label><Link to='/list-customers' style={{ color: '#fff', textDecoration: 'none' }}>Customers</Link></Label>
                        <Label >
                            <Link style={{ color: '#fff', textDecoration: 'none' }} to='/list-providers'>Providers</Link>
                        </Label>


                        <Label style={{ background: '#ffa100', color: '#000', textDecoration: 'none' }}><Link to='/list-business' style={{ color: '#000', textDecoration: 'none' }}>Business</Link></Label>

                    </LabelBoxes>

                    <DarkBox style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Link to='/list-providers'>
                            <Boxes>
                                <Button></Button>
                                <Text >Vehicle Owner</Text>
                            </Boxes>
                        </Link>
                        
                        <Boxes>
                            <Button style={{ background: "#ffa100" }}></Button>
                            <Text>Business Owner</Text>
                        </Boxes>

                    </DarkBox>
                </div>
            }


            {
                customer && <div style={{ height: 150, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                    <LabelBoxes>
                        <Label ><Link to='/list-drivers' style={{ color: '#fff', textDecoration: 'none' }}>Drivers</Link></Label>
                        <Label style={{ background: '#ffa100', color: '#000', textDecoration: 'none' }}>Customers</Label>
                        <Label><Link to='/list-providers' style={{ color: '#fff', textDecoration: 'none' }}>Providers</Link></Label>
                        <Label><Link to='/list-business'
                            style={{ color: '#fff', textDecoration: 'none' }}>Business</Link></Label>

                    </LabelBoxes>


                </div>
            }
        </CounterFilterBox >
    )
}

export default CounterFilter