import styled from 'styled-components'

export const H3 = styled.h3`
font-weight:500;
color:#ffa100;
`
export const P = styled.p`
font-weight:500;
color:#fff;
`
export const P2 = styled.p`
font-weight:500;
color:#fff;
font-size:12px;
text-align:center;
`
export const DOCDIV = styled.div`
display:flex;
width:100%;
gap:10px;
margin:10px 0px 40px 0px;

`
export const FlexDiv = styled.div`
padding-top:85px;
display:flex;
align-items:center;
justify-content:space-between;

`
export const DOC= styled.div`
width:33%;
display:flex;
flex-direction:column;

`
export const DOC1 = styled.div`
display:flex;
background: rgba(18, 142, 64, 0.62);
border: 3px solid rgba(18, 142, 64, 0.62);
border-radius:12px;
height:100px;
justify-content:center;
align-items:center;
position:relative;
color:#fff;
overflow:hidden;
`
export const DOC2 = styled.div`
display:flex;
background: rgba(255, 43, 43, 0.51);
border: 3px solid rgba(255, 43, 43, 0.51);
border-radius:12px;
height:100px;
justify-content:center;
align-items:center;
position:relative;
color:#fff;
overflow:hidden;
`
export const DOC3 = styled.div`
display:flex;
background: rgba(248, 139, 14, 0.75);
border: 3px solid rgba(248, 139, 14, 0.75);
border-radius:12px;
height:100px;
justify-content:center;
align-items:center;
color:#fff;
position:relative;
overflow:hidden;
`
export const H4 = styled.h4`
font-weight:500;
color:#ffa100;
`
export const SELECT = styled.select`
font-weight:500;
color:#ffa100;
padding-top:20px;
background:transparent;
width:50%;

text-align:center;
display:flex;
justify-content:center;
align-items:center;

`
export const Skip=styled.button`
border-radius:7px;
padding:7px 15px;
border:1px solid #ffa100;
color:#ffa100;
background:transparent;
font-size:12px;
`
export const UploadButton = styled.button`
background: #ffa100;
color: #000;
padding:15px 12px;
display:flex;
align-items:center;
height:50px;
margin-top:50px;
text-align:center;
border-radius:7px;
border:none;
outline:none;
font-size:15px;
`
export const DisabledButton = styled.button`
background: #000;
color: #aaa;
padding:15px 12px;
display:flex;
align-items:center;
height:50px;
margin-top:50px;
text-align:center;
border-radius:7px;
border:none;
outline:none;
font-size:15px;
`
export const ProofVerify = styled.div`

display:flex;
align-items:center;
gap:3px;
`
export const VerifyDiv = styled.div`

display:flex;
align-items:center;
gap:7px;

`
export const AcceptButton = styled.button`
background: #06AE2B;
color: #fff;
padding:3px 5px;
border-radius:1px;
outline:none;
border:none;
`
export const RejectButton = styled.button`
background: #E70909;
color: #fff;
padding:3px 5px;
border-radius:1px;
outline:none;
border:none;
`
