import styled from 'styled-components'



export const InputDiv = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:7px;
gap:5px;
padding:9px 10px;
justify-content:space-between;
z-index:1111;
position:relative;
box-shadow: 4px 4px 15px 2px #00000024;

`

export const BlackDiv = styled.div`
display:flex;
align-items:center;
background:#000;
width:45%;
justify-content:space-evenly;
border-radius:3px;

`




export const FlexDiv = styled.div`
display:flex;
align-items:center;
justify-content:space-around;
width:100%;
`
export const P = styled.p`
color:#fff;
font-size:11px;

`
export const PP = styled.p`
color:#fff;
font-size:13px;
font-weight:bold;

`

export const Div = styled.div`
display:flex;
align-items:center;
gap:5px;
height:35px;
`
export const LabelDiv = styled.div`
display:flex;
gap:10px;
align-items:center;
margin-top:70px;
`
export const Label = styled.label`
background: #D73777;
font-weight:bold;
font-size:13px;
margin-left:15px;
padding:5px 10px;
border-radius:5px;
top:55px;
`

export const LabelTitle = styled.label`
background: #D73777;
font-weight:bold;
font-size:13px;
margin-left:15px;
padding:5px 10px;
border-radius:5px;
margin-top:30px;`



