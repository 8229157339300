import React, { FC, useCallback, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'

import { IoLocationSharp } from "react-icons/io5";
import xmark from "../../../assets/images/png/X.png";
import mark from "../../../assets/images/png/Y.png";

import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {
  HourButton,
  DISCOUNT,
  INPUTDIV,
  BLACKDIV,
  FLEXDIV,
  P,
  MARKDIV,
  VerifyButton,
  DropDown,
  Text,
  ServiceLabel,
  InputDivHourly,
  InputDivAllNight,
  InputDivFullDay,
  InputDivWeekly,
  InputDivMonthly,
} from "./fourthScreen.styles";
import Footer from "../../footer";
import { ModalOne, TravelDiscountModal } from "./Modals";
import {
  BlackOpacity,
  Input,
  Wrapper,
} from "../../providerCarReg/fourthScreen/fourthScreen.styles";
import FifthScreen from "../../providerCarReg/fifthScreen/fifthScreen";
// import DiscountModal from './DiscountModal';
import { useAppDispatch, useAppSelector } from "../../../store";
import { Inputs, PriceInput } from "../../providerCarReg/fifthScreen/fifthScreen.styles";
import { TravelModal } from "./TravelModal";
import { setDriverInfo, setServiceVisibility } from "../../../slices/provider";
import DiscountModal from "../../discountModal";
import { DeleteModal } from "../deleteModal";
import CurrencyInput from "react-currency-input-field";
import { formatCurrencyPrefix } from "../../../services/utils";

const FourthScreen: FC<{
  pendingApproval: any;
  setWidth: any;
  setDriverDetails: any;
  driverDetails: any;
}> = ({ pendingApproval, setWidth, driverDetails, setDriverDetails }) => {
  
  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );
  const [isHourlyDiscountVisible, setIsHourlyDiscountVisible] = useState(false);
  const [isFullDayDiscountVisible, setIsFullDayDiscountVisible] =
    useState(false);
  const [isAllNightDiscountVisible, setIsAllNightDiscountVisible] =
    useState(false);
  const [isWeeklyDiscountVisible, setIsWeeklyDiscountVisible] = useState(false);
  const [isMonthlyDiscountVisible, setIsMonthlyDiscountVisible] =
    useState(false);

  const driverHourlyRateVisible = useAppSelector(
    (state: any) => state?.provider?.serviceVisibility?.driverHourlyRateVisible
  );
  const driverFullDayServiceVisible = useAppSelector(
    (state: any) =>
      state?.provider?.serviceVisibility?.driverFullDayServiceVisible
  );
  const driverAllNightServiceVisible = useAppSelector(
    (state: any) =>
      state?.provider?.serviceVisibility?.driverAllNightServiceVisible
  );
  const driverWeeklyServiceVisible = useAppSelector(
    (state: any) =>
      state?.provider?.serviceVisibility?.driverWeeklyServiceVisible
  );
  const driverMonthlyVisible = useAppSelector(
    (state: any) => state?.provider?.serviceVisibility?.driverMonthlyVisible
  );
  const driverTravelVisible = useAppSelector(
    (state: any) => state?.provider?.serviceVisibility?.driverTravelVisible
  );

  console.log(driverHourlyRateVisible, "driverHourlyRateVisible");
  console.log(driverFullDayServiceVisible, "driverFullDayServiceVisible");

  const [isVisibleModalOne, setIsVisibleModalOne] = useState(false);
  const [isTravelDiscountVisible, setIsTravelDiscountVisible] = useState(false);
  const [travelDetails, setTravelDetails] = useState();
  const [isTravelVisible, setTravelVisible] = useState(true);

  const [isVisibleTravelTwo, setIsVisibleTravelTwo] = useState(false);

  const dispatch = useAppDispatch();

  const driverTravelTrips = useAppSelector(
    (state: any) => state?.provider?.driverInfo?.travel_trip
  );
  // console.log('first', driverTravelTrips)

  const BlurOptionTravel = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setTravelVisible((prev) => !prev);
  };

  const openModal = (travels: any) => {
    setIsVisibleModalOne((prev) => !prev);
    // dispatch(setShowFilter(true))
    setTravelDetails(travels);
  };
  const openDiscount = (travels: any) => {
    setIsTravelDiscountVisible(true);
    setTravelDetails(travels);
  };

  useEffect(() => {
    setWidth(100);
  }, []);

  //@ts-ignore
  let dayPrice = driverDetails?.service_price?.filter((price) => {
    return price?.service_title?.includes("Full Day Service");
  });
  //@ts-ignore
  let allNightPrice = driverDetails?.service_price?.filter((price) => {
    return price?.service_title?.includes("All Night Service");
  });
  //@ts-ignore
  let hourlyPrice = driverDetails?.service_price?.filter((price) => {
    return price?.service_title?.includes("Hourly Rate");
  });

  //@ts-ignore
  let weeklyPrice = driverDetails?.service_price?.filter((price) => {
    return price?.service_title?.includes("Weekly Service");
  });

  //@ts-ignore
  let monthlyPrice = driverDetails?.service_price?.filter((price) => {
    return price?.service_title?.includes("Monthly Service");
  });

  const [fullDayDiscount, setFullDayDiscount] = useState(
    dayPrice[0]?.discount || 0
  );
  const [hourlyDiscount, setHourlyDiscount] = useState(
    hourlyPrice[0]?.discount || 0
  );
  const [allNightDiscount, setAllNightDiscount] = useState(
    allNightPrice[0]?.discount || 0
  );
  const [weeklyDiscount, setWeeklyDiscount] = useState(
    weeklyPrice[0]?.discount || 0
  );
  const [monthlyDiscount, setMonthlyDiscount] = useState(
    monthlyPrice[0]?.discount || 0
  );

  const [fullDayServiceHour, setFullDayServiceHour] = useState(
    dayPrice[0]?.hour || 20
  );
  const [fullDayServicePrice, setFullDayServicePrice] = useState(
    dayPrice[0]?.price
  );

  const [allNightServiceHour, setAllNightServiceHour] = useState(
    allNightPrice[0]?.hour || 6
  );
  const [allNightServicePrice, setAllNightServicePrice] = useState(
    allNightPrice[0]?.price
  );

  const [hourlyRateHour, setHourlyRateHour] = useState(
    hourlyPrice[0]?.hour || 1
  );
  const [driverHourlyRatePrice, setDriverHourlyRatePrice] = useState(
    hourlyPrice[0]?.price
  );

  const [weeklyServiceHour, setWeeklyServiceHour] = useState(
    weeklyPrice[0]?.hour || 1
  );
  const [weeklyServicePrice, setWeeklyServicePrice] = useState(
    weeklyPrice[0]?.price
  );

  const [monthlyServiceHour, setMonthlyServiceHour] = useState(
    monthlyPrice[0]?.hour || 1
  );
  const [monthlyServicePrice, setMonthlyServicePrice] = useState(
    monthlyPrice[0]?.price
  );

  let hourlyArray = [
    {
      price: 0,
      hour: hourlyRateHour,
      discount: 0,
      service_title: "Hourly Rate",
    },
  ];
  let fullDayArray = [
    {
      price: 0,
      hour: fullDayServiceHour,
      discount: 0,
      service_title: "Full Day Service",
    },
  ];

  let allNightArray = [
    {
      price: 0,
      hour: allNightServiceHour,
      discount: 0,
      service_title: "All Night Service",
    },
  ];

  let weeklyArray = [
    {
      price: 0,
      hour: weeklyServiceHour,
      discount: 0,
      service_title: "Weekly Service",
    },
  ];

  let monthlyArray = [
    {
      price: 0,
      hour: monthlyServiceHour,
      discount: 0,
      service_title: "Monthly Service",
    },
  ];

  const operation = (x: any, data: any, title: string) => {
    if (x === 0 || x === "") {
      return;
    } else {
      const updatedData = [
        {
          ...data[0], // Spread the properties of the existing object
          price: x, // Update the price property with the desired value
        },
        ...data.slice(1), // Spread the remaining objects in the data array
      ];
      let newServicePrice = [...driverDetails.service_price, ...updatedData];

      const updatedServicePrice = newServicePrice?.map((service: any) => {
        if (service.service_title === title) {
          return {
            ...service,
            price: x,
          };
        }
        return service;
      });
      // this is to prevent the price deta from getting duplicated
      //@ts-ignore
      const uniqueServicePrices = updatedServicePrice.reduce(
        (uniquePrices, price) => {
          if (
            !uniquePrices.some(
              (p: any) => p.service_title === price.service_title
            )
          ) {
            uniquePrices.push(price);
          }
          return uniquePrices;
        },
        []
      );
      setDriverDetails({
        ...driverDetails,
        service_price: uniqueServicePrices,
      });
    }
  };

  const hourOperation = (hour: any, data: any, title: string) => {
    if (hour === "0" || hour === "") {
      return;
    } else {
      const updatedData = [
        {
          ...data[0], // Spread the properties of the existing object
          hour: hour, // Update the price property with the desired value
        },
        ...data.slice(1), // Spread the remaining objects in the data array
      ];
      let newServicePrice = [...driverDetails.service_price, ...updatedData];

      const updatedServicePrice = newServicePrice?.map((service: any) => {
        if (service.service_title === title) {
          return {
            ...service,
            hour: hour,
          };
        }
        return service;
      });

      // this is to prevent the price deta from getting duplicated
      //@ts-ignore
      const uniqueServicePrices = updatedServicePrice.reduce(
        (uniquePrices, hour) => {
          if (
            !uniquePrices.some(
              (p: any) => p.service_title === hour.service_title
            )
          ) {
            uniquePrices.push(hour);
          }
          return uniquePrices;
        },
        []
      );
      setDriverDetails({
        ...driverDetails,
        service_price: uniqueServicePrices,
      });
    }
  };
  function dispatchDetails() {
    dispatch(
      setDriverInfo({
        ...driverDetails,
        service_price: [...driverDetails?.service_price],
      })
    );
  }

  useEffect(() => {
    dispatchDetails();
  }, [driverDetails]);

  const handleHourlyRatePrice = (event: any) => {
    setDriverHourlyRatePrice(event);
    operation(event, hourlyArray, "Hourly Rate");
  };
  const handleFullDayPrice = (event: any) => {
    setFullDayServicePrice(event);
    operation(event, fullDayArray, "Full Day Service");
  };
  const handleAllNightPrice = (event: any) => {
    setAllNightServicePrice(event);
    operation(event, allNightArray, "All Night Service");
  };

  const handleWeeklyPrice = (event: any) => {
    setWeeklyServicePrice(event);
    operation(event, weeklyArray, "Weekly Service");
  };

  const handleMonthlyPrice = (event: any) => {
    setMonthlyServicePrice(event);
    operation(event, monthlyArray, "Monthly Service");
  };

  const [isFullDayDel, setIsFullDayDel] = useState(false);
  const [isAllNightDel, setIsAllNightDel] = useState(false);
  const [isDriverHourlyDel, setIsDriverHourlyDel] = useState(false);
  const [isWeeklyDel, setIsWeeklyDel] = useState(false);
  const [isMonthlyDel, setIsMonthlyDel] = useState(false);

  // const deleteService = (title: string) => {
  //     //@ts-ignore
  //     setDriverDetails(prevDetails => {
  //         //@ts-ignore
  //         const filteredDetails = prevDetails.service_price.filter(detail => detail.service_title !== title);
  //         return { ...prevDetails, service_price: filteredDetails };
  //     });
  // };
  const BlurOptionHourly = () => {
    setIsDriverHourlyDel((prev) => !prev);
  };
  const BlurOptionFullDay = () => {
    setIsFullDayDel((prev) => !prev);
  };

  const BlurOptionAllNight = () => {
    setIsAllNightDel((prev) => !prev);
  };
  const BlurOptionWeekly = () => {
    setIsWeeklyDel((prev) => !prev);
  };

  const BlurOptionMonthly = () => {
    setIsMonthlyDel((prev) => !prev);
  };
  const deleteTravel = (travels: any) => {
    //@ts-ignore

    setDriverDetails((prevDetails) => {
      //@ts-ignore
      const filteredDetails = driverDetails.travel_trip.filter(
        (travel: any) => {
          return travel?.title !== travels?.title;
        }
      );
      // console.log(driverDetails.travel_trip?.length)

      return { ...prevDetails, travel_trip: filteredDetails };
    });
  };

  const handleEnableHourly = () => {
    dispatch(setServiceVisibility({ driverHourlyRateVisible: true }));
  };
  const handleEnableFullDay = () => {
    dispatch(setServiceVisibility({ driverFullDayServiceVisible: true }));
  };
  const handleEnableAllNight = () => {
    dispatch(setServiceVisibility({ driverAllNightServiceVisible: true }));
  };
  const handleEnableWeekly = () => {
    dispatch(setServiceVisibility({ driverWeeklyServiceVisible: true }));
  };
  const handleEnableMonthly = () => {
    dispatch(setServiceVisibility({ driverMonthlyVisible: true }));
  };
  const handleEnableTravel = () => {
    dispatch(setServiceVisibility({ driverTravelVisible: true }));
  };

  return (
    <>
      {driverHourlyRateVisible ? (
        ""
      ) : (
        <Wrapper
          style={{
            width: "100%",
            position: "relative",
            height: "68px",
            zIndex: 6,
            marginTop: -66,
          }}
        >
          <BlackOpacity
            style={{
              background: "#000",
              opacity: 0.8,
              position: "absolute",
              width: "90%",
              bottom: -118,
              height: "100%",
            }}
          ></BlackOpacity>
        </Wrapper>
      )}

      {isDriverHourlyDel && (
        <DeleteModal
          isDriverHourlyDel={isDriverHourlyDel}
          setIsDriverHourlyDel={setIsDriverHourlyDel}
          driverDetails={driverDetails}
          setDriverDetails={setDriverDetails}
          setHourlyRateHour={setHourlyRateHour}
          setHourlyDiscount={setHourlyDiscount}
          setDriverHourlyRatePrice={setDriverHourlyRatePrice}
          deleteFor="Hourly Rate"
        />
      )}
      <FLEXDIV>
        <InputDivHourly driverHourlyRateVisible={driverHourlyRateVisible}>
          <ServiceLabel htmlFor="">Rate per hour</ServiceLabel>
          <HourButton>
            <P style={{ left: "0px", top: "-38px" }}>Daily Hours</P>

            {hourlyRateHour ? <Text>{hourlyRateHour}</Text> : <Text>{1} </Text>}

            <DropDown>
              <IoIosArrowUp
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  if (hourlyRateHour > 4) return;
                  setHourlyRateHour((prev: any) => prev + 1);
                  hourOperation(hourlyRateHour + 1, hourlyArray, "Hourly Rate");
                }}
              />
              <IoIosArrowDown
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  if (hourlyRateHour < 4) return;
                  setHourlyRateHour((prev: any) => prev - 1);
                  hourOperation(hourlyRateHour - 1, hourlyArray, "Hourly Rate");
                }}
              />
            </DropDown>
          </HourButton>
          <BLACKDIV>
            <P style={{ left: "4px", top: "-39px" }}>Price</P>

            <Input>
              <CurrencyInput
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  color: "#fff",
                }}
                decimalsLimit={2}
                                prefix={formatCurrencyPrefix(currentLocation)}

                placeholder="0.00"
                value={driverHourlyRatePrice}
                defaultValue={0.0}
                onValueChange={(e: any) => handleHourlyRatePrice(e)}
              />
            </Input>

            <DISCOUNT onClick={() => setIsHourlyDiscountVisible(true)}>
              <P style={{ left: "0px", top: "-50px" }}>Discount</P>
              {hourlyDiscount}%
            </DISCOUNT>
          </BLACKDIV>
        </InputDivHourly>

        <MARKDIV>
          {driverHourlyRateVisible ? (
            <img onClick={BlurOptionHourly} src={xmark} alt="" width={17} />
          ) : (
            <img
              src={mark}
              alt=""
              width={17}
              onClick={() => handleEnableHourly()}
            />
          )}
        </MARKDIV>
      </FLEXDIV>
      {isHourlyDiscountVisible && (
        <DiscountModal
          setDiscount={setHourlyDiscount}
          discount={hourlyDiscount}
          discountFor="Hourly Rate"
          fullDayArray={hourlyArray}
          vehicleDetails={driverDetails}
          setVehicleDetails={setDriverDetails}
          setIsDiscountVisible={setIsHourlyDiscountVisible}
        />
      )}

      {driverFullDayServiceVisible ? (
        ""
      ) : (
        <Wrapper
          style={{
            width: "100%",
            position: "relative",
            height: "68px",
            zIndex: 6,
            marginTop: -66,
          }}
        >
          <BlackOpacity
            style={{
              background: "#000",
              opacity: 0.8,
              position: "absolute",
              width: "90%",
              bottom: -118,
              height: "100%",
            }}
          ></BlackOpacity>
        </Wrapper>
      )}

      {isFullDayDel && (
        <DeleteModal
          setIsFullDayDel={setIsFullDayDel}
          driverDetails={driverDetails}
          setDriverDetails={setDriverDetails}
          setFullDayServiceHour={setFullDayServiceHour}
          setFullDayDiscount={setFullDayDiscount}
          setFullDayServicePrice={setFullDayServicePrice}
          deleteFor="Full Day Service"
          isFullDayDel={isFullDayDel}
        />
      )}

      <FLEXDIV>
        <InputDivFullDay
          driverFullDayServiceVisible={driverFullDayServiceVisible}
        >
          <ServiceLabel htmlFor="">Full Day Service</ServiceLabel>
          <HourButton>
            <P style={{ left: "0px", top: "-41px" }}>Daily Hours</P>

            {fullDayServiceHour ? (
              <Text>{fullDayServiceHour}</Text>
            ) : (
              <Text>{dayPrice[0]?.hour || 1} </Text>
            )}

            <DropDown>
              <IoIosArrowUp
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  if (fullDayServiceHour > 19) return;
                  setFullDayServiceHour((prev: any) => prev + 1);
                  hourOperation(
                    fullDayServiceHour + 1,
                    fullDayArray,
                    "Full Day Service"
                  );
                }}
              />
              <IoIosArrowDown
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  if (fullDayServiceHour < 13) return;
                  setFullDayServiceHour((prev: any) => prev - 1);
                  hourOperation(
                    fullDayServiceHour - 1,
                    fullDayArray,
                    "Full Day Service"
                  );
                }}
              />
            </DropDown>
          </HourButton>
          <BLACKDIV>
            <P style={{ left: "4px", top: "-39px" }}>Price</P>

            <Input>
              <CurrencyInput
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  color: "#fff",
                }}
                decimalsLimit={2}
                                prefix={formatCurrencyPrefix(currentLocation)}

                placeholder="0.00"
                value={fullDayServicePrice}
                defaultValue={0.0}
                onValueChange={(e: any) => handleFullDayPrice(e)}
              />
            </Input>

            <DISCOUNT onClick={() => setIsFullDayDiscountVisible(true)}>
              <P style={{ left: "0px", top: "-50px" }}>Discount</P>
              {fullDayDiscount}%
            </DISCOUNT>
          </BLACKDIV>
        </InputDivFullDay>

        <MARKDIV>
          {driverFullDayServiceVisible ? (
            <img onClick={BlurOptionFullDay} src={xmark} alt="" width={17} />
          ) : (
            <img
              onClick={() => handleEnableFullDay()}
              src={mark}
              alt=""
              width={17}
            />
          )}
        </MARKDIV>
      </FLEXDIV>
      {isFullDayDiscountVisible && (
        <DiscountModal
          setDiscount={setFullDayDiscount}
          discount={fullDayDiscount}
          discountFor="Full Day Service"
          fullDayArray={fullDayArray}
          vehicleDetails={driverDetails}
          setVehicleDetails={setDriverDetails}
          setIsDiscountVisible={setIsFullDayDiscountVisible}
        />
      )}

      {driverAllNightServiceVisible ? (
        ""
      ) : (
        <Wrapper
          style={{
            width: "100%",
            position: "relative",
            height: "68px",
            zIndex: 6,
            marginTop: -66,
          }}
        >
          <BlackOpacity
            style={{
              background: "#000",
              opacity: 0.8,
              position: "absolute",
              width: "90%",
              bottom: -118,
              height: "100%",
            }}
          ></BlackOpacity>
        </Wrapper>
      )}

      {isAllNightDel && (
        <DeleteModal
          setIsAllNightDel={setIsAllNightDel}
          driverDetails={driverDetails}
          setDriverDetails={setDriverDetails}
          setAllNightServiceHour={setAllNightServiceHour}
          setAllNightDiscount={setAllNightDiscount}
          setAllNightServicePrice={setAllNightServicePrice}
          deleteFor="All Night Service"
          isAllNightDel={isAllNightDel}
        />
      )}

      <FLEXDIV>
        <InputDivAllNight
          driverAllNightServiceVisible={driverAllNightServiceVisible}
        >
          <ServiceLabel htmlFor="">All Night Service</ServiceLabel>
          <HourButton>
            <P style={{ left: "0px", top: "-41px" }}>Daily Hours</P>

            {allNightServiceHour ? (
              <Text>{allNightServiceHour}</Text>
            ) : (
              <Text>{1} </Text>
            )}

            <DropDown>
              <IoIosArrowUp
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  if (allNightServiceHour > 4) return;
                  setAllNightServiceHour((prev: any) => prev + 1);
                  hourOperation(
                    allNightServiceHour + 1,
                    allNightArray,
                    "All Night Service"
                  );
                }}
              />
              <IoIosArrowDown
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  if (allNightServiceHour < 4) return;
                  setAllNightServiceHour((prev: any) => prev - 1);
                  hourOperation(
                    allNightServiceHour - 1,
                    allNightArray,
                    "All Night Service"
                  );
                }}
              />
            </DropDown>
          </HourButton>

          <BLACKDIV>
            <P style={{ left: "4px", top: "-39px" }}>Price</P>

            <Input>
              <CurrencyInput
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  color: "#fff",
                }}
                decimalsLimit={2}
                                prefix={formatCurrencyPrefix(currentLocation)}

                placeholder="0.00"
                value={allNightServicePrice}
                defaultValue={0.0}
                onValueChange={(e: any) => handleAllNightPrice(e)}
              />
            </Input>
            <DISCOUNT onClick={() => setIsAllNightDiscountVisible(true)}>
              <P style={{ left: "0px", top: "-50px" }}>Discount</P>
              {allNightDiscount}%
            </DISCOUNT>
          </BLACKDIV>
        </InputDivAllNight>

        <MARKDIV>
          {driverAllNightServiceVisible ? (
            <img onClick={BlurOptionAllNight} src={xmark} alt="" width={17} />
          ) : (
            <img
              onClick={() => handleEnableAllNight()}
              src={mark}
              alt=""
              width={17}
            />
          )}
        </MARKDIV>
      </FLEXDIV>

      {isAllNightDiscountVisible && (
        <DiscountModal
          setDiscount={setAllNightDiscount}
          discount={allNightDiscount}
          discountFor="All Night Service"
          setIsDiscountVisible={setIsAllNightDiscountVisible}
          fullDayArray={allNightArray}
          vehicleDetails={driverDetails}
          setVehicleDetails={setDriverDetails}
        />
      )}

      {driverWeeklyServiceVisible ? (
        ""
      ) : (
        <Wrapper
          style={{
            width: "100%",
            position: "relative",
            height: "68px",
            zIndex: 6,
            marginTop: -66,
          }}
        >
          <BlackOpacity
            style={{
              background: "#000",
              opacity: 0.8,
              position: "absolute",
              width: "90%",
              bottom: -118,
              height: "100%",
            }}
          ></BlackOpacity>
        </Wrapper>
      )}
      {isWeeklyDel && (
        <DeleteModal
          setIsWeeklyDel={setIsWeeklyDel}
          driverDetails={driverDetails}
          setDriverDetails={setDriverDetails}
          setWeeklyServiceHour={setWeeklyServiceHour}
          setWeeklyDiscount={setWeeklyDiscount}
          setWeeklyServicePrice={setWeeklyServicePrice}
          deleteFor="Weekly Service"
          isWeeklyDel={isWeeklyDel}
        />
      )}

      <FLEXDIV>
        <InputDivWeekly driverWeeklyServiceVisible={driverWeeklyServiceVisible}>
          <ServiceLabel htmlFor="">Weekly Service</ServiceLabel>
          <HourButton>
            <P style={{ left: "0px", top: "-41px" }}>Daily Hours</P>

            {weeklyServiceHour ? (
              <Text>{weeklyServiceHour}</Text>
            ) : (
              <Text>{1} </Text>
            )}

            <DropDown>
              <IoIosArrowUp
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  if (weeklyServiceHour > 4) return;
                  setWeeklyServiceHour((prev: any) => prev + 1);
                  hourOperation(
                    weeklyServiceHour + 1,
                    weeklyArray,
                    "Weekly Service"
                  );
                }}
              />
              <IoIosArrowDown
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  if (weeklyServiceHour < 2) return;
                  setWeeklyServiceHour((prev: any) => prev - 1);
                  hourOperation(
                    weeklyServiceHour - 1,
                    weeklyArray,
                    "Weekly Service"
                  );
                }}
              />
            </DropDown>
          </HourButton>

          <BLACKDIV>
            <P style={{ left: "4px", top: "-38px" }}>Price</P>

            <Input>
              <CurrencyInput
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  color: "#fff",
                }}
                decimalsLimit={2}
                                prefix={formatCurrencyPrefix(currentLocation)}

                placeholder="0.00"
                value={weeklyServicePrice}
                defaultValue={0.0}
                onValueChange={(e: any) => handleWeeklyPrice(e)}
              />
            </Input>
            <DISCOUNT onClick={() => setIsWeeklyDiscountVisible(true)}>
              <P style={{ left: "0px", top: "-50px" }}>Discount</P>
              {weeklyDiscount}%
            </DISCOUNT>
          </BLACKDIV>
        </InputDivWeekly>

        <MARKDIV>
          {driverWeeklyServiceVisible ? (
            <img onClick={BlurOptionWeekly} src={xmark} alt="" width={17} />
          ) : (
            <img
              onClick={() => handleEnableWeekly()}
              src={mark}
              alt=""
              width={17}
            />
          )}
        </MARKDIV>
      </FLEXDIV>
      {isWeeklyDiscountVisible && (
        <DiscountModal
          setDiscount={setWeeklyDiscount}
          discount={weeklyDiscount}
          discountFor="Weekly Service"
          setIsDiscountVisible={setIsWeeklyDiscountVisible}
          fullDayArray={weeklyArray}
          vehicleDetails={driverDetails}
          setVehicleDetails={setDriverDetails}
        />
      )}

      {driverMonthlyVisible ? (
        ""
      ) : (
        <Wrapper
          style={{
            width: "100%",
            position: "relative",
            height: "68px",
            zIndex: 6,
            marginTop: -66,
          }}
        >
          <BlackOpacity
            style={{
              background: "#000",
              opacity: 0.8,
              position: "absolute",
              width: "90%",
              bottom: -118,
              height: "100%",
            }}
          ></BlackOpacity>
        </Wrapper>
      )}

      {isMonthlyDel && (
        <DeleteModal
          setIsMonthlyDel={setIsMonthlyDel}
          driverDetails={driverDetails}
          setDriverDetails={setDriverDetails}
          setMonthlyServiceHour={setMonthlyServiceHour}
          setMonthlyDiscount={setMonthlyDiscount}
          setMonthlyServicePrice={setMonthlyServicePrice}
          deleteFor="Monthly Service"
          isMonthlyDel={isMonthlyDel}
        />
      )}

      <FLEXDIV>
        <InputDivMonthly driverMonthlyVisible={driverMonthlyVisible}>
          <ServiceLabel htmlFor="">Monthly Service</ServiceLabel>
          <HourButton>
            <P style={{ left: "0px", top: "-41px" }}>Daily Hours</P>

            {monthlyServiceHour ? (
              <Text>{monthlyServiceHour}</Text>
            ) : (
              <Text>{1} </Text>
            )}

            <DropDown>
              <IoIosArrowUp
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  if (monthlyServiceHour > 4) return;
                  setMonthlyServiceHour((prev: any) => prev + 1);
                  hourOperation(
                    monthlyServiceHour + 1,
                    monthlyArray,
                    "Monthly Service"
                  );
                }}
              />
              <IoIosArrowDown
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                onClick={() => {
                  if (monthlyServiceHour < 2) return;
                  setMonthlyServiceHour((prev: any) => prev - 1);
                  hourOperation(
                    monthlyServiceHour - 1,
                    monthlyArray,
                    "Monthly Service"
                  );
                }}
              />
            </DropDown>
          </HourButton>

          <BLACKDIV>
            <P style={{ left: "4px", top: "-38px" }}>Price</P>

            <Input>
              <CurrencyInput
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  color: "#fff",
                }}
                decimalsLimit={2}
                                prefix={formatCurrencyPrefix(currentLocation)}

                placeholder="0.00"
                value={monthlyServicePrice}
                defaultValue={0.0}
                onValueChange={(e: any) => handleMonthlyPrice(e)}
              />
            </Input>
            <DISCOUNT onClick={() => setIsMonthlyDiscountVisible(true)}>
              <P style={{ left: "0px", top: "-50px" }}>Discount</P>
              {monthlyDiscount}%
            </DISCOUNT>
          </BLACKDIV>
        </InputDivMonthly>

        <MARKDIV>
          {driverMonthlyVisible ? (
            <img onClick={BlurOptionMonthly} src={xmark} alt="" width={17} />
          ) : (
            <img
              onClick={() => handleEnableMonthly()}
              src={mark}
              alt=""
              width={17}
            />
          )}
        </MARKDIV>
      </FLEXDIV>

      {isMonthlyDiscountVisible && (
        <DiscountModal
          setDiscount={setMonthlyDiscount}
          discount={monthlyDiscount}
          discountFor="Monthly Service"
          setIsDiscountVisible={setIsMonthlyDiscountVisible}
          fullDayArray={monthlyArray}
          vehicleDetails={driverDetails}
          setVehicleDetails={setDriverDetails}
        />
      )}

      {driverTravelTrips?.length < 1 && (
        <>
          {driverTravelVisible ? (
            ""
          ) : (
            <Wrapper
              style={{
                width: "100%",
                position: "relative",
                height: "68px",
                zIndex: 6,
                marginTop: -66,
              }}
            >
              <BlackOpacity
                style={{
                  background: "#000",
                  opacity: 0.8,
                  position: "absolute",
                  width: "90%",
                  bottom: -118,
                  height: "100%",
                }}
              ></BlackOpacity>
            </Wrapper>
          )}
          <FLEXDIV>
            <INPUTDIV>
              <ServiceLabel
                style={{
                  padding: "14px 0px",
                  color: "#868686",
                  display: "flex",
                  width: "31%",
                  fontSize: "15px",
                  fontWeight: "normal",
                }}
                htmlFor=""
              >
                Travel Trip
              </ServiceLabel>
              <VerifyButton style={{ padding: "8px 4px" }} onClick={openModal}>
                <P style={{ left: "0px", top: "-39px" }}>Select Location</P>
                <Text>{"Select"}</Text>
                <DropDown>
                  <IoIosArrowDown
                    style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                  />
                </DropDown>
              </VerifyButton>

              <BLACKDIV style={{ width: "38%" }}>
                <P style={{ left: "0px", top: "-37px" }}>Set Price</P>
                <Input
                  onClick={openModal}
                  type="text"
                  readOnly
                  value="0.00"
                  placeholder="0.00"
                  style={{
                    width: "40px",
                    outline: "none",
                    border: "none",
                    color: "#fff",
                    background: "transparent",
                  }}
                />

                
                <DISCOUNT>
                  <P style={{ left: "0px", top: "-45px" }}>Discount</P>
                  0%
                </DISCOUNT>
              </BLACKDIV>
            </INPUTDIV>
            <MARKDIV onClick={BlurOptionTravel}>
              {driverTravelVisible ? (
                <img src={xmark} alt="" width={17} />
              ) : (
                <img
                  src={mark}
                  alt=""
                  width={18}
                  onClick={() => handleEnableTravel()}
                />
              )}
            </MARKDIV>
          </FLEXDIV>
        </>
      )}
      {isVisibleModalOne && (
        <div style={{ position: "relative", width: "390px" }}>
          <ModalOne
            driverDetails={driverDetails}
            setDriverDetails={setDriverDetails}
            travelDetails={travelDetails}
            setIsVisibleModalOne={setIsVisibleModalOne}
            setIsVisibleTravelTwo={setIsVisibleTravelTwo}
            setIsDiscountVisible={undefined}
          />
        </div>
      )}

      {driverTravelTrips?.map((travels: any, i: any) => {
        const address = travels?.destination;
        const words = address.split(" ");
        const firstTwoWords = words.slice(0, 1).join(" ");
        return (
          <>
            <FLEXDIV key={i}>
              <INPUTDIV>
                <ServiceLabel
                  style={{
                    padding: "14px 0px",
                    color: "#868686",
                    display: "flex",
                    width: "30%",
                    fontSize: "15px",
                    fontWeight: "normal",
                  }}
                  htmlFor=""
                >
                  Travel Trip
                </ServiceLabel>
                <VerifyButton
                  style={{ width: "30%", padding: "8px 4px" }}
                  onClick={() => openModal(travels)}
                >
                  <P style={{ left: "0px", top: "-39px" }}>Select Location</P>
                  <Text>{firstTwoWords || "Select"}</Text>
                  <DropDown>
                    <IoIosArrowDown
                      style={{
                        color: "#FFD58C",
                        fontSize: 15,
                        fontWeight: 600,
                      }}
                    />
                  </DropDown>
                </VerifyButton>
                <BLACKDIV style={{ width: "40%" }}>
                  <P style={{ left: "0px", top: "-37px" }}>Set Price</P>
                  <PriceInput
                    onClick={() => openModal(travels)}
                    type="text"
                    readOnly
                    value={travels?.price}
                    placeholder="0.00"
                    style={{
                      width: "40px",
                      outline: "none",
                      border: "none",
                      color: "#fff",
                      background: "transparent",
                    }}
                  />

                  <DISCOUNT
                    onClick={() => {
                      openDiscount(travels);
                    }}
                  >
                    <P style={{ left: "0px", top: "-45px" }}>Discount</P>
                    {travels?.discount}%
                  </DISCOUNT>
                </BLACKDIV>
              </INPUTDIV>
              <MARKDIV onClick={() => deleteTravel(travels)}>
                {isTravelVisible ? (
                  <img src={xmark} alt="" width={17} />
                ) : (
                  <img src={mark} alt="" width={18} />
                )}
              </MARKDIV>
            </FLEXDIV>
          </>
        );
      })}

      {/* modal for travel options */}
      {isVisibleModalOne && (
        <div style={{ position: "relative", width: "390px" }}>
          <ModalOne
            driverDetails={driverDetails}
            setDriverDetails={setDriverDetails}
            travelDetails={travelDetails}
            setIsVisibleModalOne={setIsVisibleModalOne}
            setIsDiscountVisible={undefined}
            setIsVisibleTravelTwo={undefined}
          />
        </div>
      )}

      {/* modal for travel discount options */}
      {isTravelDiscountVisible && (
        <TravelDiscountModal
          travelDetails={travelDetails}
          driverDetails={driverDetails}
          setDriverDetails={setDriverDetails}
          setIsDiscountVisible={setIsTravelDiscountVisible}
          setIsVisibleTravelTwo={undefined}
          setIsVisibleModalOne={undefined}
        />
      )}
    </>
  );
};

export default FourthScreen;
