import React, { FC, useEffect, useState } from 'react'
import { Box, Button, DiscountBox, FlexBox, IconBox, ImageBox, Input, InputBox, Label, ModalContainer } from './index.styles'


import modalOption from '../../assets/images/png/modalOption.png'
import add from '../../assets/images/svg/triangle-up.svg'
import subtract from '../../assets/images/svg/triangle-down.svg'
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { setVehicleDetailss } from '../../slices/provider'
import { useAppDispatch } from '../../store'


type Props = {
    vehicleDetails: any;
    setVehicleDetails: any;
    setDiscount: any;
    discount: any;
    discountFor: string;
    fullDayArray: any;
    setIsDiscountVisible: any;
};
const DiscountModal: FC<Props> = ({ setIsDiscountVisible, setVehicleDetails, vehicleDetails, fullDayArray, setDiscount, discount, discountFor }) => {

    const dispatch = useAppDispatch()


    const handleIncrease = () => {
        setDiscount((prev: number) => prev + 1)
        setIsDiscountVisible(true)

    }
    const handleDecrease = () => {
        if (discount === 0) return
        setDiscount((prev: number) => prev - 1)
        setIsDiscountVisible(true)

    }


    const operation = (discount: any, data: any, title: string) => {
        if (discount === '0' || discount === '') {
            return
        } else {
            const updatedData = [
                {
                    ...data[0], // Spread the properties of the existing object
                    discount: discount, // Update the price property with the desired value
                },
                 ...data.slice(1), // Spread the remaining objects in the data array
            ];
            let newServicePrice = [...vehicleDetails.service_price, ...updatedData]
            const updatedServicePrice = newServicePrice?.map((service: any) => {
                if (service.service_title === title) {
                    return {
                        ...service,
                        discount: discount,
                    };
                }
                return service;
            });
            
            // this is to prevent the price deta from getting duplicated
            //@ts-ignore
            const uniqueServicePrices = updatedServicePrice.reduce((uniquePrices, discount) => {
                if (!uniquePrices.some((p:any) => p.service_title === discount.service_title)) {
                    uniquePrices.push(discount);
                }
                return uniquePrices;
            }, []);
            setVehicleDetails({
                ...vehicleDetails, service_price: uniqueServicePrices
            })
        }
    };

    function dispatchDetails(){
        dispatch(setVehicleDetailss(
            { ...vehicleDetails, service_price: [...vehicleDetails?.service_price] }));
    }

    useEffect(() => {
        dispatchDetails()
    }, [vehicleDetails])


    const handleSetDiscount = () => {

        operation(discount, fullDayArray, discountFor)
        setIsDiscountVisible(false)

    }

    return (

        <ModalContainer onClick={() => setIsDiscountVisible(false)}>

        <Box onClick={(e:any) => e.stopPropagation()}>
            <ImageBox>
                <img src={modalOption} height='100%' width='100%' />
            </ImageBox>
            <DiscountBox>
                <Label>Attract more clients  by offering a discount. </Label>

                <FlexBox>
                    <InputBox>
                        {/* <Input value={discount} /> */}

                        <div style={{justifyContent:'space-between', alignItems: 'center', display: 'flex', flex: 1 }}>
                            <p style={{ margin: 0, fontSize: 20, fontWeight: 'bold' }}>
                                {discount < 10 ? `0${discount} %` : `${discount} %`}
                            </p>


                            <IconBox>
                                <FiChevronUp style={{fontWeight:800}} onClick={handleIncrease} />
                                <FiChevronDown onClick={handleDecrease} />
                            </IconBox>
                        </div>

                    </InputBox>
                    <Button onClick={handleSetDiscount}>Set Discount</Button>
                </FlexBox>
            </DiscountBox>
        </Box>
        </ModalContainer>

    )
}

export default DiscountModal