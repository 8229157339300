import { useEffect } from 'react'
import { setShowCarOption } from '../../slices/showCarOption'
import { AppDispatch, RootState, useAppDispatch, useAppSelector } from '../../store'
import { CancelModalBox, ModalBox, ModalOverlay, OptionPill, PillRadioBtn } from './optionmodal.styles'

const OptionsModal = ({setTripOptions, tripOptionChoice, setPrice, previousPrice, tripOptionsList}:any) => {
    const dispatch: AppDispatch = useAppDispatch()
    const {showCarOption} = useAppSelector<any>((store:RootState) => store?.showCarOption)

    const carOptionModalControl = () => {
        dispatch(setShowCarOption(false))
    }

    console.log(tripOptionsList, '...')

    useEffect(() => {
        if(previousPrice) {
            setPrice(Number(previousPrice))
        }
    }, [previousPrice])

    // const tripOptionsList:any = [
    //     {tripOptions: 'Airport pickup/drop off', price: '10000'},
    //     {tripOptions: 'Set my hours - Min 6 hours', price: '1500'},
    //     {tripOptions: 'Half day - 6 hours', price: '7500'},
    //     {tripOptions: 'Full day - 11 hours', price: '14000'},
    //     {tripOptions: '2 Week - 12 days', price: '100000'},
    //     {tripOptions: '1 Month - 26 days', price: '160000'},
    // ]

    const handleOptionsChosen = (option:any) => {
        carOptionModalControl()
        setTripOptions(option?.service_title)
        setPrice(Number(option?.final_discounted_price))
    }

  return (
    <ModalOverlay activeModal={showCarOption} >
        <CancelModalBox onClick={() => carOptionModalControl()}/>
            <ModalBox>
                <div className="inner-modal-box">

                    {
                        tripOptionsList?.map((item:any, id:any) => {
                            return (
                                <OptionPill key={id} onClick={() => handleOptionsChosen(item)}>
                                    <PillRadioBtn activePill={item?.service_title === tripOptionChoice ? 'fill' : ''} />
                                    <p>
                                        {item?.service_title}
                                    </p>
                                </OptionPill>
                            )
                        })
                    }

                </div>
            </ModalBox>
    </ModalOverlay>
  )
}

export default OptionsModal