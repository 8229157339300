import React, { FC, useEffect, useState, useRef } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { Page, KinLabel, OwnerLabel } from './vehicleProfile.styles';
import Header from '../../../components/providerHeader';

// import SecondScreen from '../../../components/providerInsurance/secondScreen/secondScreen';
import { useNavigate, useParams } from 'react-router-dom';
import VehicleProfile from '../../../components/providerVehicleProfile/vehicleProfile/vehicleProfile';
import RegularRates from '../../../components/providerVehicleProfile/regularRates/regularRates';
import TravelRates from '../../../components/providerVehicleProfile/travelRates/travelRates';
import LongTermRates from '../../../components/providerVehicleProfile/longTermRates/longTermRates';
import Services from '../../../components/providerVehicleProfile/services/services';
import Insurance from '../../../components/providerVehicleProfile/insurance/insurance';
import Documents from '../../../components/providerVehicleProfile/documents/documents';
import { useGetBikeByIdQuery, useGetNextOfKinByProviderIdQuery, useGetProviderByIdQuery, useGetVehicleByIdQuery } from '../../../services/providerApi';
import { useAppSelector } from '../../../store';
import SecondScreen from '../../../components/providerAccountSetup/secondScreen/secondScreen';
import FirstScreen from '../../../components/providerAccountSetup/firstScreen/firstScreen';
import { StatusDiv } from '../VehicleProfile/vehicleProfile.styles';


const BikeProfile: FC = () => {
    const { id } = useParams();


    const user = useAppSelector((state: any) => state?.auth?.auth)
    const { data: bikeProfile, isFetching: isFetchingVehicleProfile, refetch: refetchBikeProfile } = useGetBikeByIdQuery(id)
    const { data: providerProfile, isFetching: isFetchingProviderProfile, refetch: refetchProviderProfile } = useGetProviderByIdQuery(user?.id)

    useEffect(() => {
        refetchBikeProfile()
    }, [])



    useEffect(() => {
        refetchProviderProfile()
    }, [])

    return (
        <>
            <Header />
            <Page>
                <StatusDiv>Pending Approval</StatusDiv>


                {/* <VehicleProfile vehicleProfile={bikeProfile} />
                <RegularRates vehicleProfile={bikeProfile} />
                <LongTermRates vehicleProfile={bikeProfile} />
                <Services vehicleProfile={bikeProfile} /> */}
                <br />
                <br />
                <br />
                <OwnerLabel>Owner </OwnerLabel>
                <br />
                <br />
                <FirstScreen />


            </Page>
        </>
    )
}
export default BikeProfile