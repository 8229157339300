import React, { FC, useEffect, useRef, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import filter from '../../../assets/images/png/filter.png'

import { Div, DriverDiv } from './adminCustomers.styles';

import { FiSearch } from 'react-icons/fi';

import Customer from './customer';
import DriverRegistration from '../../../pages/Service_provider/DriverRegistration/driverRegistration';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useGetDriversQuery } from '../../../services/providerApi';
import { setAllDriver } from '../../../slices/provider';
import { baseUrl } from '../../../services/adminApi';
import axios from 'axios';
import { setAllCustomer, setFilterPaginationData } from '../../../slices/admin';
import { PaginationButton, PaginationDiv } from '../Drivers/adminDrivers.styles';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import toast from 'react-hot-toast'
import { Toaster } from "react-hot-toast";

const AdminCustomers: FC = () => {
    let [addDriverComponent, setAddDriverComponent] = useState(false)
    let [ListDriverComponent, setListDriverComponent] = useState(true)

    const dispatch = useAppDispatch()
    const user = useAppSelector((state: any) => state?.auth.auth)
    const allCustomer = useAppSelector((state: any) => state?.admin.allCustomer)
    const [currentPage, setCurrentPage] = useState<Number>(1);

    const filterPagination = useAppSelector((state: any) => state?.admin?.filterPagination)
    const filterPaginationData = useAppSelector((state: any) => state?.admin?.filterPaginationData)



    let promise: any;
    const getCustomers = async () => {

        await axios.get(`${baseUrl}list-all-customers?page=${currentPage}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        }).then((response) => {
            if (response?.data?.message === 'success') {
                dispatch(setAllCustomer(response?.data))
                setTimeout(() => {
                    toast.dismiss(promise);
                }, 0);
                toast.success('Successfully Loaded!')
            }

        }).catch((err) => {
        })
    }


    useEffect(() => {
        getCustomers()
    }, [currentPage])

    useEffect(() => {
        dispatch(setFilterPaginationData(''))

    }, [])


    const handlePageChange = (newPage: number) => {
        // Validate newPage to prevent requesting invalid pages
        if (newPage >= 1) {
            setCurrentPage(newPage);
            // dispatch(setPageNumber(newPage))
            promise = toast.loading('Loading...');

        }
    };
    let paginationData = allCustomer?.data?.links
    let realPagination = paginationData?.filter((pagination: any) => {
        return Number(pagination.label)
    })


    return (
        <>



            <DriverDiv style={{flexDirection:'column'}}>

                {
                    allCustomer?.data?.data.map((customer: any) => {
                        return <Customer key={customer.id} driverDetails={customer} isModal={false} isCustomer={true}
                        />
                    })
                }
            </DriverDiv>


            <PaginationDiv>
                {realPagination?.map((pageData: any, index: number) => (
                    <PaginationButton
                        key={index}
                        onClick={() => handlePageChange(Number(pageData.label))

                        }
                        disabled={pageData.active}

                    >
                        {pageData.label === '&laquo; Previous' && !pageData.active ? (
                            <><BiChevronLeft /></>
                        ) : pageData.label === 'Next &raquo;' && !pageData.active ? (
                            <><BiChevronRight /></>
                        ) : (
                            <>{pageData.label}</>
                        )}
                    </PaginationButton>
                ))}
            </PaginationDiv>


        </>
    )
}

export default AdminCustomers