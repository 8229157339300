import { ItemsBtnList, ItemBtn } from './../deliveryLocationModal/deliverylocation.styles';
import styled from 'styled-components'

export const MarginTop = styled.div `
    margin-top: 20px;
`


export const SubDetailsOverallWrap = styled.div `
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    width: 100%;
`

export const BookingSubDetailsOverall = styled.div `
    min-width: 100%;
    width: 100%;
    transition: 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    display: ${(props:any) => props.display ? 'block' : 'none'};
`

export const BookingDetailsSubWrap = styled.div `
    border-radius: 9px;
    padding: 5px;
    display: flex;
    gap: 5px;
    justify-content: baseline;
    align-items: center;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
        -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
        box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
    margin-bottom: 10px;

    & .calendar-wrap {
        margin: 0 5px;
        display: flex;
        align-items: center;
        img {
            width: 14px;
        }
    }

    & .location-wrap {
        display: block;
        height: 100%;
    }

    & img {
        width: 20px;
    }

    img.calendar {
        width: 15px;
    }

    & p {
        font-size: 10px;
        color: ${(props: any) => props.theme.cartFooterBg};
        margin: 0;
        overflow-x: hidden;
        width: 70px;
    }

    @media only screen and (max-width: 454px) {
        p {
            font-size: 9px;
        }
    }

`

export const LocationDetailsWrap = styled(BookingDetailsSubWrap)`
    height: 40px;
    /* border: solid 1px red; */
    align-items: flex-start;
    white-space: wrap;
    overflow-y: scroll;
    /* text-overflow: ellipsis; */
    padding-bottom: 5px;

    /* width */
    &::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: transparent; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888; 
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }

    p {
        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

export const NumberDetailsWrap = styled(BookingDetailsSubWrap)`
    justify-content: center;
    padding: 0px;

    & .PhoneInputCountry {
        padding: 10px !important;
        border-bottom-left-radius: 9px !important;
        border-top-left-radius: 9px !important;
        height: 100% !important;
    }

    & .PhoneInputCountryIcon {
        width: unset !important;
        height: unset !important;
    }

    & .PhoneInputCountrySelectArrow {
        display: none !important;
    }

    & .PhoneInputCountryIconImg {
        width: 10px !important;
    }

    & .PhoneInputInput {
        width: 100% !important;
        font-size: 8px !important;
        color: #000 !important;
        padding: 7px !important;
    }
`

export const CartItemBtnList = styled(ItemsBtnList) `
    width: 100px;
    margin-right: 0;
    gap: 6px;

    @media only screen and (max-width: 454px) {
        width: 82px;
    }
`

export const CartItemBtn = styled(ItemBtn) `
    border: solid 1px ${(props:any) => props.active ? 'none' : '#000'};
    background-color: ${(props:any) => props.active ? '#FFD58C' : '#fff'};
    color: ${(props:any) => props.active ? '#000' : '#000'};
    font-size: 8.5px;
    min-width: 35px;
    padding: 4px 1px;
    border-radius: 4px;
`
export const EditBookingText = styled.div `
    color: #000;
    padding: 5px 9px;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
    background-color: #ffa100;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;

    @media only screen and (max-width: 454px) {
        font-size: 10px;
        border-radius: 4px;
        padding: 3px 8px;
    }
`