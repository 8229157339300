import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'


import { Page, Button, LandingPageBtnWrap } from './signup.styles';
import Footer from '../../../components/footer'
import refIcon from '../../../../assets/images/svg/referralIcon.svg'
import doodle from '../../../../assets/images/svg/doodle.png'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { Link, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../store'
import Header from '../../../components/header';
import AuthBox from '../../../components/customerSignUp/firstStep/first';
import AuthBoxTwo from '../../../components/customerSignUp/secondStep/second';
import AuthBoxThree from '../../../components/customerSignUp/thirdStep/third';
import Landing from '../../../components/customerSignUp/landing/landing';
import { PagePadding } from '../../admin/Dashboard/dashboard.styles';


const CustomerSignUp = () => {

  const userPhone = useAppSelector((state: any) => state?.provider?.otp_number)

  const navigate = useNavigate()

  let [component, setComponent] = useState<number>(2)

  const handleNextComponent = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    setComponent((prev) => prev + 1)
  };

  const handleContinue = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    navigate('/individual-reg')
  };
  return (
    <Page>
      <Header />
      <PagePadding className='padding'>
      {component === 1 && <Landing />}
      {component === 2 && <AuthBox component={component} setComponent={setComponent} />}
      {component === 3 && <AuthBoxTwo component={component} setComponent={setComponent} />}
      {component === 4 && <AuthBoxThree component={component} setComponent={setComponent} />}



      {

        component === 1 &&
        <LandingPageBtnWrap>
          <Button onClick={handleNextComponent}>
            Continue
          </Button>
          <p style={{ fontSize: '13px' }}>  <span style={{ color: '#fff' }}>Already have an  account ? </span>

            <Link to={'/login'}>
              <span style={{ color: '#FFA100' }}>Login</span>
            </Link>

          </p>
        </LandingPageBtnWrap>

        // <Button onClick={handleContinue}>
        //   Continue
        // </Button>
      }




      <div className='doodle'>

      </div>
      </PagePadding>
      <Footer />
    </Page>
  )
}

export default CustomerSignUp