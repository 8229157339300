import React, { FC, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'
import filter from "../../../assets/images/png/filter.png";
import Calendar from "../../../assets/images/png/Calendar.png";

import {
  CalendarFilterButton,
  DateRangeModal,
  FilterBox,
  FilterDate,
  FilterIcon,
  H4,
  SearchBox,
  SearchBoxDiv,
  WhiteModal,
} from "./filter.styles";
import { FiSearch } from "react-icons/fi";
// import { useGetAdminDriversQuery, useGetAdminRequestsQuery, useGetAdminVehiclesQuery } from '../../../services/adminApi';
import {
  setAllBooking,
  setAllCustomer,
  setAllDriver,
  setAllVehicle,
  setFilterPagination,
  setFilterPaginationData,
  setPageNumber,
  setProviders,
  setSearchData,
} from "../../../slices/admin";
import { useAppDispatch, useAppSelector } from "../../../store";
import { baseUrl } from "../../../services/adminApi";
import axios from "axios";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import moment from "moment";

type Props = {
  vehicle?: boolean;
  request?: boolean;
  driver?: boolean;
  provider?: boolean;
  customer?: boolean;
  business?: boolean;
};
const Filter: FC<Props> = ({
  business,
  request,
  vehicle,
  driver,
  provider,
  customer,
}) => {
  let [vehicleSearch, setVehicleSearch] = useState("");
  let [requestSearch, setRequestSearch] = useState("");
  let [driverSearch, setDriverSearch] = useState("");
  let [providerSearch, setProviderSearch] = useState("");
  let [businessSearch, setBusinessSearch] = useState("");
  let [customerSearch, setCustomerSearch] = useState("");
  let [openCalendar, setOpenCalendar] = useState(false);
  const [requests, setRequests] = useState<any>();

  const user: any = useAppSelector((state: any) => state?.auth?.auth);

  const [dateValues, setDateValues] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const dispatch: any = useAppDispatch();

  const handleSearch = (e) => {
    setVehicleSearch(e.target.value.toUpperCase());
  };
  const handleSearchD = (e) => {
    setDriverSearch(e.target.value.toLowerCase());
  };
  const handleSearchR = (e) => {
    setRequestSearch(e.target.value.toLowerCase());
    dispatch(
      setAllBooking(
        requests?.filter((request: any) => {
          return request?.request_id?.includes(requestSearch);
        })
      )
    );
  };
  const handleSearchP = (e) => {
    setProviderSearch(e.target.value.toLowerCase());
  };
  const handleSearchB = (e) => {
    setBusinessSearch(e.target.value.toLowerCase());
  };
  const handleSearchC = (e) => {
    setCustomerSearch(e.target.value.toLowerCase());
  };
  const pageNumber: number = useAppSelector(
    (state: any) => state?.admin?.searchCurrentPage
  );
  const [currentPage, setCurrentPage] = useState<Number>(pageNumber);

  const handleSearchVehicle = async () => {
    dispatch(setPageNumber(1));

    let formData = new FormData();
    formData.append("search_value", vehicleSearch);
    dispatch(setSearchData(vehicleSearch))

    axios({
      method: "post",
      url: `${baseUrl}search-vehicle?page=${pageNumber}`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(function (response) {
        const message = response.data.data;
        dispatch(setAllVehicle(response.data));
        setVehicleSearch("");
        // dispatch(setFilterPaginationData("search"))
        dispatch(setFilterPagination(true));
      })
      .catch(function (error) {
        //handle error
      });
  };
  const handleSearchDriver = async () => {
    let formData = new FormData();
    formData.append("search_value", driverSearch);
    axios({
      method: "post",
      url: `${baseUrl}search-driver`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(function (response) {
        const message = response.data.data;
        dispatch(setAllDriver(response.data));
        setDriverSearch("");
      })
      .catch(function (error) {
        //handle error
      });
  };

  const getRequests = async () => {
    await axios
      .get(`${baseUrl}admin-list-request`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          setRequests(response?.data?.data);
          dispatch(setAllBooking(response?.data?.data));
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getRequests();
  }, []);
  const getFiltered = async (data: string) => {};
  // const handleSearchRequest = () => {
  //     dispatch(setAllBooking(requests?.filter((request: any) => {
  //         return request?.request_id.toLowerCase().includes(requestSearch)
  //     })))
  //     setRequestSearch('')

  // }
  const handleSearchProvider = async () => {
    setProviderSearch("");
    let formData = new FormData();
    formData.append("search_value", providerSearch);
    axios({
      method: "post",
      url: `${baseUrl}search-provider`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(function (response) {
        const message = response.data.data;
        // dispatch(setProviders(response.data))
      })
      .catch(function (error) {
        //handle error
      });
  };
  const handleSearchBusiness = async () => {
    let formData = new FormData();
    formData.append("search_value", businessSearch);
    axios({
      method: "post",
      url: `${baseUrl}search-Business`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(function (response) {
        const message = response.data.data;
        // dispatch(setProviders(response.data))
        setBusinessSearch("");
      })
      .catch(function (error) {
        //handle error
      });
  };

  const handleSearchCustomer = async () => {
    let formData = new FormData();
    formData.append("search_value", customerSearch);
    axios({
      method: "post",
      url: `${baseUrl}search-Customer`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(function (response) {
        const message = response.data.data;
        dispatch(setAllCustomer(response.data));
        setCustomerSearch("");
      })
      .catch(function (error) {
        //handle error
      });
  };

  const handleSearchVehicleCalendar = async () => {
    let formData = new FormData();
    formData.append(
      "start_date",
      moment(dateValues[0]?.startDate).format("YYYY/MM/DD")
    );
    formData.append(
      "end_date",
      moment(dateValues[0]?.endDate).format("YYYY/MM/DD")
    );
    axios({
      method: "post",
      url: `${baseUrl}search-vehicle-calendar`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(function (response) {
        const message = response.data.data;
        dispatch(setAllVehicle(response.data));
        setOpenCalendar(false);
      })
      .catch(function (error) {
        //handle error
      });
  };

  const handleSearchDriverCalendar = async () => {
    let formData = new FormData();
    formData.append(
      "start_date",
      moment(dateValues[0]?.startDate).format("YYYY/MM/DD")
    );
    formData.append(
      "end_date",
      moment(dateValues[0]?.endDate).format("YYYY/MM/DD")
    );
    axios({
      method: "post",
      url: `${baseUrl}search-driver-calendar`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(function (response) {
        const message = response.data.data;
        dispatch(setAllDriver(response.data));
        setOpenCalendar(false);
      })
      .catch(function (error) {
        //handle error
      });
  };

  const filteredRequests = requests?.filter((request: any) => {
    const startDate = dateValues[0]?.startDate;
    const endDate = dateValues[0]?.endDate;

    // Check if startDate and endDate are valid dates
    if (startDate && endDate) {
      const requestDate = moment(request?.created_at);
      return (
        requestDate.isSameOrAfter(startDate, "day") &&
        requestDate.isSameOrBefore(endDate, "day")
      );
    }

    return false;
  });

  const handleSearchRequestCalendar = async () => {
    dispatch(setAllBooking(filteredRequests));

    setOpenCalendar(false);
  };

  const handleSearchProviderCalendar = async () => {
    let formData = new FormData();
    formData.append(
      "start_date",
      moment(dateValues[0]?.startDate).format("YYYY/MM/DD")
    );
    formData.append(
      "end_date",
      moment(dateValues[0]?.endDate).format("YYYY/MM/DD")
    );
    axios({
      method: "post",
      url: `${baseUrl}search-provider-calendar`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(function (response) {
        const message = response.data.data;
        // dispatch(setProviders(response.data))
        setOpenCalendar(false);
      })
      .catch(function (error) {
        //handle error
      });
  };

  const handleSearchCustomerCalendar = async () => {
    let formData = new FormData();
    formData.append(
      "start_date",
      moment(dateValues[0]?.startDate).format("YYYY/MM/DD")
    );
    formData.append(
      "end_date",
      moment(dateValues[0]?.endDate).format("YYYY/MM/DD")
    );
    axios({
      method: "post",
      url: `${baseUrl}search-customer-calendar`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(function (response) {
        const message = response.data.data;
        // dispatch(setProviders(response.data))
        setOpenCalendar(false);
      })
      .catch(function (error) {
        //handle error
      });
  };

  const handleSearchBusinessCalendar = async () => {
    let formData = new FormData();
    formData.append(
      "start_date",
      moment(dateValues[0]?.startDate).format("YYYY/MM/DD")
    );
    formData.append(
      "end_date",
      moment(dateValues[0]?.endDate).format("YYYY/MM/DD")
    );
    axios({
      method: "post",
      url: `${baseUrl}search-business-calendar`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(function (response) {
        const message = response.data.data;
        // dispatch(setProviders(response.data))
        setOpenCalendar(false);
      })
      .catch(function (error) {
        //handle error
      });
  };

  return (
    <>
      {openCalendar && (
        <DateRangeModal onClick={() => setOpenCalendar(false)}>
          <WhiteModal onClick={(e: any) => e.stopPropagation()}>
            <DateRange
              editableDateInputs={true}
              onChange={(item: any) => {
                setDateValues([item.selection]);
              }}
              moveRangeOnFirstSelection={false}
              ranges={dateValues}
            />
            {vehicle && (
              <CalendarFilterButton onClick={handleSearchVehicleCalendar}>
                Search Vehicle
              </CalendarFilterButton>
            )}
            {driver && (
              <CalendarFilterButton onClick={handleSearchDriverCalendar}>
                Search Driver
              </CalendarFilterButton>
            )}
            {request && (
              <CalendarFilterButton onClick={handleSearchRequestCalendar}>
                Search Request
              </CalendarFilterButton>
            )}
            {provider && (
              <CalendarFilterButton onClick={handleSearchProviderCalendar}>
                Search Provider
              </CalendarFilterButton>
            )}
            {business && (
              <CalendarFilterButton onClick={handleSearchBusinessCalendar}>
                Search Business
              </CalendarFilterButton>
            )}
            {customer && (
              <CalendarFilterButton onClick={handleSearchCustomerCalendar}>
                Search Customer
              </CalendarFilterButton>
            )}
          </WhiteModal>
        </DateRangeModal>
      )}
      {vehicle === true && (
        <FilterBox>
          <FilterIcon>
            <img src={filter} alt="" width={30} />
          </FilterIcon>

          <SearchBoxDiv>
            <SearchBox
              placeholder="Plate No, Veh. Name, Provider ID"
              value={vehicleSearch}
              onChange={handleSearch}
            />

            <FiSearch
              onClick={handleSearchVehicle}
              style={{
                color: "#DA6317",
                fontWeight: "700",
                fontSize: "28px",
              }}
            />
          </SearchBoxDiv>
          <FilterDate onClick={() => setOpenCalendar((prev) => !prev)}>
            <img src={Calendar} alt="" width={20} />
          </FilterDate>
        </FilterBox>
      )}

      {request === true && (
        <FilterBox>
          <FilterIcon>
            <img src={filter} alt="" />
          </FilterIcon>

          <SearchBoxDiv>
            <SearchBox
              value={requestSearch}
              onChange={handleSearchR}
              placeholder="Search Request ID"
            />

            <FiSearch
              // onClick={handleSearchRequest}
              style={{
                color: "#DA6317",
                fontWeight: "700",
                fontSize: "28px",
              }}
            />
          </SearchBoxDiv>
          <FilterDate onClick={() => setOpenCalendar((prev) => !prev)}>
            <img src={Calendar} alt="" width={30} />
          </FilterDate>
        </FilterBox>
      )}

      {driver === true && (
        <FilterBox>
          <FilterIcon>
            <img src={filter} alt="" />
          </FilterIcon>

          <SearchBoxDiv>
            <SearchBox
              value={driverSearch}
              onChange={handleSearchD}
              placeholder="Search Driver by name"
            />

            <FiSearch
              onClick={handleSearchDriver}
              style={{
                color: "#DA6317",
                fontWeight: "700",
                fontSize: "28px",
              }}
            />
          </SearchBoxDiv>
          <FilterDate onClick={() => setOpenCalendar((prev) => !prev)}>
            <img src={Calendar} alt="" width={30} />
          </FilterDate>
        </FilterBox>
      )}

      {provider === true && (
        <FilterBox>
          <FilterIcon>
            <img src={filter} alt="" />
          </FilterIcon>

          <SearchBoxDiv>
            <SearchBox
              value={providerSearch}
              onChange={handleSearchP}
              placeholder="Search Provider by name"
            />

            <FiSearch
              onClick={handleSearchProvider}
              style={{
                color: "#DA6317",
                fontWeight: "700",
                fontSize: "28px",
              }}
            />
          </SearchBoxDiv>
          <FilterDate onClick={() => setOpenCalendar((prev) => !prev)}>
            <img src={Calendar} alt="" width={30} />
          </FilterDate>
        </FilterBox>
      )}

      {customer === true && (
        <FilterBox>
          <FilterIcon>
            <img src={filter} alt="" />
          </FilterIcon>

          <SearchBoxDiv>
            <SearchBox
              value={customerSearch}
              onChange={handleSearchC}
              placeholder="Search Customer by name"
            />

            <FiSearch
              onClick={handleSearchCustomer}
              style={{
                color: "#DA6317",
                fontWeight: "700",
                fontSize: "28px",
              }}
            />
          </SearchBoxDiv>
          <FilterDate onClick={() => setOpenCalendar((prev) => !prev)}>
            <img src={Calendar} alt="" width={30} />
          </FilterDate>
        </FilterBox>
      )}

      {business === true && (
        <FilterBox>
          <FilterIcon>
            <img src={filter} alt="" />
          </FilterIcon>

          <SearchBoxDiv>
            <SearchBox
              value={businessSearch}
              onChange={handleSearchB}
              placeholder="Search Provider by name"
            />

            <FiSearch
              onClick={handleSearchBusiness}
              style={{
                color: "#DA6317",
                fontWeight: "700",
                fontSize: "28px",
              }}
            />
          </SearchBoxDiv>
          <FilterDate onClick={() => setOpenCalendar((prev) => !prev)}>
            <img src={Calendar} alt="" width={30} />
          </FilterDate>
        </FilterBox>
      )}
    </>
  );
};

export default Filter;
