import styled from "styled-components"

export const Card = styled.div `
    min-height: 160px;
    width: 320px;
    background-color: ${(props: any) => props.propBg};
    border-radius: 16px;
    position: relative;

    @media only screen and (min-width: 767px) {
        width: 400px;
    }  
`

export const CardImage = styled.img `
    position: absolute;
    bottom: -40px;
    right: 20px;
    width: 80px;
`

export const CardInner = styled.div `
    padding: 23px 30px;
`

export const H2 = styled.h2 `
    color: #000;
    font-size: 18px;
    font-weight: 800;

    @media only screen and (min-width: 959px) {
        font-size: 25px;
    }
`

export const P = styled.p `
    color: #000;
    font-size: 15px;
    font-weight: 400;
    margin: 0;

    @media only screen and (min-width: 959px)   {
        font-size: 19px;
    }
`

export const Button = styled.button `
    font-size: 12px;
    padding:6px 18px;
    border-radius: 8px;
    border: solid 1px #fff;
    background-color: #000;
    color: #fff;
    font-weight: 800;
    margin: 20px 0;
    margin-bottom: 0;
`