import styled from 'styled-components'



export const INPUTDIV = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:12px;
gap:5px;
padding:7px 20px;
justify-content:space-between;
z-index:1;
position:relative;
box-shadow: 4px 4px 15px 2px #00000024;
@media only screen and (max-width: 380px) {
    padding:5px 20px;

    }
`

export const BLACKDIV = styled.div`
display:flex;
align-items:center;
background:#000;
width:30%;
padding:4px 0px;
justify-content:space-evenly;
border-radius:6px;

`




export const FLEXDIV = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
width:100%;
`
export const P = styled.p`
color:#242321;
font-size:15px;

`
export const Label = styled.p`
color: #000;
font-size:13px;
font-weight:600;
display:flex;
align-items:center;
width:65%;
gap:2px;
`
export const LabelClick = styled.p`

display:flex;
align-items:center;
width:100%;
gap:5px;
`

export const Text = styled.p`
color: #fff;
font-size:13px;
font-weight:600;
display:flex;
align-items:center;
`
export const PP = styled.p`
color:#fff;
font-size:13px;
font-weight:500;

`

export const DIV = styled.div`
display:flex;
align-items:center;
gap:5px;
height:35px;
`
export const Modal = styled.div`
width:95%;
margin:0 auto;
background: #D9D9D9;
border-radius:10px;
margin-top:-20px;
padding:50px 15px 10px 15px;
`
export const Flex = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
margin-top:10px;

`
export const OptionDiv = styled.div`
display:flex;
align-items:center;
background:#000;
width:32%;
padding:1px 3px;
justify-content:space-around;
border-radius:8px;


`





// export const PP = styled.p`
// color:#fff;
// font-size:11px;
// font-weight:bold;

// `

export const DROPDOWN = styled.div`
display:flex;
align-items:center;
flex-wrap:wrap;
background: #EAEAEA;
margin-top:10px;
width:100%;
flex-direction:column;

`
export const DROPDOWNCOL = styled.div`
display:flex;
flex-direction:column;
align-items:center;
gap:2px;
flex-wrap:wrap;
background: #EAEAEA;
padding:4px;
width:95%;
margin-top:-10px;
border-radius: 0px 0px 10px 10px;
`
export const DROPDOWNDIV = styled.div`
display:flex;
align-items:center;
gap:5px;
width:100%;
margin-top:10px;
padding:0px 5px;

`
export const PPP = styled.p`
color:#000;
font-size:10.5px;
font-weight:600;

`
export const DIVS = styled.div`
display:flex;
align-items:center;
gap:3px;
background: #fff;
width:33%;
height:40px;
justify-content:flex-start;
border-radius:5px;
padding:0px 0px 0px 6px;
`
export const HOURDIV = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
gap:15px;
width:100%;
margin:25px 0px;
padding:0px 3px;

`
export const HOUR = styled.div`
display:flex;
align-items:center;
gap:5px;
width:35%;

`
export const TIMEDIV = styled.div`
display:flex;
align-items:center;
gap:5px;
width:65%;
`
export const TOGGLETIME = styled.div`
display:flex;
align-items:center;
background: #868686;
justify-content:center;
padding:0 10px;
height:35px;
border-radius:5px;
position:relative;
width:100%;
`

export const DARKBUTTON = styled.div`
display:flex;
align-items:center;
background:#000;
width:100%;
height:35px;
justify-content:space-evenly;
border-radius:5px;
`
export const AP = styled.p`
color:#000;
font-size:10px;
font-weight:bold;
position:absolute;
bottom:28px;
`
export const Radio = styled.button`
border:3px solid white;
padding:4px;
border-radius:50px;
`

export const Input = styled.input`
padding:4px 0px 4px 12px;
width:100%;
background: #868686;
color:#fff;
border:none;
outline:none;
z-index:1000;
position:absolute;

`
