import React, { FC, useEffect, useState } from "react";
import map from "../../assets/images/png/map.png";
import rect from "../../assets/images/png/rect.png";
import location from "../../assets/images/png/location.png";
import line from "../../assets/images/png/line.png";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import modalOption from "../../assets/images/png/modalOption.png";
import add from "../../../assets/images/svg/triangle-up.svg";
import subtract from "../../assets/images/svg/triangle-down.svg";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import {
  Modal,
  Div,
  Labels,
  INPUTDIV,
  Inputs,
  Texts,
  BLACKDIV,
  DISCOUNT,
  ButtonArea,
  DoneButton,
  SaveButton,
  LocationInput,
  PriceInput,
} from "../providerCarReg/fifthScreen/fifthScreen.styles";
// import { setVehicleDetailss } from '../../slices/provider'
// import { useAppDispatch } from '../../store'
// import { Box, Button, DiscountBox, FlexBox, IconBox, ImageBox, Input, InputBox, Label, ModalContainer } from './discountModal/index.styles';
import { useCarTravelUpdateMutation } from "../../services/providerApi";
import { ModalContainer } from "../providerVehicleProfile/discountModal/index.styles";
import {
  DirectionsRenderer,
  Polyline,
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  LoadScript,
} from "@react-google-maps/api";
import {
  Box,
  Button,
  DiscountBox,
  FlexBox,
  IconBox,
  ImageBox,
  Input,
  InputBox,
  Label,
} from "../discountModal/index.styles";
import CurrencyInput from "react-currency-input-field";
import { formatCurrencyPrefix } from "../../services/utils";
import { useAppSelector } from "../../store";

type Props = {
  vehicleDetails: any;
  setVehicleDetails: any;
  trip: any;
  travelDetails: any;
  setTravelData: any;
  setIsVisibleModalOne: any;
  setIsDiscountVisible: any;
  setShowSaveButton: any;
};

export const ModalOne: FC<Props> = ({
  setShowSaveButton,
  trip,
  setTravelData,
  travelDetails,
  vehicleDetails,
  setVehicleDetails,
  setIsVisibleModalOne,
}) => {

  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );
  const [destination, setDestination] = useState(
    trip?.destination ? trip?.destination : ""
  );

  const [pickupState, setPickupState] = useState(
    trip?.pickup_location ? trip?.pickup_location : ""
  );
  const [price, setPrice] = useState(trip?.price ? trip?.price : "");

  const [updateVehiclePrice, { data, isLoading, isSuccess, isError, error }] =
    useCarTravelUpdateMutation();

  const [autocomplete, setAutocomplete] = useState<any>(null);
  const [autocompleteDestination, setAutocompleteDestination] =
    useState<any>(null);

  const handleClose = () => {
    setIsVisibleModalOne(false);
  };
  const handleNewDestination = () => {
    const lastItem = travelDetails[travelDetails?.length - 1];
    const lastItemId = lastItem ? Number(lastItem.id) : 0;
    const lastItemTitle = lastItem ? Number(lastItem?.title) : 0;
    const newServicePrice = {
      // id: lastItemId + 1,
      title: lastItemTitle + 1,
      user_id: vehicleDetails?.user_id,
      vehicle_id: null,
      driver_id: vehicleDetails?.id,
      bike_id: null,
      pickup_location: pickupState,
      price,
      discount: lastItem?.discount,
      destination,
      // created_at: "2023-06-22T20:19:24.000000Z",
      // updated_at: "2023-06-22T20:19:24.000000Z"
    };

    const newServiceData = [...travelDetails, newServicePrice];
    setTravelData(newServiceData);

    setIsVisibleModalOne(false);
    console.log(travelDetails, "travelDetailstravelDetails");
  };

  const initiateNewDestination = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (travelDetails?.length >= 7) {
      notifyError("You Can only Set up to 7 Travel Destinations");

      handleClose();
    } else {
      handleNewDestination();
    }
  };

  const handlePickup = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setPickupState((event.target as HTMLTextAreaElement).value);
    setShowSaveButton(true);
  };

  const handleTravelLocation = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // setTravelLocation((event.target as HTMLTextAreaElement).value)
    setDestination((event.target as HTMLTextAreaElement).value);
    setShowSaveButton(true);
  };

  const handleTravelPrice = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setPrice(event);
    setShowSaveButton(true);
  };

  const handleTravelOne = () => {
    if (trip === "") {
      handleNewDestination();
    } else {
      const modifiedTravels = travelDetails?.map((travel: any) => {
        if (travel.title === trip?.title) {
          return {
            ...travel, // Spread the original object
            pickup_location: pickupState,
            destination: destination,
            price: price,
          };
        } else {
        }
        return travel; // Return unchanged object for other titles
      });
      setTravelData(modifiedTravels);
      if (modifiedTravels?.length < 1) {
        handleNewDestination();
      }
      handleClose();
    }
  };
  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  // const saveData = (): void => {
  //     //@ts-ignore
  //     updateVehiclePrice(travelDetails)
  // };

  // useEffect(() => {
  //     saveData()
  // }, [travelDetails])
  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const places = autocomplete.getPlace().formatted_address;
      if (places) {
        setPickupState(places);
      } else {
        setPickupState(autocomplete.getPlace().name);
      }
    }
  };
  const onPlaceChangedDestination = () => {
    if (autocompleteDestination !== null) {
      const places = autocompleteDestination.getPlace().formatted_address;
      if (places) {
        setDestination(places);
      } else {
        setDestination(autocompleteDestination.getPlace().name);
      }
    }
  };

  return (
    <ModalContainer onClick={() => handleClose()}>
      <ToastContainer />

      <Modal onClick={(e: any) => e.stopPropagation()}>
        <img src={map} width="97%" alt="" />
        <Labels style={{ padding: "10px 10px" }}>Where</Labels>
        <Labels
          style={{
            marginBottom: "10px",
            padding: "1px 10px",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          Travel out of state Maximum of 7 destinations
        </Labels>
        <INPUTDIV style={{ margin: "25px 0px" }}>
          {/* <Inputs
                            type="text"
                            placeholder='Type State'
                            value={pickupState}
                            onChange={handlePickup}
                            style={{ width: '100%', color: '#000', padding: '10px 10px 10px 40px', fontSize: '15px' }}
                        /> */}
          <Autocomplete
            className="autocomplete-wrap"
            onPlaceChanged={onPlaceChanged}
            onLoad={(value) => setAutocomplete(value)}
          >
            <LocationInput
              type="text"
              placeholder="Type State"
              value={pickupState}
              onChange={(e: any) => {
                // setOrigin(e.target.value);
                setPickupState(e.target.value);
              }}
              style={{
                width: "100%",
                color: "#000",
                padding: "10px 10px 10px 40px",
                fontSize: "15px",
              }}
            />
          </Autocomplete>
          <Texts>Pickup location</Texts>
          <img
            src={location}
            alt=""
            style={{ position: "absolute", left: "-3px" }}
          />
          <img
            src={line}
            alt=""
            style={{
              position: "absolute",
              left: "20px",
              top: "40px",
              zIndex: "1",
            }}
          />
        </INPUTDIV>
        <INPUTDIV>
          <Autocomplete
            className="autocomplete-wrap"
            onPlaceChanged={onPlaceChangedDestination}
            onLoad={(value) => setAutocompleteDestination(value)}
          >
            <LocationInput
              type="text"
              placeholder="Type State"
              value={destination}
              onChange={(e: any) => {
                // setOrigin(e.target.value);
                setDestination(e.target.value);
              }}
              style={{
                width: "100%",
                color: "#000",
                padding: "10px 10px 10px 40px",
                fontSize: "15px",
              }}
            />
          </Autocomplete>
          <Texts>Travel location(s)</Texts>
          <img
            src={rect}
            alt=""
            style={{ position: "absolute", left: "12px" }}
          />
        </INPUTDIV>
        <INPUTDIV style={{ margin: "15px 0px" }}>
          <PriceInput
            type="text"
            value="Set Price"
            readOnly
            style={{
              width: "60%",
              color: "#000",
              padding: "10px",
              fontSize: "15px",
            }}
          />
          <BLACKDIV>
            {/* <P style={{ left: '0px', top: '-37px' }}>Set Price</P> */}
            {/* <Inputs
                                type="text"
                                value={price}
                                onChange={handleTravelPrice}
                                placeholder='0.00'

                            /> */}

            <div style={{ width: "50%" }}>
              <CurrencyInput
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  color: "#fff",
                }}
                decimalsLimit={2}
                prefix={formatCurrencyPrefix(currentLocation)}

                placeholder="₦0.00"
                value={price}
                onValueChange={(e: any) => {
                  handleTravelPrice(e);
                }}
              />
            </div>
            <DISCOUNT>
              {/* <P style={{ left: '0px', top: '-45px' }}>Discount</P> */}
              0%
            </DISCOUNT>
          </BLACKDIV>
        </INPUTDIV>
        <ButtonArea>
          <SaveButton onClick={initiateNewDestination}>
            Save & Add New
          </SaveButton>
          <DoneButton onClick={handleTravelOne}>Done</DoneButton>
        </ButtonArea>
      </Modal>
    </ModalContainer>
  );
};

export const TravelDiscountModal: FC<Props> = ({
  setShowSaveButton,
  setTravelData,
  trip,
  travelDetails,
  setIsDiscountVisible,
  setVehicleDetails,
  vehicleDetails,
}) => {
  // const dispatch = useAppDispatch()

  const [discount, setDiscount] = useState(
    trip?.discount ? Number(trip?.discount) : 0
  );

  const handleIncrease = () => {
    setDiscount((prev: number) => prev + 1);
    setShowSaveButton(true);
  };
  const handleDecrease = () => {
    if (discount === 0) return;
    setDiscount((prev: number) => prev - 1);
    setShowSaveButton(true);
  };

  const handleDiscount = () => {
    const modifiedTravelDiscount = travelDetails?.map((travel: any) => {
      if (travel.title === trip?.title) {
        return {
          ...travel, // Spread the original object
          discount: discount,
        };
      }
      return travel; // Return unchanged object for other titles
    });

    setTravelData(modifiedTravelDiscount);
  };

  const handleSetDiscount = () => {
    // operation(discount, fullDayArray, discountFor)
    handleDiscount();
    setIsDiscountVisible(false);
  };

  return (
    <ModalContainer onClick={() => setIsDiscountVisible(false)}>
      <Box onClick={(e: any) => e.stopPropagation()} style={{ bottom: 150 }}>
        <ImageBox>
          <img src={modalOption} height="100%" width="100%" />
        </ImageBox>
        <DiscountBox>
          <Label>Attract more clients by offering a discount. </Label>

          <FlexBox>
            <InputBox>
              {/* <Input value={discount} /> */}

              <div
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  display: "flex",
                  flex: 1,
                }}
              >
                <p style={{ margin: 0, fontSize: 20, fontWeight: "bold" }}>
                  {discount < 10 ? `0${discount} %` : `${discount} %`}
                </p>

                <IconBox>
                  <FiChevronUp
                    style={{ fontWeight: 800 }}
                    onClick={handleIncrease}
                  />
                  <FiChevronDown onClick={handleDecrease} />
                </IconBox>
              </div>
            </InputBox>
            <Button onClick={handleSetDiscount}>Set Discount</Button>
          </FlexBox>
        </DiscountBox>
      </Box>
    </ModalContainer>
  );
};
