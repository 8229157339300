import styled from 'styled-components'
export const MembersBox = styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
width:95%;
gap:5px;
border-radius:5px;
background: #D9D9D9;
padding:1.2em .8em .4em .8em;
height:90px;
`
export const MembersDiv = styled.div`
display:flex;
align-items:center;
justify-content:end;
width:100%;
gap:10px;
position:relative;
margin-top:20px;
`
export const ImageDiv = styled.div`
width:45px;
height:45px;
border:3px solid #fff;
border-radius:50%;
display:flex;
align-items:center;
justify-content:center;
overflow:hidden;
z-index:100;
left:-10px;
position:absolute;
`

export const Data = styled.div`
display:flex;
flex-direction:column;
align-items:start;
justify-content:flex-start;
width:30%;
gap:10px;
border-radius:10px;
position:relative;
background:#000;
padding:.7em .7em;
height:65px;
`

export const Title = styled.p`
color: #000;
font-size:10px;
font-weight:700;
margin:0;
top:-17px;
left:7px;
position:absolute;

`
export const PP = styled.p`
color: #000;
font-size:10px;
font-weight:700;
margin:0;


`
export const P = styled.p`
color: #fff;
font-size:8px;
font-weight:600;
margin:0;

`

export const H3 = styled.h3`
font-weight:700;
color: #1D1C1C;
margin:0;
`
export const H6 = styled.h6`
color: #FFF;
margin:0;
`
export const Span = styled.span`
color: #FFD58C;
margin:0;
`