import React, { FC, useEffect, useRef, useState } from "react";
import { BackgroundBox, Button, H3, ModalBox } from "./index.styles";
import { Link } from "react-router-dom";

import deleteImage from "../../../assets/images/png/modalOption.png";
import { useAppSelector } from "../../../store";
const NoticeModal: FC<{ setIsAddModal }> = ({ setIsAddModal }) => {
  const user = useAppSelector((state: any) => state?.auth.auth);

  return (
    <>
      <BackgroundBox onClick={() => setIsAddModal((prev: any) => !prev)}>
        <ModalBox onClick={(e: any) => e.stopPropagation()}>
          <div className="image-container">
            <img src={deleteImage} style={{ width: "100%" }} />
          </div>
          <div className="text-container">
            <h2>Note</h2>
            &nbsp;
            {!user?.user_role ? (
              <>
                <p>
                  To post a travel, please create a service provider account or
                  login to your account.
                </p>
                <div className="buttons">
                  <Link to="/customer-signup" className="black">
                    Create Account
                  </Link>

                  <Link to="/login" className="theme">
                    Login
                  </Link>
                </div>
              </>
            ) : (
              <>
                <p>
                  Your account has no vehicle, please list a vehicle to proceed
                  to post a travel option
                </p>
                <div className="buttons">
                  <Link to="/choose-service" className="theme">
                    List Vehicle
                  </Link>

                  {/* <Link to="/" className="black">Cancel</Link> */}
                </div>
              </>
            )}
          </div>
        </ModalBox>
      </BackgroundBox>
    </>
  );
};

export default NoticeModal;
