import React from 'react'
import { CheckoutPayBtn, CheckoutPayWrapper, CheckoutSelectBtn, DropdownYellowBox, SelectWalletBtnWrap } from '../checkoutPayComponent/checkoutPay.styles'
import CheckoutWalletCardComponent from '../checkoutWalletCardComponent'
import { CheckoutErrorWrapper, CheckoutGreyWrap, ErrorOverlay, TextHead } from './checkoutPayError.styles'
import { GreyWrap } from '../collectMoneyOne/collectmoneyone.styles'
import { FundWalletOption, PillRadioWrap, CardTxt, OptionsImgBox } from '../fundWalletOne/fundwalletone.styles'
import { PillRadioBtn } from '../optionModal/optionmodal.styles'

import dropArrow from '../../assets/images/svg/arrow-down-vector.svg'
import visa from '../../assets/images/svg/visa-text.svg'
import master from '../../assets/images/svg/mastercard.svg'
import bank from '../../assets/images/svg/bank.svg'
import failed from '../../assets/images/png/failed-wallet.png'


const CheckoutPayErrorComponent = ({showBox, onBankPay, onCardPay}:any) => {
  return (
    <CheckoutErrorWrapper display={showBox}>
        <ErrorOverlay>
            <div className="error-box">
                <img src={failed} />
                <p>Ops! Sorry insufficient funds, please choose a payment option </p>
            </div>
        </ErrorOverlay>
        <CheckoutWalletCardComponent/>

        <SelectWalletBtnWrap>
            <CheckoutSelectBtn>
                <p>Select Wallet</p>

                <DropdownYellowBox>
                    <img src={dropArrow} />
                </DropdownYellowBox>
            </CheckoutSelectBtn>
        </SelectWalletBtnWrap>

        <CheckoutPayBtn>
            Pay
        </CheckoutPayBtn>

        <TextHead>
            I would like to pay with my
        </TextHead>

        <CheckoutGreyWrap>
            <FundWalletOption  onClick={() => onCardPay()}>
                <PillRadioWrap>
                    <PillRadioBtn activePill={''} />

                    <CardTxt>
                        My Card
                    </CardTxt>
                </PillRadioWrap>

                <OptionsImgBox bg={'#FFE8B6'}>
                    <img src={master} />

                    <img src={visa} />
                </OptionsImgBox>
            </FundWalletOption>

            <FundWalletOption  onClick={() => onBankPay()}>
                <PillRadioWrap>
                    <PillRadioBtn activePill={''} />

                    <CardTxt>
                        My Bank
                    </CardTxt>
                </PillRadioWrap>

                <OptionsImgBox bg={'rgba(0, 95, 238, 0.31)'}>
                    <img src={bank} />
                </OptionsImgBox>

            </FundWalletOption>
        </CheckoutGreyWrap>
    </CheckoutErrorWrapper>
  )
}

export default CheckoutPayErrorComponent