import React, { FC, useEffect, useState, useRef } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import { IoCarSport } from 'react-icons/io5';
import { BiUser } from 'react-icons/bi';
import { RiDashboard3Line } from 'react-icons/ri';
import { BsCalendar2Minus } from 'react-icons/bs';
import { GiReceiveMoney } from 'react-icons/gi';


import {
    AbsoluteDiv, Page, IconDiv, P, Button, PagePadding
} from './dashboard.styles';



import { Navigate, useNavigate } from 'react-router-dom';
import ProviderBalance from '../../../components/providerDashboard/providerBalance/providerBalance';
import ProviderActions from '../../../components/providerDashboard/providerActions/providerActions';
import AdminHeader from '../../../components/adminHeader/';
import DashboardInfo from '../../../components/Admin/DahboardInfo';
import LinkHeader from '../../../components/Admin/Header/header';

const AdminDashboard: FC = () => {
    return (
        <>
            <AdminHeader />
            <Page>


            <PagePadding className='padding'>

                <LinkHeader />

                    <Button>Access Super Admin</Button>
                    <DashboardInfo />
                </PagePadding>





            </Page>
        </>
    )
}
export default AdminDashboard