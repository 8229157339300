import React from 'react'
import { MenuBoxOverall, NavigationBar, NavigationPillWrap, NavigationPill, NavigationPillImg } from './navigationtab.styles'

import profile from '../../assets/images/svg/navigation-profile.svg'
import car from '../../assets/images/svg/pill-car.svg'
import wallet from '../../assets/images/svg/pill-wallet.svg'
import earning from '../../assets/images/svg/pill-earning.svg'
import safebox from '../../assets/images/svg/pill-safebox.svg'
import { NavLink } from 'react-router-dom'

const NavigationTab = ({profilePill, bookingsPill, walletPill, earningPill, assetsPill}: any) => {
  return (
    <>
        <MenuBoxOverall>
            <div className="inner-wrap">
                <NavigationBar>
                    <NavigationPillWrap>
                        <div className="navigation-inner">
                            <NavLink to='/user-profile-page'>
                                <NavigationPill pillActive={profilePill}>
                                    <NavigationPillImg pillActive={profilePill} src={profile} />

                                    <p>Profile</p>
                                </NavigationPill>
                            </NavLink>

                            <NavLink to='/user-booking-page'>
                                <NavigationPill pillActive={bookingsPill}>
                                    <NavigationPillImg src={car} pillActive={bookingsPill} />

                                    <p>Bookings</p>
                                </NavigationPill>
                            </NavLink>


                            <NavLink to='/user-wallet-page'>
                                <NavigationPill pillActive={walletPill}>
                                    <NavigationPillImg src={wallet} pillActive={walletPill}/>

                                    <p>Wallet</p>
                                </NavigationPill>
                            </NavLink>


                            {/* <NavLink to='/user-earning-page'>
                                <NavigationPill pillActive={earningPill}>
                                    <NavigationPillImg src={earning} pillActive={earningPill} />
                                    <p>Earnings</p>
                                </NavigationPill>
                            </NavLink>

                            <NavLink to='/'>
                                <NavigationPill pillActive={assetsPill}>
                                    <NavigationPillImg src={safebox} pillActive={assetsPill} />

                                    <p>Assets</p>
                                </NavigationPill>
                            </NavLink> */}

                        </div>

                    </NavigationPillWrap>
                </NavigationBar>
            </div>
        </MenuBoxOverall>
    </>
  )
}

export default NavigationTab