import React, { FC, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'

import { IoLocationSharp } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";

import {
  Input,
  VerifyButton,
  DIV,
  INPUTS,
  Text,
  DropDown,
  Modal,
  Option,
  Div,
  Label,
  Labels,
  LocationInput,
} from "./thirdScreen.styles";
import Footer from "../../footer";
import { useNavigate } from "react-router-dom";
import { setVehicleDetailss } from "../../../slices/provider";
import { useAppDispatch, useAppSelector } from "../../../store";
const ThirdScreen: FC<{
  setComponent: any;
  bikeSelection: any;
  setBikeSelection: any;
  setWidth: any;
  setQuestion: any;
  setVehicleDetails: any;
  vehicleDetails: any;
}> = ({
  vehicleDetails,
  setVehicleDetails,
  setWidth,
  setBikeSelection,
  setQuestion,
  bikeSelection,
  setComponent,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const vehicleInfo = useAppSelector(
    (state: any) => state?.provider?.vehicleDetails
  );

  const [autocomplete, setAutocomplete] = useState<any>(null);
  const [location, setLocation] = useState(vehicleInfo?.vehicle_location || "");
  const [numSeat, setNumSeat] = useState(vehicleInfo?.num_passengers);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      // const places = autocomplete.getPlace().formatted_address;

      // if (places) {
      //   setLocation(places);
      //   setVehicleDetails({
      //     ...vehicleDetails,
      //     vehicle_location: places,
      //   });
      //   dispatch(
      //     setVehicleDetailss({ ...vehicleDetails, vehicle_location: places })
      //   );
      // } else {
      //   setLocation(autocomplete.getPlace().name);
      // }

      const place = autocomplete.getPlace();

      if (place && place?.vicinity) {
        const cityComponent = place.address_components.find((component:any) =>
          component.types.includes("locality")
        );
        const countryComponent = place.address_components.find((component:any) =>
        component.types.includes("country")
      );
        // const stateComponent = place?.vicinity;
        if (cityComponent) {

          console.log(place)
          setLocation(cityComponent.long_name+","+" "+countryComponent.long_name);
          setVehicleDetails({
            ...vehicleDetails,
            vehicle_location: cityComponent.long_name+","+" "+countryComponent.long_name,
          });
          dispatch(
            setVehicleDetailss({ ...vehicleDetails, vehicle_location: cityComponent.long_name+","+" "+countryComponent.long_name })
          );
        }
      }
    }
  };

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setWidth(43);
    setQuestion(3);
  }, []);

  if (vehicleDetails.vehicle_type === "Delivery") {
    navigate("/bike-registration");
  }

  const openModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsVisible((prev) => !prev);
  };

  const handleNoOfSeat = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const inputValue: string = (event.target as HTMLTextAreaElement).value;
    const regex = /^[0-9\b]+$/;
    if (inputValue === "" || regex.test(inputValue)) {
      if (inputValue.length > 2) return;
      setNumSeat(inputValue);
    }

    setVehicleDetails({
      ...vehicleDetails,
      num_passengers: (event.target as HTMLTextAreaElement).value,
    });
    dispatch(
      setVehicleDetailss({
        ...vehicleDetails,
        num_passengers: (event.target as HTMLTextAreaElement).value,
      })
    );
  };

  const handleCategory = (category: string) => {
    setIsVisible((prev: any) => !prev);
    setVehicleDetails({
      ...vehicleDetails,
      vehicle_type: category,
    });
    dispatch(setVehicleDetailss({ ...vehicleDetails, vehicle_type: category }));
  };
  // const handleLocation = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //     setLocation((event.target as HTMLTextAreaElement).value)

  //     setVehicleDetails({
  //         ...vehicleDetails, vehicle_location: (event.target as HTMLTextAreaElement).value
  //     })
  //     dispatch(setVehicleDetailss({ ...vehicleDetails, vehicle_location: (event.target as HTMLTextAreaElement).value }));

  // };
  console.log(vehicleDetails, "kkk");

  return (
    <>
      <Input>
        <INPUTS
          value={numSeat}
          onChange={handleNoOfSeat}
          type="text"
          style={{
            width: "55%",
            fontSize: "14px",
            border: "none",
            outline: "none",
          }}
          placeholder="Passesnger Seats..."
        />
        {numSeat && (
          <Labels
            style={
              numSeat.length >= 2
                ? {
                    zIndex: 0,
                    position: "absolute",
                    left: "38px",
                    color: "#000",
                  }
                : { position: "absolute", left: "30px", color: "#000" }
            }
          >
            {numSeat === "1" ? "Seat" : "Seats"}
          </Labels>
        )}
        <VerifyButton style={{ width: "45%" }} onClick={openModal}>
          {vehicleInfo.vehicle_type ? (
            <Text>{vehicleInfo.vehicle_type}</Text>
          ) : (
            <Text>Category </Text>
          )}
          <DropDown>
            <IoIosArrowDown
              style={{ color: "#FFD58C", fontSize: 20, fontWeight: 600 }}
            />
          </DropDown>
        </VerifyButton>
      </Input>

      <div>
        {isVisible && (
          <Div>
            <Modal>
              <Label onClick={() => handleCategory("SUV Car")}>
                <Option>
                  <input type="radio" name="category" value="SUV Car" />
                  <Text> SUV Car</Text>
                </Option>
              </Label>
              <Label onClick={() => handleCategory("Saloon Car")}>
                <Option>
                  <input type="radio" name="category" value="Saloon Car" />
                  <Text>Saloon Car</Text>
                </Option>
              </Label>
              <Label onClick={() => handleCategory("Luxury Car")}>
                <Option>
                  <input type="radio" value="Luxury Car" name="category" />
                  <Text>Luxury Car</Text>
                </Option>
              </Label>
              <Label onClick={() => handleCategory("Sport Car")}>
                <Option>
                  <input type="radio" value="Sport Car" name="category" />
                  <Text>Sport Car</Text>
                </Option>
              </Label>

              <Label onClick={() => handleCategory("Vintage Car")}>
                <Option>
                  <input type="radio" value="Vintage Car" name="category" />
                  <Text>Vintage Car</Text>
                </Option>
              </Label>

              <br />
              <Label onClick={() => handleCategory("Mini Bus ")}>
                <Option>
                  <input type="radio" value="Mini Bus" name="category" />
                  <Text>Mini Bus </Text>
                </Option>
              </Label>

              <Label onClick={() => handleCategory("Coaster Bus")}>
                <Option>
                  <input type="radio" value="Coaster Bus" name="category" />
                  <Text>Coaster Bus </Text>
                </Option>
              </Label>
              <Label onClick={() => handleCategory("Luxury Bus")}>
                <Option>
                  <input type="radio" value="Luxury Bus" name="category" />
                  <Text>Luxury Bus </Text>
                </Option>
              </Label>
              <Label onClick={() => handleCategory("School Bus")}>
                <Option>
                  <input type="radio" value="School Bus" name="category" />
                  <Text>School Bus </Text>
                </Option>
              </Label>
              <br />
              <Label onClick={() => handleCategory("Van")}>
                <Option>
                  <input type="radio" value="Van" name="category" />
                  <Text>Van </Text>
                </Option>
              </Label>
              <Label onClick={() => handleCategory("Truck")}>
                <Option>
                  <input type="radio" value="Truck" name="category" />
                  <Text>Truck </Text>
                </Option>
              </Label>
              <Label onClick={() => handleCategory("Pick-up truck")}>
                <Option>
                  <input type="radio" value="Pick-up truck" name="category" />
                  <Text>Pick-up truck </Text>
                </Option>
              </Label>
              <Label onClick={() => handleCategory("Trailers")}>
                <Option>
                  <input type="radio" value="Trailers" name="category" />
                  <Text>Trailers </Text>
                </Option>
              </Label>
              <br />
              <Label onClick={() => handleCategory("Caterpilers")}>
                <Option>
                  <input type="radio" value="Caterpilers" name="category" />
                  <Text>Caterpilers </Text>
                </Option>
              </Label>
              <Label onClick={() => handleCategory("Boats")}>
                <Option>
                  <input type="radio" value="Boats" name="category" />
                  <Text>Boats </Text>
                </Option>
              </Label>
              <Label onClick={() => handleCategory("Jets")}>
                <Option>
                  <input type="radio" value="Jets" name="category" />
                  <Text>Jets </Text>
                </Option>
              </Label>
              <Label onClick={() => handleCategory("Helicopters")}>
                <Option>
                  <input type="radio" value="Helicopters" name="category" />
                  <Text>Helicopters </Text>
                </Option>
              </Label>
              <br />
              <Label onClick={() => handleCategory("Delivery Bikes")}>
                <Option>
                  <input type="radio" value="Delivery Bikes" name="category" />
                  <Text>Delivery Bikes </Text>
                </Option>
              </Label>

              <Label onClick={() => handleCategory("Power Bike")}>
                <Option>
                  <input type="radio" value="Power Bike" name="category" />
                  <Text>Power Bike </Text>
                </Option>
              </Label>
              <Label onClick={() => handleCategory("Fireproof truck")}>
                <Option>
                  <input type="radio" value="Fireproof truck" name="category" />
                  <Text>Fireproof truck </Text>
                </Option>
              </Label>

              <Label onClick={() => handleCategory("Emergency van")}>
                <Option>
                  <input type="radio" value="Emergency van" name="category" />
                  <Text>Emergency van </Text>
                </Option>
              </Label>
            </Modal>
          </Div>
        )}
      </div>

      <DIV>
        <Autocomplete
          className="autocomplete-wrap"
          onPlaceChanged={onPlaceChanged}
          onLoad={(value) => setAutocomplete(value)}
        >
          <LocationInput
            onChange={(e: any) => {
              setLocation(e.target.value);
            }}
            value={location}
            type="text"
            placeholder={
              vehicleDetails.vehicle_type.includes("Bike")
                ? "Bike Location"
                : "Vehicle Address"
            }
          />
        </Autocomplete>
        {/* <Autocomplete className='autocomplete-wrap'
                    onPlaceChanged={onPlaceChanged}
                    onLoad={(value) => setAutocomplete(value)}
                >
                    <input type="text" placeholder='Type here' onChange={(e) => {
                        setAddress(e.target.value);
                        setInputValue(e.target.value);
                    }}
                        value={address} />
                </Autocomplete> */}

        <IoLocationSharp
          style={{ color: "#FFA100", fontSize: 30, fontWeight: 500 }}
        />
      </DIV>
    </>
  );
};

export default ThirdScreen;
