import styled from 'styled-components'

export const Page = styled.div`
    position: relative;
    min-height: 100vh;
    place-items: center;
    transition: .5s;

`

export const DIV = styled.div`
display:flex;
align-items:center;
height:140px;
gap:15px;

`

export const IMAGEDIV = styled.div`
display:flex;
width:170px;
border:1px solid #eee;
border-radius:15px;
height:130px;
position:relative;
justify-content:center;
align-items:center;
overflow:hidden;
`

export const BUTTON = styled.div`
background-color:#fff;
position:absolute;
padding:.5em .7em;
border-radius:5px;
font-weight:600;
text-align:center;
color:#000;
font-size:12px;
`
export const SPAN = styled.span`
color:#ffa100;
`

export const H3 = styled.h3`
font-weight:500;
color:#fff;

`
export const H4 = styled.h4`
font-weight:400;
color:#fff;
padding-top:30px;

`

export const LoaderContainer = styled.div`
display:flex;
gap:10px;
margin-bottom:60px;
margin-top:0px;
height:3px;
width:70%;

`
export const DivContainer = styled.div`
display:flex;
background-color:#fff;
height:3px;
width:100%;
`

export const Loader = styled.div`
display:flex;
background: #FFA100;
width:50%;
`
export const Loader2 = styled.div`
display:flex;
background: #fff;
width:50%;
`

export const RANGEDIV = styled.div`
margin-top:60px;
position:relative;

`
export const Container = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
`
export const Button = styled.div`
background: #000;
color: #fff;
padding:0px 18px;
border-radius:5px;
border:1px solid #ddd;
display:flex;
align-items:center;
height:50px;
`
export const UploadButton = styled.div`
background: #ffa100;
color: #000;
padding:25px 18px;
border-radius:10px;
display:flex;
align-items:center;
height:40px;
`
export const DisabledButton = styled.div`
background: #000;
color: #aaa;
padding:25px 18px;
border-radius:10px;
display:flex;
align-items:center;
height:40px;
`
