import React from 'react'
import { BookingDayWrap, BookingDetailsWrap, BookingStateInfo, CartBox, CartFooter, CartImageWrap, CartLocationWrap, CartSections, EmptySpaceBox, NumberControlOverall, PriceBox, RemoveBtn, SectionsHeader } from '../cart/cart.styles'
import { InputIcons } from '../locationModal/locationmodal.styles'


import location from '../../assets/images/svg/location-black.svg'
import car from '../../assets/images/png/cart-car.png'
import minus from '../../assets/images/svg/minus.svg'
import plus from '../../assets/images/svg/plus.svg'
import clock from '../../assets/images/svg/clock.svg'
import calendar from '../../assets/images/svg/calendar-cart.svg'
import { BoxExtras, AssetOverall } from '../../pages/user/Homepage/homepage.styles'
import AssetCard from '../assetCard'
import OffersCard from '../offersCard'
import { H11 } from '../travelGarageBox/garagebox-styles'
import { BookingDetailsSubWrap, EditBookingText, LocationDetailsWrap } from '../deliveryCart/deliveryCart.styles'

const DriverCart = ({booking_title, pickup_date, pickup_time, pickup_location, image, trnasport_options, category, amount, no_of_hours, state_location, plate_number, vehicle_id, provider_id, vehicle_year, handleDelete, handleEditBooking}:any) => {
  return (
    <CartBox>
        <div className="cart-inner-wrap">
            <CartSections>
                <SectionsHeader>
                    <h3>{`${booking_title}`}</h3>
                    <hr />
                </SectionsHeader>

                <CartImageWrap>
                    <img src={image} />
                </CartImageWrap>
            </CartSections>

            <CartSections>
                <SectionsHeader>
                    <h3>Booking Details</h3>
                    <hr />
                </SectionsHeader>
                <EmptySpaceBox />

                {/* NOT USED */}
                {/* <BookingDayWrap>
                    <p>3 days</p>

                    <NumberControlOverall>
                        <div className="control-wrap">
                            <img src={minus} alt="" />
                        </div>

                        <div className="control-wrap">
                            <img src={plus} alt="" />
                        </div>
                    </NumberControlOverall>
                </BookingDayWrap> */}
                <LocationDetailsWrap>
                    <InputIcons src={location} iconWidth={'20px'} />
                    <p>{pickup_location}</p>
                </LocationDetailsWrap>


                <BookingDetailsSubWrap>
                    <img src={calendar} />

                    <p>{pickup_date}</p>
                </BookingDetailsSubWrap>

                <BookingDetailsSubWrap>
                    <img src={clock} />

                    <p>{pickup_time}</p>
                </BookingDetailsSubWrap>

                <BookingStateInfo>
                    {state_location}
                </BookingStateInfo>
            </CartSections>

            <CartSections>
                <SectionsHeader>
                    <h3>Price</h3>
                    <hr />
                </SectionsHeader>

                <PriceBox>
                    <p>₦{`${(amount)?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}</p>
                </PriceBox>
            </CartSections>
        </div>

        <CartFooter>
            <div className="cart-footer-inner">
                <div className="btns-flex">
                    {/* <EditBookingText onClick={() => handleEditBooking()}>
                        Edit
                    </EditBookingText> */}

                    <RemoveBtn onClick={() => handleDelete()}>
                        Remove
                    </RemoveBtn>
                </div>

                <h3>Sub-total</h3>

                <h3>₦{`${(amount)?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}</h3>
            </div>
        </CartFooter>
    </CartBox>
  )
}

export default DriverCart