import React, { FC, useEffect, useState } from 'react'

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider,StaticTimePicker } from '@mui/x-date-pickers';

import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


import {
    Modal, Label, OptionDiv, Flex, DIV, Text, DIVS, DARKBUTTON, AP, Radio, TOGGLETIME, HOUR, TIMEDIV,
    HOURDIV, PP, DROPDOWN, DROPDOWNDIV, PPP, DROPDOWNCOL, LabelClick, Input
} from './seventhScreen.styles';
import map from '../../../assets/images/png/map.png'
import rect from '../../../assets/images/png/rect.png'
import location from '../../../assets/images/png/location.png'
import line from '../../../assets/images/png/line.png'
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment';
import { setVehicleDetailss } from '../../../slices/provider';
import { useAppDispatch } from '../../../store';
import BookingTimeModal from '../../bookingTimeModal';
import AvailabilityTimeModal from '../../availabilityTimeModal';


export const ModalOne: FC<{ setVehicleDetails: any, vehicleDetails: any }> =
    ({ vehicleDetails, setVehicleDetails }) => {
        const dispatch = useAppDispatch()


        const [airConYes, setAirConYes] = useState<boolean>(false)
        const [airConNo, setAirConNo] = useState<boolean>(false)
        const [comeWithDriverYes, setComeWithDriverYes] = useState<boolean>(false)
        const [comeWithDriverNo, setComeWithDriverNo] = useState<boolean>(false)
        const [vehicleTrackerYes, setVehicleTrackerYes] = useState<boolean>(false)
        const [vehicleTrackerNo, setVehicleTrackerNo] = useState<boolean>(false)
        const [nightTripsYes, setNightTripsYes] = useState<boolean>(false)
        const [nightTripsNo, setNightTripsNo] = useState<boolean>(false)
        const [selfDriveYes, setSelfDriveYes] = useState<boolean>(false)
        const [selfDriveNo, setSelfDriveNo] = useState<boolean>(false)
        const [travelYes, setTravelYes] = useState<boolean>(false)
        const [travelNo, setTravelNo] = useState<boolean>(false)

        const [fuelTypePetrol, setFuelTypePetrol] = useState<boolean>(false)
        const [fuelTypeElectric, setFuelTypeElectric] = useState<boolean>(false)
        const [fuelTypeDiesel, setFuelTypeDiesel] = useState<boolean>(false)

        const [airCon, setAirCon] = useState(vehicleDetails?.air_con)
        const [comeWithDriver, setComeWithDriver] = useState(vehicleDetails?.come_with_driver)
        const [vehicleTracker, setVehicleTracker] = useState(vehicleDetails?.vehicle_tracker)
        const [fuelType, setFuelType] = useState(vehicleDetails?.fuel_type)
        const [nightTrips, setNightTrip] = useState(vehicleDetails?.night_trips)
        const [selfDrive, setSelfDrive] = useState(vehicleDetails?.self_drive)
        const [carPool, setCarpool] = useState(vehicleDetails?.travel_carpool)

        const handleAc = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setAirCon('Yes')
            setAirConYes(true)
            setAirConNo(false)
            setVehicleDetails({
                ...vehicleDetails, air_con: 'Yes'

            })
        };
        const handleOffAc = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setAirCon('No')
            setAirConNo(true)
            setAirConYes(false)
            setVehicleDetails({
                ...vehicleDetails, air_con: 'No',
            })
        };

        const handleComeWithDriver = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setComeWithDriver('Yes')
            setComeWithDriverYes(true)
            setComeWithDriverNo(false)
            setVehicleDetails({
                ...vehicleDetails, come_with_driver: 'Yes',
            })
            dispatch(setVehicleDetailss(
                { ...vehicleDetails, come_with_driver: 'Yes' }));

        };
        const handleComeWithDriverNo = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setComeWithDriver('No')
            setComeWithDriverYes(false)
            setComeWithDriverNo(true)
            setVehicleDetails({
                ...vehicleDetails, come_with_driver: 'No',
            })
            dispatch(setVehicleDetailss(
                { ...vehicleDetails, come_with_driver: 'No' }));
        };

        const handleVehicleTracker = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setVehicleTracker('Yes')
            setVehicleTrackerYes(true)
            setVehicleTrackerNo(false)
            setVehicleDetails({
                ...vehicleDetails, vehicle_tracker: 'Yes',
            })
            dispatch(setVehicleDetailss(
                { ...vehicleDetails, vehicle_tracker: 'Yes' }));

        };
        const handleVehicleTrackerNo = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setVehicleTracker('No')
            setVehicleTrackerNo(true)
            setVehicleTrackerYes(false)
            setVehicleDetails({
                ...vehicleDetails, vehicle_tracker: 'No',
            })
            dispatch(setVehicleDetailss(
                { ...vehicleDetails, vehicle_tracker: 'No' }));
        };



        const handleNightTrips = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setNightTrip('Yes')
            setNightTripsYes(true)
            setNightTripsNo(false)
            setVehicleDetails({
                ...vehicleDetails, night_trips: 'Yes',
            })
            dispatch(setVehicleDetailss(
                { ...vehicleDetails, night_trips: 'Yes' }));

        };
        const handleNightTripsNo = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setNightTrip('No')
            setNightTripsNo(true)
            setNightTripsYes(false)
            setVehicleDetails({
                ...vehicleDetails, night_trips: 'No',
            })
            dispatch(setVehicleDetailss(
                { ...vehicleDetails, night_trips: 'No' }));
        };

        const handleSelfDrive = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setSelfDrive('Yes')
            setSelfDriveYes(true)
            setSelfDriveNo(false)
            setVehicleDetails({
                ...vehicleDetails, self_drive: 'Yes',
            })
            dispatch(setVehicleDetailss(
                { ...vehicleDetails, self_drive: 'Yes' }));
        };
        const handleSelfDriveNo = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setSelfDrive('No')
            setSelfDriveYes(false)
            setSelfDriveNo(true)
            setVehicleDetails({
                ...vehicleDetails, self_drive: 'No',
            })
            dispatch(setVehicleDetailss(
                { ...vehicleDetails, self_drive: 'No' }));
        };

        const handleCarpool = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setCarpool('Yes')
            setTravelYes(true)
            setTravelNo(false)
            setVehicleDetails({
                ...vehicleDetails, travel_carpool: 'Yes',
            })
            dispatch(setVehicleDetailss(
                { ...vehicleDetails, travel_carpool: 'Yes' }));
        };

        const handleCarpoolNo = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setCarpool('No')
            setTravelNo(true)
            setTravelYes(false)
            setVehicleDetails({
                ...vehicleDetails, travel_carpool: 'No',
            })
            dispatch(setVehicleDetailss(
                { ...vehicleDetails, travel_carpool: 'No' }));

        };

        const handleFuelTypePetrol = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setFuelType('Petrol')
            setFuelTypePetrol(true)
            setFuelTypeElectric(false)
            setFuelTypeDiesel(false)
            setVehicleDetails({
                ...vehicleDetails, fuel_type: 'Petrol',

            })
            dispatch(setVehicleDetailss(
                { ...vehicleDetails, fuel_type: 'Petrol' }));
        };
        const handleFuelTypeElectric = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setFuelType('Electric')
            setFuelTypePetrol(false)
            setFuelTypeElectric(true)
            setFuelTypeDiesel(false)
            setVehicleDetails({
                ...vehicleDetails, fuel_type: 'Electric',

            })
            dispatch(setVehicleDetailss(
                { ...vehicleDetails, fuel_type: 'Electric' }));
        };
        const handleFuelTypeDiesel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setFuelType('Diesel')
            setFuelTypePetrol(false)
            setFuelTypeElectric(false)
            setFuelTypeDiesel(true)
            setVehicleDetails({
                ...vehicleDetails, fuel_type: 'Diesel',

            })
            dispatch(setVehicleDetailss(
                { ...vehicleDetails, fuel_type: 'Diesel' }));
        };



        const notifyError = (text: any) => toast.error(text, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",

        })


        return (
            <>
                <ToastContainer />
                <Modal>
                    <Flex>
                        <Label>Is the Air Conditioner working?</Label>
                        <OptionDiv>
                            <DIV>
                                <LabelClick onClick={handleAc}>
                                    <Radio style={airConYes || airCon === 'Yes' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> Yes</Text>
                                </LabelClick>
                                <Label onClick={handleOffAc}>
                                    <Radio style={airConNo || airCon === 'No' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> No</Text>
                                </Label>
                            </DIV>
                        </OptionDiv>
                    </Flex>

                    <Flex>
                        <Label>Does it come with a driver?</Label>
                        <OptionDiv>
                            <DIV>
                                <LabelClick onClick={handleComeWithDriver}>
                                    <Radio style={comeWithDriverYes || comeWithDriver === 'Yes' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> Yes</Text>
                                </LabelClick>

                                <Label onClick={handleComeWithDriverNo}>
                                    <Radio style={comeWithDriverNo || comeWithDriver === 'No' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> No</Text>
                                </Label>
                            </DIV>
                        </OptionDiv>
                    </Flex>

                    <Flex>
                        <Label>Do you have vehicle tracker? </Label>
                        <OptionDiv>
                            <DIV>
                                <Label onClick={handleVehicleTracker}>
                                    <Radio style={vehicleTrackerYes || vehicleTracker === 'Yes' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> Yes</Text>
                                </Label>

                                <Label onClick={handleVehicleTrackerNo}>
                                    <Radio style={vehicleTrackerNo || vehicleTracker === 'No' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> No</Text>
                                </Label>
                            </DIV>
                        </OptionDiv>
                    </Flex>

                    <Flex>
                        <Label>Is it available for night trips? </Label>
                        <OptionDiv>
                            <DIV>
                                <Label onClick={handleNightTrips}>
                                    <Radio style={nightTripsYes || nightTrips === 'Yes' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text>Yes</Text>
                                </Label>

                                <Label onClick={handleNightTripsNo}>
                                    <Radio style={nightTripsNo || nightTrips === 'No' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> No</Text>
                                </Label>
                            </DIV>
                        </OptionDiv>
                    </Flex>

                    <Flex>
                        <Label>Is it available for self drive? </Label>
                        <OptionDiv>
                            <DIV>
                                <Label onClick={handleSelfDrive}>
                                    <Radio style={selfDriveYes || selfDrive === 'Yes' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> Yes</Text>
                                </Label>

                                <Label onClick={handleSelfDriveNo} >
                                    <Radio style={selfDriveNo || selfDrive === 'No' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> No</Text>
                                </Label>
                            </DIV>
                        </OptionDiv>
                    </Flex>

                    <Flex>
                        <Label>Is this vehicle available for travel carpool? </Label>
                        <OptionDiv>
                            <DIV>
                                <Label onClick={handleCarpool}>
                                    <Radio style={travelYes || carPool === 'Yes' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> Yes</Text>
                                </Label>

                                <Label onClick={handleCarpoolNo}>

                                    <Radio style={travelNo || carPool === 'No' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> No</Text>
                                </Label>
                            </DIV>
                        </OptionDiv>
                    </Flex>

                    <Flex style={{ gap: '3px' }}>
                        <Label style={{ width: '30%' }}>Fuel Type </Label>
                        <OptionDiv>
                            <DIV>

                                <Label onClick={handleFuelTypePetrol}>
                                    <Radio style={fuelTypePetrol || fuelType === 'Petrol' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> Petrol</Text>
                                </Label>
                            </DIV>
                        </OptionDiv>
                        <OptionDiv>
                            <DIV>
                                <Label onClick={handleFuelTypeElectric}>
                                    <Radio style={fuelTypeElectric || fuelType === 'Electric' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> Electric</Text>
                                </Label>
                            </DIV>
                        </OptionDiv>
                        <OptionDiv>
                            <DIV>
                                <Label onClick={handleFuelTypeDiesel}>
                                    <Radio style={fuelTypeDiesel || fuelType === 'Diesel' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> Diesel</Text>
                                </Label>

                            </DIV>
                        </OptionDiv>


                    </Flex>
                </Modal>
            </>

        )
    }


export const SetAvailability: FC<{ setVehicleDetails: any, vehicleDetails: any }> = ({ vehicleDetails, setVehicleDetails }) => {

    let today = new Date();
    today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0

    const [open, setOpen] = useState(false);
    const [close, setClose] = useState(false);



    const [showTimeModal, setShowTimeModal] = useState(false);

    const [availableTime, setAvailableTime] = useState(today)
    const [closingTime, setClosingTime] = useState(today)



    const [availabilityDays, setAvailabilityDays] = useState<any>('')
    const [availabilityFullDay, setAvailabilityFullDay] = useState<any>('')

    let [day, setDay] = useState('');


    let [checkMonday, setCheckMonday] = useState(false);
    let [checkTuesday, setCheckTuesday] = useState(false);
    let [checkWednesday, setCheckWednesday] = useState(false);
    let [checkThursday, setCheckThursday] = useState(false);
    let [checkFriday, setCheckFriday] = useState(false);
    let [checkSaturday, setCheckSaturday] = useState(false);
    let [checkSunday, setCheckSunday] = useState(false);
    let [checkEveryDay, setCheckEveryDay] = useState(false);


    let availabilityArray: object = [];



    const operation = (data: any, title: string, open_time: string, close_time: string) => {
        vehicleDetails.availability.push(...data, { title, open_time, close_time });

        //this is to prevent the price deta from getting duplicated
        //@ts-ignore
        const uniqueAvailability = vehicleDetails.availability.reduce((uniqueTitle, title) => {
            //@ts-ignore
            if (!uniqueTitle.some(p => p.title === title.title)) {
                uniqueTitle.push(title);
            }
            return uniqueTitle;
        }, []);


        vehicleDetails.availability = uniqueAvailability;

        // setVehicleDetails({
        //     ...vehicleDetails, availability:uniqueAvailability
        // })


    };




    const deleteAvailability = (title: string) => {
        //@ts-ignore
        setVehicleDetails(prevDetails => {
            //@ts-ignore
            const filteredDetails = prevDetails.availability.filter(detail => detail.title !== title);
            return { ...prevDetails, availability: filteredDetails };
        });
    };

    //@ts-ignore
    const handleMonday = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        setCheckMonday(prev => !prev)
        //take out that particular day when it is unchecked
        if (checkMonday === true) {
            deleteAvailability("Monday")
        }
        operation(availabilityArray, "Monday", '24 Hours', '24 Hours')
        setDay('Monday')
    }


    const handleTuesday = () => {
        setCheckTuesday(prev => !prev)
        //take out that particular day when it is unchecked
        if (checkTuesday === true) {
            deleteAvailability("Tuesday")
        }
        operation(availabilityArray, "Tuesday", '24 Hours', '24 Hours')
        setDay('Tuesday')

    }

    const handleWednesday = () => {
        setCheckWednesday(prev => !prev)
        //take out that particular day when it is unchecked
        if (checkWednesday === true) {
            deleteAvailability("Wednesday")
        }
        operation(availabilityArray, "Wednesday", '24 Hours', '24 Hours')
        setDay('Wednesday')

    }

    const handleThursday = () => {
        setCheckThursday(prev => !prev)
        //take out that particular day when it is unchecked
        if (checkThursday === true) {
            deleteAvailability("Thursday")
        }
        operation(availabilityArray, "Thursday", '24 Hours', '24 Hours')
        setDay('Thursday')


    }

    const handleFriday = () => {
        setCheckFriday(prev => !prev)
        //take out that particular day when it is unchecked
        if (checkFriday === true) {
            deleteAvailability("Friday")
        }
        operation(availabilityArray, "Friday", '24 Hours', '24 Hours')
        setDay('Friday')


    }

    const handleSaturday = () => {
        setCheckSaturday(prev => !prev)
        //take out that particular day when it is unchecked
        if (checkSaturday === true) {
            deleteAvailability("Saturday")
        }
        operation(availabilityArray, "Saturday", '24 Hours', '24 Hours')
        setDay('Saturday')


    }

    const handleSunday = () => {
        setCheckSunday(prev => !prev)


        //take out that particular day when it is unchecked
        if (checkSunday === true) {
            deleteAvailability("Sunday")
        }
        operation(availabilityArray, "Sunday", '24 Hours', '24 Hours')
        setDay('Sunday')
    }

    const handleEveryDay = () => {
        setCheckEveryDay(prev => !prev)

        //take out that particular day when it is unchecked
        if (checkEveryDay === true) {
            deleteAvailability("EveryDay")
        }
        operation(availabilityArray, "EveryDay", '24 Hours', '24 Hours')
        setDay('EveryDay')
    }


    // const handleCloseTime = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //     // setCheckSunday(prev => !prev)
    //     //@ts-ignore
    //     vehicleDetails?.availability?.map(a => {
    //         if (a.title === day) {
    //             a.close_time = (event.target as HTMLTextAreaElement).value;
    //         }
    //         return a;
    //     });
    // }


    // const handleOpenTime = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    //     // setCheckSunday(prev => !prev)
    //     //@ts-ignore

    // }

    // check if the availabilityArray has some days
    const daysPresence: Record<string, boolean> = {};
    //@ts-ignore
    vehicleDetails?.availability.forEach(day => {
        //@ts-ignore
        daysPresence[day.title] = true;
    });

    //@ts-ignore
    const onKeyDown = (e) => {
        e.preventDefault();
    };

    console.log('availableTime', availableTime)


    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <DROPDOWNCOL>
                <DROPDOWN>
                    <DROPDOWNDIV>
                        <DIVS>
                            <Label style={{ padding: '0px' }} onClick={handleMonday}>

                                <input checked={(checkMonday && daysPresence.Monday) || checkEveryDay} style={{ marginBottom: '3px' }} className="availability-radio" type="radio" name="monday_available" value="Monday" />
                                <PPP> Mondays</PPP>
                            </Label>
                        </DIVS>
                        <DIVS>
                            <Label style={{ padding: '0px' }} onClick={handleTuesday} >
                                <input checked={(checkTuesday && daysPresence.Tuesday) || checkEveryDay} style={{ marginBottom: '3px' }} className="availability-radio" type="radio" name="tuesday_available" value="Tuesday" />
                                <PPP> Tuesday</PPP>
                            </Label>
                        </DIVS>
                        <DIVS>
                            <Label style={{ padding: '0px' }} onClick={handleWednesday}>
                                <input checked={(checkWednesday && daysPresence.Wednesday) || checkEveryDay} style={{ marginBottom: '3px' }} type="radio" className="availability-radio" name="wednesday_available" value="Wednesday" />
                                <PPP> Wednesday</PPP>
                            </Label>
                        </DIVS>


                    </DROPDOWNDIV>
                    <DROPDOWNDIV>
                        <DIVS>
                            <Label style={{ padding: '0px' }} onClick={handleThursday}>
                                <input checked={(checkThursday && daysPresence.Thursday) || checkEveryDay} style={{ marginBottom: '3px' }} type="radio" className="availability-radio" name="thursday_available" value="Thursday" />
                                <PPP> Thursday</PPP>
                            </Label>
                        </DIVS>
                        <DIVS>
                            <Label style={{ padding: '0px' }} onClick={handleFriday}>
                                <input checked={(checkFriday && daysPresence.Friday) || checkEveryDay} style={{ marginBottom: '3px' }} type="radio" className="availability-radio" name="friday_available" value="Friday" />
                                <PPP> Friday</PPP>
                            </Label>
                        </DIVS>


                        <DIVS>


                            <Label style={{ padding: '0px' }} onClick={handleSaturday}
                            >
                                <input checked={(checkSaturday && daysPresence.Saturday) || checkEveryDay} style={{ marginBottom: '3px' }} type="radio" className="availability-radio" name="saturday_available" value="Saturday" />
                                <PPP> Saturday</PPP>
                            </Label>
                        </DIVS>

                    </DROPDOWNDIV>
                    <DROPDOWNDIV>
                        <DIVS>


                            <Label style={{ padding: '0px' }} onClick={handleSunday}
                            >
                                <input checked={(checkSunday && daysPresence.Sunday) || checkEveryDay} style={{ marginBottom: '3px' }} type="radio" className="availability-radio" name="sunday_available" value="Sunday" />
                                <PPP> Sunday</PPP>
                            </Label>
                        </DIVS>

                        <DIVS>


                            <Label style={{ padding: '0px', width: '100%' }} onClick={handleEveryDay}
                            >
                                <input checked={checkEveryDay && daysPresence.EveryDay} style={{ marginBottom: '3px' }} type="radio" className="availability-radio" name="everyday_available" value="EveryDay" />
                                <PPP> Every Day</PPP>
                            </Label>
                        </DIVS>



                    </DROPDOWNDIV>


                </DROPDOWN>


                {
                    (checkMonday || checkTuesday || checkWednesday || checkThursday || checkFriday || checkSaturday
                        || checkSunday || checkEveryDay) &&
                    <HOURDIV>
                        <HOUR>
                            <DARKBUTTON>


                                <Label style={{ padding: '0px', width: '100%', marginLeft: '7px' }}>
                                    <input style={{ marginBottom: '5px' }} className="availability-radio" type="radio" name="hour_available" value="24 Hours" />
                                    <PP style={{ fontSize: '12px' }}> 24 Hours</PP>
                                </Label>
                            </DARKBUTTON>
                        </HOUR>
                        <TIMEDIV>
                            <input style={{ marginBottom: '5px' }} className='availability-radio' type="radio" name="hour_available" value="flexible" />


                            <TOGGLETIME onClick={
                                //@ts-ignore
                                (e) => setOpen(true)
                            }>
                                <AP>Open time</AP>

                                <Input
                                    value={moment(availableTime).format("hh:mm a")}
                                    type="text"
                                />
                                <div className='mui'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <StaticTimePicker
                                        
                                            displayStaticWrapperAs="mobile"
                                            value={availableTime}
                                            onChange={(time: any) => {
                                                setAvailableTime(time);
                                                //@ts-ignore
                                                vehicleDetails?.availability?.map(a => {
                                                    if (a.title === day) {
                                                        a.close_time = moment(time).format("hh:mm a");
                                                    }
                                                    return a;
                                                });
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                        />

                                    </LocalizationProvider>
                                </div>
                            </TOGGLETIME>
                            <TOGGLETIME onClick={
                                //@ts-ignore
                                (e) => setClose(true)
                            }>
                                <AP>Close time</AP>
                                {/* <PP>0:00PM</PP> */}
                                <Input
                                    value={moment(closingTime).format("hh:mm a")}
                                    type="text"
                                />

                                <div className='mui'>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DesktopTimePicker
                                            open={close}
                                            //@ts-ignore
                                            onOpen={() => setClose(true)}
                                            //@ts-ignore
                                            onClose={() => setClose(false)}
                                            value={closingTime}
                                            //@ts-ignore
                                            // minTime={new Date(0, 0, 0, min)}
                                            onChange={(time: any) => {
                                                setClosingTime(time);
                                                //@ts-ignore
                                                vehicleDetails?.availability?.map(a => {
                                                    if (a.title === day) {
                                                        a.close_time = moment(time).format("hh:mm a");
                                                    }
                                                    return a;
                                                });
                                            }}
                                            renderInput={(params) => <TextField
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                //@ts-ignore
                                                onClick={(e) => setClose(true)}
                                                //@ts-ignore
                                                onKeyDown={onKeyDown}
                                                {...params} />}
                                        />

                                    </LocalizationProvider>
                                </div>

                            </TOGGLETIME>







                        </TIMEDIV>
                    </HOURDIV>
                }


            </DROPDOWNCOL>
        </div>
    )
}


