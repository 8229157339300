import React, { FC, useEffect, useState, useRef } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { IMAGE1, SPAN, BLACKDIV, PP, INPUTDIV, DIVS, Container, Button, BUTTON, IMAGEDIV, IMAGESDIV, Page, DIV, H44, H3 } from './insuranceSetup.styles';
import Header from '../../../components/providerHeader';

import VehicleDoc from '../../../components/providerCarReg/vehicleDoc/vehicleDoc'
import blueCar from '../../../assets/images/png/blueCar.png'
import whiteMark from '../../../assets/images/png/whiteMark.png'
import SeventhScreen from '../../../components/providerCarReg/seventhScreen/seventhScreen';
import SetupInsurance from '../../../components/providerInsurance/firstScreen/firstScreen';
import SecondScreen from '../../../components/providerInsurance/secondScreen/secondScreen';
import { useAppSelector } from '../../../store';
import { useGetVehicleByIdQuery } from '../../../services/providerApi';
import { baseImageUrl } from '../../../services/utils';
import { PagePadding } from '../../admin/Dashboard/dashboard.styles';

const InsuranceSetup: FC = () => {

    const lastVehicleDetails: any = useAppSelector((state: any) => state?.provider.vehicle)
    const { data: vehicleProfile, isFetching: isFetchingVehicleProfile, refetch: refetchVehicleProfile } =
        useGetVehicleByIdQuery(lastVehicleDetails[0]?.id)

    useEffect(() => {
        refetchVehicleProfile()

    }, [refetchVehicleProfile])


    let [component, setComponent] = useState(1)
    let [hasInsurance, setHasInsurance] = useState(false)
    let [isSetInsurance, setIsSetInsurance] = useState(true)

    const handlePrevComponent = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        setComponent((prev) => prev - 1)
    };

    const handleUploadInsurance = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        setComponent((prev) => prev = 2)
        setIsSetInsurance(false)
        setHasInsurance(true)
    };

    const setInsurance = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        setComponent((prev) => prev = 1)
        setHasInsurance(false)
        setIsSetInsurance(true)
    };
    return (
        <>
            <Header />
            <Page> 
            <PagePadding className='padding'>
                    <div style={{ paddingTop: '10px', display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '20px', marginBottom: '30px' }}>
                        <BsChevronLeft  style={{ color: 'white', fontSize: 25, fontWeight: 600 }} />
                        <H3>Setup Insurance</H3>
                    </div>

                    <DIV>
                        <IMAGEDIV>
                            <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src={baseImageUrl + vehicleProfile?.data[0]?.image1} />
                        </IMAGEDIV>
                        <IMAGESDIV>
                            <IMAGE1>
                                <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src={baseImageUrl + vehicleProfile?.data[0]?.image2} />

                            </IMAGE1>
                            <IMAGE1>
                                <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src={baseImageUrl + vehicleProfile?.data[0]?.image3} />

                            </IMAGE1>
                            <IMAGE1>
                                <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src={baseImageUrl + vehicleProfile?.data[0]?.image5} />

                            </IMAGE1>
                            <IMAGE1>
                                <img style={{ objectFit: 'cover', width: '100%', height: '100%' }} src={baseImageUrl + vehicleProfile?.data[0]?.image1} />

                            </IMAGE1>
                        </IMAGESDIV>
                    </DIV>
                    <H44 style={{ fontWeight: 600 }}>
                        Does your vehicle have comprehensive insurance? <SPAN>*</SPAN>
                    </H44>

                    <INPUTDIV>
                        <BLACKDIV>
                            <DIVS>
                                <button onClick={handleUploadInsurance} style={hasInsurance ? { height: '15px', padding: '7px', background: '#ffa100', border: '2px solid white', borderRadius: '50%' } : { height: '15px', padding: '7px', background: 'transparent', border: '2px solid white', borderRadius: '50%' }}>
                                </button>
                                <PP>Yes</PP>
                            </DIVS>
                            <DIVS>
                                <button onClick={setInsurance} style={isSetInsurance ? { height: '15px', padding: '7px', background: '#ffa100', border: '2px solid white', borderRadius: '50%' } : { height: '15px', padding: '7px', background: 'transparent', border: '2px solid white', borderRadius: '50%' }}>
                                </button>
                                <PP>No</PP>
                            </DIVS>

                        </BLACKDIV>

                    </INPUTDIV>

                    {component === 1 && <SetupInsurance />}
                    {component === 2 && <SecondScreen />}

                    {/* <Container>
                    <Button>
                        <h4>OK</h4>
                        <img src={whiteMark} alt="" width={28} height={23} />
                    </Button>
                    <DIV>
                        <BsChevronLeft style={{ color: 'white', fontSize: 30, fontWeight: 600 }} />
                        <BsChevronRight style={{ color: 'white', fontSize: 30, fontWeight: 600 }} />

                    </DIV>
                </Container> */}
                    {/* <VehicleDoc /> */}
                </PagePadding>
            </Page>
        </>
    )
}
export default InsuranceSetup