import React, { useState } from 'react'
import { ChangeBtn, InputLabelLeftBox, InputLabelRightBox, InputLabelWrap, PencilImage, ProfileInputBox, ProfileInputWrap, TextInputOverall, UsernameInfo } from './profiletextinput.styles'
import PhoneInput from 'react-phone-number-input'

import editPen from '../../assets/images/svg/edit-pencil.svg'

const ProfileTextInputComponent = (
    {
        labelName,
        edit,
        verified,
        inputType,
        inputPlaceholder, 
        inputBox,
        changeBtnBox,
        inputPhoneBox,
        inputValue,
        isReadOnly,
        handleUsername,
        newUserValue,
        setChangeInputValue,
        isChangeWarning,
        isUsernameChanged,
    }:any) => {

        const [value, setValue] = useState<any>(inputValue)
  return (
    <>
        <TextInputOverall>
            <InputLabelWrap>
                <InputLabelLeftBox>
                    <p>{labelName}</p>
                </InputLabelLeftBox>

                <InputLabelRightBox>
                    {edit && <PencilImage src={editPen} />}
                    {verified && <p>Verified</p>}
                </InputLabelRightBox>
            </InputLabelWrap>

            <ProfileInputWrap>
                {
                    inputBox &&  
                    <>
                        <ProfileInputBox type={inputType} placeholder={inputPlaceholder} value={newUserValue} readOnly={isReadOnly} onChange={(e:any) => setChangeInputValue(e.target.value)} />
                        {isChangeWarning && 
                            (
                                isUsernameChanged &&
                                <UsernameInfo>
                                    You can only change your username ONCE.
                                </UsernameInfo>
                            )

                        }
                    </>

                }

                {
                    inputPhoneBox && 
                    <PhoneInput
                        defaultCountry='US'
                        international={true}
                        placeholder="Enter phone number"
                        value={value}
                        onChange={setValue}
                        readOnly={isReadOnly}
                    />
                }

                {
                    changeBtnBox &&  
                    <ChangeBtn onClick={handleUsername}>
                        change
                    </ChangeBtn>
                }
            </ProfileInputWrap>
        </TextInputOverall>
    </>
  )
}

export default ProfileTextInputComponent