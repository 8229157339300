import styled from 'styled-components'

 export const PickDriverWrap = styled.div `
    margin-top: 40px ;
    position: relative;
    height: 300px;

    & .overall-wrap {
        display: flex;
        padding-top: 25px;
        width: 120%;
        overflow-x: scroll;
        gap: 20px;
        align-items: center;
        position: absolute;
    }

    .special-request-box {
      h3 {
         color: #EBAF4A;
      }

      .call-special-request {
         button {
            background-color: #ffa100 !important;
            color: #000 !important;
            font-weight: 700;
            border-radius: 8px;
            padding: 15px;
            display: flex;
            justify-content: center;
            outline: none !important;
            border: none !important;
         }
      }
    }
 `

 export const PickDriverText = styled.div `
    margin-bottom: 20px;
    padding: 0 15px;
    color: #EBAF4A;
    font-size: 16px;
    font-weight: 700;
 `