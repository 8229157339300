import styled from "styled-components";

export const BackgroundBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.98);
  transition: all 0.5s ease 0s;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 601;
`;
export const ModalBox = styled.div`
  background: #fff;
  transition: all 0.5s ease 0s;
  display: flex;
  flex-direction: column;
  padding: 30px 10px;
  border-radius: 10px;
  position: absolute;

  width: 100%;
  .button-container button {
    background: #000;
    padding: 7px 10px;
    color: #fff;
    font-weight: bold;
    margin-top: 20px;
    outline: none;
    border-radius:7px;
    border:none;
  }
  .ticket-container {
    display: flex;
    box-shadow: 4px 4px 15px 2px #00000024;
    .left {
      padding: 5px;
      display: flex;
      flex: 0.6;
      align-items: center;
      background-image: url("/map.png");
      background-position: center;
      background-size: 220px;
      background-color: #ffa100;
      background-repeat: no-repeat;
      .logo{
        h5{
          color:black;
          margin:0;
        }
        h4{
          color:white;
          margin:0;
        }
      }
    }
    .right {
      flex: 0.45;
      background: #fff;
      display: flex;
      flex-direction: column;
      padding: 10px;
    }
    .right h5,
    .right p {
      margin: 0;
    }
    .right .travel-details,
    .right .passenger-details {
      display: flex;
      justify-content: space-between;
    }
    .travel-details h5 {
      margin: 0;
    }
    .left .travel {
      display: flex;
    }
    .travel .heading h1 {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
    }
    .right h5 {
      color: #ffa100;
      font-size: 10px;
    }
    .right p {
      color: #000;
      font-size: 9px;
    }
    .right .pass,
    .right .passenger-details {
      border-bottom: 1px solid #aaa;
      margin: 3px 0px;
      padding: 2px 0px;
    }
    .right .travel-details {
      margin: 3px 0px;
    }
    .passenger-details .phone h5 {
      font-size: 9px;
    }
  }
`;
