import React, { useState } from 'react'
import { CartBox, CartSections, SectionsHeader, CartLocationWrap, CartImageWrap, BookingDayWrap, NumberControlOverall, BookingDetailsWrap, BookingStateInfo, PriceBox, CartFooter, RemoveBtn } from '../cart/cart.styles'
import { InputIcons } from '../locationModal/locationmodal.styles'
import PhoneInput from 'react-phone-number-input'

import location from '../../assets/images/svg/location-black.svg'
import car from '../../assets/images/png/cart-car.png'
import minus from '../../assets/images/svg/minus.svg'
import mediumSize from '../../assets/images/png/medium-parcel.png'
import smallSize from '../../assets/images/png/small-parcel.png'
import profile from '../../assets/images/svg/person-icon.svg'
import plus from '../../assets/images/svg/plus.svg'
import clock from '../../assets/images/svg/clock-icon.svg'
import calendar from '../../assets/images/svg/calendar-icon.svg'
import delivery from '../../assets/images/svg/delivery-bag.svg'
import { BoxExtras, AssetOverall } from '../../pages/user/Homepage/homepage.styles'
import AssetCard from '../assetCard'
import OffersCard from '../offersCard'
import { H11 } from '../travelGarageBox/garagebox-styles'
import { BookingDetailsSubWrap, BookingSubDetailsOverall, CartItemBtn, CartItemBtnList, EditBookingText, LocationDetailsWrap, MarginTop, NumberDetailsWrap, SubDetailsOverallWrap } from './deliveryCart.styles'
import { ItemsBtnList, ItemBtn } from '../deliveryLocationModal/deliverylocation.styles'


type Props = {
    onChange: ((value: string) => void) | null;
  };

const DeliveryCart = ({onChange, booking_title, pickup_date, pickup_time, pickup_location, image, trnasport_options, category, amount, no_of_hours, state_location, plate_number, vehicle_id, provider_id, vehicle_year, handleDelete, deliveryArray, no_of_items, handleEditBooking}:any) => {

    const imgLink = `https://testapi.roadlers.com/storage/`
    const [itemActive, setItemActive] = useState(0)

    const handleShowItem = (id:any) => {
        setItemActive(id)
    }
  return (
    <CartBox>
        <div className="cart-inner-wrap delivery-inner">
            <CartSections>
                <SectionsHeader>
                    <div className="flex-wrap">
                    <img src={delivery} />
                    <h3>Delivery</h3>
                    </div>
                    <hr />
                </SectionsHeader>

                <CartLocationWrap>
                    <InputIcons src={location} iconWidth={'20px'} />
                    <input type="text" value={pickup_location} readOnly />
                </CartLocationWrap>
                
                <CartImageWrap>
                    {
                        booking_title?.includes('Single') ?
                            <img src={smallSize} />:
                            <img src={mediumSize} />
                    }
                </CartImageWrap>
                <CartItemBtnList>
                    {
                        deliveryArray?.map((item:any, id:any) => {
                            return (
                            <CartItemBtn 
                                active={ id === itemActive ? true : false }
                                key={id}
                                onClick={() => handleShowItem(id)}
                            >
                                Item {id + 1}
                            </CartItemBtn>
                            )
                        })
                    }
                </CartItemBtnList>    
            </CartSections>

            <CartSections>
                <SectionsHeader>
                    <h3>Booking Details</h3>
                    <hr />
                </SectionsHeader>  

                <MarginTop />

                {
                    deliveryArray?.map((item:any, id:any) => {
                        return (
                            <BookingSubDetailsOverall key={id} display={id === itemActive ? true : false}>
                                <BookingDetailsSubWrap>
                                    <img src={profile} />
                                    <p>{item?.receiver_name}</p>
                                </BookingDetailsSubWrap>
            
                                <LocationDetailsWrap>
                                    <div className="location-wrap">
                                        <img src={location} />
                                    </div>
                                    <p>{item?.destination}</p>
                                </LocationDetailsWrap>
            
                                <NumberDetailsWrap>
                                    <PhoneInput 
                                        defaultCountry='US'
                                        international={true}
                                        value={item?.receiver_phone}
                                        onChange={onChange}
                                        disabled={true}
                                    />
                                    {/* <p>{item?.receiver_phone}</p> */}
                                </NumberDetailsWrap>
                            </BookingSubDetailsOverall>
                        )
                    })
                }


                <BookingDetailsSubWrap>
                    <div className="calendar-wrap">
                        <img src={calendar} />
                    </div>
                    <p>{pickup_date}</p>
                </BookingDetailsSubWrap>

                <BookingDetailsSubWrap>
                    <img src={clock} />
                    <p>{pickup_time}</p>
                </BookingDetailsSubWrap>
            </CartSections>

            <CartSections>
                <SectionsHeader>
                    <h3>Price Per Item</h3>
                    <hr />
                </SectionsHeader>

                <PriceBox>
                    <p>₦{`${(amount)?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}</p>
                </PriceBox>
            </CartSections>
        </div>

        <CartFooter>
            <div className="cart-footer-inner">
                <div className="btns-flex">
                    {/* <EditBookingText onClick={() => handleEditBooking()}>
                        Edit
                    </EditBookingText> */}

                    <RemoveBtn onClick={() => handleDelete()}>
                        Remove
                    </RemoveBtn>
                </div>

                <h3>Sub-total</h3>

                <h3>₦{`${(amount)?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}</h3>
            </div>
        </CartFooter>
    </CartBox>
  )
}

export default DeliveryCart