import styled from 'styled-components'



export const InputDiv = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:7px;
gap:5px;
justify-content:space-between;
padding:8px 10px;
`

export const BlackDiv = styled.div`
display:flex;
align-items:center;
background:#000;
width:40%;
justify-content:space-around;
padding:8px 4px;
border-radius:5px;
position:relative;

`

export const SelectButton = styled.button`
background: #FFD58C;
color: #000;
padding:8px 4px;
outline:none;
border:none;
border-radius:5px;
font-size:1rem;
font-weight:bold;
margin-left:5px;
position:relative;
`
export const Add = styled.button`
background: #FFD58C;
color: #000;
padding:2px 2px;
outline:none;
border:none;
border-radius:5px;
font-size:1rem;
font-weight:bold;
height:20px;
width:20px;
display:flex;
align-items:center;
justify-content:center;
`
export const AddSpan = styled.span`
background: #09051C;
display:flex;
align-items:center;
gap:10px;
padding:5px 7px;
`
export const Text = styled.span`
color:#fff;
font-size:12px;
`


export const Discount = styled.span`
display:flex;
align-items:center;
justify-content:center;
background: #868686;
color:#fff;
padding:0.3em;
border-radius:5px;
font-size:11px;
position:relative;

`


export const FlexDiv = styled.div`
display:flex;
align-items:center;
gap:10px;
width:100%;
margin-top:50px;


`
export const LabelDiv = styled.div`
display:flex;
gap:10px;
align-items:center;
margin-top:70px;
`
export const P = styled.p`
color:#fff;
font-size:9px;
position:absolute;
font-weight:800;

`

export const Label = styled.label`
background: #3573C0;
font-weight:bold;
font-size:12px;
margin-left:15px;
padding:5px 10px;
border-radius:5px;
top:55px;


`
export const InputField = styled.input`
color: #868686;
font-size: 13px;
border:none;
  width:45%;
`
