import styled from 'styled-components'

export const FlexDiv = styled.div`
 display:flex;
width:100%;
margin-top:50px;
align-items:center;
justify-content:space-between;
margin-bottom:10px;
`

export const DetailsDiv = styled.div`
 display:flex;
width:100%;
margin-top:30px;
`
export const Flex = styled.div`
background: #FF2B2B;
color:#fff;
padding:5px;
border-radius:5px;
display:flex;
align-items:center;
gap:3px;
`

export const Div = styled.div`
width:32%;
`
export const ImageDiv = styled.div`
width:100%;
overflow-x:hidden;
border-radius:12px;
background:#000;
outline:none;
position:relative;

`
export const ImageDetails = styled.div`
width:100%;
border-radius:0;
padding:.6em;
`

export const H5 = styled.h5`
color:#fff;
margin:0;
font-weight:900;
font-size:11px;
`
export const H6 = styled.h6`
color:#ffa100;
margin:0 0 5px 0;
font-weight:700;
font-size:10.5px;
position:relative;
`

export const Label = styled.label`
font-size:9.5px;
margin-bottom:0px;
color: #868686;

`

export const Span = styled.span`
color:#fff;
font-size:10px;
`
export const LocationSpan = styled.div`
display:flex;
align-items:center;
font-size:10px;
margin:5px -2px;
`

export const P = styled.p`
color:#fff;
font-weight:600;
font-size:10px;
margin:0px 0px;

`


