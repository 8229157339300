import styled from 'styled-components'

export const ModalOverlay = styled.div `
        position: fixed;
        z-index: ${(props: any) => props.activeModal === true ? '400' : '-300'};
        width: 100%;
        height: 100vh;
        background-color: rgba(255, 255, 255, 0.9);
        top: 0;
        left: 0;
        opacity: ${(props: any) => props.activeModal === true ? '1' : '0'};
        transition: all 0.5s ease;

        & .modal-inner-wrapper{
            display: flex;
            width: 100%;
            height: 100vh;
            align-items: center;
            justify-content: center;
        }
`

export const CancelModalBox = styled.div `
    position: absolute;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: 450;
`

export const ModalBox = styled.div `
    width: 400px;
    background-color: #000;
    border-radius: 15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 500;

    @media only screen and (max-width: 454px)  {
        width: 330px;
    }

    & .inner-modal-box {
        padding: 35px;

        h4 {
            font-size: 16px;
            font-weight: 800;
            color: #ffa100;
        }

        @media only screen and (max-width: 454px) {
            padding: 30px;
        }
    }
`

export const OptionPill = styled.div `
    background-color: #fff;
    margin-bottom: 30px;
    border-radius: 10px;
    padding: 15px 30px ;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 10px;

    & p {
        color: #000;
        font-size: 16px;
        margin: 0;
    }

    @media only screen and (max-width: 484px) {
        padding:10px 20px;

        p {
            font-size: 14px;
        }
    }
`

export const PillRadioBtn = styled.div `
    border: solid 4px #000;
    background-color: ${(props: any) => props.activePill?.length !== 0 ? '#ffa100' : '#ffffff'};
    width: 15px;
    height: 15px;
    border-radius: 50%;

    @media only screen and (max-width: 454px) {
        width: 10px;
        height: 10px;
        border: solid 2px #000;
    }
`

