import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    showDeliveryLocation: false
  };
  const showDeliveryLocationSlice = createSlice({
    name: 'showDeliveryLocation',
    initialState,
    reducers: {
      setShowDeliveryLocation: (state, action) => {
        return { ...state, showDeliveryLocation: action.payload };
      },
      clearShowDeliveryLocation: () => {
        return { showDeliveryLocation: false };
      }
    }
  });
  
  const { reducer, actions } = showDeliveryLocationSlice;
  export const { setShowDeliveryLocation, clearShowDeliveryLocation} = actions;
  export default reducer;