import styled from 'styled-components'



export const Input = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:12px;
overflow:hidden;
margin-top:50px;
justify-content:space-around;
padding:13px 5px;
@media only screen and (max-width: 380px) {
    padding:8px 6px;
    }
`
export const VerifyButton = styled.button`
background: #FFD58C;
color: #000;
padding:8px 12px;
outline:none;
border:none;
border-radius:5px;
font-size:1rem;
font-weight:bold;
margin-left:5px;
margin-right:10px;
display:flex;
align-items:center;
justify-content:space-around;
gap:5px;
`
export const DIV = styled.span`
display:flex;
align-items:center;
gap:20px;


`

export const INPUTS = styled.input`
width:50%;
border-radius:13px;
padding:21px;
font-weight:medium;
::placeholder, ::-webkit-input-placeholder {
    font-size: 16px;
    color:#242321;
    font-weight:medium;
}
@media only screen and (max-width: 380px) {
    padding:17px;
    }
    ::placeholder, ::-webkit-input-placeholder {
        font-size: 14px;
    }
`

export const Text = styled.div`
font-size:16px;
margin-top:3px;
@media only screen and (max-width: 380px) {
    font-size:13px;

    }
`
export const DropDown = styled.div`
background: #000;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
padding:1px;
gap:3px;

`

export const Div = styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
margin-bottom:-40px;
position:absolute;
right:25px;
width:300px;
`
export const Modal = styled.div`
background: #000;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
padding:20px;
gap:10px;
border-radius:6px;
width:60%;

`
export const Option = styled.div`
background: #fff;
display:flex;
align-items:center;
justify-content:flex-start;
padding:10px;
gap:10px;
width:100%;
color:#000;
border-radius:6px;
font-weight:600;
`
export const Label = styled.label`

width:100%;


`

