import {createSlice} from '@reduxjs/toolkit';



const initialState = {
  auth: <any>{},
  user_role: 'customer',
};


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      return {...state, auth: action.payload};
    },
    setRole: (state, action) => {
      return {...state, user_role: action.payload};
    },
    clearAuth: (state, action) => {
      return {...state, auth: {}};
    },
  },
});

const {reducer, actions} = authSlice;
export const {
  setAuth,
  setRole,
  clearAuth,
} = actions;
export default reducer;

