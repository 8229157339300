import React, {useState} from 'react'
import CheckoutCardChoice from '../checkoutCardChoice'
import CheckoutFundWalletTwo from '../checkoutFundWalletTwo'
import CheckoutInitialPay from '../checkoutInitialPayChoice'
import CheckoutPayComponent from '../checkoutPayComponent'
import CheckoutPayErrorComponent from '../checkoutPayErrorComponent'
import CreateWalletCard from '../createWalletCard'
import FailedWalletComponent from '../failedWalletComponent'
import FundWalletBank from '../fundWalletBank'
import FundWalletThree from '../fundWalletThree'
import { CancelModalBox, ModalOverlay } from '../optionModal/optionmodal.styles'
import OtpWalletComponent from '../otpWalletComponent'
import PaymentMethodComponent from '../paymentMethodComponent'
import SuccessWalletComponent from '../successWalletComponent'
import { WalletContentWrap, WalletModalBox, WalletModalHeader } from '../walletModal/walletmodal.styles'
import { CheckoutModalBox } from './checkoutcomponent.styles'

const CheckoutComponent = ({showCheckout, handleCheckout, amountTotal, handlePayWithCard, payWithCardError, handleAddBooking, timedAddBookingError, addBookingError, walletTotal, currency, handlePayWithWallet, subComponent, setSubComponent, onWalletTopup, topupAmount, setTopupAmount, accountNumber, bankName, topupErrorMessage, createBvnShow, setCreateBvnShow, creatWalletActive, setCreateWalletModal, setBvn, handleCreateVirtualWallet, bvnError, isLoading, walletPayIsLoading, cardPayIsLoading} :any) => {

    const handleOverallCheckout = () => {
        setSubComponent('a')
        handleCheckout(false)
    }

    const handleCheckoutPayChoice = (choice:any) => {
        if(choice?.includes('wallet')) {
            if(!accountNumber) {
                setCreateBvnShow(true)
            }else {
                setSubComponent('walletPath')
                setCreateBvnShow(false)
            }
        }else {
            setSubComponent('cardPath')
        }
    }

    const handleCheckoutPay = () => {
        if(amountTotal > walletTotal) {
            setSubComponent('walletError')
        }else {
            handleAddBooking('wallet')
        } 
    }
    const handleCardOne = () => {
        setSubComponent('fundwallet1')
    }

    const handleCardThree = () => {
        setSubComponent('fundwallet3')
    }

    const handleFundWalletPay = () => {
        setSubComponent('walletSuccess')
    }

    const handleBankFund = () => {
        setSubComponent('bankfund')
    }

    if(createBvnShow) {
        return <CreateWalletCard setCreateWalletModal={setCreateBvnShow} creatWalletActive={true} setBvn={setBvn}  handleCreateVirtualWallet={handleCreateVirtualWallet} isLoading={isLoading} comingFrom={"cart"} bvnError={bvnError}/>
    }

  return (
    <ModalOverlay activeModal={showCheckout}>
        <CancelModalBox onClick={handleOverallCheckout} />
        <CheckoutModalBox>
            <div className="inner-modal-box">
                <WalletModalHeader>
                    <div className=""></div>
                </WalletModalHeader>
                    
                <WalletContentWrap>
                    <CheckoutInitialPay showBox={subComponent === 'a' ? 'block' : 'none'} onSelect={handleCheckoutPayChoice} />
                    
                    <CheckoutCardChoice showBox={subComponent === 'cardPath' ? 'block' : 'none'} setSubComponent={setSubComponent} amountTotal={amountTotal} handlePayWithCard={handlePayWithCard} payWithCardError={payWithCardError} timedAddBookingError={timedAddBookingError} addBookingError={addBookingError} handleAddBooking={handleAddBooking} cardPayIsLoading={cardPayIsLoading}/>
                    
                    <CheckoutPayComponent showBox={subComponent === 'walletPath' ? 'block' : 'none'} onPay={handleCheckoutPay} addBookingError={addBookingError} setSubComponent={setSubComponent} currency={currency} walletTotal={walletTotal} handleAddBooking={handleAddBooking} walletPayIsLoading={walletPayIsLoading} />
                    
                    <CheckoutPayErrorComponent showBox={subComponent === 'walletError' ? 'block' : 'none'} onBankPay={handleBankFund} onCardPay={handleCardOne} />
                    
                    <CheckoutFundWalletTwo showBox={subComponent === 'fundwallet1' ? 'block' : 'none'} onWalletTopup={onWalletTopup} topupAmount={topupAmount} setTopupAmount={setTopupAmount} setSubComponent={setSubComponent} topupErrorMessage={topupErrorMessage}/>
                    
                    <FundWalletThree displaybox={subComponent === 'fundwallet3' ? 'block' : 'none'} onPay={handleFundWalletPay}/>
                    
                    <OtpWalletComponent displaybox={subComponent === 'otp' ? 'block' : 'none'} />
                    
                    <SuccessWalletComponent displaybox={subComponent === 'walletSuccess' ? 'block' : 'none'} setSubComponent={setSubComponent}/>
                    
                    <FailedWalletComponent displaybox={subComponent === 'walletFailed' ? 'block' : 'none'} />
                    
                    <FundWalletBank  displaybox={subComponent === 'bankfund' ? 'block' : 'none'} setSubComponent={setSubComponent} handleOverallCheckout={handleOverallCheckout} accountNumber={accountNumber} bankName={bankName}/>
                </WalletContentWrap>
            </div>
        </CheckoutModalBox>
    </ModalOverlay>
  )
}

export default CheckoutComponent