import React, { useEffect, useState } from 'react'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import ProgressBar from '../../../components/progressBar'
import { BookingExtrasFlexWrap, BookingFlexWrap, BookingFooterBtn, BookingFooterWrap, BookingOverall, BookingQuestionsWrap, CarouselSection, FeatureWrapper, HoursControlBox, ModalCallBtn, ModalCallBtnWrap, PP, QuestImg, QuestionBox, QuestionHeader, QuestionImg, SearchInput, SearchOverall, SetHoursBox, SubFeatureWrap, TickBox, WhereImg } from '../CarBooking/CarBooking.styles'
import { AssetOverall, BoxExtras, H11, SearchBtn } from '../Homepage/homepage.styles'
import OwnersNoteCard from '../../../components/ownersNoteCard'
import RoadlersCard from '../../../components/roadlersCard'
import AssetCard from '../../../components/assetCard'
import OffersCard from '../../../components/offersCard'
import Carousel from '../../../components/carousel'
import DriverOptionModal from '../../../components/driverOptionModal'
import TimeDateModal from '../../../components/timeDateModal'
import ExtrasModal from '../../../components/extrasModal'
import LocationModal from '../../../components/locationModal'
import { AppDispatch, useAppDispatch } from '../../../store'
import { setShowDriverOption } from '../../../slices/showDriverOption'
import { setShowLocation } from '../../../slices/showLocation'
import { setShowExtraNote } from '../../../slices/showExtraNote'
import { setShowTimeDate } from '../../../slices/showTimeDate'
import { Navigate, NavLink,  useNavigate } from 'react-router-dom'
import PickDriver from '../../../components/pickDriverCard'
import { PickDriverText, PickDriverWrap } from './DriverBooking.styles'
import SearchComponent from '../../../components/searchComponent'


import arrowDown from '../../../assets/images/svg/arrowDown.svg'
import whereTag from '../../../assets/images/svg/where-tag.svg'
import whenTag from '../../../assets/images/svg/when-tag.svg'
import noteTag from '../../../assets/images/svg/note-tag.svg'
import tickX from '../../../assets/images/svg/tickx-icon.svg'
import tickR from '../../../assets/images/svg/tickr-icon.svg'
import driver from "../../../assets/images/png/bookdriver.png"
import drivingService from "../../../assets/images/png/driving-service.png"
import addArrow from '../../../assets/images/svg/addArrow.svg'
import subtractArrow from '../../../assets/images/svg/subtractArrow.svg'
import DriverLocationModal from '../../../components/driverLocationModal'
import { useGetDriverListQuery, useSpecialRequestMutation } from '../../../services/customerApi'
import uuid from 'react-uuid'
import { addToCart } from '../../../slices/cart'
import SearchUpdateComponent from '../../../components/searchUpdateComponent'
import { PaddingWrap } from '../CartPage/cartPage.styles'
import DriverVehicleTypeModal from '../../../components/driverVehicleTypeModal'
import ReactWhatsapp from 'react-whatsapp'

const DriverBooking = () => {
    const dispatch: AppDispatch = useAppDispatch()
    const navigate = useNavigate();
    const imgLink = `https://testapi.roadlers.com/storage/`
    const imgLink2 = 'https://testapi.roadlers.com/storage/'
    const {data, isFetching, refetch, error} = useGetDriverListQuery()
    const slides:any = [
        {img: drivingService, name: 'Main Image', id: 0},
    ]
    const [bookedDate, setBookedDate] = useState([])
    const [pickupTime, setPickupTime] = useState('')
    const [whenModalBtn, setWhenModalBtn] = useState(false)
    const [whereModalBtn, setWhereModalBtn] = useState(false)
    const [progressbarNum, setProgressBarNum] = useState(1)
    const [carOwnerInstruction, setCarOwnerInstruction] = useState('')
    const [escortDays, setEscortDays] = useState('')
    const [escortNumber, setEscortNumber] = useState('')
    const [progressInfo, setProgressInfo] = useState('Start Booking - Let’s Move')
    const [origin, setOrigin] = useState('')
    const [destination, setDestination] = useState('')
    const [choiceDriver, setChoiceDriver] = useState<any>([])
    const [driverService, setDriverService] = useState('')
    const [driverServiceName, setDriverServiceName] = useState('')
    const [choiceDriverPrice, setChoiceDriverPrice] = useState('')
    const [driverActive, setDriverActive] = useState(false)
    const [days, setDays] = useState(1)
    const [weeks, setWeeks] = useState(1)
    const [hours, setHours] = useState(1)
    const [month, setMonth] = useState(1)
    const [driverArray, setDriverArray] = useState<any []>([])
    const [filteredDriverArray, setFilteredDriverArray] = useState<any []>([])
    const [pickupLatLong, setPickupLatLong] = useState<any>()
    const [nearbyDriverArr, setNearbyDriverArr] = useState<any []>([])
    const [travelState, setTravelState] = useState<any>('')
    const [price, setPrice] = useState<any>(0)
    const [transportType, setTransportType] = useState('Within state')
    const [bookingAmount, setBookingAmount] = useState(0)
    const [driverName, setDriverName] = useState('')
    const [insuranceAmount, setInsuranceAmount] = useState<number>(0)
    const [insuranceType, setInsuranceType] = useState('')
    const [dateLength, setDateLength] = useState(0)
    const [driverVehicleService, setDriverVehicleService] = useState("")
    const [showDriverVehicleModal, setShowDriverVehicleModal] = useState(false)


    // set driver list Array
    useEffect(() => {
      refetch()
      setDriverArray(data?.data)
    }, [data, driverArray])


    const [specialRequest, {data: specialRequestData}] = useSpecialRequestMutation()


    // FUNCTIONS FOR SHOWING EACH MODAL
    const driverOptionModalControl = () => {
      dispatch(setShowDriverOption(true))
    }

    const locationModalControl = () => {
      dispatch(setShowLocation(true))
    } 

    const timeDateModalControl = () => {
      dispatch(setShowTimeDate(true))
    }
  
    const extraModalControl = () => {
      dispatch(setShowExtraNote(true))
    }

    //**** */ COMMON FUNCTION FOR BOOKING -OPEN

    const handleLocationComplete= (itemComplete:any) => {
      setWhereModalBtn(itemComplete)
    }
  
    const handleTimeComplete = () => {
      setWhenModalBtn(true)
    }


    useEffect(() => {
      if(bookedDate?.length !== 0 && pickupTime?.length !== 0) {
          handleTimeComplete()
      }
    }, [bookedDate, pickupTime])
  
    useEffect(() => {
      if(whereModalBtn === true) {
        setProgressBarNum(progressbarNum + 1)
      }else {
        setProgressBarNum(progressbarNum)
      }
    }, [whereModalBtn])
  
    useEffect(() => {
      if(whenModalBtn === true) {
        setProgressBarNum(progressbarNum + 1)
      }else {
        setProgressBarNum(progressbarNum)
      }
    }, [whenModalBtn])
  
    useEffect(() => {
      if(progressbarNum === 2) {
        setProgressInfo('Almost there - Keep Moving')
      }else if(progressbarNum === 3) {
        setProgressInfo('Completed - Proceed to Move with Ease')
      }
    }, [progressbarNum])
    //*****/ COMMON FUNCTION FOR BOOKING- CLOSE //

    // FILTER THROUGH TO GET OBJECTS LESS THAN 100KM FROM THE ADDRESS
    function getNearbyObjects(objects:any, address:any) {
      const R = 6371; // Radius of the earth in km
      const { latitude: lat1, longitude: lon1 } = address;
      return objects?.filter((obj:any) => {
        const { latitude: lat2, longitude: lon2 } = obj;
        const dLat = toRad(lat2 - lat1);
        const dLon = toRad(lon2 - lon1);
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos(toRad(lat1)) *
            Math.cos(toRad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c; // Distance in km
        return distance < 100; // Filter objects less than 100 km from address
      });
    }
    
    function toRad(value:any) {
      return (value * Math.PI) / 180; // Convert degrees to radians
    }
    
    useEffect(() => {
      const objects = driverArray;
      const address = { latitude: Number(pickupLatLong?.lat), longitude: Number(pickupLatLong?.lng) };
      const nearbyObjects = getNearbyObjects(objects, address);
      setNearbyDriverArr(nearbyObjects)
    }, [pickupLatLong])



    // FILTER DRIVER TO BE SHOWN
    useEffect(() => {
      let filtered = nearbyDriverArr ? nearbyDriverArr : driverArray;

      if (pickupLatLong) {
        filtered = nearbyDriverArr
      }

      if(driverVehicleService) {
        filtered = filtered?.filter((item:any) => item?.availability?.includes(driverVehicleService))
      }


      if(travelState) {
        filtered = filtered?.filter((item:any) => item?.travel_price?.some((item:any) => item?.destination?.toLowerCase()?.includes(travelState?.toLowerCase())))
      }

      if(pickupLatLong && driverServiceName && bookedDate ) {
       filtered = filtered?.filter((item:any) => item?.service_price?.some((item:any) => item?.service_title?.includes(driverServiceName)))
      }

      if(pickupLatLong && driverServiceName && bookedDate ) {
        setFilteredDriverArray(filtered)
      }
    }, [driverService, travelState, pickupLatLong, pickupTime, bookedDate, driverVehicleService, destination, nearbyDriverArr])


    // USEFFECT FUNCTION TO DETERMINE THE PRICE BASED ON THE DRIVER SERVICE PICKED
    useEffect(() => {
      if(driverService?.includes('Daily driver')) {
        setBookingAmount(price * days)
      }else if(driverService?.includes('Week')) {
        setBookingAmount(price * weeks)
      }else if(driverService?.includes('Hour')) {
        setBookingAmount(price * hours * bookedDate?.length)
      }else if(driverService?.includes('Month')) {
        setBookingAmount(price * month)
      }else {
        setBookingAmount(price * bookedDate?.length)
      }

    }, [driverService, price, days, hours, month, weeks, bookedDate])

    const handlePickDriver = (item:any) => {
        setChoiceDriver(item)
    }

    const handleIncreaseDays = () => {
      setDays(days + 1)
    }
  
    const handleDecreaseDays = () => {
      if(days > 1 ) {
        return setDays(days - 1)
      }
    }

    const handleIncreaseWeeks = () => {
      setWeeks(weeks + 1)
    }
  
    const handleDecreaseWeeks = () => {
      if(weeks > 1 ) {
        return setWeeks(weeks - 1)
      }
    }

    const handleIncreaseHours = () => {
      setHours(hours + 1)
    }
  
    const handleDecreaseHours = () => {
      if(hours > 1 ) {
        return setHours(hours - 1)
      }
    }

    const handleIncreaseMonth = () => {
      setMonth(month + 1)
    }
  
    const handleDecreaseMonth = () => {
      if(month > 1 ) {
        return setMonth(month - 1)
      }
    }

    const handleDriverVehicleChosen = (item:any) => {
      setDriverVehicleService(item)
      driverOptionModalControl()
    }

    const handleSpecialRequest = () => {
      specialRequest()
      navigate("/")
    }

    useEffect(() => {
      if(bookedDate?.length > 0) {
        if(driverServiceName.includes('Full day')) {
          setDays(bookedDate?.length)
        }
      }
    }, [bookedDate, driverServiceName, days])

    // PROCEED TO BOOKING BUTTON
    const handleProceedBooking = (e:any) => {
      e.preventDefault()

      if(progressbarNum === 3) {
        const bookingDetails:any = {
          'pickup_date': bookedDate,
          'pickup_time': pickupTime,
          'pickup_location': origin,
          'destination': destination,
          'escort_personel': escortNumber,
          'escort_days': escortDays,
          'passenger_instruction': carOwnerInstruction,
          'image': driver,
          'transport_options': driverService,
          'category': 'Driver Booking',
          'amount': bookingAmount + insuranceAmount,
          'insurance': insuranceType,
          'state_location': transportType,
          'driver_id': choiceDriver?.driver_uniqid,
          // 'user_id': '222',
          'provider_id': choiceDriver?.provider_id,
          'booking_title': driverService,
          'booking_id': uuid(),
          'total': bookingAmount + insuranceAmount,
          'num_of_day': bookedDate?.length,
          'insurance_amount': insuranceAmount,
          'vehicle_sercive': driverVehicleService,
        }


        dispatch(addToCart(bookingDetails))
        navigate('/cart')
      }
    }


  return (
    <>
        <DriverVehicleTypeModal handleService={handleDriverVehicleChosen} serviceName={driverVehicleService} isModalOpen={showDriverVehicleModal} showModal={setShowDriverVehicleModal} />

        <DriverOptionModal handleServiceName={setDriverServiceName} handleService={setDriverService} driverService={driverService} setPrice={setPrice} setChoiceDriver={setChoiceDriver}/>

        <DriverLocationModal setLocationBtn={handleLocationComplete} setOrigin={setOrigin} origin={origin} handleDestination={setDestination} destination={destination} setPickupLatLong={setPickupLatLong} setTravelState={setTravelState}
        setTransportType={setTransportType} />

        <TimeDateModal handleBookedDate={setBookedDate} handlePickupTime={setPickupTime} dateLength={dateLength} setDays={setDays} bookedDate={bookedDate}/>

        <ExtrasModal  handleInstruction={setCarOwnerInstruction} instruction={carOwnerInstruction} setEscortNum={setEscortNumber} setEscortDay={setEscortDays} insuranceAmount={insuranceAmount} setInsuranceAmount={setInsuranceAmount} insuranceType={insuranceType} setInsuranceType={setInsuranceType} insuranceLabel={'Would you like to be covered by insurance on this trip?'} />
        <Header />
        
        <PaddingWrap>
          <SearchUpdateComponent />
        </PaddingWrap>

        <BookingFlexWrap>
          <CarouselSection bg={'#fff'} className="driver-carousel">
            <Carousel 
              slides={slides} 
              display={'none'} 
              priceDisplay={'none'}
              title={'Driving Service'}
            />
          </CarouselSection>

          <BookingOverall>
            <div className="booking-suboverall" style={{padding: '30px', paddingRight: '80px'}}>
              <ProgressBar 
                progressText={progressInfo}
                barDisplay={'flex'}
                progressNum={progressbarNum}
              />

              <BookingQuestionsWrap>
                <QuestionHeader>
                    What kind of driver would you like?
                </QuestionHeader>
                <QuestionBox >

                  <PP>{driverVehicleService || 'Select option' }</PP>

                  <div className="flex-wrap">
                    {
                      driverService === 'Daily driver' &&
                      <SetHoursBox>
                        <h2>{days}</h2>
                        <p>Days</p>

                        <HoursControlBox>
                          <img src={addArrow} onClick={handleIncreaseDays} />
                          <img src={subtractArrow} onClick={handleDecreaseDays} />
                        </HoursControlBox>
                      </SetHoursBox>
                    }

                    {
                      driverService?.includes('Week') &&
                      <SetHoursBox>
                        <h2>{weeks}</h2>
                        <p>Weeks</p>

                        <HoursControlBox>
                          <img src={addArrow} onClick={handleIncreaseWeeks} />
                          <img src={subtractArrow} onClick={handleDecreaseWeeks} />
                        </HoursControlBox>
                      </SetHoursBox>
                    }

                    {
                      driverService?.includes('Hour') &&
                      <SetHoursBox>
                        <h2>{hours}</h2>
                        <p>{hours > 1 ? 'Hours' : 'Hour'}</p>

                        <HoursControlBox>
                          <img src={addArrow} onClick={handleIncreaseHours} />
                          <img src={subtractArrow} onClick={handleDecreaseHours} />
                        </HoursControlBox>
                      </SetHoursBox>
                    }

                    {    
                      driverService?.includes('Month') &&
                      <SetHoursBox>
                        <h2>{month}</h2>
                        <p>{month > 1 ? 'Months' : 'Month'}</p>

                        <HoursControlBox>
                          <img src={addArrow} onClick={handleIncreaseMonth} />
                          <img src={subtractArrow} onClick={handleDecreaseMonth} />
                        </HoursControlBox>
                      </SetHoursBox>
                    }

                    <QuestionImg src={arrowDown} className={"arrow-drop"}  width={'50px'} onClick={() => setShowDriverVehicleModal(true)}/>
                  </div>
                </QuestionBox>

                <ModalCallBtnWrap>
                  <ModalCallBtn complete={whereModalBtn} onClick={() => locationModalControl()}>
                    <WhereImg src={whereTag} width={'30px'} />

                    <p>Where?</p>
                  </ModalCallBtn>

                  <ModalCallBtn complete={whenModalBtn} onClick={() => timeDateModalControl()}>
                    <QuestImg complete={whenModalBtn} src={whenTag} width={'30px'} />

                    <p>When?</p>
                  </ModalCallBtn>

                  <ModalCallBtn  complete={carOwnerInstruction || insuranceAmount ? true : false}  onClick={() => extraModalControl()}>
                    <QuestImg className="note-img" complete={carOwnerInstruction || insuranceAmount ? true : false} src={noteTag} width={'25px'} />

                    <p>Extra/Note</p>
                  </ModalCallBtn>
                </ModalCallBtnWrap>
              </BookingQuestionsWrap>

              {
                (pickupLatLong && driverServiceName && bookedDate?.length > 0) &&
                <PickDriverWrap>
                  {
                    filteredDriverArray?.length > 0 && 
                    <PickDriverText>
                      Select a driver
                    </PickDriverText>
                  }

                  {
                    filteredDriverArray?.length > 0 ?

                    <div className="overall-wrap">
                      {
                        filteredDriverArray?.map((item:any, id:any) => {
                          return (
                            <div key={id} onClick={() => handlePickDriver(item)}>
                              <PickDriver 
                                price={item?.price}
                                driverId={item?.driver_uniqid}
                                name={item?.name || item?.firstname}
                                handleDriver={handlePickDriver}
                                handleDriverPrice={setPrice}
                                latitude={item?.latitude}
                                longitude={item?.longitude}
                                pickupLat={pickupLatLong?.lat}
                                pickupLong={pickupLatLong?.lng}
                                servicePrice={item?.service_price}
                                travelPrice={item?.travel_trip}
                                driverServiceName={driverServiceName}
                                setDriverName={setDriverName}
                                driverPicture={item?.driver_picture
                                }
                                imgLink={imgLink2}
                                travelState={travelState}
                                activeStatus={choiceDriver?.id === item?.id ? true : false}
                              />
                            </div>
                          )
                        })
                      }
                    </div> :
                    <>
                      <div className="special-request-box">
                        <h3>Didn't find any driver?</h3>

                        <div className="call-special-request" onClick={handleSpecialRequest}>
                          <ReactWhatsapp number="+2349044442200" message="Please, I want to make a Driver booking special request">
                            Make A Special Request
                          </ReactWhatsapp>
                        </div>
                      </div>
                    </>
                  }

                </PickDriverWrap>
              }

              <BookingFooterWrap>
                {
                  <BookingFooterBtn text={'#fff'} bordercolor={'#fff'} bg={'#000'}>
                    ₦{`${(Number(bookingAmount + insuranceAmount))?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}
                  </BookingFooterBtn>
                }

                {/* <NavLink to={'/cart'}> */}
                {
                  Number(bookingAmount + insuranceAmount) > 0 &&
                  <BookingFooterBtn text={'#000'} bordercolor={'#ffa100'} bg={'#ffa100'} onClick={handleProceedBooking}>
                    Proceed Booking
                  </BookingFooterBtn> 
                }

                {/* </NavLink> */}
              </BookingFooterWrap>
            </div>
          </BookingOverall>
        </BookingFlexWrap>


        <BookingExtrasFlexWrap>
          <FeatureWrapper>
            <h4>Feature</h4>

            <SubFeatureWrap>
              <TickBox>
                <QuestionImg width={'15px'} src={tickX} />
              </TickBox>

              <p>Personal Driver</p>
            </SubFeatureWrap>

            <SubFeatureWrap>
              <TickBox>
                <QuestionImg width={'15px'} src={tickR} />
              </TickBox>

              <p>No Criminal records</p>
            </SubFeatureWrap>

            <SubFeatureWrap>
              <TickBox>
                <QuestionImg width={'15px'} src={tickR} />
              </TickBox>

              <p>Available Per Hour</p>
            </SubFeatureWrap>
          </FeatureWrapper>

          <BoxExtras className="booking-section">
            <OwnersNoteCard cardtype={"driver"} />

            <RoadlersCard />
          </BoxExtras>
        </BookingExtrasFlexWrap>


        {/* <BoxExtras>
            <div className="subsection-header">
                <H11>
                  Related Offers
                </H11>
            </div>

            <AssetOverall>
                <OffersCard />
                <OffersCard />
            </AssetOverall>

        </BoxExtras> */}

        <Footer />
    </>
  )
}

export default DriverBooking