import React from 'react'
// import ToggleBar from '../../../components/toggleBar'

import {
    StatsBox, P, IconDiv, H3, Button, Div, StatsDiv, ButtonGroup, Stat
} from './stats.styles';
import car from '../../../assets/images/png/Car.png'

import { BsCalendar3, BsFillFolderFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';


const StatsTwo = ({ vehicleCount }: any) => {

    let totalCount;
    if (vehicleCount !== undefined) {

      

        //@ts-ignore
        totalCount = Object.keys(vehicleCount).reduce((sum, key) => {
            // Check if the key is not "countPinned" before adding to the sum
            if ((key !== "countBlocked") && (key !== "countPinned")) {

                return sum + vehicleCount[key];
            }
            return sum;
        }, 0);
    }



    return (
        <>
            <StatsDiv>
                <StatsBox>
                    <Link to='/list-vehicles' style={{ textDecoration: 'none' }}>
                        <P>Total Vehicle Upload</P>
                        <IconDiv >
                            <BsCalendar3 style={{ fontSize: '11px' }} />
                        </IconDiv>

                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', gap: '10px' }}>
                            <img src={car} alt="" width="50px" />

                            <H3>{totalCount}</H3>
                        </div>
                    </Link>
                </StatsBox>
                <Div>


                    <ButtonGroup>
                        <Button style={{ background: '#CF0303', }}>
                            UnScreened
                        </Button>
                        <Stat>{vehicleCount?.countPending || 0}</Stat>

                    </ButtonGroup>

                    <ButtonGroup>
                        <Button style={{ background: '#07B418' }}>
                            Accepted
                        </Button>
                        <Stat>{vehicleCount?.countApproved || 0}</Stat>

                    </ButtonGroup>

                    <ButtonGroup>
                        <Button style={{ background: '#CF0303', }}>
                            Rejected
                        </Button>
                        <Stat>{vehicleCount?.countRejected || 0}</Stat>

                    </ButtonGroup>




                </Div>
            </StatsDiv>
        </>
    )
}

export default StatsTwo