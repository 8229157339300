import styled from "styled-components";

export const BackgroundBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.98);
  transition: all 0.5s ease 0s;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 17;
`;
export const ModalBox = styled.div`
background:#fff;
transition: all 0.5s ease 0s;
display:flex;
flex-direction:column;
padding:30px 20px;
border-radius:10px;
position:absolute;
box-shadow: 4px 4px 15px 2px #00000024;

width:85%;

& .modal-box{
    background-red;
    height:100%;
    width:100%;
    h1{
        color:#000;
        text-align:center;
        margin:0;
    }
    .submit{
        background: #FFD58C;
        width:100%;
        margin-top:10px;
        padding:10px 12px;
        text-align:center;
        border-radius:10px;
        box-shadow: 4px 4px 15px 2px #00000024;
        button{
            border:none;
            background:transparent;
            font-weight:bold;
            color:#000;
        }
    }
    .user-compartment{
        color:#000;
        display:flex;
        gap:20px;
        align-items:flex-start;
        .image-section{
            position:relative;
            border-radius:50%;
            .img{
                border-radius:50%;
            }
            .camera{
                position:absolute;

                bottom:10px;
                right:0px;
                background:#000;
                padding:5px;
                border-radius:50%;
            }
        }
        .text-section{
            padding-t0p:15px;
            display:flex;
            flex-direction:column;
            gap:10px;
            h5{
                margin:0;
            }
             h3{
                margin:0;
            }
        }

    }
    .flex-div{
        display:flex;
        align-items:center;
        justify-content:space-between;
        margin-top:40px;
        gap:5px;

        }

        .from, .to{
            display:flex; 
            align-items:center;
            box-shadow: 4px 4px 15px 2px #00000024;
            border-radius:10px;
            padding:0px 15px;
            width:48%;
            position:relative;
            gap:1px;

            .autocomplete-wrap{
                overflow:hidden;
            }
            label{
                position:absolute;
                top:-25px;
                color:#000;
                font-size:12px;
            }
            input{
                outline:none;
                border:none;
                padding:12px 15px;
                overflow-x:hidden;
            }


        }
       
    }
    .phone{
        box-shadow: 4px 4px 15px 2px #00000024;
        border-radius:10px;
        margin-top:45px;
        position:relative;
        label{
            position:absolute;
            top:-25px;
            color:#000;
            font-size:12px;
            margin-left:10px;
        }
    }
    .email{
        margin-top:45px;
        position:relative;
        label{
            position:absolute;
            top:-25px;
            color:#000;
            font-size:12px;
            margin-left:10px;
        }
    }

     .date-seats{
        display:flex;
        align-items:center;
        justify-content:space-between;
        margin-top:40px;
        gap:5px;

        .date, .departure, .seat{
            display:flex; 
            align-items:center;
            box-shadow: 4px 4px 15px 2px #00000024;
            border-radius:10px;
            padding:0px 15px;
            width:33%;
            
            position:relative;

            label{
                position:absolute;
                top:-25px;
                color:#000;
                font-size:12px;
            }
            input{
                outline:none;
                border:none;
                padding:12px 2px;
                overflow-x:hidden;
            }
    }
}
`;
export const H3 = styled.h3`
  color: #fff;
`;
export const Button = styled.button`
  background: #ffa100;
  color: #000;
  width: 50%;
  border: none;
  outline: none;
  padding: 10px 12px;
  border-radius: 7px;
  font-weight: 700;
  font-size: 18px;
`;
