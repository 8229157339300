import React, { FC, useEffect, useState } from 'react'

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


import {
    Modal, Label, OptionDiv, Flex, DIV, Text, DIVS, DARKBUTTON, AP,
    Radio, TOGGLETIME, HOUR, TIMEDIV,
    HOURDIV, PP, DROPDOWN, DROPDOWNDIV, PPP, DROPDOWNCOL, LabelClick, Input
} from '../../providerCarReg/seventhScreen/seventhScreen.styles';


import map from '../../../assets/images/png/map.png'
import rect from '../../../assets/images/png/rect.png'
import location from '../../../assets/images/png/location.png'
import line from '../../../assets/images/png/line.png'
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify'
import moment from 'moment';
import { useCarUpdateMutation } from '../../../services/providerApi';

type Props = {
    vehicleProfile: any;

}
export const ModalOne: FC<Props> =({vehicleProfile }) => {
    const [vehicleInfo, setVehicleInfo] = useState(vehicleProfile?.data[0]);

        const [updateVehicle, { data, isLoading, isSuccess, isError, error }] = useCarUpdateMutation()

        console.log(vehicleInfo)
        const [airConYes, setAirConYes] = useState<boolean>(false)
        const [airConNo, setAirConNo] = useState<boolean>(false)
        const [comeWithDriverYes, setComeWithDriverYes] = useState<boolean>(false)
        const [comeWithDriverNo, setComeWithDriverNo] = useState<boolean>(false)
        const [vehicleTrackerYes, setVehicleTrackerYes] = useState<boolean>(false)
        const [vehicleTrackerNo, setVehicleTrackerNo] = useState<boolean>(false)
        const [nightTripsYes, setNightTripsYes] = useState<boolean>(false)
        const [nightTripsNo, setNightTripsNo] = useState<boolean>(false)
        const [selfDriveYes, setSelfDriveYes] = useState<boolean>(false)
        const [selfDriveNo, setSelfDriveNo] = useState<boolean>(false)
        const [travelYes, setTravelYes] = useState<boolean>(false)
        const [travelNo, setTravelNo] = useState<boolean>(false)

        const [fuelTypePetrol, setFuelTypePetrol] = useState<boolean>(false)
        const [fuelTypeElectric, setFuelTypeElectric] = useState<boolean>(false)
        const [fuelTypeDiesel, setFuelTypeDiesel] = useState<boolean>(false)

        const [airCon, setAirCon] = useState(vehicleInfo?.air_con)
        const [comeWithDriver, setComeWithDriver] = useState(vehicleInfo?.come_with_driver)
        const [vehicleTracker, setVehicleTracker] = useState(vehicleInfo?.vehicle_tracker)
        const [fuelType, setFuelType] = useState(vehicleInfo?.fuel_type)
        const [nightTrips, setNightTrip] = useState(vehicleInfo?.night_trips)
        const [selfDrive, setSelfDrive] = useState(vehicleInfo?.self_drive)
        const [carPool, setCarpool] = useState(vehicleInfo?.travel_carpool)

        const handleAc = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setAirCon('Yes')
            setAirConYes(true)
            setAirConNo(false)
            setVehicleInfo({
                ...vehicleInfo,
                air_con: 'Yes'
            });
            let obj = {
                'vehicle_id': vehicleInfo?.id,
                'vehicle_name': vehicleInfo?.vehicle_name,
                'brand_name': vehicleInfo?.brand_name,
                'plate_number': vehicleInfo?.plate_number,
                'num_passengers': vehicleInfo?.num_passengers,
                'year': vehicleInfo?.year,
                'vehicle_location': vehicleInfo?.vehicle_location,
                'vehicle_for': vehicleInfo?.vehicle_for,
                'vehicle_type': vehicleInfo?.vehicle_type,
                'file': vehicleInfo?.image1,
                'file2': vehicleInfo?.image2,
                'file3': vehicleInfo?.image3,
                'file5': vehicleInfo?.image5,
                'air_con': 'Yes',
                'come_with_driver': vehicleInfo?.come_with_driver,
                'travel_carpool': vehicleInfo?.travel_carpool,
                'fuel_type': vehicleInfo?.fuel_type,
                'vehicle_tracker':vehicleInfo?.vehicle_tracker,
                'night_trips': vehicleInfo?.night_trips,
                'self_drive': vehicleInfo?.self_drive,
    
            }
            //@ts-ignore
            updateVehicle([obj])
        };
        const handleOffAc = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setAirCon('No')
            setAirConNo(true)
            setAirConYes(false)
            setVehicleInfo({
                ...vehicleInfo,
                air_con: 'No'
            });
            let obj = {
                'vehicle_id': vehicleInfo?.id,
                'vehicle_name': vehicleInfo?.vehicle_name,
                'brand_name': vehicleInfo?.brand_name,
                'plate_number': vehicleInfo?.plate_number,
                'num_passengers': vehicleInfo?.num_passengers,
                'year': vehicleInfo?.year,
                'vehicle_location': vehicleInfo?.vehicle_location,
                'vehicle_for': vehicleInfo?.vehicle_for,
                'vehicle_type': vehicleInfo?.vehicle_type,
                'file': vehicleInfo?.image1,
                'file2': vehicleInfo?.image2,
                'file3': vehicleInfo?.image3,
                'file5': vehicleInfo?.image5,
                'air_con': 'No',
                'come_with_driver': vehicleInfo?.come_with_driver,
                'travel_carpool': vehicleInfo?.travel_carpool,
                'fuel_type': vehicleInfo?.fuel_type,
                'vehicle_tracker':vehicleInfo?.vehicle_tracker,
                'night_trips': vehicleInfo?.night_trips,
                'self_drive': vehicleInfo?.self_drive,
    
    
            }
            //@ts-ignore
            updateVehicle([obj])
        };

        const handleComeWithDriver = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setComeWithDriver('Yes')
            setComeWithDriverYes(true)
            setComeWithDriverNo(false)
            setVehicleInfo({
                ...vehicleInfo,
                come_with_driver: 'Yes'
            });
            let obj = {
                'vehicle_id': vehicleInfo?.id,
                'vehicle_name': vehicleInfo?.vehicle_name,
                'brand_name': vehicleInfo?.brand_name,
                'plate_number': vehicleInfo?.plate_number,
                'num_passengers': vehicleInfo?.num_passengers,
                'year': vehicleInfo?.year,
                'vehicle_location': vehicleInfo?.vehicle_location,
                'vehicle_for': vehicleInfo?.vehicle_for,
                'vehicle_type': vehicleInfo?.vehicle_type,
                'file': vehicleInfo?.image1,
                'file2': vehicleInfo?.image2,
                'file3': vehicleInfo?.image3,
                'file5': vehicleInfo?.image5,
                'air_con': vehicleInfo?.air_con,
                'come_with_driver': 'Yes',
                'travel_carpool': vehicleInfo?.travel_carpool,
                'fuel_type': vehicleInfo?.fuel_type,
                'vehicle_tracker':vehicleInfo?.vehicle_tracker,
                'night_trips': vehicleInfo?.night_trips,
                'self_drive': vehicleInfo?.self_drive,
            }
             //@ts-ignore
             updateVehicle([obj])
        };
        const handleComeWithDriverNo = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setComeWithDriver('No')
            setComeWithDriverYes(false)
            setComeWithDriverNo(true)
            setVehicleInfo({
                ...vehicleInfo,
                come_with_driver: 'No'
            });
            let obj = {
                'vehicle_id': vehicleInfo?.id,
                'vehicle_name': vehicleInfo?.vehicle_name,
                'brand_name': vehicleInfo?.brand_name,
                'plate_number': vehicleInfo?.plate_number,
                'num_passengers': vehicleInfo?.num_passengers,
                'year': vehicleInfo?.year,
                'vehicle_location': vehicleInfo?.vehicle_location,
                'vehicle_for': vehicleInfo?.vehicle_for,
                'vehicle_type': vehicleInfo?.vehicle_type,
                'file': vehicleInfo?.image1,
                'file2': vehicleInfo?.image2,
                'file3': vehicleInfo?.image3,
                'file5': vehicleInfo?.image5,
                'air_con': vehicleInfo?.air_con,
                'come_with_driver': 'No',
                'travel_carpool': vehicleInfo?.travel_carpool,
                'fuel_type': vehicleInfo?.fuel_type,
                'vehicle_tracker':vehicleInfo?.vehicle_tracker,
                'night_trips': vehicleInfo?.night_trips,
                'self_drive': vehicleInfo?.self_drive,
            }
             //@ts-ignore
             updateVehicle([obj])
        };

        const handleVehicleTracker = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setVehicleTracker('Yes')
            setVehicleTrackerYes(true)
            setVehicleTrackerNo(false)

             setVehicleInfo({
                ...vehicleInfo,
                vehicle_tracker: 'Yes'
            });
            let obj = {
                'vehicle_id': vehicleInfo?.id,
                'vehicle_name': vehicleInfo?.vehicle_name,
                'brand_name': vehicleInfo?.brand_name,
                'plate_number': vehicleInfo?.plate_number,
                'num_passengers': vehicleInfo?.num_passengers,
                'year': vehicleInfo?.year,
                'vehicle_location': vehicleInfo?.vehicle_location,
                'vehicle_for': vehicleInfo?.vehicle_for,
                'vehicle_type': vehicleInfo?.vehicle_type,
                'file': vehicleInfo?.image1,
                'file2': vehicleInfo?.image2,
                'file3': vehicleInfo?.image3,
                'file5': vehicleInfo?.image5,
                'air_con': vehicleInfo?.air_con,
                'come_with_driver': vehicleInfo?.come_with_driver,
                'travel_carpool': vehicleInfo?.travel_carpool,
                'fuel_type': vehicleInfo?.fuel_type,
                'vehicle_tracker': 'Yes',
                'night_trips': vehicleInfo?.night_trips,
                'self_drive': vehicleInfo?.self_drive,
            }
             //@ts-ignore
             updateVehicle([obj])
        };
        const handleVehicleTrackerNo = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setVehicleTracker('No')
            setVehicleTrackerNo(true)
            setVehicleTrackerYes(false)

             setVehicleInfo({
                ...vehicleInfo,
                vehicle_tracker: 'No'
            });
            let obj = {
                'vehicle_id': vehicleInfo?.id,
                'vehicle_name': vehicleInfo?.vehicle_name,
                'brand_name': vehicleInfo?.brand_name,
                'plate_number': vehicleInfo?.plate_number,
                'num_passengers': vehicleInfo?.num_passengers,
                'year': vehicleInfo?.year,
                'vehicle_location': vehicleInfo?.vehicle_location,
                'vehicle_for': vehicleInfo?.vehicle_for,
                'vehicle_type': vehicleInfo?.vehicle_type,
                'file': vehicleInfo?.image1,
                'file2': vehicleInfo?.image2,
                'file3': vehicleInfo?.image3,
                'file5': vehicleInfo?.image5,
                'air_con': vehicleInfo?.air_con,
                'come_with_driver': vehicleInfo?.come_with_driver,
                'travel_carpool': vehicleInfo?.travel_carpool,
                'fuel_type': vehicleInfo?.fuel_type,
                'vehicle_tracker': 'No',
                'night_trips': vehicleInfo?.night_trips,
                'self_drive': vehicleInfo?.self_drive,
            }
             //@ts-ignore
             updateVehicle([obj])
        };



        const handleNightTrips = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setNightTrip('Yes')
            setNightTripsYes(true)
            setNightTripsNo(false)

             setVehicleInfo({
                ...vehicleInfo,
                night_trips: 'Yes'
            });
            let obj = {
                'vehicle_id': vehicleInfo?.id,
                'vehicle_name': vehicleInfo?.vehicle_name,
                'brand_name': vehicleInfo?.brand_name,
                'plate_number': vehicleInfo?.plate_number,
                'num_passengers': vehicleInfo?.num_passengers,
                'year': vehicleInfo?.year,
                'vehicle_location': vehicleInfo?.vehicle_location,
                'vehicle_for': vehicleInfo?.vehicle_for,
                'vehicle_type': vehicleInfo?.vehicle_type,
                'file': vehicleInfo?.image1,
                'file2': vehicleInfo?.image2,
                'file3': vehicleInfo?.image3,
                'file5': vehicleInfo?.image5,
                'air_con': vehicleInfo?.air_con,
                'come_with_driver': vehicleInfo?.come_with_driver,
                'travel_carpool': vehicleInfo?.travel_carpool,
                'fuel_type': vehicleInfo?.fuel_type,
                'vehicle_tracker':vehicleInfo?.vehicle_tracker,
                'night_trips': 'Yes',
                'self_drive': vehicleInfo?.self_drive,
            }
             //@ts-ignore
             updateVehicle([obj])
        };
        const handleNightTripsNo = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setNightTrip('No')
            setNightTripsNo(true)
            setNightTripsYes(false)

            setVehicleInfo({
                ...vehicleInfo,
                night_trips: 'No'
            });
            let obj = {
                'vehicle_id': vehicleInfo?.id,
                'vehicle_name': vehicleInfo?.vehicle_name,
                'brand_name': vehicleInfo?.brand_name,
                'plate_number': vehicleInfo?.plate_number,
                'num_passengers': vehicleInfo?.num_passengers,
                'year': vehicleInfo?.year,
                'vehicle_location': vehicleInfo?.vehicle_location,
                'vehicle_for': vehicleInfo?.vehicle_for,
                'vehicle_type': vehicleInfo?.vehicle_type,
                'file': vehicleInfo?.image1,
                'file2': vehicleInfo?.image2,
                'file3': vehicleInfo?.image3,
                'file5': vehicleInfo?.image5,
                'air_con': vehicleInfo?.air_con,
                'come_with_driver': vehicleInfo?.come_with_driver,
                'travel_carpool': vehicleInfo?.travel_carpool,
                'fuel_type': vehicleInfo?.fuel_type,
                'vehicle_tracker':vehicleInfo?.vehicle_tracker,
                'self_drive': vehicleInfo?.self_drive,
            }
             //@ts-ignore
             updateVehicle([obj])
        };

        const handleSelfDrive = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setSelfDrive('Yes')
            setSelfDriveYes(true)
            setSelfDriveNo(false)

           setVehicleInfo({
                ...vehicleInfo,
                self_drive: 'Yes'
            });
            let obj = {
                'vehicle_id': vehicleInfo?.id,
                'vehicle_name': vehicleInfo?.vehicle_name,
                'brand_name': vehicleInfo?.brand_name,
                'plate_number': vehicleInfo?.plate_number,
                'num_passengers': vehicleInfo?.num_passengers,
                'year': vehicleInfo?.year,
                'vehicle_location': vehicleInfo?.vehicle_location,
                'vehicle_for': vehicleInfo?.vehicle_for,
                'vehicle_type': vehicleInfo?.vehicle_type,
                'file': vehicleInfo?.image1,
                'file2': vehicleInfo?.image2,
                'file3': vehicleInfo?.image3,
                'file5': vehicleInfo?.image5,
                'air_con': vehicleInfo?.air_con,
                'come_with_driver': vehicleInfo?.come_with_driver,
                'travel_carpool': vehicleInfo?.travel_carpool,
                'fuel_type': vehicleInfo?.fuel_type,
                'vehicle_tracker':vehicleInfo?.vehicle_tracker,
                'night_trips': vehicleInfo?.night_trips,
                'self_drive': 'Yes',
            }
             //@ts-ignore
             updateVehicle([obj])
        };
        const handleSelfDriveNo = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setSelfDrive('No')
            setSelfDriveYes(false)
            setSelfDriveNo(true)

           setVehicleInfo({
                ...vehicleInfo,
                self_drive: 'No'
            });
            let obj = {
                'vehicle_id': vehicleInfo?.id,
                'vehicle_name': vehicleInfo?.vehicle_name,
                'brand_name': vehicleInfo?.brand_name,
                'plate_number': vehicleInfo?.plate_number,
                'num_passengers': vehicleInfo?.num_passengers,
                'year': vehicleInfo?.year,
                'vehicle_location': vehicleInfo?.vehicle_location,
                'vehicle_for': vehicleInfo?.vehicle_for,
                'vehicle_type': vehicleInfo?.vehicle_type,
                'file': vehicleInfo?.image1,
                'file2': vehicleInfo?.image2,
                'file3': vehicleInfo?.image3,
                'file5': vehicleInfo?.image5,
                'air_con': vehicleInfo?.air_con,
                'come_with_driver': vehicleInfo?.come_with_driver,
                'travel_carpool': vehicleInfo?.travel_carpool,
                'fuel_type': vehicleInfo?.fuel_type,
                'vehicle_tracker':vehicleInfo?.vehicle_tracker,
                'night_trips': vehicleInfo?.night_trips,
                'self_drive': 'No',
            }
             //@ts-ignore
             updateVehicle([obj])
        };

        const handleCarpool = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setCarpool('Yes')
            setTravelYes(true)
            setTravelNo(false)
            setVehicleInfo({
                ...vehicleInfo,
                travel_carpool: 'Yes'
            });
            let obj = {
                'vehicle_id': vehicleInfo?.id,
                'vehicle_name': vehicleInfo?.vehicle_name,
                'brand_name': vehicleInfo?.brand_name,
                'plate_number': vehicleInfo?.plate_number,
                'num_passengers': vehicleInfo?.num_passengers,
                'year': vehicleInfo?.year,
                'vehicle_location': vehicleInfo?.vehicle_location,
                'vehicle_for': vehicleInfo?.vehicle_for,
                'vehicle_type': vehicleInfo?.vehicle_type,
                'file': vehicleInfo?.image1,
                'file2': vehicleInfo?.image2,
                'file3': vehicleInfo?.image3,
                'file5': vehicleInfo?.image5,
                'air_con': vehicleInfo?.air_con,
                'come_with_driver': vehicleInfo?.come_with_driver,
                'travel_carpool': 'Yes',
                'fuel_type': vehicleInfo?.fuel_type,
                'vehicle_tracker':vehicleInfo?.vehicle_tracker,
                'night_trips': vehicleInfo?.night_trips,
                'self_drive': vehicleInfo?.self_drive,
            }
             //@ts-ignore
             updateVehicle([obj])
        };

        const handleCarpoolNo = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setCarpool('No')
            setTravelNo(true)
            setTravelYes(false)

            setVehicleInfo({
                ...vehicleInfo,
                travel_carpool: 'No'
            });
            let obj = {
                'vehicle_id': vehicleInfo?.id,
                'vehicle_name': vehicleInfo?.vehicle_name,
                'brand_name': vehicleInfo?.brand_name,
                'plate_number': vehicleInfo?.plate_number,
                'num_passengers': vehicleInfo?.num_passengers,
                'year': vehicleInfo?.year,
                'vehicle_location': vehicleInfo?.vehicle_location,
                'vehicle_for': vehicleInfo?.vehicle_for,
                'vehicle_type': vehicleInfo?.vehicle_type,
                'file': vehicleInfo?.image1,
                'file2': vehicleInfo?.image2,
                'file3': vehicleInfo?.image3,
                'file5': vehicleInfo?.image5,
                'air_con': vehicleInfo?.air_con,
                'come_with_driver': vehicleInfo?.come_with_driver,
                'travel_carpool': 'No',
                'fuel_type': vehicleInfo?.fuel_type,
                'vehicle_tracker':vehicleInfo?.vehicle_tracker,
                'night_trips': vehicleInfo?.night_trips,
                'self_drive': vehicleInfo?.self_drive,
            }
             //@ts-ignore
             updateVehicle([obj])
        };

        const handleFuelTypePetrol = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setFuelType('Petrol')
            setFuelTypePetrol(true)
            setFuelTypeElectric(false)
            setFuelTypeDiesel(false)
            setVehicleInfo({
                ...vehicleInfo,
                fuel_type: 'Petrol'
            });
            let obj = {
                'vehicle_id': vehicleInfo?.id,
                'vehicle_name': vehicleInfo?.vehicle_name,
                'brand_name': vehicleInfo?.brand_name,
                'plate_number': vehicleInfo?.plate_number,
                'num_passengers': vehicleInfo?.num_passengers,
                'year': vehicleInfo?.year,
                'vehicle_location': vehicleInfo?.vehicle_location,
                'vehicle_for': vehicleInfo?.vehicle_for,
                'vehicle_type': vehicleInfo?.vehicle_type,
                'file': vehicleInfo?.image1,
                'file2': vehicleInfo?.image2,
                'file3': vehicleInfo?.image3,
                'file5': vehicleInfo?.image5,
                'air_con': vehicleInfo?.air_con,
                'come_with_driver': vehicleInfo?.come_with_driver,
                'travel_carpool': vehicleInfo?.travel_carpool,
                'fuel_type': 'Petrol',
                'vehicle_tracker':vehicleInfo?.vehicle_tracker,
                'night_trips': vehicleInfo?.night_trips,
                'self_drive': vehicleInfo?.self_drive,
            }
             //@ts-ignore
             updateVehicle([obj])
        };
        const handleFuelTypeElectric = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setFuelType('Electric')
            setFuelTypePetrol(false)
            setFuelTypeElectric(true)
            setFuelTypeDiesel(false)
            setVehicleInfo({
                ...vehicleInfo,
                fuel_type: 'Electric'

            });
            let obj = {
                'vehicle_id': vehicleInfo?.id,
                'vehicle_name': vehicleInfo?.vehicle_name,
                'brand_name': vehicleInfo?.brand_name,
                'plate_number': vehicleInfo?.plate_number,
                'num_passengers': vehicleInfo?.num_passengers,
                'year': vehicleInfo?.year,
                'vehicle_location': vehicleInfo?.vehicle_location,
                'vehicle_for': vehicleInfo?.vehicle_for,
                'vehicle_type': vehicleInfo?.vehicle_type,
                'file': vehicleInfo?.image1,
                'file2': vehicleInfo?.image2,
                'file3': vehicleInfo?.image3,
                'file5': vehicleInfo?.image5,
                'air_con': vehicleInfo?.air_con,
                'come_with_driver': vehicleInfo?.come_with_driver,
                'travel_carpool': vehicleInfo?.travel_carpool,
                'fuel_type': 'Electric',
                'vehicle_tracker':vehicleInfo?.vehicle_tracker,
                'night_trips': vehicleInfo?.night_trips,
                'self_drive': vehicleInfo?.self_drive,
                
            }
             //@ts-ignore
             updateVehicle([obj])
        };
        const handleFuelTypeDiesel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            setFuelType('Diesel')
            setFuelTypePetrol(false)
            setFuelTypeElectric(false)
            setFuelTypeDiesel(true)
            setVehicleInfo({
                ...vehicleInfo,
                fuel_type: 'Diesel'

            });
            let obj = {
                'vehicle_id': vehicleInfo?.id,
                'vehicle_name': vehicleInfo?.vehicle_name,
                'brand_name': vehicleInfo?.brand_name,
                'plate_number': vehicleInfo?.plate_number,
                'num_passengers': vehicleInfo?.num_passengers,
                'year': vehicleInfo?.year,
                'vehicle_location': vehicleInfo?.vehicle_location,
                'vehicle_for': vehicleInfo?.vehicle_for,
                'vehicle_type': vehicleInfo?.vehicle_type,
                'file': vehicleInfo?.image1,
                'file2': vehicleInfo?.image2,
                'file3': vehicleInfo?.image3,
                'file5': vehicleInfo?.image5,
                'air_con': vehicleInfo?.air_con,
                'come_with_driver': vehicleInfo?.come_with_driver,
                'travel_carpool': vehicleInfo?.travel_carpool,
                'fuel_type': 'Diesel',
                'vehicle_tracker':vehicleInfo?.vehicle_tracker,
                'night_trips': vehicleInfo?.night_trips,
                'self_drive': vehicleInfo?.self_drive,
            }
             //@ts-ignore
             updateVehicle([obj])
            
        };



        const notifyError = (text: any) => toast.error(text, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",

        })
        // vehicleDetails.air_con = airCon
        // vehicleDetails.come_with_driver = comeWithDriver
        // vehicleDetails.vehicle_tracker = vehicleTracker
        // vehicleDetails.fuel_type = fuelType
        // vehicleDetails.night_trips = nightTrips
        // vehicleDetails.self_drive = selfDrive
        // vehicleDetails.travel_carpool = carPool

        // const saveData = (): void => {
        //     const { id,
        //         airport,
        //         service_price, travel_trip, vehicle_discount,
        //         set_availability, image1, image2, image3, image4, image5,
        //         ...dataToSend } = vehicleInfo;
        //     //@ts-ignore
        //     updateVehicle([dataToSend])
        // };
        // useEffect(() => {
        //     saveData()
        // }, [vehicleInfo])
        console.log('vehicleProfile', vehicleProfile)
        return (
            <>
                <ToastContainer />
                <Modal>
                    <Flex>
                        <Label>Is the Air Conditioner working?</Label>
                        <OptionDiv>
                            <DIV>
                                <LabelClick onClick={handleAc}>
                                    <Radio style={airConYes || airCon === 'Yes' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> Yes</Text>
                                </LabelClick>
                                <Label onClick={handleOffAc}>
                                    <Radio style={airConNo || airCon === 'No' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> No</Text>
                                </Label>
                            </DIV>
                        </OptionDiv>
                    </Flex>

                    <Flex>
                        <Label>Does it come with a driver?</Label>
                        <OptionDiv>
                            <DIV>
                                <LabelClick onClick={handleComeWithDriver}>
                                    <Radio style={comeWithDriverYes || comeWithDriver === 'Yes' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> Yes</Text>
                                </LabelClick>

                                <Label onClick={handleComeWithDriverNo}>
                                    <Radio style={comeWithDriverNo || comeWithDriver === 'No' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> No</Text>
                                </Label>
                            </DIV>
                        </OptionDiv>
                    </Flex>

                    <Flex>
                        <Label>Do you have vehicle tracker? </Label>
                        <OptionDiv>
                            <DIV>
                                <Label onClick={handleVehicleTracker}>
                                    <Radio style={vehicleTrackerYes || vehicleTracker === 'Yes' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> Yes</Text>
                                </Label>

                                <Label onClick={handleVehicleTrackerNo}>
                                    <Radio style={vehicleTrackerNo || vehicleTracker === 'No' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> No</Text>
                                </Label>
                            </DIV>
                        </OptionDiv>
                    </Flex>

                    <Flex>
                        <Label>Is it available for night trips? </Label>
                        <OptionDiv>
                            <DIV>
                                <Label onClick={handleNightTrips}>
                                    <Radio style={nightTripsYes || nightTrips === 'Yes' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text>Yes</Text>
                                </Label>

                                <Label onClick={handleNightTripsNo}>
                                    <Radio style={nightTripsNo || nightTrips === 'No' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> No</Text>
                                </Label>
                            </DIV>
                        </OptionDiv>
                    </Flex>

                    <Flex>
                        <Label>Is it available for self drive? </Label>
                        <OptionDiv>
                            <DIV>
                                <Label onClick={handleSelfDrive}>
                                    <Radio style={selfDriveYes || selfDrive === 'Yes' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> Yes</Text>
                                </Label>

                                <Label onClick={handleSelfDriveNo} >
                                    <Radio style={selfDriveNo || selfDrive === 'No' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> No</Text>
                                </Label>
                            </DIV>
                        </OptionDiv>
                    </Flex>

                    <Flex>
                        <Label>Is this vehicle available for travel carpool? </Label>
                        <OptionDiv>
                            <DIV>
                                <Label onClick={handleCarpool}>
                                    <Radio style={travelYes || carPool === 'Yes' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> Yes</Text>
                                </Label>

                                <Label onClick={handleCarpoolNo}>

                                    <Radio style={travelNo || carPool === 'No' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> No</Text>
                                </Label>
                            </DIV>
                        </OptionDiv>
                    </Flex>

                    <Flex style={{ gap: '3px' }}>
                        <Label style={{ width: '30%' }}>Fuel Type </Label>
                        <OptionDiv>
                            <DIV>

                                <Label onClick={handleFuelTypePetrol}>
                                    <Radio style={fuelTypePetrol || fuelType === 'Petrol' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> Petrol</Text>
                                </Label>
                            </DIV>
                        </OptionDiv>
                        <OptionDiv>
                            <DIV>
                                <Label onClick={handleFuelTypeElectric}>
                                    <Radio style={fuelTypeElectric || fuelType === 'Electric' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> Electric</Text>
                                </Label>
                            </DIV>
                        </OptionDiv>
                        <OptionDiv>
                            <DIV>
                                <Label onClick={handleFuelTypeDiesel}>
                                    <Radio style={fuelTypeDiesel || fuelType === 'Diesel' ? { background: '#ffa100' } : { background: '#000' }}></Radio>
                                    <Text> Diesel</Text>
                                </Label>

                            </DIV>
                        </OptionDiv>


                    </Flex>
                </Modal>
            </>

        )
    }




