import { GreyBox, ColumnWrap } from './../fundWalletThree/fundwalletthree.styles';
import { AddIconWrap } from './../fundWalletTwo/fundwallettwo.styles';
import { AmountInputWrap } from './../fundWalletOne/fundwalletone.styles';
import styled from 'styled-components'
import { AddNewCardBtn } from '../fundWalletTwo/fundwallettwo.styles'

export const TransferOneWrap = styled.div `
    display: ${(props:any) => props.display};
`

export const TransferHeaderWrap = styled.div `
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    & h3 {
        color: #000;
        font-size: 16px;
        font-weight: 700;
    }

    @media only screen and (max-width: 454px)  {
        h3 {
            font-size: 11px;
        }
    }
`

export const SelectWallet = styled(AddNewCardBtn) `
    margin-top: 0;

    @media only screen and (max-width: 454px)  {
        p {
            font-size: 9px;
        }
    }
`

export const TransferGreyBox = styled(GreyBox) `
    margin-top: 60px;
`

export const TransferAmountInput = styled(AmountInputWrap) `
    position: relative;

    & .absolute-txt {
        position: absolute;
        left: 10px;
        bottom: -23px;
        color: #C17C2D;
        font-size: 12px;
        font-weight: 700;
        padding: 0 5px;
    }

    @media only screen and (max-width: 454px)  {
        .absolute-txt {
            font-size: 9px;
            bottom: -19px;
        }
    }
`

export const TransferErrorMsg = styled.div `
    margin-top: 25px;
    font-size: 11px;
    color: #FF3A3A;
`

export const FlexSubInput = styled.div `
    display: flex;
    align-items: center;
    gap: 10px;
`

export const YellowBox = styled(AddIconWrap) `
    padding: 1px;
    & img {
        width: 30px;
    }

    @media only screen and (max-width: 454px)  {
        img {
            width: 15px;
        }
    }
`

export const ProfileImg = styled.img `
    width: 30px;
`

export const PasswordWrap = styled(ColumnWrap) `
    padding: 10.9px 15px;
    flex: 1;
    margin-bottom: 0;

    @media only screen and (max-width: 454px)  {
        border-radius: 6px;
    }
`