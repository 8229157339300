import React, { FC } from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import {
  Button,
  CounterBox,
  ExportButton,
  Text,
  Box,
  DarkBox,
  H1
} from './Counter/counter.styles';
interface ExportToExcelProps {
  apiData: any[];
  fileName: string;
}


const ExportToExcel: FC<ExportToExcelProps> = ({ apiData, fileName }) => {
  const fileType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension: string = '.xlsx';

  const exportToCSV = (apiData: any[], fileName: string): void => {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(apiData);
    const wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data: Blob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (


    <ExportButton onClick={() => exportToCSV(apiData, fileName)}>
      Export
    </ExportButton>
  )
}

export default ExportToExcel;
