import styled from 'styled-components'
import { RadioBtn } from '../../pages/user/Homepage/homepage.styles'

export const FundWalletTwoWrap = styled.div `
    display: ${(props: any) => props.display};
    position: relative;
`

export const AmountEnteredBox = styled.div `
    margin: 15px 0;
    margin-top: 40px;
    /* margin-bottom: 15px; */
    border-radius: 13px;
    padding: 13px 20px;
    background-color: rgba(196, 196, 196, 0.6);
    box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.1);

    & p {
        color: #4F4F4F;
        font-size: 13px;
        margin: 3px 0;
    }

    & h3 {
        font-size: 16px;
        font-weight: 700;
        color: #09051C !important;
        margin: 0;
    }

    @media only screen and (max-width: 454px) {
        padding: 6px 18px;
        border-radius: 8px;

        p {
            font-size: 10px;
        }

        h3 {
            font-size: 12px;
        }
    }
`

export const SavedCardWrap = styled.div `
    background-color: ${(props: any) => props.bg};
    padding: 15px;
    margin-bottom: 25px;
    border-radius: 13px;
    box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.1);
    
    & .top-section, .bottom-section {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    & .bottom-section {
        margin: 0;
    }
`

export const PrimaryBtn = styled.div `
    border-radius: 15px;
    padding: 3px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #868686;
    color: #fff;
    font-size: 12px;

`

export const CardNumber = styled.p `
    color: ${(props:any) => props.txtColor};
    font-weight: 700;
    font-size: 16px;
    margin: 0;
`

export const CardTypeImg = styled.img `
    width: 40px;
`

export const CardRadioBtn = styled(RadioBtn) `
    border: solid 3px ${(props: any) => props.borderColor};
    width: 15px;
    height: 15px;
    margin-right: 5px;
`

export const AddNewCardBtn = styled.div `
    background-color: #09051C;
    width: 130px;
    border-radius: 6px;
    display: flex;
    padding: 6px 2px;
    gap: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 40px;

    & p {
        font-size: 12px;
        color: #fff;
        margin: 0;
    }

    @media only screen and (max-width: 454px)  {
        p {
            font-size: 9px;
        }
    }
`
export const AddIconWrap = styled.div `
    border-radius: 4px;
    background-color: #FFD58C;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;


    & img {
        width: 13px;
    }
    
    @media only screen and (max-width: 454px)  {
        padding: 2px;
        border-radius: 2px;
        p {
            width: 11px;
        }
    } 

`

export const ErrorTopUpBox = styled.div `
    font-size: 11px;
    margin-top: 6px;
    margin-bottom: 25px;
    color: #FF3A3A;
`