import styled from 'styled-components'
export const Div = styled.div`
display:flex;
align-items:center;
flex-direction:column;
justify-content:center;
width:33%;
position:relative;
`

export const H4 = styled.h4`
color:#fff;
padding:0px .8em;
`
export const Button = styled.button`
color:#fff;
padding:5px 5px;
border:1px solid #ffa100;
background:#000;
border-radius:7px;
width:84px;
font-size:10px;
font-weight:bold;
margin-top:5px;
&:hover{
    
    background: #EBAF4A;
    color:#000;
font-weight:bold;
border:1px solid #EBAF4A;

 }

`
export const FlexDiv = styled.div`
 display:flex;
width:100%;
justify-content:space-between;
padding:0 2em;
`
export const Label = styled.div`
color:#fff;
font-weight:400;
font-size:13px;
background: #FF3A3A;
padding:.5em;
border-radius:50%;
position:absolute;
right:3px;
top:-5px;
height:20px;
width:20px;
display:flex;
justify-content:center;
align-items:center;

`
export const BalanceDiv = styled.div`
background:#000;
height:160px;
width:100%;
margin-top:20px;
border-radius:20px;
position:relative;
overflow:hiddem;
`




