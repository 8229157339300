import React, { useState } from 'react'
import { AmountInputWrap, BackBtnWrap } from '../fundWalletOne/fundwalletone.styles'
import { FundWalletTwoWrap, AmountEnteredBox, SavedCardWrap, CardTypeImg, CardRadioBtn, CardNumber, PrimaryBtn, AddNewCardBtn, AddIconWrap, ErrorTopUpBox } from '../fundWalletTwo/fundwallettwo.styles'

import visa from '../../assets/images/svg/visa-text.svg'
import master from '../../assets/images/svg/mastercard.svg'
import plus from '../../assets/images/svg/dark-plus.svg'
import leftArrow from '../../assets/images/svg/dark-left-arrow.svg'
import CurrencyInput from 'react-currency-input-field'
import { SubmitBtn } from '../toggleBar/togglebar.styles'

const CheckoutFundWalletTwo = ({showBox, onWalletTopup, topupAmount, setTopupAmount, setSubComponent, topupErrorMessage}:any) => {


  return (
    <FundWalletTwoWrap display={showBox}>

      <BackBtnWrap top={'-50px'} onClick={() => setSubComponent('walletError')}>
          <img src={leftArrow} />
      </BackBtnWrap>

      <AmountInputWrap>
          <label>Enter Amount</label>
          <CurrencyInput 
          decimalsLimit={2}
          prefix="₦"
          placeholder='0.00'
          value={topupAmount}
          onValueChange={(value) => setTopupAmount(value)}
          />
          {/* <input type="text" placeholder='0.00' value={topUpAmount} onChange={(e:any) => setTopUpAmount(e.target.value)} /> */}
      </AmountInputWrap>
      <ErrorTopUpBox>{topupErrorMessage}</ErrorTopUpBox>


      {/* <SavedCardWrap bg={'#09051C'}>
        <div className="top-section">
          <CardTypeImg src={master} />

          <CardRadioBtn borderColor={'#fff'} radioActive={true} />
        </div>

        <div className="bottom-section">
          <CardNumber txtColor={'#fff'}>
            .... 6754
          </CardNumber>

          <PrimaryBtn>
            Primary
          </PrimaryBtn>
        </div>
      </SavedCardWrap>

      <SavedCardWrap bg={'#FFD58C'}>
        <div className="top-section">
          <CardTypeImg src={visa} />

          <CardRadioBtn borderColor={'#000'} radioActive={false} />
        </div>

        <div className="bottom-section">
          <CardNumber txtColor={'#09051C'}>
            .... 6754
          </CardNumber>
        </div>
      </SavedCardWrap>

      <AddNewCardBtn onClick={() => addCard()}>
        <AddIconWrap>
          <img src={plus} />
        </AddIconWrap>  
        <p>Add New Card</p>
      </AddNewCardBtn> */}
        <SubmitBtn onClick={onWalletTopup}>
            <p>Pay</p>
        </SubmitBtn>
    </FundWalletTwoWrap>
  )
}

export default CheckoutFundWalletTwo