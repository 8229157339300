import { TicketDateBox } from "./../../pages/user/TicketPage/ticketpage.styles";
import styled from "styled-components";

export const GarageCard = styled.div`
  background-color: #fff;
  /* min-height: 250px; */
  width: 100%;
  border-radius: 15px;
  margin-top: 70px;
  margin-bottom: 100px;
  padding: 0px 0px 10px 0px;
`;

export const GarageTopSection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  padding: 20px;
  gap: 30px;
`;

export const GarageCardImgWrap = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  & .priceBox {
    button {
      outline: none;
      background: black;
      border: none;
      padding: 5px 7px;
      border-radius: 5px;
      color: #fff;
    }
  }

  & .user-subwrapper {
    position: absolute;
    top: -60px;
    left: -5px;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    overflow: hidden;

    .img {
      width: 80px;
    }
  }
  & .details-subwrapper {
    position: absolute;
    top: -55px;
    left: 65px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;

    h1 {
      font-size: 11.5px;
      margin: 0;
      font-weight: bold;
    }
    p {
      font-size: 9px;
      margin: 0;
    }
  }
  & .img-subwrapper {
    position: absolute;
    top: -60px;
    right: -5px;

    .img {
      width: 125px;
      height: 115px;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      .imgFill {
      
        object-fit: cover;
        width:125px;
        height:115px;
       
      }
    }
  }
  & .trip {
    margin-top: 25px;
  }
  & .trip-details {
    width: 60%;

    h4 {
      color: #868686;
      margin: 0;
      font-size: 13px;
    }
    p {
      color: #000;
      margin: 0;
      font-size: 10.5px;
    }
  }
`;

export const DateLocationWrap = styled.div`
  position: absolute;
  bottom: 0px;
  right: -20px;
  background: #f0ede9;
  padding: 7px 10px;
  border-radius: 5px 0px 0px 5px;
`;

export const DateBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .leaving {
    display: flex;
    align-items: center;
    font-weight: 500;
    gap: 5px;
    h5 {
      font-size: 10px;
      margin: 0;
      font-weight: 800;
    }
    p {
      font-size: 9px;
      color: #000;
      font-weight: bold;
      margin: 1px 0px 0px 0px;
    }
  }
  .date {
    display: flex;
    align-items: center;
    gap: 5px;
    p {
      font-size: 9px;
      color: #000;
      margin: 0;
      font-weight: bold;
    }
  }

  & img {
    width: 12px;
  }
`;

export const LocationBox = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
  position: absolute;
  margin-top: 30px;
  margin-left: -5px;
  width: 110%;
  & img {
    width: 10px;
  }

  & p {
    color: #868686;
    font-weight: 700;
    font-size: 12px;
    margin: 0;
    width: 100%;
  }
`;
export const GarageBottomSection = styled.div`
  width: 100%;

  & .bottom-inner {
    padding: 20px 10px;
    display: flex;
    align-items: center;
  }
`;

export const TicketPriceBox = styled(TicketDateBox)`
  justify-content: center;

  & p {
    color: #ffa100;
  }
`;
