import styled from 'styled-components'

export const OffersCardWrap = styled.div `
    border: solid 1px #ffa100;
    background-color: #1D1C1C;
    border-radius: 13px;

    & .inner-wrap {
        padding: 15px;

        .img-wrap {
            display: flex;
            justify-content: center;
            img {
                width: 150px;

                @media only screen and (max-width: 484px) {
                    width: 100px;
                }
            }
        }

        .text-section {
            h3 {
                font-weight: 700;
                color: #fff;

                @media only screen and (max-width: 484px) {
                    font-size: 15px;
                }
            }

            hr {
                margin: 0;
                width: 50%;
            }
        }

        .footer {
            display: flex;
            justify-content: flex-end;

            img {
                filter: invert(47%) sepia(28%) saturate(0%) hue-rotate(276deg) brightness(100%) contrast(86%);
            }
        }
    }
`