import React, { FC, useEffect, useState, useRef } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import { IoCarSport } from 'react-icons/io5';
import { BiUser } from 'react-icons/bi';
import { RiDashboard3Line } from 'react-icons/ri';
import { BsCalendar2Minus } from 'react-icons/bs';
import { GiReceiveMoney } from 'react-icons/gi';


import {
    DriverCounterBox,
    Page,
} from './drivers.styles';



import { Navigate, useNavigate } from 'react-router-dom';
import ProviderBalance from '../../../components/providerDashboard/providerBalance/providerBalance';
import ProviderActions from '../../../components/providerDashboard/providerActions/providerActions';
import AdminHeader from '../../../components/adminHeader/';
import DashboardInfo from '../../../components/Admin/DahboardInfo';
import Counter from '../../../components/Admin/Counter';
import Filter from '../../../components/Admin/Filter';
import ButtonGroups from '../../../components/Admin/ButtonGroups';
import Vehicles from '../../../components/Admin/Vehicles';
import CounterFilter from '../../../components/Admin/CounterFilter';
import RequestButtonGroups from '../../../components/Admin/RequestButtonGroup';
import AdminRequests from '../../../components/Admin/AdminRequest/adminRequests';
import AdminDrivers from '../../../components/Admin/Drivers/adminDrivers';
import LinkHeader from '../../../components/Admin/Header/header';
import { PagePadding } from '../Dashboard/dashboard.styles';

const ListDrivers: FC = () => {
    return (
        <>
            <AdminHeader />
            <Page>



            <PagePadding className='padding'>

                    <LinkHeader />

                    <DriverCounterBox>
                        <Counter driver={true} />
                        <CounterFilter driver={true} />
                    </DriverCounterBox>
                    <Filter driver={true} />
                    <ButtonGroups isDriver={true} />
                    <AdminDrivers />
                </PagePadding>

            </Page>
        </>
    )
}
export default ListDrivers