import styled from "styled-components"

export const Card = styled.div `
    position: relative;
    height: 290px;
    width: 265px;
    background-color: #fff;
    border-radius: 16px;
    padding: 2.5px;
    cursor: pointer;

    @media only screen and (min-width: 499px)  {
        height: 290px;
        width: 195px;
        background-color: #fff;
        border-radius: 16px;
        padding: 2.5px;
        cursor: pointer;
    }

    @media only screen and (min-width: 500px) {
        width: unset;
    } 

    @media only screen and (min-width: 570px) {
        width: 215px;
    } 

    @media only screen and (min-width: 767px)  {
        width: 218px;
    }
`
export const VerifiedTxt = styled.p `
    position: absolute;
    right: 22px;
    top: -25px;
    color: #fff;
    font-weight: 600;
    font-size: 10px;
`

export const VerifiedImg = styled.img `
    position: absolute;
    top: -20px;
    right: -20px;
    z-index: 45;
    width: 50px;

    @media only screen and (max-width: 460px) {
        width: 35px;
        top: -17px;
        right: -15px;
    }
`

export const WhiteInnerCard = styled.div `
    width: 100%;
    height: 100%;
    border-radius: 14px;
    background-color: ${(props: any) => props.theme.cardColorBg};
`

export const ImgWrapper = styled.div`
    height: 160px;
    width: 100%;
    border-radius: 16px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 15;
`

export const AssetImg = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;
`

export const EmptyBox = styled.div`
    height: 150px;
    width: 100%;
`

export const InfoWrapper = styled.div `
    padding: 15px 20px 10px;
`

export const HeaderText = styled.h2`
    font-size: 15px !important;
    font-weight: 800 !important;
    width: 173px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 5px;
    margin-bottom: 8px;
    text-align: left !important;
    color: ${(props:any) => props.theme.body} !important;

    @media only screen and (max-width: 499px) {
        width: 220px;
    }

    @media only screen and (max-width: 464px) {
        font-size: 14px !important;
    }
`

export const PriceLocationBox = styled.div `
    display: flex;
    gap:12px;
    align-items:start;

    & div {
        flex: 0.6;
    }

    .right-side-location {
        width: 100%;

    }
`

export const NewPrice = styled.p `
    color: #AA6C05;
    font-size: 12px;
    font-weight: 800;
    margin: 0;
display:flex;
align-items:center;
    @media only screen and (max-width: 464px) {
        font-size: 11px;
    }
`

export const OldPrice = styled.p `
    font-size: 11px;
    text-decoration: line-through;
    color: #797979;
    margin: 0;

    @media only screen and (max-width: 464px) {
        font-size: 11px;
    }
`

export const RegText = styled.p `
    font-size: 10px;
    color: ${(props:any) => props.theme.body} ;
    font-weight: 600;
    margin: 0;
`

export const LocationWrap = styled(RegText) `
    /* width: 35px; */
    white-space: nowrap;
    font-weight: 700;
    /* overflow: hidden; */
    text-overflow: ellipsis;
    text-transform: capitalize;
    font-size:8px;
    text-wrap: wrap;
`

export const ImageTag = styled.img`
    width: 13px;
    /* filter: ${(props: any) => props.theme.cartColor}; */
`

export const StarTag = styled(ImageTag)`
filter:brightness(0) saturate(100%) invert(60%) sepia(90%) saturate(1151%) hue-rotate(356deg) brightness(99%) contrast(113%);
`

export const ExtrasBox = styled(PriceLocationBox) `
    margin-top: 15px;
    width: 100%;

    @media only screen and (max-width: 460px) {
        gap: 5px;
        /* justify-content: unset; */
    }
`

export const ImgTextBox = styled.div `
    display: flex;
    align-items: center;
    gap: 1px;

    &.rating-wrap {
        column-gap: 3px;
    }
`

export const RightExtraBox = styled.div `
    display: flex;
    align-items: center;
    gap: 14px;
    flex: 0.4;

    @media only screen and (max-width: 460px) {
        column-gap: 10px;

        & img {
            width: 18px;
        }
    }
`