import React, { FC, useEffect, useState, useRef } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import {
    DocDiv, Doc, Doc1, Doc2, Doc3, P2, Label, Close, Button, Div, P

} from './documents.styles';

import VehicleDoc from './vehicleDoc/vehicleDoc';


type Props = {
    vehicleProfile: any;
    setLicenseDocStatus?: any;
    setProofDocStatus?: any;
    user_id?:any;
}
const Documents: FC<Props> = ({ user_id,setProofDocStatus, setLicenseDocStatus, vehicleProfile }) => {

    return (
        <>
            <br />
            <br />
            <br />
            <Label>Documents </Label>
            <br />
            <br />
            <P>Your vehicle is unverified to verify upload all first 2 documents from the left</P>


            <VehicleDoc
                setProofDocStatus={setProofDocStatus}
                setLicenseDocStatus={setLicenseDocStatus}
                vehicleProfile={vehicleProfile} 
                user_id={user_id}
                />


        </>
    )
}
export default Documents