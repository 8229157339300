import React from 'react'
// import ToggleBar from '../../../components/toggleBar'

import {
    MembersBox, P, H3, H6, MembersDiv, Data, Span, ImageDiv, Title,PP
} from './index.styles';
import Admin from '../../../assets/images/png/admin.png'
import calendar from '../../../assets/images/png/Calendar.png'

import {  AiOutlineCalendar } from 'react-icons/ai';
const Members = () => {
    return (
        <>
            <MembersDiv>
                <ImageDiv>
                    <img src={Admin} alt="" />
                </ImageDiv>
                <MembersBox>
                    <Data>
                        <Title>Data</Title>
                        <H6>Ayo Jacobs</H6>
                        <P>Support Manager</P>
                    </Data>
                    <Data>
                        <Title>Daily Average</Title>

                        <H6>6.3 Hours</H6>
                        <P>Last seen: 12:30 today</P>
                    </Data>
                    <Data>
                        <div style={{color:'#000', gap:'7px', display:'flex', position: 'absolute', top: '-17px' }}>
                            <PP>Activity</PP>
                           <AiOutlineCalendar/>

                        </div>

                        <P style={{ margin: '-4px' }}><Span>Calls:</Span> 12</P>
                        <P style={{ margin: '-4px' }}><Span>Responses:</Span> 10</P>
                        <P style={{ margin: '-4px' }}><Span>Request:</Span> 10</P>
                        <P style={{ margin: '-4px' }}><Span>Screening:</Span> 0</P>
                    </Data>


                </MembersBox>
            </MembersDiv>


        </>
    )
}

export default Members