import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import { MdKeyboardArrowDown } from 'react-icons/md';
import { IoLocationSharp } from 'react-icons/io5';
import {
    Input, IdButton, TextInput, DropDown, Modal, Div, VerifyButton, Option, Radio, Text, Texts
} from './secondScreen.styles';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { IoIosArrowDown } from 'react-icons/io';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setDriverInfo } from '../../../slices/provider';
import { baseUrl } from '../../../services/providerApi';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'

const SecondScreen: FC<{ setWidth: any, setDriverDetails: any, driverDetails: any }> = ({ setWidth, driverDetails, setDriverDetails }) => {
    const dispatch: any = useAppDispatch()

    useEffect(() => {
        setWidth(30)
    }, [])
    const [idModal, setIdModal] = useState<boolean>(false);
    const [phone, setPhone] = useState(driverDetails?.phone_number)
    const [meansofID, setMeansofID] = useState<string>(driverDetails?.identification);
    const [IDNumber, setIDNumber] = useState<string>(driverDetails?.id_number);

    const selectNIN = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setMeansofID('NIN')
        confirmID('NIN')
        setIdModal(false)

        setDriverDetails({
            ...driverDetails, identification: 'NIN'
        })
        dispatch(setDriverInfo({ ...driverDetails, identification: 'NIN' }));

    };
    const selectInternationalPassport = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setMeansofID('International Passport')
        confirmID('International Passport')
        setIdModal(false)

        setDriverDetails({
            ...driverDetails, identification: 'International Passport'
        })
        dispatch(setDriverInfo({ ...driverDetails, identification: 'International Passport' }));
    };
    const selectLicense = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setMeansofID('Drivers License')
        confirmID('License')
        setIdModal(false)

        setDriverDetails({
            ...driverDetails, identification: 'Drivers License'
        })
        dispatch(setDriverInfo({ ...driverDetails, identification: 'Drivers License' }));
    };


    const openModalIDModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setIdModal((prev) => !prev)
    };


    const handleIDnumber = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        setIDNumber((event.target as HTMLTextAreaElement).value)

        if ((event.target as HTMLTextAreaElement).value?.length > 10 && meansofID === 'NIN') {
            confirmID('NIN')

        }
        if ((event.target as HTMLTextAreaElement).value?.length > 8 && meansofID === 'International Passport') {
            confirmID('International Passport')

        }
        if ((event.target as HTMLTextAreaElement).value?.length > 11 && meansofID === 'Drivers License') {
            confirmID('License')

        }
        setDriverDetails({
            ...driverDetails, id_number: (event.target as HTMLTextAreaElement).value
        })
        dispatch(setDriverInfo({ ...driverDetails, id_number: (event.target as HTMLTextAreaElement).value }));

    };

    const handlePhone = (e: any) => {
        setPhone(e)
        setDriverDetails({
            ...driverDetails, phone_number: e
        })
        dispatch(setDriverInfo({ ...driverDetails, phone_number: e }));

    }
    const user = useAppSelector((state: any) => state?.auth?.auth)
    const [passId, setPassId] = useState<boolean>(false)

    const confirmID = async (data: string) => {
        try {
            let endpoint = '';
            if (data === 'NIN' && IDNumber?.length > 9) {
                endpoint = `${baseUrl}verify-nin?nin_no=${IDNumber}`;

            } 
            
            if (data === 'International Passport' && IDNumber?.length > 8) {
                endpoint = `${baseUrl}verify-passport?passport_no=${IDNumber}&lastname=${driverDetails?.lastname}`;
            }
            const response = await axios.post(endpoint, null, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.token}`,
                },
            });
            if (response?.data.data.toLowerCase().includes("wrong"|| "invalid")) {
                notifyError(response?.data.data)

            } else {
                notifySuccess(response?.data.data)
                setPassId(true)
            }
        } catch (error: any) {
            if (error?.response?.data?.message.includes("There were validation errors for International Passport Verification")) {
                notifyError("Your International is inValid")
                setPassId(false)

            } else if (error?.response?.data?.message.includes("There were validation errors for Virtual NIN Full Details")) {
                notifyError("Your NIN is inValid")
                setPassId(false)
            }

        }
    };

    const notifyError = (text: any) => toast.error(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })

    const notifySuccess = (text: any) => toast.success(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })
    return (
        <>
            <ToastContainer />

            <Input style={{ marginTop: '30px', }}>
                <div className='driverCode'>
                    <PhoneInput
                        defaultCountry="NG"
                        value={phone}
                        onChange={handlePhone}
                        international={true}
                        style={{ fontSize: 18 }}
                    />
                </div>
            </Input>

            {/* <Input style={{ marginTop: '28px', padding: '0px 15px' }}>

                <TextInput
                    type="text"
                    placeholder='ID Number'
                    value={IDNumber}
                    onChange={handleIDnumber}
                />

                <VerifyButton>
                    <Texts>{meansofID ? meansofID : 'ID Type'}</Texts>
                    <DropDown onClick={openModalIDModal}>
                        <IoIosArrowDown style={{ color: '#FFD58C', fontSize: 20, fontWeight: 600 }} />
                    </DropDown>
                </VerifyButton>

            </Input> */}
            <div>
                {
                    idModal && <Div>
                        <Modal style={{ width: '75%' }}>

                            <Option onClick={selectInternationalPassport}>
                                <Radio style={meansofID === 'International Passport' ? { background: '#ffa100' } : { background: '#fff' }}></Radio>
                                <Text>International Passport</Text>
                            </Option>
                            {/* <Option onClick={selectLicense}>
                                <Radio style={meansofID === 'Drivers License' ? { background: '#ffa100' } : { background: '#fff' }}></Radio>
                                <Text>Drivers License</Text>
                            </Option> */}
                            <Option onClick={selectNIN}>
                                <Radio style={meansofID === 'NIN' ? { background: '#ffa100' } : { background: '#fff' }}></Radio>
                                <Text> NIN</Text>
                            </Option>
                        </Modal>
                    </Div>
                }
            </div>



        </>
    )
}
export default SecondScreen