import styled from 'styled-components'

export const OwnersCardWrap = styled.div `
    margin-right: 0px;
    border-radius: 14px;
    background-color: ${(props: any) => props.theme.text};

    & .inner-card-wrap {
        padding: 20px;

        .headers-wrap h3 {
            color: ${(props: any) => props.theme.body};
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 0;

            @media only screen and (max-width: 454px) {
                font-size: 15px;
                margin: 0;
            }
        }

        .headers-wrap hr {
            width: 40%;
            text-align: left;
            margin: 0;
            margin-top: 5px;
            border: solid 1px ${(props: any) => props.theme.body};
        }

        .location-wrap {
            display: flex;
            align-items: center;
            gap: 5px;

            p {
                color: ${(props: any) => props.theme.body};
                font-size: 14px;
                font-weight: 600;
            }
        }

        .content-wrap {
           p {
                color: ${(props: any) => props.theme.body};

                a {
                    text-decoration: none;
                    color: #ffa100;
                }
           } 

           @media only screen and (max-width: 454px) {
                p {
                    font-size: 13px;
                }
            }
        }

        .owners-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 50px;

            .verified-wrap {
                color: ${(props: any) => props.theme.body};
                border: solid 1px #C4C4C4;
                border-radius: 25px;
                padding: 8px 20px;
                font-size: 13px;
            }

            @media only screen and (max-width: 454px) {
                margin-top: 20px;

                .verified-wrap {
                    font-size: 11px;
                    padding: 5px 15px;
                }
            }
        }
    }
`