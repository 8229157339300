import styled from 'styled-components'



export const Input = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:12px;
overflow:hidden;
margin-top:50px;
justify-content:space-around;
padding:9px 15px;
position:relative;
@media only screen and (max-width: 380px) {
    padding:8px 15px;

    }

`
export const VerifyButton = styled.button`
background: #FFD58C;
color: #000;
padding:8px 6px;
outline:none;
border:none;
border-radius:7px;
font-size:1rem;
font-weight:bold;
margin-left:5px;
display:flex;
align-items:center;
justify-content:center;
gap:10px;
`
export const DIV = styled.span`
display:flex;
align-items:center;
background:#fff;
width:100%;
border-radius:13px;
padding:0px 11px 0px 8px;
margin-top:20px;
justify-content:space-between;

`


export const INPUTS = styled.input`
width:50%;
padding:15px 0px;
border:none;
outline:none;
width:100%;
font-size:16px;
@media only screen and (max-width: 380px) {
    padding:10px 0px;
    font-size:14px;

    }
`
export const LocationInput = styled.input`
width:50%;
padding:20px 6px;
border:none;
outline:none;
width:100%;
font-size:16px;
::placeholder, ::-webkit-input-placeholder {
    font-size: 16px;
    color:#242321;
    font-weight:500;

}
@media only screen and (max-width: 380px) {
    padding:17px 6px;
    font-size:14px;
        ::placeholder, ::-webkit-input-placeholder {
            font-size: 14px;
        }
`

export const Text = styled.div`
font-size:16px;
margin-top:3px;
@media only screen and (max-width: 380px) {
    font-size:14px;


    }
`
export const DropDown = styled.div`
background: #000;
display:flex;
align-items:center;
justify-content:center;
padding:1px;
`

export const Div = styled.div`
display:flex;
align-items:flex-start;
justify-content:flex-end;
margin-top:20px;
position:absolute;
right:25px;
width:300px;
overflow-y:scroll;
height:320px;
@media only screen and (min-width: 700px) {
    right:200px;
width:500px;

}
@media only screen and (min-width: 1090px) {
    right:300px;
}
`
export const Modal = styled.div`
background: #000;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
padding:20px;
gap:10px;
border-radius:6px;
width:70%;

`
export const Option = styled.div`
background: #fff;
display:flex;
align-items:center;
justify-content:flex-start;
padding:10px;
gap:10px;
width:100%;
color:#000;
border-radius:6px;
font-weight:600;
`
export const Label = styled.label`

width:100%;
font-weight:500;

`
export const Labels = styled.label`

font-size:15px;


`


