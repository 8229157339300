import React from 'react'
import { QuestionImg } from '../../pages/user/CarBooking/CarBooking.styles'
import { OffersCardWrap } from './offersCard.styles'

import like from '../../assets/images/svg/like-icon.svg'
import deliveryOffer from '../../assets/images/png/delivery-offer.png'

const OffersCard = () => {
  return (
    <OffersCardWrap>
        <div className="inner-wrap">
            <div className="img-wrap">
                <img src={deliveryOffer} alt="" />
            </div>

            <div className="text-section">
                <h3>Make a delivery</h3>

                <hr />
            </div>

            <div className="footer">
                <QuestionImg src={like} width={'30px'}/>
            </div>
        </div>
    </OffersCardWrap>
  )
}

export default OffersCard