import React, { useState } from 'react'
import Header from '../../../components/header'
import SuccessWalletComponent from '../../../components/successWalletComponent'
import { OverallPaymentWrap, PaymentBox, RoadlersBlackCircle, RoadlersHomeCone, WalletContentWrap, WalletModalHeader } from '../../../components/walletModal/walletmodal.styles'

import roadlersCone from '../../../assets/images/svg/homebutton.svg'
import logo from '../../../assets/images/svg/logo-small.svg'

const PayoutSuccessPage = () => {
    const [showComponent, setShowComponent] = useState('success')

  return (
    <>
        <Header />

        <OverallPaymentWrap>
            <PaymentBox>
                <RoadlersHomeCone  src={roadlersCone}/>
                <RoadlersBlackCircle>
                    <img src={logo} alt="" />
                </RoadlersBlackCircle>

                <div className="inner-modal-box">
                    <WalletModalHeader>
                        <div className=""></div>
                    </WalletModalHeader>

                    <WalletContentWrap>
                    <SuccessWalletComponent  displaybox={showComponent === 'success' ? 'block' : 'none'} handleShowComponent={setShowComponent}/>
                    </WalletContentWrap>
                </div>
            </PaymentBox>
        </OverallPaymentWrap>
    </>
  )
}

export default PayoutSuccessPage