import React, { useState, useEffect } from 'react'
// import {EditorState, convertRaw} from "draft-js"

import "./styles/InputText.css"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import {render} from "react-dom"

const InputText = ({setTextInput}: any) => {
  const [content, setContent] = useState("")
  const [id, setId] = useState("")
  const [picture, setPicture] = useState("")
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  const [saving, setSaving] =  useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTextInput(content)
  }, [content])



  return (
    <div className="input-text-wrapper">
        <div className="input-text-inner-wrapper">
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(newState: any) => {
                setEditorState(newState);
                setContent(draftToHtml(convertToRaw(newState.getCurrentContent())))
              }}
              toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true },
                history: { inDropdown: true },
                image: { inDropdown: true },
              }}
            />
        </div>
    </div>
  )
}

export default InputText