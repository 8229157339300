import React, { useEffect, useState } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { AdminProtected, AdminProvider, CustomersProtected, ProviderCustomer, ProvidersProtected } from '../components/AdminProtected'
import Protected from '../components/Protected'
import SmoothScroll from '../components/SmoothScroll'
import BlogEdit from '../pages/admin/BlogEdit'
import AdminBlogList from '../pages/admin/BlogListAdmin'
import BlogUpload from '../pages/admin/BlogUpload'
import Login from '../pages/auth/login'
import BlogPost from '../pages/user/BlogPost'
import { RootState, useAppDispatch, useAppSelector } from '../store'
import BlogHome from '../pages/user/BlogHome'
import Homepage from '../pages/user/Homepage'
import { darkTheme, lightTheme } from '../assets/styles/Theme'
import { GlobalStyle } from '../assets/styles/globalStyles'

import ServiceProviderAuth from '../pages/auth/CustomerSignUp'
import ProviderLogin from '../pages/Service_provider/Auth/Login/Login'

import Registration from '../pages/Service_provider/Registration/registration'
import CarRegistration from '../pages/Service_provider/CarRegistration/carRegistration'
import ProfileSetup from '../pages/Service_provider/ProfileSetup/profileSetup'
import InsuranceSetup from '../pages/Service_provider/InsuranceSetup/InsuranceSetup'
import DriverRegistration from '../pages/Service_provider/DriverRegistration/driverRegistration'

import CarBooking from '../pages/user/CarBooking'
import DriverBooking from '../pages/user/DriverBooking'
import DeliverBooking from '../pages/user/DeliveryBooking'
import GiftcardBooking from '../pages/user/GiftcardBooking'
import SearchPage from '../pages/user/SearchPage'
import TicketPage from '../pages/user/TicketPage'
import CartPage from '../pages/user/CartPage'
import TransactionSuccessPage from '../pages/user/TransactionSuccessPage'
import TransactionFailedPage from '../pages/user/TransactionFailedPage'
import TravelGaragePage from '../pages/user/TravelGarage'
import UserWalletPage from '../pages/user/UserWalletPage'
import UserBookingPage from '../pages/user/UserBookingPage'
import UserProfilePage from '../pages/user/UserProfilePage'
import UserEarningPage from '../pages/user/UserEarningPage'
import VProfile from '../pages/Service_provider/VehicleProfile/vehicleProfile'
import Dashboard from '../pages/Service_provider/Dashboard/dashboard'
import Wallet from '../pages/Service_provider/Wallet/wallet'
import AdminDashboard from '../pages/admin/Dashboard/dashboard'
// import Header from '../components/providerHeader/header'
// import BikeRegistration from '../pages/Service_provider/BikeRegistration/bikeRegistration'
import EditCarBookingPage from '../pages/user/EditCarBooking'
import EditDeliverBooking from '../pages/user/EditDeliveryBooking'
import SignUp from '../pages/auth/CustomerSignUp'
import CustomerSignUp from '../pages/auth/CustomerSignUp'
import BikeProfile from '../pages/Service_provider/BikeProfile/vehicleProfile'
import DriverProfile from '../pages/Service_provider/DriverProfile/DriverProfile'
import Header from '../components/providerHeader'
import ListVehicles from '../pages/admin/Vehicles/vehicles'
import ListDrivers from '../pages/admin/Drivers/drivers'
import ListRequests from '../pages/admin/Requests/requests'
import { useGetUserLocationQuery } from '../services/customerApi'
import { setCurrentLocation } from '../slices/currentLocation'
import ListProviders from '../pages/admin/Providers/providers'
import ListCustomers from '../pages/admin/Customers/customers'
import TransactionInsuranceSuccessPage from '../pages/user/TransactionInsuranceSuccessPage'
import EditDriverBooking from '../pages/user/EditDriverBooking'
import ReceiptPage from '../pages/user/ReceiptPage'
import PaymentLinkPage from '../pages/user/PaymentLinkPage'
import { useGetNextOfKinByProviderIdQuery } from '../services/providerApi'
import NextOfKin from '../pages/Service_provider/NextOfKin'
import { Guarantor } from '../pages/Service_provider/Guarantor/guarantor'
import BusinessScreen from '../pages/Service_provider/BusinessSetup'
import ListBusinessProviders from '../pages/admin/Business/providers'
import PayoutSuccessPage from '../pages/user/PayoutSuccessPage'
import Choose from '../pages/auth/ChooseOption/Choose'
import ForgotPassword from '../pages/auth/ForgotPassword/FirstScreen'
import Reset from '../pages/auth/ForgotPassword/ResetPassword'
import CheckMail from '../pages/auth/ForgotPassword/CheckMail'

function AppWrapper() {
  //USING AUTH
  const { id = '', token = '' } = useAppSelector<any>((store: RootState) => store?.auth?.auth) || {};
  const user_role = useAppSelector<any>((store: RootState) => store?.auth?.user_role) || '';


  const { data, isFetching, refetch, isSuccess } = useGetUserLocationQuery({ refetchOnFocus: true, refetchOnMountOrArgChange: true })
  // const { data: provider, isFetching: fetchProvider, refetch: refetchProvider, isSuccess: successProvider } = useGetNextOfKinByProviderIdQuery(id)

  const dispatch: any = useAppDispatch()
  const { theme } = useAppSelector<any>((store: RootState) => store?.theme)
  const [isSignedIn, setIsSignedIn] = useState(token?.length)
  const [providerData, setProviderData] = useState(null)






  useEffect(() => {
    refetch()
  }, [data])

  useEffect(() => {
    dispatch(setCurrentLocation(data?.data))
  }, [data, isSuccess])





  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyle />
      <Router>
        <SmoothScroll>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="blogHome" element={<BlogHome />} />
            <Route path='user-wallet-page' element={<UserWalletPage />} />
            <Route path='user-profile-page' element={<UserProfilePage />} />
            <Route path='user-booking-page' element={<UserBookingPage />} />
            <Route path='user-earning-page' element={<UserEarningPage />} />
            <Route path="login" element={<Login />} />
            <Route path="car-booking/:vehicleId" element={<CarBooking />} />
            <Route path="driver-booking" element={<DriverBooking />} />
            <Route path="delivery-booking" element={<DeliverBooking />} />
            <Route path="giftcard-booking" element={<GiftcardBooking />} />
            <Route path='search-page' element={<SearchPage />} />
            <Route path='ticket-page/:travelId/:vehicleId' element={<TicketPage />} />
            <Route path='cart' element={<CartPage />} />
            <Route path='transaction-success' element={<TransactionSuccessPage />} />
            <Route path='transaction-insurance-success' element={<TransactionInsuranceSuccessPage />} />
            <Route path='transaction-failed' element={<TransactionFailedPage />} />
            <Route path='travel-garage' element={<TravelGaragePage />} />
            <Route path="blogpost/:bloglink" element={<BlogPost />} />
            <Route path="edit-car-booking" element={<EditCarBookingPage />} />
            <Route path="edit-delivery-booking" element={<EditDeliverBooking />} />
            <Route path="edit-driver-booking" element={<EditDriverBooking />} />
            <Route path="receipt-page" element={<ReceiptPage />} />
            <Route path="payment-link/:link" element={<PaymentLinkPage />} />
            <Route path="payout-success" element={<PayoutSuccessPage />} />




              <Route path="choose-service" element={<Choose />} />


            {/* ADMIN PROTECTED ROUTES */}
            <Route path="/" element={<AdminProtected isSignedIn={isSignedIn} role={user_role} />}>
              <Route path='admin-blog-list' element={<AdminBlogList />} />
              <Route path='upload-blog-page' element={<BlogUpload />} />
              <Route path='edit-blog/:bloglink' element={<BlogEdit />} />
              <Route path='admin-dashboard' element={<AdminDashboard />} />
              <Route path='list-vehicles' element={<ListVehicles />} />
              <Route path='list-requests' element={<ListRequests />} />
              <Route path='list-drivers' element={<ListDrivers />} />
              <Route path='list-providers' element={<ListProviders />} />
              <Route path='list-business' element={<ListBusinessProviders />} />
              <Route path='list-customers' element={<ListCustomers />} />


            </Route>

            <Route path="/" element={<AdminProvider isSignedIn={isSignedIn} role={user_role} />}>

              <Route path="/vehicle-profile/:id/:user_id" element={<VProfile />} />
              <Route path='bike-profile/:id/:user_id' element={<BikeProfile />} />
              <Route path='driver-profile/:id/:user_id' element={<DriverProfile />} />
            </Route>

            <Route path="/" element={<ProviderCustomer isSignedIn={isSignedIn} role={user_role} />}>
              <Route path='individual-reg' element={<Registration />} />

              <Route path='car-registration' element={<><Header /><CarRegistration dash={false} /></>} />
              <Route path='driver-registration' element={<><Header /><DriverRegistration hideHeader dashboard={false} /></>} />
              <Route path='profile-setup' element={<ProfileSetup />} />

            </Route>



            <Route path='customer-signup' element={<CustomerSignUp />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route path='check-mail' element={<CheckMail />} />
            <Route path='password-reset/:id' element={<Reset />} />

            <Route path='provider-signup' element={<CustomerSignUp />} />


            {/* SERVICE PROVIDERS */}
            <Route path='profile-setup' element={<ProfileSetup />} />
            <Route path="/" element={<ProvidersProtected
              isSignedIn={isSignedIn} role={user_role}
            />}>

              <Route path='next-of-kin' element={<NextOfKin />} />
              <Route path='add-guarantor' element={<Guarantor />} />
              <Route path='add-business' element={<BusinessScreen />} />
              <Route path='insurance-setup' element={<InsuranceSetup />} />

              <Route path='provider-dashboard' element={<Dashboard />} />
              <Route path='provider-wallet' element={<Wallet />} />
            </Route>

          </Routes>
        </SmoothScroll>
      </Router>
    </ThemeProvider>
  )
}

export default AppWrapper