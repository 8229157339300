import React, { useEffect, useRef, useState } from "react";
import { PillWrapper } from "../../pages/user/Homepage/homepage.styles";
import {
  CancelModalBox,
  ModalBox,
  ModalOverlay,
  OptionPill,
  PillRadioBtn,
} from "../optionModal/optionmodal.styles";
import { CancelWrap } from "../itemSizeModal/itemsize.styles";
import {
  ArrowDownWrap,
  DestinationOverall,
  InputBtn,
  InputIcons,
  LocationBox,
  LocationInputWrap,
  LocationModalBox,
  SpecialReqBtn,
  UserLocationWrap,
  WhereOptionPill,
  WhereRadioPill,
  YellowBgArrowWrap,
} from "./locationmodal.styles";
import { SaveBtn } from "../extrasModal/extrasmodal.styles";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { setShowLocation } from "../../slices/showLocation";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import ReactWhatsapp from "react-whatsapp";

import whereTag from "../../assets/images/svg/where-tag.svg";
import greyBox from "../../assets/images/svg/greybox.svg";
import arrowDown from "../../assets/images/svg/arrowDown.svg";
import cancel from "../../assets/images/svg/cancel-yellow.svg";
import smiley from "../../assets/images/png/smiley.png";
import { useNavigate } from "react-router-dom";
import { setShowTimeDate } from "../../slices/showTimeDate";

const center = { lat: 6.605874, lng: 3.349149 };

const LocationModal = ({
  setLocationBtn,
  setOrigin,
  handleDestination,
  setTransportType,
  previousOrigin,
  previousDestination,
  withinOrWithout,
  states,
  setPrice,
  specialRequest,
}: any) => {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { showLocation } = useAppSelector<any>(
    (store: RootState) => store?.showLocation
  );

  const [userOrigin, setUserOrigin] = useState<any>("");
  const [userDestination, setUserDestination] = useState<any>("");
  const [map, setMap] = useState(null);
  const [directionResponse, setDirectionResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [where, setWhere] = useState("within");
  const [withinText, setWithinText] = useState("within");
  const [withoutText, setWithoutText] = useState("");
  const [choiceState, setChoiceState] = useState("");
  const [showLocationBox, setShowLocationBox] = useState("block");
  const [showErrorBox, setShowErrorBox] = useState("none");
  const [showAvailableState, setShowAvailableState] = useState("none");
  const [showFullModal, setShowFullModal] = useState(false);
  const [autocomplete, setAutocomplete] = useState<any>(null);
  const [autocompleteOrigin, setAutocompleteOrigin] = useState<any>(null);

  const originRef: any = useRef();

  const destinationRef: any = useRef();

  const libraries: (
    | "places"
    | "drawing"
    | "geometry"
    | "localContext"
    | "visualization"
  )[] = ["places", "geometry", "drawing"];

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
    libraries,
  });

  const onOriginPlaceChanged = () => {
    if (autocompleteOrigin !== null) {
      const places = autocompleteOrigin.getPlace().formatted_address;
      if (places) {
        console.log(
          autocompleteOrigin.getPlace(),
          "autocompleteOrigin.getPlace()"
        );
        setOrigin(places);
        setUserOrigin(places);
      } else {
        setOrigin(autocompleteOrigin.getPlace().name);
        setUserOrigin(autocompleteOrigin.getPlace().name);
      }
    }
  };

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const places = autocomplete.getPlace().formatted_address;
      if (places) {
        handleDestination(places);
        setUserDestination(places);
      } else {
        setUserDestination(autocomplete.getPlace().name);
        handleDestination(autocomplete.getPlace().name);
      }
    }
  };

  function getCommonWordsCount(address1, address2) {
    const words1 = address1.split(" ");
    const words2 = address2.split(" ");
    let commonWordsCount = 0;
    const wordsSet = new Set(words1);
    for (const word of words2) {
      if (wordsSet.has(word)) {
        commonWordsCount++;
      }
    }
    return commonWordsCount;
  }

  useEffect(() => {
    const commonWordsCount = getCommonWordsCount(userOrigin, userDestination);
    if (commonWordsCount > 1) {
      setWithinText("within")
      setWithoutText("") 
    } else {
      setWithinText("")
      setWithoutText("without")
    }
  }, [userOrigin, userDestination]);

  const locationModalControl = () => {
    dispatch(setShowLocation(false));
  };

  useEffect(() => {
    if (previousOrigin) {
      setUserOrigin(previousOrigin);
      if (withinOrWithout?.includes("Within")) {
        setWhere("within");
        setTransportType("Within state");
        setWithinText("within");
        setWithoutText("");
        setUserDestination(previousDestination);
      } else {
        setWhere("without");
        setTransportType("Out of state");
        setWithinText("");
        setWithoutText("without");
        setChoiceState(previousDestination);
      }
    }
  }, [previousOrigin, previousDestination, withinOrWithout]);

  useEffect(() => {
    locationModalControl();
  }, [showFullModal]);

  async function calculateRoute() {
    // @ts-ignore
    if (userOrigin === "" || userDestination === "") {
      return setLocationBtn(false);
    }
    const directionService = new google.maps.DirectionsService();
    const results = await directionService.route({
      // @ts-ignore
      origin: userOrigin,
      // @ts-ignore
      destination: userDestination,
      travelMode: google.maps.TravelMode.DRIVING,
    });

    // @ts-ignore
    setDirectionResponse(results);
    // @ts-ignore
    setDistance(results.routes[0].legs[0].distance.text);
    // @ts-ignore
    setDuration(results.routes[0].legs[0].duration.text);

    setShowFullModal(!showFullModal);

    setOrigin(userOrigin);
    handleDestination(userDestination);
    setLocationBtn(true);
    dispatch(setShowTimeDate(true));
  }

  const setLocationWithin = () => {
    setWhere("within");
    setTransportType("Within state");
    setWithinText("within");
    setWithoutText("");
  };

  const setLocationWithout = () => {
    setWhere("without");
    setTransportType("Out of state");
    setWithinText("");
    setWithoutText("without");
  };

  const handleSelectState = (selectedState: any) => {
    setChoiceState(selectedState?.destination);
    handleDestination(selectedState?.destination);
    setPrice(Number(selectedState?.price));
    setShowLocationBox("block");
    setShowAvailableState("none");
    setShowErrorBox("none");
  };

  const handleShowAvailableState = () => {
    if (states?.length !== 0) {
      setShowLocationBox("none");
      setShowAvailableState("block");
      setShowErrorBox("none");
    } else {
      setShowLocationBox("none");
      setShowAvailableState("none");
      setShowErrorBox("block");
    }
  };

  const handleShowLocationBox = () => {
    setShowLocationBox("block");
    setShowAvailableState("none");
    setShowErrorBox("none");
  };

  const handleTavelLocation = () => {
    if (userOrigin?.length !== 0 && choiceState?.length !== 0) {
      setOrigin(userOrigin);
      setLocationBtn(true);
      setShowFullModal(!showFullModal);
    }
  };

  const handleSpecialRequest = () => {
    specialRequest();
    dispatch(setShowLocation(false));
    navigate("/");
  };

  return (
    <div>
      <ModalOverlay activeModal={showLocation}>
        <CancelModalBox onClick={() => locationModalControl()} />

        {/*  */}
        <LocationModalBox
          boxDisplay={showLocationBox}
          onClick={() => {
            dispatch(setShowLocation(true));
          }}
        >
          <div className="inner-modal-box">
            {/* GOOGLE MAP BOX */}
            <LocationBox>
              {/* Google map box */}
              {/*@ts-ignore */}
              <GoogleMap
                center={center}
                zoom={10}
                mapContainerStyle={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "14px",
                }}
                options={{
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                }}
              >
                {/* <Marker position={center} /> */}
                {directionResponse && (
                  <DirectionsRenderer directions={directionResponse} />
                )}
              </GoogleMap>
            </LocationBox>

            <UserLocationWrap>
              <h4>Where</h4>

              {/* SET WHETHER IT'S WITHIN STATE OR OUT OF STATE */}
              <div className="where-pill-wrap">
                <WhereOptionPill onClick={setLocationWithin}>
                  <WhereRadioPill pillActive={withinText} />

                  <p>Within State</p>
                </WhereOptionPill>

                <WhereOptionPill onClick={setLocationWithout}>
                  <WhereRadioPill pillActive={withoutText} />

                  <p>Travel out of state</p>
                </WhereOptionPill>
              </div>

              <div className="location-input-overall">
                <label>Pickup location</label>

                {/* USERS PICKUP LOCATION */}
                <LocationInputWrap>
                  <InputIcons src={whereTag} iconWidth={"30px"} />
                  <Autocomplete
                    className="autocomplete-wrap"
                    onPlaceChanged={onOriginPlaceChanged}
                    onLoad={(value) => setAutocompleteOrigin(value)}
                  >
                    <input
                      type="text"
                      placeholder="Type here"
                      onChange={(e: any) => {
                        setUserOrigin(e.target.value);
                        setOrigin(e.target.value);
                      }}
                      value={userOrigin}
                    />
                  </Autocomplete>
                </LocationInputWrap>

                {/* DESTINATION FOR WITHIN STATE */}
                <DestinationOverall
                  display={where === "within" ? "block" : "none"}
                >
                  <label>Farthest destination</label>
                  <LocationInputWrap>
                    <InputIcons src={greyBox} iconWidth={"30px"} />

                    <Autocomplete
                      className="autocomplete-wrap"
                      onPlaceChanged={onPlaceChanged}
                      onLoad={(value) => setAutocomplete(value)}
                    >
                      <input
                        type="text"
                        placeholder="Type here"
                        onChange={(e: any) => {
                          setUserDestination(e.target.value);
                          handleDestination(e.target.value);
                        }}
                        value={userDestination}
                      />
                    </Autocomplete>

                    <InputBtn onClick={calculateRoute}>
                      <p>Done</p>
                    </InputBtn>
                  </LocationInputWrap>
                </DestinationOverall>

                {/* DESTINATION FOR OUT OF STATE */}
                <DestinationOverall
                  display={where !== "within" ? "block" : "none"}
                >
                  <label>Travel Destination</label>
                  <LocationInputWrap>
                    <InputIcons src={greyBox} iconWidth={"30px"} />
                    <input
                      type="text"
                      placeholder="Select Destination"
                      defaultValue={choiceState}
                      onClick={handleShowAvailableState}
                      readOnly
                    />

                    <YellowBgArrowWrap onClick={handleShowAvailableState}>
                      <InputIcons src={arrowDown} iconWidth={"30px"} />
                    </YellowBgArrowWrap>

                    {choiceState?.length !== 0 && (
                      <InputBtn onClick={() => handleTavelLocation()}>
                        <p>Done</p>
                      </InputBtn>
                    )}
                  </LocationInputWrap>
                </DestinationOverall>
              </div>
            </UserLocationWrap>
          </div>
        </LocationModalBox>

        {/* ERROR BOX */}
        <LocationModalBox boxDisplay={showErrorBox}>
          <div className="inner-modal-box">
            <CancelWrap>
              <div className="" onClick={handleShowLocationBox}>
                <img src={cancel} alt="" />
              </div>
            </CancelWrap>

            <div className="header-text">
              <h2>Ops!</h2>
            </div>

            <div className="content-wrap">
              <p>
                This vehicle owner is not available for out of state travel at
                the moment
              </p>

              <InputIcons src={smiley} iconWidth={"140px"} />
            </div>

            <SpecialReqBtn onClick={handleSpecialRequest}>
              <ReactWhatsapp
                number="+2349044442200"
                message="Please, I want to make a Car booking special request"
              >
                Make A Special Request
              </ReactWhatsapp>
            </SpecialReqBtn>
          </div>
        </LocationModalBox>

        {/* STATES AVAILABLE BOX */}
        <LocationModalBox boxDisplay={showAvailableState}>
          <div className="inner-modal-box">
            {states?.map((item: any, index: any) => {
              return (
                <OptionPill key={index} onClick={() => handleSelectState(item)}>
                  <PillRadioBtn
                    activePill={choiceState === item?.destination ? "fill" : ""}
                  />
                  <p>{item?.destination}</p>
                </OptionPill>
              );
            })}

            <div className="redirect-link" onClick={handleSpecialRequest}>
              <ReactWhatsapp
                number="+2349044442200"
                message="Please, I want to make a Car booking special request"
              >
                Didn't see your state? Click to make a special reques.
              </ReactWhatsapp>
            </div>
          </div>
        </LocationModalBox>
      </ModalOverlay>
    </div>
  );
};

export default LocationModal;
