import React, { useEffect, useState } from "react";
import AssetCard from "../../../components/assetCard";
import Carousel from "../../../components/carousel";
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import OffersCard from "../../../components/offersCard";
import OwnersNoteCard from "../../../components/ownersNoteCard";
import ProgressBar from "../../../components/progressBar";
import RoadlersCard from "../../../components/roadlersCard";
import { ToastContainer, toast } from "react-toastify";

import {
  SearchOverall,
  SearchInput,
  CarouselSection,
  BookingQuestionsWrap,
  QuestionHeader,
  QuestionBox,
  PP,
  QuestionImg,
  ModalCallBtnWrap,
  ModalCallBtn,
  BookingFooterWrap,
  BookingFooterBtn,
  FeatureWrapper,
  SubFeatureWrap,
  TickBox,
} from "../CarBooking/CarBooking.styles";
import {
  SearchBtn,
  BoxExtras,
  H11,
  AssetOverall,
} from "../Homepage/homepage.styles";
import {
  BtnWrap,
  ExtrasBox,
  MailIconBox,
  MailWrap,
  NameInput,
  NameInputWrap,
  TicketDateBox,
  TicketInfoBox,
  TicketInfoWrap,
  TicketPageWrap,
} from "./ticketpage.styles";

import car1 from "../../../assets/images/svg/carousel1.svg";
import car2 from "../../../assets/images/svg/carousel2.svg";
import car3 from "../../../assets/images/svg/carousel3.svg";
import seat from "../../../assets/images/png/seat.png";

import arrowDown from "../../../assets/images/svg/arrowDown.svg";
import seatYellow from "../../../assets/images/png/seatYellow.png";
import whenTag from "../../../assets/images/svg/when-tag.svg";
import noteTag from "../../../assets/images/svg/note-tag.svg";
import tickX from "../../../assets/images/svg/tickx-icon.svg";
import tickR from "../../../assets/images/svg/tickr-icon.svg";
import filledMail from "../../../assets/images/svg/filled-mailbox.svg";
import yellowCalendar from "../../../assets/images/svg/yellow-calendar.svg";
import { InputIcons } from "../../../components/locationModal/locationmodal.styles";
import SearchComponent from "../../../components/searchComponent";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetTravelByIdQuery,
  useGetVehicleByIdQuery,
} from "../../../services/providerApi";
import moment from "moment";
import { addToCart } from "../../../slices/cart";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import uuid from "react-uuid";
import { setShowExtraNote } from "../../../slices/showExtraNote";
import ExtrasModal from "../../../components/extrasModal";
import AddPassengerModal from "../../../components/travelGarage/addPassenger";
import ViewPassengerModal from "../../../components/travelGarage/viewPassengers";
import {
  clearPassengers,
  setPassengers,
} from "../../../slices/travelPassengers";

const TicketPage = () => {
  const params: any = useParams();
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();

  const { passengers } = useAppSelector<any>(
    (store: RootState) => store?.travel
  );

  const extraModalControl = () => {
    dispatch(setShowExtraNote(true));
  };

  const {
    data: TravelData,
    isFetching: isFetchingTravelData,
    isLoading: isLoadingTravelData,
    refetch: refetchTravelData,
    error: errorTravelData,
  } = useGetTravelByIdQuery(params?.travelId, {
    refetchOnMountOrArgChange: true,
  });
  const [vehicleInfoArray, setVehicleInfoArray] = useState<any>();
  const [addPassengerModal, setAddPassengerModal] = useState<any>(false);
  const [viewPassengerModal, setViewPassengerModal] = useState<any>(false);

  const [travelInfo, setTravelInfo] = useState<any>();
  const [bookingAmount, setBookingAmount] = useState<any>(Number(TravelData?.data[0]?.price));
  const [seatNumber, setSeatNumber] = useState<any>();

  const { data, isFetching, isLoading, refetch, error } =
    useGetVehicleByIdQuery(params?.vehicleId, {
      refetchOnMountOrArgChange: true,
    });

  useEffect(() => {
    refetch();
    setVehicleInfoArray(data);
  }, [params?.vehicleId]);

  useEffect(() => {
    refetchTravelData();
    setTravelInfo(TravelData);
  }, [TravelData]);

  useEffect(() => {
    if (TravelData && TravelData?.data[0]?.price) {
      setBookingAmount(TravelData?.data[0]?.price);
    }
  }, [TravelData]);
  useEffect(() => {
    setSeatNumber(passengers?.length);
  }, [passengers]);

  const slides: any = [
    { img: `${vehicleInfoArray?.data[0]?.image1}`, name: "Main Image", id: 0 },
    { img: `${vehicleInfoArray?.data[0]?.image2}`, name: "Inner Image", id: 1 },
    { img: `${vehicleInfoArray?.data[0]?.image5||vehicleInfoArray?.data[0]?.image4}`, name: "Back Image", id: 2 },
  ];
  const [carOwnerInstruction, setCarOwnerInstruction] = useState("");
  const [insuranceAmount, setInsuranceAmount] = useState<number>(0);
  const [insuranceType, setInsuranceType] = useState("");


  
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const bookingDetails: any = {
      pickup_date: [
        moment(travelInfo?.data[0]?.departure_date, "YYYY-MM-DD").format(
          "MM/DD/YYYY"
        ),
      ],
      pickup_time: travelInfo?.data[0]?.departure_time,
      pickup_location: travelInfo?.data[0]?.pickup_address,
      destination: travelInfo?.data[0]?.destination,
      pickup_address: travelInfo?.data[0]?.pickup_address,
      booking_id: uuid(),
      image: vehicleInfoArray?.data?.[0]?.image1,
      category: "Travel Garage Booking",
      amount: Number(bookingAmount),
      plate_number: vehicleInfoArray?.data?.[0]?.plate_number,
      vehicle_id: vehicleInfoArray?.data?.[0]?.id,
      provider_id: vehicleInfoArray?.data?.[0]?.user_id,
      booking_title:
        vehicleInfoArray?.data?.[0]?.vehicle_name +
        " " +
        vehicleInfoArray?.data?.[0]?.year,
      vehicle_year: vehicleInfoArray?.data?.[0]?.year,
      total: Number(bookingAmount),
      tripPrice: Number(bookingAmount),
      booking_price: Number(bookingAmount),
      num_of_seats: seatNumber,
      insurance_amount: insuranceAmount,
      insurance: insuranceType,
      currency_code: travelInfo?.data[0]?.currency,

      
      book_picture: vehicleInfoArray?.data?.[0]?.image1,
      from: travelInfo?.data[0]?.from,
      to: travelInfo?.data[0]?.to,
      departure_date: travelInfo?.data[0]?.departure_date,
      departure_time: travelInfo?.data[0]?.departure_time,
      passenger: passengers,
      travel_id: params?.travelId,
    };
    dispatch(addToCart(bookingDetails));
    dispatch(clearPassengers());

    navigate("/cart");
  };

  const increaseSeat = () => {
    if (seatNumber < Number(travelInfo?.data[0]?.seat_count)) {
      setAddPassengerModal(true);
    } else {
      notifyError("You Can't book beyond the available seats");
    }
  };
  const decreaseSeat = () => {
    setViewPassengerModal(true);
  };

  useEffect(() => {
    setBookingAmount(
      TravelData?.data[0]?.price * seatNumber + seatNumber * insuranceAmount
    );
  }, [seatNumber, insuranceAmount]);

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  return (
    <TicketPageWrap>
      <ToastContainer />

      {addPassengerModal && (
        <AddPassengerModal
          addPassengerModal={addPassengerModal}
          setAddPassengerModal={setAddPassengerModal}
        />
      )}

      {viewPassengerModal && (
        <ViewPassengerModal
          viewPassengerModal={viewPassengerModal}
          setViewPassengerModal={setViewPassengerModal}
        />
      )}

      <ExtrasModal
        handleInstruction={setCarOwnerInstruction}
        instruction={carOwnerInstruction}
        insuranceAmount={insuranceAmount}
        setInsuranceAmount={setInsuranceAmount}
        insuranceType={insuranceType}
        setInsuranceType={setInsuranceType}
        insuranceLabel={
          "Would you like to be covered by insurance on this trip?"
        }
      />

      <Header />

      <SearchComponent />

      <CarouselSection bg={"transparent"}>
        <Carousel
          slides={slides}
          display={"block"}
          priceDisplay={"block"}
          currencyCode={travelInfo?.data[0]?.currency}
          title={vehicleInfoArray?.data[0].vehicle_name}
          displayAmount={Number(bookingAmount)?.toLocaleString("en-US", {
            maximumFractionDigits: 2,
          })}
        />
      </CarouselSection>

      <TicketInfoWrap border={"#4F4F4F"}>
        <TicketInfoBox border={"transparent"}>
          <h3>Depature</h3>
          <p>
            {moment(travelInfo?.data[0]?.departure_time, "HH:mm").format(
              "hh:mm a"
            )}
          </p>
        </TicketInfoBox>

        {/* <TicketInfoBox border={"#4F4F4F"}>
          <h3>Arrival</h3>
          <p>12 PM</p>
        </TicketInfoBox> */}
        <div className="border"></div>

        <TicketInfoBox border={"#4F4F4F"}>
          <h3>Passengers</h3>
          <p>{travelInfo?.data[0]?.seat}</p>
        </TicketInfoBox>
        <div className="border"></div>

        <TicketDateBox>
          <InputIcons src={yellowCalendar} style={{ width: "12px" }} />

          <p> {travelInfo?.data[0]?.departure_date}</p>
        </TicketDateBox>

        <TicketDateBox>
          <InputIcons src={seatYellow} style={{ width: "12px" }} />

          <p>{travelInfo?.data[0]?.seat_count} Seats Left</p>
        </TicketDateBox>
      </TicketInfoWrap>

      <div className="" style={{ padding: "30px", paddingRight: "80px" }}>
        <h3>Book A Seat</h3>

        <BtnWrap style={{ marginTop: 30 }}>
          <div className="seats">
            <h6>{seatNumber}</h6>
            <img src={seat} alt="" />
            <button onClick={decreaseSeat}>-</button>
            <button onClick={() => increaseSeat()}>+</button>
          </div>
          <div className="extra">
            <ExtrasBox onClick={() => extraModalControl()}>
              <InputIcons src={noteTag} iconWidth={"20px"} />
              <p>Extra/Note</p>
            </ExtrasBox>
          </div>
        </BtnWrap>

        <BookingFooterWrap>
          <BookingFooterBtn text={"#fff"} bordercolor={"#fff"} bg={"#000"}>
            {travelInfo?.data[0]?.currency}{" "}
            {Number(bookingAmount).toLocaleString("en-US", {
              maximumFractionDigits: 2,
            })}
          </BookingFooterBtn>

          {seatNumber < 1 ? (
            <BookingFooterBtn
              text={"#000"}
              bg={"#bbb"}
              onClick={()=>notifyError("You need to add at least a passenger before you can proceed")}

            >
              Proceed Booking
            </BookingFooterBtn>
          ) : (
            <BookingFooterBtn
              text={"#000"}
              bordercolor={"#ffa100"}
              bg={"#ffa100"}
              onClick={handleSubmit}
            >
              Proceed Booking
            </BookingFooterBtn>
          )}
        </BookingFooterWrap>
      </div>

      <FeatureWrapper>
        <h4>Feature</h4>

        <SubFeatureWrap>
          <TickBox>
            <QuestionImg
              width={"15px"}
              src={vehicleInfoArray?.data[0].air_con === "Yes" ? tickR : tickX}
            />
          </TickBox>

          <p>Air Conditioner Working</p>
        </SubFeatureWrap>

        <SubFeatureWrap>
          <TickBox>
            <QuestionImg width={"15px"} src={tickR} />
          </TickBox>

          <p>Available for travel </p>
        </SubFeatureWrap>

        <SubFeatureWrap>
          <TickBox>
            <QuestionImg width={"15px"} src={tickR} />
          </TickBox>

          <p>Available Per Hour</p>
        </SubFeatureWrap>

        <SubFeatureWrap>
          <TickBox>
            <QuestionImg
              width={"15px"}
              src={
                vehicleInfoArray?.data[0].come_with_driver === "Yes"
                  ? tickR
                  : tickX
              }
            />
          </TickBox>

          <p>Comes with a driver </p>
        </SubFeatureWrap>

        {/* <SubFeatureWrap>
          <TickBox>
            <QuestionImg width={"15px"} src={tickX} />
          </TickBox>

          <p>Fully fuelled.</p>
        </SubFeatureWrap> */}

        {/* <SubFeatureWrap>
          <TickBox>
            <QuestionImg width={"15px"} src={tickX} />
          </TickBox>

          <p>Available for self drive</p>
        </SubFeatureWrap> */}

        <SubFeatureWrap>
          <TickBox>
            <QuestionImg width={"15px"} src={tickX} />
          </TickBox>

          <p>Available for overnight trips</p>
        </SubFeatureWrap>

        <SubFeatureWrap>
          <TickBox>
            <QuestionImg width={"15px"} src={tickX} />
          </TickBox>

          <p>Comprehensive insurance</p>
        </SubFeatureWrap>

        <SubFeatureWrap>
          <TickBox>
            <QuestionImg width={"15px"} src={tickX} />
          </TickBox>

          <p>Verified </p>
        </SubFeatureWrap>
      </FeatureWrapper>

      <BoxExtras>
        <OwnersNoteCard />

        <RoadlersCard />
      </BoxExtras>

      {/* <BoxExtras>
        <div className="subsection-header">
          <H11>Related Offers</H11>
        </div>

        <AssetOverall>
          <AssetCard />
          <AssetCard />
          <AssetCard />
          <AssetCard />
          <OffersCard />
          <OffersCard />
        </AssetOverall>
      </BoxExtras> */}

      <Footer />
    </TicketPageWrap>
  );
};

export default TicketPage;
