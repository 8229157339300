import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import bike from '../../../assets/images/png/bike.png'
import deletes from '../../../assets/images/png/delete.png'
import { IoLocationSharp } from 'react-icons/io5';
import { BiPencil } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';



import {
    Div, FlexDiv, H6, Label, P, ImageDiv, ImageDetails, H5, Flex, LocationSpan
} from './deliveryBikes.styles';
import { useAppDispatch, useAppSelector } from '../../../store';
import axios from 'axios';
import { setAllBike } from '../../../slices/provider';
import { Link } from 'react-router-dom';
import { DeleteModal } from '../deleteModal';
const Bikes: FC<{ bikeDetails: any }> = ({ bikeDetails }) => {

    const token = useAppSelector((state: any) => state?.auth?.auth?.token)
    const [isDelete, setIsDelete] = useState<boolean>(false)
    const handleDeleteModal = async () => {
        setIsDelete(prev => !prev)
    }
    const dispatch = useAppDispatch()
 
    return (
        <>
           
            <Div>
            {
                isDelete && <DeleteModal
                    id={bikeDetails?.id}
                    setIsDelete={setIsDelete}
                    isVehicle={true}
                />
            }
                <FlexDiv style={{ marginBottom: '2px', padding: '0px 0px 0px 10px' }}>
                    <Label>Plate Number: {bikeDetails?.plate_number}</Label>
                </FlexDiv>
                <ImageDiv >
                    <div style={{
                        borderRadius: '12px',
                        overflow: 'hidden',
                        backgroundImage: `url(https://testapi.roadlers.com/storage/${bikeDetails?.image1 || bike})`,
                        backgroundRepeat: 'no-repeat',
                        height: '120px',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundOrigin: 'content-box'
                    }}>

                    </div>
                    <ImageDetails>
                        <H5>{bikeDetails?.vehicle_name} {" " + bikeDetails?.year}</H5>


                        <LocationSpan>
                            <IoLocationSharp style={{ color: 'red', fontSize: '14' }} />
                            <span style={{ fontWeight: 'bold', fontSize: '9px' }}>{bikeDetails?.location}</span>
                        </LocationSpan>

                        <H6>Rider Detail</H6>
                        <P>{bikeDetails?.firstname} {bikeDetails?.lastname}</P>
                        <Label>{bikeDetails?.phone_number}</Label>

                        <button onClick={handleDeleteModal} style={{ background: 'transparent', border: 'none', position: 'absolute', right: '0px', bottom: '32px' }}>
                            {/* <img src={deletes} alt="" width={12} /> */}
                            <RiDeleteBinLine style={{ color: '#fff', fontSize: '15px' }} />

                        </button>
                        <Flex style={{ position: 'absolute', right: '5px', width: '30px', bottom: '12px', padding: '2px 5px', borderRadius: '3px' }} >
                            {/* <BiPencil style={{ fontSize: '12px' }} /> */}
                            <label style={{ fontSize: '11px' }}>
                                <Link to={"/vehicle-profile/" + bikeDetails?.id + "/" + bikeDetails?.user_id} style={{ color: 'white' }}>

                                    Edit
                                </Link>
                            </label>

                        </Flex>
                    </ImageDetails>
                </ImageDiv>

            </Div>

        </>

    )
}

export default Bikes