import styled from 'styled-components'


export const DOCDIV = styled.div`
display:flex;
width:100%;
gap:10px;
margin-top:30px;

`
export const DOC= styled.div`
width:40%;
display:flex;
flex-direction:column;

`
export const DOC1 = styled.div`
display:flex;
background: #FF3A3A;
width:85%;
border-radius:12px;
height:110px;
justify-content:center;
align-items:center;
position:relative;
color:#fff;
overflow:hidden;
`

export const H44 = styled.h4`
font-weight:500;
color:#FFF;
margin-top:5px;
`

export const P2 = styled.p`
font-weight:500;
color:#fff;
font-size:11px;
text-align:left;
`
export const DIV = styled.div`
display:flex;
gap:20px;
justify-content:space-between;
flex-direction:column;

width:100%;

`
export const InputDiv = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
background:white;
width:100%;
border-radius:7px;
overflow:hidden;
margin-top:3px;

`
export const Input = styled.input`

background:white;
width:100%;
border-radius:9px;
padding:20px 15px;
margin-top:3px;
border:none;
outline:none;
font-size:14px;
`
export const Label = styled.label`
font-size:13px;
color:#fff;
margin-left:30px;
`
export const FlexDiv = styled.div`
display:flex;
width:100%;
gap:5px;
background:#fff;
border-radius:7px;
overflow:hidden;
border:none;
margin-top:3px;

`
export const IconDiv = styled.div`
background:#000;
color:#ccc;
width:22%;
display:flex;
align-items:center;
justify-content:center;
font-size:35px;
border-radius:0px 8px 8px 0px;
`



export const Button = styled.button`
background: #FFA100;
color: #000;
width:100%;
border-radius:30px;
padding:15px 10px;
margin-top:3rem;
width:55%;
outline:none;
border:none;
font-size:16px;
font-weight:bold;
`