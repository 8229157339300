import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  showFilter: false
};
const showFilterSlice = createSlice({
  name: 'showFilter',
  initialState,
  reducers: {
    setShowFilter: (state, action) => {
      return { ...state, showFilter: action.payload };
    },
    clearShowFilter: () => {
      return { showFilter: false };
    }
  }
});

const { reducer, actions } = showFilterSlice;
export const { setShowFilter, clearShowFilter} = actions;
export default reducer;