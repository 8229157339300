import styled from 'styled-components'



export const Div = styled.div`
padding:0em 2em;
margin-top:50px;
`

export const DriverDiv = styled.div`
padding:0 1em;
margin-top:70px;
@media only screen and (min-width: 900px) {
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:1em;
    }

`
export const PaginationDiv = styled.div`
width:100%;
margin:50px auto;
display:flex;
flex-wrap:wrap;
justify-content:center;
gap:4px;

`

export const PaginationLabel = styled.button`
background:transparent;
font-weight:bold;
font-size:15px;
color:#000;
outline:none;
border:none;
border-radius:3px;
display:flex;
justify-content:center;
align-items:center;
`
export const PaginationButton = styled.button`
background: ${(props:any) => props.disabled ? "#ffa100" : "#EAEAEA"};

padding:4px 7px;
font-weight:bold;
font-size:13px;
color:#000;
outline:none;
border:none;
border-radius:3px;
display:flex;
justify-content:center;
align-items:center;
`








