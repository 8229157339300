import React, { useEffect, useState } from 'react'
import { FlexInputWrap } from '../fundWalletThree/fundwalletthree.styles'

import withdraw from '../../assets/images/svg/withdraw-icon.svg'
import { DropdownContent, DropdownWrap } from './dropdown.styles'
import { useGetBankListQuery } from '../../services/walletApi'

const DropDown = ({setWithdrawBank, withdrawBank, bankCode, setBankCode}: any) => {
    const [dropdownActive, setDropdownActive] = useState(false)
    const [receivedBankList, setReceivedBankList] = useState<any []>([])

    const {data, refetch, isSuccess} = useGetBankListQuery()

    useEffect(() => {
        refetch()
    }, [data])

    useEffect(() => {
        if(isSuccess) {
            setReceivedBankList(data?.data)
        }
    }, [data])

    const handleBankChosen = (item:any) => {
        setWithdrawBank(item?.name)
        setBankCode(item?.code)
    }

  return (
    <DropdownWrap onClick={() => setDropdownActive(!dropdownActive)}>
        <p> { withdrawBank || <span>Bank Name</span>}</p>
        <img src={withdraw} style={{width:'24px'}} />

        <DropdownContent active={dropdownActive}>
            {receivedBankList?.map((item:any, id:any) => 
                    <div className="content-text" key={id} onClick={() => handleBankChosen(item)}>
                        {item?.name}
                    </div>
            )}
        </DropdownContent>
    </DropdownWrap>
  )
}

export default DropDown