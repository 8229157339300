import React, { FC, useEffect, useState, useRef } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { RANGEDIV, SPAN, Container, Button, BUTTON, IMAGEDIV, Page, DIV, H4, H3, LoaderContainer, DivContainer, Loader, Loader2, DisabledButton, UploadButton } from './driverRegistration.styles';
import Header from '../../../components/providerHeader';

import VehicleDoc from '../../../components/providerCarReg/vehicleDoc/vehicleDoc'
import blueCar from '../../../assets/images/png/blueCar.png'
import whiteMark from '../../../assets/images/png/whiteMark.png'
import FirstScreen from '../../../components/providerDriverReg/firstScreen/firstScreen';
import SecondScreen from '../../../components/providerDriverReg/secondScreen/secondScreen';
import ThirdScreen from '../../../components/providerDriverReg/thirdScreen/thirdScreen';
import FourthScreen from '../../../components/providerDriverReg/fourthScreen/fourthScreen';
import { useNavigate } from 'react-router-dom';
import { useDriverPictureUpdateMutation, useDriverRegistrationMutation } from '../../../services/providerApi';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from '../../../store';
import FirstScreenDriver from '../../../components/providerDriverReg/firstScreen/firstScreen';
import { ArrowDiv, CancelButton } from '../../../components/providerCarReg/carImageComponent/index.styles';
import { setDriverInfo, setServiceOffering, setUploadedDriverInfo } from '../../../slices/provider';
import { PopupModal } from './Popup';
import { PagePadding } from '../../admin/Dashboard/dashboard.styles';
import Compressor from 'compressorjs';
import { setRole } from '../../../slices/auth';
import { IoIosArrowBack } from 'react-icons/io';

type Props = {
    hideHeader: boolean;
    dashboard: boolean;
    // setShowFirstScreen: (val: boolean) => void;
};
const DriverRegistration: FC<Props> = ({ hideHeader, dashboard }) => {

    const [driverPictureUpdate, { data: pictureData, isLoading: PictureLoading, isSuccess: PictureSuccess, isError: PictureIsError, error: PictureError }] = useDriverPictureUpdateMutation()
    const imageRef: any = useRef()

    const [image1, setImage1] = useState<any>();
    const [image1Prev, setImage1Prev] = useState<any>()
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState()
    const refDiv = React.useRef<HTMLInputElement>(null);
    const [width, setWidth] = useState<number>(0)
    let [question, setQuestion] = useState(1)
    const [allQuestion, setAllQuestion] = useState(4)
    let [component, setComponent] = useState(1)
    const [btnLoad, setBtnLoad] = useState(false)
    const navigate = useNavigate()

    const user = useAppSelector((state: any) => state?.auth?.auth)
    const driverInfo: any = useAppSelector((state: any) => state?.provider?.driverInfo)
    const driverInfos = useAppSelector((state: any) => state?.provider?.uploadedDriverInfo)

    const dispatch: any = useAppDispatch()


    useEffect(() => {
        dispatch(setServiceOffering('Driver'))
    }, [])

    const [driverDetails, setDriverDetails] = useState({
        firstname: driverInfo?.firstname || '',
        user_id: user?.id,
        charge:30,
        lastname: driverInfo?.lastname || '',
        address: driverInfo?.address || '',
        latitude: driverInfo?.latitude || '',
        longitude: driverInfo?.longitude || '',
        phone_number: driverInfo?.phone_number || '',
        availability: driverInfo?.availability || driverInfo?.availability1 || [],
        travel_trip: driverInfo?.travel_trip || [],
        service_price: [
            {
                price: 0,
                hour: 1,
                discount: 0,
                service_title: 'Hourly Rate'
            },
            {
                price: 0,
                hour: 12,
                discount: 0,
                service_title: 'Full Day Service'
            },
            {
                price: 0,
                hour: 3,
                discount: 0,
                service_title: 'All Night Service'
            },

            {
                price: 0,
                hour: 1,
                discount: 0,
                service_title: 'Weekly Service'
            },
            {
                price: 0,
                hour: 1,
                discount: 0,
                service_title: 'Monthly Service'
            }
        ],
    });

    const handleNextComponent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        if (component === 1) {
            if (driverDetails?.firstname === '') {
                setComponent(0)
                setQuestion(0)
                notifyError("What is your Driver's Firstname?")

            } else if (driverDetails?.lastname === '') {
                setComponent(0)
                setQuestion(0)
                notifyError("What is your Driver's Lastname?")
            } else if (driverDetails?.address === '') {
                setComponent(0)
                setQuestion(0)
                notifyError("What is your Driver's Address?")
            }
        }

        if (component === 2) {
            if (driverDetails?.phone_number?.length < 14) {
                setComponent(1)
                setQuestion(1)
                notifyError("Phone number format incorrect")
            }

        }
        if (component === 3) {
            if (driverDetails?.availability?.length < 1) {
                setComponent(2)
                setQuestion(2)
                notifyError("Please Select an Availability Option")
            }

        }
        setComponent((prev) => prev + 1)
        setQuestion((prev) => prev + 1)
    };
    const handlePrevComponent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setComponent((prev) => prev - 1)
        setQuestion((prev) => prev - 1)

    };


    const [addDriver, { data: driverData, isLoading, isSuccess, isError, error }] = useDriverRegistrationMutation()
    useEffect(() => {
        if (isLoading) {
            setBtnLoad(true)
        }
        if (isSuccess) {
            dispatch(setUploadedDriverInfo(driverData?.data[0]))

            setBtnLoad(false)
            notifySuccess('Driver Image Upload Unlocked, Now upload your Driver Image')
            imageRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start' // or 'end'
            });
        }
        if (isError && 'status' in error!) {
            if (isError && error.status.toString() === 'FETCH_ERROR') {
                notifyError('Network Connection Failed')
            }
            setBtnLoad(false)
        }


    }, [isLoading, isSuccess, isError, driverData, error])







    const accountType: any = useAppSelector((state: any) => state?.provider.account_type)

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const file: any = event.currentTarget.files;
        if (file != null) {
            setImage1Prev(file);
            let reader = new FileReader();
            reader.onload = (e) => {
                if (e.target != null) {
                    setImage1Prev(e.target.result);
                }

            };
            reader.readAsDataURL(file[0]);
        }

        new Compressor(file[0], {
            quality: 0.6,
            success: (compressedResult: any) => {
                const formData = new FormData();
                formData.append('id', driverInfos?.id);
                formData.append('driver_picture', compressedResult);

                //@ts-ignore
                driverPictureUpdate(formData).then((response: any) => {
                    dispatch(setRole('provider'))
                    if (response?.data?.message === 'success') {
                        notifySuccess('Driver Image Saved')

                        navigate('/profile-setup')


                    }
                    else {
                        notifyError('Something went wrong')

                    }

                }).catch((err) => {
                })
            },
        });

    };

    const address = driverDetails?.address
    const geocoder = new window.google.maps.Geocoder();

    useEffect(() => {
        geocoder.geocode({ address }, (result: any, status) => {
            if (status === "OK") {

                setDriverDetails({
                    ...driverDetails,
                    latitude: result[0]?.geometry.location.lat(),
                    longitude: result[0]?.geometry.location.lng()
                });
            } else {
                console.log("Geocode was not successful for the following reason: " + status);
            }
        });

        //@ts-ignore
    }, [address])
    const driverHourlyRateVisible = useAppSelector((state: any) => state?.provider?.serviceVisibility?.driverHourlyRateVisible)
    const driverFullDayServiceVisible = useAppSelector((state: any) => state?.provider?.serviceVisibility?.driverFullDayServiceVisible)
    const driverAllNightServiceVisible = useAppSelector((state: any) => state?.provider?.serviceVisibility?.driverAllNightServiceVisible)
    const driverWeeklyServiceVisible = useAppSelector((state: any) => state?.provider?.serviceVisibility?.driverWeeklyServiceVisible)
    const driverMonthlyVisible = useAppSelector((state: any) => state?.provider?.serviceVisibility?.driverMonthlyVisible)
    const driverTravelVisible = useAppSelector((state: any) => state?.provider?.serviceVisibility?.driverTravelVisible)


    let [driverHourlyPopUp, setDriverHourlyPopUp] = useState(false)
    let [driverFullDayPopUp, setDriverFullDayPopUp] = useState(false)
    let [driverAllNightPopUp, setDriverAllNightPopUp] = useState(false)
    let [driverWeeklyPopUp, setDriverWeeklyPopUp] = useState(false)
    let [driverMonthlyPopUp, setDriverMonthlyPopUp] = useState(false)
    let [travelPopUp, setTravelPopUp] = useState(false)


    let servicePrice = driverInfo?.service_price
    let travelServicePrice = driverInfo?.travel_trip

    let isHourlyService: any = servicePrice?.filter((price: any) => {
        return price?.service_title === 'Hourly Rate'
    })
    let isFullDayService: any = servicePrice?.filter((price: any) => {
        return price?.service_title === 'Full Day Service'
    })
    let isAllNightService: any = servicePrice?.filter((price: any) => {
        return price?.service_title === 'All Night Service'
    })
    let isWeeklyService: any = servicePrice?.filter((price: any) => {
        return price?.service_title === 'Weekly Service'
    })
    let isMonthlyService: any = servicePrice?.filter((price: any) => {
        return price?.service_title === 'Monthly Service'
    })
    const handleUpload = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        if (isHourlyService[0]?.price === 0 && driverHourlyRateVisible === true) {
            setDriverHourlyPopUp(true)
        }
        else if (isFullDayService[0]?.price === 0 && driverFullDayServiceVisible === true) {
            setDriverFullDayPopUp(true)
        }
        else if (isAllNightService[0]?.price === 0 && driverAllNightServiceVisible === true) {
            setDriverAllNightPopUp(true)
        }
        else if (isWeeklyService[0]?.price === 0 && driverWeeklyServiceVisible === true) {
            setDriverWeeklyPopUp(true)
        }
        else if (isMonthlyService[0]?.price === 0 && driverMonthlyVisible === true) {
            setDriverMonthlyPopUp(true)
        }
        else if (travelServicePrice[0]?.price === 0 && driverTravelVisible === true) {
            setTravelPopUp(true)
        }

        else {
            //@ts-ignore
            addDriver([driverDetails]).then((response) => {

            }).catch((error) => {
                console.log(error, "error")
            })
            setDriverInfo(driverData?.data[0])
        }
    };


    const notifyError = (text: any) => toast.error(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })
    const notifySuccess = (text: any) => toast.success(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })

    const handleGoBack = () => {
        navigate("/individual-reg")
    };
    return (
        <>
            <ToastContainer />

            {
                driverHourlyPopUp &&
                <PopupModal
                    hourlyPopUp={driverHourlyPopUp}
                    setHourlyPopUp={setDriverHourlyPopUp}
                    service='Hourly Rate'
                />
            }
            {
                driverFullDayPopUp &&
                <PopupModal
                    fullDayPopUp={driverFullDayPopUp}
                    setDriverFullDayPopUp={setDriverFullDayPopUp}
                    service='Full Day Service'
                />
            }
            {
                driverAllNightPopUp &&
                <PopupModal
                    allNightPopUp={driverAllNightPopUp}
                    setDriverAllNightPopUp={setDriverAllNightPopUp}
                    service='All Night Service'
                />
            }
            {
                driverWeeklyPopUp &&
                <PopupModal
                    weeklyPopUp={driverWeeklyPopUp}
                    setDriverWeeklyPopUp={setDriverWeeklyPopUp}
                    service='Weekly Service'
                />
            }
            {
                driverMonthlyPopUp &&
                <PopupModal
                    monthlyPopUp={driverMonthlyPopUp}
                    setDriverMonthlyPopUp={setDriverMonthlyPopUp}
                    service='Monthly Service'
                />
            }
            {
                travelPopUp &&
                <PopupModal
                    travelPopUp={travelPopUp}
                    setTravelPopUp={setTravelPopUp}
                    service='Travel Service'
                />
            }


            <Page style={dashboard === true ? { padding: '0em .5em' } : { padding: '2em 2em' }}>
                <PagePadding className={dashboard === true ? '' : 'padding'}>

                    <ArrowDiv>
                        <IoIosArrowBack onClick={() => handleGoBack()}
                            style={{ fontSize: 30, padding: '0px', marginLeft: '-10px' }} />
                        <H3 style={{ margin: 0, paddingTop: 0 }}>Earn With Roadlers </H3>

                    </ArrowDiv>
                    <LoaderContainer>
                        <DivContainer>
                            <Loader />
                            <Loader />
                        </DivContainer>
                        <DivContainer>
                            <Loader />
                            <Loader2 />
                        </DivContainer>
                        <DivContainer>
                            <Loader2 />
                            <Loader2 />
                        </DivContainer>
                    </LoaderContainer>
                    <DIV>
                        <div ref={imageRef}>
                            {image1Prev ?

                                <IMAGEDIV >
                                    <img style={{ objectFit: 'cover' }} width='100%' height='100%' src={image1Prev} />
                                    <CancelButton
                                        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                            event.preventDefault();
                                            if (fileInputRef.current != null) {
                                                fileInputRef.current.click()
                                            }
                                        }}
                                    >X</CancelButton>
                                </IMAGEDIV> :
                                <>

                                    {
                                        driverInfos?.id ?
                                            <IMAGEDIV >
                                                <img style={{ objectFit: 'cover' }} width='100%' height='100%' src={image1Prev} />
                                                <BUTTON
                                                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                        event.preventDefault();
                                                        if (fileInputRef.current != null) {
                                                            fileInputRef.current.click()
                                                        }
                                                    }}
                                                >Upload Your Image</BUTTON>
                                            </IMAGEDIV> :
                                            <IMAGEDIV >
                                                <img style={{ objectFit: 'cover' }} width='100%' height='100%' src={image1Prev} />
                                                <BUTTON
                                                    style={{ background: 'darkgray', color: '#000' }} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                                        event.preventDefault();
                                                        notifyError('Image Upload Locked, to unlock, upload your driver details first')
                                                    }}>Upload Not allowed</BUTTON>
                                            </IMAGEDIV>
                                    }

                                </>

                            }
                        </div>

                    </DIV>
                    <H4 style={{ fontWeight: 700 }}>
                        Register Your Account in  <SPAN>{5 - component} {component === 4 ? 'Move' : 'Moves'}</SPAN>
                    </H4>
                    <RANGEDIV style={component > 3 ? { width: '89%' } : { width: '100%' }}>
                        <input type="range" min="1" max={allQuestion} value={question} className="driverSlider" id="myRange" />
                        <div id="driverbackground"> </div>
                        <div id="driverprogress" ref={refDiv} style={{ width: width + '%' }}></div>
                    </RANGEDIV>


                    {component === 1 &&
                        <FirstScreenDriver
                            setDriverDetails={setDriverDetails}
                            driverDetails={driverDetails}
                            setWidth={setWidth}
                        />}
                    {component === 2 &&
                        <SecondScreen
                            setDriverDetails={setDriverDetails}
                            driverDetails={driverDetails}
                            setWidth={setWidth}
                        />}
                    {component === 3 && <ThirdScreen
                        setDriverDetails={setDriverDetails}
                        driverDetails={driverDetails}
                        setWidth={setWidth}

                    />}
                    {component === 4 && <FourthScreen
                        setDriverDetails={setDriverDetails}
                        driverDetails={driverDetails}
                        setWidth={setWidth}
                        pendingApproval={false}

                    />}
                    <Container>
                        {component === 4 ?
                            (btnLoad ?
                                <DisabledButton> Please Wait...</DisabledButton> :
                                <UploadButton onClick={handleUpload}><h4>Add Driver</h4></UploadButton>
                            ) :
                            <Button onClick={handleNextComponent}>
                                <h4>OK</h4>
                                <img src={whiteMark} alt="" width={28} height={23} />
                            </Button>
                        }
                        <DIV>
                            {component >= 2 &&
                                <button style={{ background: 'transparent', border: 'none' }} onClick={handlePrevComponent}>
                                    <BsChevronLeft style={{ color: 'white', fontSize: 30, fontWeight: 600 }} />
                                </button>}
                            {component < 4 &&
                                <button style={{ background: 'transparent', border: 'none' }} onClick={handleNextComponent}>
                                    <BsChevronRight style={{ color: 'white', fontSize: 30, fontWeight: 600 }} />
                                </button>
                            }
                        </DIV>
                    </Container>
                </PagePadding>

            </Page>
            <input type="file" style={{ display: 'none' }} ref={fileInputRef} accept="image/*" onChange={onChange} />
        </>
    )
}
export default DriverRegistration