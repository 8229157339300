import React from 'react'
import { AbsoluteButton, CardContentWrap } from '../walletCardComponent/walletcard.styles'
import { CheckoutAbsoluteButton, CheckoutCardContentWrap, CheckoutCardTextWrap, CheckoutCardWrap, CheckoutImgWrap } from './checkoutWalletCard.styles'


import wallet from '../../assets/images/png/card-purse.png'
import fund from '../../assets/images/svg/fund-img.svg'


const CheckoutWalletCardComponent = ({currency, walletTotal}:any) => {
  return (
    <>
        <CheckoutCardWrap>
            <div className="inner-item-card">
                <CheckoutCardContentWrap>
                    <CheckoutCardTextWrap>
                        <p>My Total Balance</p>

                        <h2><span>{currency}</span> {`${(Number(walletTotal))?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}</h2>
                    </CheckoutCardTextWrap>

                    <CheckoutImgWrap>
                        <img src={wallet}  />
                    </CheckoutImgWrap>
                </CheckoutCardContentWrap>
            </div>

            <CheckoutAbsoluteButton>
                <div className="inner-button">
                    <img src={fund} />

                    <p>Fund Wallet</p>
                </div>
            </CheckoutAbsoluteButton>
        </CheckoutCardWrap>
    </>
  )
}

export default CheckoutWalletCardComponent