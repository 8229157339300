import React, { FC, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'

import vehicleRequest from "../../../assets/images/png/trips-card-car.png";
import envelope from "../../../assets/images/png/envelop.png";
import { AiOutlineClockCircle } from "react-icons/ai";
import { ImLocation2 } from "react-icons/im";
import { BsCalendar3 } from "react-icons/bs";
import { RiUser3Line } from "react-icons/ri";
import { HiEllipsisHorizontal } from "react-icons/hi2";
import { IoCallSharp } from "react-icons/io5";
import userWhite from "../../../assets/images/png/user-white.png";

import {
  RequestDiv,
  Div,
  ImageDiv,
  StatusDiv,
  Span,
  AcceptButton,
  FlexDiv,
  H6,
  H5,
  Label,
  IDDiv,
  StartButton,
  CancelButton,
  UserDiv,
  StatusLabel,
  StatusOption,
  AssignButton,
  CallButton,
  CallDiv,
  FinishButton,
} from "./requests.styles";
import {
  useAcceptRequestMutation,
  useRejectRequestMutation,
  useUpdateRequestMutation,
} from "../../../services/providerApi";
import { setAllBooking } from "../../../slices/admin";
import { useAppDispatch } from "../../../store";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer, toast } from "react-toastify";
import BookingTimeModal from "../../bookingTimeModal";
import BookingCalendarModal from "../../bookingCalendarModal";
import BookingLocationModal from "../../bookingLocationModal";
import CallCustomer from "../CallCustomer";
import { EllipsesModal } from "../Ellipses/Modal";
import AssignRequest from "../AssignRequest";
import { baseImageUrl } from "../../../services/utils";
import TicketModal from "../../providerDashboard/providerRequests/ticketPopup";
const Request: FC<{ request: any }> = ({ request }) => {
  const dispatch: any = useAppDispatch();
  const pickupDateArray = request?.pickup_date
    ?.split(",")
    ?.map((date) => date.trim());

  const address = request?.destination;
  const words = address?.split(" ");
  const firstTwoWords = words?.slice(0, 2).join(" ");

  const [acceptRequest, { data, isLoading, isSuccess, isError, error }] =
    useAcceptRequestMutation();
  const [
    rejectRequest,
    {
      data: rejectData,
      isLoading: loading,
      isSuccess: success,
      isError: err,
      error: errr,
    },
  ] = useRejectRequestMutation();
  const [
    updateRequest,
    {
      data: updateData,
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: Updateerror,
    },
  ] = useUpdateRequestMutation();

  useEffect(() => {
    if (isLoading) {
    }
    if (isSuccess) {
      // dispatch(setAllBooking(data?.data))
    }
    if (isError && "status" in error!) {
      if (isError && error.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
        // setIsDelete(false)
      }
      // setBtnLoad(false)
    }
  }, [isLoading, isSuccess, isError, data]);

  useEffect(() => {
    if (loading) {
    }
    if (success) {
      // dispatch(setAllBooking(rejectData?.data))
    }
    if (err && "status" in errr!) {
      if (err && errr.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
        // setIsDelete(false)
      }
      // setBtnLoad(false)
    }
  }, [loading, success, err, rejectData]);

  useEffect(() => {
    if (isUpdateLoading) {
    }
    if (isUpdateSuccess) {
      // dispatch(setAllBooking(updateData?.data))
    }
    if (isUpdateError && "status" in Updateerror!) {
      if (isUpdateError && Updateerror.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
        // setIsDelete(false)
      }
      // setBtnLoad(false)
    }
  }, [isUpdateLoading, isUpdateSuccess, isUpdateError, updateData]);

  const handleAcceptRequest = async () => {
    let formData: any = new FormData();
    formData.append("id", request?.id);
    formData.append("book_status", "Pending");
    acceptRequest(formData);
  };

  const handleStartRequest = async () => {
    let formData: any = new FormData();
    formData.append("booking_id", request?.id);
    formData.append("book_status", "In Progress");
    formData.append("provider_status", "In Progress");
    updateRequest(formData);
  };
  const handleCancelRequest = async () => {
    let formData: any = new FormData();
    formData.append("booking_id", request?.id);
    formData.append("provider_status", "Rejected");
    formData.append("book_status", "Open");
    acceptRequest(formData);
  };

  const handleFinishRequest = async () => {
    // const dates: any = formatPickupDate();

    let formData: any = new FormData();
    formData.append("booking_id", request?.id);
    formData.append("book_status", "Completed");
    formData.append("provider_status", "Completed");
    updateRequest(formData);
  };
  const [isEllipsesVisible, setIsEllipsesVisible] = useState(false);

  const openEllipses = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsEllipsesVisible((prev) => !prev);
  };

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showCustomer, setShowCustomer] = useState(false);
  const [assignRequest, setAssignRequest] = useState(false);
  const [passengerInfo, setPassengerInfo] = useState({});

  const handleReassign = () => {
    setAssignRequest(true);
  };
  const passengersDetails = request?.passenger_booking;

  const handlePassenger = (passengerData) => {
    setPassengerInfo(passengerData);
    setIsTicket((prev) => !prev);
  };
  const [isTicket, setIsTicket] = useState(false);
  return (
    <div>
      {isEllipsesVisible && (
        <EllipsesModal
          isRequests={true}
          setIsEllipsesVisible={setIsEllipsesVisible}
        />
      )}
      <ToastContainer />

      <BookingCalendarModal
        showDateModal={showDateModal}
        setShowDateModal={setShowDateModal}
        dateArray={pickupDateArray}
      />
      <BookingTimeModal
        time={request?.pickup_time}
        showTimeModal={showTimeModal}
        setShowTimeModal={setShowTimeModal}
      />
      <BookingLocationModal
        previousOrigin={request?.pickup_location}
        previousDestination={request?.destination}
        showLocationModal={showLocationModal}
        setShowLocationModal={setShowLocationModal}
        withinOrWithout={"within"}
        setDistance={undefined}
      />
      {showCustomer && (
        <CallCustomer
          customerId={request?.user_id}
          setShowCustomer={setShowCustomer}
        />
      )}
      {assignRequest && (
        <AssignRequest request={request} setAssignRequest={setAssignRequest} />
      )}

      <div
        style={{
          position: "relative",
          margin: "60px 0px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {request?.book_status === "Open" &&
        request?.category === "Travel Garage Booking" ? (
          <StatusDiv style={{ background: "#005FEE" }}>Listed</StatusDiv>
        ) : (
          <StatusDiv>{request?.book_status}</StatusDiv>
        )}

        {request?.book_status === "Cancel" && (
          <StatusDiv>{request?.book_status}</StatusDiv>
        )}
        {request?.book_status === "Pending" && (
          <StatusDiv style={{ background: "#005FEE" }}>
            {request?.book_status}
          </StatusDiv>
        )}
        {request?.book_status === "In Progress" && (
          <StatusDiv style={{ background: "#ffa100", color: "#000" }}>
            In_Progress
          </StatusDiv>
        )}
        {request?.book_status === "Completed" && (
          <StatusDiv style={{ background: "##07B418" }}>
            {request?.book_status}
          </StatusDiv>
        )}

        <StatusLabel>
          {request?.book_status === "Open" ? "Unassigned" : "Assigned"}
        </StatusLabel>
        <UserDiv style={{ background: "#ff3a3a" }}>
          <RiUser3Line style={{ fontSize: 24 }} />
        </UserDiv>
        <StatusOption onClick={openEllipses}>
          <HiEllipsisHorizontal />
        </StatusOption>

        <RequestDiv>
          <FlexDiv>
            <ImageDiv>
              <img
                src={baseImageUrl + request?.booking_picture}
                alt={baseImageUrl + request?.booking_picture}
                width={90}
                height="auto"
              />
            </ImageDiv>
            <IDDiv>
              <Label>ID: {request?.request_id}</Label>
              <H5>
                {request?.category === "Travel Garage Booking"
                  ? request?.from + " to " + request?.to
                  : request?.booking_title}
              </H5>
            </IDDiv>

            <Div style={{ alignItems: "start" }}>
              <Span onClick={() => setShowTimeModal(true)}>
                <AiOutlineClockCircle />
                <Label>{request?.pickup_time}</Label>
              </Span>

              <Span onClick={() => setShowLocationModal(true)}>
                <ImLocation2 />
                <Label>{firstTwoWords}</Label>
              </Span>

              <Span onClick={() => setShowDateModal(true)}>
                <BsCalendar3 />
                <Label>{pickupDateArray[0] || ""}</Label>
              </Span>
            </Div>
            <Div style={{ width: "30%", alignItems: "flex-end" }}>
              {request?.book_status !== "In Progress" && (
                <H6>
                  {request?.currency_code+ " "}
                  {request?.amount.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}
                </H6>
              )}

              {request?.book_status === "Open" && (
                <>
                  <AssignButton onClick={() => handleReassign()}>
                    Re-Assign
                  </AssignButton>
                  <CallDiv>
                    <CallButton onClick={() => setShowCustomer(true)}>
                      <IoCallSharp />
                    </CallButton>
                    {!isLoading ? (
                      <AcceptButton onClick={handleAcceptRequest}>
                        Accept
                      </AcceptButton>
                    ) : (
                      <AcceptButton onClick={handleAcceptRequest}>
                        Loading...
                      </AcceptButton>
                    )}
                  </CallDiv>
                </>
              )}

              {(request?.book_status === "Cancel" ||
                request?.provider_status === "Cancel") && (
                <>
                  <AssignButton
                    onClick={() => {
                      handleReassign();
                    }}
                  >
                    Re-Assign
                  </AssignButton>
                  <CallDiv>
                    <CallButton onClick={() => setShowCustomer(true)}>
                      <IoCallSharp />
                    </CallButton>
                    {!isLoading ? (
                      <AcceptButton onClick={handleAcceptRequest}>
                        Accept
                      </AcceptButton>
                    ) : (
                      <AcceptButton onClick={handleAcceptRequest}>
                        Loading...
                      </AcceptButton>
                    )}
                  </CallDiv>
                </>
              )}

              {request?.book_status === "Pending" && (
                <>
                  <StartButton onClick={handleStartRequest}>
                    Start Now
                  </StartButton>

                  <div
                    style={{ display: "flex", alignItems: "center", gap: 7 }}
                  >
                    <img
                      src={envelope}
                      alt=""
                      width={28}
                      onClick={handleAcceptRequest}
                    />
                    <CancelButton onClick={handleCancelRequest}>
                      Cancel
                    </CancelButton>
                  </div>
                </>
              )}

              {request?.book_status === "In Progress" && (
                <>
                  {/* <StartButton onClick={handleStartRequest}>
                                        Start Now
                                    </StartButton> */}
                  <div className="">&nbsp;</div>
                  <H6>
                    {request?.currency_code}
                    {request?.amount.toLocaleString("en-US", {
                      maximumFractionDigits: 2,
                    })}
                  </H6>
                  <CallDiv>
                    <CallButton onClick={() => setShowCustomer(true)}>
                      <IoCallSharp />
                    </CallButton>
                    {!isLoading ? (
                      <FinishButton onClick={handleFinishRequest}>
                        Finish
                      </FinishButton>
                    ) : (
                      <FinishButton>Loading...</FinishButton>
                    )}
                  </CallDiv>
                </>
              )}
              {request?.book_status === "Completed" && (
                <>
                  {/* <StartButton onClick={handleStartRequest}>
                                        Start Now
                                    </StartButton> */}
                  <div className="">&nbsp;</div>
                  {/* <H6>
                                        ₦{request?.amount.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                                    </H6> */}
                  <CallDiv>
                    <CallButton onClick={() => setShowCustomer(true)}>
                      <IoCallSharp />
                    </CallButton>
                    {!isLoading ? (
                      <FinishButton
                      // onClick={handleFinishRequest}
                      >
                        Completed
                      </FinishButton>
                    ) : (
                      <FinishButton>Loading...</FinishButton>
                    )}
                  </CallDiv>
                </>
              )}
            </Div>
          </FlexDiv>
        </RequestDiv>
      </div>
      {request?.category === "Travel Garage Booking" ? (
        <div
          style={{
            marginTop: -45,
            marginBottom: 75,
            marginLeft: 15,
            display: "flex",
            gap: 5,
            alignItems: "center",
          }}
        >
          {passengersDetails?.map((passenger, i) => {
            return (
              <div
                key={passenger?.id}
                onClick={() => handlePassenger(passenger)}
                style={{
                  //   border: "1px solid white",
                  padding: "5px",
                  borderRadius: 5,
                  background: "#AA6C05",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={userWhite} width={30} height={25} />
              </div>
            );
          })}
        </div>
      ) : (
        ""
      )}

      {isTicket && (
        <TicketModal
          setIsTicket={setIsTicket}
          request={request}
          passengerInfo={passengerInfo}
        />
      )}
    </div>
  );
};

export default Request;
