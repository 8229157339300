import { Page } from './../Homepage/homepage.styles';
import styled from 'styled-components'

export const ContentPageWrap = styled.section `
    padding: 0 40px;
    margin-bottom: 120px;
`

export const EarningInfoFlex = styled.div `
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 60px;
`

export const ReferralBox = styled.div `
    & .referral-box {
        border-radius: 9px;
        padding: 20px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FF5242;
        color: #000;
        font-weight: 700;
        font-size: 18px;
    }

    & p {
        font-weight: 700;
        font-size: 13px;
        text-align: center;
    }
`

export const TransactionsBox = styled.div `
    & .transaction-box {
        border-radius: 9px;
        padding: 20px 20px;
        min-width: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #72B3EE;
        color: #000;
        font-weight: 700;
        font-size: 18px;
    }

    & p {
        font-weight: 700;
        font-size: 13px;
        text-align: center;
    }
`

export const EarningContentInfo = styled.div `
    margin-top: 30px;
    & p {
        font-size: 16px;
        font-weight: 600;
        color: #C4C4C4;

    }
`

export const LinkInputWrap = styled.div `
    display: flex;
    background-color: #fff;
    justify-content: space-between;
    border-radius: 9px;
    margin-top: 40px;
    gap: 15px;

    & input {
        width: 100%;
        outline: none;
        border: none;
        background-color: transparent;
        padding-left: 20px;
        color: #868686;
    }

    & .copy-btn {
        border-radius: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        padding: 15px;
        color: #fff;
        font-weight: 700;
        font-size: 15px;
        cursor: pointer;
        background-color: rgba(134, 134, 134, 0.71);
    }
`

export const SocialMediaWrap = styled.div `
    display: flex;
    flex-direction: column;
    /* padding-left: 40px; */
    gap: 20px;
    margin-top: 40px;

    & h2 {
        font-weight: 700;
        font-size: 16px;
        color: #C8C8C8;
        margin: 0;
    }
`

export const SocialIconsWrap = styled.div `
    display: flex;
    align-items: center;
    gap: 20px;
`

export const IconsBox = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${(props:any) => props.pad};
    border-radius: 9px;
    background-color: ${(props:any) => props.bg};
`

export const SocialIcons = styled.img `
    width: 40px;
`