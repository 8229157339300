import styled from 'styled-components'

export const RoadlersFooter = styled.footer `
    position: relative;
    z-index: 15;

    & .footer-body {
        padding: 40px 20px;
        background-color: ${(props: any) => props.text};

        .footer-body-inner {
            display: flex;
            justify-content: space-between;
            gap: 40px;

            .first-section{
                flex: 30%;

                h2 {
                    color: ${(props: any) => props.text};
                    font-size: 25px;
                    margin: 0;
                }

                .contact-info-wrapper {
                    color: ${(props: any) => props.theme.text};

                    h4 {
                        color: ${(props: any) => props.theme.text};
                        font-size: 15px;
                    }

                    .roadlers-address, .roadlers-mail-phone {
                        display: flex;
                        align-items: center;
                        gap: 20px;

                        img {
                            filter: ${(props: any) => props.theme.iconColor}
                            width: 20px;
                        }
                    }
                    
                    .roadlers-mail-phone {
                        .mail-phone-inner {
                            p {
                                margin: 0;
                            }
                        }
                    }
                }
            }

            .wrap-sections {
                display: flex;
                gap: 40px;
                flex: 50%;

                .second-section {
                    flex: 20%;
                    display: flex;
                    justify-content: center;
    
                    iframe {
                        border-radius: 20px;
                        width: 220px;
                        height: 220px;
                        box-shadow: 10px 5px 5px #000;
                    }
                }
    
                /* .third-section {
                    flex: 30%;
    
                    form {
                        color: ${(props: any) => props.theme.text};
                        div {
                            display: flex;
                            gap: 5px;
                            padding: 10px 0;
                            border-bottom: solid 1px ${(props: any) => props.theme.text};
    
                            label {
                                font-size: 14px;
                            }
    
                            input {
                                background-color: transparent;
                                border: none;
                                outline: none;
                                color: $white;
                                font-size: 15px;
                                padding: 0;
                            }
                        }
    
                        .purpose-input {
                            display: flex;
                            flex-direction: column;
                            border: none;
    
                            textarea {
                                background-color: transparent;
                                border: none;
                                border-bottom: solid 1px ${(props: any) => props.theme.text};
                                outline: none;
                                color: ${(props: any) => props.text};
                                font-size: 15px;
                            }
                        }
                    }
    
                    .submit-btn-wrapper {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 20px;
    
                        button {
                            outline: none;
                            border: none;
                            background-color: ${(props: any) => props.theme.body};
                            border: solid 1px ${(props: any) => props.theme.text};
                            border-radius: 9px;
                            padding: 15px 30px;
                            width: 70%;
                            font-size: 18px;
                            color: ${(props: any) => props.theme.text};
                            font-weight: 600;
                        }
                    }
                } */
                
                .third-section {
                    ul {
                        padding: 0;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        /* justify-content: space-between; */

                        li {
                            list-style: none;
                            a {
                                color: ${(props: any) => props.theme.text};
                                text-decoration: none;
                            }
                        }
                    }
                }
            }


        }
    }

    & .footer-foot {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        background-color: #000000;
        color: #fff;
    }


    @media only screen and (max-width: 991px) {
        & .footer-body {    
            .footer-body-inner {
                display: flex;
                flex-direction: column;

                .wrap-sections {
                    justify-content: center;
                    .second-section {
                        flex: 10%;
                        display: flex;
                        justify-content: flex-start;
        
                        iframe {
                            border-radius: 20px;
                            width: 280px;
                            height: 280px;
                            box-shadow: 10px 5px 5px #000;
                        }
                    }

                    .third-section {
                        flex: 20%;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 767px) {
        & .footer-body {
            padding: 40px;
        }
    }

    @media only screen and (max-width: 651px) {
        & .footer-body {
            padding: 40px;

            .footer-body-inner {
                .wrap-sections {
                    .second-section {
                        flex: 20%;
                        display: flex;
                        justify-content: center;
        
                        iframe {
                            border-radius: 20px;
                            width: 240px;
                            height: 240px;
                            box-shadow: 10px 5px 5px #000;
                        }
                    }

                    .third-section {
                        .submit-btn-wrapper {
                            button {
                                padding: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 565px) {
        & .footer-body {
            .footer-body-inner {
                .wrap-sections {
                    .second-section {
                        flex: 20%;
                        justify-content: flex-end;
                        iframe {
                            border-radius: 20px;
                            width: 220px;
                            height: 220px;
                            box-shadow: 10px 5px 5px #000;
                        }
                    }

                    .third-section {
                        .submit-btn-wrapper {
                            button {
                                padding: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 546px) {
        &  .footer-body {
            .footer-body-inner {
                .wrap-sections {
                    .second-section {
                        iframe {
                            border-radius: 20px;
                            width: 220px;
                            height: 220px;
                            box-shadow: 10px 5px 5px #000;
                        }
                    }

                    .third-section {
                        .submit-btn-wrapper {
                            button {
                                padding: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 515px) {
        & .footer-body {
            .footer-body-inner {
                .wrap-sections {
                    .second-section {
                        iframe {
                            border-radius: 20px;
                            width: 220px;
                            height: 220px;
                            box-shadow: 10px 5px 5px #000;
                        }
                    }

                    .third-section {
                        .submit-btn-wrapper {
                            button {
                                padding: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 454px) {
        .footer-body {
            padding: 30px;
            .footer-body-inner {
                .first-section {
                    h2 {
                        font-size: 20px;
                    }

                    .contact-info-wrapper {

                        & > div {
                            img {
                                width: 25px;
                            }

                            p {
                                font-size: 12px;
                            }
                        }
                    }
                }

                .wrap-sections {
                    flex-direction: row;
                    .second-section {
                        iframe {
                            border-radius: 20px;
                            width: 150px;
                            height: 150px;
                            box-shadow: 10px 5px 5px #000;
                        }
                    }

                    .third-section {
                        .submit-btn-wrapper {
                            button {
                                padding: 15px;
                            }
                        }

                        ul {
                            margin: 0;
                            li {
                                a {
                                    font-size: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


`

export const FooterHeader = styled.div `
        height: 60px;
        border-bottom: solid 2px #FFA100;
        padding: 0 20px;
        background-color: ${(props: any) => props.theme.footerHeader};

        & .footer-header-inner {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .logo-wrapper {                
                img {
                    height: 65px;
                }
            }

            .social-icon-wrapper {
                display: flex;
                gap: 10px;

                img {
                    filter: ${(props: any) => props.theme.iconColor};
                    width: 30px;
                }
            }
        }

        @media only screen and (min-width: 385px) {
            & .footer-header-inner {
                .social-icon-wrapper {
                    gap: 30px;
                }
        }

        @media only screen and (max-width: 767px) {
            padding: 0 40px;
        }
    }
`