import React, { useState, useEffect  } from 'react'
import { InputBtn, InputIcons, LocationBox, LocationInputWrap, LocationModalBox, WhereOptionPill, WhereRadioPill } from '../locationModal/locationmodal.styles'
import { CancelModalBox, ModalOverlay } from '../optionModal/optionmodal.styles'
import { GoogleMap, LoadScript, Marker, useJsApiLoader, Polyline } from '@react-google-maps/api';
import { Autocomplete } from '@react-google-maps/api';
import PhoneInput from 'react-phone-number-input'
import greyBox from '../../assets/images/svg/greybox.svg'
import { DeleteFormBtn, DeleteFormWrap, DeleteQuestionBox, DeleteQuestionWrap, DeliveryInputOverall, DeliveryLocationWrap, DoneBtn, DoneOverall, InputWrapOverall, ItemBtn, ItemsBtnList, SaveDeliveryBtn, YesBtn } from './deliverylocation.styles';
import { AppDispatch, RootState, useAppDispatch, useAppSelector } from '../../store'
import { setShowDeliveryLocation } from '../../slices/showDeliveryLocation'

import mails from '../../assets/images/svg/mail.svg'
import whereTag from '../../assets/images/svg/where-tag.svg'
import profile from '../../assets/images/svg/user-icon-black.svg'
import trash from '../../assets/images/svg/trash.svg'
import {Formik, useFormikContext} from 'formik'
import * as yup from 'yup'
import MultipleDeliveryInput from '../multipleDeliveryInput';


const center = {lat: 6.605874, lng: 3.349149}

const DeliveryLocationModal2 = ({setDeliveryOption, origin, setOrigin ,setDestination,setSingleMail, setSingleName, destination, setSingleNumber,setLocationBtn, singleNumber, singleName, singleMail, setCalculatedAmt, setMultiplyDeliveryArray, previousDeliveryType, previousDeliveryInfo,previousPickupLocation}:any) => {
    const dispatch: AppDispatch = useAppDispatch()
    const {showDeliveryLocation} = useAppSelector<any>((store:RootState) => store?.showDeliveryLocation)
    const YOUR_API_KEY = 'AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs';
    const [autocomplete, setAutocomplete] = useState<any>(null)
    const [address, setAddress] = useState('')
    const [deliveryType, setDeliveryType] = useState('single')
    const [autocompleteOrigin, setAutocompleteOrigin] = useState<any>(null)
    const [forms, setForms] = useState<any []>([]);
    const [slideId, setSlideId] = useState(0)
    const [addresses, setAddresses] = useState<any []>([]);
    const [distance, setDistance] = useState<any>(0);
    const [price, setPrice] = useState(0);
    const [inputAddress, setInputAddress] = useState('');
    const [showSingleForm, setShowSingleForm] = useState(false)
    const [showSingleBoxCall, setShowSingleboxCall] = useState(false)
    const [calculateDistSingle, setCalculateDistSingle] = useState(false)

    const [phoneValue, setPhoneValue] = useState<any>()
    const [mail, setMail] = useState('')
    const [name, setName] = useState('')

    const [receiverMailMulitple, setReceiverMailMultiple] = useState('')
    const [receiverNumMutliple, setReceiverNumMultiple] = useState<any>()
    const [receiverNameMultiple, setReceiverNameMultiple] = useState('')
    const [receiverAddressMultiple, setReceiverAddressMultiple] = useState('')
    const [receiverInfoArray, setReceiverInfoArray] = useState<any []>([])
    const [twistButton, setTwistButton] = useState(false)
    const [duration, setDuration] = useState<any>()
    const [editPickupBtn, setEditPickupBtn] = useState(false)
    const [pickupAddressFilled, setPickupAddressFilled] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [switchForm, setSwitcForm] = useState(false)
    const [editSwitchForm, setEditSwitchForm] = useState(false)
    const [showSubPage, setShowSubPage] = useState(true)
    const [selectedForm, setSelectedForm] = useState<any>([])
    const [editAddressId, setEditAddressId] = useState<any>()
    const [editFormId, setEditFormId] = useState<any>()
    const [showDeleteQuestion, setShowDeleteQuestion] = useState<any>(false)


    const libraries: (
        | 'places'
        | 'drawing'
        | 'geometry'
        | 'localContext'
        | 'visualization'
      )[] = ['places', 'geometry', 'drawing'];

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs',
        libraries,
    }) 

    // const onPlaceChanged = () => {
    //     if(autocomplete !== null) {
    //         const places = autocomplete.getPlace().formatted_address
    //         if(places) {
    //             setAddress(places)
    //         }else {
    //             setAddress(autocomplete.getPlace().name)
    //         }
    //     }
    // }

    const onDestinationPlaceChanged = () => {
        if(autocomplete !== null) {
            const places = autocomplete.getPlace().formatted_address
            if(places) {
                setDestination(places)
                setInputAddress(places)
            }else {
                setDestination(autocomplete.getPlace().name)
                setInputAddress(autocomplete.getPlace().name)
            }
        }
    }

    const onOriginPlaceChanged = () => {
        if(autocompleteOrigin !== null) {
            const places = autocompleteOrigin.getPlace().formatted_address
            if(places) {
                setOrigin(places)
                setInputAddress(places)
            }else {
                setOrigin(autocompleteOrigin.getPlace().name)
                setInputAddress(autocompleteOrigin.getPlace().name)
            }
        }
    }

    const locationModalControl = () => {
        dispatch(setShowDeliveryLocation(false))
    }

    const goToSlide = (id: any) => {
        setSlideId(id)
    }

    // USEFFECT TO UPDATE THE FORMS WHEN EDIT IS CLICKED ON CART
    useEffect(() => {
        if(previousDeliveryType) {
            if(previousDeliveryType?.includes('Single')) {
                setDeliveryType('single');
                setDeliveryOption('single');
            }else {
                setDeliveryType('multiple');
                setDeliveryOption('multiple');
            }
        }
    }, [previousDeliveryType])

    useEffect(() => {
        if(previousDeliveryInfo) {
            setOrigin(previousPickupLocation)
            handleAddAddress(previousPickupLocation)
            setReceiverInfoArray(previousDeliveryInfo)
        }
    }, [previousDeliveryInfo, previousPickupLocation])

    useEffect(() => {
        if(previousDeliveryInfo && addresses?.length  < previousDeliveryInfo?.length+1) {
            if(addresses?.[0]) {
                    previousDeliveryInfo?.map((item:any) => {
                        setInputAddress(item?.destination)
                        handleAddAddress(item?.destination)
                    })
            }
        }
    }, [previousDeliveryInfo, addresses])

    // useEffect(() => {
    //     if(previousDeliveryInfo && inputAddress && addresses?.length  < previousDeliveryInfo?.length+1) {
    //         handleAddAddress(inputAddress)
    //     }
    // }, [inputAddress])


    // ADD THE VERY FIRST FORM
    const addForm = () => {
        if(pickupAddressFilled) {
            setSwitcForm(true)
            setShowSubPage(false)
        }else {
            setErrorText('Fill the previous field to proceed')

            setTimeout(() => {
                setErrorText('')
            }, 3000)
        }

        // if(origin?.trim()?.length > 3) {
        //     handleAddAddress(inputAddress)
        //     const newForm = <MultipleDeliveryInput key={forms.length} 
        //         setInputValue={setInputAddress}
        //         setOriginValue={setReceiverAddressMultiple}
        //         setPhoneValue={setReceiverNumMultiple}
        //         setMailValue={setReceiverMailMultiple}
        //         setNameValue={setReceiverNameMultiple}
        //     />;
        //     setForms([...forms, newForm]);
        // }
    };

    const handleFormDone = () => {
        if (receiverNumMutliple?.trim()?.length > 3 && receiverAddressMultiple?.trim()?.length > 3 &&  receiverNameMultiple?.trim()?.length >= 2) {
            
                const newDeliveryInput:any = {
                    receiver_name: receiverNameMultiple,
                    receiver_mail: receiverMailMulitple,
                    receiver_phone: receiverNumMutliple,
                    destination: receiverAddressMultiple,
                    pickup_location: origin,
                } 
                
                handleAddAddress(inputAddress)
                setReceiverInfoArray([  ...receiverInfoArray, newDeliveryInput,])
                setSwitcForm(false)
                setShowSubPage(true)
            
        }else {
            console.error('error on form done')
        }
    }

    const handleShowEditForm = (object:any, addressId:any, formId:any) => {
        setSelectedForm(object)
        setShowSubPage(false)
        setEditSwitchForm(true)
        setEditAddressId(addressId)
        setEditFormId(formId)
    
    }

    const handleFormEdit = (addressId:any, formId:any ) => {
        handleEditAddress(inputAddress, addressId)
        handleEditReceiverInfoArray(formId)
        setShowSubPage(true)
        setEditSwitchForm(false)
    }

    // DELETE THE LAST FORM
    const deleteForm = (addressId:any, formId:any) => {
            const updatedForms = receiverInfoArray?.filter((form) => receiverInfoArray?.indexOf(form) !== formId);
            setReceiverInfoArray(updatedForms);
    
            const newAddresses = addresses?.filter((item:any) => addresses?.indexOf(item) !== addressId); 
            setAddresses(newAddresses); 
            setShowSubPage(true)
            setEditSwitchForm(false)
            setShowDeleteQuestion(false)

    };

    // function to handle adding new addresses to the list
    const handleAddAddress = (newAddress:any) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: newAddress }, (results:any, status) => {
          if (status === 'OK') {
            const location = results[0].geometry.location;
            setAddresses([...addresses, location]);
            setInputAddress('');
          } else {
            console.log(`Geocoding error: ${status}`);
          }
        });
    };

    // function too handle Editing of address
    const handleEditAddress = (newAddress:any, i:any) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: newAddress }, (results:any, status) => {
          if (status === 'OK') {
            const location = results[0].geometry.location;
            const updatedAddresses = [...addresses]; // create a copy of the addresses array
            updatedAddresses[i] = location; // update the address at index i with the new location
            setAddresses(updatedAddresses); // update the state with the modified array
          } else {
            console.log(`Geocoding error: ${status}`);
          }
        });
    };

    const handleEditReceiverInfoArray = (i:any) => {
        const newDeliveryInput:any = {
            receiver_name: receiverNameMultiple,
            receiver_mail: receiverMailMulitple,
            receiver_phone: receiverNumMutliple,
            destination: receiverAddressMultiple,
            pickup_location: origin,
        } 

        const updatedArray = [...receiverInfoArray]
        updatedArray[i] = newDeliveryInput;
        setReceiverInfoArray(updatedArray)
    }

    const onEditPickupAddress = () => {
        if (addresses?.[0]) {
            handleEditAddress(inputAddress, 0)
            setEditPickupBtn(false)
        }else {
            handleAddAddress(inputAddress)
            setEditPickupBtn(false)
            setPickupAddressFilled(true)
        }
    }


    // function to calculate distance and price
    // const calculateDistanceAndPrice = () => {
    //     // use Google Maps API to calculate distance between all addresses
    //     // calculate total distance and set state
    //     const service = new window.google.maps.DistanceMatrixService();

    //     service.getDistanceMatrix(
    //         {
    //             origins: addresses,
    //             destinations: addresses,
    //             travelMode: window.google.maps.TravelMode.DRIVING,
    //             unitSystem: window.google.maps.UnitSystem.METRIC,
    //         },
    //         (response:any, status) => {
    //             if (status === 'OK') {
    //             let totalDistance = 0;
    //             for (let i = 0; i < response.rows.length; i++) {
    //                 const row = response.rows[i];
    //                 for (let j = 0; j < row.elements.length; j++) {
    //                 const element = row.elements[j];
    //                 totalDistance += element.distance.value;
    //                 }
    //             }
    //             setDistance(totalDistance);
    //             } else {
    //             console.log(`Distance matrix error: ${status}`);
    //             }
    //         }
    //     );

    //     // calculate price based on distance and set state
    //     // const basePrice = 700
    //     // const pricePerKm = 0.1; 
    //     // const pricePerTime = 20 
    //     // const price = (distance / 1000) * pricePerKm;
    //     // setPrice(price);
    // };

    // FUNCTION TO CALCULATE THE DISTANCE AND TIME
    const calculateDistance = () => {
        return new Promise((resolve, reject) => {
          const service = new window.google.maps.DistanceMatrixService();
          service.getDistanceMatrix(
            {
              origins: addresses,
              destinations: addresses,
              travelMode: window.google.maps.TravelMode.DRIVING,
              unitSystem: window.google.maps.UnitSystem.METRIC,
            },
            (response:any, status) => {
              if (status === 'OK') {
                let totalDistance = 0;
                let totalDuration = 0;
                for (let i = 0; i < response.rows.length; i++) {
                  const row = response.rows[i];
                  for (let j = 0; j < row.elements.length; j++) {
                    const element = row.elements[j];
                    totalDistance += element.distance.value;
                    totalDuration += element.duration.value;
                  }
                }
                const result = {
                    distance: totalDistance,
                    duration: totalDuration,
                  };
        
                resolve(result);
              } else {
                reject(`Distance matrix error: ${status}`);
              }
            }
          );
        });
      };
      
    //   FUNCTION TO CALL THE DISTANCE AND TIME CALCULATOR
    const handleCalculateDistance = async () => {
        try {
        const result:any = await calculateDistance();
        setDistance(result.distance);
        setDuration(result.duration)
        } catch (error) {
        console.log(error);
        }
    };

    // FUNCTION TO ADD FORM FOR SINGLE BOOKING
    const handleShowSingleForm = () => {
        if(origin?.trim()?.length > 3) {
            handleAddAddress(inputAddress)
            setShowSingleForm(true)
        }
    }

    // THIS SAVES THE SINGLE DESTINATION ADDRESS
    const handleSingleSave = () => {
        if(addresses?.[1]?.length !== 0 && origin?.trim()?.length > 0 && destination?.trim()?.length > 2 && singleName?.trim()?.length > 2 && singleNumber?.length !== 0) {
            if(inputAddress?.length !== 0) {
                handleEditAddress(inputAddress, 1)
            }
            setCalculateDistSingle(true)
        } else {
            if(origin?.trim()?.length > 0 && destination?.trim()?.length > 2 && singleName?.trim()?.length > 2 && singleNumber?.length !== 0) {
                if(inputAddress?.length !== 0) {
                    handleAddAddress(inputAddress)
                }
                setCalculateDistSingle(true)
                // locationModalControl()
                // setLocationBtn(true)
            }
        }

    }

    // FUNCTION TO CALL THE handleCalculateDistance
    const handleSingleDone = () => {
        handleCalculateDistance()
        locationModalControl()
        setLocationBtn(true)
        setCalculateDistSingle(false)
    }

    // USEFFECT THAT CALCULATES THE PRICE
    useEffect(() => {
        const pricePerKm = 40; 
        const pricePerHr = 15;
        const prices = ((distance / 1000) * pricePerKm) + ((duration/3600) * pricePerHr)
        setPrice(prices);
        setCalculatedAmt(prices || 0)
    }, [distance, duration])

    // HANDLE CALL FOR DONE BUTTONF FOR MULTIPLE BOOKING
    const handleMultipleDone = () => {
        handleCalculateDistance()
        locationModalControl()
        setLocationBtn(true)
        setMultiplyDeliveryArray(receiverInfoArray)
    }


  return (
    <div>
        <ModalOverlay activeModal={showDeliveryLocation}>
            <CancelModalBox onClick={() => locationModalControl()}/>
            <LocationModalBox>
                {
                    showDeleteQuestion && 
                    <DeleteQuestionWrap>
                        <DeleteQuestionBox>
                            <div className="question-wrap">
                                <p>Are you sure you want to delete this item?</p>
                            </div>

                            <div className="btns-wrap">
                                <InputBtn onClick={() => setShowDeleteQuestion(false)}>
                                    <p>No</p>
                                </InputBtn>

                                <YesBtn onClick={() => deleteForm(editAddressId, editFormId)}>
                                    <p>Yes</p>
                                </YesBtn>
                            </div>
                        </DeleteQuestionBox>
                    </DeleteQuestionWrap>
                }
                <div className="inner-modal-box">
                    {/* GOOGLE MAP  */}
                    <LocationBox>
                            <GoogleMap
                                center={center} 
                                zoom={10} 
                                mapContainerStyle={{width: '100%', height: '100%', borderRadius: '14px'}} 
                                options={{
                                    zoomControl: false,
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                    fullscreenControl: false
                                }}
                                // onClick={(e) => handleAddAddress(e.latLng)}
                            >
                                {addresses.map((address, index) => (
                                <Marker key={index} position={address} />
                                ))}

                                <Polyline path={addresses} 
                                options={{ strokeColor: "#FF0000" }}
                                />
                            </GoogleMap>
                    </LocationBox>



                    <DeliveryLocationWrap>

                        <h4>Where</h4>

                        <div className='where-pill-wrap'>
                            <WhereOptionPill onClick={() => {
                                setDeliveryType('single');
                                setDeliveryOption('single');
                            }}>
                                <WhereRadioPill pillActive={deliveryType === 'single' ? 'fill' : ''} />

                                <p>Single delivery </p>
                            </WhereOptionPill>

                            <WhereOptionPill onClick={() => {
                                setDeliveryType('multiple');
                                setDeliveryOption('multiple');
                            }}>
                                <WhereRadioPill pillActive={deliveryType === 'multiple' ? 'fill' : ''} />

                                <p>Multiple deliveries </p>
                            </WhereOptionPill>
                        </div>

                        <div className="location-input-overall">
                            <label>
                                Pickup location
                            </label>
                            <LocationInputWrap>
                                <InputIcons src={whereTag} iconWidth={'30px'} />
                                <Autocomplete className='autocomplete-wrap'
                                onPlaceChanged={onOriginPlaceChanged}
                                onLoad={(value) => setAutocompleteOrigin(value)}
                                >
                                    <input type="text" placeholder='Type here'
                                    // ref={originRef}
                                    value={origin}
                                    onChange={(e:any) => {setOrigin(e.target.value);
                                        setInputAddress(e.target.value)
                                        setEditPickupBtn(true)

                                        
                                    } }
                                    />
                                </Autocomplete> 
                                {
                                    editPickupBtn && 
                                    <InputBtn onClick={onEditPickupAddress}>
                                        <p>Save</p>
                                    </InputBtn> 
                                }
                            </LocationInputWrap>

                            {
                                // SINGLE DELIVERY FORM
                                deliveryType === 'single' ?
                                <>
                                    {
                                        showSingleForm &&
                                        <>
                                            <label>
                                                Delivery location
                                            </label>
                                            <LocationInputWrap>
                                                <InputIcons src={greyBox} iconWidth={'30px'} />
                                                <Autocomplete className='autocomplete-wrap'
                                                onPlaceChanged={onDestinationPlaceChanged}
                                                onLoad={(value) => setAutocomplete(value)}>
                                                    <input type="text" placeholder='Type here'
                                                    // ref={destinationRef}
                                                    value={destination}
                                                    onChange={(e:any) => {setDestination(e.target.value);
                                                        setInputAddress(e.target.value)
                                                    }}
                                                    />
                                                </Autocomplete>
                                                
                                                {/* <InputBtn>
                                                    <p>Done</p>
                                                </InputBtn> */}
                                            </LocationInputWrap>

                                            <InputWrapOverall>
                                                <LocationInputWrap>
                                                    <PhoneInput
                                                        defaultCountry='US'
                                                        international={true}
                                                        placeholder="Enter phone number"
                                                        value={singleNumber}
                                                        onChange={setSingleNumber}
                                                    />
                                                </LocationInputWrap>
                                            </InputWrapOverall>

                                            <InputWrapOverall>
                                                <LocationInputWrap>
                                                    <InputIcons src={mails} iconWidth={'30px'} />
                                                    <input type="email" placeholder='Reciever’s Email (otpional)'
                                                    // ref={mailRef}
                                                    value={singleMail}
                                                    onChange={(e:any) => setSingleMail(e.target.value)}
                                                    />
                                                </LocationInputWrap>
                                            </InputWrapOverall>

                                            <InputWrapOverall>
                                                <LocationInputWrap>
                                                    <InputIcons src={profile} iconWidth={'20px'} />
                                                    <input type="text" placeholder='Reciever’s Name'
                                                    // ref={nameRef}
                                                    value={singleName}
                                                    onChange={(e:any) => setSingleName(e.target.value)}
                                                    />
                                                </LocationInputWrap>
                                            </InputWrapOverall>

                                            <div className="save-add-wrapper">
                                                <SaveDeliveryBtn onClick={handleSingleSave} >
                                                    Save
                                                </SaveDeliveryBtn>

                                                {
                                                    calculateDistSingle &&
                                                    <DoneBtn onClick={handleSingleDone}>
                                                        Finish
                                                    </DoneBtn>
                                                }
                                            </div>    
                                        </>
                                    }
                        
                                    {
                                        !showSingleForm && 
                                        <SaveDeliveryBtn onClick={handleShowSingleForm}>
                                            Add Destination
                                        </SaveDeliveryBtn>
                                    }
                                </> :
                                // MULTIPLE DELIVERY FORM
                                <>  
                                    {
                                        showSubPage &&
                                        <>
                                            {receiverInfoArray?.length !== 0 && 
                                                <DeliveryInputOverall>
                                                {receiverInfoArray?.map((form, id) => 
                                                    <div 
                                                    className='input-box-wrapper'
                                                    key={id}
                                                    onClick={() => {handleShowEditForm(form, id+1, id)}}
                                                    // style={{transform: `translate(-${slideId * 100}%)`}}
                                                    >
                                                        <input type="text" className="input" value={form?.destination} readOnly/>
                                                        <div className="edit-btn">Edit</div>
                                                        <div className="absolute-txt">Delivery Location {id+1}</div>
                                                    </div>
                                                )}
                                                </DeliveryInputOverall>
                                            }

                                            <div className="save-add-wrapper">
                                                {receiverInfoArray?.length < 5 && 
                                                <SaveDeliveryBtn onClick={addForm}>Add Destination</SaveDeliveryBtn> 
                                                }
                                                

                                                {
                                                    receiverInfoArray?.length > 1 &&
                                                    <DoneBtn onClick={handleMultipleDone}>
                                                        Finish
                                                    </DoneBtn>
                                                }

                                            </div>
                                        </>
                                        
                                    }

                                    {
                                        switchForm && 
                                        <> 
                                            <MultipleDeliveryInput 
                                                setInputValue={setInputAddress}
                                                setOriginValue={setReceiverAddressMultiple}
                                                setPhoneValue={setReceiverNumMultiple}
                                                setMailValue={setReceiverMailMultiple}
                                                setNameValue={setReceiverNameMultiple}
                                            />
                                            <DoneBtn onClick={handleFormDone}>
                                                Done
                                            </DoneBtn>
                                        </>
                                    }

                                    {
                                        editSwitchForm && 
                                        <> 
                                            <MultipleDeliveryInput 
                                                setInputValue={setInputAddress}
                                                setOriginValue={setReceiverAddressMultiple}
                                                setPhoneValue={setReceiverNumMultiple}
                                                setMailValue={setReceiverMailMultiple}
                                                setNameValue={setReceiverNameMultiple}
                                                mail={selectedForm?.receiver_mail}
                                                name={selectedForm?.receiver_name}
                                                newAddress={selectedForm?.destination}
                                                value={selectedForm?.receiver_phone}
                                            />

                                            <div className="save-add-wrapper">
                                                <div className="left-wrap">
                                                    <SaveDeliveryBtn onClick={() => {
                                                        setShowSubPage(true)
                                                        setEditSwitchForm(false)
                                                    }}>
                                                        Back
                                                    </SaveDeliveryBtn>

                                                    <div className="delete-btn-wrap" onClick={() => setShowDeleteQuestion(true)}>
                                                        <img src={trash} />
                                                    </div>
                                                </div>


                                                <DoneBtn onClick={() => handleFormEdit(editAddressId, editFormId)}>
                                                    Done
                                                </DoneBtn>
                                            </div>

                                        </>
                                    }

                                    {/* <DeleteFormWrap>
                                        {
                                            forms?.length > 0 && 
                                            <DeleteFormBtn onClick={() => deleteForm(forms[forms?.length - 1]?.key)}>
                                                Delete Last Form
                                            </DeleteFormBtn>
                                        }
                                    </DeleteFormWrap> */}

                                    {/* <div className="save-add-wrapper">
                                        {
                                            forms?.length === 0 ?
                                            <SaveDeliveryBtn onClick={addForm}>Add Form</SaveDeliveryBtn> 
                                            : <>
                                                {
                                                    twistButton ? 
                                                    <SaveDeliveryBtn onClick={twistSaveAddForm}>
                                                        Add Form
                                                    </SaveDeliveryBtn> :
                                                    <SaveDeliveryBtn onClick={saveAddForm}>
                                                        Save & Add Form
                                                    </SaveDeliveryBtn>                          
                                                }
                                            </>
                                        }
                                        

                                    </div> */}

                                    {/* <ItemsBtnList>
                                        {forms?.map((child:any, id) => (
                                            <ItemBtn key={id} onClick={() => goToSlide(id)}
                                                active={id === slideId ? true : false}
                                            >
                                                Item {id + 1}
                                            </ItemBtn>
                                        ))}
                                    </ItemsBtnList>      */}
                                </>
                            }



                        </div>
                    </DeliveryLocationWrap>

                </div>
            </LocationModalBox>
        </ModalOverlay>
    </div>
  )
}

export default DeliveryLocationModal2