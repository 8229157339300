import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  showExtraNote: false
};
const showExtraNoteSlice = createSlice({
  name: 'showExtraNote',
  initialState,
  reducers: {
    setShowExtraNote: (state, action) => {
      return { ...state, showExtraNote: action.payload };
    },
    clearShowExtraNote: () => {
      return { showExtraNote: false };
    }
  }
});

const { reducer, actions } = showExtraNoteSlice;
export const { setShowExtraNote, clearShowExtraNote} = actions;
export default reducer;