import styled from 'styled-components'


export const Box = styled.div`
display:grid;
grid-template-columns:1fr 1fr;

gap:20px;
margin-top:50px;
@media only screen and (min-width: 900px) {
    grid-template-columns:1fr 1fr 1fr;

    }
`

export const H4 = styled.h4`
color:#000;
margin:0px;
font-size:13px;
font-weight:700;
`

export const ImageContainer = styled.div`
background:#fff;
position:relative;
border-radius:11px;
width:158px;

@media only screen and (min-width: 390px) and (max-width: 420px) {
    width:175px;
 
     }
@media only screen and (min-width: 900px) {
   width:240px;

    }
display:flex;
flex-direction:column;
margin-top:50px;

`
export const ImageDiv = styled.div`
position:relative;
width:100%;
overflow:hidden;
height:120px;
display:flex;
justify-content:center;
align-items:center;
border-radius:8px;

`
export const TextWrapper = styled.div`

position:relative;
margin-top:0px;
padding:10px;

`
export const PriceDiv = styled.div`
display:flex;
margin-top:10px;
justify-content:space-between;
align-items:center;
`
export const Price = styled.h5`
color:#AA6C05;
margin-top:0px;

`
export const Location = styled.h5`
color:#000;
font-size:10px;
margin-top:0px;

`
export const IconBox = styled.div`
color:#000;
font-size:10px;
margin-top:0px;

`

export const UserDiv = styled.div`
background:#000;
border-radius:50%;
width:30px;
height:30px;
padding:5px;
position:absolute;
top:12px;
left:12px;
display:flex;
justify-content:center;
align-items:center;

`
export const DateDiv = styled.div`
background:#000;
border-radius:7px;
width:30px;
height:30px;
padding:5px;
position:absolute;
top:12px;
right:12px;
display:flex;
justify-content:center;
align-items:center;

`
export const StatusOption = styled.div`
font-size:30px;
position:absolute;
bottom:-26px;
align-items:center;
display:flex;
justify-content:space-between;
width:100%;
`
export const StatusButton = styled.button`
font-size:11px;
background: #005FEE;
padding:3px 5px;
color: #fff;
outline:none;
border:none;
border-radius:5px;
@media only screen and (max-width: 390px)  {
    font-size: 10px;
}
`

