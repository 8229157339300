import styled from 'styled-components'



export const INPUTDIV = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:11px;
gap:5px;
position:relative;
padding:9px 11px;
@media only screen and (max-width: 380px) {
    padding:7px 11px;

    }
`
export const AirportInputDiv = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:11px;
gap:5px;
position:relative;
padding:9px 11px;
@media only screen and (max-width: 380px) {
    padding:7px 11px;

    }
    z-index: ${(props: any) => props.airportVisible ? '10' : '4'};

`


export const BLACKDIV = styled.div`
display:flex;
align-items:center;
background:#000;
width:42%;
justify-content:space-around;
border-radius:8px;
position:relative;
padding:10px 6px;
@media only screen and (max-width: 380px) {
    padding:7px 6px ;

    }
`

export const VerifyButton = styled.button`
background: #FFD58C;
color: #000;
padding:6px 4px;
outline:none;
border:none;
border-radius:5px;
font-size:1rem;
font-weight:bold;
margin-left:5px;
width:34%;
position:relative;
display:flex;
align-items:center;
justify-content:center;
gap:5px;
@media only screen and (max-width: 380px) {
    font-size:0.8rem;

    }
`
export const DISCOUNT = styled.span`
display:flex;
align-items:center;
justify-content:center;
background: #868686;
color:#fff;
padding:5px;
border-radius:5px;
font-size:11px;
position:relative;

`


export const FLEXDIV = styled.div`
display:flex;
align-items:center;
gap:10px;
width:100%;
margin-top:50px;
position:relative;
justify-content:space-between;

`
export const TITLE = styled.div`
display:flex;
gap:10px;
position:relative;
`
export const P = styled.p`
color:#fff;
font-size:9px;
position:absolute;
font-weight:normal;

`

export const MARKDIV = styled.div`
display:flex;
align-items:center;
background: #FFD58C;
border-radius:5px;
padding:0.2em;
z-index:8;

`

export const Wrapper = styled.div`



`
export const BlackOpacity = styled.div`



`

export const Text = styled.div`
font-size:15px;
margin-top:1px;
@media only screen and (max-width: 380px) {
  font-size:14px;


  }
`

export const Texts = styled.p`
font-size:12px;
top:-30px;
left:40px;
position:absolute;
`
export const DropDown = styled.div`
background: #000;
display:flex;
align-items:center;
justify-content:center;
padding:1px;
`

export const Div = styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
position:absolute;
width:90%;
z-index:10000;
top:-500px;
`
export const Modal = styled.div`
background: #000;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
padding:20px;
gap:10px;
border-radius:24px;
width:80%;
@media only screen and (min-width: 600px) {
  width:30%;

}
`
export const Option = styled.div`
background: #fff;
display:flex;
align-items:center;
justify-content:flex-start;
padding:10px;
gap:10px;
width:100%;
color:#000;
border-radius:6px;
font-weight:600;
`
export const Labels = styled.label`

width:100%;


`
export const Inputs = styled.div`
width: 100%; 
outline: none; 
border: none; 
color: #fff; 
background: transparent;
font-size:14px;
font-weight:medium;
overflow:hidden;
  ::placeholder, ::-webkit-input-placeholder {
    color: #fff; 
  }
@media only screen and (max-width: 380px) {
font-size:13px;
    
    }
`

export const PriceInput = styled.input`
width: 100%; 
outline: none; 
border: none; 
color: #fff; 
background: transparent;
font-size:14px;
font-weight:medium;
overflow:hidden;
  ::placeholder, ::-webkit-input-placeholder {
    color: #fff; 
  }
@media only screen and (max-width: 380px) {
font-size:13px;
    
    }
`
export const LocationInput = styled.input`
width:50%;
padding:20px 6px;
border:none;
outline:none;
width:100%;
font-size:16px;
::placeholder, ::-webkit-input-placeholder {
    font-size: 16px;
    color:#242321;
    font-weight:500;

}
@media only screen and (max-width: 380px) {
    padding:17px 6px;
    font-size:14px;
        ::placeholder, ::-webkit-input-placeholder {
            font-size: 14px;
        }
`


export const ButtonArea = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
width:100%;
`
export const SaveButton = styled.button`
background: linear-gradient(0deg, #868686, #D9D9D9);
padding:7px 10px;
font-size:15px;
font-weight:bold;
border:none;
border-radius:5px;
color:#000;
`
export const DoneButton = styled.button`
background: #ffa100;
padding:7px 10px;
font-size:15px;
font-weight:bold;
border:none;
border-radius:5px;
color:#000;

`
