import React, { FC, useEffect, useState, useRef } from "react";
// import { DROPDOWNCOL } from '../../providerDriverReg/thirdScreen/thirdScreen.styles';
import {
  DIVS,
  DROPDOWN,
  DROPDOWNCOL,
  DROPDOWNDIV,
  P,
  Button,
  PPP,
} from "../../providerDriverReg/thirdScreen/thirdScreen.styles";
import { useDriverUpdateMutation } from "../../../services/providerApi";

const DriverAvailability: FC<{ driverProfile: any }> = ({ driverProfile }) => {
  const [driverDetails, setDriverDetails] = useState<any>();
  const [available, setAvailable] = useState<any>(false);
  // const [driverAvailability, setDriverAvailability] = useState<any>([])

  useEffect(() => {
    if (driverProfile && driverProfile.data && driverProfile.data.length > 0) {
      setDriverDetails(driverProfile.data[0]);
      // setDriverAvailability(driverProfile.data[0]?.availability1)
    }
  }, [driverProfile]);

  const [updateDriver, { data, isLoading, isSuccess, isError, error }] =
    useDriverUpdateMutation();

  let [carDriver, setCarDriver] = useState(
    driverDetails?.availability1?.includes("Car Driving") ? true : false
  );
  const [bikeDriver, setBikeDriver] = useState(
    driverDetails?.availability1?.includes("Bike Driving") ? true : false
  );
  const [fullTime, setFullTime] = useState(
    driverDetails?.availability1?.includes("Full Time Driving") ? true : false
  );
  const [truckDriver, setTruckDriver] = useState(
    driverDetails?.availability1?.includes("Truck Driving") ? true : false
  );

  let obj = {
    lastname: driverDetails?.lastname,
    driver_id: driverDetails?.id,
    availability1: driverDetails?.availability1,
    availability: driverDetails?.availability1,
    address: driverDetails?.address,
    phone_number: driverDetails?.phone_number,
    firstname: driverDetails?.firstname,
    gender: driverDetails?.gender,
    country: driverDetails?.country,
    state: driverDetails?.state,
    id_number: driverDetails?.id_number,
    identification: driverDetails?.identification,
    dob: driverDetails?.dob,
    latitude: driverDetails?.latitude,
    longitude: driverDetails?.longitude,
  };


  const carDriving = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setCarDriver(true);
    let newArr = [...driverDetails?.availability1, "Car Driving"];
    let updatedDetails = { ...driverDetails, availability1: newArr };
    setDriverDetails(updatedDetails);
    setAvailable(true)
  };
  const remCarDriving = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setCarDriver(false);
    const removeele = driverDetails?.availability1?.filter((f: any) => {
      return f !== "Car Driving";
    });
    if (removeele) {
      setDriverDetails({
        ...driverDetails,
        availability1: removeele,
      });
    }
    setAvailable(true)

  };

  const remBikeDriving = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setBikeDriver(false);
    const removeele = driverDetails?.availability1?.filter((f: any) => {
      return f !== "Bike Driving";
    });

    setDriverDetails({
      ...driverDetails,
      availability1: removeele,
    });
    setAvailable(true)

  };

  const bikeDriving = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setBikeDriver(true);
    let newArr = [...driverDetails?.availability1, "Bike Driving"];

    let updatedDetails = { ...driverDetails, availability1: newArr };
    setDriverDetails(updatedDetails); // Update the driverDetails state
    setAvailable(true)

  };

  const remFullTimeDriving = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setFullTime(false);
    const removeele = driverDetails?.availability1?.filter((f: any) => {
      return f !== "Full Time Driving";
    });

    setDriverDetails({
      ...driverDetails,
      availability1: removeele,
    });
    setAvailable(true)

  };

  const fullTimeDriving = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setFullTime(true);
    let newArr = [...driverDetails?.availability1, "Full Time Driving"];

    let updatedDetails = { ...driverDetails, availability1: newArr };
    setDriverDetails(updatedDetails); // Update the driverDetails state
    setAvailable(true)

  };

  const remTruckDriving = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setTruckDriver(false);
    const removeele = driverDetails?.availability1?.filter((f: any) => {
      return f !== "Truck Driving";
    });

    setDriverDetails({
      ...driverDetails,
      availability1: removeele,
    });
    setAvailable(true)

  };

  const truckDriving = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setTruckDriver(true);
    let newArr = [...driverDetails?.availability1, "Truck Driving"];
    let updatedDetails = { ...driverDetails, availability1: newArr };
    setDriverDetails(updatedDetails); // Update the driverDetails state
    setAvailable(true)

  };

 
  useEffect(() => {
    //@ts-ignore
    updateDriver([obj]);
  }, [available]);

  return (
    <>
      <>
        <P>Select all that works with your schedule</P>
        <DROPDOWNCOL>
          <DROPDOWN>
            <DROPDOWNDIV>
              {/* this removes the availability option */}

              {driverDetails?.availability1?.includes("Car Driving") ? (
                <DIVS onClick={remCarDriving}>
                  <Button style={{ background: "#ffa100" }}></Button>
                  <PPP>Car Driving</PPP>
                </DIVS>
              ) : (
                <DIVS onClick={carDriving}>
                  <Button style={{ background: "#fff" }}></Button>
                  <PPP>Car Driving</PPP>
                </DIVS>
              )}
              {driverDetails?.availability1?.includes("Bike Driving") ? (
                <DIVS onClick={remBikeDriving}>
                  <Button style={{ background: "#ffa100" }}></Button>
                  <PPP>Bike Driving</PPP>
                </DIVS>
              ) : (
                <DIVS onClick={bikeDriving}>
                  <Button style={{ background: "#fff" }}> </Button>
                  <PPP>Bike Driving</PPP>
                </DIVS>
              )}
            </DROPDOWNDIV>
            <DROPDOWNDIV>
              {driverDetails?.availability1?.includes("Full Time Driving") ? (
                <DIVS onClick={remFullTimeDriving}>
                  <Button style={{ background: "#ffa100" }}></Button>
                  <PPP>Full-time Driving</PPP>
                </DIVS>
              ) : (
                <DIVS onClick={fullTimeDriving}>
                  <Button style={{ background: "#fff" }}></Button>
                  <PPP>Full-time Driving</PPP>
                </DIVS>
              )}
              {driverDetails?.availability1?.includes("Truck Driving") ? (
                <DIVS onClick={remTruckDriving}>
                  <Button style={{ background: "#ffa100" }}></Button>
                  <PPP>Truck Driving</PPP>
                </DIVS>
              ) : (
                <DIVS onClick={truckDriving}>
                  <Button style={{ background: "#fff" }}> </Button>
                  <PPP>Truck Driving</PPP>
                </DIVS>
              )}
            </DROPDOWNDIV>
          </DROPDOWN>
        </DROPDOWNCOL>
      </>
    </>
  );
};
export default DriverAvailability;
