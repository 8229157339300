import styled from 'styled-components'



export const INPUTDIV = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:11px;
gap:5px;
padding:9px 11px;
@media only screen and (max-width: 380px) {
    padding:7px 11px;

    }
`
export const FullDayInput = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:11px;
gap:5px;
padding:9px 11px;
@media only screen and (max-width: 380px) {
    padding:7px 11px;

    }
    z-index: ${(props: any) => props.fullDayServiceVisible ? '10' : '4'};

`
export const HalfDayInput = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:11px;
gap:5px;
padding:9px 11px;
@media only screen and (max-width: 380px) {
    padding:7px 11px;

    }
    z-index: ${(props: any) => props.halfDayServiceVisible ? '10' : '4'};

`
export const HourlyInput = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:11px;
gap:5px;
padding:9px 11px;
@media only screen and (max-width: 380px) {
    padding:7px 11px;

    }
    z-index: ${(props: any) => props.halfDayServiceVisible ? '10' : '4'};

`

export const ServiceLabel = styled.label`
color:#242321;
font-size:13px;
font-weight:medium;
width:40%;
display:flex;
@media only screen and (max-width: 380px) {
    font-weight:medium;
    }
`


export const BLACKDIV = styled.div`
display:flex;
align-items:center;
background:#000;
width:42%;
justify-content:space-around;
border-radius:8px;
position:relative;
padding:9px 6px;
@media only screen and (max-width: 380px) {
    padding:7px 6px ;

    }
`
export const Input = styled.div`
width: 50%; 
outline: none; 
border: none; 
color: #fff; 
background: transparent;
overflow:hidden;
font-size:14px;
flex:1;
::placeholder, ::-webkit-input-placeholder {
    color:#fff;
    font-weight:medium;
    font-size:14px;
    
}
`

export const HourButton = styled.button`
background: #FFD58C;
color: #000;
padding:4px 6px;
outline:none;
border:none;
border-radius:5px;
font-size:1rem;
font-weight:bold;
margin-left:5px;
position:relative;
display:flex;
align-items:center;
justify-content:space-between;
gap:8px;
`
export const DISCOUNT = styled.span`
display:flex;
align-items:center;
justify-content:center;
background: #868686;
color:#fff;
padding:5px;
border-radius:5px;
font-size:13px;
position:relative;

`


export const FLEXDIV = styled.div`
display:flex;
align-items:center;
gap:10px;
width:90%;
margin-top:50px;
position:relative;

`

export const P = styled.p`
color:#fff;
font-size:9px;
position:absolute;
font-weight:normal;

`

export const MARKDIV = styled.div`
display:flex;
align-items:center;
background: #FFD58C;
border-radius:5px;
padding:0.2em;
position:absolute;
right:-35px;
z-index:8;
`
export const Wrapper = styled.div`



`
export const BlackOpacity = styled.div`



`


export const Text = styled.div`
font-size:16px;
margin-top:3px;
@media only screen and (max-width: 380px) {
    font-size:11px;

    }
`
export const DropDown = styled.div`
background: #000;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
padding:1px;
`

export const Div = styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
position:absolute;
right:50px;
width:150px;
z-index:1000;
top:60px;
`
export const Modal = styled.div`
background: #000;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
padding:20px;
gap:10px;
border-radius:6px;
width:80%;

`
export const Option = styled.div`
background: #fff;
display:flex;
align-items:center;
justify-content:flex-start;
padding:10px;
gap:10px;
width:100%;
color:#000;
border-radius:6px;
font-weight:600;
`
export const Label = styled.label`

width:100%;


`

