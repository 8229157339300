import React, { FC, useEffect, useRef, useState } from "react";
import { BackgroundBox, Button, H3, ModalBox } from "./index.styles";
import { Link } from "react-router-dom";

import deleteImage from "../../../assets/images/png/modalOption.png";
import { useAppDispatch, useAppSelector } from "../../../store";
import axios from "axios";
import { baseUrl } from "../../../services/providerApi";
import { setGarage, setTravels } from "../../../slices/travelPassengers";
import { ToastContainer, toast } from "react-toastify";

const DeleteTravelModal: FC<{ setIsDeleteAction; loneTravel }> = ({
  loneTravel,
  setIsDeleteAction,
}) => {
  const dispatch: any = useAppDispatch();

  const user = useAppSelector((state: any) => state?.auth.auth);
  
  const handleCancel = () => {
    let formData: any = new FormData();
    formData.append("id", loneTravel?.id);
    axios({
      method: "post",
      url: `${baseUrl}delete-travel-garage`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    }).then((response: any) => {
      dispatch(setTravels(response?.data?.data))
      dispatch(setGarage(response?.data?.data));

      notifySuccess(
        "This Vehicle has been successfully deleted on the travel garage"
      );
      setIsDeleteAction((prev) => !prev);
    });
  };

  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  return (
    <>
      <ToastContainer />

      <BackgroundBox onClick={() => setIsDeleteAction((prev: any) => !prev)}>
        <ModalBox onClick={(e: any) => e.stopPropagation()}>
          <div className="image-container">
            <img src={deleteImage} style={{ width: "100%" }} />
          </div>
          <div className="text-container">
            <h2>Note</h2>
            &nbsp;
            <>
              <p>
                Are You sure you want to de-list or delete thsi travel listing?.
              </p>
              <div className="buttons">
                <button className="black" onClick={()=>handleCancel()}>Delete</button>

                <button className="theme" onClick={()=>setIsDeleteAction(false)}>Go Back</button>
              </div>
            </>
          </div>
        </ModalBox>
      </BackgroundBox>
    </>
  );
};

export default DeleteTravelModal;
