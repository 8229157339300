import styled from 'styled-components'

export const Page = styled.div`
display:flex;
background: #242321;
padding:10px 0px;
width:100%;
flex-direction:column;
min-height:100vh;
padding:2em;

`
export const H3 = styled.h2`
font-weight:600;
color:#fff;
text-align:left;
`
export const P = styled.p`
font-weight:500;
color:#fff;
font-size:15px;
margin:0px 0px 50px 0px;
`
export const LoaderContainer = styled.div`
display:flex;
gap:10px;
margin-bottom:60px;
margin-top:0px;
height:3px;
width:70%;
`
export const DivContainer = styled.div`
display:flex;
background-color:#fff;
height:3px;
width:100%;
`
export const Loader = styled.div`
display:flex;
background: #FFA100;
width:50%;
`
export const Loader2 = styled.div`
display:flex;
background: #fff;
width:50%;
`
export const Button = styled.button`
background: #FFA100;
color: #000;
width:100%;
border-radius:30px;
padding:12px 10px;
margin-top:3rem;
width:55%;
outline:none;
border:none;
font-size:.8rem;
font-weight:bold;
`
export const DIV = styled.div`
display:flex;
justify-content:center;
align-items:center;
`