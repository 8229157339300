import React, { FC, useEffect, useState } from 'react'
import {
    Modal, Label, Flex, ImageContainer, TextContainer, BackButton, DeleteButton, ModalContainer
} from './index.styles';
import deleteImage from '../../../assets/images/png/modalOption.png'
import spinner from '../../../assets/images/gif/spinner.gif'

import 'react-toastify/dist/ReactToastify.css';

// import { ToastContainer, toast } from 'react-toastify'
import { useDeleteDriverMutation, useDeleteVehicleMutation } from '../../../services/providerApi';
import axios from 'axios';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setAllVehicle } from '../../../slices/provider';
import { useVerifyDriverMutation, useVerifyVehicleMutation } from '../../../services/adminApi';
import toast from 'react-hot-toast'
import { Toaster } from "react-hot-toast";
type Props = {
    isDriver?: boolean;
    isVehicle?: boolean;
    id: any;
    admin?: boolean;
    setIsDelete?: any;
    isProvider?: boolean;
    vehicleDisplay?: string
    vehicleStatus?: string;
    setIsHide?: any;
    isHide?: boolean;
    driverStatus?: string;
    driverDisplay?: string;
    isDelete?: boolean;
    setHide?: any;
    setVehicleUnHide?:any;
    setDriverHide?: any;
    setDriverUnHide?: any;

}
export const DeleteModal: FC<Props> = ({setVehicleUnHide, setDriverUnHide, setDriverHide, setHide, isDelete, setIsHide, driverDisplay, driverStatus, isHide, admin, vehicleDisplay,
    vehicleStatus, isProvider, isVehicle, isDriver, id, setIsDelete }) => {
    const token = useAppSelector((state: any) => state?.auth?.auth?.token)
    const allVehicle = useAppSelector((state: any) => state?.provider.allVehicle)

    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    //@ts-ignore
    const dispatch = useAppDispatch()


    const [deleteVehicle, { data, isLoading, isSuccess, isError, error }] = useDeleteVehicleMutation()
    const [deleteDriver, { data: driverDelete, isLoading: driverLoading, isSuccess: driverSuccess, isError: driverIsError, error: driverError }] = useDeleteDriverMutation()
    const [hideVehicle, { data: hideData, isLoading: hideLoading, isSuccess: hideSuccess, isError: hideIsError, error: hideError }] = useVerifyVehicleMutation()
    const [hideDriver, { data: hideDriverData, isLoading: hideDriverLoading, isSuccess: hideDriverSuccess, isError: hideDriverIsError, error: hideDriverError }] = useVerifyDriverMutation()


    useEffect(() => {
        if (isLoading) {

        }
        if (isSuccess) {
            // dispatch(setAllVehicle([]))
            const updatedVehicle = data?.data;
            // dispatch(setAllVehicle(data?.data))

            dispatch(setAllVehicle(updatedVehicle))
            setIsDelete(false)


        }
        if (isError && 'status' in error!) {
            if (isError && error.status.toString() === 'FETCH_ERROR') {
                // notifyError('Network Connection Failed')
                setIsDelete(false)
            }
            // setBtnLoad(false)
        }
    }, [isLoading, isSuccess, isError, data])


    let promise: any;

    const handleHide = async () => {
        if (vehicleStatus !== "Approved") {

            toast.error('A vehicle must be approved before you can hide them!')

        } else {
            let formData: any = new FormData()
            formData.append('id', id)
            formData.append("display", '0');
            formData.append("status", 'Pending');
            hideVehicle(formData).then((response: any) => {

                console.log(response?.data?.data?.display)
                if (response?.data?.data?.display === '0') {
                    toast.success('Vehicle Status successfully hidden ')
                    setHide(true)

                }
                setTimeout(() => {
                    toast.dismiss(promise);
                }, 0);

            }).catch((err) => {

            })
        }
    }

    const handleHideDriver = async () => {
        if (driverStatus !== "Approved") {

            toast.error('A Driver must be approved before you can hide them!')

        } else {
            let formData: any = new FormData()
            formData.append('id', id)
            formData.append("display", '0');
            formData.append("status", 'Pending');
            hideDriver(formData).then((response: any) => {

                console.log(response?.data?.data?.display)
                if (response?.data?.data?.display === '0') {
                    toast.success('Driver Status successfully hidden ')
                    setDriverHide(true)
                }
                setTimeout(() => {
                    toast.dismiss(promise);
                }, 0);

            }).catch((err) => {

            })
        }
    }

    const handleUnHide = async () => {
        let formData: any = new FormData()
        formData.append('id', id)
        formData.append("display", '1');
        formData.append("status", 'Approved');
        hideVehicle(formData).then((response:any) => {
            if (response?.data?.data?.display === '1') {
                toast.success('Vehicle Status successfully open ')
                setVehicleUnHide(true)

            }
        }).catch((err) => {

        })
    }

    const handleUnHideDriver = async () => {
        let formData: any = new FormData()
        formData.append('id', id)
        formData.append("display", '1');
        formData.append("status", 'Approved');
        hideDriver(formData).then((response: any) => {
            if (response?.data?.data?.display === '1') {
                toast.success('Driver Status successfully open ')
                setDriverUnHide(true)

            }
        }).catch((err) => {

        })
    }

    const handleDelete = async () => {
        let formData: any = new FormData()
        formData.append('id', id)
        deleteVehicle(formData)
    }


    const handleDeleteDriver = async () => {
        let formData: any = new FormData()
        formData.append('id', id)
        deleteDriver(formData)
    }
    const handleDeleteProvider = async () => {
        let formData: any = new FormData()
        formData.append('id', id)
        deleteVehicle(formData)
    }

    const handleDeleteModal = async () => {
        setIsDelete(false)
        setIsHide(false)
    }




    // const notifyError = (text: any) => toast.error(text, {
    //     position: "bottom-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",

    // })

    return (
        <>
            {/* <ToastContainer /> */}
            <Toaster />


            <ModalContainer onClick={() => handleDeleteModal()}>

                <Modal>
                    <ImageContainer>
                        <img src={deleteImage} style={{ width: '100%' }} />
                    </ImageContainer>
                    <TextContainer>
                        <Label>
                            Are you sure you want to



                            {admin ? 'hide' : 'delete '} this
                            {isDriver && ' driver? '}
                            {isVehicle && ' vehicle? '}
                            {isProvider && ' provider? '}
                            {admin ? "it will not be made available for customers" : "It will be moved to your trash."}

                        </Label>

                        <Flex>
                            <BackButton onClick={handleDeleteModal}>
                                Go Back
                            </BackButton>


                            {(isVehicle && !admin) && <>
                                {

                                    isLoading ?
                                        <DeleteButton>
                                            loading <img src={spinner} width={50} />
                                        </DeleteButton> :

                                        <DeleteButton onClick={handleDelete}>
                                            Delete
                                        </DeleteButton>
                                }
                            </>}

                            {(isVehicle && admin) && (
                                <>
                                    {isLoading ? (
                                        <DeleteButton>
                                            loading <img src={spinner} width={50} alt="Loading" />
                                        </DeleteButton>
                                    ) : (
                                        (vehicleStatus === '0') ? (
                                            <DeleteButton onClick={handleUnHide}>
                                                Un-Hide
                                            </DeleteButton>
                                        ) : (
                                            <DeleteButton onClick={handleHide}>
                                                Hide
                                            </DeleteButton>
                                        )
                                    )}
                                </>
                            )}


                            {/* {(isDriver && isDelete) &&<>
                                {

                                    isLoading ?
                                        <DeleteButton>
                                            loading <img src={spinner} width={50} />
                                        </DeleteButton> :

                                        <DeleteButton onClick={handleDeleteDriver}>
                                            Delete
                                        </DeleteButton>
                                }
                            </>} */}

                            {(isDriver && isDelete) && (
                                <>
                                    {isLoading ? (
                                        <DeleteButton>
                                            loading <img src={spinner} width={50} alt="Loading" />
                                        </DeleteButton>
                                    ) : (
                                        (driverDisplay === '0') ? (
                                            <DeleteButton onClick={handleUnHideDriver}>
                                                Un-Hide
                                            </DeleteButton>
                                        ) : (
                                            <DeleteButton onClick={handleHideDriver}>
                                                Hide
                                            </DeleteButton>
                                        )
                                    )}
                                </>
                            )}

                            {/* {(isDriver && isHide) && <>
                                {

                                    isLoading ?
                                        <DeleteButton>
                                            loading <img src={spinner} width={50} />
                                        </DeleteButton> :

                                        <DeleteButton onClick={handleHideDriver}>
                                            Hides
                                        </DeleteButton>
                                }
                            </>} */}
                            {(isDriver && isHide && admin) && (
                                <>
                                    {isLoading ? (
                                        <DeleteButton>
                                            loading <img src={spinner} width={50} alt="Loading" />
                                        </DeleteButton>
                                    ) : (
                                        (driverDisplay === '0') ? (
                                            <DeleteButton onClick={handleUnHideDriver}>
                                                Un-Hide
                                            </DeleteButton>
                                        ) : (
                                            <DeleteButton onClick={handleHideDriver}>
                                                Hide
                                            </DeleteButton>
                                        )
                                    )}
                                </>
                            )}


                            {isProvider && <>
                                {

                                    isLoading ?
                                        <DeleteButton>
                                            loading <img src={spinner} width={50} />
                                        </DeleteButton> :

                                        <DeleteButton onClick={handleDeleteProvider}>
                                            Delete
                                        </DeleteButton>
                                }
                            </>}
                        </Flex>
                    </TextContainer>


                </Modal>
            </ModalContainer>

        </>

    )
}


