import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import { RiUser3Fill } from 'react-icons/ri';
import { FaSuitcase } from 'react-icons/fa';
import {
    DIV, H2, H4, DivContainer, IconDiv1, LoaderContainer, Loader, Loader2, Dotted, Container, IconDivContainer
} from './firstScreen.styles';
import Footer from '../../../components/footer'
import { useAppDispatch, useAppSelector } from '../../../store';
import { setAccount } from '../../../slices/provider';
import { FiChevronLeft } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

type Props = {
    showFirstScreen: boolean;
    setShowFirstScreen: (val: boolean) => void;
    providerDetails: any;
    setProviderDetails: any
};
const FirstScreen: FC<Props> = ({ showFirstScreen, setShowFirstScreen, providerDetails, setProviderDetails }) => {

    const account = useAppSelector((state: any) => state?.provider?.account_type)

    const dispatch = useAppDispatch()

    const handleIndividualClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>,) => {
        dispatch(setAccount('Individual'))
        setProviderDetails({
            ...providerDetails, account_type: 'Individual'
        })
        setShowFirstScreen(false)

    };
    const handleBusinessClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>,) => {

        dispatch(setAccount('Business'))
        setProviderDetails({
            ...providerDetails, account_type: 'Business'
        })
        setShowFirstScreen(false)
    };
    const navigate = useNavigate()


    return (
        <>
            <DIV>
                <div style={{ display: 'flex', gap: 10, alignItems: 'center', flexDirection: 'row' }}>

                    <FiChevronLeft
                        onClick={() => {
                            navigate('/choose-service')
                        }}
                        style={{ marginLeft: '-7px', color: '#fff', fontSize: 35 }} />
                    <H2>Set up your account </H2>

                </div>
            </DIV>
            <H4>Earn With Roadlers </H4>
            <LoaderContainer>
                <DivContainer>
                    <Loader />
                    <Loader2 />
                </DivContainer>

                <DivContainer>
                    <Loader2 />
                    <Loader2 />
                </DivContainer>

                <DivContainer>
                    <Loader2 />
                    <Loader2 />
                </DivContainer>


            </LoaderContainer>
            <H4>Are you a business or individual?</H4>

            <Container>

                <IconDivContainer>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <IconDiv1 onClick={handleIndividualClick}>
                            <Dotted style={account === 'Individual' ?{ background: '#ffa100' }:{ background: '#fff' }} />
                            <RiUser3Fill style={account === 'Individual' ? { fontSize: '50px', color: '#ffa100' } : { fontSize: '50px', color: '#000' }} />
                            <p style={{ fontWeight: '600', marginTop: '5px', marginBottom: '0px', color: '#000' }}>Individual</p>

                        </IconDiv1>
                        <p style={{ fontSize: '12px', marginTop: '8px', marginBottom: '0px', color: '#fff', textAlign: 'center', fontWeight: 400 }}>
                            List personal vehicles or become a driver
                        </p>
                    </div>

                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',

                    }}>
                        <IconDiv1 onClick={handleBusinessClick}>
                            <Dotted style={account === 'Business' ?{ background: '#ffa100' }:{ background: '#fff' }}/>
                            <FaSuitcase style={account === 'Business' ? { fontSize: '50px', color: '#ffa100' } : { fontSize: '50px', color: '#000' }} />
                            <p style={{ fontWeight: '600', marginTop: '5px', marginBottom: '0px', color: '#000' }}>Business</p>
                        </IconDiv1>
                        <p style={{ fontSize: '12px', marginTop: '8px', marginBottom: '0px', color: '#fff', textAlign: 'center' }}>
                            List multiple vehicles as a logistics or car rental business
                        </p>

                    </div>

                </IconDivContainer>
            </Container>
        </>

    )
}

export default FirstScreen