import React from 'react'
// import ToggleBar from '../../../components/toggleBar'

import {
    StatsBox, P, IconDiv, H3, Button, Div, StatsDiv, ButtonGroup, Stat
} from './stats.styles';
import signups from '../../../assets/images/png/signups.png'

import { BsCalendar3, BsFillFolderFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
const StatsThree = ({ totalCount }: any) => {
    let overAllCount;
    if (totalCount !== undefined) {

        //@ts-ignore
        // overAllCount = Object.values(totalCount).reduce((acc, count) => acc + count, 0);

        const keysToExclude = ["totalRequests", "totalVehicles", "totalDrivers", "totalProvider"];

        //@ts-ignore
        overAllCount = Object.values(totalCount).reduce((acc, count, index) => {
            if (!keysToExclude.includes(Object.keys(totalCount)[index])) {
                //@ts-ignore
                return acc + count;
            }
            return acc;
        }, 0);

    }
    return (
        <>
            <StatsDiv>
                <StatsBox>
                    <Link to='/list-drivers' style={{ textDecoration: 'none' }}>

                        <P>Total Sign-Ups</P>
                        <IconDiv >
                            <BsCalendar3 style={{ fontSize: '11px' }} />
                        </IconDiv>

                        <div style={{ display: 'flex', width: '100%', alignItems: 'center', gap: '10px' }}>
                            <img src={signups} alt="" width="35px" />

                            <H3>{overAllCount}</H3>
                        </div>
                    </Link>
                </StatsBox>
                <Div>

                    <ButtonGroup>
                        <Button style={{ background: '#7B56A0', }}>
                            Customers
                        </Button>

                        <Stat>{totalCount?.totalSignUp || 0}</Stat>

                    </ButtonGroup>
                    <ButtonGroup>
                        <Button style={{ background: '#78C193 ', color: '#000' }}>
                            Providers
                        </Button>

                        <Stat>{totalCount?.totalIndividualProvider || 0}</Stat>

                    </ButtonGroup>

                    <ButtonGroup>
                        <Button style={{ background: '#74ABFF', color: '#000' }}>
                            Businesses
                        </Button>

                        <Stat>{totalCount?.totalBusiness || 0}</Stat>

                    </ButtonGroup>



                </Div>
            </StatsDiv>
        </>
    )
}

export default StatsThree