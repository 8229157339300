import styled from 'styled-components'

export const BUTTON = styled.button`
background:transparent;
border:none;
outline:none;
font-size:30px;
color:#fff;
display:flex;
align-items:center;



`
export const DIV = styled.div`
display:flex;
gap:5px;
align-items:center;
padding-top:20px;
justify-content:flex-start;
width:100%;

`

export const H2 = styled.h2`
color:#fff;
font-weight:bold;
`
export const H4 = styled.h4`
font-weight:500;
color:#fff;

`
export const Container = styled.div`
display:flex;
justify-content:space-between;
align-items:center;
margin-bottom:60px;

`

export const LoaderContainer = styled.div`
display:flex;
gap:10px;
margin-bottom:60px;
margin-top:50px;
height:3px;
width:70%;

`
export const DivContainer = styled.div`
display:flex;
background-color:#fff;
height:3px;
width:100%;


`

export const Loader = styled.div`
display:flex;
background: #FFA100;
width:50%;
`
export const Loader2 = styled.div`
display:flex;
background: #fff;
width:50%;
`

export const IconDivContainer = styled.div`
display:flex;
justify-content:space-between;
gap:40px;
width:100%;

`
export const IconDiv1 = styled.div`
background-color: #fff;
border-radius:5px;
width:100%;
height:110px;
position:relative;
display:flex;

flex-direction:column;
gap:0px;

justify-content:center;
align-items:center;
`
export const IconDiv2 = styled.div`
background-color: #fff;
border-radius:8px;

`
export const Dotted = styled.div`
border-radius:50%;
width:18px;
height:18px;
position:absolute;
top:10px;
left:10px;
border:3px solid black;


`
