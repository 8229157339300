import React, { FC, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'

import xmark from "../../../assets/images/png/X.png";
import mark from "../../../assets/images/png/Y.png";
import { IoIosArrowDown } from "react-icons/io";

import {
  VerifyButton,
  DISCOUNT,
  INPUTDIV,
  BLACKDIV,
  FLEXDIV,
  P,
  MARKDIV,
  Wrapper,
  BlackOpacity,
  Text,
  DropDown,
  PriceInput,
  AirportInputDiv,
} from "./fifthScreen.styles";
import Footer from "../../footer";
import { ModalOne, TravelDiscountModal } from "./Modal";
import { setShowFilter } from "../../../slices/showFilter";
import { useAppDispatch, useAppSelector } from "../../../store";
import DiscountModal from "../../discountModal";
import {
  setServiceVisibility,
  setVehicleDetailss,
} from "../../../slices/provider";
import { Input, ServiceLabel } from "../fourthScreen/fourthScreen.styles";
import { DeleteModal } from "../deleteModal";
import CurrencyInput from "react-currency-input-field";
import { formatCurrencyPrefix } from "../../../services/utils";

const FifthScreen: FC<{
  setVehicleDetails: any;
  vehicleDetails: any;
  setWidth: any;
  setQuestion: any;
}> = ({ vehicleDetails, setVehicleDetails, setWidth, setQuestion }) => {

  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );
  
  const vehicleInfo: boolean = useAppSelector(
    (state: any) => state?.provider?.vehicleDetails
  );
  const airportVisible: boolean = useAppSelector(
    (state: any) => state?.provider?.serviceVisibility?.airportVisible
  );
  const travelVisible: boolean = useAppSelector(
    (state: any) => state?.provider?.serviceVisibility?.travelVisible
  );

  //@ts-ignore
  let airportPriceValue = vehicleInfo?.service_price?.filter(
    (price: { service_title?: string }) => {
      return price?.service_title?.includes("Airport Pickup");
    }
  );

  const [airportDiscount, setAirportDiscount] = useState(
    airportPriceValue[0]?.discount || 0
  );

  const [isAirportDiscountVisible, setIsAirportDiscountVisible] =
    useState(false);
  const [isTravelDiscountVisible, setIsTravelDiscountVisible] = useState(false);

  const [isAirportDel, setIsAirportDel] = useState(false);

  const [isTravelVisible, setTravelVisible] = useState(true);

  // modals
  const [isVisibleModalOne, setIsVisibleModalOne] = useState(false);
  const [isVisibleModalTwo, setIsVisibleModalTwo] = useState(false);

  // travels
  const [isVisibleTravelTwo, setIsVisibleTravelTwo] = useState(false);

  const [airportPrice, setAirportPrice] = useState(
    airportPriceValue[0]?.price || ""
  );

  // state for first travel options
  const [travelDetails, setTravelDetails] = useState();
  const [travelPrice, setTravelPrice] = useState("");

  const dispatch: any = useAppDispatch();

  useEffect(() => {
    setWidth(65);
    setQuestion(5);
  }, []);

  const BlurOptionTravel = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setTravelVisible((prev) => !prev);
  };

  const BlurOptionAirport = () => {
    setIsAirportDel((prev) => !prev);
  };

  const deleteTravel = (travels: any) => {
    //@ts-ignore
    setVehicleDetails((prevDetails) => {
      //@ts-ignore
      const filteredDetails = vehicleDetails.travel_trip.filter(
        (travel: any) => {
          return travel?.title !== travels?.title;
        }
      );
      console.log(vehicleDetails.travel_trip?.length);

      return { ...prevDetails, travel_trip: filteredDetails };
    });
  };
  const openModal = (travels?: any) => {
    setIsVisibleModalOne((prev) => !prev);
    setTravelDetails(travels);
  };
  const openDiscount = (travels: any) => {
    setIsTravelDiscountVisible(true);
    setTravelDetails(travels);
  };

  let airportArray = [
    {
      price: airportPrice,
      discount: 0,
      service_title: "Airport Pickup",
    },
  ];

  const operation = (x: any, data: any, title: string) => {
    if (x === 0 || x === "") {
      return;
    } else {
      const updatedData = [
        {
          ...data[0], // Spread the properties of the existing object
          price: x, // Update the price property with the desired value
        },
        ...data.slice(1), // Spread the remaining objects in the data array
      ];
      // vehicleDetails.service_price.push(...updatedData);
      let newServicePrice = [...vehicleDetails.service_price, ...updatedData];

      const updatedServicePrice = newServicePrice?.map((service: any) => {
        if (service.service_title === title) {
          return {
            ...service,
            price: x,
          };
        }
        return service;
      });

      // this is to prevent the price deta from getting duplicated
      //@ts-ignore
      const uniqueServicePrices = updatedServicePrice.reduce(
        (uniquePrices, price) => {
          if (
            !uniquePrices.some(
              (p: any) => p.service_title === price.service_title
            )
          ) {
            uniquePrices.push(price);
          }
          return uniquePrices;
        },
        []
      );
      setVehicleDetails({
        ...vehicleDetails,
        service_price: uniqueServicePrices,
      });
    }
  };

  function dispatchDetails() {
    dispatch(
      setVehicleDetailss({
        ...vehicleDetails,
        service_price: [...vehicleDetails?.service_price],
      })
    );
    dispatch(
      setVehicleDetailss({
        ...vehicleDetails,
        travel_trip: [...vehicleDetails?.travel_trip],
      })
    );
  }

  useEffect(() => {
    dispatchDetails();
  }, [vehicleDetails]);

  const handleAirportPrice = (event: any) => {
    setAirportPrice(event);
    operation(event, airportArray, "Airport Pickup");
  };

  const providerTravelTrips = useAppSelector(
    (state: any) => state?.provider?.vehicleDetails?.travel_trip
  );

  const handleEnableAirport = () => {
    dispatch(setServiceVisibility({ airportVisible: true }));
  };
  const handleEnableTravel = () => {
    dispatch(setServiceVisibility({ travelVisible: true }));
  };

  return (
    <>
      {airportVisible ? (
        ""
      ) : (
        <Wrapper
          style={{
            width: "100%",
            position: "relative",
            height: "68px",
            zIndex: 6,
            marginTop: -66,
          }}
        >
          <BlackOpacity
            style={{
              background: "#000",
              opacity: 0.8,
              position: "absolute",
              width: "90%",
              bottom: -118,
              height: "100%",
            }}
          ></BlackOpacity>
        </Wrapper>
      )}

      {"driver_picture" in vehicleDetails ? (
        ""
      ) : (
        <>
          {isAirportDel && (
            <DeleteModal
              setIsAirportDel={setIsAirportDel}
              vehicleDetails={vehicleDetails}
              setVehicleDetails={setVehicleDetails}
              setFullDayServiceHour={0}
              setDiscount={setAirportDiscount}
              setAirportPrice={setAirportPrice}
              deleteFor="Airport Pickup"
              isAirportDel={isAirportDel}
            />
          )}
          <FLEXDIV>
            <AirportInputDiv airportVisible={airportVisible}>
              <ServiceLabel style={{ flex: 1 }} htmlFor="">
                Airport pickup
              </ServiceLabel>

              <BLACKDIV>
                <P style={{ left: "0px", top: "-37px" }}>Set Price</P>

                <Input>
                  <CurrencyInput
                    style={{
                      background: "transparent",
                      border: "none",
                      outline: "none",
                      color: "#fff",
                    }}
                    decimalsLimit={2}
                    prefix={formatCurrencyPrefix(currentLocation)}
                    placeholder="0.00"
                    value={airportPrice}
                    defaultValue={0.0}
                    onValueChange={(e: any) => handleAirportPrice(e)}
                  />
                </Input>
                <DISCOUNT onClick={() => setIsAirportDiscountVisible(true)}>
                  <P style={{ left: "0px", top: "-45px" }}>Discount</P>
                  {airportDiscount}%
                </DISCOUNT>
                {isAirportDiscountVisible && (
                  <DiscountModal
                    setDiscount={setAirportDiscount}
                    discount={airportDiscount}
                    discountFor="Airport Pickup"
                    fullDayArray={airportArray}
                    vehicleDetails={vehicleDetails}
                    setVehicleDetails={setVehicleDetails}
                    setIsDiscountVisible={setIsAirportDiscountVisible}
                  />
                )}
              </BLACKDIV>
            </AirportInputDiv>
            <MARKDIV>
              {airportVisible ? (
                <img
                  onClick={BlurOptionAirport}
                  src={xmark}
                  alt=""
                  width={17}
                />
              ) : (
                <img
                  src={mark}
                  alt=""
                  width={18}
                  onClick={() => handleEnableAirport()}
                />
              )}
            </MARKDIV>
          </FLEXDIV>
        </>
      )}

      {providerTravelTrips?.length < 1 && (
        <>
          {travelVisible ? (
            ""
          ) : (
            <Wrapper
              style={{
                width: "100%",
                position: "relative",
                height: "68px",
                zIndex: 6,
                marginTop: -66,
              }}
            >
              <BlackOpacity
                style={{
                  background: "#000",
                  opacity: 0.8,
                  position: "absolute",
                  width: "90%",
                  bottom: -118,
                  height: "100%",
                }}
              ></BlackOpacity>
            </Wrapper>
          )}
          <FLEXDIV>
            <INPUTDIV>
              <ServiceLabel style={{ flex: 1 }} htmlFor="">
                Travel Trip
              </ServiceLabel>
              <VerifyButton onClick={openModal}>
                <P style={{ left: "0px", top: "-39px" }}>Select Location</P>
                <Text>{"Select"}</Text>
                <DropDown>
                  <IoIosArrowDown
                    style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
                  />
                </DropDown>
              </VerifyButton>
              <BLACKDIV style={{ width: "34%" }}>
                <P style={{ left: "0px", top: "-37px" }}>Set Price</P>
                {/* <PriceInput
                  onClick={openModal}
                  type="text"
                  readOnly
                  value={travelPrice}
                  placeholder="0.00"
                /> */}
                <Input onClick={openModal}>
                  <CurrencyInput
                    style={{
                      background: "transparent",
                      border: "none",
                      outline: "none",
                      color: "#fff",
                    }}
                    decimalsLimit={2}
                    prefix={formatCurrencyPrefix(currentLocation)}
                    placeholder="0.00"
                    value={travelPrice}
                  />
                </Input>

                <DISCOUNT>
                  <P style={{ left: "0px", top: "-45px" }}>Discount</P>
                  0%
                </DISCOUNT>
              </BLACKDIV>
            </INPUTDIV>
            <MARKDIV onClick={BlurOptionTravel}>
              {travelVisible ? (
                <img src={xmark} alt="" width={17} />
              ) : (
                <img
                  src={mark}
                  alt=""
                  width={18}
                  onClick={() => handleEnableTravel()}
                />
              )}
            </MARKDIV>
          </FLEXDIV>
        </>
      )}
      {isVisibleModalOne ? (
        <div style={{ position: "relative", width: "370px" }}>
          <ModalOne
            vehicleDetails={vehicleDetails}
            setVehicleDetails={setVehicleDetails}
            travelDetails={travelDetails}
            setIsVisibleModalOne={setIsVisibleModalOne}
            setIsVisibleModalTwo={setIsVisibleModalTwo}
            setIsVisibleTravelTwo={setIsVisibleTravelTwo}
          />
        </div>
      ) : (
        ""
      )}
      {}
      {providerTravelTrips?.map((travels: any, i: any) => {
        const address = travels?.destination;
        const words = address.split(" ");
        const firstTwoWords = words.slice(0, 1).join(" ");
        return (
          <>
            <FLEXDIV key={i}>
              <INPUTDIV>
                <ServiceLabel style={{ flex: 1 }} htmlFor="">
                  Travel Trip
                </ServiceLabel>
                <VerifyButton onClick={() => openModal(travels)}>
                  <P style={{ left: "0px", top: "-39px" }}>Select Location</P>
                  <Text>{firstTwoWords || "Select"}</Text>
                  <DropDown>
                    <IoIosArrowDown
                      style={{
                        color: "#FFD58C",
                        fontSize: 15,
                        fontWeight: 600,
                      }}
                    />
                  </DropDown>
                </VerifyButton>
                <BLACKDIV style={{ width: "34%" }}>
                  <P style={{ left: "0px", top: "-37px" }}>Set Price</P>
                  {/* <PriceInput
                    onClick={() => openModal(travels)}
                    type="text"
                    readOnly
                    value={travels?.price}
                    placeholder="0.00"
                  /> */}
                   <Input onClick={() => openModal(travels)}>
                  <CurrencyInput
                    style={{
                      background: "transparent",
                      border: "none",
                      outline: "none",
                      color: "#fff",
                    }}
                    decimalsLimit={2}
                    prefix={formatCurrencyPrefix(currentLocation)}
                    placeholder="0.00"
                    value={travels?.price}

                    defaultValue={0.0}
                    // onValueChange={(e: any) => handleAirportPrice(e)}
                  />
                </Input>


                  <DISCOUNT
                    onClick={() => {
                      openDiscount(travels);
                    }}
                  >
                    <P style={{ left: "0px", top: "-45px" }}>Discount</P>
                    {travels?.discount}%
                  </DISCOUNT>
                </BLACKDIV>
              </INPUTDIV>
              <MARKDIV onClick={() => deleteTravel(travels)}>
                {isTravelVisible ? (
                  <img src={xmark} alt="" width={17} />
                ) : (
                  <img src={mark} alt="" width={18} />
                )}
              </MARKDIV>
            </FLEXDIV>
          </>
        );
      })}
      {isVisibleModalOne && (
        <div style={{ position: "relative", width: "390px" }}>
          <ModalOne
            vehicleDetails={vehicleDetails}
            setVehicleDetails={setVehicleDetails}
            travelDetails={travelDetails}
            setIsVisibleModalOne={setIsVisibleModalOne}
            setIsVisibleModalTwo={setIsVisibleModalTwo}
            setIsVisibleTravelTwo={setIsVisibleTravelTwo}
          />
        </div>
      )}

      {isTravelDiscountVisible && (
        <TravelDiscountModal
          travelDetails={travelDetails}
          vehicleDetails={vehicleDetails}
          setVehicleDetails={setVehicleDetails}
          setIsDiscountVisible={setIsTravelDiscountVisible}
        />
      )}
    </>
  );
};

export default FifthScreen;
