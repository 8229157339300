import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  favourites: [],
};
const favouriteSlice = createSlice({
  name: 'favourite',
  initialState,
  reducers: {
    addToFavourite: (state:any, {payload}) => {
      return {...state, favourites: state.favourites.concat(payload)}
    }
    // replaceFavouriteItem: (state:any, {payload}) => {
    //   return {favourites: state.cfavourites?.map((item:any) => item?.booking_id !== payload.booking_id ? item : payload)}
    // },

  }
});

const { reducer, actions } = favouriteSlice;
export const { addToFavourite} = actions;
export default reducer;