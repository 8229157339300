import React, { FC, useEffect, useRef, useState } from "react";
import { BackgroundBox, ModalBox } from "./ticket.styles";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../store";
import html2canvas from "html2canvas";
import { FaCarSide } from "react-icons/fa";
const TicketModal: FC<{ setIsTicket; passengerInfo?: any; request?: any }> = ({
  request,
  passengerInfo,
  setIsTicket,
}) => {
  const modalRef = useRef(null);
  const user = useAppSelector((state: any) => state?.auth.auth);

  const downloadImage = async () => {
    if (modalRef.current) {
      const canvas = await html2canvas(modalRef.current);
      const dataUrl = canvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "Roadlers_Travel_Ticket.png";
      link.click();
    }
  };

  return (
    <>
      <BackgroundBox onClick={() => setIsTicket((prev: any) => !prev)}>
        <ModalBox onClick={(e: any) => e.stopPropagation()}>
          <div ref={modalRef} className="ticket-container">
            <div className="left">
              <img src="/logo.png" width={80} style={{marginLeft:-10}} />
              <div className="logo" style={{marginLeft:-10}}>
                <h5>Roadlers</h5>
                <h4>Travel Garage</h4>
              </div>
            </div>
            <div className="right">
              <div className="pass">
                <h5>Boarding Pass</h5>
                <p>
                  {request?.booking_title?.toUpperCase()} --{" "}
                  {request?.plate_number?.toUpperCase()}
                </p>
              </div>

              <div className="passenger-details">
                <div className="">
                  <p>Firstname</p>
                  <h5>{passengerInfo?.firstname}</h5>
                </div>
                <div className="phone">
                  <p>Phone</p>

                  <Link to={"tel:" + passengerInfo?.phone}>
                    <h5>{passengerInfo?.phone}</h5>
                  </Link>
                </div>
              </div>

              <div className="travel-details">
                <div className="">
                  <p>From</p>
                  <h5>{request?.from}</h5>
                </div>

                <div className="">
                  <FaCarSide style={{fontSize:20, color:"black"}}/>
                </div>
                <div className="">
                  <p>To</p>
                  <h5>{request?.to}</h5>
                </div>
              </div>

              <div className="travel-details">
                <div className="">
                  <p>Boarding Time</p>
                  <h5>{request?.departure_time}</h5>
                </div>
                <div className="">
                  <p>Date</p>
                  <h5>{request?.departure_date}</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="button-container">
            <button onClick={downloadImage}>
              Download {passengerInfo?.firstname}'s Ticket
            </button>
          </div>
        </ModalBox>
      </BackgroundBox>
    </>
  );
};

export default TicketModal;
