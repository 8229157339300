import { CheckoutPayWrapper } from './../checkoutPayComponent/checkoutPay.styles';
import { GreyWrap } from '../collectMoneyOne/collectmoneyone.styles';
import styled from 'styled-components'

export const CheckoutErrorWrapper = styled(CheckoutPayWrapper) `
    position: unset;
`

export const TextHead = styled.h3 `
    color: #09051C;
    font-weight: 700;
    font-size: 16px;
    margin-top: 40px;

    @media only screen and (max-width: 454px) {
        font-size: 12px;
    }
`

export const CheckoutGreyWrap = styled(GreyWrap) `
    padding-bottom: 7px;
    margin-bottom: 0;
`

export const ErrorOverlay = styled.div `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 56%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    /* background-color: rgba(0, 0, 0, 0.65); */
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;

    & .error-box {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        border-radius: 10px;
        padding: 10px 12px;
        width: 80%;
        background-color: #fff;

        img {
            width: 40px;
        }

        p {
            color: #C70303;
            margin: 0;
            font-size: 13px;
            font-weight: 600;
        }
    }

    @media only screen and (max-width: 454px)  {

        .error-box {
            gap: 9px;

            p {
                font-size: 10px;
            }

            img {
                width: 20px;
            }
        }

    } 
`