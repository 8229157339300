import {
  AssetImg,
  Card,
  EmptyBox,
  ExtrasBox,
  HeaderText,
  ImageTag,
  ImgTextBox,
  ImgWrapper,
  InfoWrapper,
  LocationWrap,
  NewPrice,
  OldPrice,
  PriceLocationBox,
  RegText,
  RightExtraBox,
  StarTag,
  VerifiedImg,
  VerifiedTxt,
  WhiteInnerCard,
} from "./assetCard.styles";
import merc from "../../assets/images/jpg/mercedes-f1.jpeg";
import location from "../../assets/images/svg/location-tag.svg";
import like from "../../assets/images/svg/like-icon.svg";
import likeFilled from "../../assets/images/svg/likeFilled.svg";
import share from "../../assets/images/svg/share.svg";
import passenger from "../../assets/images/svg/user-sit.svg";
import star from "../../assets/images/svg/starr.svg";
import verified from "../../assets/images/png/verified.png";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  useAddVehicleLikesMutation,
  useGetVehicleLikesByIdQuery,
} from "../../services/customerApi";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { addToFavourite } from "../../slices/favourites";
import { useEffect, useState } from "react";
import { RWebShare } from "react-web-share";

const AssetCard = ({
  item,
  firstPrice,
  discountPrice,
  discount,
  name,
  locate,
  vehicleImg,
  vehicleId,
  year,
  passengerNum,
  rating,
  likes,
}: any) => {
  const imgLink = `https://testapi.roadlers.com/storage/`;
  const imgLink2 = "https://testapi.roadlers.com/storage/";
  const navigate = useNavigate();
  const params = { vehicleId: `${vehicleId}` };
  const dispatch: AppDispatch = useAppDispatch();
  const [likeNo, setLikeNo] = useState<any>([]);

  const { favourites } = useAppSelector<any>(
    (store: RootState) => store?.favourites
  );

  const [addLike, { data, isLoading, isSuccess, isError, error }] =
    useAddVehicleLikesMutation();

  const {
    data: likesData,
    isFetching: isLikesFetching,
    refetch: refetchLikes,
    error: likesError,
  } = useGetVehicleLikesByIdQuery(vehicleId, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    refetchLikes();
    setLikeNo(likesData?.data);
  }, [data, likesData]);

  const goToVehicleBooking = () => {
    // navigate({
    //     pathname: "/car-booking",
    //     search: `?${createSearchParams(params).toString()}`
    // })
    navigate(`/car-booking/${vehicleId}`);
  };

  const handleAddVehicleLike = () => {
    let formData: any = new FormData();

    formData.append("id", vehicleId);
    formData.append("likes", "1");

    addLike(formData);

    dispatch(addToFavourite(item));
  };

  return (
    <Card>
      <VerifiedTxt>Verified</VerifiedTxt>
      <VerifiedImg src={verified} />
      <ImgWrapper onClick={goToVehicleBooking}>
        <AssetImg src={`${imgLink}${vehicleImg}`} loading="lazy" />
      </ImgWrapper>
      <WhiteInnerCard>
        <EmptyBox />
        <InfoWrapper>
          <div>
            <HeaderText>
              {name} - ({year})
            </HeaderText>
          </div>

          <PriceLocationBox>
            <div>
              <div
                style={{ display: "flex", alignItems: "center" , gap:3}}
              >
                <NewPrice>
                  <span>{item?.currency}{" "}</span>
                  {Number(discountPrice).toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                  })}
                  {/* {
                                    discount === '0%' ?
                                    Number(firstPrice).toLocaleString('en-US', {maximumFractionDigits: 2}) : Number(discountPrice).toLocaleString('en-US', {maximumFractionDigits: 2})
                                } */}
                </NewPrice>
                <span >
                  <RegText>/</RegText>
                </span>

                <span >
                  <RegText>day</RegText>
                </span>
              </div>
              <OldPrice>
                {/* {
                                discount === '0%' ? 
                                '' : (`₦${Number(firstPrice).toLocaleString('en-US', {maximumFractionDigits: 2})}`)
                            } */}
                {item?.currency}{" "}
                {Number(firstPrice).toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}
              </OldPrice>
            </div>

            <div
              className="right-side-location"
              style={{ display: "flex", alignItems: "center", gap: "2px" }}
            >
              <ImageTag src={location} />

              <LocationWrap>{locate}</LocationWrap>
            </div>
          </PriceLocationBox>

          <ExtrasBox>
            <div>
              <ImgTextBox>
                {likeNo?.[0]?.likes ? (
                  <ImageTag src={likeFilled} onClick={handleAddVehicleLike} />
                ) : (
                  <ImageTag src={like} onClick={handleAddVehicleLike} />
                )}

                <RegText>{likeNo?.[0]?.likes || "0"}</RegText>
              </ImgTextBox>
            </div>

            <RightExtraBox>
              <RWebShare
                data={{
                  text: `Book this vehicle with the link below`,
                  url: `https://m.roadlers.com/car-rental/${vehicleId}`,
                  title: "Book Vehicles on Roadlers",
                }}
              >
                <ImageTag src={share} style={{ width: "35px" }} />
              </RWebShare>

              <ImgTextBox>
                <ImageTag src={passenger} />
                <RegText>{passengerNum}</RegText>
              </ImgTextBox>

              <ImgTextBox className={"rating-wrap"}>
                <StarTag src={star} />
                <RegText>{rating ? rating : " 0"}</RegText>
              </ImgTextBox>
            </RightExtraBox>
          </ExtrasBox>
        </InfoWrapper>
      </WhiteInnerCard>
    </Card>
  );
};

export default AssetCard;
