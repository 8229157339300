import styled from 'styled-components'

export const BackgroundBox = styled.div`
 display:flex;
width:100%;
align-items:center;
justify-content:center;
background:rgba(255, 255, 255, 0.9);
transition: all 0.5s ease 0s;
height:100vh;
position:fixed;
top:0;
left:0;
bottom:0;
z-index:11;

`
export const ModalBox = styled.div`
background:black;
display:flex;
flex-direction:column;
justify-content:center;
align-items:flex-end;
padding:20px;
border-radius:10px;
position:absolute;
width:95%;
`
export const H3 = styled.h3`
color:#fff;
`
export const Button = styled.button`
background:#ffa100;
color:#000;
width:50%;
border:none;
outline:none;
padding:8px;
border-radius:7px;
font-weight:700;
font-size:18px;
`
export const SearchBoxDiv = styled.div`
display:flex;
background:#3a3937;
align-items:center;
border-radius:10px;
padding:9px;
width:100%;
margin:10px 0px;

`
export const SearchBox = styled.input`
display:flex;
background:#3a3937;
outline:none;
border:none;
color:#fff;
font-size:15px;
width:100%;
  @media only screen and (max-width: 450px)  {
    ::placeholder {
        font-size:14px;
    
      }
}
`
export const DriverBoxDiv = styled.div`
background:#000;
width:300px;
display:grid;
padding:15px;
gap:15px;
grid-template-columns:1fr 1fr ;
align-items:center; 
justify-content:center;
@media only screen and (max-width: 450px)  {
  width:100%;

}
`
export const DriverDiv = styled.div`
width:100%;
background:#fff;
border-radius:10px;
padding:10px;
position:relative;
`
export const H4 = styled.h4`
color: #09051C;
margin:8px 0px;

`
export const P = styled.p`
color: #09051C;
font-size:14px;
flex:1;
`
export const PriceBtn = styled.button`
background: #09051C;
color:#fff;
padding:8px 15px;
outline:none;
border:none;
border-radius:7px;
font-weight:bold;

`

export const SelectBtn = styled.button`
background: #fff;
border: 8px solid #000;
position:absolute;
right:10px;
border-radius:50%;
padding:12px;

`
export const BottomDiv = styled.div`
width:100%;
display:flex;
align-items:center;
justify-content:space-between;
`
export const StarDiv = styled.div`
display:flex;
align-items:center;
gap:7px;
`
