import styled from 'styled-components'


export const DROPDOWN = styled.div`
display:flex;
align-items:center;
flex-wrap:wrap;
background:#fff;
width:100%;
flex-direction:column;
padding:0px 10px;

`
export const DROPDOWNCOL = styled.div`
display:flex;
flex-direction:column;
align-items:center;
gap:2px;
flex-wrap:wrap;
background: #fff;
padding:7px;
width:100%;
border-radius: 10px 10px 10px 10px;
`
export const DROPDOWNDIV = styled.div`
display:flex;
align-items:center;
gap:15px;
width:100%;
padding:10px 5px;
background:#fff;
`
export const PPP = styled.p`
color:#000;
font-size:12.5px;
font-weight:700;

`
export const P = styled.p`
color:#fff;
font-size:12px;
margin-top:50px;

`
export const DIVS = styled.div`
display:flex;
align-items:center;
gap:1px;
background: #EAEAEA;
width:50%;
gap:15px;
justify-content:flex-start;
border-radius:5px;
padding:0px 0px 0px 4px;
`
export const Button = styled.button`
height: 6px; 
 padding: 5px;
border:2px solid black;
 border-radius: 50%; 
`


