import React, { FC, useEffect, useRef, useState } from "react";
import { BackgroundBox, Button, H3, ModalBox } from "./index.styles";
import { Link } from "react-router-dom";
import { useAdminCustomerDataMutation } from "../../../services/adminApi";
import userImg from "../../../assets/images/png/phebe.png";
import locationBlack from "../../../assets/images/png/locationBlack.png";
import location from "../../../assets/images/png/location.png";
import seat from "../../../assets/images/png/seat.png";
import cam from "../../../assets/images/png/cam.png";
import {
  baseUrl,
  useGetVehiclesQuery,
  useProviderPictureUpdateMutation,
  useTravelGarageMutation,
} from "../../../services/providerApi";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import CurrencyInput from "react-currency-input-field";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import Compressor from "compressorjs";
import axios from "axios";
import { setGarage, setTravels } from "../../../slices/travelPassengers";
import { formatCurrencyPrefix } from "../../../services/utils";

type Props = {
  setIsAddModal?: any;
  editTravel?: any;
  edit?: any;
};
const AddTravelModal: FC<Props> = ({ edit, editTravel, setIsAddModal }) => {
  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );
  let [isLoadingPublish, setIsLoadingPublish] = useState(false);
  const [imagePrev, setImagePrev] = useState<any>();

  const user = useAppSelector((state: any) => state?.auth.auth);

  const {
    data: providerVehicles,
    isFetching: isFetchingVehicles,
    refetch: refetchVehicles,
  } = useGetVehiclesQuery(user?.id);

  const [travelGarageData, { data, isLoading, isSuccess, isError, error }] =
    useTravelGarageMutation();

  useEffect(() => {
    refetchVehicles();
  }, [providerVehicles, refetchVehicles]);
  const allowedVehicles = providerVehicles?.data?.filter((allowedVehicle) => {
    return allowedVehicle?.status === "Approved";
  });

  let [travelVehicleDetails, setTravelVehicleDetails] = useState<any>({
    from: editTravel?.from,
    to: editTravel?.to,
    pickup_address: editTravel?.pickup_address,
    dropoff_address: editTravel?.destination,
    departure_date: editTravel?.departure_date,
    departure_time: editTravel?.departure_time,
    seats: editTravel?.seat,
    price_per_seat: editTravel?.price,
    vehicle: "",
  });

  const [autocomplete, setAutocomplete] = useState<any>(null);
  const [autocompleteTo, setAutocompleteTo] = useState<any>(null);
  const [autocompleteDrop, setAutocompleteDrop] = useState<any>(null);
  const [autocompletePick, setAutocompletePick] = useState<any>(null);
  const [googleAddressTo, setGoogleAddressTo] = useState<any>(false);
  const [googleAddressFrom, setGoogleAddressFrom] = useState<any>(false);

  const dispatch: any = useAppDispatch();

 

  const libraries: (
    | "places"
    | "drawing"
    | "geometry"
    | "localContext"
    | "visualization"
  )[] = ["places", "geometry", "drawing"];

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs",
    libraries,
  });

  // const providerRequest = useAppSelector((state: any) => state?.provider.allRequest)

  const handlePublishError = () => {
    notifyError(
      "Please Fill all fields, make sure you select the google address where necessary"
    );
  };

  const handleEdit = () => {
    let formData: any = new FormData();
    formData.append("category", "travel_garage");
    formData.append("vehicle_id", travelVehicleDetails?.vehicle);
    formData.append("title", "Travel Garage");
    formData.append("pickup_location", travelVehicleDetails.pickup_address);
    formData.append("price", travelVehicleDetails.price_per_seat);
    formData.append("seat", travelVehicleDetails.seats);
    formData.append("destination", travelVehicleDetails.dropoff_address);
    formData.append(
      "departure_time",
      moment(travelVehicleDetails.departure_time, "HH:mm").format("hh:mm a")
    );
    formData.append("departure_date", travelVehicleDetails.departure_date);
    formData.append("to", travelVehicleDetails.to);
    formData.append("from", travelVehicleDetails.from);
    formData.append("pickup_address", travelVehicleDetails.pickup_address);
    formData.append("provider_id", user?.id);
    formData.append("id", editTravel?.id);

    axios({
      method: "post",
      url: `${baseUrl}update-travel-garage`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        dispatch(setTravels(response?.data?.data));
        dispatch(setGarage(response?.data?.data));
        
        setIsAddModal(false);
        notifySuccess("Travel Garage Listing Successfully Updated");
      })
      .catch((error) => {
        notifyError(error?.response?.data);
      });
  };
  const handlePublish = () => {
    setIsLoadingPublish(true);
    let formData: any = new FormData();
    formData.append("category", "travel_garage");
    formData.append("vehicle_id", travelVehicleDetails?.vehicle);
    formData.append("title", "Travel Garage");
    formData.append("pickup_location", travelVehicleDetails.pickup_address);
    formData.append("price", travelVehicleDetails.price_per_seat);
    formData.append("seat", travelVehicleDetails.seats);
    formData.append("destination", travelVehicleDetails.dropoff_address);
    formData.append(
      "departure_time",
      moment(travelVehicleDetails.departure_time, "HH:mm").format("hh:mm a")
    );
    formData.append("departure_date", travelVehicleDetails.departure_date);
    formData.append("to", travelVehicleDetails.to);
    formData.append("from", travelVehicleDetails.from);
    formData.append("pickup_address", travelVehicleDetails.pickup_address);
    formData.append("provider_id", user?.id);

    travelGarageData(formData)
      .then((response: any) => {
        setIsLoadingPublish(false);
        notifySuccess(
          "This Vehicle has been successfully listed on the travel garage"
        );
        setIsAddModal(false);
        dispatch(setTravels(response?.data?.data));
        dispatch(setGarage(response?.data?.data));

      })
      .catch((error) => {
        notifyError("Something went wrong");
        setIsAddModal(false);
      });
  };

  const onPlaceChangedFrom = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      if (place && place?.vicinity) {
        // const stateComponent = place.address_components.find((component) =>
        //   component.types.includes("administrative_area_level_1")
        // );
        const stateComponent = place?.vicinity;
        if (stateComponent) {
          const state = stateComponent;
          setTravelVehicleDetails({
            ...travelVehicleDetails,
            from: state,
          });
          setGoogleAddressFrom(true);
        }
      }
    }
  };

  const onPlaceChangedTo = () => {
    if (autocompleteTo !== null) {
      const place = autocompleteTo.getPlace();

      if (place && place?.vicinity) {
        // const stateComponent = place.address_components.find((component) =>
        //   component.types.includes("administrative_area_level_1")
        // );
        const stateComponent = place?.vicinity;
        if (stateComponent) {
          const state = stateComponent;
          setTravelVehicleDetails({
            ...travelVehicleDetails,
            to: state,
          });
          setGoogleAddressTo(true);
        }
      }
    }
  };

  const onPlaceChangedDrop = () => {
    if (autocompleteTo !== null) {
      const places = autocompleteDrop.getPlace().formatted_address;
      if (places) {
        setTravelVehicleDetails({
          ...travelVehicleDetails,
          dropoff_address: places,
        });
      } else {
      }
    }
  };
  const onPlaceChangedPick = () => {
    if (autocompleteTo !== null) {
      const places = autocompletePick.getPlace().formatted_address;
      if (places) {
        setTravelVehicleDetails({
          ...travelVehicleDetails,
          pickup_address: places,
        });
      } else {
      }
    }
  };

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const [
    uploadImage,
    {
      data: providerImage,
      isLoading: providerImageLoading,
      isSuccess: providerImageSuccess,
      isError: providerImageIsError,
      error: providerImageError,
    },
  ] = useProviderPictureUpdateMutation();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const file = event.currentTarget.files;
    if (file != null) {
      setImagePrev(file);
      let reader = new FileReader();
      reader.onload = (e) => {
        if (e.target != null) {
          setImagePrev(e.target.result);
        }

        new Compressor(file[0], {
          quality: 0.6,
          success: (compressedResult: any) => {
            const formData = new FormData();
            //@ts-ignore
            formData.append("provider_id", user?.id);
            formData.append("profile_image", compressedResult);
            //@ts-ignore
            uploadImage(formData)
              .then((response: any) => {
                if (response?.data?.message === "success") {
                  notifySuccess("ID Image Saved");
                } else {
                  notifyError("Something went wrong");
                }
              })
              .catch((err) => {});
          },
        });
      };
      reader.readAsDataURL(file[0]);
    }
    // setFrontImage(false)
    // setBackImage(true)
  };

  return (
    <>
      <ToastContainer />

      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        accept="image/*"
        onChange={onChange}
      />

      <BackgroundBox onClick={() => setIsAddModal((prev: any) => !prev)}>
        <ModalBox onClick={(e: any) => e.stopPropagation()}>
          <div className="modal-box">
            <div className="user-compartment">
              <div
                className="image-section"
                onClick={(event: any) => {
                  event.preventDefault();
                  if (fileInputRef.current != null) {
                    fileInputRef.current.click();
                  }
                }}
              >
                {imagePrev ? (
                  <img
                    src={imagePrev}
                    className="img"
                    width={90}
                    height={90}
                    alt=""
                  />
                ) : (
                  <img src={userImg} width={90} height={90} alt="" />
                )}
                <img
                  src={cam}
                  width={25}
                  height={25}
                  alt=""
                  className="camera"
                />
              </div>
              <div className="text-section">
                <h5>Update Your Picture</h5>
                <h3>{user?.firstname}</h3>
              </div>
            </div>

            <div className="from-to">
              <div className="from">
                <label>From</label>
                <img src={locationBlack} width={20} alt="" />

                <Autocomplete
                  className="autocomplete-wrap"
                  onPlaceChanged={onPlaceChangedFrom}
                  onLoad={(value) => setAutocomplete(value)}
                  types={["(regions)"]}
                >
                  <input
                    type="text"
                    placeholder="City | State"
                    value={travelVehicleDetails.from}
                    onChange={(e: any) =>
                      setTravelVehicleDetails({
                        ...travelVehicleDetails,
                        from: e.target.value,
                      })
                    }
                  />
                </Autocomplete>
              </div>
              <div className="to">
                <label>To</label>
                <img src={locationBlack} width={20} alt="" />
                <Autocomplete
                  className="autocomplete-wrap"
                  onPlaceChanged={onPlaceChangedTo}
                  onLoad={(value) => setAutocompleteTo(value)}
                  types={["(regions)"]}
                >
                  <input
                    type="text"
                    placeholder="City | State"
                    value={travelVehicleDetails.to}
                    onChange={(e: any) =>
                      setTravelVehicleDetails({
                        ...travelVehicleDetails,
                        to: e.target.value,
                      })
                    }
                  />
                </Autocomplete>
              </div>
            </div>
            <br />
            <div className="from-to">
              <div className="from">
                <label>Pickup Address</label>
                <img src={location} width={20} alt="" />
                <Autocomplete
                  className="autocomplete-wrap"
                  onPlaceChanged={onPlaceChangedPick}
                  onLoad={(value) => setAutocompletePick(value)}
                >
                  <input
                    type="text"
                    value={travelVehicleDetails?.pickup_address}
                    placeholder="Meeting Point"
                    onChange={(e: any) =>
                      setTravelVehicleDetails({
                        ...travelVehicleDetails,
                        pickup_address: e.target.value,
                      })
                    }
                  />
                </Autocomplete>
              </div>
              <div className="to">
                <label>Drop-Off Address</label>
                <img src={location} width={20} alt="" />
                <Autocomplete
                  className="autocomplete-wrap"
                  onPlaceChanged={onPlaceChangedDrop}
                  onLoad={(value) => setAutocompleteDrop(value)}
                >
                  <input
                    type="text"
                    value={travelVehicleDetails?.dropoff_address}
                    placeholder="Destination Point"
                    onChange={(e: any) =>
                      setTravelVehicleDetails({
                        ...travelVehicleDetails,
                        dropoff_address: e.target.value,
                      })
                    }
                  />
                </Autocomplete>
              </div>
            </div>
            <br />
            <div className="date-seats">
              <div className="date">
                <label>Date</label>
                {/* <img src={locationBlack} width={20} alt="" /> */}
                <input
                  type="date"
                  value={travelVehicleDetails?.departure_date}
                  onChange={(e: any) =>
                    setTravelVehicleDetails({
                      ...travelVehicleDetails,
                      departure_date: e.target.value,
                    })
                  }
                />
              </div>
              <div className="departure">
                <label>Time</label>
                {/* <img src={locationBlack} width={20} alt="" /> */}
                <input
                  type="time"
                  step="60"
                  pattern="([01]?[0-9]|2[0-3]):[0-5][0-9]"
                  min="09:00"
                  max="18:00"
                  value={moment(
                    travelVehicleDetails?.departure_time,
                    "h:mm a"
                  ).format("HH:mm")}
                  onChange={(e: any) =>
                    setTravelVehicleDetails({
                      ...travelVehicleDetails,
                      departure_time: e.target.value,
                    })
                  }
                />
              </div>

              <div className="seat">
                <label>Seats</label>
                <img src={seat} width={15} alt="" /> &nbsp;
                <input
                  defaultValue={travelVehicleDetails?.seats}
                  type="number"
                  onChange={(e: any) =>
                    setTravelVehicleDetails({
                      ...travelVehicleDetails,
                      seats: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <br />
            <div className="from-to">
              <div className="from price">
                <label>Price per seat</label>

                <CurrencyInput
                  decimalsLimit={2}
                  prefix={formatCurrencyPrefix(currentLocation)}

                  placeholder="0.00"
                  value={travelVehicleDetails.price_per_seat}
                  onValueChange={(value) =>
                    setTravelVehicleDetails({
                      ...travelVehicleDetails,
                      price_per_seat: value,
                    })
                  }
                />
              </div>
              <div className="to price">
                <label>Select Car</label>
                <select
                  name=""
                  id=""
                  onChange={(event) => {
                    setTravelVehicleDetails({
                      ...travelVehicleDetails,
                      vehicle: event.target.value,
                    });
                  }}
                >
                  <option value="">Select Car</option>
                  {allowedVehicles?.map((vehicle: any) => {
                    return (
                      <option value={vehicle.id}>{vehicle.vehicle_name}</option>
                    );
                  })}
                </select>
              </div>
            </div>
            <br />

            {edit ? (
              <div className="submit">
                {isLoadingPublish ? (
                  <button>Loading...</button>
                ) : travelVehicleDetails?.pickup_address?.length < 3 ||
                  travelVehicleDetails?.dropoff_address?.length < 3 ||
                  travelVehicleDetails?.departure_date?.length < 3 ||
                  travelVehicleDetails?.departure_time?.length < 3 ||
                  travelVehicleDetails?.seats?.length < 1 ||
                  travelVehicleDetails?.price_per_seat?.length < 3 ||
                  travelVehicleDetails?.from?.length < 3 ||
                  travelVehicleDetails?.to?.length < 3 ||
                  travelVehicleDetails?.vehicle?.length < 3 ? (
                  <button onClick={() => handlePublishError()}>
                    Confirm Edit
                  </button>
                ) : (
                  <button onClick={() => handleEdit()}>Confirm Edit</button>
                )}
              </div>
            ) : (
              <div className="submit">
                {isLoadingPublish ? (
                  <button>Loading...</button>
                ) : travelVehicleDetails?.pickup_address?.length < 3 ||
                  travelVehicleDetails?.dropoff_address?.length < 3 ||
                  travelVehicleDetails?.departure_date?.length < 3 ||
                  travelVehicleDetails?.departure_time?.length < 3 ||
                  travelVehicleDetails?.seats?.length < 1 ||
                  travelVehicleDetails?.price_per_seat?.length < 3 ||
                  travelVehicleDetails?.from?.length < 3 ||
                  travelVehicleDetails?.to?.length < 3 ||
                  travelVehicleDetails?.vehicle?.length < 3 ? (
                  <button onClick={() => handlePublishError()}>Publish</button>
                ) : (
                  <button onClick={() => handlePublish()}>Publish</button>
                )}
              </div>
            )}
          </div>
        </ModalBox>
      </BackgroundBox>
    </>
  );
};

export default AddTravelModal;
