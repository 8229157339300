import React, { useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'

import { Box, H4 } from "./vehicles.styles";
import VehicleBox from "./vehicleBox";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setFilterPagination,
  setFilterPaginationData,
  setPageNumber,
  setSearchData,
  setVehicles,
} from "../../../slices/admin";
import { CustomButton, PageButton, PaginationBox } from "./index.styles";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { HiChevronDoubleLeft, HiChevronDoubleRight } from "react-icons/hi2";
import { baseUrl } from "../../../services/providerApi";
import axios from "axios";
import {
  PaginationButton,
  PaginationDiv,
} from "../Drivers/adminDrivers.styles";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";
const Vehicles = () => {
  const vehicles = useAppSelector((state: any) => state?.admin.allVehicle);
  const searchData = useAppSelector((state: any) => state?.admin.searchData);

  const user: any = useAppSelector((state: any) => state?.auth?.auth);
  const dispatch: any = useAppDispatch();
  const pageNumber: number = useAppSelector(
    (state: any) => state?.admin?.currentPage
  );
  const filterPagination = useAppSelector(
    (state: any) => state?.admin?.filterPagination
  );
  const filterPaginationData = useAppSelector(
    (state: any) => state?.admin?.filterPaginationData
  );

  const [currentPage, setCurrentPage] = useState<Number>(pageNumber);
  const [hide, setHide] = useState<boolean>(false);
  const [unHide, setUnHide] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setFilterPaginationData(false));
    dispatch(setFilterPagination(false));
    dispatch(setSearchData(""));
  }, []);

  let promise: any;
  const getVehicles = async () => {
    await axios
      .get(`${baseUrl}admin-list-vehicle?page=${currentPage}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          dispatch(setVehicles(response?.data));
          setTimeout(() => {
            toast.dismiss(promise);
          }, 0);
          toast.success("Successfully Loaded!");
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getVehicles();
  }, [currentPage, hide, unHide]);

  const handlePageChange = (newPage: number) => {
    // Validate newPage to prevent requesting invalid pages
    if (newPage >= 1) {
      setCurrentPage(newPage);
      dispatch(setPageNumber(newPage));
      promise = toast.loading("Loading...");
    }
  };

  const getFilteredVehicle = async (data: string, pageNo: any) => {
    const promise = toast.loading("Loading...");
    dispatch(setSearchData(""));
    console.log("ade")
    await axios
      .get(`${baseUrl}filter-vehicle-by-status/${data}?page=${pageNo}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.token}`,
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((response) => {
        if (response?.data?.message === "success") {
          dispatch(setVehicles(response?.data));
          setTimeout(() => {
            toast.dismiss(promise);
          }, 0);
          toast.success("Successfully Loaded!");
        }
      })
      .catch((err) => {});
  };

  const getSearchVehicle = async (newPage: any) => {
    const promise = toast.loading("Loading...");
    let formData = new FormData();
    formData.append("search_value", searchData);
    axios({
      method: "post",
      url: `${baseUrl}search-vehicle?page=${newPage}`,
      data: formData,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user?.token}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((response) => {
        if (response?.data?.message === "success") {
          dispatch(setVehicles(response?.data));
          setTimeout(() => {
            toast.dismiss(promise);
          }, 0);
          toast.success("Successfully Loaded!");
        }
      })
      .catch((err) => {});
  };

  const handlePageChangeTwo = (newPage: number) => {
    // Validate newPage to prevent requesting invalid pages
    if (newPage >= 1) {
      dispatch(setPageNumber(newPage));
      getFilteredVehicle(filterPaginationData, newPage);
    }
  };
  const handlePageChangeSearch = (newPage: number) => {
    // Validate newPage to prevent requesting invalid pages
    if (newPage >= 1) {
      dispatch(setPageNumber(newPage));
      getSearchVehicle(newPage);
    }
  };

  let paginationData = vehicles?.data?.links;
  let realPagination = paginationData?.filter((pagination: any) => {
    return Number(pagination.label);
  });

  return (
    <>
      <Toaster />

      <>
        <Box>
          {vehicles?.data?.data?.map((vehicle: any) => {
            return (
              <VehicleBox
                setHide={setHide}
                setUnHide={setUnHide}
                key={vehicle?.id}
                vehicleData={vehicle}
              />
            );
          })}
        </Box>
        <PaginationDiv>
          {realPagination?.map((pageData: any, index: number) => (
            <PaginationButton
              key={index}
              onClick={() => {
             
                filterPagination === true && searchData.length > 0
                  ? handlePageChangeSearch(Number(pageData.label))
                  : filterPagination === true && searchData.length < 1
                  ? handlePageChangeTwo(Number(pageData.label))
                  : handlePageChange(Number(pageData.label));
              }}
              disabled={pageData.active}
            >
              {pageData.label === "&laquo; Previous" && !pageData.active ? (
                <>
                  <BiChevronLeft />
                </>
              ) : pageData.label === "Next &raquo;" && !pageData.active ? (
                <>
                  <BiChevronRight />
                </>
              ) : (
                <>{pageData.label}</>
              )}
            </PaginationButton>
          ))}
        </PaginationDiv>
      </>
    </>
  );
};

export default Vehicles;
