import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify'

import { BsEnvelopeFill } from 'react-icons/bs';
import { IoIosArrowDown } from 'react-icons/io';
import Envelop from '../../../assets/images/png/envelop.png'

import {
    DIV, INPUTS, P, FLEXDIV, FLEXDIV2, Input, ICONDIV, GuarantorLabel
} from './guarantorProfile.styles';
import { useGetNextOfKinByProviderIdQuery, useUpdateGuarantorMutation } from '../../../services/providerApi';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useNavigate } from 'react-router-dom';
import { SubmitButton, SubmitDiv } from '../../providerVehicleProfile/regularRates/regularRates.styles';


type Props = {
    userId?: any;
    providerInfo?: any;
    setProviderInfo?: any;
}
const GuarantorProfile: FC<Props> = ({ userId, providerInfo, setProviderInfo }) => {

    const { data: guarantorData, isFetching: isFetchingGuarantor, refetch: refetchGuarantor } = useGetNextOfKinByProviderIdQuery(userId)

    useEffect(() => {
        refetchGuarantor()
    }, [refetchGuarantor, guarantorData])


    let [guarantor, setGuarantor] = useState({
        id:undefined,
        relationship:undefined,
        firstname:undefined,
        lastname:undefined,
        email:undefined,
        phone_number:undefined,
        driver_id:undefined
    });

    useEffect(() => {
        if (providerInfo?.data[0] && providerInfo?.data[0]?.guarantor[0] !== undefined) {
            setGuarantor(providerInfo?.data[0]?.guarantor[0])
        }
    }, [guarantorData])

    const [isSaveLoading, setIsSaveLoading] = useState(false)
    const [showSaveButton, setShowSaveButton] = useState(false)

    const [btnLoad, setBtnLoad] = useState<boolean>(false)
    const navigate = useNavigate()




    const handleRelationship = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setGuarantor((prevState: any) => ({
            ...prevState,
            relationship: (event.target as HTMLTextAreaElement).value,
        }));
        setShowSaveButton(true)

    };
    const handleFirstName = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setGuarantor((prevState: any) => ({
            ...prevState,
            firstname: (event.target as HTMLTextAreaElement).value,
        }));
        setShowSaveButton(true)

    };
    const handleLastName = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setGuarantor((prevState: any) => ({
            ...prevState,
            lastname: (event.target as HTMLTextAreaElement).value,
        }));
        setShowSaveButton(true)

    };
    const handlePhone = (e: any) => {
        setGuarantor((prevState: any) => ({
            ...prevState,
            phone_number: e,
        }));
        setShowSaveButton(true)

    }
    const handleEmail = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        setGuarantor((prevState: any) => ({
            ...prevState,
            email: (event.target as HTMLTextAreaElement).value,
        }));
        setShowSaveButton(true)

    };





    const [guarantorMutation, { data, isLoading, isSuccess, isError, error }] = useUpdateGuarantorMutation()

    useEffect(() => {
        if (isLoading) {
            setBtnLoad(true)
        }
        if (isSuccess) {
            setBtnLoad(false)
            // navigate('/provider-dashboard')
            // navigate('/insurance-setup')


        }


        if (isError && 'status' in error!) {
            if (isError && error.status.toString() === 'FETCH_ERROR') {
                notifyError('Network Connection Failed')
            }
            setBtnLoad(false)
        }
    }, [isLoading, isSuccess, isError, data])




 
    
    const handleUpdateGuarantor = () => {
        setIsSaveLoading(true)

        let formData: any = new FormData()
        formData.append('id', guarantor?.id)
        formData.append('relationship', guarantor?.relationship)
        formData.append('firstname', guarantor?.firstname)
        formData.append('lastname', guarantor?.lastname)
        formData.append('email', guarantor?.email)
        formData.append('phone_number', guarantor?.phone_number)
        formData.append('driver_id', guarantor?.driver_id)



        guarantorMutation(formData).then((response: any) => {
            // setUserId(response?.data?.data[0]?.user_id)
            setIsSaveLoading(false)
            setShowSaveButton(false)
            // setProviderDetails(response?.data)
        }).catch((err) => {
            setIsSaveLoading(false)
            notifyError('Error Occured')
        })


    }

    const currentLocation = useAppSelector((state: any) => state?.currentLocation?.currentLocation?.country)

    const alertError = () => {
        notifyError('Please Fill all Important Fields')
    }
    const notifyError = (text: any) => toast.error(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })
    return (
        <>
            <ToastContainer />

            <br />
            <br />
            <GuarantorLabel>Guarantor </GuarantorLabel>
            <br />
            <br />
            <br />

            <DIV>
                <DIV>
                    {/* {kinProfile ? '' : <P>Add next of kin *</P>} */}
                    <INPUTS
                        type="text"
                        value={guarantor?.relationship}
                        onChange={handleRelationship}

                        style={{ width: '100%', padding: '20px', border: 'none', outline: 'none', }}
                        placeholder='Relationship'
                    />


                </DIV>


                <FLEXDIV style={{ marginTop: '10px' }}>
                    <INPUTS
                        type="text"
                        value={guarantor?.firstname}
                        onChange={handleFirstName}
                        style={{ padding: '20px', border: 'none', outline: 'none', }}
                        placeholder='Firstname'
                    />
                    <INPUTS
                        type="text"
                        value={guarantor?.lastname}
                        onChange={handleLastName}
                        style={{ padding: '20px', border: 'none', outline: 'none', }}
                        placeholder='Lastname'
                    />
                </FLEXDIV>





                <DIV>
                    <div className='phone-select'>
                        <Input className='phone' >
                            <PhoneInput
                                defaultCountry={currentLocation}
                                defaultValue={guarantor?.phone_number}
                                //@ts-ignore
                                value={guarantor?.phone_number}
                                onChange={handlePhone}
                                international
                                withCountryCallingCode


                            />
                        </Input>
                    </div>


                </DIV>

                <FLEXDIV2>
                    <ICONDIV>
                        <BsEnvelopeFill />

                    </ICONDIV>
                    <INPUTS
                        type="email"
                        value={guarantor?.email}
                        onChange={handleEmail}
                        style={{ width: '78%', padding: '20px 0px', border: 'none', outline: 'none', }}
                        placeholder='Johndoe@gmail.com'
                    />
                </FLEXDIV2>

            </DIV>




            {
                showSaveButton && <SubmitDiv>
                    {isSaveLoading ? <SubmitButton style={{backgroundColor:'#059BBF'}}>
                        Updating Profile...
                    </SubmitButton> :
                        <SubmitButton style={{backgroundColor:'#059BBF'}} onClick={() => handleUpdateGuarantor()}>
                            Save and Update Guarantor
                        </SubmitButton>}
                </SubmitDiv>
            }
        </>

    )
}

export default GuarantorProfile