import React from 'react'
import { BackBtnWrap } from '../fundWalletOne/fundwalletone.styles'
import { SubmitBtn } from '../toggleBar/togglebar.styles'
import { OTPinfoWrap, OTPInputWrap, OTPWrap } from './otpwallet.styles'

import leftArrow from '../../assets/images/svg/dark-left-arrow.svg'
import { WithdrawError } from '../withdrawWalletComponent/withdrawwallet.styles'

const OtpWalletComponent = ({displaybox, handleShowComponent, otp, setOtp, onOtpVerify, otpError}:any) => {
  return (
    <OTPWrap display={displaybox} >
        {/* <BackBtnWrap top={'-58px'} onClick={() => handleShowComponent('a')}>
            <img src={leftArrow} />
        </BackBtnWrap> */}
        
        <OTPInputWrap>
            <input type="text" placeholder='Enter OTP' value={otp} onChange={(e:any) => setOtp(e.target.value)}/>
        </OTPInputWrap>

        <OTPinfoWrap>
          <p>
            To verify your payment please input the OTP sent to your mail
          </p>

          <h5>
            Expires in 15 minutes 
          </h5>
        </OTPinfoWrap>

        <SubmitBtn onClick={() => onOtpVerify()}>
            Continue
            <WithdrawError>{otpError}</WithdrawError>
        </SubmitBtn>
    </OTPWrap>
  )
}

export default OtpWalletComponent