import styled from 'styled-components'

export const TabsContainer = styled.div `
    width: 100%;
    background-color: #F1EFEF;
    border-radius: 12px;

    & .inner-tab-wrap {
        padding: 15px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    @media only screen and (max-width: 454px) {
        border-radius: 8px;

        .inner-tab-wrap {
            padding: 8px 10px;
        }
    }

    @media only screen and (min-width: 1100px) {
        width: 60%;
    }
`

export const TabItem = styled.div ` 
    background-color: ${(props:any) => props.tabActive ? '#fff' : 'transparent'};
    border-radius: 7px;
    flex: 0.4;
    box-shadow: ${(props:any) => props.tabActive ? '0px 0px 7px 1px rgba(0,0,0,0.13)' : 'none' } ;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-weight: 700;
    font-size: 15px;
    padding: 10px;

    @media only screen and (max-width: 454px) {
        font-size: 10px;
    }
`