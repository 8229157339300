import React, { FC, useEffect, useState, useRef } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

import TravelRatesComponent from './travelRatesComponent';

type Props = {
    vehicleProfile: any;
    vehicleInfo: any;
    setVehicleInfo: any;
}

const TravelRates: FC<Props> = ({ vehicleInfo,
    setVehicleInfo, vehicleProfile }) => {


        return (
            <>

                <TravelRatesComponent 
                vehicleProfile={vehicleProfile} 
                vehicleInfo={vehicleInfo} 
                setVehicleInfo={setVehicleInfo} 
                />
            </>
        )
    }
export default TravelRates