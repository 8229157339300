import { GreyBox, InputBottomWrap } from '../fundWalletThree/fundwalletthree.styles';
import styled from 'styled-components'
import { SelectTypeWrap } from '../swapWalletComponent/swapwallet.styles';

export const CollectMoneyOneWrap = styled.div `
    display: ${(props:any) => props.display};
`

export const PaymentLinkHeader = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0 10px;
    margin-bottom: 20px;

    & img {
        width: 80px;
    }

    & .right-side {
        h3 {
            font-weight: 700;
            font-size: 15px;
            margin: 0;
            color: #000;
        }

        p {
            font-size: 13px;
            margin: 0;
            color: #868686;
        }
    }

    @media only screen and (max-width: 454px)  {
        img {
            width: 40px;
        }

        .right-side {
            h3 {
                font-size: 12px;
            }

            p {
                font-size: 9px;
            }
        }
    } 
`

export const H33 = styled.div `
    font-size: 16px;
    font-weight: 700;
    color: #000;
    margin: 0 13px;

    @media only screen and (max-width: 454px)  {
        font-size: 12px;
    } 
`

export const SelectTypeOverall = styled(SelectTypeWrap) `
    background-color: #fff;
    margin-bottom: 18px;
`

export const GreyWrap = styled(GreyBox) `
    margin-top: 10px;
    padding-bottom: 15px;
`

export const CustomizeLinkWrap = styled.div `
    border-radius: 11px;
    background-color: #fff;
    box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 10%);
    position: relative;
    padding: 12px 15px;
    flex: 0.5;

    & input {
        width: 100%;
        outline: none;
        border: none;
        background-color: transparent;

        &::placeholder {
            font-size: 13px;
            color: #868686;
        }
    }

    & .absolute-wrap {
        position: absolute;
        bottom: -14px;
        left: 8px;
        color: #B46101;
        font-size: 9px;
    }

    @media only screen and (max-width: 454px)  {
        padding: 9px 15px;
        border-radius: 6px;

        input {
            font-size: 9px;

            &::placeholder {
                font-size: 9px;
            }
        }

        .absolute-wrap {
            font-size: 7px;
            left: 4px;
        }
    } 
`

export const SaveBeneficiaryBox = styled(InputBottomWrap) `
    margin: 0;
`