import React, { FC, useEffect, useState } from 'react'
import { Box, Button, DiscountBox, FlexBox, IconBox, ImageBox, Input, InputBox, Label, ModalContainer } from './index.styles'


import modalOption from '../../../assets/images/png/modalOption.png'
import add from '../../../assets/images/svg/triangle-up.svg'
import subtract from '../../../assets/images/svg/triangle-down.svg'
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';



type Props = {
    priceList: any;
    setServiceData: any;
    serviceData: any;
    setIsDiscountVisible: any;
};
const DiscountModal: FC<Props> = ({ setIsDiscountVisible, serviceData, setServiceData, priceList }) => {
    const [discounts, setDiscounts] = useState(priceList?.discount)

    const handleIncrease = () => {
        const updatedServiceData = serviceData?.service_price?.map((data: any) => {
            if (data.id === priceList.id) {
                const currentDiscounr = Number(data?.discount);
                const newDiscount = Number(data?.discount + 1);
                setDiscounts(newDiscount)

                return {
                    ...data,
                    discount: newDiscount
                };

            }
            return data;
        });
        
        setServiceData({
            ...serviceData, service_price: updatedServiceData
        })
        

    }
    const handleDecrease = () => {

        const updatedServiceData = serviceData?.service_price?.map((data: any) => {
            if (data.id === priceList.id) {
                const currentDiscount = Number(data?.discount);
                const newDiscount = currentDiscount - 1;

                if (newDiscount < 0) {
                    setDiscounts(0)
                    return {
                        ...data,
                        discount: 0
                    };

                } else {

                    setDiscounts(newDiscount)
                    return {
                        ...data,
                        discount: newDiscount
                    };
                }






            }
            return data;
        });
        // setServiceData(updatedServiceData);
        setServiceData({
            ...serviceData, service_price: updatedServiceData
        })
    }




    return (

        <ModalContainer onClick={() => setIsDiscountVisible(false)}>

            <Box onClick={(e: any) => e.stopPropagation()}>
                <ImageBox>
                    <img src={modalOption} height='100%' width='100%' />
                </ImageBox>
                <DiscountBox>
                    <Label>Attract more clients  by offering a discount. </Label>

                    <FlexBox>
                        <InputBox>
                            {/* <Input value={discount} /> */}

                            <div style={{ justifyContent: 'space-between', alignItems: 'center', display: 'flex', flex: 1 }}>
                                <p style={{ margin: 0, fontSize: 20, fontWeight: 'bold' }}>
                                    {discounts < 10 ? `0${discounts} %`
                                        : `${discounts} %`}
                                </p>


                                <IconBox>
                                    <FiChevronUp style={{ fontWeight: 800 }} onClick={handleIncrease} />
                                    <FiChevronDown onClick={handleDecrease} />
                                </IconBox>
                            </div>

                        </InputBox>
                        <Button
                            onClick={() => setIsDiscountVisible(false)}
                        >Set Discount</Button>
                    </FlexBox>
                </DiscountBox>
            </Box>
        </ModalContainer>

    )
}

export default DiscountModal