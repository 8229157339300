import { H3 } from './../../pages/Service_provider/InsuranceSetup/insuranceSetup.styles';
import { colors } from './../../assets/styles/Theme';
import styled from 'styled-components'

export const CartBox = styled.div `
    position: relative;
    border-radius: 13px;
    background-color: ${(props: any) => props.theme.text};
    margin-bottom: 40px;

    & .cart-inner-wrap {
        padding: 20px;
        gap: 15px;
        border-radius: inherit;
        justify-content: space-between;
        display: flex;
        height: 330px;
        /* overflow-y: scroll;
        padding-bottom: 300px; */ 
    }

    @media only screen and (max-width: 454px) {
        .cart-inner-wrap {
            height: 270px;
            padding: 15px;

            &.delivery-inner {
                height: 330px;
                gap: 11px;
            }
        }
    }

    @media only screen and (max-width: 374px) {
        .cart-inner-wrap {
            height: 280px;
            padding: 15px;

            &.delivery-inner {
                height: 330px;
                gap: 11px;
            }
        }
    }

    @media only screen and (min-width: 592px) {
        width: 512px;
    }
`

export const CartSections = styled.div `
    margin-bottom: 45px;
    flex: 0.33;

    @media only screen and (max-width: 454px) {
        margin-bottom: 30px;
    }
`

export const EmptySpaceBox = styled.div `
    height: 20px;
`

export const SectionsHeader = styled.div `
    & .flex-wrap {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-top: 9px;
        margin-bottom: 3px;

        img {
            width: 20px;
        }

        h3 {
            margin: 0;
            white-space: nowrap;
        }
    }

    & h3 {
        font-weight: 700;
        font-size: 12px;
        color: ${(props: any) => props.theme.cartFooterBg};
        /* margin-top: 0px; */
        margin-bottom: 3px;
        white-space: nowrap;

        @media only screen and (min-width: 500px)  {
            font-size: 14px;
        }
    }

    & hr {
        border: solid .2px ${(props: any) => props.theme.cartFooterBg};
        margin: 0;
        width: 80%;
    }

    @media only screen and (max-width: 454px)  {
        h3 {
            font-size: 9px;
        }
    }
`

export const CartLocationWrap = styled.div `
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    gap: 10px;

    & img {
        filter: ${(props: any) => props.theme.cartColor};
    }

    & p {
        color: ${(props: any) => props.theme.cartFooterBg};
        font-size: 12px;
        margin: 0;
    }

    & input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
        font-size: 12px;
        color: ${(props: any) => props.theme.cartFooterBg};
    }

    @media only screen and (max-width: 454px) {
        gap: 5px;
        width: 81px;

        p {
            font-size: 9px;
        }

        input {
            font-size: 9px;
        }
    } 
`

export const CartImageWrap = styled.div `
    margin-top: 20px;
    border-radius: 7px;
    width: 100%;
    overflow: hidden;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
        -moz-box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);
        box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.1);

    & img {
        border-radius: inherit;
        height: 145.68px;
        width: 100%;
        display: block;
        object-position: center;
        object-fit: cover;
    }

    @media only screen and (max-width: 454px) {
        width: 81px;
        height: 65px;

        img {
            object-position: center;
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
`

export const BookingDayWrap = styled.div `
    background-color: ${(props: any) => props.theme.cartFooterBg};
    border: solid 1px ${(props: any) => props.theme.text};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    width: 130px;
    padding: 7px 9px;
    gap: 15px;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 20px;

    & p {
        color: ${(props: any) => props.theme.text};
        font-size: 12px;
        margin: 0;
    }
`

export const NumberControlOverall = styled.div `
    display: flex;
    align-items: center;
    gap: 10px;

    & .control-wrap {
        border-radius: 5px;
        padding: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #ffa100;


        img {
            width: 15px;
        }
    }
`

export const BookingDetailsWrap = styled.div `
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;

    & p {
        color: #797979;
        font-size: 13px;
        margin: 0;
        width: 70px;
        /* border: solid 1px red; */
        overflow-x: hidden;
    }

    & img {
        width: 30px;
    }
`

export const BookingStateInfo = styled.div `
    margin-top: 20px;
    font-weight: 700;
    font-size: 14px;
    color: ${(props: any) => props.theme.cartFooterBg};
    padding-left: 5px;

    @media only screen and (max-width: 454px) {
        font-size: 10px;
    }
`

export const PriceBox = styled.div `
    margin-top: 50px;
    display: flex;
    justify-content: center;

    & p {
        margin: 0;
        font-weight: 700;
        font-size: 12px;
        color: ${(props: any) => props.theme.cartFooterBg};

        @media only screen and (min-width: 500px)  {
            font-size: 14px;
        }
    }

    @media only screen and (max-width: 384px) {
        p {
            font-size: 10px;
        }
    }
`

export const CartFooter = styled.div `
    position: absolute;
    width: 100%;
    background-color: ${(props: any) => props.theme.cartFooterBg};
    border: solid 1px ${(props: any) => props.theme.text};
    border-radius: 13px;
    bottom: 0;
    left: 0;

    .cart-footer-inner {
        padding: 15px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btns-flex {
            display: flex;
            gap: 6px;
        }

        h3 {
            margin: 0;
            color: #ffa100;
            font-size: 14px;
            font-weight: 700;
        }
    }

    @media only screen and (max-width: 454px) {
        .cart-footer-inner {

            h3 {
                font-size: 11px;
            }
        }
    }
`

export const RemoveBtn = styled.div `
    display: flex;
    cursor: pointer;
    border: solid 1px #ffa100;
    border-radius: 7px;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    color: #ffa100;
    font-weight: 700;
    font-size: 13px;

    @media only screen and (max-width: 454px) {
        font-size: 10px;
        border-radius: 4px;
        padding: 3px 8px;
    }
`