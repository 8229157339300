import styled from 'styled-components'



export const ModalContainer = styled.div`
display:flex;
align-items:center;
justify-content:center;
background: rgba(204, 204, 204, 0.96);
height:100vh;
width:100%;
position: fixed;
top:0px;
z-index:10;
left:0px;
bottom:0px;
`
export const Box = styled.div`

border-radius:20px;
background-color: #000;
position:absolute;
width:240px;
height:320px;
z-index:13;
`
export const DiscountBox = styled.div`
background-color: #fff;
padding:20px 12px 30px 12px;
flex:1; 
border-radius:20px;
z-index: 11;
position: absolute;
bottom:0;
`
export const ImageBox = styled.div`
display: flex;
flex:1;
 
`
export const FlexBox = styled.div`
display: flex;
gap:10px;
margin-top:20px;
`
export const InputBox = styled.div`
display: flex;
color:#000;
background:#ffa100;
padding:2px 12px;
border-radius:6px;
border:none;
outline:none;
width:50%;
align-items:center;
gap:10px;

`
export const Input = styled.input`
border:none;
outline:none;
background:transparent;
font-size:20px;
font-weight:700;
width:35%;
padding:0px 10px;
`
export const IconBox = styled.div`
display: flex;
flex-direction:column;
font-size:20px;
font-weight:800;
`
export const Button = styled.button`
width:50%;
color:#fff;
background:#000;
padding:5px 6px;
border-radius:6px;
border:none;
outline:none;
`

export const Label = styled.label`
   font-weight:700;
   font-size:15px;
   color:#000;
`