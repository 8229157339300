import React, { useState, useRef, useEffect } from 'react'
import "./styles/BlogUpload.css"
import 'swiper/css'
import 'swiper/css/free-mode'
import 'react-toastify/dist/ReactToastify.css';

import camera from "../../../assets/images/svg/camera.svg"
import likeIcon from "../../../assets/images/svg/likeFilled.svg"
import car from "../../../assets/images/svg/car-filter.svg"
import driver from "../../../assets/images/svg/driver-filter.svg"
import travel from "../../../assets/images/svg/travel-filter.svg"
import owner from "../../../assets/images/svg/owner-filter.svg"
import author from "../../../assets/images/svg/author.svg"
import videoBtn from "../../../assets/images/svg/video-btn.svg"
import textBtn from "../../../assets/images/svg/text-btn.svg"
import imageBtn from "../../../assets/images/svg/img-btn.svg"
import cancelBtn from "../../../assets/images/svg/cancel-icon-yellow.svg"
import spin from "../../../assets/images/gif/spinner.gif"

import Resizer from "react-image-file-resizer";
import uuid from 'react-uuid';

import Footer from '../../../components/footer'
import Header from '../../../components/header'
import ShareArticle from '../../../components/sharearticle'
import ReviewCard from '../../../components/reviewcard'
import { useCreateBlogPostMutation, useGetBlogLikesQuery } from '../../../services/blogApi'
import InputText from '../../../components/inputText'
import InputVideo from '../../../components/inputVideo'
import InputImage from '../../../components/inputImage'
import Modal from '../../../components/modal'
import { ToastContainer, toast } from 'react-toastify'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation } from 'swiper'






const BlogUpload = () => {
    // USESTATE
    const [coverImage, setCoverImage] = useState<any | undefined>()
    const [authorImage, setAuthorImage] = useState<any | undefined>()
    const [children, setChildren] = useState<any[]>([])
    const [blogCategory, setBlogCategory] = useState("")
    const [authorName, setAuthorName] = useState("")
    const [authorTitle, setAuthorTitle] = useState("")
    const [blogTitle, setBlogTitle] = useState("")
    const [textInput, setTextInput] = useState("")
    const [imageContent, setImageContent] = useState<any | undefined>()
    const [blogId, setBlogId] = useState(3)
    const [likesCount, setLikesCount] = useState<any>()
    const [heading, setHeading] = useState("")
    const [activeClass, setActiveClass] = useState("")
    const [btnLoad, setBtnLoad] = useState(false)


    // RTK ENDPOINTS
    const [createBlogPost, { data, isLoading, isSuccess, isError, error }] = useCreateBlogPostMutation();
    const {data: likeData, isFetching, refetch} = useGetBlogLikesQuery(blogId)

    // USEFFECT
    useEffect(() => {
        refetch()
    }, [blogId])

    useEffect(() => {
        if (isLoading) {
            setBtnLoad(true)
        }
        if (isSuccess) {
            setActiveClass("active-modal")
            setBtnLoad(false)
        }
        if (isError && 'status' in error!) {
            notifyError(error.data.message)
            setBtnLoad(false)
        }
    }, [isLoading, isSuccess, isError, data ])

    // USEREF
    const coverImgRef: any = useRef()
    const authorImgRef: any = useRef()

    // FUNCTIONS
    const addImgOutput = () => {
        // this is basically calling/recognizing the contents in the state and adding the component to it
        setChildren(children => [...children, { prop: <InputImage  setImageContent={setImageContent}/>, id: uuid() }])
    }

    const addTextOutput = () => {
        setChildren(children => [...children, { prop: <InputText setTextInput={setTextInput} />, id: uuid() }])
    }

    const addVidOutput = () => {
        setChildren(children => [...children, { prop: <InputVideo />, id: uuid() }])
    }

    const imageExist = children.some(child => child.prop.type.name === "InputImage")
    const textExist = children.some(child => child.prop.type.name === "InputText")
    const videoExist = children.some(child => child.prop.type.name === "InputVideo")

    const coverImgChange = async (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            setCoverImage(file)
        } else {
            setCoverImage(null)
        }
    }

    const handleAuthorImg = (e:any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            setAuthorImage(file)
        } else {
            setAuthorImage(null)
        }
    }


    const removeCoverImg = () => {
        setCoverImage(null)
    }

    // PUBLISH BLOG
    const handleSubmit = () => {
        let formData: any = new FormData()

        formData.append('title', blogTitle)
        formData.append('content', textInput)
        formData.append('heading', heading)
        formData.append('cover_image', coverImage)
        formData.append('category', blogCategory)
        formData.append('post_image', imageContent)
        formData.append('author_name', authorName)
        formData.append('author_role', authorTitle)
        formData.append('author_image', authorImage)

        if(blogTitle.length === 0) {
            notifyError('Blog Title cannot be empty')
        }else if(textInput.length === 0) {
            notifyError('Blog Content cannot be empty')
        }else if(heading.length === 0) {
            notifyError('Blog Heading cannot be empty')
        }else if(!coverImage) {
            notifyError('Upload cover image')
        }else if(blogCategory.length === 0) {
            notifyError('Select category for blog')
        }else if(authorName.length === 0) {
            notifyError("Please type in author's name")
        }else if(authorTitle.length === 0) {
            notifyError("Please type in author's role")
        }else if(!authorImage) {
            notifyError("Upload author's image")
        }else {
            createBlogPost(formData)
        }
    }


    // REMOVING EITHER IMAGE VIDEO OR TEXT COMPONENT FORM CHILDREN ARRAY
    const removeChild = (val: string) => {
        setChildren(children => children.filter(child => {
            return child.id !== val
        }))
    }

    const notifyError = (text: any) => toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })

    return (
        <div className='blogUploadPage'>
            <ToastContainer />
            <Modal activeClass={activeClass}/>
            <Header />

            <div className="cover-image-section">
                <div className="cover-inner">

                    {
                        !coverImage && <div className="cover-inner-overlay"></div>
                    }


                    {
                        !coverImage &&
                        <div className="upload-action-box">
                            <div className="action-btn" onClick={(e) => {
                                e.preventDefault()
                                coverImgRef.current.click()
                            }}>
                                <img src={camera} alt="" />
                                <p>Upload Cover Image</p>
                            </div>
                        </div>
                    }

                    {
                        coverImage &&
                        <div className="cover-img-wrapper">
                            <img src={URL.createObjectURL(coverImage)} alt="Cover " />
                            <button onClick={removeCoverImg}><img src={cancelBtn} alt="cancel btn" /></button>
                        </div>
                    }

                    <input type="file" className='cover-image-input' accept='image/*' ref={coverImgRef} onChange={coverImgChange} />

                    <div className="input-date">
                        <p></p>
                    </div>
                </div>


                <div className="blogpost-header-container">
                    <div className="blogpost-title">
                        <input type="text" placeholder='Type hook line here...' value={heading}  onChange={(e) => setHeading(e.target.value)}/>
                    </div>

                    <div className="likes">
                        <button>
                            <img src={likeIcon} alt="" />
                        </button>


                        <div className="likes-count">
                            <p>{!likesCount ? 0 : likesCount } likes</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-category-filter">
                <div className="inner-category-filter">
                    <button onClick={() => setBlogCategory("cars")} className={blogCategory === "cars" ? "active-button" : ""}>
                        <img src={car} alt="" />
                        <p>Cars</p>
                    </button>

                    <button onClick={() => setBlogCategory("drivers")} className={blogCategory === "drivers" ? "active-button" : ""}>
                        <img src={driver} alt="" />
                        <p>Drivers</p>
                    </button>

                    <button onClick={() => setBlogCategory("travel")} className={blogCategory === "travel" ? "active-button" : ""}>
                        <img src={travel} alt="" />
                        <p>Travel</p>
                    </button>

                    <button onClick={() => setBlogCategory("ownership")} className={blogCategory === "ownership" ? "active-button" : ""}>
                        <img src={owner} alt="" />
                        <p>Ownership</p>
                    </button>
                </div>
            </div>

            <div className="blogUpload-body">
                <div className="inner-upload-wrapper">
                    <div className="blogpost-topic">
                        <input type="text" placeholder='Type in topic here' value={blogTitle} onChange={(e) =>  setBlogTitle(e.target.value)} />
                    </div>

                    <div className="blogpost-content">
                        <div className="">
                            {children.map((child) => (
                                <div className="" style={{ position: "relative", marginTop: "100px" }}>
                                    <div className="cancel-btn-wrapper" onClick={() => removeChild(child.id)}>
                                        <img src={cancelBtn} alt="" />
                                    </div>
                                    {child.prop}
                                </div>
                            ))}
                        </div>

                        <div className={children?.length === 0 ? "upload-btns-wrapper absolute-btn-wrapper" : "upload-btns-wrapper relative-btn-wrapper"}>
                            <div className="inner-upload-wrapper">
                                {
                                    !imageExist ?
                                        <div className="btn-wrapper no-shadow" >
                                            <img src={imageBtn} alt="" />
                                            <p>Image</p>
                                        </div> :
                                        <div className="btn-wrapper" onClick={() => addImgOutput()}>
                                            <img src={imageBtn} alt="" />
                                            <p>Image</p>
                                        </div>
                                }

                                {
                                    !videoExist ?
                                        <div className="btn-wrapper no-shadow" >
                                            <img src={videoBtn} alt="" />
                                            <p>Video</p>
                                        </div>:
                                        <div className="btn-wrapper" onClick={() => addVidOutput()}>
                                            <img src={videoBtn} alt="" />
                                            <p>Video</p>
                                        </div>
                                }

                                {
                                    textExist ?
                                    <div className="btn-wrapper no-shadow">
                                        <img src={textBtn} alt="" />
                                        <p>Text</p>
                                    </div>:
                                    <div className="btn-wrapper" onClick={() => addTextOutput()}>
                                        <img src={textBtn} alt="" />
                                        <p>Text</p>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>

                    <div className="author-info-container">
                        <div className="inner-author-wrapper">
                        <input type="file" className='author-image-input' accept='image/*' ref={authorImgRef} onChange={handleAuthorImg} />
                            <div className="author-img-wrapper" onClick={(e) => {
                                e.preventDefault()
                                authorImgRef.current.click()
                            }}>
                                
                                {
                                    authorImage &&
                                    <img src={URL.createObjectURL(authorImage)} alt="author" />
                                }

                                {
                                    !authorImage && 
                                    <img src={author} alt="author" />
                                }
                                
                                <img src={camera} alt="camera" className='author-camera' />
                            </div>
                            

                            <div className="text">
                                <input className='author-name' type="text" placeholder='Author’s Name' value={authorName} onChange={(e) => setAuthorName(e.target.value)} />
                                <input className='title-name' type="text" placeholder='Title' value={authorTitle} onChange={(e) => setAuthorTitle(e.target.value)}/>
                            </div>
                        </div>

                        <div className="publish-btn">
                            <button onClick={() => handleSubmit()}>
                                {
                                    btnLoad ? 
                                    <img src={spin} alt="" />: <p>Publish</p>
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default BlogUpload