import { BigBox } from './../successWalletComponent/successwallet.styles';
import styled from 'styled-components'

export const FailedWalletWrap = styled.div `
    display: ${(props:any) => props.display};
    margin: 40px 0;
` 

export const FailedBigBox = styled(BigBox) `
    background-color: #09051C;
`