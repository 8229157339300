import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import zip from '../../../assets/images/png/zip.png'
import wallet from '../../../assets/images/png/walletMoney.png'
import {
    Div, H3, FlexDiv, Label, BalanceDiv, H4
} from './providerBalance.styles';
import ToggleBar from '../toggleBar';
import { useAppSelector } from '../../../store';
import { useGetWalletByIdQuery } from '../../../services/walletApi';
import { baseUrl } from '../../../services/providerApi';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ProviderBalance: FC<{ providerInfo: any }> = ({ providerInfo }) => {

    const { id, name, firstname, lastname, token
    } = useAppSelector((state: any) => state?.auth?.auth || '')

    const [walletDetails, setWalletDetails] = useState<any>([])

    const { data: walletData, refetch: refetchWallet, isSuccess: walletSuccess } = useGetWalletByIdQuery(id, { refetchOnFocus: true, refetchOnMountOrArgChange: true })

    useEffect(() => {
        refetchWallet()
    }, [])

    useEffect(() => {
        if (walletSuccess) {
            setWalletDetails(walletData?.data)
        }
    }, [])

    const [display, setDisplay] = useState<string>('')

    const getProviderDetails = async () => {

        await axios.get(`${baseUrl}provider-next-of-kin/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          }
        }).then((response: any) => {
    
          if (response && response.data[0]?.status) {
                setDisplay(response.data[0]?.display)
            console.log('provider', response.data[0]?.status)
          }
        }).catch((err: any) => {
        })
    
      }
    
      useEffect(() => {
        getProviderDetails()
      }, [id])
    





    return (
        <>
            <Div>
                <H3>Hello {providerInfo?.name}</H3>
                <FlexDiv>
                    <Label>Are avaliable for requests today?</Label>
                    <ToggleBar
                        display={display}
                        setDisplay={setDisplay}
                    />
                    <Label>
                        {display === '0' ? 'No' : 'Yes'}

                    </Label>

                </FlexDiv>


                <BalanceDiv>
                    <img width={120} src={zip} style={{ position: 'absolute', left: -15, top: -30 }} />
                    <img width={120} src={wallet} style={{ position: 'absolute', right: 10, top: 19 }} />
                    <Label style={{ position: 'absolute', top: 68, left: 20, fontSize: '14px' }}>My Income</Label>
                    <H4 style={{ position: 'absolute', top: 73, left: 8 }}>
                        {walletDetails?.currency_code || 'NGN'} {walletDetails?.balance || 0.00}
                    </H4>
                </BalanceDiv>
            </Div>
        </>

    )
}

export default ProviderBalance