import React from 'react'
import { Card, CardBtn, DriverImg, H1, ImgCard, P, TextCard, TextCardInner } from './drivercard.styles'
import fullDriver from "../../assets/images/svg/fulltime-driver.svg"
import { NavLink } from 'react-router-dom'

const DriverRequestCard = ({driverTypeImg, cardHeader, cardContent, imgBg}: any) => {
  return (
    <Card> 
        <ImgCard imgBg={imgBg}>
            <DriverImg src={driverTypeImg} />
        </ImgCard>

        <TextCard>
            <TextCardInner>
                <div style={{padding:'0 10px'}}>
                    <H1>
                        {cardHeader}
                    </H1>
                </div>

                <div>
                    <P>
                        {cardContent}
                    </P>
                </div>

                <div className='access-offer-wrap' style={{display:'flex', justifyContent:'center'}}>
                    <NavLink to='/driver-booking'>
                        <CardBtn>
                            Access Offers
                        </CardBtn>
                    </NavLink>

                </div>


            </TextCardInner>
        </TextCard>
    </Card>
  )
}

export default DriverRequestCard