import React from 'react'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import { ReceiptActionFooter, ReceiptBodyWrap, ReceiptHead, ReceiptSummaryBottom, ReceiptSummaryTop, SubfooterCards, TripSummaryBox } from './receiptpage.styles'
import { jsPDF } from "jspdf";

import profile from '../../../assets/images/png/wallet-profile.png'
import car from '../../../assets/images/png/trips-card-car.png'
import download from '../../../assets/images/svg/icon-download.svg'
import send from '../../../assets/images/svg/icon-send.svg'
import canada from '../../../assets/images/png/canada.png'

const index = () => {

    const doc = new jsPDF();


  return (
    <>
        <Header />
        <ReceiptBodyWrap>
            <div className="inner-receipt-body-wrap">
            <ReceiptHead>
                <img src={profile} alt="" />
                <p>Hi Phebe</p>
            </ReceiptHead>

            <ReceiptSummaryTop>
                <div className="date-type-divide">
                    <p>17th April 2023</p>

                    <p>Vehicle Rental</p>
                </div>

                <div className="card-wrap">
                    <div className="top-section">
                        <div className="text-side">
                            <h4>Your Receipt For Your Booking - Benz 2018</h4>
                        </div>

                        <div className="image-side">
                            <img src={car} alt="" />
                        </div>
                    </div>

                    <div className="bottom-section">
                        <div className="left-title">
                            <h4>Total:</h4>
                        </div>

                        <div className="right-value">
                            <h4>$294.32</h4>
                        </div>
                    </div>
                </div>
            </ReceiptSummaryTop>

            <ReceiptSummaryBottom>
                <div className="rate-type-header">
                    <p>Hourly Rate</p>
                </div>

                <div className="trip-summary-header">
                    <div className="wrap">12 Hours</div>
                    <div className="wrap">$25/H</div>
                    <div className="wrap">$264</div>
                </div>

                <TripSummaryBox>
                    <div className="inner-trip-wrap">
                        <p className="left-text">Insurance (PAI)</p>

                        <p className="right-text">$ 2.5</p>
                    </div>
                </TripSummaryBox>

                <TripSummaryBox>
                    <div className="inner-trip-wrap">
                        <p className="left-text">Paystack charges </p>

                        <p className="right-text">$3.5</p>
                    </div>
                </TripSummaryBox>

                <TripSummaryBox>
                    <div className="inner-trip-wrap">
                        <p className="left-text">Promotion</p>

                        <p className="right-text">-$10</p>
                    </div>
                </TripSummaryBox>

                <TripSummaryBox>
                    <div className="inner-trip-wrap">
                        <p className="left-text">Tax fee</p>

                        <p className="right-text">$34.32</p>
                    </div>
                </TripSummaryBox>

                <ReceiptActionFooter>
                    <div className="receipt-footer-wrap">
                        <SubfooterCards>
                            <img src={download} alt="" />
                            <p>Download</p>
                        </SubfooterCards>

                        <SubfooterCards>
                            <img src={send} alt="" />
                            <p>Send Email</p>
                        </SubfooterCards>

                        <SubfooterCards>
                            <img src={canada} alt="" />
                            <p>CAD</p>
                        </SubfooterCards>
                    </div>
                </ReceiptActionFooter>
            </ReceiptSummaryBottom>
            </div>
        </ReceiptBodyWrap>
        <Footer />
    </>
  )
}

export default index