import React, { FC, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'

import whiteMark from "../../../assets/images/png/whiteMark.png";

import {
  VerifyButton,
  ShadowDiv,
  Label,
  Button,
  InfoDiv,
  P,
  DisabledButton,
} from "./index.styles";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useUploadInsuranceMutation } from "../../../services/providerApi";
import { useAppSelector } from "../../../store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
const ServiceFee: FC<{
  setVehicleDetails: any;
  vehicleDetails: any;
  driver?: any;
}> = ({ driver, vehicleDetails, setVehicleDetails }) => {
  const navigate = useNavigate();

  const [isBaseFee, setIsBaseFee] = useState<boolean>(true);
  const [isAdditionalFee, setIsAdditionalFee] = useState<boolean>(false);
  const [percent, setPercent] = useState<number>(driver ? 30 : 15);
  const [btnLoad, setBtnLoad] = useState<boolean>(false);

  const handleServiceFee = (option: string): void => {
    if (option === "BaseFee") {
      setIsBaseFee(true);
      setIsAdditionalFee(false);
    }
    if (option === "BaseFeePlusInsurance") {
      setIsAdditionalFee((prev) => !prev);
      setIsBaseFee(false);
    }
  };

  const handlePercent = (value: number): void => {
    setPercent(value);
    if (value) {
      setIsAdditionalFee(false);
      setIsBaseFee(true);
      setVehicleDetails({
        ...vehicleDetails,
        charge: value,
      });
    } else if (value) {
      setIsAdditionalFee(true);
      setIsBaseFee(false);
      setVehicleDetails({
        ...vehicleDetails,
        charge: value,
      });
    }
  };

  const [setupInsurance, { data, isLoading, isSuccess, isError, error }] =
    useUploadInsuranceMutation();

  useEffect(() => {
    if (isLoading) {
      setBtnLoad(true);
    }
    if (isSuccess) {
      setBtnLoad(false);
      // navigate('/provider-dashboard')
      notifySuccess("Insurance Document Submitted");
      setTimeout(() => {
        navigate("/provider-dashboard"); // Redirect to "/profile-setup" after 3 seconds
      }, 2000);
    }

    if (isError && "status" in error!) {
      if (isError && error.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
      }
      setBtnLoad(false);
    }
  }, [isLoading, isSuccess, isError, data]);

  const user = useAppSelector((state: any) => state?.auth?.auth);
  const vehicleId = useAppSelector(
    (state: any) => state?.provider?.vehicle[0]?.id
  );

  console.log("vehicleDetails", vehicleDetails.charge);

  // const handleFinish = (
  //   event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => {
  //   let formData: any = new FormData();
  //   formData.append("user_id", user?.id);
  //   formData.append("vehicle_id", vehicleId);
  //   formData.append("insurance_percent", percent);

  //   setupInsurance(formData);
  // };
  // const alertError = () => {
  //   notifyError("Please Select any Insurance Option");
  // };

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  return (
    <>
      <ToastContainer />
      <P>Select service fee preference</P>
      {driver ? (
        <ShadowDiv>
          <Button
            onClick={() => handlePercent(30)}
            style={percent === 30 ? { background: "#ffa100" } : {}}
          ></Button>
          <Label>30% fee on every transaction </Label>

          {isBaseFee ? (
            <VerifyButton onClick={() => setIsBaseFee(false)}>
              <AiOutlineMinus style={{ fontSize: 20, fontWeight: 800 }} />
            </VerifyButton>
          ) : (
            <VerifyButton onClick={() => handleServiceFee("BaseFee")}>
              <AiOutlinePlus style={{ fontSize: 20, fontWeight: 800 }} />
            </VerifyButton>
          )}
        </ShadowDiv>
      ) : (
        <ShadowDiv>
          <Button
            onClick={() => handlePercent(15)}
            style={percent === 15 ? { background: "#ffa100" } : {}}
          ></Button>
          <Label>15% fee on every transaction </Label>

          {isBaseFee ? (
            <VerifyButton onClick={() => setIsBaseFee(false)}>
              <AiOutlineMinus style={{ fontSize: 20, fontWeight: 800 }} />
            </VerifyButton>
          ) : (
            <VerifyButton onClick={() => handleServiceFee("BaseFee")}>
              <AiOutlinePlus style={{ fontSize: 20, fontWeight: 800 }} />
            </VerifyButton>
          )}
        </ShadowDiv>
      )}

      {isBaseFee && (
        <InfoDiv>
          <ul style={{ color: "#000", fontSize: 13, fontWeight: 600 }}>
            <li style={{ marginBottom: 12 }}>Roadlers Service Charge</li>
          </ul>
        </InfoDiv>
      )}

      {driver ? (
        <ShadowDiv>
          <Button
            onClick={() => handlePercent(35)}
            style={percent === 35 ? { background: "#ffa100" } : {}}
          ></Button>
          <Label>35% fee on every transaction </Label>

          {isAdditionalFee ? (
            <VerifyButton onClick={() => setIsAdditionalFee(false)}>
              <AiOutlineMinus style={{ fontSize: 20, fontWeight: 800 }} />
            </VerifyButton>
          ) : (
            <VerifyButton
              onClick={() => handleServiceFee("BaseFeePlusInsurance")}
            >
              <AiOutlinePlus style={{ fontSize: 20, fontWeight: 800 }} />
            </VerifyButton>
          )}
        </ShadowDiv>
      ) : (
        <ShadowDiv>
          <Button
            onClick={() => handlePercent(25)}
            style={percent === 25 ? { background: "#ffa100" } : {}}
          ></Button>
          <Label>25% fee on every transaction </Label>

          {isAdditionalFee ? (
            <VerifyButton onClick={() => setIsAdditionalFee(false)}>
              <AiOutlineMinus style={{ fontSize: 20, fontWeight: 800 }} />
            </VerifyButton>
          ) : (
            <VerifyButton
              onClick={() => handleServiceFee("BaseFeePlusInsurance")}
            >
              <AiOutlinePlus style={{ fontSize: 20, fontWeight: 800 }} />
            </VerifyButton>
          )}
        </ShadowDiv>
      )}

      {isAdditionalFee && (
        <InfoDiv>
          <ul style={{ color: "#000", fontSize: 13, fontWeight: 600 }}>
            <li style={{ marginBottom: 12 }}>
              Roadlers Service Charge {driver ? "(30%)" : "(10%)"}
            </li>
            <li style={{ marginBottom: 12 }}>
              Cover shall be granted based on the following: fire, accidental
              damage, and 3rd party liability.
            </li>
            <li style={{ marginBottom: 12 }}>
              Cover shall only be active when the vehicle is rented through
              Roadlers.
            </li>
            <li style={{ marginBottom: 12 }}>
              Renters will bear an excess of N10,000 or 10% of any claim,
              whichever is higher.
            </li>
          </ul>
        </InfoDiv>
      )}
    </>
  );
};

export default ServiceFee;
