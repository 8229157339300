import React, { FC, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'

import {
  AuthDiv,
  Div,
  H3,
  SignupDiv,
  VerifyButton,
  VerifiedButton,
  Input,
  P,
  Button,
  DisabledButton,
} from "../../../components/customerSignUp/secondStep/second.styles";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { FiChevronLeft } from "react-icons/fi";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  useConfirmOTPMutation,
  useCreateOTPMutation,
  useForgotPasswordMutation,
} from "../../../services/providerApi";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  FLEXDIV2,
  ICONDIV,
  InputField,
} from "../../../components/providerAccountSetup/firstScreen/firstScreen.styles";
import { BsEnvelopeFill } from "react-icons/bs";
import { setOTPData } from "../../../slices/otp";
import Header from "../../../components/header";

const ForgotPassword = ({ component, setComponent }: any) => {
  const userOTP = useAppSelector((state: any) => state?.otp?.otp);

  const [email, setEmail] = useState<string>("");
  const [isEmailVsible, setIsEmailVsible] = useState(false);
  const [isPhoneVsible, setIsPhoneVsible] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isContinue, setIsContinue] = useState(false);

  let userPhone = userOTP?.phoneNumber;
  let userEmail = userOTP?.email;

  const [OTPValue, setOTPValue] = useState<string | number>();
  const [btnLoad, setBtnLoad] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const dispatch: any = useAppDispatch();
  const navigate = useNavigate();

  const [forgotPassword, { data, isLoading, isSuccess, isError, error }] =
    useForgotPasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      notifySuccess(data?.data);
    }

    if (isError && "status" in error!) {
      notifyError(error?.data?.message);
    }

    if (isError && "status" in error!) {
      if (isError && error.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
      }
      setBtnLoad(false);
    }
  }, [isLoading, isSuccess, isError, data]);

  const validateEmail = (email: string) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleEmail = (e: any) => {
    setEmail(e.target.value);
    setIsValidEmail(validateEmail(e.target.value));
  };

  const handleContinue = () => {
    if (!isValidEmail) {
      notifyError("Email provided Invalid, Please provide valid email");
    } else {
      let formData: any = new FormData();
      formData.append("email", email);
      localStorage.setItem("recovery_email", email);
      forgotPassword(formData)
        .then((response: any) => {
          notifySuccess(response?.data?.data?.responseMessage);
          navigate("/check-mail");
        })
        .catch((error) => {});
    }
  };

  const handlePrevComponent = () => {
    navigate("/login");
  };

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  return (
    <>
      <ToastContainer />
      <Header />

      <AuthDiv>
        <Div>
          <FiChevronLeft
            onClick={handlePrevComponent}
            style={{ color: "#fff", fontSize: "30px", fontWeight: "700" }}
          />
          <H3>Forgot Password</H3>
        </Div>
        <SignupDiv style={{ height: "auto" }}>
          <>
            <FLEXDIV2 style={{ marginTop: 1, boxShadow: "2px 2px 5px  #ddd" }}>
              <ICONDIV
                style={{ fontSize: 20, borderRadius: "10px 0px 0px 10px" }}
              >
                <BsEnvelopeFill />
              </ICONDIV>
              <Input>
                <InputField
                  style={{ fontSize: 14, padding: "16px 0px" }}
                  type="text"
                  value={email}
                  onChange={handleEmail}
                  placeholder="Email Address"
                />
              </Input>
            </FLEXDIV2>
          </>
        </SignupDiv>

        <Button onClick={() => handleContinue()}>Continue</Button>

        <p style={{ fontSize: "13px" }}>
          {" "}
          <span style={{ color: "#fff" }}>Already have an account ? </span>
          <Link to={"/login"}>
            <span style={{ color: "#FFA100" }}>Login</span>
          </Link>
        </p>
      </AuthDiv>
    </>
  );
};

export default ForgotPassword;
