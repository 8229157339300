import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';


interface CustomError {
    data: {
        error: string;
        message: any;
        statusCode: number;
    };
    status: number;
}

export const baseUrl = process.env.REACT_APP_API_BASE_URL;
//export const baseUrl = "https://testapi.roadlers.com/api/v2/";
// export const baseUrl = "https://testapi.roadlers.com/api/v2/";

// const baseUrl = 'https://testapi.roadlers.com/api/v1/';

export const adminApi = createApi({
    reducerPath: 'adminApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState)?.auth?.auth?.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
    tagTypes: ['admin'],
    endpoints: builder => ({
       
      
    

       
       
        
        adminCustomerData: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'search-customer',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        deleteVehicle: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'delete-vehicle',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        pinVehicle: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'pin-vehicle',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),

        pinDriver: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'pin-driver',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),

        pinProvider: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'pin-provider',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        
        acceptRequest: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'confirm-request',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        rejectRequest: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'confirm-request',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        updateRequest: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-request',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),

        updateOwnerProfile: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-car-owner-registration',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),

        
        verifyVehicle: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'verify-vehicle-profile',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),

        verifyProvider: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'verify-provider-profile',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        verifyDriver: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'verify-driver-profile',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        

        verifyVehicleDocument: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'verify-vehicle-document',
                    method: 'post',
                    // body, 
                    body: value

                };

            },
        }),
        
     
        getFilterVehicles: builder.query({
            query: (value) => {
                return {
                    url: `filter-vehicle-by-status/${value}`,
                };
            },
        }),

        getPinnedVehicles: builder.query({
            query: (value) => {
                return {
                    url: `filter-pin-vehicle/${value}`,
                };
            },
        }),
        

        getAdminProviders: builder.query<any, void>({
            query: () => {
                return {
                    url: `admin-list-provider`,
                };
            },
        }),

        getAdminDrivers: builder.query({
            query: (page) => {
                return {
                    url: `list-driver-admin?page=${Number(page)}`,
                };
            },
        }),

        getAdminRequests: builder.query({
            query: (page) => {
                return {
                    url: `admin-list-request?page=${Number(page)}`,
                };
            },
        }),

      

        getFetchTotalRequests: builder.query<any, void>({
            query: () => {
                return {
                    url: 'fetch-total-requests',
                };
            }
        }),


        getVehicleById: builder.query({
            query: (id) => {
                return {
                    url: `fetch-vehiclebyidsingle/${id}`,
                };
            },
        }),

      

        getBikeById: builder.query({
            query: (id) => {
                return {
                    url: `bike-details/${id}`,
                };
            },
        }),
        getDriverById: builder.query({
            query: (id) => {
                return {
                    url: `fetch-driver-provider/${id}`,
                };
            },
        }),
        getProviderById: builder.query({
            query: (id) => {
                return {
                    url: `view-provider-profile/${id}`,
                };
            },
        }),
        getProviderByPlateNumber: builder.query({
            query: (plate_no) => {
                return {
                    url: `view-provider-by-plate-number/${plate_no}`,
                };
            },
        }),
        getNextOfKinByProviderId: builder.query({
            query: (id) => {
                return {
                    url: `provider-next-of-kin/${id}`,
                };
            },
        }),

    }),

    
});

export const {
    
    useAcceptRequestMutation,
    useRejectRequestMutation,
    useUpdateRequestMutation,
    useVerifyVehicleMutation,
    useVerifyProviderMutation,
    useVerifyDriverMutation,
    useVerifyVehicleDocumentMutation,
    useUpdateOwnerProfileMutation,
    useDeleteVehicleMutation,
    usePinVehicleMutation,
    usePinDriverMutation,
    usePinProviderMutation,
    useGetFilterVehiclesQuery,
    useGetPinnedVehiclesQuery,
    useGetFetchTotalRequestsQuery,
    useGetVehicleByIdQuery,

    useGetProviderByIdQuery,
    useGetProviderByPlateNumberQuery,
    useGetBikeByIdQuery,
    useGetDriverByIdQuery,
    useAdminCustomerDataMutation,
    useGetAdminDriversQuery,
    useGetAdminRequestsQuery,
    useGetAdminProvidersQuery,
    useGetNextOfKinByProviderIdQuery


} = adminApi;