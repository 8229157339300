import styled from 'styled-components'
export const StatsBox = styled.div`
display:flex;
flex-direction:column;
align-items:start;
justify-content:flex-start;
width:100%;
border-radius:10px;
position:relative;
background:#FCF8F8;
padding:.7em .8em;
margin-bottom:7px;
height:100px;

`
export const P = styled.p`
color: #797979;
font-size:11px;
font-weight:700;
margin-left:0;

`
export const IconDiv = styled.div`
color: #fff;
background: #000;
padding: .4em;
display:flex;
align-items:center;
justify-content:center;
position:absolute;
right:7px;
top:10px;
border-radius:5px;

`
export const H3 = styled.h3`
font-weight:700;
color: #1D1C1C;
margin:0;
`

export const Button = styled.button`
color:#fff;
font-size:8px;
font-weight:600;
border-radius:3px;
border:1px solid #fff;
padding:2px 3px;
`
export const ButtonGroup = styled.div`
color:#fff;
font-size:8px;
position:relative;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;

`

export const Stat = styled.p`
color:#fff;
font-size:10px;
margin-top:5px;
`

export const Div = styled.div`
display:flex;
gap:5px;
justify-content:center;
`
export const StatsDiv = styled.div`
display:flex;
flex-direction:column;
width:49%;
`
