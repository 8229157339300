import styled from 'styled-components'
export const Div = styled.div`
display:flex;
align-items:flex-start;
flex-direction:column;
justify-content:start;
width:100%;
margin-top:60px;
padding:2em;
`
export const H3 = styled.h3`
color:#fff;
padding:0px .8em;
margin-bottom:10px;
`
export const H4 = styled.h4`
color:#fff;
padding:0px .8em;
font-size:18px;

`
export const FlexDiv =styled.div`
 display:flex;
padding:0px .8em;
gap:3px;
`
export const Label=styled.label`
color:#fff;
font-weight:400;
font-size:11px;
`
export const BalanceDiv=styled.div`
background:#000;
height:150px;
width:100%;
margin-top:20px;
border-radius:20px;
position:relative;
overflow:hidden;
`




