import styled from 'styled-components'

export const TripsTabOverall = styled.div `
    padding: 0 40px;
    margin-bottom: 30px;

    @media only screen and (min-width: 1100px) {
        display: flex;
        justify-content: center;
    }
`

export const TripsCardOverall = styled.div `
    padding: 0 20px;
    margin: 30px 0;
    margin-top: 120px;
    display: ${(props:any) => props.activeTab ? 'flex' : 'none'};
    flex-direction: column;
    align-items: center;

`