import styled from 'styled-components'


export const H4 = styled.h4`
color:#fff;
margin-top:50px;
`
export const Button = styled.button`
color:#C4C4C4;
padding:5px 15px;
background:#000;
border-radius:7px;
font-size:10px;
font-weight:bold;
margin-top:20px;
border:none;
`
export const FlexDiv =styled.div`
 display:flex;
width:100%;
justify-content:space-between;
margin-top:50px;
flex-wrap:wrap;
`

export const BalanceDiv=styled.div`
background:#000;
height:160px;
width:100%;
margin-top:20px;
border-radius:20px;
position:relative;
overflow:hiddem;
`




