import React, { useState } from 'react'
import AssetCard from '../../../components/assetCard'
import Carousel from '../../../components/carousel'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import OffersCard from '../../../components/offersCard'
import OwnersNoteCard from '../../../components/ownersNoteCard'
import ProgressBar from '../../../components/progressBar'
import RoadlersCard from '../../../components/roadlersCard'
import { SearchOverall, SearchInput, CarouselSection, BookingQuestionsWrap, QuestionHeader, QuestionBox, PP, QuestionImg, ModalCallBtnWrap, ModalCallBtn, BookingFooterWrap, BookingFooterBtn, FeatureWrapper, SubFeatureWrap, TickBox } from '../CarBooking/CarBooking.styles'
import { SearchBtn, BoxExtras, H11, AssetOverall } from '../Homepage/homepage.styles'

import arrowDown from '../../../assets/images/svg/arrowDown.svg'
import inputProfile from '../../../assets/images/svg/input-profile.svg'
import whenTag from '../../../assets/images/svg/when-tag.svg'
import noteTag from '../../../assets/images/svg/note-tag.svg'
import tickX from '../../../assets/images/svg/tickx-icon.svg'
import tickR from '../../../assets/images/svg/tickr-icon.svg'
import gift1 from "../../../assets/images/svg/gift1.svg"
import gift2 from "../../../assets/images/svg/gift2.svg"
import gift3 from "../../../assets/images/svg/gift3.svg"
import { LocationInputWrap, InputIcons } from '../../../components/locationModal/locationmodal.styles'
import { NavLink } from 'react-router-dom'
import SearchComponent from '../../../components/searchComponent'

const GiftcardBooking = () => {
  const [receiverName, setReceiverName] = useState('')
  const [receiverMail, setReceiverMail] = useState('')

    const slides:any = [
        {img: gift1, name: 'Main Image', id: 0},
        {img: gift2, name: 'Inner Image', id: 1},
        {img: gift3, name: 'Back Image', id: 2}
    ]

  return (
    <>
        <Header />
        
        <SearchComponent />

        <CarouselSection bg={'#fff'} wrapHeight={'200px'}>
          <Carousel 
            slides={slides} 
            display={'block'} 
            priceDisplay={'none'}
            title={'Gift Card'}
        />
        </CarouselSection>

        <div className="" style={{padding: '30px', paddingRight: '80px'}}>
          <ProgressBar 
            progressText={'Who is the gift card for?'}
            barDisplay={'none'}
          />

          <BookingQuestionsWrap>
            <LocationInputWrap>
                <input type="text" 
                  placeholder='Reciever’s First Name'
                  value={receiverName}
                  onChange={(e:any) => setReceiverName(e.target.value)}
                />
                <InputIcons src={inputProfile} iconWidth={'20px'} /> 
            </LocationInputWrap>

            <LocationInputWrap>
                <input type="email" 
                  placeholder="Receiver's Email"
                  value={receiverMail}
                  onChange={(e:any) => setReceiverMail(e.target.value)}
                />
            </LocationInputWrap>
          </BookingQuestionsWrap>

          <BookingFooterWrap>
            <BookingFooterBtn text={'#fff'} bordercolor={'#fff'} bg={'#000'}>
              ₦0.00
            </BookingFooterBtn>

            <NavLink to='/cart'>
              <BookingFooterBtn text={'#000'} bordercolor={'#ffa100'} bg={'#ffa100'}>
                  Purchase Card
              </BookingFooterBtn>
            </NavLink>

          </BookingFooterWrap>
        </div>

        <FeatureWrapper>
          <h4>Feature</h4>

          <SubFeatureWrap>
            <TickBox>
              <QuestionImg width={'15px'} src={tickR} />
            </TickBox>

            <p>Digital gift card </p>
          </SubFeatureWrap>

          <SubFeatureWrap>
            <TickBox>
              <QuestionImg width={'15px'} src={tickR} />
            </TickBox>

            <p>Can be used any time</p>
          </SubFeatureWrap>

          <SubFeatureWrap>
            <TickBox>
              <QuestionImg width={'15px'} src={tickR} />
            </TickBox>

            <p>One time coupon code</p>
          </SubFeatureWrap>
        </FeatureWrapper>

        <BoxExtras>
          <OwnersNoteCard />

          <RoadlersCard />
        </BoxExtras>

        <BoxExtras>
            <div className="subsection-header">
                <H11>
                  Related Offers
                </H11>
            </div>

            <AssetOverall>
                <AssetCard />
                <AssetCard />
                <AssetCard />
                <AssetCard />
                <OffersCard />
                <OffersCard />
            </AssetOverall>

        </BoxExtras>

        <Footer />
    </>
  )
}

export default GiftcardBooking