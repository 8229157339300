import styled from 'styled-components'


export const DIV = styled.span`
display:flex;
align-items:center;
gap:20px;
background:#fff;
width:100%;
border-radius:12px;
padding:1px 15px;
margin-top:25px;
`


export const INPUTS = styled.input`
width:50%;
border-radius:12px;
padding:15px 0px;
border:none;
outline:none;
width:100%;
font-size:16px;
`
export const FLEXDIV = styled.div`
display:flex;
width:100%;
gap:15px;
padding:0px;

`




