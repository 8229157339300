import React, { useEffect, useState } from 'react'
import CollectMoneyThree from '../../../components/collectMoneyThree'
import Header from '../../../components/header'
import PaymentMethodComponent from '../../../components/paymentMethodComponent'
import { OverallPaymentWrap, PaymentBox, RoadlersBlackCircle, RoadlersHomeCone, WalletContentWrap, WalletModalBox, WalletModalHeader } from '../../../components/walletModal/walletmodal.styles'
import roadlersCone from '../../../assets/images/svg/homebutton.svg'
import logo from '../../../assets/images/svg/logo-small.svg'
import FailedWalletComponent from '../../../components/failedWalletComponent'
import SuccessWalletComponent from '../../../components/successWalletComponent'
import { useGetPaymentLinkQuery, useInitiatePayMeMutation } from '../../../services/walletApi'
import { useParams } from 'react-router-dom'

const PaymentLinkPage = () => {
  const [showComponent, setShowComponent] = useState('c')
  const [paymentLinkData, setPaymentLinkData] = useState<any>([])
  const { link } = useParams();

  const {data, refetch, isSuccess} = useGetPaymentLinkQuery(link
  , {refetchOnFocus:true, refetchOnMountOrArgChange:true})
  
  const [payme, {data: paymeData, isLoading: paymeLoading, isError: isPaymeError, error: paymeError, isSuccess: paymeSuccess}] = useInitiatePayMeMutation()

  // USEFFECT
  useEffect(() => {
      refetch()

      if(isSuccess) {
          setPaymentLinkData(data?.data)
      }
  }, [data, link])

  useEffect(() => {
    if(paymeSuccess) {
      window.open(paymeData.data?.authorization_url)
    }
  }, [paymeData, paymeError, isPaymeError, paymeSuccess])

  const handlePayme = (data: any) => {
    payme(data)
  }

  return (
    <>
        <Header />

        <OverallPaymentWrap>
          <PaymentBox>
            <RoadlersHomeCone src={roadlersCone} />
            <RoadlersBlackCircle>
              <img src={logo} alt="" />
            </RoadlersBlackCircle>

            <div className="inner-modal-box">
              <WalletModalHeader>
                <div className=""></div>
              </WalletModalHeader>

              <WalletContentWrap>
                <SuccessWalletComponent  displaybox={showComponent === 'success' ? 'block' : 'none'} handleShowComponent={setShowComponent}/>

                <FailedWalletComponent  displaybox={showComponent === 'failed' ? 'block' : 'none'} handleShowComponent={setShowComponent}/> 

                <CollectMoneyThree displaybox={showComponent === 'c' ? 'block' : 'none'} handleShowComponent={setShowComponent} description={paymentLinkData?.description} receiverName={paymentLinkData?.receiver_name} currency={paymentLinkData?.currency} amount={paymentLinkData?.amount} payerName={paymentLinkData?.payer_name} handlePayme={handlePayme} paymeSuccess={paymeSuccess} link={link}/>

                <PaymentMethodComponent displaybox={showComponent === 'd' ? 'block' : 'none'} handleShowComponent={setShowComponent} 
                />
              </WalletContentWrap>
            </div>
          </PaymentBox>
        </OverallPaymentWrap>
        

    </>
  )
}

export default PaymentLinkPage