import { ModalOverlay } from '../optionModal/optionmodal.styles';
import { ModalFilterBox } from '../filterBoxModal/filterModal.styles';
import styled from "styled-components"

export const LocationModalOverlay = styled(ModalOverlay)`
    background-color: rgba(0,0,0, 0.6);
`

export const LocationModalBox = styled(ModalFilterBox) `
    background-color: #fff;
`

export const LocationHeader = styled.div `
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
        font-size: 15px;
        color: #000;
        margin: 0;
        font-weight: 700;
    }

    img {
        width: 30px;
        cursor: pointer;
    }
`

export const LocationInputWrapper = styled.div `
    margin-top: 20px;

    input {
        background-color: #fff;
        outline: none;
        border: solid 1px #000;
        padding: 15px;
        width: 100%;
        border-radius: 8px;

        &::placeholder {
            font-size: 14px;
            color: #868686;
        }
    }
`

export const LocationActionWrap = styled.div `
    display: flex;
    justify-content: flex-start;
`