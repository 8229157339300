import React, { useEffect, useState, memo } from 'react'
import {  DeleteFormBtn, DeleteFormWrap, InputWrapOverall } from '../deliveryLocationModal/deliverylocation.styles'
import { LocationInputWrap, InputIcons, InputBtn } from '../locationModal/locationmodal.styles'
import PhoneInput from 'react-phone-number-input'
import { Autocomplete } from '@react-google-maps/api'

import mails from '../../assets/images/svg/mail.svg'
import profile from '../../assets/images/svg/user-icon-black.svg'
import greyBox from '../../assets/images/svg/greybox.svg'


const MultipleDeliveryInput = ({value, setOriginValue, setMailValue, setNameValue, setPhoneValue, mail, newAddress, package_id, setFormId, handleDelete, setInputValue, name}:any) => {
    const [valueContent, setValueContent] = useState<any>(value || '')
    const [mailContent, setMailContent] = useState(mail || '')
    const [nameContent, setNameContent] = useState(name || '')
    const [address, setAddress] = useState(newAddress || '')
    const [autocomplete, setAutocomplete] = useState<any>(null)

    const onPlaceChanged = () => {
        if(autocomplete !== null) {
            const places = autocomplete.getPlace().formatted_address
            if(places) {
                setAddress(places)
                setInputValue(places)
            }else {
                setAddress(autocomplete.getPlace().name)
                setInputValue(autocomplete.getPlace().name)
            }
        }
    }

    console.log(package_id)

    useEffect(() =>{
        setPhoneValue(valueContent)
        setNameValue(nameContent)
        setMailValue(mailContent)
        setOriginValue(address)
    }, [mailContent, nameContent, valueContent, address])

  return (
    <>
        <label>
            Delivery location
        </label>
        <LocationInputWrap>
            <InputIcons src={greyBox} iconWidth={'30px'} />
            <Autocomplete className='autocomplete-wrap'
                onPlaceChanged={onPlaceChanged}
                onLoad={(value) => setAutocomplete(value)}
            >
                <input type="text" placeholder='Type here' onChange={(e) => {setAddress(e.target.value);
                        setInputValue(e.target.value);
                }}
                value={address}/>
            </Autocomplete>
        </LocationInputWrap>

        <InputWrapOverall>
            <LocationInputWrap>
                <PhoneInput
                    defaultCountry='US'
                    international={true}
                    placeholder="Enter phone number"
                    value={valueContent}
                    onChange={setValueContent}
                />
            </LocationInputWrap>
        </InputWrapOverall>

        <InputWrapOverall>
            <LocationInputWrap>
                <InputIcons src={mails} iconWidth={'30px'} />
                <input type="email" placeholder='Reciever’s Email (otpional)' value={mailContent} onChange={(e:any) => setMailContent(e.target.value)}/>
            </LocationInputWrap>
        </InputWrapOverall>

        <InputWrapOverall>
            <LocationInputWrap>
                <InputIcons src={profile} iconWidth={'20px'} />
                <input type="text" placeholder='Reciever’s Name' value={nameContent} onChange={(e:any) => setNameContent(e.target.value)}/>
            </LocationInputWrap>
        </InputWrapOverall>
        {/* <DeleteFormWrap>
            <DeleteFormBtn onClick={() => handleDelete(package_id)}>
                Delete
            </DeleteFormBtn>
        </DeleteFormWrap> */}
    </>
  )
}

export default memo(MultipleDeliveryInput)