import styled from "styled-components"

export const Card = styled.div `
    min-height: 600px;
    /* width: 320px; */
    border-radius: 16px;
    position: relative;
`

export const ImgCard = styled.div `
    border-radius: 16px;
    height: 200px;
    width: 100%;
    border: solid 1px black;
    /* background-color: ${(props: any) => props.imgBg}; */
`

export const DriverImg = styled.img `
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
    /* border: none; */
`

export const TextCard = styled.div `
    position: absolute;
    padding-bottom: 30px;
    width: 99.5%;
    left: 0;
    top: 105px;
    border: solid 1px #EBAF4A;
    border-radius: 16px;
    background-color: ${(props:any) => props.theme.driverCardBg};
`
export const TextCardInner = styled.div `
    position: relative;
    padding: 5px 18px;

    & .access-offer-wrap {
        a {
            text-decoration: none;
            color: unset;
        }
    }
`

export const H1 = styled.h2 `
    font-size: 25px;
    font-weight: 800;
    color: #FFA100;
    text-align: center;
    margin: 0;

    @media only screen and (max-width: 484px) {
        font-size: 19px;
    }
`

export const P = styled.p `
    font-size: 19px;
    color: #fff;
    font-weight: 600;
    text-align: center;

    @media only screen and (max-width: 484px) {
        font-size: 16px;
    }
`

export const CardBtn = styled.div `
    border: solid 1px white;
    border-radius: 8px;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-weight: 800;
    margin-top: 20px;
    padding: 15px;
    width: 150px;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 484px) {
        font-size: 14px;
    }
`