import React, { FC, useEffect, useState, useRef } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import { IoCarSport } from 'react-icons/io5';
import { BiUser } from 'react-icons/bi';
import { RiDashboard3Line } from 'react-icons/ri';
import { BsCalendar2Minus } from 'react-icons/bs';
import { GiReceiveMoney } from 'react-icons/gi';





import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import ProviderBalance from '../../../components/providerDashboard/providerBalance/providerBalance';
import ProviderActions from '../../../components/providerDashboard/providerActions/providerActions';
import AdminHeader from '../../../components/adminHeader/';
import DashboardInfo from '../../../components/Admin/DahboardInfo';
import Counter from '../../../components/Admin/Counter';
import Filter from '../../../components/Admin/Filter';
import ButtonGroups from '../../../components/Admin/ButtonGroups';
import Vehicles from '../../../components/Admin/Vehicles';

import {
    AbsoluteDiv, IconDiv, P, Button
} from './header.styles';



const LinkHeader: FC = () => {



    const location = useLocation();

    return (
        <>

            <AbsoluteDiv>
                <Link to='/list-drivers' style={{ textDecoration: 'none', color: '#fff' }}>
                    <IconDiv
                        style={location.pathname === '/list-drivers' ? { background: '#ffa100' } : { background: '#000' }} >
                        <BiUser style={location.pathname === '/list-drivers' ? { color: '#000' } : { color: '#fff' }} />
                        <P>Sign Ups</P>
                    </IconDiv>
                </Link>

                <Link to='/list-vehicles' style={{ textDecoration: 'none', color: '#fff' }}>

                    <IconDiv
                        style={location.pathname === '/list-vehicles' ? { background: '#ffa100' } : { background: '#000' }}>
                        <IoCarSport style={location.pathname === '/list-vehicles' ? { color: '#000' } : { color: '#fff' }} />

                        <P>Vehicles</P>
                    </IconDiv>
                </Link>

                <Link to='/admin-dashboard' style={{ textDecoration: 'none', color: '#fff' }}>
                    <IconDiv
                        style={location.pathname === '/admin-dashboard' ? { background: '#ffa100' } : { background: '#000' }}>
                        <RiDashboard3Line style={location.pathname === '/admin-dashboard' ? { color: '#000' } : { color: '#fff' }} />

                        <P>Dashboard</P>
                    </IconDiv>
                </Link>
                <Link to='/list-requests' style={{ textDecoration: 'none', color: '#fff' }}>

                    <IconDiv
                        style={location.pathname === '/list-requests' ? { background: '#ffa100' } : { background: '#000' }}>
                        <GiReceiveMoney style={location.pathname === '/list-requests' ? { color: '#000' } : { color: '#fff' }} />

                        <P>Bookings</P>
                    </IconDiv>
                </Link>

                <Link to='' style={{ textDecoration: 'none', color: '#fff' }}>
                    <IconDiv >
                        <BsCalendar2Minus />
                        <P>Messages</P>
                    </IconDiv>
                </Link>

            </AbsoluteDiv >



        </>
    )
}
export default LinkHeader