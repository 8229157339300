import React, { FC, useEffect, useState, useRef } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { IMAGE1, AP, BUTTON, IMAGEDIV, IMAGESDIV, DIV, H3 } from './driverProfile.styles';

import blueCar from '../../../assets/images/png/blueCar.png'
import providerPlaceholder from '../../../assets/images/png/providerPlaceholder.png'

import DriverProfileForms from './profileForms';
import { Link, useNavigate } from 'react-router-dom';
import { useDriverPictureUpdateMutation, useDriverUpdateMutation, useGetVehicleByIdQuery } from '../../../services/providerApi';
import { ImageDiv } from '../../providerDashboard/providerDrivers/driver.styles';
import { ApprovedDiv, RejectedDiv, StatusDiv } from '../../../pages/Service_provider/VehicleProfile/vehicleProfile.styles';
import { useAppSelector } from '../../../store';
import { baseImageUrl } from '../../../services/utils';


const DriverProfile: FC<{ driverProfile: any }> = ({ driverProfile }) => {
    const user: any = useAppSelector((state: any) => state?.auth?.auth)
    const [updateDriverPicture, { data, isLoading, isSuccess, isError, error }] = useDriverPictureUpdateMutation()

    const navigate = useNavigate()

    // const [updateDriver, { data, isLoading, isSuccess, isError, error }] = useDriverUpdateMutation()

    const handlePrev = () => {
        if (user?.user_role === 'admin') {
            navigate('/list-drivers')
        }
        else {
            navigate('/provider-dashboard')
        }
    };
    const [driverDetails, setDriverDetails] = useState<any>()


    let [image1Prev, setImage1Prev] = useState<any>()
  
    useEffect(() => {
        if (driverProfile && driverProfile.data && driverProfile.data.length > 0) {
            setDriverDetails(driverProfile.data[0]);
            setImage1Prev(baseImageUrl+driverProfile?.data[0]?.driver_picture)
        }
    }, [driverProfile]);

   





    // let obj = {
    //     'lastname': driverDetails?.lastname,
    //     'driver_id': driverDetails?.id,
    //     'availability1': null,
    //     'availability': ['Car Driving'],
    //     'address': driverDetails?.address,
    //     'phone_number': driverDetails?.phone_number,
    //     'firstname': driverDetails?.firstname,
    //     'gender': driverDetails?.gender,
    //     'country': driverDetails?.country,
    //     'state': driverDetails?.state,
    //     'id_number': driverDetails?.id_number,
    //     'identification': driverDetails?.identification,
    //     'latitude': driverDetails?.latitude,
    //     'longitude': driverDetails?.longitude,


    // }
    const fileInputRef = useRef<HTMLInputElement>(null);


    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.persist();
        const file: any = event.currentTarget.files;
        if (file != null) {
            setImage1Prev(file);
            let reader = new FileReader();
            reader.onload = (e) => {
                if (e.target != null) {
                    setImage1Prev(e.target.result);
                }

            };
            reader.readAsDataURL(file[0]);
        }
        const formData = new FormData();
        formData.append('id', driverDetails.id);
        formData.append('driver_picture', file[0]);
        //@ts-ignore
        updateDriverPicture(formData).then((response: any) => {
            //   notifySuccess('Front Image Saved')

        }).catch((err: any) => {
        })

    };



    return (
        <>
            <div style={{ position: 'relative', paddingTop: '20px', display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '8px', marginBottom: '30px' }}>

                <BsChevronLeft onClick={() => handlePrev()}
                    style={{ color: 'white', fontSize: 25, fontWeight: 600 }} />
                <H3>Driver Profile </H3>


                {
                    (driverProfile?.data[0]?.status === 'Rejected')
                    && <RejectedDiv style={{ top: '-32px' }}>Rejected</RejectedDiv>

                }
                {(driverProfile?.data[0]?.status === 'Approved')
                    && <ApprovedDiv style={{ top: '-32px' }}>Accepted</ApprovedDiv>
                }

                {(driverProfile?.data[0]?.status === 'Pending')
                    && <StatusDiv style={{ top: '-32px' }}>Pending Approval</StatusDiv>
                }
                {(driverProfile?.data[0]?.status === null)
                    && <StatusDiv style={{ top: '-32px' }}>Pending Approval</StatusDiv>
                }
            </div>

            <ImageDiv style={{ position: 'relative', overflow: 'hidden', width: '100px', height: '100px' }}>
                <img src={image1Prev} style={{ width: '100px', height: 'auto' }} />
                <BUTTON
                    onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                        event.preventDefault();
                        if (fileInputRef.current != null) {
                            fileInputRef.current.click()
                        }
                    }}
                >X</BUTTON>
            </ImageDiv>
            <DriverProfileForms driverProfile={driverProfile} />


            <input type="file" style={{ display: 'none' }} ref={fileInputRef} accept="image/*" onChange={onChange} />

        </>
    )
}
export default DriverProfile