import React, { FC, useEffect, useState, useRef } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import {
    Div, H6, TransferDiv, TransferDetails, TransferAmount, H5, Span, Label
} from './paymentHistory.styles';


import { CiReceipt } from 'react-icons/ci';

const PaymentHistory: FC = () => {




    return (
        <>

          

            <Div>
                <TransferDiv>
                    <TransferDetails>
                        <H6>From: Peace</H6>
                        <Span>
                            <Label>18 July 2022</Label>
                            <Label>17:50</Label>
                        </Span>
                    </TransferDetails>
                    <TransferAmount>
                        <Span style={{gap:'3px'}}>
                            <CiReceipt style={{color:'#000', fontSize:'38px'}} />
                            <H5>+$100,000.00</H5>

                        </Span>
                    </TransferAmount>

                </TransferDiv>
            </Div>


        </>
    )
}
export default PaymentHistory