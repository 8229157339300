import React, { useEffect, useRef, useState } from 'react'
import { PillWrapper } from '../../pages/user/Homepage/homepage.styles'
import { CancelModalBox, ModalBox, ModalOverlay, OptionPill, PillRadioBtn } from '../optionModal/optionmodal.styles'
import { CancelWrap } from '../itemSizeModal/itemsize.styles'
import { ArrowDownWrap, DestinationOverall, InputBtn, InputIcons, LocationBox, LocationInputWrap, LocationModalBox, SpecialReqBtn, UserLocationWrap, WhereOptionPill, WhereRadioPill, YellowBgArrowWrap } from '../locationModal/locationmodal.styles'
import { SaveBtn } from '../extrasModal/extrasmodal.styles'
import { useJsApiLoader, LoadScript, GoogleMap, Marker, Autocomplete, DirectionsRenderer } from '@react-google-maps/api'
import { setShowLocation } from '../../slices/showLocation'
import { AppDispatch, RootState, useAppDispatch, useAppSelector } from '../../store'

import whereTag from '../../assets/images/svg/where-tag.svg'
import greyBox from '../../assets/images/svg/greybox.svg'
import arrowDown from '../../assets/images/svg/arrowDown.svg'
import cancel from '../../assets/images/svg/cancel-yellow.svg'
import smiley from '../../assets/images/png/smiley.png'




const center = {lat: 6.605874, lng: 3.349149}

const DriverLocationModal = ({setLocationBtn, setOrigin, handleDestination, setPickupLatLong, setTravelState, setTransportType, destination, origin, editItem}:any) => {
    const dispatch: AppDispatch = useAppDispatch()
    const {showLocation} = useAppSelector<any>((store:RootState) => store?.showLocation)

    const [map, setMap] = useState(null)
    const [directionResponse, setDirectionResponse] = useState(null)
    const [distance, setDistance] = useState('')
    const [duration, setDuration] = useState('')
    const [where, setWhere] = useState('within')
    const [withinText, setWithinText] = useState('within')
    const [withoutText, setWithoutText] = useState('')
    const [choiceState, setChoiceState] = useState('')
    const [showLocationBox, setShowLocationBox] = useState('block')
    const [showErrorBox, setShowErrorBox] = useState('none')
    const [showAvailableState, setShowAvailableState] = useState('none')
    const [showFullModal, setShowFullModal] = useState (false)
    const [autocomplete, setAutocomplete] = useState<any>(null)
    const [autocompleteOrigin, setAutocompleteOrigin] = useState<any>(null)
    const [userDestination, setUserDestination] = useState('')


    const originRef:any = useRef()

    const destinationRef:any = useRef()

    const libraries: (
        | 'places'
        | 'drawing'
        | 'geometry'
        | 'localContext'
        | 'visualization'
      )[] = ['places', 'geometry', 'drawing'];

    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyDkMuW-hGvdRfUhsb2R9RFqA0Pq4Qxg4Cs',
        libraries,
    }) 

    const onOriginPlaceChanged = () => {
        if(autocompleteOrigin !== null) {
            const places = autocompleteOrigin.getPlace().formatted_address
            if(places) {
                setOrigin(places)
            }else {
                setOrigin(autocompleteOrigin.getPlace().name)
            }
        }
    }

    const onPlaceChanged = () => {
        if(autocomplete !== null) {
            const places = autocomplete.getPlace().formatted_address
            if(places) {
                handleDestination(places)
                setUserDestination(places)
            }else {
                setUserDestination(autocomplete.getPlace().name)
                handleDestination(autocomplete.getPlace().name)
            }
        }
    }

    const locationModalControl = () => {
        dispatch(setShowLocation(false))
    }

    useEffect(() => {
        locationModalControl()
    }, [showFullModal])

    async function calculateRoute() {
        // @ts-ignore
        if(origin?.length === 0 || destination?.length === 0) {
            return setLocationBtn(false)
        }
        const directionService = new google.maps.DirectionsService()
        const results = await directionService.route({
            // @ts-ignore
            origin: origin,
            // @ts-ignore
            destination: destination,
            travelMode: google.maps.TravelMode.DRIVING
        })

        const address = origin

        // @ts-ignore
        setDirectionResponse(results)
        // @ts-ignore
        setDistance(results.routes[0].legs[0].distance.text)
        // @ts-ignore
        setDuration(results.routes[0].legs[0].duration.text)

        setShowFullModal(!showFullModal)
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ address }, (result:any, status) => {
            if (status === "OK") {
              setPickupLatLong({
                lat: result[0].geometry.location.lat(),
                lng: result[0].geometry.location.lng()
              });
            } else {
              console.log("Geocode was not successful for the following reason: " + status);
            }
        });

        setLocationBtn(true)
    }

    console.log(origin, 'orig')
    console.log('dest', destination)

    const setLocationWithin = () => {
        setWhere('within')
        setWithinText('within')
        setWithoutText('')
        setTransportType('Within state')
    }

    const setLocationWithout = () => {
        setWhere('without')
        setWithinText('')
        setWithoutText('without')
        setTransportType('Out of state')
    }

    const handleTavelLocation = () => {
        if(origin?.length > 3 && choiceState?.length > 3) {
            setLocationBtn(true)
            setShowFullModal(!showFullModal)
            setTravelState(choiceState)
            handleDestination(choiceState)

            const address = origin
            const geocoder = new window.google.maps.Geocoder();

            geocoder.geocode({ address }, (result:any, status) => {
                if (status === "OK") {
                  setPickupLatLong({
                    lat: result[0].geometry.location.lat(),
                    lng: result[0].geometry.location.lng()
                  });
                } else {
                  console.log("Geocode was not successful for the following reason: " + status);
                }
            });
        }
    }

    // USEFFECT TO HANDLE EDITED ITEMS
    useEffect(() => {
        if(editItem) {
            setOrigin(editItem?.[0]?.pickup_location)
            if(editItem?.[0]?.state_location?.includes('Within')) {
                setLocationWithin()
                setUserDestination(editItem?.[0]?.destination)
                handleDestination(editItem?.[0]?.destination)
            }else {
                setLocationWithout()
                setChoiceState(editItem?.[0]?.destination)
            }
        }
    }, [editItem])
    

 
  return (
    <div>
        <ModalOverlay activeModal={showLocation}>
            <CancelModalBox  onClick={() => locationModalControl()} />
                <LocationModalBox boxDisplay={showLocationBox} onClick={()=> {dispatch(setShowLocation(true))}}>
                    <div className="inner-modal-box">
                        <LocationBox>
                            {/* Google map box */}
                            <GoogleMap 
                                center={center} 
                                zoom={10} 
                                mapContainerStyle={{width: '100%', height: '100%', borderRadius: '14px'}} 
                                options={{
                                    zoomControl: false,
                                    streetViewControl: false,
                                    mapTypeControl: false,
                                    fullscreenControl: false
                                }}
                            >
                                <Marker position={center} />
                                {directionResponse && <DirectionsRenderer directions={directionResponse}/>}
                            </GoogleMap>

                        </LocationBox>

                        <UserLocationWrap>
                            <h4>Where</h4>

                            <div className='where-pill-wrap'>
                                <WhereOptionPill onClick={setLocationWithin}>
                                    <WhereRadioPill pillActive={withinText} />

                                    <p>Within State</p>
                                </WhereOptionPill>

                                <WhereOptionPill onClick={setLocationWithout}>
                                    <WhereRadioPill pillActive={withoutText}/>

                                    <p>Travel out of state</p>
                                </WhereOptionPill>
                            </div>

                            <div className="location-input-overall">

                                <label>
                                    Pickup location
                                </label>
                                <LocationInputWrap>
                                    <InputIcons src={whereTag} iconWidth={'30px'} />
                                    <Autocomplete className='autocomplete-wrap'
                                    onPlaceChanged={onOriginPlaceChanged}
                                    onLoad={(value) => setAutocompleteOrigin(value)}
                                    >
                                        <input type="text" placeholder='Type here' value={origin} onChange={(e:any) => setOrigin(e.target.value) }  />
                                    </Autocomplete>
                                </LocationInputWrap>

                                <DestinationOverall display={where === 'within' ? 'block' : 'none'}>
                                    <label>
                                        Farthest destination
                                    </label>
                                    <LocationInputWrap>
                                        <InputIcons src={greyBox} iconWidth={'30px'} />

                                        <Autocomplete className='autocomplete-wrap'        onPlaceChanged={onPlaceChanged}
                                         onLoad={(value) => setAutocomplete(value)}
                                        >
                                            <input type="text" placeholder='Type here' 
                                            value={userDestination}
                                            onChange={(e:any) => {
                                                setUserDestination(e.target.value);
                                                handleDestination(e.target.value)
                                            }} />
                                        </Autocomplete> 

                                        <InputBtn onClick={calculateRoute}>
                                            <p>Done</p>
                                        </InputBtn>
                                    </LocationInputWrap>
                                </DestinationOverall>

                                <DestinationOverall display={where !== 'within' ? 'block' : 'none'}>
                                    <label>
                                        Travel Destination
                                    </label>
                                    <LocationInputWrap>
                                        <InputIcons src={greyBox} iconWidth={'30px'} />
                                        <input type="text" placeholder='Type Destination State' value={choiceState} onChange={(e:any) => setChoiceState(e.target.value)}/>

                                        {/* <YellowBgArrowWrap>
                                            <InputIcons src={arrowDown} iconWidth={'30px'} />
                                        </YellowBgArrowWrap> */}
                                        
                                        {
                                            choiceState?.length !== 0 &&
                                            <InputBtn onClick={() => handleTavelLocation()}>
                                                <p>Done</p>
                                            </InputBtn>
                                        }
                                    </LocationInputWrap>
                                </DestinationOverall>


                            </div>
                        </UserLocationWrap>
                    </div>
                </LocationModalBox>

                {/* <LocationModalBox boxDisplay={showErrorBox}>
                    <div className="inner-modal-box">
                        <CancelWrap>
                            <div className="" onClick={handleShowLocationBox}>
                                <img src={cancel} alt="" /> 
                            </div>
                        </CancelWrap>

                        <div className="header-text">
                            <h2>Ops!</h2>
                        </div>

                        <div className="content-wrap">
                            <p>
                            This vehicle owner is not available for out of state travel at the moment 
                            </p>

                            <InputIcons src={smiley} iconWidth={'140px'}/>
                        </div>

                        <SpecialReqBtn>
                            Make A Special Request
                        </SpecialReqBtn>
                    </div>
                </LocationModalBox> */}

                {/* <LocationModalBox boxDisplay={showAvailableState}>
                    <div className="inner-modal-box">
                        {
                            states?.map((item:any, index:any) => {
                                return (
                                    <OptionPill key={index} onClick={() => handleSelectState(item?.name)}>
                                        <PillRadioBtn activePill={choiceState === item?.name ? 'fill' : ''} />
                                        <p>
                                            {item?.name}
                                        </p>
                                    </OptionPill>                                   
                                )
                            })
                        }

                        <div className="redirect-link">
                            Didn't see your state? Click to make a special request.
                        </div>
                    </div>
                </LocationModalBox> */}
        </ModalOverlay>
    </div>

  )
}

export default DriverLocationModal