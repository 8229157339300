import React from "react";
import {
  BottomImg,
  ParallaxImgWrap,
  Boxes,
  Button,
  ContentWrap,
  H1,
  H11,
  HeaderBox,
  HorizontalLine,
  Image,
  ImageWrap,
  P,
  Wrapper,
  GarageOverall,
} from "./garagebox-styles";

import garage from "../../assets/images/svg/garage.svg";
import car from "../../assets/images/png/travelCar.png";
import boxes from "../../assets/images/svg/boxes.svg";
import bottomcar from "../../assets/images/svg/cars-bottom.svg";
import { NavLink } from "react-router-dom";

const TravelGarageBox = () => {
  return (
    <GarageOverall>
      <Wrapper>
        <ContentWrap>
          <H1>Roadlers Travel Garage</H1>
          <HorizontalLine />
          <P>
            Travel across the country with comfortable rides and refreshments,
            get a ticket at more affordable rate
          </P>
          <NavLink to="/travel-garage" className="travel-garage-link">
            <Button>Book Travel Ride</Button>
          </NavLink>
        </ContentWrap>
        <ImageWrap>
          <Image src={car} />
        </ImageWrap>
      </Wrapper>

      <ParallaxImgWrap />
    </GarageOverall>
  );
};

export default TravelGarageBox;
