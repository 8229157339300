import React, { FC, useEffect, useState, useRef } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import { IoCarSport } from 'react-icons/io5';
import { BiUser } from 'react-icons/bi';
import { RiDashboard3Line } from 'react-icons/ri';
import { BsCalendar2Minus } from 'react-icons/bs';
import { GiReceiveMoney } from 'react-icons/gi';


import {
    Page,
} from './vehicles.styles';



import { Navigate, useNavigate } from 'react-router-dom';
import ProviderBalance from '../../../components/providerDashboard/providerBalance/providerBalance';
import ProviderActions from '../../../components/providerDashboard/providerActions/providerActions';
import AdminHeader from '../../../components/adminHeader/';
import DashboardInfo from '../../../components/Admin/DahboardInfo';
import Counter from '../../../components/Admin/Counter';
import Filter from '../../../components/Admin/Filter';
import ButtonGroups from '../../../components/Admin/ButtonGroups';
import Vehicles from '../../../components/Admin/Vehicles';

import {
    AbsoluteDiv, IconDiv, P, Button, PagePadding
} from '../Dashboard/dashboard.styles';
import LinkHeader from '../../../components/Admin/Header/header';



const ListVehicles: FC = () => {
    return (
        <>
            <AdminHeader />
            <Page>

                <PagePadding className='padding'>


                    <LinkHeader />

                    <Counter vehicle={true} />

                    <Filter vehicle={true} />
                    <ButtonGroups isVehicle={true} />
                    <Vehicles />
                </PagePadding>


            </Page>
        </>
    )
}
export default ListVehicles