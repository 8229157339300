import React, { useState } from 'react'
import FailedWalletComponent from '../failedWalletComponent'
import { ModalOverlay, CancelModalBox } from '../optionModal/optionmodal.styles'
import OtpWalletComponent from '../otpWalletComponent'
import SuccessWalletComponent from '../successWalletComponent'
import SwapWalletComponent from '../swapWalletComponent'
import { WalletModalBox, WalletModalHeader, WalletContentWrap, RoadlersBlackCircle, RoadlersHomeCone } from '../walletModal/walletmodal.styles'

import roadlersCone from '../../assets/images/svg/homebutton.svg'
import logo from '../../assets/images/svg/logo-small.svg'

const SwapCurrencyModal = ({showSwapModal, removeWalletModal}:any) => {
    const [showComponent, setShowComponent] = useState('a')

    const handleShowComponent = (type: any) => {
        setShowComponent(type)
    }
  return (
    <ModalOverlay activeModal={showSwapModal}>
        <CancelModalBox onClick={() => {removeWalletModal(false)
            handleShowComponent('a')
        }}/>
        <WalletModalBox>
            <RoadlersHomeCone src={roadlersCone} />
            <RoadlersBlackCircle>
                <img src={logo} />
            </RoadlersBlackCircle>
            <div className="inner-modal-box">
                <WalletModalHeader>
                    <div className=""></div>
                </WalletModalHeader>

                <WalletContentWrap>
                    <OtpWalletComponent displaybox={showComponent === 'otp' ? 'block' : 'none'} handleShowComponent={handleShowComponent} />
                    <SuccessWalletComponent displaybox={showComponent === 'success' ? 'block' : 'none'} handleShowComponent={handleShowComponent} />
                    <FailedWalletComponent displaybox={showComponent === 'failed' ? 'block' : 'none'} handleShowComponent={handleShowComponent} />
                    <SwapWalletComponent displaybox={showComponent === 'a' ? 'block' : 'none'} handleShowComponent={handleShowComponent}/>
                </WalletContentWrap>
            </div>
        </WalletModalBox>

    </ModalOverlay>
  )
}

export default SwapCurrencyModal