import styled from 'styled-components'

export const PickDriverCard = styled.div `
    background-color: ${(props: any) => props.theme.text};
    border-radius: 12px;
    cursor: pointer;
    padding: 10px;
    position: relative;
    /* -webkit-box-shadow: 0px 6px 9px 1px rgba(255,255,255,1);
    -moz-box-shadow: 0px 6px 9px 1px rgba(255,255,255,1);
    box-shadow: 0px 6px 9px 1px rgba(255,255,255,1); */

    & .inner-wrap {
        width: 120px;

        & .card-top {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }
    }
`

export const DistanceWrap = styled.div `
    position: absolute;
    top: -20px;
    right: 15px;

    & p {
        font-size: 11px;
        margin: 0;
    }
`

export const TopImgWrap = styled.div `
    border-radius: 50%;
    border: solid 4px #ffa100;
    width: 50px;
    height: 50px;

    & img {
        width: 100%;
        height: 100%;
        /* height: 40px; */
        border-radius: inherit;
        object-fit: cover;
        object-position: center;
        display: block;
    }
`

export const DriverRadioBtn = styled.div `
    width: 20px;
    height: 20px;
    background-color: ${(props:any) => props.active ? '#ffa100' : 'transparent'};
    border: solid 3px ${(props:any) => props.theme.cartFooterBg};
    border-radius: 50%;
`

export const DriverName = styled.div `
    color: ${(props:any) => props.theme. cartFooterBg};
    margin: 15px 0;
    font-size: 14px;
    font-weight: 700;
`

export const PricePerDayOverall = styled.div `
    display: flex;
    justify-content: flex-start;
`

export const PricePerDay = styled.div `
    background-color: #383837;
    border-radius: 9px;
    padding: 8px 12px;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    color: #fff;
    font-weight: 700;
    width: 80px;
    font-size: 10px;
`

export const CardFooter = styled.div `
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
`

export const RidesCount = styled.div `
    color: #868686;
    font-weight: 700;
    font-size: 13px;
`

export const Rating = styled.div `
    display: flex;
    align-items: center;
    gap: 5px;

    & img {
        width: 15px;
    }
    
    & p {
        margin: 0;
        color: #868686;
        font-weight: 700;
        font-size: 13px;
    }
`