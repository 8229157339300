import React from 'react'
import { Button, Card, CardImage, CardInner, H2, P } from './signupCard.styles'

import car from "../../assets/images/svg/carProp.svg"
import driverProp from "../../images/svg/driverProp.svg"

const SignupCard = ({ownerType, contentText, imgProp, propBg, onClick}: any) => {
  return (
    <Card propBg={propBg} >
        <CardImage src={imgProp} />
        <CardInner>
            <div>
                <H2>
                    {ownerType}
                </H2>
            </div>

            <div>
                <P>
                    {contentText}
                </P>
            </div>

            <Button onClick={onClick}>
                Sign Up
            </Button>
        </CardInner>
    </Card>
  )
}

export default SignupCard