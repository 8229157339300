import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import providerPlaceholder from '../../../assets/images/png/providerPlaceholder.png'
import ratings from '../../../assets/images/png/ratings.png'
import pin from '../../../assets/images/png/pin.png'
import pinned from '../../../assets/images/png/YellowPin.png'

import deleteicon from '../../../assets/images/png/delete.png'
import { GiCheckMark } from 'react-icons/gi';
import { IoCallSharp, IoEllipsisHorizontalOutline } from 'react-icons/io5';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { BsEnvelope, BsPinFill } from 'react-icons/bs';
import { GrFormClose } from "react-icons/gr";



import {
    DriverDiv, Div, ImageDiv, CheckMark, DriverDetails, FlexDiv, H6, Id, StatusLabel, HideLabel,
    TripsText, CallDiv, CallButton, IdDiv, AddressDiv, FirstNameDiv, NameLabel, PinnedInfoDiv,
    TopLabel, JoinDiv, MessageDiv, Ellipses, PendingLabel, RejectedLabel
} from './driver.styles';
import { Link } from 'react-router-dom';
import moment from 'moment'
import { PinModal } from '../pinModal'
import { DeleteModal } from '../../providerDashboard/deleteModal'
import { EllipsesModal } from '../Ellipses/Modal'
import { baseImageUrl } from '../../../services/utils'
import { AiOutlineClose } from 'react-icons/ai'
import axios from 'axios'
import { baseUrl } from '../../../services/adminApi'
import { useAppSelector } from '../../../store'

const Driver: FC<{ setUnHide: any, setHide: any, driverDetails: any }> =
    ({ setUnHide, setHide, driverDetails }) => {
        const user: any = useAppSelector((state: any) => state?.auth?.auth)

        const firstPart = driverDetails?.availability?.split(",")[0];
        const [isDelete, setIsDelete] = useState<boolean>(false)
        const [isHide, setIsHide] = useState<boolean>(false)
        const [isPin, setIsPin] = useState<boolean>(false)
        const [isUnPin, setIsUnPin] = useState<boolean>(false)
        const [trips, setTrips] = useState<number>(0)

        const handleDeleteModal = async () => {
            setIsDelete((prev: boolean) => !prev)
        }
        const handleHideModal = async () => {
            setIsHide((prev: boolean) => !prev)
        }
        const handlePinModal = async () => {
            setIsPin((prev: boolean) => !prev)
        }
        const handleUnPinModal = async () => {
            setIsUnPin((prev: boolean) => !prev)
        }
        const [isEllipsesVisible, setIsEllipsesVisible] = useState(false)

        const openEllipses = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>,) => {
            setIsEllipsesVisible((prev) => !prev)
        };



        const getTrips = async () => {
            // const promise = toast.loading('Loading...');

            await axios.get(`${baseUrl}count-driver-trip/${driverDetails?.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${user?.token}`,
                    "Access-Control-Allow-Origin": "*",
                }
            }).then((response) => {

                if (response?.data?.message === 'Details Retrieved') {
                    setTrips(response?.data?.data?.totalTrips)

                }

            }).catch((err) => {
            })
        }

        useEffect(() => {
            getTrips()
        }, [])

        return (
            <>
                {
                    isDelete && <DeleteModal
                        id={driverDetails?.id}
                        setIsDelete={setIsDelete}
                        isDriver={true}
                        isDelete={true}

                    />
                }
                {
                    isHide && <DeleteModal
                        id={driverDetails?.id}
                        driverStatus={driverDetails?.status}
                        driverDisplay={driverDetails?.display}
                        setIsDelete={setIsHide}
                        isHide={true}
                        isDriver={true}
                        admin={true}
                        setDriverHide={setHide}
                        setDriverUnHide={setUnHide}

                    />
                }
                {
                    isPin && <PinModal id={driverDetails?.id}
                        setIsPin={setIsPin}
                        isDriver={true}
                    />
                }
                {
                    isUnPin && <PinModal id={driverDetails?.id}
                        setIsPin={setIsPin}
                        isUnPin={isUnPin}
                        setIsUnPin={setIsUnPin}
                        isDriver={true}

                    />
                }


                {isEllipsesVisible &&
                    <EllipsesModal
                        // vehicleInfo={vehicleInfo}
                        setIsEllipsesVisible={setIsEllipsesVisible}
                        // setVehicleInfo={setVehicleInfo}
                        isDriver={true}
                    />
                }
                <DriverDiv>
                    <PinnedInfoDiv>
                        <JoinDiv>

                            {
                                driverDetails?.pin_status === '1' ? <img src={pinned} onClick={handleUnPinModal} width={12} /> :
                                    <img src={pin} width={12} onClick={handlePinModal} />
                            }
                            <TopLabel>Joined: {moment(driverDetails?.created_at).format('DD/MM/YYYY')}</TopLabel>
                        </JoinDiv>
                        <img src={deleteicon} width={12} onClick={handleDeleteModal} />
                        <MessageDiv>
                            <BsEnvelope style={{ fontSize: 18 }} />
                        </MessageDiv>
                        <TopLabel>{firstPart}</TopLabel>

                        <Ellipses onClick={openEllipses}>

                            <IoEllipsisHorizontalOutline style={{ fontSize: 25, color: '#FFD58C' }} />
                        </Ellipses>
                    </PinnedInfoDiv>
                    <IdDiv>
                        <ImageDiv style={{ overflow: 'hidden', width: 80, height: 80 }}>
                            <img style={{ width: 80, height: 'auto' }} src={baseImageUrl + driverDetails?.driver_picture} />
                        </ImageDiv>

                        {driverDetails?.guarantor?.length > 0 ?
                            <CheckMark>
                                <GiCheckMark style={{ padding: '.2em', borderRadius: '5px', background: '#FFD58C', }} />
                                <p style={{ fontSize: '9px', fontWeight: '800' }}>Guarantor</p>
                            </CheckMark> :
                            <CheckMark>
                                <AiOutlineClose style={{ color: 'red', padding: '.2em', borderRadius: '5px', background: '#FFD58C', }} />
                                <p style={{ fontSize: '9px', fontWeight: '800' }}>Guarantor</p>
                            </CheckMark>
                        }


                        <CheckMark>
                            <GiCheckMark style={{ padding: '.2em', borderRadius: '5px', background: '#FFD58C', }} />
                            <p style={{ fontSize: '9px', fontWeight: '800' }}>ID</p>
                        </CheckMark>

                        <CheckMark>
                            <GiCheckMark style={{ padding: '.2em', borderRadius: '5px', background: '#FFD58C', }} />
                            <p style={{
                                fontSize: '9px', fontWeight: '800',
                                width: '60px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}>{driverDetails?.identification} </p>
                        </CheckMark>

                    </IdDiv>

                    <AddressDiv>
                        <DriverDetails>
                            <FirstNameDiv>
                                <NameLabel>First</NameLabel>
                                <H6>{driverDetails?.firstname}</H6>
                            </FirstNameDiv>

                            <FirstNameDiv>
                                <NameLabel>Lastname</NameLabel>
                                <H6>{driverDetails?.lastname}</H6>
                            </FirstNameDiv>
                        </DriverDetails>

                        <DriverDetails>
                            <FirstNameDiv>
                                <NameLabel>Phone Number</NameLabel>
                                <H6>{driverDetails?.phone_number || '.'}</H6>
                            </FirstNameDiv>

                        </DriverDetails>

                        <DriverDetails>
                            <FirstNameDiv>
                                <NameLabel>Location</NameLabel>
                                <H6>{driverDetails?.address || '.'}</H6>
                            </FirstNameDiv>

                        </DriverDetails>
                    </AddressDiv>

                    <Div style={{ display: 'flex', flex: 1, alignItems: 'flex-end', gap: 10 }}>
                        <Id>{driverDetails?.driver_uniqid}</Id>
                        {driverDetails?.status === 'Approved' &&
                            <Link to={"/driver-profile/" + driverDetails?.id + "/" + driverDetails?.user_id}>
                                <StatusLabel >Accepted</StatusLabel>
                            </Link>
                        }
                        {driverDetails?.status === 'Rejected' &&

                            <Link to={"/driver-profile/" + driverDetails?.id + "/" + driverDetails?.user_id}>
                                <RejectedLabel >Rejected</RejectedLabel>
                            </Link>

                        }
                        {(driverDetails?.status === 'Pending' || driverDetails?.status === 'Accepted') &&


                            <Link to={"/driver-profile/" + driverDetails?.id + "/" + driverDetails?.user_id}>
                                <PendingLabel >Pending</PendingLabel>
                            </Link>

                        }

                        {
                            driverDetails?.display === '0' ? <HideLabel onClick={handleHideModal}>Un-Hide</HideLabel> :
                                <HideLabel onClick={handleHideModal}>Hide</HideLabel>
                        }

                        <TripsText>{trips} trips</TripsText >
                        <img src={ratings} width={50} />

                        <CallDiv>
                            <FaRegCalendarAlt style={{ fontSize: 18 }} />
                            <CallButton>

                                <a style={{ color: '#fff', padding: '0' }} href={`tel: ${driverDetails.phone_number}`} >
                                    <IoCallSharp />
                                </a>
                            </CallButton>

                        </CallDiv>
                    </Div>

                </DriverDiv >

            </>

        )
    }

export default Driver