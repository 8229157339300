import styled from 'styled-components'



export const INPUTDIV = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:9px;
gap:5px;
padding:7px 10px ;
justify-content:space-between;

`

export const BLACKDIV = styled.div`
display:flex;
align-items:center;
background:#000;
width:38%;
justify-content:space-around;
padding:10px 4px;
border-radius:5px;
position:relative;

`
export const Input = styled.div`
width: 50%; 
outline: none; 
border: none; 
color: #fff; 
background: transparent;
overflow:hidden;
font-size:14px;
::placeholder, ::-webkit-input-placeholder {
    color:#fff;
    font-weight:medium;
    font-size:14px;
    
}
`
 
export const VerifyButton = styled.button`
background: #FFD58C;
color: #000;
padding:5px 4px;
outline:none;
border:none;
border-radius:5px;
font-size:1rem;
font-weight:bold;
margin-left:5px;
position:relative;
display:flex;
align-items:center;
justify-content:center;
gap:5px;

`
export const DISCOUNT = styled.span`
display:flex;
align-items:center;
justify-content:center;
background: #868686;
color:#fff;
padding:5px;
border-radius:5px;
font-size:11px;
position:relative;

`


export const FLEXDIV = styled.div`
display:flex;
align-items:center;
gap:10px;
width:100%;
margin-top:50px;
position:relative;

`

export const P = styled.p`
color:#fff;
font-size:8.5px;
position:absolute;
font-weight:normal;

`

export const MARKDIV = styled.div`
display:flex;
align-items:center;
background: #FFD58C;
border-radius:5px;
padding:0.2em;
position:absolute;
right:-35px;
z-index:55;
`
export const Wrapper = styled.div`



`
export const BlackOpacity = styled.div`



`


export const Text = styled.div`
font-size:16px;
margin-top:3px;
`
export const DropDown = styled.div`
flex-direction:column;
gap:7px;
background: #000;
display:flex;
align-items:center;
justify-content:center;
padding:1px;
`

export const Div = styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
position:absolute;
right:50px;
width:150px;
z-index:1000;
top:60px;
`
export const Modal = styled.div`
background: #000;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
padding:20px;
gap:10px;
border-radius:6px;
width:80%;

`
export const Option = styled.div`
background: #fff;
display:flex;
align-items:center;
justify-content:flex-start;
padding:10px;
gap:10px;
width:100%;
color:#000;
border-radius:6px;
font-weight:600;
`
export const Label = styled.label`
background: #3573C0;
padding:5px;
border-radius:5px;
font-size:12px;

`


export const LabelTitle = styled.label`
background: #AD7108;
font-weight:bold;
font-size:12px;
margin-left:15px;
padding:5px 10px;
border-radius:5px;

margin-top:20px;`

export const SubmitDiv = styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
margin-top:30px;
`
export const SubmitButton = styled.div`
background: #AD7108;
outline:none;
padding:5px 7px;
border:none;
border-radius:8px;
`
