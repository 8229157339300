import React, { FC, useEffect, useState } from 'react'
import Customer from '../Customers/customer'
import { BackgroundBox, Button, H3, ModalBox, SearchBox, SearchBoxDiv } from './assignrequest.styles'
import { Link } from 'react-router-dom'
import { baseUrl, useAdminCustomerDataMutation, useGetProviderByIdQuery, useGetProviderByPlateNumberQuery } from '../../../services/adminApi'
import { FiSearch } from 'react-icons/fi'
import Provider from '../Providers/provider'
import axios from 'axios'
import { useAppSelector } from '../../../store'
import DriverBox from './driverBox'
import { ToastContainer, toast } from 'react-toastify'


type Props = {
    setAssignRequest: any;
    request: any;
}
const AssignRequest: FC<Props> = ({ request, setAssignRequest }) => {

    let [providerIdNumber, setProviderIdNumber] = useState(request?.providerId)

    let [vehiclePlateNumber, setVehiclePlateNumber] = useState(request?.plate_number)
    let [driverLocation, setDriverLocation] = useState(request?.pickup_location)
    let [isClicked, setIsClicked] = useState(false)
    let [selectedDriver, setSelectedDriver] = useState({})


    console.log('selectedDriver', selectedDriver)
    const user: any = useAppSelector((state: any) => state?.auth?.auth)


    // const providerRequest = useAppSelector((state: any) => state?.provider.allRequest)

    // const { data, isFetching, refetch, isSuccess } = useGetProviderByIdQuery(providerIdNumber, { refetchOnFocus: true, refetchOnMountOrArgChange: true })
    const { data, isFetching, refetch, isSuccess } = useGetProviderByPlateNumberQuery(vehiclePlateNumber, { refetchOnFocus: true, refetchOnMountOrArgChange: true })


    // useEffect(() => {
    //     if (isLoading) {
    //         // setBtnLoad(true)
    //     }
    //     if (isSuccess) {
    //         // setBtnLoad(false)
    //         setCustomerInfo(data?.data)
    //     }


    //     if (isError && 'status' in error!) {
    //         if (isError && error.status.toString() === 'FETCH_ERROR') {
    //             // notifyError('Network Connection Failed')
    //         }
    //         // setBtnLoad(false)
    //     }
    // }, [providerId])

    useEffect(() => {
        refetch()
        // setProviderNo(data?.phone_number)
    }, [data, vehiclePlateNumber])

    console.log(data)
    const handleSearchProviderByPlate = () => {
        // let formData: any = new FormData()
        // formData.append('customer_id', providerId)



        // customerData(formData)

    }
    // useEffect(() => {
    //     handleGetCustomer()
    // }, [providerId])


    // const dispatch = useAppDispatch()

    // useEffect(() => {
    //     refetchCustomer()
    //     // dispatch(setAllRequest(providerRequests?.data))
    //     // dispatch(setRequestCount(totalMessageCount))
    // }, [refetchCustomer, customerData])


    // const notifyError = (text: any) => toast.error(text, {
    //     position: "bottom-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    // })
    const handleReAssignRequest = async () => {
        let formData: any = new FormData()
        formData.append("pre_plate_number", request?.plate_number);
        formData.append("booking_id", request?.id);
        formData.append("plate_number", vehiclePlateNumber);
        // rejectRequest(formData)

        axios({
            method: 'post',
            url: `${baseUrl}reassign-request`,
            data: formData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${user?.token}`,
                "Access-Control-Allow-Origin": "*",
            }

        })
            .then(function (response) {
                //handle success
                // setReassign(!reassign)
                // const respData = response.data.data;
                // const errors = response.data.errors;
                // if (respData) {
                //     let notify = () => { toast(respData) };
                //     notify()
                //     setProviderAsssign(true)
                //     setReassignOpen(false)

                //     // window.location.reload(true)
                // }
                console.log(response?.data?.data, 'omoo') 
                if(response?.data?.data==='This Vehicle is not Approved'){

                    notifyError('This Vehicle is not Approved')
                }

                if(response?.data?.data==='This Vehicle is booked already'){
                    notifyError('This Vehicle is booked already')
                }



            })
            .catch(function (error) {
                //handle error

                // const notify = () => { toast(error.response.data.message) };
                // notify()


            });

    }
    // function handleReassignVehicle() {
    //     let formData = new FormData();
    //     formData.append("pre_plate_number", plateNumber);
    //     formData.append("booking_id", requestId);
    //     formData.append("plate_number", plateId);
    //     axios({
    //         method: 'post',
    //         url: `${BASE_URL}/v1/reassign-request`,
    //         data: formData,
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: `Bearer ${token}`,
    //             "Access-Control-Allow-Origin": "*",
    //         }

    //     })
    //         .then(function (response) {
    //             //handle success
    //             setReassign(!reassign)
    //             const respData = response.data.data;
    //             const errors = response.data.errors;
    //             if (respData) {
    //                 let notify = () => { toast(respData) };
    //                 notify()
    //                 setProviderAsssign(true)
    //                 setReassignOpen(false)

    //                 // window.location.reload(true)
    //             }


    //         })
    //         .catch(function (error) {
    //             //handle error

    //             const notify = () => { toast(error.response.data.message) };
    //             notify()


    //         });


    // }

    const notifyError = (text: any) => toast.error(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })
    const notifySuccess = (text: any) => toast.success(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })
    return (
        <>
            <ToastContainer />

            <BackgroundBox onClick={() => setAssignRequest(false)}>

                <ModalBox onClick={(e: any) => e.stopPropagation()}>
                    {request?.category === "Car Booking" &&
                        <>
                            <Provider providerDetails={data?.data} isModal={true} />

                            <SearchBoxDiv>

                                <SearchBox
                                    placeholder='New Plate Number'
                                    value={vehiclePlateNumber}
                                    onChange={(e: any) => setVehiclePlateNumber(e.target.value)}
                                />



                                <FiSearch
                                    // onClick={handleSearchProviderByPlate}
                                    style={{
                                        color: '#DA6317',
                                        fontWeight: '700',
                                        fontSize: '28px'
                                    }} />

                            </SearchBoxDiv>

                            <Button onClick={handleReAssignRequest}>
                                Assign

                            </Button>
                        </>
                    } 

                    {/* {request?.category === "Car Booking" &&
                        <>
                            {isClicked === true ? <>
                                <Provider providerDetails={data?.data} isModal={true} />

                                <SearchBoxDiv>

                                    <SearchBox
                                        placeholder='New Plate Number'
                                        value={vehiclePlateNumber}
                                        onChange={(e: any) => setVehiclePlateNumber(e.target.value)}
                                    />



                                    <FiSearch
                                        // onClick={handleSearchProviderByPlate}
                                        style={{
                                            color: '#DA6317',
                                            fontWeight: '700',
                                            fontSize: '28px'
                                        }} />

                                </SearchBoxDiv>

                                <Button onClick={handleReAssignRequest}>
                                    Assign

                                </Button>
                            </> :
                                <div style={{ width: '100%' }}>
                                    <SearchBoxDiv>

                                        <SearchBox
                                            placeholder='Driver Location'
                                            value={driverLocation}
                                            onChange={(e: any) => setDriverLocation(e.target.value)}
                                        />



                                        <FiSearch
                                            // onClick={handleSearchProviderByPlate}
                                            style={{
                                                color: '#DA6317',
                                                fontWeight: '700',
                                                fontSize: '28px'
                                            }} />

                                    </SearchBoxDiv>
                                    <DriverBox
                                        driverLocation={driverLocation}
                                        setDriverLocation={setDriverLocation}
                                        setIsClicked={setIsClicked}
                                        setSelectedDriver={setSelectedDriver}
                                    />
                                </div>}

                         
                        </>} */}
                </ModalBox>
            </BackgroundBox>

        </>
    )
}

export default AssignRequest