import React, { useEffect } from 'react'
import { FlexEndWrap } from '../../pages/user/Homepage/homepage.styles'
import { ModalOverlay, ModalBox, CancelModalBox } from '../optionModal/optionmodal.styles'
import { CancelWrap, ChoiceBox, HeaderContent, ImgWrap, ParcelOverall, ParcelsTypeContainer, SaveBtnWrap } from './itemsize.styles'
import { RadioPill } from '../timeDateModal/timedatemodal.styles'

import cancel from '../../assets/images/svg/cancel-yellow.svg'
import mediumSize from '../../assets/images/png/medium-parcel.png'
import smallSize from '../../assets/images/png/small-parcel.png'
import { SaveBtn } from '../extrasModal/extrasmodal.styles'
import { AppDispatch, useAppDispatch, useAppSelector, RootState } from '../../store'
import { setShowItemSize } from '../../slices/showItemSize'


const ItemSizeModal = ({itemModalActive, setOptionsSize, optionsSize, previousSize}:any) => {
    const dispatch: AppDispatch = useAppDispatch()
    const {showItemSize} = useAppSelector<any>((store:RootState) => store?.showItemSize)

    const itemSizeModalControl = () => {
        dispatch(setShowItemSize(false))
    }

    useEffect(() => {
        if(previousSize?.length > 0 ) {
            setOptionsSize(previousSize)
        }

    }, [previousSize])

    const itemSizeList:any = [
        {itemSize: 'Medium Parcel  Max 50kg', itemImg: mediumSize},
        {itemSize: 'Small Parcel  1-5kg ', itemImg: smallSize}
    ]
    
  return (
    <ModalOverlay activeModal={showItemSize} >
        <CancelModalBox onClick={() => itemSizeModalControl()}/>
        <div className="modal-inner-wrapper">
            <ModalBox>
                <div className="inner-modal-box">
                        <CancelWrap>
                            <div className="" onClick={() => itemSizeModalControl()}>
                                <img src={cancel} alt="" /> 
                            </div>
                        </CancelWrap>

                        <HeaderContent>
                            Select the image that best describes your item size. If your item can’t fit into a bike select medium if you have larger items click vans.
                        </HeaderContent>

                        <ParcelsTypeContainer>
                            {
                                itemSizeList?.map((item:any, id:any) => {
                                    return (
                                        <ParcelOverall onClick={() => {
                                            itemSizeModalControl()
                                            setOptionsSize(item?.itemSize)}}
                                        key={id}
                                        >
                                            <ChoiceBox>
                                                <RadioPill pillActive={item?.itemSize?.includes(optionsSize) ? 'fill' : ''} />
                                                <p>{item?.itemSize}</p>
                                            </ChoiceBox>
                                            <ImgWrap>
                                                <img src={item?.itemImg} />
                                            </ImgWrap>
                                        </ParcelOverall>
                                    )
                                })
                            }
                        </ParcelsTypeContainer>
{/* 
                        <SaveBtnWrap>
                            <SaveBtn>
                                Vans
                            </SaveBtn>
                        </SaveBtnWrap> */}
                </div>
            </ModalBox>
        </div>
    </ModalOverlay>
  )
}

export default ItemSizeModal