import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  cart: [],
};
const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state:any, {payload}) => {
      return {...state, cart: state.cart.concat(payload)}
    },
    deleteFromCart: (state, {payload}) => {
      return { cart: state.cart?.filter(({booking_id}) => booking_id !== payload) };
    },
    replaceCartItem: (state:any, {payload}) => {
      return {cart: state.cart?.map((item:any) => item?.booking_id !== payload.booking_id ? item : payload)}
    },
    clearCartItem: () => {
      return {cart: []}
    }
  }
});

const { reducer, actions } = cartSlice;
export const { addToCart, deleteFromCart, replaceCartItem, clearCartItem} = actions;
export default reducer;