import React from 'react'
import { DropdownYellowBox } from '../checkoutPayComponent/checkoutPay.styles'
import { InsuranceAccordionWrap, InsuranceBoxWrap, InsuranceRadio } from './insurancebox.styles'

import plus from '../../assets/images/svg/plus.svg'

const InsuranceChoiceBox = ({type, info, contentShow, choice, setInsuranceType, onToggle}:any) => {
  return (
    <InsuranceAccordionWrap active={contentShow}>
          <InsuranceBoxWrap>
            <div className="inner-wrapper">
              <InsuranceRadio picked={choice} onClick={setInsuranceType}/>

              <div className="insurance-type">
                {type}
              </div>

              <DropdownYellowBox onClick={onToggle}>
                <img src={plus}  />
              </DropdownYellowBox>
            </div>
        </InsuranceBoxWrap>

        <div className="accordion-content">
          <div className="accordion-subcontent">
            <p>{info}</p>
          </div>
        </div>
    </InsuranceAccordionWrap>
  )
}

export default InsuranceChoiceBox