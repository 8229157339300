import React from 'react'
import { CollectMoneyOneWrap, CustomizeLinkWrap, GreyWrap, H33, PaymentLinkHeader, SaveBeneficiaryBox, SelectTypeOverall } from './collectmoneyone.styles'
import { BoxesWrap, FlexInputWrap, GreyBox, InputBottomWrap } from '../fundWalletThree/fundwalletthree.styles'


import linkImg from '../../assets/images/png/link-img.png'
import creditCard from '../../assets/images/svg/credit-card.svg'
import person from  '../../assets/images/svg/person-icon.svg'
import arrow from '../../assets/images/svg/arrow-down-vector.svg'
import { SelectTypeWrap, AmountSectionWrap, SelectOptionBox, SelectYellowBox } from '../swapWalletComponent/swapwallet.styles'
import { SubmitBtn } from '../toggleBar/togglebar.styles'
import CurrencyInput from 'react-currency-input-field'
import { WithdrawError } from '../withdrawWalletComponent/withdrawwallet.styles'


const CollectMoneyOne = ({displaybox, handleShowComponent, onCreatePaymentLink, collectMoneyAmount, setCollectMoneyAmount, collectMoneyDescription, setCollectMoneyDescription, collectMoneyPayer, setCollectMoneyPayer, collectMoneyError }:any) => {
  return (
        <CollectMoneyOneWrap display={displaybox}>
            <PaymentLinkHeader>
                <img src={linkImg} />
                <div className="right-side">
                    <h3>Create A Payment Link</h3>
                    <p>Your payment link is an easy and secure way to receive money from anyone in different currencies  </p>
                </div>
            </PaymentLinkHeader>

            <H33>Receive from ...</H33>

            <GreyWrap>
                <FlexInputWrap>
                    <input type="text" placeholder='Who? Type Name' value={collectMoneyPayer} onChange={(e:any) => setCollectMoneyPayer(e.target.value)}/>
                    <img src={person} />
                </FlexInputWrap>

                <SelectTypeOverall>
                    <AmountSectionWrap>
                        <label>Enter Amount</label>
                        <CurrencyInput 
                            decimalsLimit={2}
                            prefix="₦"
                            placeholder='0.00'
                            value={collectMoneyAmount}
                            onValueChange={(value) => setCollectMoneyAmount(value)}
                        />
                    </AmountSectionWrap>

                    {/* <SelectOptionBox>
                        <p>Select Currency</p>
                        <SelectYellowBox>
                            <img src={arrow}  />
                        </SelectYellowBox>
                    </SelectOptionBox> */}
                </SelectTypeOverall>

                <FlexInputWrap>
                    <input type="text" placeholder='Description' value={collectMoneyDescription} onChange={(e:any)=> setCollectMoneyDescription(e.target.value)}/>
                    <img src={creditCard} />
                </FlexInputWrap>

                {/* <BoxesWrap>
                    <CustomizeLinkWrap>
                        <input type="text" placeholder='Customize Your Link' />

                        <div className="absolute-wrap">
                            www.me.roadlers/paymentlink.ca
                        </div>
                    </CustomizeLinkWrap>

                    <SaveBeneficiaryBox>
                        <p> Save Beneficiary</p>
                        <ToggleBar />
                    </SaveBeneficiaryBox>
                </BoxesWrap> */}
            </GreyWrap>

            <SubmitBtn onClick={onCreatePaymentLink}>
                <p>Create Payment Link </p>
                <WithdrawError>{collectMoneyError}</WithdrawError>
            </SubmitBtn>
        </CollectMoneyOneWrap>
  )
}

export default CollectMoneyOne