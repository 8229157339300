import styled from 'styled-components'

export const FlexDiv = styled.div`
 display:flex;
width:100%;
justify-content:space-between;
padding:.6em 1.8em;
background: #CECECE;
border-radius:8px;
margin-top:50px;
align-items:center;
`
export const FlexBetween = styled.div`
 display:flex;
width:80%;
gap:5px;
margin-top:-20px;
align-items:center;
`


export const InputFlexBetween = styled.div`
 display:flex;
width:70%;
justify-content:space-between;
margin-top:-25px;
align-items:center;
background:#3F3D3C;
border-radius:10px;
padding:0 1em;
flex:1;
`
export const Div = styled.div`
padding:0em 2em;
margin-top:50px;
`
export const H6 = styled.h6`
color:#000;
font-size:12px;
margin:0;
font-weight:bold;

`
export const H4 = styled.h4`
color:#fff;
margin:0;
font-weight:bold;

`
export const H5 = styled.h5`
color:#fff;
margin:0;
font-weight:bold;

`
export const Button = styled.button`
padding:.2em 2em;
font-size:12px;
background: ${(props:any) => props.active ? '#fff' : 'transparent' };
border:none;
font-weight:bold;
border-radius:4px;
box-shadow: ${(props:any) => props.active ? '0px 4px 8px 0px #00000014' : '0px' };
color:#000;

`


export const Buttons = styled.button`
padding:.4em 1.3em;
font-size:11px;
background: #FFFFFF;
border:none;
font-weight:bold;
border-radius:3px;
box-shadow: 0px 4px 8px 0px #00000014;
color:#000;
background: ${(props:any) => props.active ? '#ffa100' : '#fff' };

`
export const PendingButton = styled.button`
padding:.2em .7em;
font-size:10px;
background: navyblue;
border:none;
font-weight:bold;
border-radius:4px;
color:#000;

`

export const TrashButton = styled.button`
padding:.4em .7em;
font-size:10px;
background: #ffa100;
border:none;
font-weight:bold;
border-radius:3px;
color:#000;

`


// export const VehicleDiv = styled.div`
// padding:0 .5em;
// margin-top:0px;
// display:flex;
// gap:10px;

// `

export const VehicleDiv = styled.div`
padding:2px 4px;
border-radius:5px;
display:grid;
grid-template-columns:1fr 1fr;
gap:10px;

`
export const BikeDiv = styled.div`
padding:0 .3em;
margin-top:0px;
display:flex;
gap:5px;

`

export const Input = styled.input`
background:transparent;
border:none;
padding:12px 5px;
opacity:1;
outline:none;
color:#fff;
width:90%;
`







