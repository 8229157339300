import styled from 'styled-components'

export const Page = styled.div`
    position: relative;
    min-height: 60vh;
    width: 100vw;
    place-items: center;
    transition: .5s;
    background-color: #242321;
    display:flex;
    align-items:center;
    flex-direction:column;
`

export const SignupDiv = styled.div`
display:flex;
flex-direction:column;
gap:1em;
background:#F6F6F6;
border-radius:7px;
padding:3em 2em;
align-items:center;
justify-content:center;
`
export const Input = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
background:white;
width:100%;
border-radius:7px;
overflow:hidden;
box-shadow:2px 2px 5px  #ddd;
`
export const P = styled.p`
 font-size: .7rem;
 width:50%;
 text-align:left;
color:#000;
`
export const Button = styled.div`
background: #FFA100;
color: #000;
width:100%;
border-radius:30px;
padding:12px 10px;
width:55%;
outline:none;
border:none;
font-size:16px;
font-weight:700;
text-align:center;
margin-top:60px;
`