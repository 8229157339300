import React from 'react'
import { Calendar } from 'react-multi-date-picker'
import { BookingTimeOverlay } from '../bookingTimeModal/bookingtimemodal.styles'
import { CancelModalBox } from '../optionModal/optionmodal.styles'
import { TimeDateModalBox } from '../timeDateModal/timedatemodal.styles'

const BookingCalendarModal = ({showDateModal, setShowDateModal, dateArray}:any) => {
  return (
    <BookingTimeOverlay activeModal={showDateModal}>
        <CancelModalBox onClick={() => setShowDateModal(false)} />
        <div className="modal-inner-wrapper">
            <TimeDateModalBox boxDisplay={true}>
                <div className="inner-modal-box">
                    <div className="calendar-wrap">
                        <div className="subwrapper">
                            <Calendar 
                                // value={dateArray}
                                mapDays={({date}:any) => {
                                    let props:any = {}

                                    if (dateArray?.includes(date.format("MM/DD/YYYY"))) props.style = {
                                        ...props.style,
                                        backgroundColor: "#ffa100",
                                        // borderRadius: '2px',
                                        color: "#fff",
                                        width: '29px',
                                        height: '29px',
                                        fontSize: '12px',
                                    }

                                    if (!dateArray.includes(date.format("MM/DD/YYYY"))) props.disabled = true

                                    return props
                                }}
                            />
                        </div>
                    </div>
                </div>

            </TimeDateModalBox>
        </div>
    </BookingTimeOverlay>
  )
}

export default BookingCalendarModal