import React, { FC, useCallback, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { IoLocationSharp } from "react-icons/io5";
import xmark from "../../../assets/images/png/X.png";
import mark from "../../../assets/images/png/Y.png";
import { IoIosArrowDown } from "react-icons/io";

import {
  VerifyButton,
  DISCOUNT,
  INPUTDIV,
  BLACKDIV,
  FLEXDIV,
  P,
  MARKDIV,
  Wrapper,
  BlackOpacity,
  Text,
  DropDown,
  Input,
  HalfMonthInput,
  FullMonthInput,
} from "./sixthScreen.styles";
import Footer from "../../footer";
import { ModalOne } from "./Modal";
import DiscountModal from "../../discountModal";
import { useAppDispatch, useAppSelector } from "../../../store";
import { ServiceLabel } from "../fourthScreen/fourthScreen.styles";
import { DeleteModal } from "../deleteModal";
import { setServiceVisibility } from "../../../slices/provider";
import CurrencyInput from "react-currency-input-field";
import { formatCurrencyPrefix } from "../../../services/utils";
const SixthScreen: FC<{
  setVehicleDetails: any;
  vehicleDetails: any;
  setWidth: any;
  setQuestion: any;
}> = ({ vehicleDetails, setVehicleDetails, setWidth, setQuestion }) => {
  const dispatch: any = useAppDispatch();
  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );
  const vehicleInfo = useAppSelector(
    (state: any) => state?.provider?.vehicleDetails
  );
  const halfMonthVisible: boolean = useAppSelector(
    (state: any) => state?.provider?.serviceVisibility?.halfMonthVisible
  );
  const fullMonthVisible: boolean = useAppSelector(
    (state: any) => state?.provider?.serviceVisibility?.fullMonthVisible
  );

  let fullMonthPrice = vehicleDetails?.service_price?.filter(
    (price: { service_title?: string }) => {
      return price?.service_title?.includes("Full Month Service");
    }
  );

  let halfMonthPrice = vehicleDetails?.service_price?.filter(
    (price: { service_title?: string }) => {
      return price?.service_title?.includes("Half Month Service");
    }
  );

  const [isHalfMonthDiscountVisible, setIsHalfMonthDiscountVisible] =
    useState(false);
  const [isFullMonthDiscountVisible, setIsFullMonthDiscountVisible] =
    useState(false);
  const [halfMonthDiscount, setHalfMonthDiscount] = useState(
    halfMonthPrice[0]?.discount || 0
  );
  const [fullMonthDiscount, setFullMonthDiscount] = useState(
    fullMonthPrice[0]?.discount || 0
  );

  const [isVisibleModalOne, setIsVisibleModalOne] = useState(false);

  const [halfMonthServicePrice, setHalfMonthServicePrice] = useState(
    halfMonthPrice[0]?.price || ""
  );
  const [fullMonthServicePrice, setFullMonthServicePrice] = useState(
    fullMonthPrice[0]?.price || ""
  );

  const [fullMonthServiceMonths, setFullMonthServiceMonths] = useState(
    fullMonthPrice[0]?.hour || "1"
  );

  const [isFullMonthDel, setIsFullMonthDel] = useState(false);
  const [isHalfMonthDel, setIsHalfMonthDel] = useState(false);

  const BlurOptionFullmonth = () => {
    setIsFullMonthDel((prev) => !prev);
  };

  const BlurOptionHalfmonth = () => {
    setIsHalfMonthDel((prev) => !prev);
  };

  const openModal = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsVisibleModalOne((prev) => !prev);
  };

  let fullMonthArray = [
    {
      price: fullMonthServicePrice,
      hour: fullMonthServiceMonths,
      discount: 0,
      service_title: "Full Month Service",
    },
  ];

  let halfMonthArray = [
    {
      price: halfMonthServicePrice,
      hour: "",
      discount: 0,
      service_title: "Half Month Service",
    },
  ];

  const operation = (price: any, data: any, title: string) => {
    if (price === "0" || price === "") {
      return;
    } else {
      const updatedData = [
        {
          ...data[0], // Spread the properties of the existing object
          price: price, // Update the price property with the desired value
        },
      ];

      let newServicePrice = [...vehicleDetails.service_price, ...updatedData];
      const updatedServicePrice = newServicePrice?.map((service: any) => {
        if (service.service_title === title) {
          return {
            ...service,
            price: price,
          };
        }
        return service;
      });

      // this is to prevent the price deta from getting duplicated
      //@ts-ignore
      const uniqueServicePrices = updatedServicePrice.reduce(
        (uniquePrices, price) => {
          if (
            !uniquePrices.some(
              (p: any) => p.service_title === price.service_title
            )
          ) {
            uniquePrices.push(price);
          }
          return uniquePrices;
        },
        []
      );
      setVehicleDetails({
        ...vehicleDetails,
        service_price: uniqueServicePrices,
      });
    }
  };

  const handleFullmonthPrice = (event: any) => {
    setFullMonthServicePrice(event);
    operation(event, fullMonthArray, "Full Month Service");
  };

  const handleHalfmonthPrice = (event: any) => {
    setHalfMonthServicePrice(event);
    operation(event, halfMonthArray, "Half Month Service");
  };

  useEffect(() => {
    setWidth(75);
    setQuestion(6);
  }, []);

  const handleEnableHalfMonth = () => {
    dispatch(setServiceVisibility({ halfMonthVisible: true }));
  };
  const handleEnableFullMonth = () => {
    dispatch(setServiceVisibility({ fullMonthVisible: true }));
  };
  return (
    <>
      {halfMonthVisible ? (
        ""
      ) : (
        <Wrapper
          style={{
            zIndex: 6,
            width: "100%",
            position: "relative",
            height: "67px",
            marginTop: -66,
          }}
        >
          <BlackOpacity
            style={{
              padding: "10px",
              background: "#000",
              opacity: 0.8,
              position: "absolute",
              width: "90%",
              bottom: -118,
              height: "100%",
            }}
          ></BlackOpacity>
        </Wrapper>
      )}

      {isHalfMonthDel && (
        <DeleteModal
          setIsHalfMonthDel={setIsHalfMonthDel}
          vehicleDetails={vehicleDetails}
          setVehicleDetails={setVehicleDetails}
          setFullDayServiceHour={0}
          setDiscount={setHalfMonthDiscount}
          setHalfMonthServicePrice={setHalfMonthServicePrice}
          deleteFor="Half Month Service"
          isHalfMonthDel={isHalfMonthDel}
        />
      )}
      <FLEXDIV>
        <HalfMonthInput halfMonthVisible={halfMonthVisible}>
          <ServiceLabel style={{ flex: 1 }} htmlFor="">
            Half month service
          </ServiceLabel>
          <BLACKDIV>
            <P style={{ left: "0px", top: "-37px" }}>Set Price</P>

            <Input>
              <CurrencyInput
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  color: "#fff",
                }}
                decimalsLimit={2}
                prefix={formatCurrencyPrefix(currentLocation)}
                placeholder="0.00"
                value={halfMonthServicePrice}
                defaultValue={0.0}
                onValueChange={(e: any) => handleHalfmonthPrice(e)}
              />
            </Input>
            <DISCOUNT onClick={() => setIsHalfMonthDiscountVisible(true)}>
              <P style={{ left: "0px", top: "-43px" }}>Discount</P>
              {halfMonthDiscount}%
            </DISCOUNT>
          </BLACKDIV>
        </HalfMonthInput>
        <MARKDIV>
          {halfMonthVisible ? (
            <img onClick={BlurOptionHalfmonth} src={xmark} alt="" width={17} />
          ) : (
            <img
              src={mark}
              alt=""
              width={18}
              onClick={() => handleEnableHalfMonth()}
            />
          )}
        </MARKDIV>
      </FLEXDIV>
      {isHalfMonthDiscountVisible && (
        <DiscountModal
          setDiscount={setHalfMonthDiscount}
          discount={halfMonthDiscount}
          discountFor="Half Month Service"
          fullDayArray={halfMonthArray}
          vehicleDetails={vehicleDetails}
          setVehicleDetails={setVehicleDetails}
          setIsDiscountVisible={setIsHalfMonthDiscountVisible}
        />
      )}

      {fullMonthVisible ? (
        ""
      ) : (
        <Wrapper
          style={{
            width: "100%",
            position: "relative",
            height: "68px",
            zIndex: 6,
            marginTop: -66,
          }}
        >
          <BlackOpacity
            style={{
              background: "#000",
              opacity: 0.8,
              position: "absolute",
              width: "90%",
              bottom: -118,
              height: "100%",
            }}
          ></BlackOpacity>
        </Wrapper>
      )}
      {isFullMonthDel && (
        <DeleteModal
          setIsFullMonthDel={setIsFullMonthDel}
          isFullMonthDel={isFullMonthDel}
          vehicleDetails={vehicleDetails}
          setVehicleDetails={setVehicleDetails}
          setFullMonthServiceMonths={setFullMonthServiceMonths}
          setDiscount={setFullMonthDiscount}
          setFullMonthServicePrice={setFullMonthServicePrice}
          deleteFor="Full Month Service"
        />
      )}

      <FLEXDIV>
        <FullMonthInput fullMonthVisible={fullMonthVisible}>
          {/* <label
                        style={{ color: '#868686', width: '100%', display: 'flex', fontSize: '14px', fontWeight: 'normal' }}
                        htmlFor="">Full month service
                    </label> */}
          <ServiceLabel style={{ flex: 1 }} htmlFor="">
            Full month service
          </ServiceLabel>
          <VerifyButton>
            <P style={{ left: "0px", top: "-37px" }}>Months</P>

            {fullMonthServiceMonths ? (
              <Text>{fullMonthServiceMonths}</Text>
            ) : (
              <Text>0 </Text>
            )}
            <DropDown>
              <IoIosArrowDown
                style={{ color: "#FFD58C", fontSize: 15, fontWeight: 600 }}
              />
            </DropDown>
          </VerifyButton>

          {/* {isVisibleModalOne &&
                        <ModalOne
                            vehicleDetails={vehicleDetails}
                            setVehicleDetails={setVehicleDetails}
                            setFullMonthServiceMonths={setFullMonthServiceMonths}
                            fullMonthServiceMonths={fullMonthServiceMonths}
                            fullMonthArray={fullMonthArray}


                        />
                    } */}
          <BLACKDIV>
            <P style={{ left: "0px", top: "-37px" }}>Set Price</P>

            <Input>
              <CurrencyInput
                style={{
                  background: "transparent",
                  border: "none",
                  outline: "none",
                  color: "#fff",
                }}
                decimalsLimit={2}
                prefix={formatCurrencyPrefix(currentLocation)}
                placeholder="0.00"
                value={fullMonthServicePrice}
                defaultValue={0.0}
                onValueChange={(e: any) => handleFullmonthPrice(e)}
              />
            </Input>
            <DISCOUNT onClick={() => setIsFullMonthDiscountVisible(true)}>
              <P style={{ left: "0px", top: "-43px" }}>Discount</P>
              {fullMonthDiscount}%
            </DISCOUNT>
          </BLACKDIV>
        </FullMonthInput>
        <MARKDIV>
          {fullMonthVisible ? (
            <img onClick={BlurOptionFullmonth} src={xmark} alt="" width={17} />
          ) : (
            <img
              src={mark}
              alt=""
              width={18}
              onClick={() => handleEnableFullMonth()}
            />
          )}
        </MARKDIV>
      </FLEXDIV>

      {isFullMonthDiscountVisible && (
        <DiscountModal
          setDiscount={setFullMonthDiscount}
          discount={fullMonthDiscount}
          discountFor="Full Month Service"
          fullDayArray={fullMonthArray}
          vehicleDetails={vehicleDetails}
          setVehicleDetails={setVehicleDetails}
          setIsDiscountVisible={setIsFullMonthDiscountVisible}
        />
      )}
    </>
  );
};

export default SixthScreen;
