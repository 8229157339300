import React, { FC, useEffect, useState, useRef } from 'react'
// import ToggleBar from '../../../components/toggleBar'
import { IMAGE1, Button, IMAGEDIV, IMAGESDIV, DIV, H3, LoaderContainer, DivContainer, Loader, Loader2, CancelButton, ArrowDiv } from './index.styles';
import blueCar from '../../../assets/images/png/blueCar.png'
import { setRegisteredVehicle, setVehicleDetailss } from '../../../slices/provider';
import { useAppDispatch, useAppSelector } from '../../../store';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify'
import { useCarPictureUpdateMutation, useCarUpdateMutation } from '../../../services/providerApi';
import Compressor from 'compressorjs';
import { BUTTON } from '../../providerAccountReg/secondScreen/secondScreen.styles';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from 'react-icons/io';
type Props = {
  setScroll?: any;
  component?: any;
  setVehicleDetails: any;
  vehicleDetails: any;
}
const CarImageComponent: FC<Props> = ({ setScroll, setVehicleDetails, vehicleDetails, component }) => {
  const dispatch = useAppDispatch()
  const [updateVehiclePicture, { data, isLoading, isSuccess, isError, error }] = useCarPictureUpdateMutation()

  const lastVehicleDetails: any = useAppSelector((state: any) => state?.provider.vehicle)

  const vehicleInfo = useAppSelector((state: any) => state?.provider?.vehicleDetails)

  const IMG_QUALITY = 0.7

  const refDiv = React.useRef<HTMLInputElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const file2InputRef = useRef<HTMLInputElement>(null);
  const file3InputRef = useRef<HTMLInputElement>(null);
  const file4InputRef = useRef<HTMLInputElement>(null);

  const [image1Prev, setImage1Prev] = useState<any>()
  const [image2Prev, setImage2Prev] = useState<any>()
  const [image3Prev, setImage3Prev] = useState<any>()
  const [image4Prev, setImage4Prev] = useState<any>()

  // const [image1url, setImage1url] = useState()

  const [frontImage, setFrontImage] = useState(true)
  const [backImage, setBackImage] = useState(false)
  const [interiorImage, setInteriorImage] = useState(false)
  const [sideImage, setSideImage] = useState(false)



  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const file: any = event.currentTarget.files;
    if (file != null) {
      setImage1Prev(file);
      let reader = new FileReader();
      reader.onload = (e) => {
        if (e.target != null) {
          setImage1Prev(e.target.result);
        }

      };
      reader.readAsDataURL(file[0]);
    }

    new Compressor(file[0], {
      quality: IMG_QUALITY,
      success: (compressedResult: any) => {
        const formData = new FormData();
        formData.append('id', lastVehicleDetails[0]?.id);
        formData.append('file1', compressedResult);

        //@ts-ignore
        updateVehiclePicture(formData).then((response: any) => {
          if (response?.data?.message === "success") {
            notifySuccess('Front Image Saved')
          } else {
            notifyError('Something went wrong')

          }

        }).catch((err) => {
        })
      },
    });


    setFrontImage(false);
    setBackImage(true);
  };

  const onChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const file: any = event.currentTarget.files;
    if (file != null) {
      setImage2Prev(file);
      let reader = new FileReader();
      reader.onload = (e) => {
        if (e.target != null) {
          setImage2Prev(e.target.result);
        }
      };
      reader.readAsDataURL(file[0]);
    }


    new Compressor(file[0], {
      quality: IMG_QUALITY,
      success: (compressedResult: any) => {
        const formData = new FormData();
        formData.append('id', lastVehicleDetails[0]?.id);
        formData.append('file2', compressedResult);

        //@ts-ignore
        updateVehiclePicture(formData).then((response: any) => {
          if (response?.data?.message === "success") {
            notifySuccess('Back Image Saved')
          } else {
            notifyError('Something went wrong')

          }

        }).catch((err) => {
        })
      },
    });

    setBackImage(false);
    setInteriorImage(true);
  };

  const onChangess = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const file: any = event.currentTarget.files;
    if (file != null) {
      setImage3Prev(file);
      let reader = new FileReader();
      reader.onload = (e) => {
        if (e.target != null) {
          setImage3Prev(e.target.result);
        }
      };
      reader.readAsDataURL(file[0]);
    }



    new Compressor(file[0], {
      quality: IMG_QUALITY,
      success: (compressedResult: any) => {
        const formData = new FormData();
        formData.append('id', lastVehicleDetails[0]?.id);
        formData.append('file3', compressedResult);

        //@ts-ignore
        updateVehiclePicture(formData).then((response: any) => {
          if (response?.data?.message === "success") {
            notifySuccess('Interior Image Saved')
          } else {
            notifyError('Something went wrong')

          }

        }).catch((err) => {
        })
      },
    });
    setSideImage(true);
    setInteriorImage(false);
  };



  const onChangesss = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    const file: any = event.currentTarget.files;
    if (file != null) {
      setImage4Prev(file);
      let reader = new FileReader();
      reader.onload = (e) => {
        if (e.target != null) {
          setImage4Prev(e.target.result);
        }
      };
      reader.readAsDataURL(file[0]);
    }


    new Compressor(file[0], {
      quality: IMG_QUALITY,
      success: (compressedResult: any) => {
        const formData = new FormData();
        formData.append('id', lastVehicleDetails[0]?.id);
        formData.append('file5', compressedResult);

        //@ts-ignore
        updateVehiclePicture(formData).then((response: any) => {
          if (response?.data?.message === "success") {
            notifySuccess('Interior Image Saved')
            setScroll(true)
            notifySuccess('Congratulations, start uploading your vehicle Documents')
          } else {
            notifyError('Something went wrong')

          }

        }).catch((err) => {
        })
      },
    });



  };

  const notifyError = (text: any) => toast.error(text, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  })
  const notifySuccess = (text: any) => toast.success(text, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  })
  let navigate = useNavigate()

  const handleGoBack = () => {
    navigate("/individual-reg")
  };
  return (
    <>
      <ToastContainer />

      <ArrowDiv>
          <IoIosArrowBack onClick={()=>handleGoBack()}
            style={{fontSize:30, padding: '0px', marginLeft: '-10px' }} />
        <H3 style={{margin:0, paddingTop:0}}>Earn With Roadlers </H3>

      </ArrowDiv>

      <LoaderContainer>
        <DivContainer>
          <Loader />
          <Loader />
        </DivContainer>
        <DivContainer>
          <Loader />
          <Loader2 />
        </DivContainer>
        <DivContainer>
          <Loader2 />
          <Loader2 />
        </DivContainer>
      </LoaderContainer>
      <DIV>
        <IMAGEDIV style={{ background: `linear-gradient(0deg, rgba(12, 12, 12, 0.9), rgba(40, 37, 31, 0.9)), url(${blueCar})`, backgroundPosition: 'center', backgroundSize: 'cover' }}>

          {/* respond to click on all images */}
          {
            frontImage ?
              <img style={{ objectFit: 'cover' }} width='100%' height='100%' src={image1Prev} />
              : (
                backImage ? <img style={{ objectFit: 'cover' }} width='100%' height='100%' src={image2Prev} />
                  :
                  (
                    interiorImage ? <img style={{ objectFit: 'cover' }} width='100%' height='100%' src={image3Prev} />
                      : <img style={{ objectFit: 'cover' }} width='100%' height='100%' src={image4Prev} />
                  )
              )


          }


          {frontImage &&
            <>
              {image1Prev ?

                <CancelButton onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  event.preventDefault();
                  if (fileInputRef.current != null) {
                    fileInputRef.current.click()
                  }
                }}>
                  X
                </CancelButton>
                :

                <>
                  {lastVehicleDetails?.length > 0 ?
                    <Button onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      event.preventDefault();

                      if (fileInputRef.current != null) {
                        fileInputRef.current.click()
                      }

                    }}>
                      Upload Front Image
                    </Button> :

                    <Button style={{ background: 'darkgray', color: '#000' }} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      event.preventDefault();
                      notifyError('Image Upload Locked, to unlock, upload your vehicle details')
                    }}>
                      Upload Not allowed
                    </Button>
                  }
                </>
              }
            </>
          }


          {backImage &&
            <>
              {image2Prev ?
                <CancelButton onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  event.preventDefault();
                  if (file2InputRef.current != null) {
                    file2InputRef.current.click()
                  }
                }}>
                  X
                </CancelButton>
                :

                <>
                  {lastVehicleDetails?.length > 0 ?
                    <Button onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      event.preventDefault();

                      if (file2InputRef.current != null) {
                        file2InputRef.current.click()
                      }

                    }}>
                      Upload Back Image
                    </Button> :

                    <Button style={{ background: 'darkgray', color: '#000' }} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      event.preventDefault();
                      notifyError('Image Upload Locked, to unlock, upload your vehicle details')
                    }}>
                      Upload Not allowed
                    </Button>
                  }
                </>
              }
            </>
          }
          {interiorImage &&
            <>

              {image3Prev ?
                <CancelButton
                  onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    event.preventDefault();
                    if (file3InputRef.current != null) {
                      file3InputRef.current.click()
                    }
                  }}>
                  X
                </CancelButton>

                :
                <>
                  {lastVehicleDetails?.length > 0 ?
                    <Button onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      event.preventDefault();

                      if (file3InputRef.current != null) {
                        file3InputRef.current.click()
                      }

                    }}>
                      Upload Interior Image
                    </Button> :

                    <Button style={{ background: 'darkgray', color: '#000' }} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      event.preventDefault();
                      notifyError('Image Upload Locked, to unlock, upload your vehicle details')
                    }}>
                      Upload Not allowed
                    </Button>
                  }
                </>
              }
            </>
          }
          {sideImage &&
            <>
              {image4Prev ?

                <CancelButton onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                  event.preventDefault();
                  if (file4InputRef.current != null) {
                    file4InputRef.current.click()
                  }
                }}>
                  X
                </CancelButton> :
                <>
                  {lastVehicleDetails?.length > 0 ?
                    <Button onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      event.preventDefault();

                      if (file4InputRef.current != null) {
                        file4InputRef.current.click()
                      }

                    }}>
                      Upload Side Image
                    </Button> :

                    <Button style={{ background: 'darkgray', color: '#000' }} onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                      event.preventDefault();
                      notifyError('Image Upload Locked, to unlock, upload your vehicle details')
                    }}>
                      Upload Not allowed
                    </Button>
                  }
                </>
              }
            </>
          }
        </IMAGEDIV>
        <IMAGESDIV>
          <IMAGE1
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

              setInteriorImage(false)
              setFrontImage(false)
              setBackImage(false)
              setSideImage(true)
              // setImage4Prev(image4Prev)
            }}
            style={{ background: `linear-gradient(0deg, rgba(12, 12, 12, 0.7), rgba(40, 37, 31, 0.7)), url(${blueCar})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
          >
            <img style={{ objectFit: 'cover' }} width='100%' height='100%' src={image4Prev} />

          </IMAGE1>
          <IMAGE1
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              setInteriorImage(true)
              setFrontImage(false)
              setBackImage(false)
              setSideImage(false)
            }}
            style={{ background: `linear-gradient(0deg, rgba(12, 12, 12, 0.7), rgba(40, 37, 31, 0.7)), url(${blueCar})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
          >
            <img style={{ objectFit: 'cover' }} width='100%' height='100%' src={image3Prev} />

          </IMAGE1>
          <IMAGE1
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              setBackImage(true)
              setFrontImage(false)
              setInteriorImage(false)
              setSideImage(false)
            }}
            style={{ background: `linear-gradient(0deg, rgba(12, 12, 12, 0.7), rgba(40, 37, 31, 0.7)), url(${blueCar})`, backgroundPosition: 'center', backgroundSize: 'cover' }}
          >
            <img style={{ objectFit: 'cover' }} width='100%' height='100%' src={image2Prev} />

          </IMAGE1>
          <IMAGE1
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

              setFrontImage(true)
              setBackImage(false)
              setInteriorImage(false)
              setSideImage(false)

            }}
            style={{ background: `linear-gradient(0deg, rgba(12, 12, 12, 0.7), rgba(40, 37, 31, 0.7)), url(${blueCar})`, backgroundPosition: 'center', backgroundSize: 'cover' }}

          >
            <img style={{ objectFit: 'cover' }} width='100%' height='100%' src={image1Prev} />
          </IMAGE1>
        </IMAGESDIV>
      </DIV>

      {
        console.log('lastVehicleDetails', lastVehicleDetails?.length > 0)
      }
      {lastVehicleDetails?.length > 0 ?

        <div>
          <input type="file" name="file" style={{ display: 'none' }} ref={fileInputRef} accept="image/*" onChange={onChange} />

          <input type="file" name="file2" style={{ display: 'none' }} ref={file2InputRef} accept="image/*" onChange={onChanges} />

          <input type="file" name="file3" style={{ display: 'none' }} ref={file3InputRef} accept="image/*" onChange={onChangess} />

          <input type="file" name="file5" style={{ display: 'none' }} ref={file4InputRef} accept="image/*" onChange={onChangesss} />
        </div> :
        <div >

        </div>}


    </>
  )
}
export default CarImageComponent