import React, { useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { SearchInput, SearchOverall } from '../../pages/user/CarBooking/CarBooking.styles'
import { SearchBtn } from '../../pages/user/Homepage/homepage.styles'

const SearchComponent = () => {
    const navigate = useNavigate() 
    const [location, setLocation] = useState('')
    const [vehicleType, setVehicleType] = useState('')
    const params = {vehicleLocation:`${location}`, typeVehicle: `${vehicleType}`}

    const goToSearch = () => {
        if (location?.length !== 0 && vehicleType?.length !==0) {
          navigate({
            pathname: '/search-page',
            search: `?${createSearchParams(params).toString()}`
          })
        }
    }

  return (
    <>
        <SearchOverall>
          <SearchInput flex='0.6' placeholder='Search by car, model, company....' value={vehicleType} onChange={(e:any) => setVehicleType(e.target.value)}/>
          <SearchInput flex='0.3' placeholder='Pickup Location' value={location} onChange={(e:any) => setLocation(e.target.value)}/>
          <SearchBtn bg='#ffa100' onClick={goToSearch}>
            Search
          </SearchBtn>
        </SearchOverall>
    </>
  )
}

export default SearchComponent