import styled from 'styled-components'



export const Input = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:9px;
overflow:hidden;
margin-top:5px;
justify-content:space-around;
padding:12px 12px;

`
export const Modal = styled.div`
background: #000;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
padding:20px;
gap:10px;
border-radius:6px;
width:60%;

`

