import React, { FC, useState } from 'react'
import {
  VerifyButton, DISCOUNT, INPUTDIV, BLACKDIV, FLEXDIV,
  P, Input, MARKDIV, Wrapper, BlackOpacity, Text, DropDown, Label, LabelTitle, SubmitButton, SubmitDiv
} from '../regularRates/regularRates.styles';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { ModalContainer } from '../discountModal/index.styles';
import CurrencyInput from 'react-currency-input-field';
import { formatCurrencyPrefix } from '../../../services/utils';
import { useAppSelector } from '../../../store';



type Props = {
  setVehicleInfo: any
  setIsNewLongTerm: any;
  vehicleInfo: any;
  setShowSaveButton: any;
}


const LongTermPopUp: FC<Props> = ({ setShowSaveButton, vehicleInfo, setIsNewLongTerm, setVehicleInfo }) => {

  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );
  const [fullMonthPrice, setFullMonthPrice] = useState<any>()

  const handleFullMonthPrice = (event: any) => {
    setFullMonthPrice(event)

  };



  const FullMonthDone = () => {
    setIsNewLongTerm(false)

    const existingEntry = vehicleInfo.service_price.find((data: any) => data?.service_title === "Full Month Service");

    if (existingEntry) {
      const prevId = existingEntry.id;
      const newService = {
        user_id: vehicleInfo?.user_id,
        vehicle_id: vehicleInfo?.id,
        service_title: "Full Month Service",
        hour: 2,
        price: Number(fullMonthPrice),
        bike_id: null,
        discount: 0,
        location: null,
        months: null,

      }
      setVehicleInfo({
        ...vehicleInfo,
        service_price: [...vehicleInfo.service_price, newService]
      });
      setShowSaveButton(true)
    }



  }
  return (
    <>
      <ModalContainer onClick={() => setIsNewLongTerm(false)}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
          <FLEXDIV onClick={(e: any) => e.stopPropagation()}>
            <INPUTDIV>
              <label
                style={{ color: '#868686', display: 'flex', width: '55%', fontSize: '14px', fontWeight: 'normal' }}
                htmlFor="">Full Month Service
              </label>

              <BLACKDIV style={{ width: '45%', padding: '0px 10px', justifyContent: 'space-between' }}>
                <P style={{ left: '0px', top: '-37px' }}>Set Price</P>
             


                <Input>
                  <CurrencyInput
                    style={{ padding:13, background: 'transparent', border: 'none', outline: 'none', color: '#fff' }}
                    decimalsLimit={2}
                    prefix={formatCurrencyPrefix(currentLocation)}
                    placeholder='0.00'
                    value={fullMonthPrice}
                    onValueChange={(e: any) => handleFullMonthPrice(e)}
                  />
                </Input>

              </BLACKDIV>
            </INPUTDIV>

          </FLEXDIV>

          <SubmitDiv>
            <SubmitButton onClick={FullMonthDone}>Add New Price</SubmitButton>
          </SubmitDiv>
        </div>

      </ModalContainer>

    </>

  )
}

export default LongTermPopUp