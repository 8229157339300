import React from 'react'
// import ToggleBar from '../../../components/toggleBar'

import {
    StatsBox, P, IconDiv, H3, Button, Div, StatsDiv, ButtonGroup, Stat
} from './stats.styles';
import Duotone from '../../../assets/images/png/Duotone.png'

import { BsCalendar3, BsFillFolderFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
const StatsFour = ({ driverCount }: any) => {
    let totalCount;
    if (driverCount !== undefined) {
        //@ts-ignore
        totalCount = Object.keys(driverCount).reduce((sum, key) => {
            // Check if the key is not "countPinned" before adding to the sum
            if ((key !== "countBlocked") && (key !== "countPinned")) {
                return sum + driverCount[key];
            }
            return sum;
        }, 0);
    }


    
    return (
        <>
            <StatsDiv>
                <StatsBox>
                <Link to='/list-drivers' style={{ textDecoration: 'none' }}>

                    <P>Total Driver uploaded</P>
                    <IconDiv >
                        <BsCalendar3 style={{ fontSize: '11px' }} />
                    </IconDiv>

                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', gap: '10px' }}>
                        <img src={Duotone} alt="" width="35px" />

                        <H3>{totalCount}</H3>
                    </div>
                    </Link>

                </StatsBox>
                <Div>

                    <ButtonGroup>
                        <Button style={{ background: '#CF0303', }}>
                            UnScreened
                        </Button>
                        <Stat>{driverCount?.countPending || 0}</Stat>

                    </ButtonGroup>
                    <ButtonGroup>
                        <Button style={{ background: '#07B418' }}>
                            Accepted
                        </Button>
                        <Stat>{driverCount?.countApproved || 0}</Stat>

                    </ButtonGroup>
                    <ButtonGroup>
                        <Button style={{ background: '#CF0303', }}>
                            Rejected
                        </Button>
                        <Stat>{driverCount?.countRejected || 0}</Stat>

                    </ButtonGroup>



                </Div>
            </StatsDiv>
        </>
    )
}

export default StatsFour