import React, { FC, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { BsEnvelopeFill } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import Envelop from "../../../assets/images/png/envelop.png";

import {
  DIV,
  INPUTS,
  P,
  FLEXDIV,
  FLEXDIV2,
  SELECTDIV,
  Input,
  ICONDIV,
  Text,
  DropDown,
  Modal,
  Div,
  Option,
  Radio,
  Button,
} from "./index.styles";
import { useNextofKinMutation } from "../../../services/providerApi";
import { useAppDispatch, useAppSelector } from "../../../store";
import { useNavigate } from "react-router-dom";
import { setNextOfKin } from "../../../slices/provider";
import Header from "../../../components/providerHeader";
import {
  DivContainer,
  H3,
  Loader,
  Loader2,
  LoaderContainer,
  Page,
} from "../ProfileSetup/profileSetup.styles";
import { FiChevronLeft } from "react-icons/fi";
import { PagePadding } from "../../admin/Dashboard/dashboard.styles";

const NextOfKin: FC = () => {
  const [btnLoad, setBtnLoad] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();
  const nextOfKinStore = useAppSelector(
    (state: any) => state?.provider?.nextOfKin
  );

  // [0]?.next_of_kin
  const [relationship, setRelationship] = useState<string>(
    nextOfKinStore?.relationship
  );
  const [firstname, setFirstname] = useState<String>(nextOfKinStore?.firstname);
  const [lastname, setLastname] = useState<String>(nextOfKinStore?.lastname);
  const [phone, setPhone] = useState<String>(nextOfKinStore?.phone_number);
  const [email, setEmail] = useState<String>(nextOfKinStore?.email);

  const handleRelationship = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setRelationship((event.target as HTMLTextAreaElement).value);
    dispatch(
      setNextOfKin({
        relationship: (event.target as HTMLTextAreaElement).value,
      })
    );
  };
  const handleFirstName = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setFirstname((event.target as HTMLTextAreaElement).value);
    dispatch(
      setNextOfKin({ firstname: (event.target as HTMLTextAreaElement).value })
    );
  };
  const handleLastName = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setLastname((event.target as HTMLTextAreaElement).value);
    dispatch(
      setNextOfKin({ lastname: (event.target as HTMLTextAreaElement).value })
    );
  };
  const handlePhone = (e: any) => {
    setPhone(e);
    dispatch(setNextOfKin({ phone_number: e }));
  };
  const handleEmail = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setEmail((event.target as HTMLTextAreaElement).value);
    dispatch(
      setNextOfKin({ email: (event.target as HTMLTextAreaElement).value })
    );
  };

  const [nextofKin, { data, isLoading, isSuccess, isError, error }] =
    useNextofKinMutation();

  useEffect(() => {
    if (isLoading) {
      setBtnLoad(true);
    }
    if (isSuccess) {
      setBtnLoad(false);
      if (currentLocation === "NG") {
        navigate("/insurance-setup");
      } else {
        navigate("/provider-dashboard");
      }
    }

    if (isError && "status" in error!) {
      if (isError && error.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
      }
      setBtnLoad(false);
    }
  }, [isLoading, isSuccess, isError, data]);

  const user = useAppSelector((state: any) => state?.auth?.auth);

  const handleUpdateNextofKin = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    let formData: any = new FormData();
    formData.append("user_id", user.id);
    formData.append("relationship", relationship);
    formData.append("firstname", firstname);
    formData.append("lastname", lastname);
    formData.append("email", email);
    formData.append("phone_number", phone);

    nextofKin(formData);
  };

  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );

  const alertError = () => {
    notifyError("Please Fill all Important Fields");
  };
  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  return (
    <>
      <ToastContainer />

      <Header />
      <Page>
        <PagePadding className="padding">
          <div
            style={{
              display: "flex",
              gap: 10,
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 10,
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <FiChevronLeft
                onClick={() => {
                  navigate("/profile-setup");
                }}
                style={{ marginLeft: "-7px", color: "#fff", fontSize: 30 }}
              />
              <H3>Finish Your Profile</H3>
            </div>
          </div>

          {/* <P>Next of Kin data page</P> :
                        <P style={{ fontSize: '15px' }}>You are almost done</P>
                 */}
          <LoaderContainer>
            <DivContainer>
              <Loader />
              <Loader />
            </DivContainer>
            <DivContainer>
              <Loader />
              <Loader />
            </DivContainer>
            <DivContainer>
              <Loader />
              <Loader2 />
            </DivContainer>
          </LoaderContainer>
          <DIV>
            <DIV>
              <P>Add next of kin *</P>
              <INPUTS
                type="text"
                value={relationship}
                onChange={handleRelationship}
                style={{
                  width: "100%",
                  padding: "20px",
                  border: "none",
                  outline: "none",
                }}
                placeholder="Relationship"
              />
            </DIV>

            <FLEXDIV style={{ marginTop: "10px" }}>
              <INPUTS
                type="text"
                value={firstname}
                onChange={handleFirstName}
                style={{ padding: "20px", border: "none", outline: "none" }}
                placeholder="Firstname"
              />
              <INPUTS
                type="text"
                value={lastname}
                onChange={handleLastName}
                style={{ padding: "20px", border: "none", outline: "none" }}
                placeholder="Lastname"
              />
            </FLEXDIV>

            <DIV>
              <div className="phone-select">
                <Input className="phone">
                  <PhoneInput
                    defaultCountry={currentLocation}
                    defaultValue={phone}
                    //@ts-ignore
                    value={phone}
                    onChange={handlePhone}
                    international
                    withCountryCallingCode
                  />
                </Input>
              </div>
            </DIV>

            <FLEXDIV2>
              <ICONDIV>
                <BsEnvelopeFill />
              </ICONDIV>
              <INPUTS
                type="email"
                value={email}
                onChange={handleEmail}
                style={{
                  width: "78%",
                  padding: "20px 0px",
                  border: "none",
                  outline: "none",
                }}
                placeholder="Johndoe@gmail.com"
              />
            </FLEXDIV2>
          </DIV>

          {relationship?.length === undefined ||
          firstname?.length === undefined ||
          lastname?.length === undefined ||
          phone?.length === undefined ||
          email?.length === undefined ? (
            <DIV style={{ justifyContent: "center", flexDirection: "row" }}>
              <Button
                onClick={() => alertError()}
                style={{
                  background: "#000",
                  color: "gray",
                  width: "50%",
                  borderRadius: 30,
                  padding: "18px 8px",
                }}
              >
                Finish it
              </Button>
            </DIV>
          ) : (
            <DIV style={{ justifyContent: "center", flexDirection: "row" }}>
              <Button
                style={{ width: "50%", borderRadius: 30, padding: "18px 8px" }}
                onClick={handleUpdateNextofKin}
              >
                Finish it
              </Button>
            </DIV>
          )}
        </PagePadding>
      </Page>
    </>
  );
};

export default NextOfKin;
