import styled from 'styled-components'

export const FundWalletBankWrap = styled.div `
    display: ${(props:any) => props.display};
    position: relative;
`

export const BankInfoWrap = styled.div `
    margin: 40px 0;
`

export const H23 = styled.h3 `
    font-size: 17px;
    font-weight: 700;
    color: #868686;
    margin: 10px;
    margin-bottom: 4px;

    @media only screen and (max-width: 454px) {
        font-size:14px;
    }
`

export const GreyBoxTextWrap = styled.div `
    border-radius: 9px;
    background-color: #F6F6F6;
    padding: 15px 30px;

    & h3 {
        color: #000;
        font-size: 14px;
        font-weight: 700;
    }

    & p {
        color: #868686;
        font-size: 14px;
    }

    @media only screen and (max-width: 454px) {
        p {
            font-size: 10px;
        }

        h3, p {
            font-size: 12px;
        }

    }
`

export const CopiedTxt = styled.div `
    color: #07B418;
    font-size: 11px;
    margin-bottom: 6px;
    text-align: right;
`