import styled from 'styled-components'
export const RatingsBox = styled.div`
display:flex;
flex-direction:column;
align-items:start;
justify-content:flex-start;
width:49%;
gap:10px;
border-radius:10px;
position:relative;
background:#000;
padding:.7em .8em;
margin-bottom:7px;

`
export const P = styled.p`
color: #ffa100;
font-size:14px;
font-weight:700;
margin:0;

`

export const H3 = styled.h3`
font-weight:700;
color: #1D1C1C;
margin:0;
`
export const H6 = styled.h6`
color: #FFF;
margin:0;
`
