import React from 'react'
import { TransferHeaderWrap } from '../transferOne/transferone.styles'
import { AmountSectionWrap, SelectOptionBox, SelectTypeWrap, SelectYellowBox, SwapContentWrap, SwapIconWrap, SwapWalletWrap } from './swapwallet.styles'

import arrow from '../../assets/images/svg/arrow-down-vector.svg'
import swap from '../../assets/images/svg/swap-icon-dark.svg'
import { SubmitBtn } from '../toggleBar/togglebar.styles'

const SwapWalletComponent = ({displaybox, handleShowComponent}:any) => {
  return (
    <SwapWalletWrap display={displaybox}>
        <TransferHeaderWrap>
            <h3>I would like to swap....</h3>
        </TransferHeaderWrap>

        <SwapContentWrap>
            <SelectTypeWrap>
                <AmountSectionWrap>
                    <label>Enter Amount</label>
                    <input type="text" placeholder='0.00' />
                </AmountSectionWrap>

                <SelectOptionBox>
                    <p>Select Wallet</p>
                    <SelectYellowBox>
                        <img src={arrow}  />
                    </SelectYellowBox>
                </SelectOptionBox>
            </SelectTypeWrap>

            <SwapIconWrap>
                <img src={swap} />
            </SwapIconWrap>

            <SelectTypeWrap>
                <AmountSectionWrap>
                    <input type="text" placeholder='0.00' />
                </AmountSectionWrap>

                <SelectOptionBox>
                    <p>Select Currency</p>
                    <SelectYellowBox>
                        <img src={arrow}  />
                    </SelectYellowBox>
                </SelectOptionBox>

                <div className="absolute-txt">
                    This transaction fee is 0.05%
                </div>
            </SelectTypeWrap>
        </SwapContentWrap>

        <SubmitBtn onClick={() => handleShowComponent('otp')}>
            <p>Swap</p>
        </SubmitBtn>

    </SwapWalletWrap>

  )
}

export default SwapWalletComponent