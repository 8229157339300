import styled from 'styled-components'

export const Div = styled.div`
padding:0em .5em;
margin-top:20px;
`
export const TransferDiv = styled.div`
background: #fff;
padding:.5em 1em;
display:flex;
align-items:center;
gap:20px;
border-radius:7px;
`
export const TransferDetails = styled.div`
width:60%;
display:flex;
flex-direction:column;
gap:8px;
`
export const TransferAmount = styled.div`
width:40%;

`


export const H6 = styled.h6`
color:#000;
font-size:14px;
margin:0;
font-weight:bold;

`
export const H5 = styled.h5`
color: #128E40;


`
export const Button = styled.button`
padding:.4em 1.5em;
font-size:14px;
background: #FFFFFF;
border:none;
font-weight:bold;
border-radius:8px;
box-shadow: 0px 4px 8px 0px #00000014;
color:#000;
 &:focus{
    color:red;
 }
`
export const Span = styled.div`
display:flex;
align-items:center;
gap:15px;
`
export const Label = styled.label`
color: #868686;
font-size:13px;

font-weight:700px;
`