import styled from 'styled-components'

export const SuccessWalletWrap = styled.div `
    display: ${(props:any) => props.display};
    margin: 40px 0;
`

export const BigBox = styled.div `
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border-radius: 10px;
    background-color: #09051C;
    margin-bottom: 15px;
    gap: 15px;
`

export const TextBox = styled.div `
    & h2 {
        font-weight: 700;
        color: #fff;
        font-size: 23px;
        margin: 0;
        margin-top: 10px;
    }

    & p {
        font-size: 13px;
        color: #fff;
        margin: 0;
    }

    @media only screen and (max-width: 454px)  {
        h2 {
            font-size: 16px;
        }

        p {
            font-size: 10px;
        }
    } 
`
 export const SuccessImg = styled.img `
    width: 90px;

    @media only screen and (max-width: 454px)  {
        width: 45px;
    }
 `

 export const ExtrasActionWrap = styled.div `
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    padding: 20px;
    border-radius: 9px;
    background-color: ${(props: any) => props.bg};

    & p {
        color: #09051C;
        margin: 0;
        font-weight: 700;
    }

    & img {
        width: 20px;
    }

    @media only screen and (max-width: 454px)  {
        padding: 15px 20px;

        p {
            font-size: 10px;
        }

        img {
            width: 13px;
        }
    }
 `