// import { lightTheme } from './../../../components/application/Theme';
import styled from 'styled-components'

export const Page = styled.div `

`

export const Container = styled.section `
    padding: 2rem;
    padding: 60px 50px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media only screen and (max-width: 400px) {
        padding: 30px;
    }

    @media only screen and (min-width: 767px) {
        flex-direction: row;
        align-items: center;
    }

    @media only screen and (min-width: 1200px) {
        padding: 60px 150px 30px;
    }
`

export const SecondBoxContainer = styled(Container) `
    gap: 0px;
    @media only screen and (min-width: 767px) {
        flex-direction: row;
        justify-content: center;
        column-gap: 150px;
        align-items: center;
    }
`

export const H11 = styled.h1 `
    font-size: 35px;
    font-weight: 800;
    margin-bottom: 0;
    margin-top: 10px;

    @media only screen and (max-width: 484px) {
        font-size: 28px;
    }

    @media only screen and (max-width: 400px) {
        font-size: 25px;
    }

    @media only screen and (min-width: 1200px) {
        font-size: 45px;
    }
`

export const H12 = styled(H11) `
    background: ${(props: any) => props.theme.h12color};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

export const H13 = styled(H11) `
    color: ${(props: any) => props.theme.h13color};
    background: ${(props: any) => props.theme.h13color};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    

`

export const H13DIV = styled.div `
    & h1 {
        color: ${(props: any) => props.theme.h13color};
        background: ${(props: any) => props.theme.h13color};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 35px !important;

        @media only screen and (max-width: 484px) {
            font-size: 28px !important;
        }

        @media only screen and (max-width: 400px) {
            font-size: 25px !important;
        }   

        @media only screen and (min-width: 1200px) {
            font-size: 45px !important;
        }
    }

`

export const ContentLeft = styled.div `
    flex: .5;
`

export const ContentRight = styled(ContentLeft) `
    margin-top: 20px;
`
export const BannerWrap = styled.div `
    background-color: ${(props:any) => props.theme.body};
`

export const Banner = styled.img `
    width: 100%;
    height: 100%;
    object-fit: contain;
`
export const ContentBottom = styled(ContentLeft) `
    padding-top: 30px;
`

export const BookNow = styled.button `
    outline: none;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    background-image: linear-gradient(#FFBB46, #583904);
    color: #fff;
    font-weight: 800;
    font-size: 16px;
    cursor: pointer;
    margin: 0;

    @media only screen and (max-width: 454px) {
        font-size: 13px;
        padding: 6px 14px;
    }
`

export const Icons = styled.img `
    width: 40px;

    &.gift-icon {
        width: 35px;
        margin-bottom: 7px;
    }

    &.header-icon {
        width: 35px;
    }
`

export const H1 = styled.h1 `
    font-size: 3rem;
`

export const P = styled.p `
    font-size: 1.4rem;
`

export const PS = styled(P) `
    font-size: 12px;
    margin: 0;
    font-weight: 600;
    text-align: center;
    position: absolute;
    bottom: -18px;
    z-index: 60;
    color: ${(props: any) => props.theme.text};
`

export const PL = styled.p `
    font-size: 18px;
`

export const AboutBox = styled.div `
    width: 270px;
    min-height: 150px;
    border-radius: 16px;
    position: relative;
    padding: 20px;
    background-color: ${(props: any) => props.color};

    @media only screen and (max-width: 484px) {
        width: 240px;
    }
`

export const FirstAboutBox = styled(AboutBox) `
    background-color: #000;

    .about-content {
        color: #fff;
    }

    .about-header {
        color: #FFD58C;
    }

    @media only screen and (min-width: 500px) {
        width: 370px;
    }

    @media only screen and (min-width: 767px) {
        width: 270px;
    }
`

export const FlexStartWrap = styled.div `
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;

    &.center-aligned {
        justify-content: center;
    }
`

export const FlexEndWrap = styled(FlexStartWrap)`
    justify-content: flex-end;
    margin-top: 60px;

    &.center-aligned {
        justify-content: center;
    }

    @media only screen and (max-width: 484px)  {
        &.explain-box {
            margin-top: 30px;
        }

    }
`

export const BoxH1 = styled.h2 `
    font-size: 20px;
    font-weight: 800;
    color: #000;

    @media only screen and (max-width: 484px) {
        font-size: 18px;

        &.left-margin {
            margin-left: 30px !important;
        }
    }
`

export const BoxP = styled.p `
    font-size: 16px;
    font-weight: 400;
    color: #1D1C1C;

    @media only screen and (max-width: 454px) {
        font-size: 14px;
        margin-right: 30px !important;

        &.left-margin {
            margin-right: 0px !important;
            margin-left: 30px !important;
        }
    }
`

export const VehicleImgRight = styled.img `
    position: absolute;
    right: -40px;
    top: 50%;
    transform: translateY(-50%);
    width: 100px;

    @media only screen and (max-width: 454px) {
        width: 80px;
    }

    &.delivery-image {
        width: 80px;
        right: -10px;
    }
`

export const DriverImgLeft = styled(VehicleImgRight) `
    left: -40px;
`

export const CenterWrapper = styled.div `
    display: flex;
    justify-content: center;
    margin: 50px 0;
    padding: 0 50px;

    @media only screen and (min-width: 767px) {
        justify-content: center;
    }

    & a {
        text-decoration: none;
        width: 85%;
        cursor: pointer;

        @media only screen and (min-width: 500px) {
            width: 350px;
        }
    }
`

export const DeliveryBox = styled.div `
    background-color: #000;
    color: #fff;
    font-size: 16px;
    font-weight: 800;
    padding: 18px 25px;
    border-radius: 8px;
    position: relative;
    width: 100%;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    & p {
        font-size: 17px;
        margin: 0;
    }

    @media only screen and (min-width: 500px) {
        width: 350px;

        p {
            text-align: center;
        }
    }
`

export const SectionFilter = styled.section `
    padding: 30px;
    /* border: solid 1px red; */

    &>div h2 {
        font-size: 27px;

        font-weight: 800;
        text-align: center;

        @media only screen and (max-width: 454px) {
            font-size: 24px;
        }
    }
`

export const PillWrapper = styled.div `
    display: flex;
    flex-wrap: wrap;
    justify-content: ${(props:any) => props.justify};
    gap: 10px;
    margin-bottom: 30px;
    padding: ${(props:any) => props.padding};

    & a {
        text-decoration: none;
        color: unset;
    }

    @media only screen and (max-width: 484px) {
        width: 484px;
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow-x: scroll;
        padding: 0px 10px;
        width: 100%;
    }
`

export const PillMiniWrapper = styled.div `
    display: flex;
    flex-wrap: wrap;
    justify-content: ${(props:any) => props.justify};
    gap: 10px;
    margin-bottom: 30px;
    padding: ${(props:any) => props.padding};

    & a {
        text-decoration: none;
        color: unset;
        cursor: pointer;
    }
`

export const SearchFilterBox = styled.div `
    display: flex;
    flex-wrap: wrap;
    justify-content: ${(props:any) => props.justify};
    gap: 10px;
    margin-bottom: 30px;
    padding: ${(props:any) => props.padding};
`

export const FilterBoxWrap = styled.div `
    padding: 20px;
    padding-left: 30px;
    padding-top: 0;
    justify-content: center;
    display: flex;
`

export const FilterPill = styled.div `
    border: solid 1px ${(props:any) => props.active === true ? '#FFA100' : (props: any) => props.theme.borderColor};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    background-color: ${(props: any) => props.active === true ? '#FFA100' : 'transparent'};
    color: ${(props: any) => props.active === true ? '#000' : `${(props: any) => props.theme.borderColor}`};
    font-weight: 600;
    font-size: 13px;
    cursor: pointer;

    & button {
        background-color: transparent;
        border: none;
        outline: none;
        color: #fff;
        font-size: 11px;
    }

    @media only screen and (max-width: 484px) {
        padding: 6px 12px;
        font-size: 11px; 
    }
`

export const SearchWrap = styled.div `
    display: flex;
    align-items: center;
    background-color: ${(props: any) => props.theme.inputBg};
    border-radius: 8px;
    flex: 0.9;
    /* justify-content: space-between; */

    & .input-search-box {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        background-color: #FFC561;
        font-size: 14px;
        font-weight: 800;
        color: #000;
        height: 100%;
        padding: 0px 15px;
        flex: 0.2;
        cursor: pointer;

        & img {
            width: 20px;
        }
    }
`

export const InputBox = styled.input `
    height: 100%;
    outline: none;
    width: 100%;
    border: none;
    background-color: transparent;
    color: ${(props: any) => props.theme.body};
    padding: 15px;
    flex: 0.95;

    &::placeholder {
        color: #8F8E8E;
        font-size: 14px;
    }
`

export const FilterBtn = styled.button `
    border: none;
    outline: none;
    border-radius: 8px;
    background-color: ${(props:any) => props.bg};
    padding: 3px;
    font-size: 14px;
    font-weight: 800;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    flex: 0.025;

    & img {
        width: 50px;
    }
`

export const SearchBtn = styled(FilterBtn) `
    padding: 15px;
`

export const FilterVerifiedHeaderWrap = styled.div `
    @media only screen and (min-width: 767px) {
        /* padding-left: 160px; */
    }
`

export const FilterVerifiedHeader = styled.div `
    margin-top: 0px;
    display: flex;
    gap: 15px;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 500;

    @media only screen and (max-width: 454px) {
        font-size: 12px;
        margin-bottom: 10px;
        padding-left: 12px;
    }

    @media only screen and (min-width: 540px)  {
        justify-content: center;
    }
`

export const Radio0verall = styled.div `
    margin: 30px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    & div {
        margin: 0;
        align-items: center;
        /* color: #fff; */
        gap: 5px;
    }
`

export const RadioBtn = styled.div `
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: solid 2px ${(props: any) => props.theme.borderColor};
    background-color: ${(props:any) => props.radioActive ? '#ffa100' : 'transparent'};
    cursor: pointer;
`

export const AssetOverall = styled.div `
    margin: 40px 0;
    display: flex;
    gap: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & a {
        text-decoration: none;
    }

    @media only screen and (min-width: 355px)  {
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    @media only screen and (min-width: 415px)  {
        padding: 0;

    }

    @media only screen and (min-width: 450px)  {
        padding: 0 30px;
    }

    @media only screen and (min-width: 500px)  {
        grid: auto /repeat(2, 1fr);
        padding: 0;
        display: grid;
        justify-content: space-evenly;
        justify-items: center;
    }

    @media only screen and (min-width: 715px)  {
        grid: auto /repeat(3, 1fr);
        display: grid;
        grid-gap: 25px;
    }

    @media only screen and (min-width: 959px)  {
        grid: auto /repeat(4, 1fr);
        row-gap: 50px; 
    }
    
    @media only screen and (min-width: 1200px) {
        padding: 0 150px;
    }
`

export const BoxSection = styled.section ` 
    margin: 50px 0;
    margin-top: 90px;
    /* border: solid 1px blue; */
    width: 100%;

    & .subsection-header  {
        margin-bottom: 40px;
        padding: 0 30px;
    }

    & .subsection-header h1 {
        text-align: center;
    }
`

export const SwiperWrapper = styled.div `
    margin-top: 40px;
    padding: 40px;
    padding-bottom: 0px;

    & .swiper-button-next:after, & .swiper-rtl .swiper-button-prev:after, & .swiper-button-prev:after, & .swiper-rtl .swiper-button-next:after {
        color: ${(props:any) => props.theme.text} !important;
    }

    .swiper-button-prev,
    .swiper-button-next {
        display: none !important;
    }

    @media only screen and (min-width: 1200px) {
        padding: 40px 150px;
    }  
`

export const BoxExtras = styled(BoxSection) `
    padding: 30px;
    width: unset;

    & .subsection-header {
        padding: 0;
    }

    & .subsection-header h1{
        text-align: left;
        margin-bottom: 0px;
    }

    & .unique-gift-text {
        width: 50%;
    }

    & .unique-gift-text p {
        font-size: 18px;
    }

    & .special-deals-wrapper {
        margin: 100px 0;
        margin-bottom: 0;

        .swiper-button-next:after, & .swiper-rtl .swiper-button-prev:after, & .swiper-button-prev:after, & .swiper-rtl .swiper-button-next:after {
            color: #fff !important;
        }      
    }

    & h5 {
        color: #ffa100;
        font-size: 18px;
        margin-bottom: 0;
    }

    & .unique-gift-wrapper {
        display: grid;
        grid: auto /repeat(2, 1fr);
        grid-gap: 35px;
        margin-bottom: 0;
    }

    & .unique-gift-wrapper .gift-subwrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }

    @media only screen and (min-width: 800px) {
        &.booking-section {
            flex: 0.5;
            margin-top: 40px;
        }
    }

    @media only screen and (min-width: 1200px) {
        padding: 30px 150px;

        &.booking-section {
            padding: 0;
        }
    }  
`

export const UniqueGiftImg = styled.img `
    width: 100%;
`

export const DeliveryBoxInfo = styled.div `
    padding-right: 100px;
    padding-left: 20px;
    margin-top: 60px;
`

export const DeliveryTxtHeader = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

export const WhiteDeliveryTxt = styled.h2 `
    font-size: 25px !important;
    font-weight: 700;
    margin: 0;
    text-align: left !important;
`

export const YellowDeliveryTxt = styled(WhiteDeliveryTxt) `
    color: #ffa100;
`

export const DeliveryImageBox = styled.div`
    background-color: #000;
    border-radius: 12px;
    width: 250px;
    height: 250px;
    position: relative;
    margin: 40px;
`

export const DeliveryImageWrap = styled.div`
    position: absolute;
    top: 0;
    right: -40px;

    & img {
        width: 270px;
    }
`

export const DeliveryContentBox = styled.div `
    & p {
        font-size: 16px;
      
    }
`

export const StartDeliveryBtn = styled.div `
    cursor: pointer;
    padding: 15px 25px;
    border: solid 1px #fff;
    border-radius: 8px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;

    & p {
        color: #fff;
        margin: 0;
        font-weight: 700;
        font-size: 16px;
    }
`

export const YoutubeWrap = styled.div `
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    & iframe {
        border-radius: 15px;
    }
`

