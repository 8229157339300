import styled from 'styled-components'



export const Div = styled.div`
padding:0em 2em;
margin-top:50px;
`

export const DriverDiv = styled.div`
padding:0 1em;
margin-top:70px;
display:flex;
gap:10px;
@media only screen and (min-width: 900px) {
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:1em;

    }

`







