import React, { FC, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'
import {
  AuthDiv,
  SignupDiv,
  Input,
  P,
  Button,
  Div,
  H3,
  DisabledButton,
} from "./first.styles";
import refIcon from "../../../assets/images/png/referralIcon.png";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer, toast } from "react-toastify";

import { useCreateOTPMutation } from "../../../services/providerApi";
import { useAppDispatch, useAppSelector } from "../../../store";
import { Link } from "react-router-dom";
import {
  FLEXDIV2,
  ICONDIV,
  InputField,
} from "../../providerAccountSetup/firstScreen/firstScreen.styles";
import { BsEnvelopeFill } from "react-icons/bs";
import { setOTPData } from "../../../slices/otp";
import countryToCurrency, { Currencies, Countries } from "country-to-currency";

const AuthBox = ({ component, setComponent }: any) => {
  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );

  const [email, setEmail] = useState<string>("");
  const [isValidEmail, setIsValidEmail] = useState(false);

  const validateEmail = (email: string) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleEmail = (e: any) => {
    setEmail(e.target.value);
    setIsValidEmail(validateEmail(e.target.value));
  };

  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [btnLoad, setBtnLoad] = useState(false);

  const dispatch: any = useAppDispatch();

  const [OTP, { data, isLoading, isSuccess, isError, error }] =
    useCreateOTPMutation();

  useEffect(() => {
    if (isLoading) {
      setBtnLoad(true);
    }
    if (isSuccess) {
      // dispatch(setOTPEmail(email))
      setComponent(3);
    }
    if (isError && "status" in error!) {
      if (error.data?.message) {
        notifyError(error.data?.message);
      } else {
        // dispatch(setOTPEmail(email))
        notifyError(error?.data?.message);
        setComponent(4);
        notifyError(error?.data?.message);
      }
      setBtnLoad(false);
    }

    if (isError && "status" in error!) {
      if (isError && error.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
      }
      setBtnLoad(false);
    }
  }, [isLoading, isSuccess, isError, data]);

  const handlePhone = (e: any) => {
    setPhoneNumber(e);
  };

  const handleCreatePhoneOTP = () => {
    if (phoneNumber?.length < 1) {
      notifyError(
        "Pnone Number provided Incorrect, Please provide the correct info"
      );
    } else {
      dispatch(setOTPData({ phoneNumber: phoneNumber }));
      dispatch(setOTPData({ email: "" }));

      let formData: any = new FormData();
      formData.append("phone_number", phoneNumber);
      OTP(formData);
    }
  };

  const handleCreateEmailOTP = () => {
    if (!isValidEmail) {
      notifyError("Email provided Incorrect");
    } else {
      dispatch(setOTPData({ phoneNumber: "" }));
      dispatch(setOTPData({ email: email }));

      let formData: any = new FormData();
      formData.append("email", email);
      OTP(formData).then((response:any)=>{
        console.log(response)
        if (response?.error?.data?.message.hasOwnProperty("email")) {
            notifyError(
              "Email provided already taken, Please provide the new email or log in with your email and password"
            );
          }
      });
    }
  };

  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  return (
    <>
      <ToastContainer />

      <AuthDiv>
        <Div>
          {/* <AiOutlineLeft style={{color:'#fff', fontSize:'25px'}}/> */}
          <H3>Create Account </H3>
        </Div>
        <SignupDiv>
          <Input>
            <input
              type="text"
              style={{
                fontSize: 14,
                padding: "17px",
                border: "none",
                outline: "none",
              }}
              placeholder="Referral Code"
            />
            <img
              src={refIcon}
              alt=""
              style={{ width: "35px", marginRight: "10px" }}
            />
          </Input>

          {currentLocation !== "NG" ? (
            <>
            <FLEXDIV2 style={{ marginTop: 1, boxShadow: "2px 2px 5px  #ddd" }}>
              <ICONDIV
                style={{ fontSize: 20, borderRadius: "10px 0px 0px 10px" }}
              >
                <BsEnvelopeFill />
              </ICONDIV>
              <Input>
                <InputField
                  style={{ fontSize: 14, padding: "16px 0px" }}
                  type="text"
                  value={email}
                  onChange={handleEmail}
                  placeholder="Email Address"
                />
              </Input>

            </FLEXDIV2>
             <P>To verify your Email address we will send you an OTP</P>
             </>
          ) : (
            <>
              <Input>
                <PhoneInput
                  defaultCountry={currentLocation}
                  value={phoneNumber}
                  onChange={handlePhone}
                  international
                  withCountryCallingCode
                />
              </Input>
              <P>To verify your number we will send an SMS Code</P>
            </>
          )}
        </SignupDiv>

        {btnLoad ? (
          <DisabledButton>Please Wait...</DisabledButton>
        ) : (
          <>
            {currentLocation !== "NG" ? (
              <Button onClick={handleCreateEmailOTP}>Continue</Button>
            ) : (
              <Button onClick={handleCreatePhoneOTP}>Continue</Button>
            )}
          </>
        )}

        <p style={{ fontSize: "13px" }}>
          {" "}
          <span style={{ color: "#fff" }}>Already have an account ? </span>
          <Link to={"/login"}>
            <span style={{ color: "#FFA100" }}>Login</span>
          </Link>
        </p>
      </AuthDiv>
    </>
  );
};
export default AuthBox;
