import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';


import {
    Input, VerifyButton, DIV, INPUTS, Text, DropDown, Modal, Option, Div, Label
} from './secondScreen.styles';
import Footer from '../../footer'
import whiteMark from '../../../assets/images/png/whiteMark.png'
import { setVehicleDetailss } from '../../../slices/provider';
import { useAppDispatch, useAppSelector } from '../../../store';
const SecondScreen: FC<{ setQuestion: any, setWidth: any, setVehicleDetails: any, vehicleDetails: any }> = ({ setQuestion, setWidth, setVehicleDetails, vehicleDetails }) => {
    const regex: RegExp = /^[A-Za-z]+$/; // Regex to match alphabetic characters only

    const vehicleInfo = vehicleDetails

    const [isVisible, setIsVisible] = useState(false)
    const [year, setYear] = useState(Number(vehicleInfo?.year))
    const dispatch = useAppDispatch()


    useEffect(() => {
        setWidth(30)
        setQuestion(2)
    }, [])

    const openModal = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        setIsVisible((prev) => !prev)
    };

    const handleYear = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        const inputValue = (event.target as HTMLTextAreaElement).value
        const regex = /^[0-9\b]+$/;
        if (inputValue === '' || regex.test(inputValue)) {
            setYear(Number((event.target as HTMLTextAreaElement).value))
        }
        
        setVehicleDetails({
            ...vehicleDetails, year: (event.target as HTMLTextAreaElement).value
        })
        dispatch(setVehicleDetailss({ ...vehicleDetails, year: (event.target as HTMLTextAreaElement).value }));


    };

    const handlePlateNumber = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        setVehicleDetails({
            ...vehicleDetails, plate_number: (event.target as HTMLTextAreaElement).value.trim()
        })
        dispatch(setVehicleDetailss({ ...vehicleDetails, plate_number: (event.target as HTMLTextAreaElement).value.trim() }));


    };

    const handleBrand = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
        setVehicleDetails({
            ...vehicleDetails, brand_name: (event.target as HTMLTextAreaElement).value
        })
        dispatch(setVehicleDetailss({ ...vehicleDetails, brand_name: (event.target as HTMLTextAreaElement).value }));


    };

    const handleName = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setVehicleDetails({
            ...vehicleDetails, vehicle_name: (event.target as HTMLTextAreaElement).value
        })
        dispatch(setVehicleDetailss({ ...vehicleDetails, vehicle_name: (event.target as HTMLTextAreaElement).value }));

    };




    return (
        <>
            <Input>
                <INPUTS
                    type="text"
                    value={vehicleInfo.vehicle_name}
                    onChange={handleName}
                    style={{ width: '70%', padding: '0px 15px', height: '100%', fontSize: '15px', border: 'none', outline: 'none', }}
                    placeholder='Vehicle Name'
                />

                {/* <img src={refIcon} alt="" /> */}
                <VerifyButton style={{ padding: '2px', width: '30%' }}>
                    {/* {vehicleInfo.year ? <Text>{vehicleInfo.year}</Text> : <Text>Year </Text>} */}

                    <INPUTS
                        type="text"
                        onChange={handleYear}
                        value={Number(year)}
                        style={{ width: '50%', background: 'transparent', padding: '0px 1px', height: '100%', fontSize: '15px', border: 'none', outline: 'none', }}
                    />
                    <DropDown>
                        <IoIosArrowUp style={{ color: '#FFD58C', fontSize: 15, fontWeight: 600 }} onClick={() => {
                            setYear((prev: any) => prev + 1)
                            setVehicleDetails({
                                ...vehicleDetails, year: year+1
                            })
                            dispatch(setVehicleDetailss({ ...vehicleDetails, year: year+1 }));
                    

                        }} />
                        <IoIosArrowDown style={{ color: '#FFD58C', fontSize: 15, fontWeight: 600 }} 
                        onClick={() => { setYear((prev: any) => prev - 1) 
                            setVehicleDetails({
                                ...vehicleDetails, year: year-1
                            })
                            dispatch(setVehicleDetailss({ ...vehicleDetails, year: year-1 }));
                        }} 
                        
                        />
                    </DropDown>
                </VerifyButton>


            </Input>
 

            <DIV style={{ marginTop: '20px' }}>
                <INPUTS
                    type="text"
                    value={vehicleInfo.brand_name}
                    onChange={handleBrand}
                    style={{ fontSize: '16px', border: 'none', outline: 'none', }}
                    placeholder='Brand name'
                />
                <INPUTS
                    type="text"
                    value={vehicleInfo.plate_number}
                    onChange={handlePlateNumber}
                    style={{ fontSize: '16px', border: 'none', outline: 'none', }}
                    placeholder='Plate number'
                />
            </DIV>




        </>

    )
}

export default SecondScreen