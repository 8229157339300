import styled from 'styled-components'


export const P2 = styled.p`
font-weight:500;
color:#fff;
font-size:12px;
text-align:center;
`
export const DocDiv = styled.div`
display:flex;
width:100%;
gap:10px;
margin-top:30px;

`
export const Doc= styled.div`
width:33%;
display:flex;
flex-direction:column;
position:relative;
`
export const Close= styled.button`
width:33%;
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
background:#000;
color:#ffa100;
position:absolute;
top:10px;
right:10px;
padding:5px;
z-index:100;
border-radius:50%;
height:25px;
width:25px;
font-size:15px;
`
export const Doc1 = styled.div`
display:flex;
border: 3px solid rgba(18, 142, 64, 0.62);
border-radius:12px;
height:100px;
justify-content:center;
align-items:center;
position:relative;
color:#fff;
overflow:hidden;
`
export const Doc2 = styled.div`
display:flex;
border: 3px solid rgba(255, 43, 43, 0.51);
border-radius:12px;
height:100px;
justify-content:center;
align-items:center;
position:relative;
color:#fff;
overflow:hidden;

`
export const Doc3 = styled.div`
display:flex;
border: 3px solid rgba(248, 139, 14, 0.75);
border-radius:12px;
height:100px;
justify-content:center;
align-items:center;
color:#fff;
position:relative;
overflow:hidden;

`
export const Label = styled.label`
background: #A10505;
font-weight:bold;
font-size:13px;
margin-left:15px;
padding:5px 10px;
border-radius:5px;
margin-top:55px;
`
export const Button = styled.button`
background: #ffa100;
color:#000;
padding:8px 20px;
border-radius:7px;
font-size:14px;
font-weight:bold;
border:none;
`

export const Div = styled.div`
display:flex;
gap:5px;
align-items:center;
justify-content:end;
margin-top:10px;
`
export const P = styled.label`
color: #FFD58C;
font-weight:bold;
font-size:13px;
margin-top:30px;
`