import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';


interface CustomError {
    data: {
        error: string;
        message: any;
        statusCode: number;
    };
    status: number;
}

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const walletApi = createApi({
    reducerPath: 'walletApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState)?.auth?.auth?.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            headers.set('Accept', 'application/json');
            return headers;
        },
    }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
    tagTypes: ['Wallet'],
    endpoints: builder => ({
        createFiatWallet: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'wallet/create-fiat-wallet',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
            invalidatesTags: ["Wallet"]
        }),
        createVirtualWallet: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'setup-virtual-account',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
            invalidatesTags: ["Wallet"]
        }),
        verifyAccount: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'verify-account-number',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
            invalidatesTags: ["Wallet"]
        }),
        topUpWallet: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'initialize-paystack-transaction',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
            invalidatesTags: ["Wallet"]
        }),

        payWithStripe: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'stripe-payment-checkout',
                    method: 'post',
                    // body, 
                    body: value
                };
            }
            
        }),
        payWithWallet: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'pay-with-wallet',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
            invalidatesTags: ["Wallet"]
        }),
        walletTransfer: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'wallet/transfer',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
            invalidatesTags: ["Wallet"]
        }),
        withdrawWallet: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'initiate-payout',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
            invalidatesTags: ["Wallet"]
        }),
        initiatePayMe: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'initiate-payme',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
            invalidatesTags: ["Wallet"]
        }),
        addBooking: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'add-booking',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
            invalidatesTags: ["Wallet"]
        }),
        verifyWithdrawOtp: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'complete-payout',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
            invalidatesTags: ["Wallet"]
        }),
        createPaymentLink: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'create-payment-link',
                    method: 'post',
                    // body, 
                    body: value
                };
            },
            invalidatesTags: ["Wallet"]
        }),
        getWalletById: builder.query({
            query: (id) => {
                return {
                    url: `wallet/get-fiat-wallet/${id}`,
                };
            },
        }),
        getBankList: builder.query<any, void>({
            query: () => {
                return {
                    url: 'fetch-bank-names',
                };
            }
        }),
        getPaymentLink: builder.query({
            query: (link) => {
                return {
                    url: `/fetch-payment-link/${link}`,
                };
            },
        }),
    }),
});

export const {
    useCreateFiatWalletMutation,
    useCreateVirtualWalletMutation,
    useTopUpWalletMutation,
    usePayWithStripeMutation,
    useWalletTransferMutation,
    useInitiatePayMeMutation,
    useVerifyAccountMutation,
    useWithdrawWalletMutation,
    useAddBookingMutation,
    useVerifyWithdrawOtpMutation,
    usePayWithWalletMutation,
    useCreatePaymentLinkMutation,
    useGetBankListQuery,
    useGetWalletByIdQuery,
    useGetPaymentLinkQuery,
} = walletApi;