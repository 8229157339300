import { ItemCardWrap, CardTextWrap, CardContentWrap } from './../walletCardComponent/walletcard.styles';
import styled from 'styled-components'

export const EarningCardWrap = styled(ItemCardWrap) `
    background: linear-gradient(180deg, rgba(255, 4, 4, 0.73) 0%, rgba(255, 161, 0, 0.87) 48.96%);
    min-height: 170px;
`

export const EarningCardTextWrap = styled(CardTextWrap) `
    & p {
        color: #000;
        font-weight: 700;
        font-size: 23px;
    }
`

export const EarningBtn = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    color: #fff;
    font-weight: 700;
    font-size: 17px;
    border-radius: 6px;
    padding: 10px 19px;
    margin-top: 10px;
`

export const EarningCardContentWrap = styled(CardContentWrap) `
    margin-top: 0;
`