import styled from 'styled-components'

export const SearchOverall = styled.div `
    padding: 30px;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const SearchInput = styled.input `
    border: solid 1px ${(props: any) => props.theme.text};
    width: 100%;
    border-radius: 9px;
    padding: 15px;
    color: ${(props: any) => props.theme.text};
    background-color: transparent;
    font-size: 14px;
    outline: none;
    flex: ${(props: any) => props.flex};

    &::placeholder {
        color: solid 1px ${(props: any) => props.theme.text};
        font-size: 12px;
    }
`

export const BookingFlexWrap = styled.div `
    @media only screen and (min-width: 1100px) {
        display: flex;
        justify-content: space-between;
    }

    @media only screen and (min-width: 1300px) {
        padding: 0 100px; 
    }

    @media only screen and (min-width: 1600px) {
        padding: 0 140px; 
    }
`

export const BookingExtrasFlexWrap = styled.div `
    @media only screen and (min-width: 800px) {
        display: flex;
        flex-direction: row-reverse;
        column-gap: 40px;
        justify-content: space-between;
        padding: 0 30px;
    }

    @media only screen and (min-width: 1300px) {
        padding: 0 130px; 
    }

    @media only screen and (min-width: 1600px) {
        padding: 0 170px; 
    }
`

export const CarouselSection = styled.div `
    padding: 30px;
    padding-top: 0;
    margin-top: 60px;
    display: flex;
    position: relative;
    gap: 20px;

    @media only screen and (min-width: 1100px) {
        flex: 0.55;

        &.driver-carousel {
            .left-carousel-section {
                height: 420px;
            }
        }
    }

    & .left-carousel-section {
        flex: 0.75;
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        overflow: hidden;
        height: 270px;
        border-radius: 15px;
        height: ${(props: any) => props.wrapHeight};
        background-color: ${(props: any) => props.bg};
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
        box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);

        @media only screen and (max-width: 485px) {
            width: 220px;
            height: 220px;
            flex: 0.85;
        }

        @media only screen and (min-width: 1100px) {
            height: 420px;
        }

        .carousel-item {
            /* height: 350px; */
            min-width: 100%;
            width: 100%;
            /* transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1); */
            border-radius: 15px;

            img {
            object-fit: cover;
            object-position: center;
            max-width: 100%;
            height: 100%;
            }
        }
        

    }

    & .right-carousel-section {
        flex: 0.25;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 9px;

        img {
            width: 100px;
            height: 80px;
            cursor: pointer;
            object-fit: cover;
        }

        @media only screen and (max-width: 485px) {
            flex: 0.15;

            img {
                width: 55px;
                height: 43px;
                border-radius: 8px;
                object-fit: cover;
                object-position: center;
            }
        }
    }
`

export const BookingOverall = styled.div `

    @media only screen and (max-width: 484px) {
        .booking-suboverall {
            padding-right: 60px !important;
        }
    }

    @media only screen and (min-width: 1100px) {
        flex: 0.45;
    }
`

export const CarouselImg = styled.img `
    border-radius: 12px;
    width: ${(props: any) => props.imgWidth};
`

export const VehicleNameBox = styled.div `
    background-color: #000;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    padding: 10px 15px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    position: absolute;
    top: -36px;
    left: 20px;
`

export const VehiclePrice = styled(VehicleNameBox) `
    background-color: #ffa100;
    color: #000;
    border-radius: 0px;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
    top: unset;
    left: 0;
    bottom: 30px;
`

export const BookingQuestionsWrap = styled.div `
    display: block;
    margin-top: 40px;

    & .flex-wrap {
        display: flex;
        align-items: center;
        gap: 10px;
    }
`

export const QuestionHeader = styled.h3 `
    color: #C4C4C4;
    font-size: 14px;
    font-weight: 600;
`

export const QuestionBox = styled.div `
    background-color: ${(props: any) => props.theme.text};
    display: flex;
    padding: 8px 15px;
    border-radius: 9px;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 454px) {
        padding: 6px 10px;
    }
`

export const PP = styled.p `
    font-size: 14px;
    color: ${(props: any) => props.theme.body};

    @media only screen and (max-width: 484px) {
        font-size: 12px;
    }
`

export const SetHoursBox = styled.div `
    border-radius: 8px;
    padding: 4px 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-color: #09051C;

    & h2 {
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        margin: 0;
    }

    & p {
        font-size: 10px;
        color: #ffa100;
        margin: 0;
        font-weight: 700;
    }
`

export const SelectBlackBox = styled(SetHoursBox) `
    padding: 10px 13px;
`

export const HoursControlBox = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
    
    & img {
        width: 16px;
        cursor: pointer;
    }
`

export const QuestionImg = styled.img `
    width: ${(props: any) => props.width};
    cursor: pointer;

    @media only screen and (max-width: 454px) {
        width: 13px;

        &.arrow-drop {
            width: 25px;
        }
    }
`

export const QuestImg = styled.img `
    width: ${(props: any) => props.width};
    cursor: pointer;
    filter: ${(props:any) => props.complete === false ? 'invert(0%) sepia(0%) saturate(0%) hue-rotate(320deg) brightness(89%) contrast(106%)' : 'invert(100%) sepia(12%) saturate(7450%) hue-rotate(258deg) brightness(118%) contrast(114%)'};
`

export const WhereImg = styled.img `
    width: ${(props: any) => props.width};
    cursor: pointer;

    @media only screen and (max-width: 454px) {
        width: 30px;
    }
`

export const ModalCallBtnWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    margin-top: 20px;
`

export const ModalCallBtn = styled.div `
    /* flex: 33%; */
    background-color: ${(props:any) => props.complete === true ? '#fff' : '#000'};
    display: flex;
    justify-content: center;
    width: 30%;
    cursor: pointer;
    padding: 13px 0px;
    border-radius: 4px;
    align-items: center;
    gap: 5px;

    & p {
        font-size: 13px;
        font-weight: 700;
        color: ${(props:any) => props.complete === true ? '#000' : '#fff'};
        margin: 0;
    }

    @media only screen and (max-width: 484px)  {
        gap: 1px;

        & img {
            width: 20px !important;
        }

        & .note-img {
            width: 17px !important;
        }
    }

    @media only screen and (max-width: 424px) {
        padding: 8px 0px;

        & p {
            font-size: 10px;
        }

        & .note-img {
            width: 15px !important;
        }
    }
`

export const BookingFooterWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 60px;

    & a {
        text-decoration: none;
        color: unset;
    }

    @media only screen and (max-width: 454px) {
        margin-top: 40px;
    }
`

export const BookingFooterBtn = styled.div `
    padding: 13px 0px;
    border-radius: 7px;
    width: 48%;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    color: ${(props: any) => props.text};
    border: solid 1px ${(props: any) => props.bordercolor};
    background-color: ${(props: any) => props.bg};
    cursor: pointer;

    @media only screen and (max-width: 484px)  {
        font-size: 12px;
        padding: 8px 0;
    }
`

export const FeatureWrapper = styled.div `
    display: block;
    padding: 30px;

    @media only screen and (min-width: 800px) {
        flex: 0.4;
    }

    & h4 {
        font-weight: 700;
        font-size: 18px;
        color: #ffa100;
    }

    @media only screen and (max-width: 484px) {
        h4 {
            font-size: 15px;
        }
    }
`

export const SubFeatureWrap = styled.div `
    display: flex;
    gap: 15px;
    align-items: center;
    margin-top: 15px;

    & p {
        margin: 0;
    }

    @media only screen and (max-width: 454px) {
        p {
            font-size: 12px;
        }
    }
`

export const TickBox = styled.div `
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FCDBA5;
    padding: 6px;
    width: 20px;
    height: 20px;
`