import styled from 'styled-components'



export const INPUTDIV = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:11px;
gap:3px;
padding:9px 11px;
@media only screen and (max-width: 380px) {
    padding:7px 11px;
  
    }
`
export const HalfMonthInput = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:11px;
gap:5px;
position:relative;
padding:9px 11px;
@media only screen and (max-width: 380px) {
    padding:7px 11px;

    }
    z-index: ${(props: any) => props.halfMonthVisible ? '10' : '4'};

`
export const FullMonthInput = styled.div`
display:flex;
align-items:center;
background:white;
width:100%;
border-radius:11px;
gap:5px;
position:relative;
padding:9px 11px;
@media only screen and (max-width: 380px) {
    padding:7px 11px;

    }
    z-index: ${(props: any) => props.fullMonthVisible ? '10' : '4'};

`
export const BLACKDIV = styled.div`
display:flex;
align-items:center;
background:#000;
width:40%;
justify-content:space-around;

border-radius:8px;
position:relative;
padding:10px 6px;
@media only screen and (max-width: 380px) {
    padding:8px 6px ;

    }
`
export const Input = styled.div`
width: 100%; 
outline: none; 
border: none; 
color: #fff; 
font-size:14px;
font-weight:medium;
background: transparent;
overflow:hidden;
::placeholder, ::-webkit-input-placeholder {
    color:#fff;
    font-weight:medium;
    font-size:14px;   
}
@media only screen and (max-width: 380px) {
    font-size:13px;

    }
`

export const VerifyButton = styled.button`
background: #FFD58C;
color: #000;
padding:8px 4px;
outline:none;
border:none;
border-radius:5px;
font-size:1rem;
font-weight:bold;
position:relative;
display:flex;
align-items:center;
justify-content:center;
gap:5px;
@media only screen and (max-width: 380px) {
    font-size:0.8rem;

    }
`
export const DISCOUNT = styled.span`
display:flex;
align-items:center;
justify-content:center;
background: #868686;
color:#fff;
padding:5px;
border-radius:5px;
font-size:11px;
position:relative;

`


export const FLEXDIV = styled.div`
display:flex;
align-items:center;
gap:10px;
width:100%;
margin-top:50px;
position:relative;

`
export const TITLE = styled.div`
display:flex;
gap:10px;
position:relative;
`
export const P = styled.p`
color:#fff;
font-size:8.5px;
position:absolute;
font-weight:normal;

`

export const MARKDIV = styled.div`
display:flex;
align-items:center;
background: #FFD58C;
border-radius:5px;
padding:0.2em;
z-index:8;



`
export const Wrapper = styled.div`



`
export const BlackOpacity = styled.div`



`


export const Text = styled.div`
font-size:16px;
margin-top:3px;
`
export const DropDown = styled.div`
background: #000;
display:flex;
align-items:center;
justify-content:center;
padding:1px;
`

export const Div = styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
position:absolute;
right:50px;
width:150px;
z-index:1000;
top:60px;
`
export const Modal = styled.div`
background: #000;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
padding:20px;
gap:10px;
border-radius:6px;
width:80%;

`
export const Option = styled.div`
background: #fff;
display:flex;
align-items:center;
justify-content:flex-start;
padding:10px;
gap:10px;
width:100%;
color:#000;
border-radius:6px;
font-weight:600;
`
export const Label = styled.label`

width:100%;


`

