import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify'

import { BsEnvelopeFill } from 'react-icons/bs';
import { BiBriefcase } from "react-icons/bi";

import { IoIosArrowDown } from 'react-icons/io';
import Envelop from '../../../assets/images/png/envelop.png'

import {
    DIV, INPUTS, FLEXDIV, FLEXDIV2, Input, ICONDIV, Label
} from './business.styles';
import { baseUrl, useGetBusinessByIdQuery, useGetNextOfKinByProviderIdQuery, useUpdateKinMutation, useUpdateProviderMutation } from '../../../services/providerApi';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useNavigate } from 'react-router-dom';
import { SubmitButton, SubmitDiv } from '../regularRates/regularRates.styles';


type Props = {
    userId?: any;
    providerInfo?: any;
    setProviderInfo?: any;
}
const BusinessScreen: FC<Props> = ({ userId, providerInfo, setProviderInfo }) => {


    const { data, isFetching, refetch } = useGetBusinessByIdQuery(userId)

    useEffect(() => {
        refetch()
    }, [refetch, data])



    const [isSaveLoading, setIsSaveLoading] = useState(false)
    const [showSaveButton, setShowSaveButton] = useState(false)

 
    let [businessProfile, setBusinessProfile] = useState({
        company_name: undefined,
        govt_num: undefined,
        business_email: undefined,
        business_number: undefined,
        phone_number: undefined
    });
    useEffect(() => {
        if (data && data?.data[0] !== undefined) {
            setBusinessProfile(data?.data[0])
        }
    }, [data])

 


    const handleCompanyName = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setBusinessProfile((prevState: any) => ({
            ...prevState,
            company_name: (event.target as HTMLTextAreaElement).value,
        }));
        setShowSaveButton(true)

    };
    const handleGovtNum = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setBusinessProfile((prevState: any) => ({
            ...prevState,
            govt_num: (event.target as HTMLTextAreaElement).value,
        }));
        setShowSaveButton(true)

    };

    const handlePhone = (e: any) => {
        setBusinessProfile((prevState: any) => ({
            ...prevState,
            business_number: e,
        }));
        setShowSaveButton(true)

    }
    const handleEmail = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        setBusinessProfile((prevState: any) => ({
            ...prevState,
            business_email: (event.target as HTMLTextAreaElement).value,
        }));
        setShowSaveButton(true)

    };





    // const [nextofKin, { data, isLoading, isSuccess, isError, error }] = useUpdateKinMutation()

    // useEffect(() => {
    //     if (isLoading) {
    //         setBtnLoad(true)
    //     }
    //     if (isSuccess) {
    //         setBtnLoad(false)
    //         // navigate('/provider-dashboard')
    //         // navigate('/insurance-setup')


    //     }


    //     if (isError && 'status' in error!) {
    //         if (isError && error.status.toString() === 'FETCH_ERROR') {
    //             notifyError('Network Connection Failed')
    //         }
    //         setBtnLoad(false)
    //     }
    // }, [isLoading, isSuccess, isError, data])



    const [updateBusiness, { data: businessData, isLoading, isSuccess, isError, error }] = useUpdateProviderMutation()

    const handleUpdateBusiness = () => {
        setIsSaveLoading(true)

        let formData: any = new FormData()
        formData.append('user_id', userId)
        formData.append('category', 'business')
        formData.append('company_name', businessProfile?.company_name)
        formData.append('govt_num', businessProfile?.govt_num)
        formData.append('business_email', businessProfile?.business_email)
        formData.append('business_number', businessProfile?.business_number)
        formData.append('phone_number', businessProfile?.phone_number)
        formData.append('user_role', 'provider')

        updateBusiness(formData).then((response: any) => {
            // setUserId(response?.data?.data[0]?.user_id)
            if (response?.data?.message === 'success') {
                notifySuccess('Updated Successfully')
            }
            setIsSaveLoading(false)
            setShowSaveButton(false)
            // setProviderDetails(response?.data)
        }).catch((err: any) => {
            setIsSaveLoading(false)
            notifyError('Error Occured')
        })



    }

    const currentLocation = useAppSelector((state: any) => state?.currentLocation?.currentLocation?.country)

    const alertError = () => {
        notifyError('Please Fill all Important Fields')
    }
    const notifyError = (text: any) => toast.error(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })

    const notifySuccess = (text: any) => toast.success(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })




    return (
        <>
            <ToastContainer />

            <DIV style={{ marginTop: 20 }}>
                <DIV >
                    <Label style={{ marginBottom: -12 }}>Business Name</Label>
                    <INPUTS
                        type="text"
                        value={businessProfile?.company_name}
                        onChange={handleCompanyName}

                        style={{ width: '100%', padding: '20px', border: 'none', outline: 'none', }}
                        placeholder='Company Name'
                    />


                </DIV>


                <DIV style={{ marginTop: 20 }}>
                    <Label>Government Reg. Number</Label>
                    <FLEXDIV2>
                        <ICONDIV>
                            <BiBriefcase />

                        </ICONDIV>
                        <INPUTS
                            type="text"
                            value={businessProfile?.govt_num}
                            onChange={handleGovtNum}
                            style={{ width: '78%', padding: '20px 0px', border: 'none', outline: 'none', }}
                            placeholder='123GTR555'
                        />
                    </FLEXDIV2>

                </DIV>

                <DIV style={{ marginTop: 20 }}>
                    <Label>Business Email</Label>

                    <FLEXDIV2>
                        <ICONDIV>
                            <BsEnvelopeFill />

                        </ICONDIV>
                        <INPUTS
                            type="email"
                            value={businessProfile?.business_email}
                            onChange={handleEmail}
                            style={{ width: '78%', padding: '20px 0px', border: 'none', outline: 'none', }}
                            placeholder='Johndoe@gmail.com'
                        />
                    </FLEXDIV2>
                </DIV>




                <DIV style={{ marginTop: 20 }}>
                    <Label>Business Contact</Label>

                    <div className='phone-select'>
                        <Input className='phone' >
                            <PhoneInput
                                defaultCountry={currentLocation}
                                defaultValue={businessProfile?.business_number}
                                //@ts-ignore
                                value={businessProfile?.business_number}
                                onChange={handlePhone}
                                international
                                withCountryCallingCode


                            />
                        </Input>
                    </div>


                </DIV>



            </DIV>




            {
                showSaveButton && <SubmitDiv>
                    {isSaveLoading ? <SubmitButton>
                        Updating Profile...
                    </SubmitButton> :
                        <SubmitButton onClick={() => handleUpdateBusiness()}>
                            Save and Update
                        </SubmitButton>}
                </SubmitDiv>
            }
        </>

    )
}

export default BusinessScreen