import React, { FC, useEffect, useState } from "react";
// import ToggleBar from '../../../components/toggleBar'

import {
  AuthDiv,
  Div,
  H3,
  SignupDiv,
  VerifyButton,
  VerifiedButton,
  Input,
  P,
  Button,
  DisabledButton,
} from "./second.styles";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

import { FiChevronLeft } from "react-icons/fi";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  useConfirmOTPMutation,
  useCreateOTPMutation,
} from "../../../services/providerApi";
import { Link } from "react-router-dom";
import {
  FLEXDIV2,
  ICONDIV,
  InputField,
} from "../../providerAccountSetup/firstScreen/firstScreen.styles";
import { BsEnvelopeFill } from "react-icons/bs";
import { setOTPData } from "../../../slices/otp";

const AuthBoxTwo = ({ component, setComponent }: any) => {
  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );

  const userOTP = useAppSelector((state: any) => state?.otp?.otp);
  let userPhone = userOTP?.phoneNumber;
  let userEmail = userOTP?.email;
  const [email, setEmail] = useState<string>(userEmail || "");
  const [isEmailVsible, setIsEmailVsible] = useState(false);
  const [isPhoneVsible, setIsPhoneVsible] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isContinue, setIsContinue] = useState(false);

  const [OTPValue, setOTPValue] = useState<string | number>();
  const [btnLoad, setBtnLoad] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>(userPhone || "");

  const dispatch: any = useAppDispatch();

  const [
    OTP,
    {
      data: otpData,
      isLoading: otpLoading,
      isSuccess: otpSuccess,
      isError: otpIsError,
      error: otpError,
    },
  ] = useCreateOTPMutation();

  const [confirmOTP, { data, isLoading, isSuccess, isError, error }] =
    useConfirmOTPMutation();

  useEffect(() => {
    if (isSuccess) {
      notifySuccess(data?.data);
    }

    if (isError && "status" in error!) {
      notifyError(error?.data?.message);
    }

    if (isError && "status" in error!) {
      if (isError && error.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
      }
      setBtnLoad(false);
    }
  }, [isLoading, isSuccess, isError, data]);

  useEffect(() => {
    if (otpSuccess) {
      notifySuccess(otpData?.data);
      setIsContinue(true);
    }

    if (otpIsError && "status" in otpError!) {
      notifyError(otpError?.data?.message);
    }

    if (otpIsError && "status" in otpError!) {
      if (otpIsError && otpError.status.toString() === "FETCH_ERROR") {
        notifyError("Network Connection Failed");
      }
      setBtnLoad(false);
    }
  }, [otpLoading, otpSuccess, otpIsError, otpData]);

  const handleOTP = (e: any) => {
    setOTPValue(e.target.value);
  };

  const validateEmail = (email: string) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleEmail = (e: any) => {
    setEmail(e.target.value);
    setIsValidEmail(validateEmail(e.target.value));
  };

  const handleCreateEmailOTP = () => {
    if (!isValidEmail) {
      notifyError("Email provided Incorrect, Please provide the correct info");
    } else {
      dispatch(setOTPData({ email: email }));
      dispatch(setOTPData({ phoneNumber: "" }));

      let formData: any = new FormData();
      formData.append("email", email);
      OTP(formData).then((otpResponse: any) => {
        if (otpResponse?.error?.data?.message.hasOwnProperty("email")) {
          notifyError(
            "Email provided already taken, Please provide the new email or log in with your email and password"
          );
        }
      });
    }
  };

  const handleNextComponent = () => {
    setComponent(4);
  };
  const handlePrevComponent = () => {
    setComponent(2);
  };

  const handleVerifyOTP = () => {
    let formData: any = new FormData();
    userEmail
      ? formData.append("email", userEmail)
      : formData.append("phone_number", userPhone);
    formData.append("otp_code", OTPValue);
    confirmOTP(formData);
  };

  const handlePhone = (e: any) => {
    setPhoneNumber(e);
  };

  const handleEmailOTP = () => {
    setPhoneNumber("");
    setIsEmailVsible(true);
    setIsPhoneVsible(false);
  };
  const handlePhoneOTP = () => {
    setEmail("");
    setIsEmailVsible(false);
    setIsPhoneVsible(true);
  };
  const notifyError = (text: any) =>
    toast.error(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });

  const notifySuccess = (text: any) =>
    toast.success(text, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  return (
    <>
      <ToastContainer />
      <AuthDiv>
        <Div>
          <FiChevronLeft
            onClick={handlePrevComponent}
            style={{ color: "#fff", fontSize: "30px", fontWeight: "700" }}
          />
          <H3>Create Account</H3>
        </Div>
        <SignupDiv>
          {isEmailVsible || currentLocation !== "NG" ? (
            <>
              <FLEXDIV2
                style={{ marginTop: 1, boxShadow: "2px 2px 5px  #ddd" }}
              >
                <ICONDIV
                  style={{ fontSize: 20, borderRadius: "10px 0px 0px 10px" }}
                >
                  <BsEnvelopeFill />
                </ICONDIV>
                <Input>
                  <InputField
                    style={{ fontSize: 14, padding: "16px 0px" }}
                    type="text"
                    value={email}
                    onChange={handleEmail}
                    placeholder="Email Address"
                  />
                </Input>
              </FLEXDIV2>
              {/* <P style={{ width: '80%', color: '#000', marginTop: 5 }}>
                  Didn't Recieve an OTP via Email? Try Phone Instead
                  <span onClick={handlePhoneOTP} style={{ fontWeight: '700' }}> Click Here</span>
                </P> */}
            </>
          ) : (
            <Input>
              <PhoneInput
                defaultCountry={currentLocation}
                value={userPhone}
                onChange={handlePhone}
                readOnly
                international
                withCountryCallingCode
              />
            </Input>
          )}

          <Input style={{ padding: "0px 10px" }}>
            <input
              type="text"
              style={{
                width: "65%",
                padding: "15px",
                border: "none",
                outline: "none",
              }}
              placeholder="Enter OTP"
              value={OTPValue}
              onChange={handleOTP}
            />

            {/* <img src={refIcon} alt="" /> */}
            {isSuccess ? (
              <VerifiedButton>Verified</VerifiedButton>
            ) : (
              <VerifyButton onClick={handleVerifyOTP}>Verify</VerifyButton>
            )}
          </Input>

          <P style={{ width: "80%", color: "#000", marginTop: 5 }}>
            Didn't Recieve an OTP via SMS? Try Email Instead
            <span onClick={handleEmailOTP} style={{ fontWeight: "700" }}>
              {" "}
              Click Here
            </span>
          </P>
        </SignupDiv>

        {isEmailVsible && !isContinue && (
          <Button onClick={() => handleCreateEmailOTP()}>
            Request Email OTP
          </Button>
        )}

        {(isContinue || !isEmailVsible) && (
          <>
            {!isSuccess ? (
              <DisabledButton>Continue</DisabledButton>
            ) : (
              <Button onClick={() => handleNextComponent()}>Continue</Button>
            )}
          </>
        )}

        <p style={{ fontSize: "13px" }}>
          {" "}
          <span style={{ color: "#fff" }}>Already have an account ? </span>
          <Link to={"/login"}>
            <span style={{ color: "#FFA100" }}>Login</span>
          </Link>
        </p>
      </AuthDiv>
    </>
  );
};

export default AuthBoxTwo;
