import React from 'react'
import { QuestionImg } from '../../pages/user/CarBooking/CarBooking.styles'
import { OwnersCardWrap } from './ownersCard.styles'

import stars from '../../assets/images/svg/stars.svg'
import location from '../../assets/images/svg/location-black.svg'
import { Link } from 'react-router-dom'

const OwnersNoteCard = ({cardtype}: any) => {
  return (
    <OwnersCardWrap>
        <div className="inner-card-wrap">
            <div className="headers-wrap">
                <h3>
                    {
                        cardtype === "driver" ? 
                        "Driver's Note" : "Owners Note"
                    }
                </h3>
                <hr />
            </div>

            <div className="location-wrap">
                <QuestionImg width={'20px'} src={location}/>
                <p>
                    Lagos
                </p>
            </div>

            <div className="content-wrap">
                {
                    cardtype === "driver" ? 
                    <>
                        <p>
                            At Roadlers, our executive drivers are professionally trained individuals dedicated to your safety and comfort. He/she would work with you <b>10 hours daily, extra hours are charged at 1,000 naira hourly.</b>  
                        </p>

                        <p>
                            <b>Traveling across Nigeria?</b>  make an out of state booking and our support team will assist you in completing your order.
                        </p>
                    </> : 
                    <p>
                        This vehicle is available for 10 hours daily, for services outside state, make a travel request as it's come at a different rate. 
                        For inquiries, or immediate response please contact <a href="mailto:support@roadlers.com">support@roadlers.com</a>
                    </p>
                }

            </div>

            <div className="owners-footer">
                <QuestionImg src={stars} />

                <div className="verified-wrap">
                    Verified
                </div>
            </div>
        </div>
    </OwnersCardWrap>
  )
}

export default OwnersNoteCard