import React, { useEffect, useState } from 'react'
import {useParams} from 'react-router-dom'
import "./styles/BlogPost.css"
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation';
import DOMPurify from "dompurify";
import MetaTags from 'react-meta-tags';

import banner from "../../../assets/images/jpg/blogbanner.jpg"
import likeEmpty from "../../../assets/images/svg/like-icon.svg"
import likeIcon from "../../../assets/images/svg/likeFilled.svg"
import car from "../../../assets/images/svg/car-filter.svg"
import driver from "../../../assets/images/svg/driver-filter.svg"
import travel from "../../../assets/images/svg/travel-filter.svg"
import owner from "../../../assets/images/svg/owner-filter.svg"
import author from "../../../assets/images/svg/author.svg"

import Footer from '../../../components/footer'
import Header from '../../../components/header'
import ShareArticle from '../../../components/sharearticle'
import ReviewCard from '../../../components/reviewcard'
import { useGetBlogCommentsQuery, useGetBlogLikesQuery, useGetBlogPostQuery, usePostBlogLikeMutation, usePostCommentsMutation } from '../../../services/blogApi'
import moment from 'moment'
import { Swiper, SwiperSlide } from 'swiper/react';
import {FreeMode, Navigation} from 'swiper'
import {ContentState, convertToRaw, EditorState} from "draft-js"
import htmlToDraft from 'html-to-draftjs'
import Loader from '../../../components/loader'
import { ToastContainer, toast } from 'react-toastify'

const BlogPost = () => {
    const params = useParams()
    // USESTATE
    const [blogId, setBlogId] = useState(1)
    const [blogData, setBlogData] = useState<any>()
    const [blogCommentData, setBlogCommentData] = useState<any[]>([])
    const [likesCount, setLikesCount] = useState<any>()
    const [preLoad, setPreLoad] = useState(false)
    const [comment, setComment] = useState("")
    const [userName, setUserName] = useState("")
    const [userMail, setUserMail] = useState("")
    const [btnLoad, setBtnLoad] = useState(false)
    const [likeBtn, setLikeBtn] = useState(false)
    

    const {data: likeData, isFetching, refetch} = useGetBlogLikesQuery(blogId)
    const {data: blogPostData, isFetching: isFetchingBlogPost, refetch: refetchBlogPost} = useGetBlogPostQuery(params.bloglink, {refetchOnMountOrArgChange: true})
    const {data: blogPostComments, isFetching: isFetchingBlogComments, refetch: refetchBlogComments} = useGetBlogCommentsQuery(blogData?.id, {refetchOnMountOrArgChange: true})
    const [postBlogLike, {data:bloglikeData}] = usePostBlogLikeMutation()
    const [postComments, { data, isLoading, isSuccess, isError, error }] = usePostCommentsMutation()

    
    
    // USEFFECT

    // useEffect(() => {
    //     if(isFetchingBlogPost) {
    //         setPreLoad(true)
    //     }else {
    //         setPreLoad(false)
    //     }
    // }, [isFetchingBlogPost])

    useEffect(() => {
        const likesNoCount = Number(blogData?.likes)
        setLikesCount(likesNoCount)
    }, [blogData])

    useEffect(() => {
        refetchBlogPost()
        setBlogData(blogPostData?.data[0])
    }, [blogPostData, blogId, likeData, params])

    useEffect(() => {
        refetchBlogComments()
        setBlogCommentData(blogPostComments?.data)
    }, [blogPostComments, blogPostData, params])


    useEffect(() => {
        if (isLoading) {
            setBtnLoad(true)
        }
        if (isSuccess) {
            setBtnLoad(false)
            notifySuccess("Comment has been sent!")
        }
        if (isError && 'status' in error!) {
            notifyError(error.data.message)
            setBtnLoad(false)
        }
    }, [isLoading, isSuccess, isError, data ])

    const handleComment = () => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        let formData: any = new FormData()

        formData.append('name', userName)
        formData.append('email', userMail)
        formData.append('comment', comment)
        formData.append('post_id', blogData?.id)

        if(userName?.length === 0) {
            notifyError("Name input cannot be empty")
        }else if(userMail.length == 0) {
            notifyError("Mail input cannot be empty")
        }else if(!userMail.match(mailformat)) {
            notifyError("Input correct mail format")
        }else if (comment?.length === 0) {
            notifyError("Comment section cannot be empty")
        }else {
            postComments(formData)
        }

    }

    const handleBlogPostLike = (numLike: number) => {
        let formData: any = new FormData()
    
        formData.append('post_id', blogData?.id)
        formData.append('likes', numLike)
    
        postBlogLike(formData)
    }

    const handleLikesSub = () => {
        setLikeBtn(false)
          const newCount = likesCount - 1;
          if(newCount < 0 ) {
            setLikesCount(0)
          }else {
            setLikesCount(newCount)
          }
          handleBlogPostLike(0)
    }  
    
    const handleLikesAdd = () => {
        setLikeBtn(true)
        const newCount = likesCount + 1;
        setLikesCount(newCount)
        handleBlogPostLike(1)
    }

    const notifyError = (text: any) => toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })

    const notifySuccess = (text: any) => toast.success(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })


    // const contentBlock = htmlToDraft(blogData?.content)
    // const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
    // const _editorState =  EditorState.createWithContent(contentState)

  return (
    preLoad ? 
    <Loader /> : 
    <div className='blogPost'>
        <MetaTags>
            <title>{blogData?.title}</title>
            {/* <meta name="description" content="Your description here.." /> */}
            <meta property="og:title" content="Roadlers" />
            <meta property="og:image" content={`https://api.roadlers.com/storage/${blogData?.post_image}`} />
        </MetaTags>
        <ToastContainer />
        <Header />
        <div className="blogpost-banner">
            <img className='banner-img' src={`https://api.roadlers.com/storage/${blogData?.post_image}`}  alt="" />
            
            <div className="created-date">
                <p>{moment(blogData?.created_at).format('MM/DD/YYYY')}</p>
            </div>

            <div className="blogpost-header-container">
                <div className="blogpost-title">
                    <h2>{blogData?.heading}</h2>
                </div>

                <div className="likes">
                        {  
                          likeBtn === false && (
                            <button onClick={() => handleLikesAdd()}>
                              <img src={likeEmpty} alt="" />
                            </button>
                          )         
                        }
  
                        {
                          likeBtn === true && 
                          <button onClick={() => handleLikesSub()}>
                            <img src={likeIcon} alt="" />
                          </button>
                        }
                    

                    <div className="likes-count">
                        <p>{!likesCount ? 0 : likesCount } likes</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="blog-category-filter">
            <div className="inner-category-filter">
                <button className={blogData?.category === "cars" ? "active" : ""} >
                    <img src={car} alt="" />
                    <p>Cars</p>
                </button>

                <button className={blogData?.category === "drivers" ? "active" : ""}>
                    <img src={driver} alt="" />
                    <p>Drivers</p>
                </button>

                <button className={blogData?.category === "travel" ? "active" : ""}>
                    <img src={travel} alt="" />
                    <p>Travel</p>
                </button>

                <button className={blogData?.category === "ownership" ? "active" : ""}>
                    <img src={owner} alt="" />
                    <p>Ownership</p>
                </button>
            </div>
        </div>

        <div className="blogpost-body">
            <div className="innerpost-wrapper">
                <div className="blogpost-topic">
                    <h2>
                    {blogData?.title}
                    </h2>
                </div>

                <div className="blogpost-content">
                    <div className="innerblog-content">
                        <div 
                           dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(blogData?.content)
                           }} 
                        />

                    </div>
                </div>

                <div className="author-info-container">
                    <div className="inner-author-wrapper">
                        <img src={`https://api.roadlers.com/storage/${blogData?.author_image}`} alt="author" />

                        <div className="text">
                            <h4>{blogData?.author_name}</h4>
                            <p>{blogData?.author_role}</p>
                        </div>
                    </div>
                </div>

                <ShareArticle setComment={setComment} handleComment={handleComment} setUserMail={setUserMail} setUserName={setUserName} link={params.bloglink} title={blogData?.title}/>

                <div className="ratings-review-wrapper">
                    <Swiper
                        freeMode={true}
                        grabCursor={true}
                        modules={[FreeMode, Navigation]}
                        navigation
                        className='mySwiper'
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            }

                        }}
                    >
                        {
                            blogCommentData?.map((comment) => (
                                <SwiperSlide key={comment?.id}>
                                    <ReviewCard 
                                        initial={comment?.name.charAt(0).toUpperCase()}
                                        firstName={comment?.name}
                                        comments={comment?.comment}
                                    />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </div>

        </div>


        <Footer />
        
    </div>
  )
}

export default BlogPost