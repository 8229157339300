import React, { FC, useEffect, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";


import { CalendarDiv, Button, H6, Label, FlexBetween, DoneButton }
    from './requestsCalendar.styles';
const RequestsCalendar: FC<{
    requests: any, setRequestComponent: any,

    setCalendarComponent: any
}> = ({ setRequestComponent, setCalendarComponent, requests }) => {



    const [dateArr, setDateArr] = useState<any>();
    const [month, setMonth] = useState<any>();

    const pickupDates = requests.map((request: any) => request.pickup_date);

    const pickupDatesArray = pickupDates?.map((date: any) => JSON.parse(date)).flat();


    function setDate() {
        for (let i = 0; i < pickupDatesArray.length; i++) {
            pickupDatesArray[i] = pickupDatesArray[i].replace(/\\/g, '');
            setDateArr(pickupDatesArray)
        }
    }
    useEffect(() => {
        setDate()
    }, [])

    function convertDate(dateArr: any) {
        const dateParts = dateArr.split("/");
        return {
            year: parseInt(dateParts[2]),
            month: parseInt(dateParts[0]),
            day: parseInt(dateParts[1])
        }
    }
    const convertedDates = dateArr?.map((dateArr: any) => convertDate(dateArr));



    //@ts-ignore

    const handleDone = () => {
        setRequestComponent(1)
        setCalendarComponent(0)
    }



    function getMonthName(monthNumber) {
        const months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];

        return months[monthNumber - 1];
    }

  
    return (
        <>
            <Label>All coloured dates indicates your bookings</Label>
            <H6>Dates Booked</H6>
            <CalendarDiv>
                <Calendar
                    value={convertedDates}
                    colorPrimary='#ffa100'
                    calendarClassName='calendarDate'
                />
                <DoneButton onClick={handleDone}>Done</DoneButton>
            </CalendarDiv>

            <FlexBetween>
                <Button>{convertedDates?.length} days Booked</Button>
                {/* <Label style={{ fontWeight: '500' }}>
                    April | May | June

                   
                
                </Label> */}
            </FlexBetween>


        </>

    )
}

export default RequestsCalendar