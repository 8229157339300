import styled from 'styled-components'

export const ItemCardWrap = styled.div `
    background: linear-gradient(180deg, #6A6A6A 0%, #121211 32.29%);
    border-radius: 15px;
    /* min-height: 200px; */
    position: relative;

    & .inner-item-card {
        padding: 15px 30px; 
    }

    @media only screen and (min-width: 654px) {
        width: 574px;
    }
`

export const CardContentWrap = styled.div `
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 30px;

    @media only screen and (max-width: 454px) {
        margin-top : 0;
    }
`

export const CardTextWrap = styled.div `
    flex: 0.5;

    & p {
        color: #868686;
        font-weight: 600;
        font-size: 13px;
        margin: 0;
        white-space: nowrap;
    }

    & h2 {
        font-size: 20px;
        color: #fff;
        font-weight: 700;
        white-space: nowrap;
    }

    @media only screen and (max-width: 454px) {
        p {
            font-size: 10px;
        }

        h2 {
            font-size: 14px;
            margin-bottom: 20px;
        }
    }
`

export const CardImgWrap = styled.div `
    flex: 0.4;

    & img {
        width: 100%;
        margin-top: 10px;
    }
`

export const AbsoluteButton = styled.div `
    position: absolute;
    bottom: -25px;
    right: 30px;
    border-radius: 8px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.13);
    -moz-box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.13);
    box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.13);

    & .inner-button {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        padding: 10px;

        img {
            width: 30px;
        }

        p {
            color: #000;
            font-weight: 700;
            font-size: 17px;
            margin: 0;
        }
    }

    @media only screen and (max-width: 454px) {
        border-radius: 6px;
        bottom: -12px;

        .inner-button {
            padding: 8px;

            img {
                width: 20px;
            }

            p {
                font-size: 12px;
            }
        }
    }
`



