import React, { useEffect, useState } from 'react'
import { AddImageBox, AddImageBtn, AddImageSection, AlertWrapper, ButtonFooter, InsuranceInfoBox, InsuranceInfoFlexOverall, ProgressBox, ProgressWrap, SelectInput } from './transactionalertbox.styles'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { ToastContainer, toast } from 'react-toastify';

import map from '../../assets/images/png/map.png'
import car from '../../assets/images/svg/success-car.svg'
import arrow from '../../assets/images/svg/arrow-down-vector.svg'
import license from '../../assets/images/png/insurance-img.png'
import camera from '../../assets/images/svg/add-img-yellow.svg'
import { Link } from 'react-router-dom'
import InsuranceDropdown from '../insuranceDropdown';
import { useUpdateInsuranceMutation } from '../../services/customerApi';
import { RootState, useAppSelector } from '../../store';



const TransactionAlertBox = ({buttonText, insureDisplay, setIsDisplay}: any) => {
  const {id } = useAppSelector<any>((store: RootState) => store?.auth?.auth)

  const [updateInsurance, { data, isLoading, isSuccess, isError, error }] = useUpdateInsuranceMutation();

  const [userState, setUserState] = useState<string>('');
  const [userCountry, setUserCountry] = useState<string>('');
  const [gender, setGender] = useState('')
  const [identification, setIdentification] = useState('')
  const [idNum, setIdNum] = useState('')
  const [dob, setDob] = useState('')
  

  const genderList:any = [
    {name: 'Male'},
    {name: 'Female'},
    {name: 'Others'},
  ]

  const idList:any = [
    {name:'International passport'},
    {name:'Drivers Lisense'},
    {name:'NIN'},
  ]

  // USEEFFECT FOR UPDATING INSURANCE
  useEffect(() => {
    if(isSuccess) {
      notifySuccess('Details succesfully updated')
      setIsDisplay('none')
    }
    if(isError && 'status' in error!) {
      notifyError(error.data.message)
    }
  }, [data, isSuccess, isError, error,])

  const handleUpdateInsurance = () => {
    if(!userCountry || !userState || !gender || !dob || !identification || !idNum) {
      notifyError('Please fill in all your information')
    }else {
      let formData: any = new FormData()

      formData.append('gender', gender)
      formData.append('dob', dob)
      formData.append('file', '')
      formData.append('id_number', idNum)
      formData.append('identification', identification)
      formData.append('country', userCountry)
      formData.append('city', userState)
      formData.append('id', id)
  
      updateInsurance(formData)
    }
  }

  const notifyError = (text: any) => toast.error(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  })

  const notifySuccess = (text: any) => toast.success(text, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  })

  
  return (
    <AlertWrapper>
        <ToastContainer />
        <div className="inner-wrapper">
            {/* <h3>{alertHeader}</h3> */}

            <div className="image-wrap">
                <img src={map}/>
            </div>

            <h3>Waiting for service provider to confirm</h3>

            <ProgressWrap>
              <ProgressBox>
                <div className="inner-progress-box">
                    <img src={car} />
                </div>
              </ProgressBox>
            </ProgressWrap>

            <p>
              Your request has been sent to the vehicle owner pending acceptance.
            </p>

            <InsuranceInfoBox display={insureDisplay}>
              <h4>Insurance</h4>

              <h6>Provide the following information for your insurance claim</h6>

              <InsuranceInfoFlexOverall>
                <form>
                  <label>Gender <span>*</span> </label>
                  <InsuranceDropdown 
                    selectType={'Select'}
                    list={genderList}
                    choice={gender}
                    setChoice={setGender}
                  />
                </form>

                <form>
                  <label>Date of Birth <span>*</span> </label>
                  <SelectInput>
                    <input type="date" value={dob} onChange={(e:any) => setDob(e.target.value)} />
                  </SelectInput>
                </form>
              </InsuranceInfoFlexOverall>

              <InsuranceInfoFlexOverall>
                <form>
                  <label>Means of ID  <span>*</span> </label>
                  <InsuranceDropdown 
                    selectType={'Select ID Type'}
                    list={idList}
                    choice={identification}
                    setChoice={setIdentification}
                  />
                </form>

                <form>
                  <label>ID Number <span>*</span> </label>
                  <SelectInput bg={'#fff'}>
                    <input type="text" placeholder='Type here...' value={idNum} onChange={(e:any) => setIdNum(e.target.value)} />

                  </SelectInput>
                </form>
              </InsuranceInfoFlexOverall>

              <AddImageSection>
                <h4>Skip to Add Image Later</h4>

                <AddImageBox>
                  <img src={license} />

                  <AddImageBtn>
                    <img src={camera} />
                  </AddImageBtn>
                </AddImageBox>
              </AddImageSection>

              <InsuranceInfoFlexOverall>
                <form>
                  <label>Country <span>*</span> </label>
                  <SelectInput bg={'#000'}>
                    <CountryDropdown
                      value={userCountry}
                      onChange={setUserCountry}
                    />
                  </SelectInput>
                </form>
              
                <form>
                  <label>City  <span>*</span> </label>
                  <SelectInput bg={'#000'}>
                      <RegionDropdown 
                        country={userCountry}
                        value={userState}
                        onChange={setUserState} 
                      />
                  </SelectInput>
                </form>
              </InsuranceInfoFlexOverall>
            </InsuranceInfoBox>

            {
              insureDisplay === "block" ? 
              <ButtonFooter btnBg={"#000"} onClick={handleUpdateInsurance}>
                  <h5>Submit</h5>
              </ButtonFooter> :

              <Link to={'/user-booking-page'}>
                <ButtonFooter btnBg={"#000"}>
                    <h5>{buttonText}</h5>
                </ButtonFooter>
              </Link>
            }

        </div>
    </AlertWrapper>
  )
}

export default TransactionAlertBox