import styled from 'styled-components'

export const Page = styled.div`
    position: relative;
    min-height: 100vh;
    width: 100vw;
    place-items: center;
    transition: .5s;
    background-color: #242321;
padding:1.3em;

`

export const AbsoluteDiv = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
height:100px;
border-radius:0px 0px 15px 15px;
background:#fff;
width:100%;
position: relative;
padding:0 1.5em;
margin-top:-60px;
`
export const IconDiv = styled.div`

font-size:32px;
font-weight:800;
padding:12px;
border-radius:12px;
background:#000;
color:#ccc;
display:flex;
align-items:center;
justify-content:center;

`
export const P = styled.p`
color:#fff;
font-size:12px;
margin-top:6px;
`
export const FlexCol = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
margin-top:135px;
`