export const colors = {
    primary: '#E25A5A',
    secondary: '#0A161C',
    text: '#FFFFFF50',
    white: '#FFFFFF',
    dark: '#FFFFFF0D'
  };
  
  export const lightTheme = {
    body: '#fff',
    text: '#242321',
    garageText: '#fff',
    driverCardBg: '#000',
    cardColorBg: '#000',
    cartFooterBg: '#fff',
    h12color: 'linear-gradient(to bottom, #000000 40%, #FFA100 100%)',
    h13color: 'linear-gradient(to bottom, #000000 -20%, #FFA100 100%)',
    iconColor: 'invert(100%) sepia(12%) saturate(7450%) hue-rotate(258deg) brightness(118%) contrast(114%)',
    cartColor: 'invert(85%) sepia(100%) saturate(2%) hue-rotate(252deg) brightness(103%) contrast(101%)',
    footerHeader: '#000',
    borderColor: '#242321',
    inputBg: '#242321',
    headerBg: '#000',
    subText: '#00000050',
    toggledBorder: '#fff',
    backgroundColor: '#0A161C20',
    darkBg: '#08192320',
    light: '#ffffff1a',
    primary: '#E25A5A',
    test: 'green',
    black: '#000',
    shadowBox: '0px 0px 7px 1px rgba(0,0,0,0.13)',
  };
  
  export const darkTheme = {
    body: '#242321',
    text: '#fff',
    cardColorBg: '#fff',
    cartFooterBg: '#000',
    garageText: '#4F4F4F',
    driverCardBg: 'rgba(0, 0, 0, 0.7)',
    h12color: '-webkit-linear-gradient(#fff, rgba(255, 161, 0, 0.83))',
    h13color: 'rgba(255, 161, 0, 0.93)',
    iconColor: 'invert(0%) sepia(0%) saturate(0%) hue-rotate(320deg) brightness(89%) contrast(106%)',
    cartColor: 'invert(0%) sepia(0%) saturate(0%) hue-rotate(320deg) brightness(89%) contrast(106%)',
    footerHeader: '#fff',
    borderColor: '#fff',
    headerBg: '#fff',
    inputBg: '#fff',
    subText: '#FFFFFF50',
    toggledBorder: '#6b8096',
    backgroundColor: '#FFFFFF0D',
    darkBg: '#081923',
    light: '#ffffff1a',
    primary: '#E25A5A',
    lightBg: '#16242B',
    cardBg: '#253339',
    offWhite: '#ffffff80',
    offBlue: '#23353F',
    test: 'red',
    black: '#000',
    shadowBox: 'none',
  };