import React, { useCallback, useEffect, useState } from 'react'
import { AmountSectionWrap, SelectOptionBox, SelectTypeWrap, SelectYellowBox } from '../swapWalletComponent/swapwallet.styles'
import { H22, PaddingWrap, SaveBankBox, SaveBankOverall, WithdrawError, WithdrawWrap } from './withdrawwallet.styles'
import { FlexInputWrap, GreyBox } from '../fundWalletThree/fundwalletthree.styles'


import arrow from '../../assets/images/svg/arrow-down-vector.svg'
import creditCard from '../../assets/images/svg/credit-card.svg'
import person from  '../../assets/images/svg/person-icon.svg'
import withdraw from '../../assets/images/svg/withdraw-icon.svg'
import { SubmitBtn } from '../toggleBar/togglebar.styles'
import CurrencyInput from 'react-currency-input-field'
import Dropdown from '../dropdown'
import { useVerifyAccountMutation } from '../../services/walletApi'
import { formatCurrencyPrefix } from '../../services/utils'
import { useAppSelector } from '../../store'


const WithdrawWalletComponent = ({displaybox, handleShowComponent, onWithdraw, withdrawAmount, setWithdrawAmount, withdrawBank, setWithdrawBank, withdrawAccountNo, setWithdrawAccountNo, withdrawAccountName, setWithdrawAccountName, withdrawErrorMessage, setBankCode, bankCode}:any) => {
    const [verifyAccount, { data, isLoading, isSuccess, isError, error }] = useVerifyAccountMutation();

    const [userAccountName, setUserAccountName] = useState([])

    useEffect(() => {
        if(isSuccess) {
            setUserAccountName(data?.data)
            setWithdrawAccountName(data?.data?.accountName)
        }
    }, [data, isSuccess, isError, error, isLoading])


    const onVerifyAcct = () => {
        let formData:any = new FormData();

        formData.append("account_number", withdrawAccountNo)
        formData.append("bank_name", withdrawBank);
        formData.append("bank_code", bankCode);


        verifyAccount(formData)
    }

    useEffect(() => {
        if(withdrawBank && bankCode && withdrawAccountNo) {
            onVerifyAcct()
        }
    }, [withdrawBank, bankCode])
    const currentLocation = useAppSelector(
        (state: any) => state?.currentLocation?.currentLocation?.country
      );

  return (
    <WithdrawWrap display={displaybox}>
        <PaddingWrap>
            <SelectTypeWrap>
                <AmountSectionWrap>
                    <label>Enter Amount</label>
                    {/* <input type="text" placeholder='0.00' value={withdrawAmount} onChange={(e:any) => setWithdrawAmount(e.target.value)} /> */}
                    <CurrencyInput 
                    decimalsLimit={2}
                    prefix={formatCurrencyPrefix(currentLocation)}

                    placeholder='0.00'
                    value={withdrawAmount}
                    onValueChange={(value) => setWithdrawAmount(value)}
                    />
                </AmountSectionWrap>

                {/* <SelectOptionBox>
                    <p>Select Wallet</p>
                    <SelectYellowBox>
                        <img src={arrow}  />
                    </SelectYellowBox>
                </SelectOptionBox> */}

                <div className="absolute-txt">
                    This transaction fee is 0.05%
                </div>
            </SelectTypeWrap>
        </PaddingWrap>


        <H22>Withdraw to</H22>

        <GreyBox>
            <FlexInputWrap>
                <input type="text" placeholder='Account Number' value={withdrawAccountNo} onChange={(e:any) => setWithdrawAccountNo(e.target.value)} />
                <img src={creditCard}  />
            </FlexInputWrap>

            <Dropdown setWithdrawBank={setWithdrawBank} withdrawBank={withdrawBank} bankCode={bankCode} setBankCode={setBankCode} />

            <FlexInputWrap>
                <input type="text" placeholder='Account Name' value={withdrawAccountName} onChange={(e:any) => setWithdrawAccountName(e.target.value)} />
                <img src={person}  />
            </FlexInputWrap>

            {/* <SaveBankOverall>
                <SaveBankBox>
                    <p>Save Beneficiary</p>
                </SaveBankBox>
            </SaveBankOverall> */}
        </GreyBox>

        <SubmitBtn onClick={onWithdraw}>
            <p>Withdraw</p>
            <WithdrawError>{withdrawErrorMessage}</WithdrawError>
        </SubmitBtn>
    </WithdrawWrap>
  )
}

export default WithdrawWalletComponent