import React, { useState, useRef, useEffect } from 'react'
import "./styles/BlogEdit.css"
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation';

import camera from "../../../assets/images/svg/camera.svg"
import likeIcon from "../../../assets/images/svg/likeFilled.svg"
import car from "../../../assets/images/svg/car-filter.svg"
import driver from "../../../assets/images/svg/driver-filter.svg"
import travel from "../../../assets/images/svg/travel-filter.svg"
import owner from "../../../assets/images/svg/owner-filter.svg"
import author from "../../../assets/images/svg/author.svg"
import videoBtn from "../../../assets/images/svg/video-btn.svg"
import textBtn from "../../../assets/images/svg/text-btn.svg"
import imageBtn from "../../../assets/images/svg/img-btn.svg"
import cancelBtn from "../../../assets/images/svg/cancel-icon-yellow.svg"
import spin from "../../../assets/images/gif/spinner.gif"

import uuid from 'react-uuid';

import Footer from '../../../components/footer'
import Header from '../../../components/header'
import ShareArticle from '../../../components/sharearticle'
import ReviewCard from '../../../components/reviewcard'
import { useCreateBlogPostMutation, useEditBlogPostMutation, useGetBlogCommentsQuery, useGetBlogLikesQuery, useGetBlogPostQuery } from '../../../services/blogApi'
import InputText from '../../../components/inputText'
import InputVideo from '../../../components/inputVideo'
import InputImage from '../../../components/inputImage'
import Modal from '../../../components/modal'
import {useParams} from 'react-router-dom'
import {ContentState, convertToRaw, EditorState} from "draft-js"
import htmlToDraft from 'html-to-draftjs'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import { ToastContainer, toast} from 'react-toastify'
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Navigation } from 'swiper';






const BlogEdit = () => {
    const params = useParams()
    // USESTATE
    const [coverImage, setCoverImage] = useState<any | undefined>()
    const [coverImageReceived, setCoverImageReceived] = useState("")
    const [authorImage, setAuthorImage] = useState<any | undefined>()
    const [authorImageReceived, setAuthorImageReceived] = useState("")
    const [blogData, setBlogData] = useState<any>()
    const [children, setChildren] = useState<any[]>([])
    const [blogCommentData, setBlogCommentData] = useState<any[]>([])
    const [blogCategory, setBlogCategory] = useState("")
    const [authorName, setAuthorName] = useState("")
    const [authorTitle, setAuthorTitle] = useState("")
    const [blogTitle, setBlogTitle] = useState("")
    const [textInput, setTextInput] = useState("")
    const [imageContent, setImageContent] = useState<any | undefined>()
    const [blogId, setBlogId] = useState(1)
    const [likesCount, setLikesCount] = useState<any>()
    const [heading, setHeading] = useState('')
    const [activeClass, setActiveClass] = useState("")
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [content, setContent] = useState("")
    const [id, setId] = useState("")
    const [picture, setPicture] = useState("")
    const [btnLoad, setBtnLoad] = useState(false)

  
    const [saving, setSaving] =  useState(false)
    const [loading, setLoading] = useState(true)

    const blogLink = params.bloglink


    // RTK ENDPOINTS
    const [editBlogPost, { data, isLoading, isSuccess, isError, error }] = useEditBlogPostMutation();
    const {data: likeData, isFetching, refetch} = useGetBlogLikesQuery(blogId)
    const {data: blogPostData, isFetching: isFetchingBlogPost, refetch: refetchBlogPost} = useGetBlogPostQuery(blogLink,{refetchOnMountOrArgChange: true})
    const {data: blogPostComments, isFetching: isFetchingBlogComments, refetch: refetchBlogComments} = useGetBlogCommentsQuery(blogData?.id, {refetchOnMountOrArgChange: true})


    // USEFFECT
    useEffect(() => {
        refetch()
    }, [blogId])

    useEffect(() => {
        refetchBlogPost()
        setBlogData(blogPostData?.data[0])
    }, [blogPostData, blogId, likeData, params])

    useEffect(() => {
        setBlogCategory(blogData?.category)
        setCoverImageReceived(blogData?.post_image)
        setAuthorImageReceived(blogData?.author_image)
        // setEditorState(_editorState)
    }, [blogData])

    useEffect(() => {
        refetchBlogComments()
        setBlogCommentData(blogPostComments?.data)
    }, [blogPostComments, blogPostData, params])

    useEffect(() => {
        setTimeout(() => {
            const contentBlock = htmlToDraft(blogData?.content)
            console.log(contentBlock, 'lol')
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
            const _editorState =  EditorState.createWithContent(contentState)
            setEditorState(_editorState)
        }, 10000)
    }, [blogData])

    useEffect(() => {
        if (isLoading) {
            setBtnLoad(true)
        }
        if (isSuccess) {
            setActiveClass("active-modal")
            setBtnLoad(false)
        }
        if (isError && 'status' in error!) {
            notifyError(error.data.message)
            setBtnLoad(false)
        }
    }, [isLoading, isSuccess, isError, data ])



    // USEREF
    const coverImgRef: any = useRef()
    const authorImgRef: any = useRef()

    // FUNCTIONS
    const addImgOutput = () => {
        // this is basically calling/recognizing the contents in the state and adding the component to it
        setChildren(children => [...children, { prop: <InputImage  setImageContent={setImageContent}/>, id: uuid() }])
    }

    const addTextOutput = () => {
        setChildren(children => [...children, { prop: <InputText setTextInput={setTextInput} />, id: uuid() }])
    }

    const addVidOutput = () => {
        setChildren(children => [...children, { prop: <InputVideo />, id: uuid() }])
    }

    const imageExist = children.some(child => child.prop.type.name === "InputImage")
    const textExist = children.some(child => child.prop.type.name === "InputText")
    const videoExist = children.some(child => child.prop.type.name === "InputVideo")

    const coverImgChange = async (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            setCoverImage(file)
        } else {
            setCoverImage(null)
        }
    }

    const handleAuthorImg = (e:any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            setAuthorImage(file)
        } else {
            setAuthorImage(null)
        }
    }


    const removeCoverImg = () => {
        setCoverImage(null)
        setCoverImageReceived("")
    }

    // PUBLISH BLOG
    const handleSubmit = () => {
        let formData: any = new FormData()

        formData.append('title', blogTitle || blogData?.title)
        formData.append('content', textInput)
        formData.append('heading', heading || blogData?.heading)
        formData.append('cover_image', coverImage)
        formData.append('category', blogCategory || blogData?.category)
        formData.append('post_image', imageContent)
        formData.append('author_name', authorName || blogData?.author_name)
        formData.append('author_role', authorTitle || blogData?.author_role)
        formData.append('author_image', authorImage)
        formData.append('id', blogData?.id)

        if(textInput.length === 0) {
            notifyError('Please you cannot update with an empty content ')
        }else if(!coverImage) {
            notifyError('Please upload a cover image')
        }else if(!authorImage) {
            notifyError("Please upload auhtor's image ")
        }else {
            editBlogPost({id: blogData?.id, post: formData})
        }

    }


    // REMOVING EITHER IMAGE VIDEO OR TEXT COMPONENT FORM CHILDREN ARRAY
    const removeChild = (val: string) => {
        setChildren(children => children.filter(child => {
            return child.id !== val
        }))
    }

    const notifyError = (text: any) => toast.error(text, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })

    return (
        <div className='blogEditPage'>
            <ToastContainer />
            <Modal activeClass={activeClass}/>
            <Header />

            <div className="cover-image-section">
                <div className="cover-inner">

                    {
                        !coverImage || !coverImageReceived && <div className="cover-inner-overlay"></div>
                    }


                    {
                        !coverImage || !coverImageReceived &&
                        <div className="upload-action-box">
                            <div className="action-btn" onClick={(e) => {
                                e.preventDefault()
                                coverImgRef.current.click()
                            }}>
                                <img src={camera} alt="" />
                                <p>Upload Cover Image</p>
                            </div>
                        </div>
                    }

                    {
                        coverImage ?
                        <div className="cover-img-wrapper">
                                <img src={URL.createObjectURL(coverImage)} alt="Cover " /> 
                                <div className="upload-action-box">
                                    <div className="action-btn" onClick={(e) => {
                                        e.preventDefault()
                                        coverImgRef.current.click()
                                    }}>
                                        <img src={camera} alt="" />
                                        <p>Upload Cover Image</p>
                                    </div>
                                </div>
                        </div> :

                        <div className="cover-img-wrapper">
                            <img src={`https://api.roadlers.com/storage/${coverImageReceived}`} alt="Cover " />

                            <div className="upload-action-box">
                                <div className="action-btn" onClick={(e) => {
                                    e.preventDefault()
                                    coverImgRef.current.click()
                                }}>
                                    <img src={camera} alt="" />
                                    <p>Upload Cover Image</p>
                                </div>
                            </div>
                        </div>
                    }

                    <input type="file" className='cover-image-input' accept='image/*' ref={coverImgRef} onChange={coverImgChange} />

                    <div className="input-date">
                        <p></p>
                    </div>
                </div>


                <div className="blogpost-header-container">
                    <div className="blogpost-title">
                        <input type="text" placeholder='Type hook line here...' defaultValue={blogData?.heading || heading}  onChange={(e) => setHeading(e.target.value)}/>
                    </div>

                    <div className="likes">
                        <button>
                            <img src={likeIcon} alt="" />
                        </button>


                        <div className="likes-count">
                            <p>{!blogData?.likes ? 0 : blogData?.likes }  likes</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-category-filter">
                <div className="inner-category-filter">
                    <button onClick={() => setBlogCategory("cars")} className={blogCategory === "cars" ? "active-button" : ""}>
                        <img src={car} alt="" />
                        <p>Cars</p>
                    </button>

                    <button onClick={() => setBlogCategory("drivers")} className={blogCategory === "drivers" ? "active-button" : ""}>
                        <img src={driver} alt="" />
                        <p>Drivers</p>
                    </button>

                    <button onClick={() => setBlogCategory("travel")} className={blogCategory === "travel" ? "active-button" : ""}>
                        <img src={travel} alt="" />
                        <p>Travel</p>
                    </button>

                    <button onClick={() => setBlogCategory("ownership")} className={blogCategory === "ownership" ? "active-button" : ""}>
                        <img src={owner} alt="" />
                        <p>Ownership</p>
                    </button>
                </div>
            </div>

            <div className="blogUpload-body">
                <div className="inner-upload-wrapper">
                    <div className="blogpost-topic">
                        <input type="text" placeholder='Type in topic here' defaultValue={blogData?.title || blogTitle} onChange={(e) =>  setBlogTitle(e.target.value)} />
                    </div>

                    <div className="blogpost-content">
                        <div className="">
                            <div className="" style={{ position: "relative", marginTop: "100px" }}>
                                <div className="input-text-wrapper">
                                    <div className="input-text-inner-wrapper">
                                        <Editor
                                            editorState={editorState}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            
                                            onEditorStateChange={(newState: any) => {
                                                setEditorState(newState);
                                                setTextInput(draftToHtml(convertToRaw(newState?.getCurrentContent())))
                                            }}
                                            toolbar={{
                                                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                                                inline: { inDropdown: true },
                                                list: { inDropdown: true },
                                                textAlign: { inDropdown: true },
                                                link: { inDropdown: true },
                                                history: { inDropdown: true },
                                                image: { inDropdown: true },
                                                blockType: {
                                                    inDropdown: true,
                                                    options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                                                    className: undefined,
                                                    component: undefined,
                                                    dropdownClassName: undefined,
                                                  },
                                                  fontSize: {
                                                    options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
                                                    className: undefined,
                                                    component: undefined,
                                                    dropdownClassName: undefined,
                                                  },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className={children?.length === 0 ? "upload-btns-wrapper absolute-btn-wrapper" : "upload-btns-wrapper relative-btn-wrapper"}>
                            <div className="inner-upload-wrapper">
                                {
                                    imageExist ?
                                        <div className="btn-wrapper no-shadow" >
                                            <img src={imageBtn} alt="" />
                                            <p>Image</p>
                                        </div> :
                                        <div className="btn-wrapper" onClick={() => addImgOutput()}>
                                            <img src={imageBtn} alt="" />
                                            <p>Image</p>
                                        </div>
                                }

                                {
                                    videoExist ?
                                        <div className="btn-wrapper no-shadow" >
                                            <img src={videoBtn} alt="" />
                                            <p>Video</p>
                                        </div>:
                                        <div className="btn-wrapper" onClick={() => addVidOutput()}>
                                            <img src={videoBtn} alt="" />
                                            <p>Video</p>
                                        </div>
                                }

                                {
                                    textExist ?
                                    <div className="btn-wrapper no-shadow">
                                        <img src={textBtn} alt="" />
                                        <p>Text</p>
                                    </div>:
                                    <div className="btn-wrapper" onClick={() => addTextOutput()}>
                                        <img src={textBtn} alt="" />
                                        <p>Text</p>
                                    </div>
                                }

                            </div>
                        </div> */}
                    </div>

                    <div className="author-info-container">
                        <div className="inner-author-wrapper">
                        <input type="file" className='author-image-input' accept='image/*' ref={authorImgRef} onChange={handleAuthorImg} />
                            <div className="author-img-wrapper" onClick={(e) => {
                                e.preventDefault()
                                authorImgRef.current.click()
                            }}>
                                
                                {
                                    authorImage ?  <img src={URL.createObjectURL(authorImage)} alt="author" /> :
                                        <img src={`https://api.roadlers.com/storage/${authorImageReceived}`} alt="author" /> 
                                }

                                {
                                    !authorImage || !authorImageReceived && 
                                    <img src={author} alt="author" />
                                }

                                <img src={camera} alt="camera" className='author-camera' />
                            </div>
                            

                            <div className="text">
                                <input className='author-name' type="text" placeholder='Author’s Name' defaultValue={blogData?.author_name || authorName} onChange={(e) => setAuthorName(e.target.value)} />
                                <input className='title-name' type="text" placeholder='Title' defaultValue={blogData?.author_role || authorTitle} onChange={(e) => setAuthorTitle(e.target.value)}/>
                            </div>
                        </div>

                        <div className="publish-btn">
                            <button onClick={() => handleSubmit()}>
                                {
                                    btnLoad ? 
                                    <img src={spin} alt="" />: <p>Publish</p>
                                }
                            </button>
                        </div>
                    </div>

                    {/* <ShareArticle /> */}

                    <div className="ratings-review-wrapper">
                    <Swiper
                        freeMode={true}
                        grabCursor={true}
                        modules={[FreeMode, Navigation]}
                        navigation
                        className='mySwiper'
                        breakpoints={{
                            0: {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            }

                        }}
                    >
                        {
                            blogCommentData?.map((comment) => (
                                <SwiperSlide key={comment?.id}>
                                    <ReviewCard 
                                        initial={comment?.name.charAt(0).toUpperCase()}
                                        firstName={comment?.name}
                                        comments={comment?.comment}
                                    />
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default BlogEdit