import styled from 'styled-components'

export const Div = styled.div`
display:flex;
align-items:flex-start;
flex-direction:column;
justify-content:start;
width:100%;
margin-top:20px;
padding:.5em;
`
export const H3 = styled.h3`
color:#fff;
padding:0px .8em;
margin-bottom:10px;
`
export const H4 = styled.h4`
color:#fff;
padding:0px .8em;
font-weight:bold;
font-size:18px;
`
export const H5 = styled.h5`
color:#000;
font-weight:700;
font-size:14px;

`

export const FlexDiv = styled.div`
 display:flex;
padding:0px .8em;
`
export const FlexBox = styled.div`
 display:flex;
 background:#fff;
padding:0px .8em;
height:43px;
align-items:center;
gap:10px;
border-radius:5px;

`

export const Label = styled.label`
color:#fff;
font-weight:400;
font-size:10.5px;
`
export const FlexBetween = styled.div`
 display:flex;
 justify-content:space-between;
 align-items:center;
padding:0em .7em;
background:#fff;
position:absolute;
bottom:-20px;
right:20px;
border-radius:5px;
gap:10px;
height:43px;
align-items:center;
`
export const BalanceDiv = styled.div`
background:#000;
height:150px;
width:100%;
margin-top:20px;
border-radius:10px;
position:relative;
`
export const Icondiv = styled.div`
 width: 18%;
 background: #09051C;
 color: #fff; 
 padding: .4em;
border: none;
borderRadius: 5px;
display:flex;
align-items:center;
justify-content:center;
position:relative;

`
