import React, { FC, useEffect, useState, useRef } from "react";
// import ToggleBar from '../../../components/toggleBar'
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import {
  VerifyButton,
  DISCOUNT,
  INPUTDIV,
  BLACKDIV,
  FLEXDIV,
  P,
  Input,
  MARKDIV,
  Wrapper,
  BlackOpacity,
  Text,
  DropDown,
  Label,
  LabelTitle,
  SubmitButton,
  SubmitDiv,
} from "./regularRates.styles";
import {
  useAddNewCarPriceMutation,
  useCarPriceUpdateMutation,
  useCarUpdateMutation,
} from "../../../services/providerApi";
import DiscountModal from "../discountModal";
import CurrencyInput from "react-currency-input-field";
import { formatCurrencyPrefix } from "../../../services/utils";
import { useAppSelector } from "../../../store";

type Props = {
  vehicleProfile: any;
};
const RegularRates: FC<Props> = ({ vehicleProfile }) => {
  const currentLocation = useAppSelector(
    (state: any) => state?.currentLocation?.currentLocation?.country
  );
  const vehicleId = vehicleProfile?.data[0]?.id;
  const userId = vehicleProfile?.data[0]?.user_id;
  const serviceRates = [
    {
      price: 0,
      hour: 12,
      discount: 0,
      service_title: "Full Day Service",
      user_id: userId,
      vehicle_id: vehicleId,
    },
    {
      price: 0,
      hour: 6,
      discount: 0,
      service_title: "Half Day Service",
      user_id: userId,
      vehicle_id: vehicleId,
    },
    {
      price: 0,
      hour: 1,
      discount: 0,
      service_title: "Hourly Rate",
      user_id: userId,
      vehicle_id: vehicleId,
    },

    {
      price: 0,
      discount: 0,
      service_title: "Airport Pickup",
      user_id: userId,
      vehicle_id: vehicleId,
    },
    {
      price: 0,
      hour: 1,
      discount: 0,
      service_title: "Full Month Service",
      user_id: userId,
      vehicle_id: vehicleId,
    },
    {
      price: 0,
      hour: 1,
      discount: 0,
      service_title: "Half Month Service",
      user_id: userId,
      vehicle_id: vehicleId,
    },
  ];

  const [updateVehiclePrice, { data, isLoading, isSuccess, isError, error }] =
    useCarPriceUpdateMutation();
  const [
    addNewVehiclePrice,
    {
      data: newData,
      isLoading: newIsLoading,
      isSuccess: newIsSucess,
      isError: newIsError,
      error: newError,
    },
  ] = useAddNewCarPriceMutation();

  const [vehicleInfo, setVehicleInfo] = useState(vehicleProfile?.data[0]);

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);

  const [isDiscountVisible, setIsDiscountVisible] = useState(false);
  const [priceList, setPriceList] = useState({});

  const [price, setPrice] = useState("");
  const [hour, setHour] = useState("");

  let RegularRatesPrice =
    vehicleInfo?.service_price?.filter(function (data: any) {
      return data.service_title !== "Full Month Service";
    }) || [];

  // const handlePrice = (event: any, servicePrice: any): any => {
  //     setShowSaveButton(true)
  //     const updatedServiceData = vehicleInfo?.service_price.map((data: any) => {
  //         setPrice(event);

  //         if (data.id === servicePrice.id) {
  //             setPrice(event);
  //             return {
  //                 ...data,
  //                 price: event
  //             };

  //         }
  //         return data;
  //     });
  //     setVehicleInfo({
  //         ...vehicleInfo, service_price: updatedServiceData
  //     })

  // }

  const updatePrice = (updatedRate, price) => {
    setShowSaveButton(true);

    const updatedServiceData = combinedRates.map((data: any) => {
      if (data.service_title === updatedRate.service_title) {
        setPrice(price);
        return {
          ...data,
          price: price,
        };
      }
      return data;
    });
    setVehicleInfo({
      ...vehicleInfo,
      service_price: updatedServiceData,
    });
  };

  const handleIncrease = (servicePrice: any) => {
    setShowSaveButton(true);

    const updatedServiceData = vehicleInfo?.service_price?.map((data: any) => {
      if (data.id === servicePrice.id) {
        if (servicePrice?.service_title === "Full Day Service") {
          const currentHour = Number(data?.hour);
          const newHour = currentHour + 1;
          const updatedHour = newHour <= 20 ? newHour : currentHour;
          return {
            ...data,
            hour: updatedHour,
          };
        }
        if (servicePrice?.service_title === "Half Day Service") {
          const currentHour = Number(data?.hour);
          const newHour = currentHour + 1;
          const updatedHour = newHour <= 10 ? newHour : currentHour;
          return {
            ...data,
            hour: updatedHour,
          };
        }
        if (servicePrice?.service_title === "Hourly Rate") {
          const currentHour = Number(data?.hour);
          const newHour = currentHour + 1;
          const updatedHour = newHour <= 5 ? newHour : currentHour;
          return {
            ...data,
            hour: updatedHour,
          };
        }
      }
      return data;
    });

    setVehicleInfo({
      ...vehicleInfo,
      service_price: updatedServiceData,
    });
  };

  const handleDecrease = (servicePrice: any) => {
    setShowSaveButton(true);

    const updatedServiceData = vehicleInfo?.service_price?.map((data: any) => {
      if (data.id === servicePrice.id) {
        if (servicePrice?.service_title === "Full Day Service") {
          const currentHour = Number(data?.hour);
          const newHour = currentHour - 1;
          const updatedHour = newHour >= 12 ? newHour : currentHour;
          return {
            ...data,
            hour: updatedHour,
          };
        }
        if (servicePrice?.service_title === "Half Day Service") {
          const currentHour = Number(data?.hour);
          const newHour = currentHour - 1;
          const updatedHour = newHour >= 10 ? newHour : currentHour;
          return {
            ...data,
            hour: updatedHour,
          };
        }
        if (servicePrice?.service_title === "Hourly Rate") {
          const currentHour = Number(data?.hour);
          const newHour = currentHour - 1;
          const updatedHour = newHour >= 3 ? newHour : currentHour;
          return {
            ...data,
            hour: updatedHour,
          };
        }
      }
      return data;
    });

    setVehicleInfo({
      ...vehicleInfo,
      service_price: updatedServiceData,
    });
  };

  // useEffect(() => {
  //     saveData()
  // }, [])

  const combinedRates = [
    ...RegularRatesPrice,
    ...serviceRates.filter((defaultRate) => {
      return (
        !RegularRatesPrice.some(
          (existingRate) =>
            existingRate.service_title === defaultRate.service_title
        ) && defaultRate.service_title !== "Full Month Service"
      );
    }),
  ];

  const newRate = combinedRates.filter((item) => !item.hasOwnProperty("id"));
  const oldRate = combinedRates.filter((item) => item.hasOwnProperty("id"));

  const saveData = () => {
    setIsSaveLoading(true);

    //@ts-ignore
    updateVehiclePrice(oldRate)
      .then((response: any) => {
        setIsSaveLoading(false);
        setShowSaveButton(false);
      })
      .catch((err) => {
        setIsSaveLoading(false);
      });

    if (newRate) {
      //@ts-ignore
      addNewVehiclePrice(newRate)
        .then((response: any) => {
          setIsSaveLoading(false);
          setShowSaveButton(false);

          // setProviderDetails(response?.data)
        })
        .catch((err) => {
          setIsSaveLoading(false);
        });
    }
  };

  return (
    <>
      <LabelTitle>Regular Rates</LabelTitle>

      {combinedRates.map((rate) => (
        <FLEXDIV key={rate.service_title}>
          <INPUTDIV>
            <label
              style={{
                color: "#868686",
                display: "flex",
                width: "40%",
                fontSize: "13px",
                fontWeight: "normal",
              }}
              htmlFor=""
            >
              {rate.service_title}
            </label>
            {rate.service_title !== "Airport Pickup" &&
              rate.service_title !== "Half Month Service" && (
                <VerifyButton>
                  <P style={{ left: "0px", top: "-37px" }}>Hours</P>
                  <Text>{rate.hour || rate.months || 0}</Text>
                  <DropDown>
                    <IoIosArrowUp
                      style={{
                        color: "#FFD58C",
                        fontSize: 15,
                        fontWeight: 600,
                      }}
                      onClick={() => handleIncrease(rate)}
                    />
                    <IoIosArrowDown
                      style={{
                        color: "#FFD58C",
                        fontSize: 15,
                        fontWeight: 600,
                      }}
                      onClick={() => handleDecrease(rate)}
                    />
                  </DropDown>
                </VerifyButton>
              )}
            <BLACKDIV>
              <P style={{ left: "0px", top: "-37px" }}>Set Price</P>
              <Input>
                <CurrencyInput
                  style={{
                    background: "transparent",
                    border: "none",
                    outline: "none",
                    color: "#fff",
                  }}
                  decimalsLimit={2}
                  prefix={formatCurrencyPrefix(currentLocation)}
                  placeholder="0.00"
                  value={rate.price}
                  // onValueChange={(e) => handlePrice(e, rate)}
                  onValueChange={(e: any) => updatePrice(rate, e)}
                />
              </Input>
              <DISCOUNT
                onClick={() => {
                  setIsDiscountVisible(true);
                  setPriceList(rate);
                  setShowSaveButton(true);
                }}
              >
                <P style={{ left: "0px", top: "-45px" }}>Discount</P>
                {rate.discount}%
              </DISCOUNT>
            </BLACKDIV>
          </INPUTDIV>
        </FLEXDIV>
      ))}

      {isDiscountVisible && (
        <DiscountModal
          priceList={priceList}
          setServiceData={setVehicleInfo}
          serviceData={vehicleInfo}
          setIsDiscountVisible={setIsDiscountVisible}
        />
      )}

      {showSaveButton && (
        <SubmitDiv>
          {isSaveLoading ? (
            <SubmitButton>Updating Pricing...</SubmitButton>
          ) : (
            <SubmitButton onClick={() => saveData()}>
              Save and Update Pricing
            </SubmitButton>
          )}
        </SubmitDiv>
      )}
    </>
  );
};
export default RegularRates;
