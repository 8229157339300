import React, { FC, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../store';
import { setPageNumber } from '../../../slices/admin';
import { PaginationButton, PaginationDiv, PaginationLabel } from '../Drivers/adminDrivers.styles';

type Props = {
    data?: any;

}
const AdminPagination: FC<Props> = ({ data }) => {

    console.log("data",data)
    const dispatch: any = useAppDispatch()
    const pageNumberStore: number = useAppSelector((state: any) => state?.admin?.currentPage)
    const [currentPage, setCurrentPage] = useState<number>(pageNumberStore);
    const ITEMS_PER_PAGE = 10; // Number of items to display per page
    const totalPages = Math.ceil(data?.length / ITEMS_PER_PAGE);

    // Calculate the range of pages to display in the pagination bar
    const pageRange = 3; // Adjust this value to control the number of page links displayed

    const getPageNumbers = () => {
        const pageNumbers = [];
        if (totalPages <= pageRange * 2 + 1) {
            for (let i = 1; i <= totalPages; i++) {
                //@ts-ignore
                pageNumbers.push(i);
            }
        } else {
            const startPage = Math.max(currentPage - pageRange, 1);
            const endPage = Math.min(currentPage + pageRange, totalPages);

            if (startPage > 1) {
                //@ts-ignore
                pageNumbers.push(1, '...'); // Add ellipsis if not on the first page
            }

            for (let i = startPage; i <= endPage; i++) {
                //@ts-ignore
                pageNumbers.push(i);
            }

            if (endPage < totalPages) {
                //@ts-ignore
                pageNumbers.push('...', totalPages); // Add ellipsis if not on the last page
            }
        }
        return pageNumbers;
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
        setPageNumber(page)
    };
    
    const renderPagination = () => {
        const pageNumbers = getPageNumbers();

        return (
            <PaginationDiv>
                {pageNumbers.map((page, index) => (
                    <PaginationButton
                        key={index}
                        className={`page-item ${page === '...' || page === currentPage ? 'disabled' : ''
                            }`}
                        disabled={page === currentPage}
                    >
                        {page === '...' ? (
                            <span className="page-link">...</span>
                        ) : (
                            <PaginationLabel

                                onClick={() => handlePageClick(page)}
                            >
                                {page}
                            </PaginationLabel>
                        )}
                    </PaginationButton>
                ))}
            </PaginationDiv>
        );
    };

    // Calculate the start and end indices for the current page
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    // Slice the data to display only the items for the current page
    // const currentData = data?.slice(startIndex, endIndex);



    return (
        <>
            {renderPagination()}
        </>
    )
}

export default AdminPagination