import React, { FC, useEffect, useState } from 'react'
// import { Box, Button, DiscountBox, FlexBox, IconBox, ImageBox, Input, InputBox, Label, ModalContainer } from './index.styles'


import modalOption from '../../../assets/images/png/modalOption.png'
import add from '../../../assets/images/svg/triangle-up.svg'
import subtract from '../../../assets/images/svg/triangle-down.svg'
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { Modal, Option, Text } from '../../providerCarReg/firstScreen/firstScreen.styles'
import { ModalContainer, Label } from '../../discountModal/index.styles';
import { useCarUpdateMutation } from '../../../services/providerApi';



type Props = {
    setIsVisible?: any;
    setVehicleInfo: any;
    vehicleInfo: any;
    setIsCategoryVisible?:any;
};
export const FirstModal: FC<Props> = ({ vehicleInfo, setVehicleInfo, setIsVisible }) => {

    const [updateVehicle, { data, isLoading, isSuccess, isError, error }] = useCarUpdateMutation()

    const handleUsage = (purpose: string) => {

        setVehicleInfo({
            ...vehicleInfo,
            vehicle_for: purpose
        });
        let obj = {
            'vehicle_id': vehicleInfo?.id,
            'vehicle_name': vehicleInfo?.vehicle_name,
            'brand_name': vehicleInfo?.brand_name,
            'plate_number': vehicleInfo?.plate_number,
            'num_passengers': vehicleInfo?.num_passengers,
            'vehicle_location': vehicleInfo?.vehicle_location,
            'vehicle_type': vehicleInfo?.vehicle_type,
            'year': vehicleInfo?.year,
            'vehicle_for': purpose,
            'file': vehicleInfo?.image1,
            'file2': vehicleInfo?.image2,
            'file3': vehicleInfo?.image3,
            'file5': vehicleInfo?.image5,
        }
        //@ts-ignore
        updateVehicle([obj])
        setIsVisible(false)
    };
    return (

        <ModalContainer onClick={() => setIsVisible(false)}>
            {/* 
            <Box >
              
            </Box> */}

            <Modal onClick={(e: any) => e.stopPropagation()} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
                <Label style={{ width: '100%' }} onClick={() => handleUsage('Rental Only')}>
                    <Option>
                        <input type="radio" name="vehicle_for" value="Rental only" />
                        <Text> Rental only</Text>
                    </Option>
                </Label>

                <Label style={{ width: '100%' }} onClick={() => handleUsage('Deliveries Only')}>
                    <Option>
                        <input type="radio" name="vehicle_for" value="Deliveries only" />
                        <Text>Deliveries only</Text>
                    </Option>
                </Label>




                <Label style={{ width: '100%' }} onClick={() => handleUsage('Rental and delivery')}>
                    <Option>
                        <input
                            type="radio"
                            value="Rental and delivery"
                            name="vehicle_for"
                        />
                        <Text>Rental and delivery</Text>
                    </Option>
                </Label>

                <Label style={{ width: '100%' }} onClick={() => handleUsage('Insurance Only')}>
                    <Option>
                        <input
                            type="radio"
                            value="Insurance only"
                            name="vehicle_for"

                        />
                        <Text>Insurance only</Text>
                    </Option>
                </Label>

                <Label style={{ width: '100%' }} onClick={() => handleUsage('Travel Only')}>
                    <Option>
                        <input
                            type="radio"
                            value="Travel only"
                            name="vehicle_for"

                        />
                        <Text>Travel only</Text>
                    </Option>
                </Label>



            </Modal>
        </ModalContainer>

    )
}

export const SecondModal: FC<Props> = ({ vehicleInfo, setVehicleInfo, setIsCategoryVisible }) => {

    const [updateVehicle, { data, isLoading, isSuccess, isError, error }] = useCarUpdateMutation()

    
    const handleCategory = (category: string) => {

        setVehicleInfo({
            ...vehicleInfo,
            vehicle_type: category
        });
        let obj = {
            'vehicle_id': vehicleInfo?.id,
            'vehicle_name': vehicleInfo?.vehicle_name,
            'brand_name': vehicleInfo?.brand_name,
            'plate_number': vehicleInfo?.plate_number,
            'num_passengers': vehicleInfo?.num_passengers,
            'vehicle_location': vehicleInfo?.vehicle_location,
            'vehicle_type': category,
            'year': vehicleInfo?.year,
            'vehicle_for': vehicleInfo?.vehicle_for,
            'file': vehicleInfo?.image1,
            'file2': vehicleInfo?.image2,
            'file3': vehicleInfo?.image3,
            'file5': vehicleInfo?.image5,
        }
        //@ts-ignore
        updateVehicle([obj])
        setIsCategoryVisible(false)
    };

    return (

        <ModalContainer onClick={() => setIsCategoryVisible(false)}>
          

            <Modal style={{width:'60%',height:'80%', overflowY:'scroll', paddingTop:'120px'}} onClick={(e: any) => e.stopPropagation()}>
                <Label style={{width:'100%', marginTop:'650px'}} onClick={() => handleCategory('SUV Car')}>
                    <Option>
                        <input type="radio" name="category" value="SUV Car"
                        />
                        <Text> SUV Car</Text>
                    </Option>
                </Label>
                <Label style={{width:'100%'}} onClick={() => handleCategory('Saloon Car')}>
                    <Option>
                        <input type="radio" name="category" value="Saloon Car"
                        />
                        <Text>Saloon Car</Text>
                    </Option>
                </Label>
                <Label style={{width:'100%'}} onClick={() => handleCategory('Luxury Car')}>
                    <Option>
                        <input
                            type="radio"
                            value="Luxury Car"
                            name="category"
                        />
                        <Text>Luxury Car</Text>
                    </Option>
                </Label>
                <Label style={{width:'100%'}} onClick={() => handleCategory('Sport Car')}>
                    <Option>
                        <input
                            type="radio"
                            value="Sport Car"
                            name="category"
                        />
                        <Text>Sport Car</Text>
                    </Option>
                </Label>

                <Label style={{width:'100%'}} onClick={() => handleCategory('Vintage Car')}>
                    <Option>
                        <input
                            type="radio"
                            value="Vintage Car"
                            name="category"
                        />
                        <Text>Vintage Car</Text>
                    </Option>
                </Label>

                <br />
                <Label style={{width:'100%'}} onClick={() => handleCategory('Mini Bus ')}>
                    <Option>
                        <input
                            type="radio"
                            value="Mini Bus"
                            name="category"
                        />
                        <Text>Mini Bus  </Text>
                    </Option>
                </Label>

                <Label style={{width:'100%'}} onClick={() => handleCategory('Coaster Bus')}>
                    <Option>
                        <input
                            type="radio"
                            value="Coaster Bus"
                            name="category"
                        />
                        <Text>Coaster Bus </Text>
                    </Option>
                </Label>
                <Label style={{width:'100%'}} onClick={() => handleCategory('Luxury Bus')}>
                    <Option>
                        <input
                            type="radio"
                            value="Luxury Bus"
                            name="category"
                        />
                        <Text>Luxury Bus </Text>
                    </Option>
                </Label>
                <Label style={{width:'100%'}} onClick={() => handleCategory('School Bus')}>
                    <Option>
                        <input
                            type="radio"
                            value="School Bus"
                            name="category"
                        />
                        <Text>School Bus </Text>
                    </Option>
                </Label>
                <br />
                <Label style={{width:'100%'}}  onClick={() => handleCategory('Van')}>
                    <Option>
                        <input
                            type="radio"
                            value="Van"
                            name="category"
                        />
                        <Text>Van </Text>
                    </Option>
                </Label>
                <Label  style={{width:'100%'}} onClick={() => handleCategory('Truck')}>
                    <Option>
                        <input
                            type="radio"
                            value="Truck"
                            name="category"
                        />
                        <Text>Truck </Text>
                    </Option>
                </Label>
                <Label style={{width:'100%'}}  onClick={() => handleCategory('Pick-up truck')}>
                    <Option>
                        <input
                            type="radio"
                            value="Pick-up truck"
                            name="category"
                        />
                        <Text>Pick-up truck </Text>
                    </Option>
                </Label>
                <Label style={{width:'100%'}}  onClick={() => handleCategory('Trailers')}>
                    <Option>
                        <input
                            type="radio"
                            value="Trailers"
                            name="category"
                        />
                        <Text>Trailers </Text>
                    </Option>
                </Label>
                <br />
                <Label  style={{width:'100%'}} onClick={() => handleCategory('Caterpilers')}>
                    <Option>
                        <input
                            type="radio"
                            value="Caterpilers"
                            name="category"
                        />
                        <Text>Caterpilers </Text>
                    </Option>
                </Label>
                <Label style={{width:'100%'}} onClick={() => handleCategory('Boats')}>
                    <Option>
                        <input
                            type="radio"
                            value="Boats"
                            name="category"
                        />
                        <Text>Boats </Text>
                    </Option>
                </Label>
                <Label style={{width:'100%'}} onClick={() => handleCategory('Jets')}>
                    <Option>
                        <input
                            type="radio"
                            value="Jets"
                            name="category"
                        />
                        <Text>Jets </Text>
                    </Option>
                </Label>
                <Label style={{width:'100%'}} onClick={() => handleCategory('Helicopters')}>
                    <Option>
                        <input
                            type="radio"
                            value="Helicopters"
                            name="category"
                        />
                        <Text>Helicopters </Text>
                    </Option>
                </Label>
                <br />
                <Label style={{width:'100%'}} onClick={() => handleCategory('Delivery Bikes')}>
                    <Option>
                        <input
                            type="radio"
                            value="Delivery Bikes"
                            name="category"
                        />
                        <Text>Delivery Bikes </Text>
                    </Option>
                </Label>

                <Label style={{width:'100%'}} onClick={() => handleCategory('Power Bike')}>
                    <Option>
                        <input
                            type="radio"
                            value="Power Bike"
                            name="category"
                        />
                        <Text>Power Bike </Text>
                    </Option>
                </Label>
                <Label style={{width:'100%'}} onClick={() => handleCategory('Fireproof truck')}>
                    <Option>
                        <input
                            type="radio"
                            value="Fireproof truck"
                            name="category"
                        />
                        <Text>Fireproof truck </Text>
                    </Option>
                </Label>

                <Label style={{width:'100%'}} onClick={() => handleCategory('Emergency van')}>
                    <Option>
                        <input
                            type="radio"
                            value="Emergency van"
                            name="category"
                        />
                        <Text>Emergency van </Text>
                    </Option>
                </Label>
            </Modal>
        </ModalContainer>

    )
}

