import React from 'react'
import { AddIconWrap, AddNewCardBtn, AmountEnteredBox, CardNumber, CardRadioBtn, CardTypeImg, ErrorTopUpBox, FundWalletTwoWrap, PrimaryBtn, SavedCardWrap } from './fundwallettwo.styles'

import visa from '../../assets/images/svg/visa-text.svg'
import master from '../../assets/images/svg/mastercard.svg'
import plus from '../../assets/images/svg/dark-plus.svg'
import { BackBtnWrap } from '../fundWalletOne/fundwalletone.styles'
import leftArrow from '../../assets/images/svg/dark-left-arrow.svg'
import { SubmitBtn } from '../toggleBar/togglebar.styles'

const FundWalletTwo = ({displaybox, handleShowComponent, topUpAmount, onPay, topUpErrorMessage}: any) => {
  return (
    <FundWalletTwoWrap display={displaybox}>

        <BackBtnWrap top={'-60px'} onClick={() => handleShowComponent('a')}>
            <img src={leftArrow} />
        </BackBtnWrap>

        <AmountEnteredBox>
            <p>Amount Entered</p>
            <h3>₦{`${(Number(topUpAmount))?.toLocaleString('en-US', {maximumFractionDigits: 2})}`}</h3>
        </AmountEnteredBox>
        <ErrorTopUpBox>{topUpErrorMessage}</ErrorTopUpBox>

        {/* <SavedCardWrap bg={'#09051C'}>
          <div className="top-section">
            <CardTypeImg src={master} />

            <CardRadioBtn borderColor={'#fff'} radioActive={true} />
          </div>

          <div className="bottom-section">
            <CardNumber txtColor={'#fff'}>
              .... 6754
            </CardNumber>

            <PrimaryBtn>
              Primary
            </PrimaryBtn>
          </div>
        </SavedCardWrap>

        <SavedCardWrap bg={'#FFD58C'}>
          <div className="top-section">
            <CardTypeImg src={visa} />

            <CardRadioBtn borderColor={'#000'} radioActive={false} />
          </div>

          <div className="bottom-section">
            <CardNumber txtColor={'#09051C'}>
              .... 6754
            </CardNumber>
          </div>
        </SavedCardWrap>

        <AddNewCardBtn onClick={() => handleShowComponent('c')}>
          <AddIconWrap>
            <img src={plus} />
          </AddIconWrap>  
          <p>Add New Card</p>
        </AddNewCardBtn> */}
        <SubmitBtn onClick={() => {
            // handleShowComponent('otp');
            onPay();
        }}>
            <p>Pay</p>
        </SubmitBtn>
    </FundWalletTwoWrap>
  )
}

export default FundWalletTwo