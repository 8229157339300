import React, { useEffect, useState } from 'react'
import Footer from '../../../components/footer'
import Header from '../../../components/header'
import NavigationTab from '../../../components/navigationTab'
import { CountryImgWrap, WalletCardWrap, WalletHeaderText, WalletHeaderWrap,TabOverall, WalletProfileWrap, DarkBtnWrap, HistoryOverall, RowGapWrap, RowWrap, WithdrawBtn, PaymentHistoryCardOverall, PaymentHistoryBtn, SeeMoreBtn } from './userwalletpage.styles'
import WalletCard from '../../../components/walletCardComponent'
import { ArrowDownWrap, InputIcons } from '../../../components/locationModal/locationmodal.styles'
import DatePicker from "react-multi-date-picker"
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import DatePanel from "react-multi-date-picker/plugins/date_panel"

import profile from '../../../assets/images/png/wallet-profile.png'
import canada from '../../../assets/images/png/canada.png'
import withdraw from '../../../assets/images/svg/withdraw-icon.svg'
import arrowDown from '../../../assets/images/svg/arrowDown.svg'
import swap from '../../../assets/images/svg/swap-icon.svg'
import transfer from '../../../assets/images/svg/transfer-icon.svg'
import envelope from '../../../assets/images/svg/collect-money.svg'
import history from '../../../assets/images/svg/history-icon.svg'
import TabComponent from '../../../components/tabComponent'
import PaymentHistoryCard from '../../../components/paymentHistoryComponent'
import WalletModal from '../../../components/walletModal'
import TransferWalletModal from '../../../components/transferWalletModal'
import SwapCurrencyModal from '../../../components/swapModal'
import WithdrawCurrencyModal from '../../../components/withdrawModal'
import CollectMoneyModal from '../../../components/collectMoneyModal'
import { useCreateFiatWalletMutation, useCreatePaymentLinkMutation, useCreateVirtualWalletMutation, useGetWalletByIdQuery, useTopUpWalletMutation, useVerifyWithdrawOtpMutation, useWalletTransferMutation, useWithdrawWalletMutation } from '../../../services/walletApi'
import { RootState, useAppDispatch, useAppSelector } from '../../../store'
import CreateWalletCard from '../../../components/createWalletCard'
import { useGetBookingHistoryQuery, useGetTransactionHistoryQuery } from '../../../services/customerApi'
import ReactCountryFlag from "react-country-flag"
import moment from 'moment'



const UserWalletPage = () => {
  const dispatch = useAppDispatch()
  dayjs.extend(advancedFormat);
  dayjs.extend(isSameOrAfter);

  

  const { id,firstname, lastname, username
  } = useAppSelector<any>((store: RootState) => store?.auth?.auth)

  const {currentLocation} = useAppSelector<any>((store: RootState) => store?.currentLocation)

  const [changeTab, setChangeTab] = useState<any >('tab1')
  const [showWalletModal, setShowWalletModal] = useState(false)
  const [transferWalletModal, setTransferWalletModal] = useState(false)
  const [swapWalletModal, setSwapWalletModal] = useState(false)
  const [withdrawWalletModal, setWithdrawWalletModal] = useState(false)
  const [collectMoneyModal, setCollectMoneyModal] = useState(false)
  const [createWalletModal, setCreateWalletModal] = useState(false)
  const [bvn, setBvn] = useState<string>('')
  const [walletDetails, setWalletDetails] = useState<any>([])
  const [bvnError, setBvnError] = useState('')
  const [topUpAmount, setTopUpAmount] = useState('')
  const [topUpErrorMessage, setTopUpErrorMessage] = useState('')
  const [transferErrorMessage, setTransferErrorMessage] = useState('')
  const [transferAmount, setTransferAmount] = useState('')
  const [creditWalletName, setCreditWalletName] = useState('')
  const [transferPassword, setTransferPassword] = useState('')
  const [withdrawAmount, setWithdrawAmount] = useState('')
  const [withdrawBank, setWithdrawBank] = useState('')
  const [bankCode, setBankCode] = useState('')
  const [withdrawAccountNo, setWithdrawAccountNo] = useState('')
  const [withdrawAccountName, setWithdrawAccountName] = useState('')
  const [withdrawErrorMessage, setWithdrawErrorMessage] = useState('')
  const [transactionHistory, setTransactionHistory] = useState<any []>([])
  const [transactionHistoryPage, setTransactionHistoryPage] = useState(1)
  const [bookingHistory, setBookingHistory] = useState<any []>([])
  const [showWithdrawSubcomponent, setShowWithdrawSubcomponent] = useState('a')
  const [showCollectMoneyComponent, setShowCollectMoneyComponent] = useState('a')
  const [otp, setOtp] = useState('')
  const [otpError, setOtpError] = useState('')
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [collectMoneyAmount, setCollectMoneyAmount] = useState("")
  const [collectMoneyDescription, setCollectMoneyDescription] = useState("")
  const [collectMoneyCurrency, setCollectMoneyCurrency] = useState("")
  const [collectMoneyPayer, setCollectMoneyPayer] = useState("")
  const [collectMoneyError, setCollectMoneyError] = useState("")
  const [collectMoneyData, setCollectMoneyData] = useState([])
  const [transferwalletComponent, setTransferWalletComponent] = useState("a")


  const [createFiatWallet, { data: fiatwalletData, isSuccess: walletIsSuccess, isError: walletIsError, error: walletError }] = useCreateFiatWalletMutation()
 
  const [createVirtualWallet, { data, isLoading, isSuccess, isError, error }] = useCreateVirtualWalletMutation();

  const [topUpWallet, { data: topUpData, isLoading: isTopUpLoading, isSuccess: isTopUpSuccess, isError: isTopUpError, error: topUpError }] = useTopUpWalletMutation();

  const [walletTransfer, { data: transferData, isLoading: isTransferLoading, isSuccess: isTransferSuccess, isError: isTransferError, error: transferError }] = useWalletTransferMutation();

  const [withdrawWallet, { data: withdrawData, isLoading: isWithdrawLoading, isSuccess: isWithdrawSuccess, isError: isWithdrawError, error: withdrawError }] = useWithdrawWalletMutation();

  const [createPayLink, { data: createPayLinkData, isLoading: isCreatePayLinkLoading, isSuccess: isCreatePayLinkSuccess, isError: isCreatePayLinkError, error: createPayLinkError }] = useCreatePaymentLinkMutation();

  const {data: walletData, refetch: refetchWallet, isSuccess: walletSuccess} = useGetWalletByIdQuery(id, {refetchOnFocus:true, refetchOnMountOrArgChange:true})

  const {data: transactionData, refetch: refetchTransaction, isSuccess: transactionSuccess} = useGetTransactionHistoryQuery(username, {refetchOnFocus:true, refetchOnMountOrArgChange:true})

  const {data: bookingHistoryData, refetch: refetchBookingHistory, isSuccess: bookingHistorySuccess} = useGetBookingHistoryQuery(id, {refetchOnFocus:true, refetchOnMountOrArgChange:true})

  const [verifyOtp, { data: otpData, isLoading: otpIsLoading, isSuccess: otpIsSuccess, isError: otpIsError, error: errorOtp }] = useVerifyWithdrawOtpMutation();

  useEffect(() => {
    refetchWallet()
    refetchTransaction()
    refetchBookingHistory()
  }, [walletData, transactionData, bookingHistoryData, data, isSuccess])

  useEffect(() => {
    if(walletSuccess) {
      setWalletDetails(walletData?.data)
    }
  }, [walletData, data])

  useEffect(() => {
    if(transactionSuccess) {
      setTransactionHistory(prevData => [...prevData, ...transactionData?.data])
      setTransactionHistoryPage(prevPage => prevPage + 1)
    }

    if(bookingHistorySuccess) {
      setBookingHistory(bookingHistoryData?.data)
    }
  }, [transactionData, bookingHistoryData])

  // console.log(transactionHistory?.reverse(), 'data')
  
  // USEFFECT FOR CREATE VIRTUAL WALLET
  useEffect(() => {
    if(isSuccess) {
      setShowWalletModal(true)
      setCreateWalletModal(false)
    }
    if(isError && 'status' in error!) {
      setBvnError(error.data.message) 

      setTimeout(() => {
        setBvnError('')
      }, 3000)
    }
  }, [isLoading, isSuccess, isError, data])

  // USEFFECT FOR WALLET TOPUP
  useEffect(() => {
    if(isTopUpSuccess) {
      window.open(topUpData?.data?.authorization_url)
      setTopUpAmount('')
      removeWalletModal()
    }

    if(isTopUpError && 'status' in topUpError!) {
      setTopUpErrorMessage(topUpError.data.message)

      setTimeout(() => {
        setTopUpErrorMessage('')
      }, 3000)
    }

  }, [topUpData, isTopUpLoading, isTopUpError, isTopUpSuccess, topUpError])


  // USEFFECT FOR WALLET TRANSFER
  useEffect(() => {
    if(isTransferSuccess) {
      setTransferWalletComponent("success")
      setTransferAmount('')
      setCreditWalletName('')
      setTransferPassword('')
    }

    if(isTransferError && 'status' in transferError!) {
      setTransferErrorMessage(transferError.data.message)

      setTimeout(() => {
        setTransferErrorMessage('')
      }, 5000)
    }

  }, [transferData, isTransferLoading, isTransferError, isTransferSuccess, transferError])

  const handleCreateFiatWallet = () => {
    let formData: any = new FormData()
    formData.append("currency_code", 'NGN')

    // convert the formdata to JSON data
    let object:any = {};
    formData.forEach((value:any, key:any) => object[key] = value);
    let value:any = JSON.stringify(object)

    createFiatWallet(formData)
}

  // USEFFECT FOR WITHDRAWAL
  useEffect(() => {
    if(isWithdrawSuccess) {
      setWithdrawAmount('')
      setWithdrawAccountName('')
      setWithdrawAccountNo('')
      setWithdrawBank('')
      setShowWithdrawSubcomponent('otp')
    }

    if(isWithdrawError && 'status' in withdrawError!) {
      setWithdrawErrorMessage(withdrawError.data.message)

      setTimeout(() => {
        setWithdrawErrorMessage('')
      }, 5000)
    }

  }, [withdrawData, isWithdrawLoading, isWithdrawError, isWithdrawSuccess, withdrawError])

  // USEEFFECT FOR OTP VERIFY
  useEffect(() => {
    if(otpIsSuccess) {
        setShowWithdrawSubcomponent('success')
        setOtp('')
    }

    if(otpIsError && 'status' in errorOtp!) {
      setOtpError(errorOtp?.data?.message)
      // setShowWithdrawSubcomponent('failed')
      setOtp('')

      setTimeout(() => {
        setOtpError('')
      }, 5000)
    }
  }, [otpData, otpIsError, otpIsLoading, otpIsSuccess, errorOtp])

  // USEEFFECT FOR CREATE PAYMENT LINK
  useEffect(() => {
    if(isCreatePayLinkSuccess) {
      setShowCollectMoneyComponent('b')
      setCollectMoneyData(createPayLinkData?.data)
    }

    if(isCreatePayLinkError && 'status' in createPayLinkError!) {
      setCollectMoneyError(createPayLinkError?.data?.message)
      
      setTimeout(() => {
        setCollectMoneyError("")
      }, 5000)
    }

  }, [createPayLinkData, createPayLinkError, isCreatePayLinkError, isCreatePayLinkLoading, isCreatePayLinkSuccess])

  const removeWalletModal = () => {
    setShowWalletModal(false)
    setTransferWalletModal(false)
    setSwapWalletModal(false)
    setWithdrawWalletModal(false)
    setCollectMoneyModal(false)
    setCreateWalletModal(false)
  }

  const CreateVirtualWallet = () => {
    let formData: any = new FormData()

    if(bvn?.length === 11) {
      formData.append('firstname', firstname)
      formData.append('first_name', firstname)
      formData.append('lastname', lastname)
      formData.append('last_name', lastname)
      formData.append('bvn', bvn)
  
      createVirtualWallet(formData);
    }else if (!bvn) {
      setBvnError('Please type in your bvn')

      setTimeout(() => {
        setBvnError('')
      }, 3000)
    }else {
      setBvnError('Please type in correct bvn')

      setTimeout(() => {
        setBvnError('')
      }, 3000)
    }
  }

  const handleWalletModalDisplay = () => {
    if(!walletDetails?.virtual_account_number) {
      setCreateWalletModal(true)
    }else {
      setShowWalletModal(true)
    }
  }

  // TOPUP WALLET FUNCTION
  const onPay = () => {
    if(topUpAmount) {
      let formData:any = new FormData();

      formData.append("currency", 'NGN');
      formData.append("amount", topUpAmount);
  
      topUpWallet(formData)
    }

  }

  // WALLET TRANSFER FUNCTION
  const onWalletTransfer = () => {
    let formData:any = new FormData();

    formData.append("creditWalletEmail", creditWalletName);
    formData.append("debitWalletUsername", walletDetails?.user_name);
    formData.append("password", transferPassword);
    formData.append("amount", Number(transferAmount));
    formData.append("currency", 'NGN');

    walletTransfer(formData)
  }

  // WITHDRAW FUNCTION
  const onWithdraw = () => {
    let formData:any = new FormData();

    if(!withdrawAmount || !withdrawBank || !withdrawAccountName || !withdrawAccountNo) {
      setWithdrawErrorMessage('Please fill all inputs')

      setTimeout(() => {
        setWithdrawErrorMessage('')
      }, 4000)
    } else {
      formData.append("withdrawal_amount", withdrawAmount);
      formData.append("username", walletDetails?.user_name);
      formData.append("bank_name", withdrawBank);
      formData.append("account_number", withdrawAccountNo);
      formData.append("bank_code", bankCode )
      formData.append("account_name", withdrawAccountName);
  
      withdrawWallet(formData)
    }
    
  }

  // VERIFY OTP FOR WITHDRAWAL
  const onOtpVerify = () => {
    let formData:any = new FormData();

    if(!otp) {
      setOtpError('Please type in OTP')

      setTimeout(() => {
        setOtpError('')
      }, 4000)
    }else {
      formData.append("otp", otp);
      formData.append("id", withdrawData?.message);
      formData.append("username", username);
  
      verifyOtp(formData)
    }
  }

  // CREATE PAYMENT LINK
  const onCreatePaymentLink = () => {
    let formData:any = new FormData();

    if(!collectMoneyAmount || !collectMoneyDescription || !collectMoneyPayer) {
      console.error("fill in inputs")
    }else {
      formData.append("amount", collectMoneyAmount);
      formData.append("payer_name", collectMoneyPayer );
      formData.append("description", collectMoneyDescription);
      formData.append("currency", "NGN");

      createPayLink(formData)
    }
  }

  const filterItems = (item:any) => {
    if (selectedDates.length === 2) {
      const startDate = selectedDates[0];
      const endDate = selectedDates[1];
      const itemDate = dayjs(item?.created_at, "YYYY-MM-DD");
      return dayjs(itemDate).isSameOrAfter(startDate, "day") && dayjs(itemDate).isSameOrBefore(endDate, "day");
    }
    return true;
  };

  const filteredItems = transactionHistory.filter(filterItems);

  return (
    <>
        <WalletModal walletModalActive={showWalletModal} removeWalletModal={removeWalletModal} setTopUpAmount={setTopUpAmount} topUpAmount={topUpAmount} accountNumber={walletDetails?.virtual_account_number} bankName={walletDetails?.bank_name} onPay={onPay} topUpErrorMessage={topUpErrorMessage} isTopUpLoading={isTopUpLoading}/>

        <TransferWalletModal showTransferModal={transferWalletModal} removeWalletModal={removeWalletModal} onWalletTransfer={onWalletTransfer} transferAmount={transferAmount} setTransferAmount={setTransferAmount} creditWalletName={creditWalletName} setCreditWalletName={setCreditWalletName} transferErrorMessage={transferErrorMessage} transferPassword={transferPassword} setTransferPassword={setTransferPassword} isTransferLoading={isTransferLoading} setTransferWalletComponent={setTransferWalletComponent} transferwalletComponent={transferwalletComponent} transferData={transferData} />

        <SwapCurrencyModal showSwapModal={swapWalletModal} removeWalletModal={removeWalletModal}  />

        <WithdrawCurrencyModal showWithdrawModal={withdrawWalletModal} removeWalletModal={removeWalletModal} onWithdraw={onWithdraw} withdrawAmount={withdrawAmount} setWithdrawAmount={setWithdrawAmount} withdrawBank={withdrawBank} setWithdrawBank={setWithdrawBank} withdrawAccountNo={withdrawAccountNo} setWithdrawAccountNo={setWithdrawAccountNo} withdrawAccountName={withdrawAccountName} setWithdrawAccountName={setWithdrawAccountName} withdrawErrorMessage={withdrawErrorMessage} bankCode={bankCode} setBankCode={setBankCode} showComponent={showWithdrawSubcomponent} setShowComponent={setShowWithdrawSubcomponent} otp={otp} setOtp={setOtp} onOtpVerify={onOtpVerify} isWithdrawLoading={isWithdrawLoading} otpIsLoading={otpIsLoading} otpError={otpError} withdrawData={otpData}/>

        <CollectMoneyModal showCollectModal={collectMoneyModal} removeWalletModal={removeWalletModal} onCreatePaymentLink={onCreatePaymentLink} collectMoneyAmount={collectMoneyAmount} setCollectMoneyAmount={setCollectMoneyAmount} collectMoneyDescription={collectMoneyDescription} setCollectMoneyDescription={setCollectMoneyDescription} collectMoneyCurrency={collectMoneyCurrency} setCollectMoneyCurrency={setCollectMoneyCurrency} collectMoneyPayer={collectMoneyPayer} setCollectMoneyPayer={setCollectMoneyPayer} collectMoneyError={collectMoneyError} showComponent={showCollectMoneyComponent} setShowComponent={setShowCollectMoneyComponent} collectMoneyData={collectMoneyData} isCreatePayLinkLoading={isCreatePayLinkLoading}/>
        
        <CreateWalletCard setCreateWalletModal={setCreateWalletModal} creatWalletActive={createWalletModal} setBvn={setBvn} handleCreateVirtualWallet={CreateVirtualWallet} bvnError={bvnError} isLoading={isLoading}/>


        <Header />
          <NavigationTab walletPill={true} />

          <WalletHeaderWrap>
            <div className="inner-wrap">
              <WalletProfileWrap>
                <img src={profile} />
              </WalletProfileWrap>

              <WalletHeaderText>
                <h2>Hello {firstname}</h2>
                <p>Welcome to your Wallet</p>
              </WalletHeaderText>

              <CountryImgWrap>
                {/* <img src={canada}  /> */}
                <ReactCountryFlag countryCode={currentLocation?.country} />
              </CountryImgWrap>
            </div>
          </WalletHeaderWrap>

          <WalletCardWrap>
            <WalletCard handleWalletModal={setShowWalletModal} handleWalletModalDisplay={handleWalletModalDisplay} 
            currency={walletDetails?.currency_code} amount={walletDetails?.balance}/>
          </WalletCardWrap>

          <RowWrap className="left-move">              
              <DarkBtnWrap onClick={() => {setTransferWalletModal(true)}}>
                  <img className='transfer-icon' src={transfer}/>

                  <h3>Transfer</h3>
              </DarkBtnWrap>

              <DarkBtnWrap onClick={() => setCollectMoneyModal(true)}>
                <img className='envelope' src={envelope} />
                <p>Collect Money</p>
              </DarkBtnWrap>

              <WithdrawBtn onClick={() => setWithdrawWalletModal(true)}>
                <img src={withdraw} />
                <p>Withdraw</p>
              </WithdrawBtn>
          </RowWrap>

          {/* HAD TO COMMENT THESE TO MAKE THE PAGE LOOK NEATER, WHEN SELECT WALLET AND SWAP IS ADDED PLEASE UNCOMMENT THIS */}
          {/* <RowGapWrap>
              <DarkBtnWrap className='dark-btn-wrap' inactive={true}>
                <p>Select Currency </p>
                <ArrowDownWrap>
                    <InputIcons src={arrowDown} iconWidth={'25px'} />
                </ArrowDownWrap>
              </DarkBtnWrap>

              <WithdrawBtn onClick={() => setWithdrawWalletModal(true)}>
                <img src={withdraw} />
                <p>Withdraw</p>
              </WithdrawBtn>
          </RowGapWrap>
          <RowWrap>
              <div className="subwrap">
                <DarkBtnWrap onClick={() => {setTransferWalletModal(true)}}>
                  <img className='transfer-icon' src={transfer}/>

                  <h3>Transfer</h3>
                </DarkBtnWrap>

                <DarkBtnWrap className='swap-box' 
                onClick={() => setSwapWalletModal(true)}
                inactive={true}>
                  <img className='swap-icon' src={swap}/>
                  <h3>Swap</h3>
                </DarkBtnWrap>
              </div>

              <DarkBtnWrap onClick={() => setCollectMoneyModal(true)}>
                <img className='envelope' src={envelope} />
                <p>Collect Money</p>
              </DarkBtnWrap>
          </RowWrap> */}

          <HistoryOverall>
            <PaymentHistoryBtn>
              <p>Payment History</p>
              <img src={history} />
            </PaymentHistoryBtn>
          </HistoryOverall>

          <TabOverall>
            <TabComponent handleChange={setChangeTab} tabOne={'Transactions'} tabTwo={'Bookings'}/>
          </TabOverall>

          {
            changeTab === 'tab1' ?
            (
              filteredItems?.length > 0 && 
              <PaymentHistoryCardOverall>
                {
                  filteredItems?.slice(0)?.reverse()?.map((item:any, id:any) => {
                    return (
                      <PaymentHistoryCard 
                        key={id}
                        date={moment(item?.updated_at).format('MMM Do YY')}
                        time={moment(item?.updated_at).format('h:mm a')}
                        type={item?.txn_type}
                        amount={item?.amount}
                        currency={item?.currency_code}
                      />
                    )
                  })
                }

              </PaymentHistoryCardOverall> 
            )
              :
            (
              bookingHistory?.length > 0 && 
              <PaymentHistoryCardOverall>
                  {
                    bookingHistory?.map((item:any, id:any) => {
                      return (
                        <PaymentHistoryCard 
                          key={id}
                          date={moment(item?.updated_at).format('MMM Do YY')}
                          time={moment(item?.updated_at).format('h:mm a')}
                          type={item?.txn_type}
                          amount={item?.amount}
                          currency={'NGN'}
                        />
                      )
                    })
                  }
              </PaymentHistoryCardOverall>
            )

          }

        {/* <Footer /> */}
    </>
  )
}

export default UserWalletPage