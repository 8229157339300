import React, { useState } from 'react'
import FailedWalletComponent from '../failedWalletComponent'
import { ModalOverlay, CancelModalBox } from '../optionModal/optionmodal.styles'
import OtpWalletComponent from '../otpWalletComponent'
import SuccessWalletComponent from '../successWalletComponent'
import TransferOneComponent from '../transferOne'
import { WalletModalBox, WalletModalHeader, WalletContentWrap, RoadlersBlackCircle, RoadlersHomeCone } from '../walletModal/walletmodal.styles'


import roadlersCone from '../../assets/images/svg/homebutton.svg'
import logo from '../../assets/images/svg/logo-small.svg'
import LittleLoader from '../littleLoader'

const TransferWalletModal = ({showTransferModal, removeWalletModal, onWalletTransfer, transferAmount, setTransferAmount, creditWalletName, setCreditWalletName, transferErrorMessage, transferPassword, setTransferPassword, isTransferLoading, setTransferWalletComponent, transferwalletComponent, transferData}: any) => {
    const [showComponent, setShowComponent] = useState('a')

    const handleShowComponent = (type: any) => {
        setShowComponent(type)
        setTransferWalletComponent(type)
    }
  return (
    <ModalOverlay activeModal={showTransferModal}>
        <CancelModalBox onClick={() => {removeWalletModal()
            handleShowComponent('a')
        }} />
        <WalletModalBox>
            <RoadlersHomeCone src={roadlersCone} />
            <RoadlersBlackCircle>
                <img src={logo} />
            </RoadlersBlackCircle>
            <div className="inner-modal-box">
                <WalletModalHeader>
                    <div className=""></div>
                </WalletModalHeader>

                {
                    isTransferLoading ? 
                    <LittleLoader/> : 
                    <WalletContentWrap>
                        <OtpWalletComponent displaybox={transferwalletComponent === 'otp' ? 'block' : 'none'} handleShowComponent={handleShowComponent} />
                        <SuccessWalletComponent displaybox={transferwalletComponent === 'success' ? 'block' : 'none'} handleShowComponent={handleShowComponent}  
                            walletText={`was succesfully transferred`} amount={transferData?.data?.amountPaid?.toLocaleString('en-US', {maximumFractionDigits: 2})}
                        />
                        <FailedWalletComponent displaybox={transferwalletComponent === 'failed' ? 'block' : 'none'} handleShowComponent={handleShowComponent}  
                        walletText={"was not succesfully transferred"} amount={transferData?.data?.amountPaid?.toLocaleString('en-US', {maximumFractionDigits: 2})}
                        />
                        <TransferOneComponent displaybox={transferwalletComponent === 'a' ? 'block' : 'none'} handleShowComponent={handleShowComponent} onWalletTransfer={onWalletTransfer} transferAmount={transferAmount} setTransferAmount={setTransferAmount} creditWalletName={creditWalletName} setCreditWalletName={setCreditWalletName} transferErrorMessage={transferErrorMessage} transferPassword={transferPassword} setTransferPassword={setTransferPassword}  />
                    </WalletContentWrap>
                }
            </div>
        </WalletModalBox>

    </ModalOverlay>
  )
}

export default TransferWalletModal