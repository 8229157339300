import React from 'react'
// import ToggleBar from '../../../components/toggleBar'

import {
    RatingsBox, P, H3, H6

} from './index.styles';
import flag from '../../../assets/images/png/Flag.png'

import { BsCalendar3, BsFillFolderFill } from 'react-icons/bs';
const Reports = () => {
    return (
        <>
            <RatingsBox>
                <H6>Reports</H6>
                <P>50</P>
                <img src={flag} alt="" width='25px' />

            </RatingsBox>
        </>
    )
}

export default Reports