import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

interface CustomError {
    data: {
        error: string;
        message: any;
        statusCode: number;
    };
    status: number;
}

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const customerApi = createApi({
    reducerPath: 'customerApi',
    baseQuery: fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState)?.auth?.auth?.token;
            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }) as BaseQueryFn<string | FetchArgs, unknown, CustomError, {}>,
    tagTypes: ['Customers'],
    endpoints: builder => ({
        updateUsername: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-username',
                    method: 'post',
                    body: value
                };
            }
        }),
        addVehicleLikes: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'add-vehicle-like',
                    method: 'post',
                    body: value
                };
            }
        }),
        updateInsurance: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-insurance-details',
                    method: 'post',
                    body: value
                };
            }
        }),
        updateBooking: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'update-request',
                    method: 'post',
                    body: value
                };
            }
        }),
        specialRequest: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'new-special-request',
                    method: 'post',
                    body: value
                };
            }
        }),
        rateService: builder.mutation<any, void>({
            query: (value) => {
                return {
                    url: 'rate-service',
                    method: 'post',
                    body: value
                };
            }
        }),
        getVehicleLikesById: builder.query<any, any>({
            query: (id) => {
                return {
                    url: `view-vehicle-likes/${id}`,
                };
            }
        }),
        getUserLocation: builder.query({
            query: () => {
                return {
                    url: `current-location`,
                };
            },
        }),
        getVehicleList: builder.query<any, any>({
            query: () => {
                return {
                    url: 'list-vehicle',
                };
            }
        }),
        getSearchVehicleList: builder.query({
            query: ({vehicleLocation, value}) => {
                return {
                    url: `search-vehicle?search_location=${vehicleLocation}&search_input=${value}`,
                };
            },
        }),
        getAllVehicleList: builder.query<any, void>({
            query: () => {
                return {
                    url: `list-vehicle-with-serviceprice`,
                };
            },
        }),

       

        getVehicleById: builder.query({
            query: (id) => {
                return {
                    url: `view-vehicle/${id}`,
                };
            },
        }),
        getProviderById: builder.query({
            query: (id) => {
                return {
                    url: `search-providerby-id/${id}`,
                };
            },
        }),
        listCustomerBooking: builder.query({
            query: (id) => {
                return {
                    url: `list-bookings/${id}`,
                };
            },
        }),
        getTransactionHistory: builder.query({
            query: (username) => {
                return {
                    url: `wallet/get-fiat-wallet-transactions/${username}`,
                };
            },
        }),
        getBookingHistory: builder.query({
            query: (id) => {
                return {
                    url: `transaction-history/${id}`,
                };
            },
        }),
        getGiftCardList: builder.query<any, void>({
            query: () => {
                return {
                    url: 'list-gift-card',
                };
            }
        }),
        getDriverList: builder.query<any, void>({
            query: () => {
                return {
                    url: 'list-all-drivers',
                };
            }
        }),
        getPricingList: builder.query<any, void>({
            query: () => {
                return {
                    url: 'view-pricing',
                };
            }
        }),
    }),
});

export const {
    useUpdateUsernameMutation,
    useAddVehicleLikesMutation,
    useUpdateInsuranceMutation,
    useUpdateBookingMutation,
    useRateServiceMutation,
    useSpecialRequestMutation,
    useGetVehicleLikesByIdQuery,
    useGetUserLocationQuery,
    useGetVehicleListQuery,
    useGetSearchVehicleListQuery,
    useGetAllVehicleListQuery,
    useGetVehicleByIdQuery,
    useGetGiftCardListQuery,
    useGetDriverListQuery,
    useGetProviderByIdQuery,
    useGetTransactionHistoryQuery,
    useListCustomerBookingQuery,
    useGetBookingHistoryQuery,
    useGetPricingListQuery
} = customerApi;

