import React, { FC, useEffect, useRef, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import van from '../../../assets/images/png/yellowVan.png'
import { IoLocationSharp } from 'react-icons/io5';
import { MdOutlineAirlineSeatReclineNormal } from 'react-icons/md';
import { FiHeart, FiSend, FiStar } from 'react-icons/fi';
import { BiPencil } from 'react-icons/bi';
import { RiDeleteBinLine } from 'react-icons/ri';



import {
    Div, FlexDiv, H6, Label, Flex, ImageDiv, ImageDetails, H5, PriceDiv, Span, LocationSpan, OldPrice, DetailsDiv, StatusLabel
} from './vehicle.styles';
import VehicleToggleBar from './vehicleToggle';

import { useDeleteVehicleMutation } from '../../../services/providerApi';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify'
import { DeleteModal } from '../deleteModal';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '../../../store';
import { setVehicleIdValue } from '../../../slices/provider';


const Vehicle: FC<{ vehicleDetails: any }> = ({ vehicleDetails }) => {
    const dispatch: any = useAppDispatch()

    const [display, setDisplay] = useState<string>(vehicleDetails?.display)

    const [isDelete, setIsDelete] = useState<boolean>(false)
    const handleDeleteModal = async () => {
        setIsDelete(prev => !prev)
    }
    const notifyError = (text: any) => toast.error(text, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
    })
    const address = vehicleDetails?.vehicle_location;
    const words = address?.split(" ");
    const firstWord = words?.slice(0, 1).join(" ");
    const setVehicleId = (value: any) => {
        dispatch(setVehicleIdValue(value))

    }

    const fullDayPrice = vehicleDetails?.service_price?.filter((price: any) => {
        return price?.service_title === 'Full Day Service'
    })

    const discountCalc = (price: number, discount: number) => {
        let discountPrice = discount / 100 * price
        let newPrice = price - discountPrice
        return newPrice

    }


    return (
        <>

            <Div style={{}}>
                <ToastContainer />



                {
                    isDelete && <DeleteModal
                        id={vehicleDetails?.id}
                        setIsDelete={setIsDelete}
                        isVehicle={true}
                    />
                }


                <FlexDiv>
                    <Label>This vehicle is
                        {
                            display === '1' ? ' Online' : ' Offline'
                        }
                    </Label>

                    <VehicleToggleBar
                        setDisplay={setDisplay}
                        display={display}
                        id={vehicleDetails?.id}
                        status={vehicleDetails?.status}
                        style={{ marginTop: '66px' }}
                    />
                    <RiDeleteBinLine onClick={handleDeleteModal} style={{ fontSize: '14px' }} />
                    <Flex >
                        {/* <BiPencil style={{ fontSize: '9px' }} /> */}
                        <label style={{ fontSize: '11px' }}>
                            <Link
                                onClick={() => setVehicleId(vehicleDetails?.id)}
                                to={"/vehicle-profile/" + vehicleDetails?.id + "/" + vehicleDetails?.user_id} style={{ color: 'white' }}>
                                Edit
                            </Link>
                        </label>
                    </Flex>
                </FlexDiv>
                <ImageDiv >
                    <div style={{
                        borderRadius: '12px',
                        overflow: 'hidden',
                        backgroundImage: `url(https://testapi.roadlers.com/storage/${vehicleDetails?.image1 || van})`,
                        backgroundRepeat: 'no-repeat',
                        height: '120px',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundOrigin: 'content-box'
                    }}>

                    </div>
                    {vehicleDetails?.status === null && <StatusLabel style={{ background: '#005FEE', color: '#fff' }}>Pending</StatusLabel>}
                    {vehicleDetails?.status === 'Pending' && <StatusLabel style={{ background: '#005FEE', color: '#fff' }}>Pending</StatusLabel>}
                    {vehicleDetails?.status === 'Rejected' && <StatusLabel style={{ background: '#FF2B2B', color: '#fff' }}>Rejected</StatusLabel>}
                    {vehicleDetails?.status === 'Approved' && <StatusLabel style={{ background: '#06AE2B', color: '#fff' }}>Approved</StatusLabel>}
                    <ImageDetails>
                        <H5>{vehicleDetails?.vehicle_name} - {vehicleDetails?.year}</H5>

                        <PriceDiv>
                            <H6>₦{discountCalc(fullDayPrice[0]?.price, fullDayPrice[0]?.discount)} <Span>/ Day</Span>
                                <br /><OldPrice>
                                    ₦{fullDayPrice[0]?.price}
                                </OldPrice>
                            </H6>
                            <LocationSpan>
                                <IoLocationSharp style={{ color: 'red', fontSize: '16' }} />
                                <span style={{ fontWeight: 'bold' }}>{firstWord}</span>
                            </LocationSpan>
                        </PriceDiv>

                        <DetailsDiv>
                            <div style={{ width: '40%', display: 'flex', alignItems: 'center', gap: '3px' }}>
                                <FiHeart />
                                <Span>{vehicleDetails?.likes || 0}</Span>
                            </div>
                            <div style={{ width: '60%', display: 'flex', alignItems: 'center', gap: '8px' }}>
                                <FiSend />
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <MdOutlineAirlineSeatReclineNormal />
                                    <Span>{vehicleDetails?.num_passengers || 1}</Span>
                                </div>

                                <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                                    <FiStar style={{ color: '#ffa100' }} />
                                    <Span>3</Span>
                                </div>
                            </div>

                        </DetailsDiv>
                    </ImageDetails>
                </ImageDiv>

            </Div>

        </>

    )
}

export default Vehicle
