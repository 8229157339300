import React, { FC, useEffect, useState } from 'react'
import Customer from '../Customers/customer'
import { BackgroundBox, Button, H3, ModalBox } from './providerModal.styles'
import { Link } from 'react-router-dom'
import { useAdminCustomerDataMutation } from '../../../services/adminApi'
import Provider from '../Providers/provider'


type Props = {
    setIsProfile?: any;
    providerId?: string;
    providerDetails?:any;
}
const ProviderModal: FC<Props> = ({providerDetails, providerId, setIsProfile }) => {

    let [customerInfo, setCustomerInfo] = useState([])

    // const providerRequest = useAppSelector((state: any) => state?.provider.allRequest)


    const [customerData, { data, isLoading, isSuccess, isError, error }] = useAdminCustomerDataMutation()
    useEffect(() => {
        if (isLoading) {
            // setBtnLoad(true)
        }
        if (isSuccess) {
            // setBtnLoad(false)
            setCustomerInfo(data?.data)
        }


        if (isError && 'status' in error!) {
            if (isError && error.status.toString() === 'FETCH_ERROR') {
                // notifyError('Network Connection Failed')
            }
            // setBtnLoad(false)
        }
    }, [providerId])


    const handleGetCustomer = () => {
        let formData: any = new FormData()
        formData.append('customer_id', providerId)



        customerData(formData)

    }
    useEffect(() => {
        handleGetCustomer()
    }, [providerId])


    // const dispatch = useAppDispatch()

    // useEffect(() => {
    //     refetchCustomer()
    //     // dispatch(setAllRequest(providerRequests?.data))
    //     // dispatch(setRequestCount(totalMessageCount))
    // }, [refetchCustomer, customerData])


    // const notifyError = (text: any) => toast.error(text, {
    //     position: "bottom-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "dark",
    // })

    return (
        <>
            <BackgroundBox onClick={() => setIsProfile((prev:any) => !prev)}>

                <ModalBox onClick={(e:any) => e.stopPropagation()}>
                    <H3>Provider Data</H3>
                    <Provider providerDetails={providerDetails} isModal={true} />


                    {/* <Button> */}
                    {/* <a href={`tel: ${customerInfo?.phone_number}`}>
                        Call Client

                    </a> */}

                    {/* </Button> */}

                </ModalBox>
            </BackgroundBox>

        </>
    )
}

export default ProviderModal