import React, { FC, useEffect, useRef, useState } from 'react'
// import ToggleBar from '../../../components/toggleBar'

import filterIcon from '../../../assets/images/png/filter.png'

import {
    Div, FlexDiv, Button, H6, VehicleDiv, BikeDiv, FlexBetween, Buttons, Input, InputFlexBetween, H4, H5, TrashButton, PendingButton
} from './providerVehicles.styles';

import { FiSearch } from 'react-icons/fi';
import Vehicle from './vehicle';
import Bikes from './deliveryBikes';
import CarRegistration from '../../../pages/Service_provider/CarRegistration/carRegistration';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useGetBikesQuery, useGetVehiclesQuery } from '../../../services/providerApi';
import { setAllBike, setAllVehicle } from '../../../slices/provider';
import axios from 'axios';
import NoData from '../providerRequests/NoData';
import { setPageNumber } from '../../../slices/admin';
import { PaginationButton, PaginationDiv, PaginationLabel } from '../../Admin/Drivers/adminDrivers.styles';

const ProviderVehicles: FC = () => {
    const user = useAppSelector((state: any) => state?.auth.auth)
    const vehicles = useAppSelector((state: any) => state?.provider.allVehicle)

    const bikes = vehicles?.filter((vehicle) => {
        return vehicle.vehicle_type.includes("Bike")
    })
    const nonBikes = vehicles?.filter((vehicle) => {
        return !vehicle.vehicle_type.includes("Bike")
    })

    let [addVehicleComponent, setAddVehicleComponent] = useState(false)
    let [listVehicleComponent, setListVehicleComponent] = useState(true)
    let [active, setActive] = useState<boolean>(false)


    const { data: providerVehicles, isFetching: isFetchingVehicles, refetch: refetchVehicles } = useGetVehiclesQuery(user?.id)

    // const { data: providerBikes, isFetching: isFetchingBikes, refetch: refetchBikes } = useGetBikesQuery(user?.id)

    // // const vehicles = useAppSelector((state: any) => state?.provider.allVehicle)
    // // const bikes = useAppSelector((state: any) => state?.provider.allBike)

    const dispatch = useAppDispatch()

    useEffect(() => {
        refetchVehicles()
        dispatch(setAllVehicle(providerVehicles?.data))
    }, [providerVehicles, refetchVehicles])

    // useEffect(() => {
    //     refetchBikes()
    //     dispatch(setAllBike(providerBikes?.data))
    // }, [])



    const handleAddVehicleComponent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>,) => {
        setAddVehicleComponent(true)
        setListVehicleComponent(false)
    };

    const handleListVehicleComponent = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setListVehicleComponent(true)
        setAddVehicleComponent(false)
    };




    // const bikes = useAppSelector((state: any) => state?.provider.allBike)

    const [filter, setFilter] = useState("");






    //@ts-ignore
    const handleSearch = (e) => {
        setFilter(e.target.value.toLowerCase());

        dispatch(setAllVehicle(providerVehicles?.data?.filter((item: any) => {
            let name = item?.vehicle_name.toLowerCase()
            return name && name.includes(e.target.value.toLowerCase())
        })))

    }









    const vehicleFilter = (type: string) => {
        setActive((prev: any) => !prev)
        dispatch(setAllVehicle(providerVehicles?.data?.filter((item: any) => item?.vehicle_type.includes(type))))
    }



    const vehicleTypes = [...new Set(providerVehicles?.data?.map((item: any) => item.vehicle_type))];

    const ITEMS_PER_PAGE = 8; // Number of items to display per page

    const totalPages = Math.ceil(nonBikes?.length / ITEMS_PER_PAGE);

    // Calculate the range of pages to display in the pagination bar
    const pageRange = 3; // Adjust this value to control the number of page links displayed

    const getPageNumbers = () => {
        const pageNumbers = [];
        if (totalPages <= pageRange * 2 + 1) {
            for (let i = 1; i <= totalPages; i++) {
                //@ts-ignore
                pageNumbers.push(i);
            }
        } else {
            const startPage = Math.max(currentPage - pageRange, 1);
            const endPage = Math.min(currentPage + pageRange, totalPages);

            if (startPage > 1) {
                //@ts-ignore
                pageNumbers.push(1, '...'); // Add ellipsis if not on the first page
            }

            for (let i = startPage; i <= endPage; i++) {
                //@ts-ignore
                pageNumbers.push(i);
            }

            if (endPage < totalPages) {
                //@ts-ignore
                pageNumbers.push('...', totalPages); // Add ellipsis if not on the last page
            }
        }
        return pageNumbers;
    };

    const pageNumberStore: number = useAppSelector((state: any) => state?.admin.currentPage)
    console.log(pageNumberStore, "pageNumberStore")
    const [currentPage, setCurrentPage] = useState<number>(pageNumberStore > totalPages ? 1 : pageNumberStore);

    const handlePageClick = (page) => {
        setCurrentPage(page);
        setPageNumber(page)
    };

    const renderPagination = () => {
        const pageNumbers = getPageNumbers();
        return (
            <PaginationDiv>
                {pageNumbers.map((page, index) => (
                    <PaginationButton
                        key={index}
                        className={`page-item ${page === '...' || page === currentPage ? 'disabled' : ''
                            }`}
                        disabled={page === currentPage}
                    >
                        {page === '...' ? (
                            <span className="page-link">...</span>
                        ) : (
                            <PaginationLabel

                                onClick={() => handlePageClick(page)}
                            >
                                {page}
                            </PaginationLabel>
                        )}
                    </PaginationButton>
                ))}
            </PaginationDiv>
        );
    };

    // Calculate the start and end indices for the current page
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;

    // Slice the data to display only the items for the current page
    const currentData = nonBikes?.slice(startIndex, endIndex);


    return (
        <>
            <Div >
                <FlexDiv>
                    <Button active={listVehicleComponent} onClick={handleListVehicleComponent}>Vehicles</Button>
                    <Button active={addVehicleComponent} onClick={handleAddVehicleComponent}>Add a Vehicle</Button>
                </FlexDiv>

            </Div>





            {
                addVehicleComponent ?
                    <div style={{ width: '95%', margin: '30px auto', paddingTop: '-70px' }}>
                        <CarRegistration dash={true} />
                    </div> :
                    <>
                        {

                            isFetchingVehicles ?
                                <div style={{ textAlign: 'center', margin: '40px auto' }}>
                                    <h4>Loading...</h4>
                                </div>
                                :
                                <>


                                    {!providerVehicles?.data?.length ? <NoData /> :
                                        <>
                                            <Div>
                                                <FlexBetween>
                                                    <button style={{ padding: '3px 7px', display: 'flex', alignItems: 'center', background: '#EBAF4A', border: 'none', borderRadius: '3px' }}>
                                                        <img width={23} src={filterIcon} alt="" />
                                                    </button>



                                                    {vehicleTypes?.map((vehicleType: any) => (
                                                        <Buttons
                                                            key={vehicleType}
                                                            onClick={() => vehicleFilter(vehicleType)}
                                                            active={active}
                                                        >
                                                            {vehicleType}
                                                        </Buttons>
                                                    ))}

                                                </FlexBetween>
                                            </Div>
                                            <Div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                                <InputFlexBetween>
                                                    <Input
                                                        onChange={handleSearch}
                                                        value={filter}
                                                        placeholder='Search' />
                                                    <FiSearch style={{ color: '#DA6317', fontWeight: '700', fontSize: '24px', width: '10%' }} />
                                                </InputFlexBetween>
                                                {/* <div>
                                <TrashButton>
                                    Trash
                                </TrashButton>

                                <PendingButton>
                                    Pending
                                </PendingButton>
                            </div> */}

                                            </Div>


                                            <Div style={{ marginTop: '30px', marginBottom: '9px' }}>
                                                <H5>{nonBikes?.length} Vehicles</H5 >
                                            </Div>


                                            {/* <div className='ul' ref={scrl} onScroll={scrollCheck} > */}
                                            <VehicleDiv>
                                                {
                                                    currentData?.map((vehicle: any) => {
                                                        return <Vehicle key={vehicle.id} vehicleDetails={vehicle}
                                                        />
                                                    })
                                                }
                                            </VehicleDiv>
                                            {/* </div> */}
                                            {renderPagination()}




                                        </>}

                                    {!bikes?.length ? '' :

                                        <>

                                            <H4 style={{ margin: '50px 5px 0px' }}>Delivery Bikes</H4>
                                            <hr style={{ color: '#ffa100', width: '65px', border: '1px solid #ffa100', marginLeft: '5px' }} />

                                            <VehicleDiv>

                                                {
                                                    bikes?.map((bike: any) => {
                                                        return <Bikes key={bike.id} bikeDetails={bike}
                                                        />
                                                    })
                                                }
                                            </VehicleDiv>

                                        </>
                                    }

                                </>
                        }

                    </>
            }




        </>

    )
}

export default ProviderVehicles